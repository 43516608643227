import React from "react";
import { Typography } from "@mui/material"
import SeleccionarFilaDesembolsosAnteriores from "../Components/seleccionarFila/SeleccionarFilaDesembolsosAnteriores";


export const COLUMNSDESEMBOLSOANTERIOR = [
  {
    header: "N° Orden",
    accessorKey: "orden",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontWeight: 600, fontSize: { xs: ".7rem", md: ".8rem" } }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: "Socio Participe",
    accessorKey: "socio",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontWeight: 600, fontSize: { xs: ".7rem", md: ".8rem" } }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: "Acreedor",
    accessorKey: "acreedor",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontWeight: 600, fontSize: { xs: ".7rem", md: ".8rem" } }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: "Fecha Monetización",
    accessorKey: "fecha",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontWeight: 600, fontSize: { xs: ".7rem", md: ".8rem" } }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: "Monto",
    accessorKey: "monto",
    Cell: ({ cell }) => (
      <Typography color="primary" sx={{ fontWeight: 600, fontSize: { xs: ".7rem", md: ".8rem" } }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: "Ver",
    minSize: 60, //min size enforced during resizing
    maxSize: 80, //max size enforced during resizing
    accessorKey: "garantiaid",
    Cell: ({ cell }) => {
      return <SeleccionarFilaDesembolsosAnteriores value={cell.getValue()} />
    }
  },
]