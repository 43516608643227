import React from 'react'
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { useDispatch } from 'react-redux';
import { LightModeOutlined, DarkModeOutlined, Menu as MenuIcon, ArrowDropDownOutlined } from '@mui/icons-material'
import FlexBetween from './FlexBetween'
import profileImage from '../../src/img/perfilRandom.jpg'
import { useTheme, AppBar, Toolbar, IconButton, Button, Box, Typography, MenuItem, Menu, Divider, List, ListItem, ListItemAvatar, Tab, ListItemText, Stack, Paper } from '@mui/material'
import { cerrarSesion } from '../../src/Redux/Usuarios'
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import Popover from '@mui/material/Popover';
import Tooltip from '@mui/material/Tooltip';
import { useProSidebar } from "react-pro-sidebar";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import logolight from "../../src/img/LogoColorHorizontal.png"
import logo from "../../src/img/LogoBlancoHorizontal.png"
import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import SendIcon from '@mui/icons-material/Send';
import CheckIcon from '@mui/icons-material/Check';

const Appbar = ({ setearColor, user, foto, loggedUser }) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorNoti, setAnchorNoti] = React.useState(null);
    const [notificaciones, setNotificaciones] = React.useState([])
    const isOpen = Boolean(anchorEl);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const { toggleSidebar, broken, rtl } = useProSidebar();
    const [checked, setChecked] = React.useState([0])
    const [value, setValue] = React.useState('0')

    const CerrarSesion = (e) => {
        e.preventDefault()
        handleClose()
        dispatch(cerrarSesion())
        navigate('/')
    }

    const handleClickMarcar = (value) => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
        // dispatch(inactivarTarea(value, token.token))
    }
    const handleChangeTabs = (event, newValue) => {
        setValue(newValue)
    }

    const StyledBadge = styled(Badge)(({ theme }) => ({
        '& .MuiBadge-badge': {
            backgroundColor: '#44b700',
            color: '#44b700',
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: 'ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""',
            },
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1,
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0,
            },
        },
    }));

    const handleClickNoti = (event) => {
        setAnchorNoti(event.currentTarget);
    };

    const handleCloseNoti = () => {
        setAnchorNoti(null);
    };

    const open = Boolean(anchorNoti);
    const id = open ? 'simple-popover' : undefined;

    const destinoNotificacion = (tipo) => {
        if (tipo == 100000006) {//"Mi Cuenta"
            navigate('/micuenta')
            setTimeout(() => {
                handleCloseNoti()
            }, 300)
        } else if (tipo == 100000000) { //"Documentación"
            navigate('/midocumentaciondigital')
            setTimeout(() => {
                handleCloseNoti()
            }, 300)
        }
    }

    return (
        <AppBar
            sx={{
                position: "static",
                background: "none",
                boxShadow: "none",
                bgColor: "#fff"
            }}
        >
            <Toolbar sx={{ justifyContent: "space-between", px: 0, mx: 0 }}>
                <Box sx={{
                    width: "100%",
                    display: 'flex',
                    flexDirection: 'row',
                    display: { xs: 'none', sm: 'block' },
                    px: 0,
                    mx: 0
                }}>
                    {
                        theme.palette.mode == 'dark' ?
                            <img src={logo} alt="Logo Responsive" style={{ height: "1.8rem" }} /> :
                            <img src={logolight} alt="Logo Responsive" style={{ height: "1.8rem" }} />
                    }
                </Box>
                {/* Lado izquierdo */}
                <FlexBetween>
                    <Box display="flex">
                        {broken && !rtl && (
                            <IconButton
                                sx={{ margin: "0 6 0 2" }}
                                onClick={() => toggleSidebar()}
                            >
                                <MenuOutlinedIcon />
                            </IconButton>
                        )}
                    </Box>
                    <FlexBetween
                        backgroundColor={theme.palette.background.alt}
                        borderRadius="9px"
                        gap="3rem"
                        p="0.1rem 1.5rem"
                    >
                    </FlexBetween>
                </FlexBetween>
                {/* Lado derecho */}
                <FlexBetween gap="0.2rem">
                    <Tooltip title={<Typography sx={{ color: '#fff' }}>Notificaciones</Typography>}>
                        <IconButton aria-describedby={id} variant="contained" onClick={handleClickNoti}>
                            {theme.palette.mode === 'dark' ?
                                (
                                    <Badge badgeContent={notificaciones.length} color="primary">
                                        <NotificationsNoneOutlinedIcon
                                            sx={{ fontSize: "25px", color: '#fff' }} />
                                    </Badge>

                                )
                                :
                                (
                                    <Badge badgeContent={notificaciones.length} color="primary" >
                                        <NotificationsNoneOutlinedIcon sx={{ fontSize: "25px", color: '#000' }} />
                                    </Badge>
                                )
                            }
                        </IconButton>
                    </Tooltip>
                    {/* <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorNoti}
                        onClose={handleCloseNoti}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}

                    >
                        <Box sx={{ width: '400px' }}>
                            <Grid xs={12} sx={{ bgcolor: theme.palette.primary.main, p: 1, color: '#eeeeee', borderRadius: '5px', m: 0 }}>
                                <Typography variant="subtitle1" sx={{ m: 0 }} gutterBottom>
                                    Notificaciones
                                </Typography>
                            </Grid>
                            {
                                notificaciones.map((item) => {
                                    return (
                                        <Grid container>
                                            <Grid item xs={2} sx={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                alignContent: 'center'
                                            }}>
                                                <ReportGmailerrorredIcon sx={{ fontSize: "35px", color: theme.palette.primary.main, ml: 2 }} />
                                            </Grid>
                                            <Grid item xs={10}>
                                                <Typography variant="subtitle1" sx={{ m: 1 }}>
                                                    {item.subject}
                                                </Typography>
                                                <Typography variant="subtitle1" sx={{ m: 1 }}>
                                                    {moment(new Date(item.createdon)).format('DD/MM/yyyy')}
                                                    <Tooltip title={<Typography sx={{ color: '#fff' }}>Ir</Typography>} >
                                                        <IconButton edge="end" aria-label="" onClick={() => destinoNotificacion(item.new_tipodenotificacion)}>
                                                            <ArrowForwardIcon color="warning" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </Typography>
                                            </Grid>
                                            <Grid xs={12}>
                                                <Divider sx={{ borderBottomWidth: 2 }} />
                                            </Grid>
                                        </Grid>
                                    )
                                })
                            }

                            <Grid xs={12} sx={{ color: '#eeeeee', borderRadius: '5px', m: 0, p: 2 }}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <Typography variant="subtitle1" sx={{
                                        m: 0, display: 'flex',
                                        alignItems: 'center',
                                        color: theme.palette.mode === 'dark' ?
                                            '#fff' : '#000',
                                    }}>
                                        {notificaciones.length > 0 ?
                                            `Tienes ${notificaciones.length} notificaciones pendientes` :
                                            'No tienes notificaciones pendientes'
                                        }
                                    </Typography>
                                </Box>
                            </Grid>
                        </Box>

                    </Popover> */}
                    <Popover
                        open={Boolean(anchorNoti)}
                        anchorEl={anchorNoti}
                        onClose={handleCloseNoti}
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        PaperProps={{
                            sx: {
                                mt: 1.5,
                                ml: 0.75,
                                width: 400,
                                maxHeight: 500,
                                backgroundColor: theme.palette.background.list,
                                color: theme.palette.mode === 'dark' ? '#fff' : '#000',
                                borderRadius: '8px',
                            },
                        }}
                    >
                        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography variant="subtitle1" sx={{ color: theme.typography.primary.text }}>Notificaciones</Typography>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    {notificaciones.filter(item => item.statecode === 0).length == 0 ?
                                        "No tienes notificaciones pendientes" : null}
                                    {notificaciones.filter(item => item.statecode === 0).length > 1 ?
                                        "Tienes " + notificaciones.filter(item => item.statecode === 0).length + " notificaciones pendientes" : null}
                                    {notificaciones.filter(item => item.statecode === 0).length === 1 ?
                                        "Tienes 1 notificación pendiente" : null}
                                </Typography>
                            </Box>
                        </Box>
                        <Divider sx={{ borderStyle: 'dashed' }} />
                        <TabContext value={value}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                px: 0, mx: 0
                            }}>
                                <TabList
                                    aria-label="scrollable force tabs example"
                                    onChange={handleChangeTabs} variant="scrollable"
                                    scrollButtons="auto"
                                    allowScrollButtonsMobile
                                >
                                    <Tab label="Pendientes" value="0" sx={{ fontWeight: 'bold', fontSize: '12px', color: theme.typography.primary.text }}></Tab>
                                    <Tab label="Completadas" value="1" sx={{ fontWeight: 'bold', fontSize: '12px', color: theme.typography.primary.text }}></Tab>
                                </TabList>
                            </Box>
                            <TabPanel value="0" sx={{ p: 0 }}>
                                <Paper elevation={2} sx={{ m: 0, p: 0 }}>
                                    <List
                                        sx={{
                                            width: '100%',
                                            maxWidth: 400,
                                            backgroundColor: theme.palette.background.list,
                                            m: 0, p: 0
                                        }}>
                                        {notificaciones.filter(item => item.statecode === 0).length === 0 ?
                                            <ListItem
                                                alignItems="center"
                                                className={theme.palette.mode === 'dark' ? "itemnotificaciones" : "itemnotificaciones-light"}
                                                sx={{
                                                    backgroundColor: theme.palette.background.list,
                                                }}
                                            >
                                                <Box sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                                                    <Typography align="center">
                                                        No tienes notificaciones pendientes
                                                    </Typography>
                                                </Box>
                                            </ListItem>
                                            : null}
                                        {
                                            notificaciones.filter(item => item.statecode === 0).map((item) => {
                                                return (
                                                    <ListItem
                                                        alignItems="flex-start"
                                                        className={theme.palette.mode === 'dark' ? "itemnotificaciones" : "itemnotificaciones-light"}
                                                        sx={{
                                                            minHeight: 100,
                                                            backgroundColor: theme.palette.background.list,
                                                        }}
                                                        key={item.id}
                                                    >
                                                        <ListItemAvatar>
                                                            <CircleNotificationsIcon sx={{ fontSize: "35px", color: "#43a047" }} />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={
                                                                <Stack
                                                                    direction="row"
                                                                    justifyContent="space-between"
                                                                    alignItems="center"
                                                                    spacing={1}
                                                                >
                                                                    <Typography variant="body2">
                                                                        {item.title}
                                                                    </Typography>
                                                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                        {item.createdAt}
                                                                    </Typography>
                                                                </Stack>
                                                            }
                                                            secondary={
                                                                <React.Fragment>
                                                                    <Typography variant="body2" sx={{ my: 1 }}>
                                                                        {item.description}
                                                                    </Typography>
                                                                    {
                                                                        item.statecode === 0 ?
                                                                            <Stack
                                                                                direction="row"
                                                                                justifyContent="flex-start"
                                                                                alignItems="center"
                                                                                spacing={1}
                                                                            >
                                                                                <Button
                                                                                    sx={{ borderRadius: 5 }}
                                                                                    size="small"
                                                                                    onClick={() => destinoNotificacion(item.type_value)}
                                                                                    loadingPosition="start"
                                                                                    startIcon={<SendIcon />}
                                                                                    variant="outlined"
                                                                                >
                                                                                    <span>Ir</span>
                                                                                </Button>
                                                                                <LoadingButton
                                                                                    sx={{ borderRadius: 5 }}
                                                                                    size="small"
                                                                                    onClick={() => handleClickMarcar(item.id)}
                                                                                    loading={checked?.indexOf(item.id) !== -1 ? true : false}
                                                                                    loadingPosition="start"
                                                                                    startIcon={<CheckIcon />}
                                                                                    variant="contained"
                                                                                >
                                                                                    <span>Marcar como leido</span>
                                                                                </LoadingButton>
                                                                            </Stack> : null
                                                                    }
                                                                </React.Fragment>
                                                            }
                                                        />
                                                        <Divider sx={{ my: 1, borderBottomWidth: 2 }} />
                                                    </ListItem>
                                                )
                                            })
                                        }
                                    </List>
                                </Paper>
                            </TabPanel>
                            <TabPanel value="1" sx={{ p: 0 }}>
                                <Paper elevation={2} sx={{ m: 0, p: 0 }}>
                                    <List
                                        sx={{
                                            width: '100%',
                                            maxWidth: 400,
                                            maxHeight: 350,
                                            overflow: 'auto',
                                            backgroundColor: theme.palette.background.list,
                                            m: 0, p: 0
                                        }}>
                                        {notificaciones.filter(item => item.statecode === 1).length === 0 ?
                                            <ListItem
                                                alignItems="flex-start"
                                                className={theme.palette.mode === 'dark' ? "itemnotificaciones" : "itemnotificaciones-light"}
                                                sx={{
                                                    backgroundColor: theme.palette.background.list,
                                                }}
                                            >
                                                <Box sx={{ width: "100%", display: 'flex', justifyContent: 'center' }}>
                                                    <Typography align="center">
                                                        No tienes notificaciones completadas
                                                    </Typography>
                                                </Box>
                                            </ListItem>
                                            : null}
                                        {
                                            notificaciones.filter(item => item.statecode === 1).map((item) => {
                                                return (
                                                    <ListItem
                                                        alignItems="flex-start"
                                                        className={theme.palette.mode === 'dark' ? "itemnotificaciones" : "itemnotificaciones-light"}
                                                        sx={{
                                                            minHeight: 100,
                                                            backgroundColor: theme.palette.background.list,
                                                        }}
                                                        key={item.id}
                                                    >
                                                        <ListItemAvatar>
                                                            <CircleNotificationsIcon sx={{ fontSize: "35px", color: "#43a047" }} />
                                                        </ListItemAvatar>
                                                        <ListItemText
                                                            primary={
                                                                <Stack
                                                                    direction="row"
                                                                    justifyContent="space-between"
                                                                    alignItems="center"
                                                                    spacing={1}
                                                                >
                                                                    <Typography variant="body2">
                                                                        {item.title}
                                                                    </Typography>
                                                                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                                                        {item.createdAt}
                                                                    </Typography>
                                                                </Stack>
                                                            }
                                                            secondary={
                                                                <React.Fragment>
                                                                    <Typography variant="body2" sx={{ my: 1 }}>
                                                                        {item.description}
                                                                    </Typography>
                                                                    {
                                                                        item.statecode === 0 ?
                                                                            <Stack
                                                                                direction="row"
                                                                                justifyContent="flex-start"
                                                                                alignItems="center"
                                                                                spacing={1}
                                                                            >
                                                                                <Button
                                                                                    sx={{ borderRadius: 5 }}
                                                                                    size="small"
                                                                                    onClick={() => destinoNotificacion(item.type_value)}
                                                                                    loadingPosition="start"
                                                                                    startIcon={<SendIcon />}
                                                                                    variant="outlined"
                                                                                >
                                                                                    <span>Ir</span>
                                                                                </Button>
                                                                                <LoadingButton
                                                                                    sx={{ borderRadius: 5 }}
                                                                                    size="small"
                                                                                    onClick={() => handleClickMarcar(item.id)}
                                                                                    loading={checked?.indexOf(item.id) !== -1 ? true : false}
                                                                                    loadingPosition="start"
                                                                                    startIcon={<CheckIcon />}
                                                                                    variant="contained"
                                                                                >
                                                                                    <span>Marcar como leido</span>
                                                                                </LoadingButton>
                                                                            </Stack> : null
                                                                    }
                                                                </React.Fragment>
                                                            }
                                                        />
                                                    </ListItem>
                                                )
                                            })
                                        }
                                    </List>
                                </Paper>
                            </TabPanel>
                        </TabContext>
                    </Popover>
                    <Tooltip title={<Typography sx={{ color: '#fff' }}>Tema</Typography>}>
                        <IconButton onClick={setearColor}>
                            {theme.palette.mode === 'dark' ?
                                (
                                    <DarkModeOutlined sx={{ fontSize: "25px", color: '#fff' }} />
                                )
                                :
                                (
                                    <LightModeOutlined sx={{ fontSize: "25px", color: '#000' }} />
                                )
                            }
                        </IconButton>
                    </Tooltip>
                    <FlexBetween>
                        <Button
                            onClick={handleClick}
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                textTransform: "none",
                                gap: "1rem",
                            }}
                        >
                            <IconButton color="inherit">
                                <StyledBadge
                                    overlap="circular"
                                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                                    variant="dot"
                                >
                                    {foto !== undefined ? (
                                        <Avatar alt="Remy Sharp" src={foto} />
                                    ) : (
                                        <Avatar alt="Remy Sharp" src={profileImage} />
                                    )}
                                </StyledBadge>
                            </IconButton>

                            <Box
                                // sx={{ display: { xs: "none", sm: "block" } }}
                                textAlign="left"
                            >
                                <Typography
                                    fontWeight="bold"
                                    fontSize="0.80rem"
                                    sx={{
                                        color:
                                            theme.palette.mode === "dark"
                                                ? "#f5f5f5"
                                                : "#3a3541de",
                                        fontWeight: 600,
                                    }}
                                >
                                    {user.tipo === "SGR" ? user.fullname : user.name}
                                </Typography>
                                <Typography
                                    fontSize="0.75rem"
                                    sx={{
                                        color:
                                            theme.palette.mode === "dark"
                                                ? "#f5f5f5"
                                                : "#3a3541de",
                                        fontWeight: 500,
                                    }}
                                >
                                    {user.email}
                                </Typography>
                            </Box>
                            <ArrowDropDownOutlined
                                sx={{ color: "#fafafa", fontSize: "25px" }}
                            />
                        </Button>
                        <Menu
                            anchorEl={anchorEl}
                            open={isOpen}
                            onClose={handleClose}
                            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                        >
                            <MenuItem onClick={e => CerrarSesion(e)}>Cerrar sesión</MenuItem>
                        </Menu>
                    </FlexBetween>
                </FlexBetween>
            </Toolbar >
        </AppBar >
    )
}

export default Appbar