import React, { useState, useEffect, useContext } from 'react'
import Tabla from '../Components/Tabla'
import { COLUMNSGEC, COLUMNSSGRLIDER } from '../Columnas/ColumnasGarantias'
import { Grid, Typography, Box, useTheme } from '@mui/material'
import { MiSgr } from '../Components/MiSgr'
import { useDispatch, useSelector } from 'react-redux'
import { obtenerParticipacionEnSeries } from '../Redux/Series'
import GraficosEstadioPymes from '../Components/GraficosEstadioPymes'
import LoaderAnimado from '../Components/loaderAnimado/LoaderAnimado'
import TokenContext from '../context/TokenContext'
import GraficoLineaApex from '../Components/Graficos/GraficoLineaApex'
import GraficoBarraApex from '../Components/Graficos/GraficoBarraApex'

export const Series = ({ nameSGR }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const token = useContext(TokenContext)
  const [opciones, setOpciones] = React.useState([])
  const [opcionesMontos, setOpcionesMontos] = React.useState([])
  //Selectores
  const participacionesSelector = useSelector(store => store.series.participacionesEnSerie)
  const resultadoParticipacionesSelector = useSelector(store => store.series.resultadoParticipaciones)
  const [participaciones, setParticipaciones] = useState([])
  const [sgrsParticipantes, setSgrsParticipantes] = useState([])
  const [montosMonetizados, setMontosMonetizados] = React.useState([])
  const [saldosVigentesXSerie, setSaldosVigentesXSerie] = React.useState([])
  const [log, setLog] = useState(true)
  const [idSGR, setIdSGR] = useState(JSON.parse(localStorage.getItem('Id SGR')) ? JSON.parse(localStorage.getItem('Id SGR')) : "")

  useEffect(() => {
    let idSGR = JSON.parse(localStorage.getItem('Id SGR'))
    dispatch(obtenerParticipacionEnSeries(token, idSGR))
    setIdSGR(idSGR)
    setLog(true)
  }, [token])

  useEffect(() => {
    if (participacionesSelector?.length > 0 && resultadoParticipacionesSelector == true) {
      tablasParticipaciones(participacionesSelector)
      graficoCantSGRs(participacionesSelector)
      graficoSaldoVigente(participacionesSelector)
    }
  }, [participacionesSelector, resultadoParticipacionesSelector])


  const tablasParticipaciones = (participacionesSeries) => {
    var participacionesAux = []
    var seriesUnicas = [...new Map(participacionesSeries.filter(item => item["_new_sgr_value"] == idSGR).map(item => [item["serie.new_seriedeoperacinsindicadaid"], item])).values()]
    debugger
    seriesUnicas.forEach(item => {
      var participacion = {
        serie: item["serie.new_name"],
        fechaInicio: item["serie.new_fechadeiniciodelaserie@OData.Community.Display.V1.FormattedValue"],
        estado: item["serie.statuscode@OData.Community.Display.V1.FormattedValue"],
        montoGlobal: item["serie.new_importetotalaavalar"],
        montoPendiente: item["serie.new_importependiente"],
        estado: item["serie.statuscode"],
        serieDeOperacinSindicadaId: item["serie.new_seriedeoperacinsindicadaid"],
        sgrLider: item["serie.new_sgrlider"]
      }
      participacionesAux.push(participacion)
    })
    participacionesAux.sort((a, b) => (a.serie < b.serie) ? 1 : ((b.serie < a.serie) ? -1 : 0))
    setParticipaciones(participacionesAux)
    setLog(false)
  }

  const graficoCantSGRs = (participacionesSeries) => {
    let Acreedores = []
    let seriesUnicas = [...new Map(participacionesSeries.map(item => [item["serie.new_seriedeoperacinsindicadaid"], item])).values()]
    let sgrEnSeries = []
    let montos = []
    seriesUnicas.forEach(item => {
      let nombreSeparado = item["serie.new_name"].split('-')
      let nombreSerie = nombreSeparado[0].trim()
      let nombreAcreedor = nombreSeparado[1].trim()
      var ObjMonetizados = {
        Serie: nombreSerie,
        Opcion: nombreAcreedor,
        Monto: item["serie.new_importetotalavaladoliquidado"]
      }
      var serie = {
        filtro: nombreAcreedor,
        opcion: nombreSerie,
        cantidad: item["serie.new_cantidadsgrparticipantes"],
      }
      montos.push(ObjMonetizados)
      Acreedores.push(nombreAcreedor)
      sgrEnSeries.push(serie)
    })
    let acreedoresUnicos = [...new Set(Acreedores.map(item => item))]
    setOpcionesMontos(acreedoresUnicos)
    sgrEnSeries.sort((a, b) => (a.opcion > b.opcion) ? 1 : ((b.opcion > a.opcion) ? -1 : 0))
    montos.sort((a, b) => (a.Serie > b.Serie) ? 1 : ((b.Serie > a.Serie) ? -1 : 0))
    setSgrsParticipantes(sgrEnSeries)
    setMontosMonetizados(montos)
  }

  const graficoSaldoVigente = (participacionesSeries) => {
    var garantiasMontosVigentes = []
    let seriesUnicas = [...new Map(participacionesSeries.map(item => [item["serie.new_seriedeoperacinsindicadaid"], item])).values()]
    let garantiasUnicas = [...new Map(participacionesSeries.map(item => [item["garantia.new_garantiaid"], item])).values()]
    let Acreedores = []
    seriesUnicas.forEach(serie => {
      let serieActual = serie["serie.new_name"]
      let nombreSeparado = serieActual.split('-')
      let nombreSerie = nombreSeparado[0]
      let nombreAcreedor = nombreSeparado[1]
      let saldoVigente = 0
      garantiasUnicas.filter(item => item["garantia.new_nmerodeserie"] === serie["serie.new_seriedeoperacinsindicadaid"]).forEach(item => {
        if (item["garantia.new_saldovigente"] && item["operacion.new_porcentaje"]) {
          let saldoCalculado = item["garantia.new_saldovigente"] * item["operacion.new_porcentaje"]
          saldoVigente += saldoCalculado
        }
      })
      var ObjVigentes = {
        Serie: nombreSerie,
        Opcion: nombreAcreedor,
        Monto: saldoVigente
      }
      Acreedores.push(nombreAcreedor)
      garantiasMontosVigentes.push(ObjVigentes)
    })
    let acreedoresUnicos = [...new Set(Acreedores.map(item => item))]
    setOpciones(acreedoresUnicos)
    garantiasMontosVigentes.sort((a, b) => (a.Serie > b.Serie) ? 1 : ((b.Serie > a.Serie) ? -1 : 0))
    setSaldosVigentesXSerie(garantiasMontosVigentes)
  }

  return (
    <Box component="main" sx={{ flexGrow: 1, p: 3, py: 0 }}>
      <MiSgr nameSGR={nameSGR} />
      <Grid container>
        <Grid xs={12}>
          <Typography variant="h5" sx={{ fontFamily: theme.typography.fontFamily, color: theme.typography.primary.main }}>
            Mis Series
          </Typography>
        </Grid>
        {log ?
          <Grid xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: "center",
                alignItems: "center",
                p: 4
              }}>
              <LoaderAnimado />
            </Box>
          </Grid>
          : null
        }
        {!log ?
          <Grid sx={9} md={8}>
            <Box
              sx={{
                minwidth: 1800,
                minheight: 400,
                bgcolor: '#eeeeee',
                boxShadow: 1,
                borderRadius: 2,
                p: { sx: .8, md: 1 },
                m: 1,
              }}
            >
              <Tabla columnas={COLUMNSGEC(theme)} datos={participaciones} titulo="Participación en Series" width="85vw" />
            </Box>
          </Grid>
          : null
        }
        {!log ?
          <Grid sx={9} md={4}>
            <Box
              sx={{
                minwidth: 1800,
                minheight: 400,
                bgcolor: '#eeeeee',
                boxShadow: 1,
                borderRadius: 2,
                p: { sx: .8, md: 1 },
                m: 1,
              }}
            >
              <Tabla columnas={COLUMNSSGRLIDER(theme)}
                datos={participaciones.filter(item => item.sgrLider === idSGR)}
                titulo="Participación en Series como Lider" width="85vw" />
            </Box>
          </Grid>
          : null
        }
        {!log ?
          <Grid sx={12} md={12}>
            <GraficoLineaApex datos={saldosVigentesXSerie} opciones={opciones} titulo="Mis Saldos Vigentes Por Serie" width="90%" />
          </Grid>
          : null
        }
        {!log ?
          <Grid xs={12} md={6}>
            <GraficoBarraApex datos={sgrsParticipantes} opciones={opcionesMontos} titulo="Cantidad de SGRs por Serie" />
          </Grid>
          : null
        }
        {!log ?
          <Grid xs={12} md={6}>
            <GraficoLineaApex datos={montosMonetizados} opciones={opcionesMontos} titulo="Monto Monetizado por Serie" />
          </Grid>
          : null
        }
      </Grid>
      <GraficosEstadioPymes dataCargada={log} />
    </Box >
  )
}
