import React, { useMemo } from 'react'
import MaterialReactTable from 'material-react-table';
import {
    Typography,
    Box,
    Button,
    IconButton,
    Tooltip,
} from '@mui/material';
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { darken, useTheme, createTheme, ThemeProvider } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { ExportToCsv } from 'export-to-csv';
import FileDownloadIcon from '@mui/icons-material/FileDownload';

const Tabla = ({ columnas, datos, titulo, handleOpen, handleOpenNew, width = "", acciones = false,
    accionAgregar = false, edicionRegistro = false, exportData = false, dataExp = [], headerDataExp = [], nombreArchivo }) => {
    const columns = useMemo(() => columnas, [datos])
    const [data, setData] = React.useState(datos)
    const [pagination, setPagination] = React.useState({
        pageIndex: 0,
        pageSize: 5
    });
    const globalTheme = useTheme();

    React.useEffect(() => {
        if (datos.length > 0) {
            setData(datos)
        }
    }, [datos])

    const openModal = (row) => {
        var id = row.getValue('id')
        handleOpen(id)
    }

    const openModalNew = () => {
        try {
            handleOpenNew()
        } catch (error) {
            console.log(error)
        }   
    }

    const csvOptions = {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalSeparator: '.',
        showLabels: true,
        useBom: true,
        useKeysAsHeaders: false,
        filename: nombreArchivo ? nombreArchivo : "Datos",
        headers: headerDataExp,
    };

    const csvExporter = new ExportToCsv(csvOptions);

    const handleExportRows = (rows) => {
        let datos = []
        if (dataExp?.length > 0) {
            rows.forEach(item => {
                let datosFiltrados = dataExp.filter(dat => dat.id === item.original.id)
                const nuevoArray = datosFiltrados.map(objeto => {
                    const { id, ...restoPropiedades } = objeto;
                    return restoPropiedades;
                });
                nuevoArray?.forEach(itemDF => {
                    datos.push(itemDF)
                })
            })
        }
        if (datos.length > 0) {
            csvExporter.generateCsv(datos);
        }
    };

    const handleExportData = () => {
        if (dataExp?.length > 0) {
            const nuevoArray = dataExp.map(objeto => {
                const { id, ...restoPropiedades } = objeto;
                return restoPropiedades;
            });
            if (nuevoArray?.length > 0) {
                csvExporter.generateCsv(nuevoArray);
            }
        }
    };

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
                    primary: globalTheme.palette.primary, //swap in the secondary color as the primary for the table
                    info: {
                        main: '#223354', //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.background.main
                    },
                },
                typography: {
                    color: "#223354",
                    // fontSize: '.8rem', 
                    fontWeight: 'bold',
                    // color: globalTheme.palette.mode == 'dark' ? 'rgb(17, 22, 51)' : 'rgba(255, 255, 255, 0.5)',
                    button: {
                        textTransform: 'none', //customize typography styles for all buttons in table by default
                        fontSize: '1rem',
                    },
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: '1rem', //override to make tooltip font size larger
                            },
                        },
                    },
                    MuiSwitch: {
                        styleOverrides: {
                            thumb: {
                                color: 'pink', //change the color of the switch thumb in the columns show/hide menu to pink
                            },
                        },
                    },
                },
            }),
        [globalTheme],

    );

    return (
        <ThemeProvider theme={tableTheme}>
            <MaterialReactTable
                muiTablePaginationProps={{
                    rowsPerPageOptions: [5, 10],
                    showFirstLastPageButtons: false
                }}
                onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
                state={{ pagination }}
                columns={columns}
                data={data}
                // muiTablePaperProps={{
                //     elevation: 0, //change the mui box shadow
                //     //customize paper styles
                //     sx: (theme) => ({
                //         borderRadius: '0',
                //         border: darken(theme.palette.background.default, 0.1),
                //         bgcolor: darken(theme.palette.background.default, 0.1)
                //     }),
                // }}
                muiTableBodyProps={{
                    sx: (theme) => ({
                        '& tr:nth-of-type(odd)': {
                            backgroundColor: theme.palette.mode == 'dark' ? 'rgb(17, 22, 51)' : 'rgba(255, 255, 255, 0.5)',
                        },
                    }),
                }}
                enableFullScreenToggle={false}
                enableColumnFilterModes={true}
                enableColumnOrdering={true} //mueve columnas
                enableColumnActions={true} //aciones de columnas como filtro individual
                enableColumnFilters={true}
                enableSorting={true}
                enableColumnResizing
                columnResizeMode="onChange"
                muiTableContainerProps={{ sx: { maxWidth: { xs: width ? width : "98vw", md: '97vw' }, fontSize: { xs: ".7rem", md: "7rem" } } }}
                muiTableBodyCellEditTextFieldProps={{ sx: { fontSize: { xs: ".7rem", md: "7rem" } } }}
                // enableGrouping
                enablePinning
                enableRowActions={acciones}
                enableStickyHeader
                enableRowSelection={exportData}
                initialState={{ showColumnFilters: false, columnVisibility: { id: false }, density: 'compact' }}
                positionToolbarAlertBanner="bottom"
                //idiomas
                localization={MRT_Localization_ES}
                defaultColumn={{
                    maxSize: 400,
                    minSize: 40,
                }}
                enableEditing={false}
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title="Ver">
                            <IconButton >
                                {!edicionRegistro ? <VisibilityIcon onClick={() => openModal(row)} /> : <UploadFileIcon onClick={() => openModal(row)} />}
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                renderTopToolbarCustomActions={({ table }) => (
                    <Box
                        sx={{ display: 'flex', gap: '1rem', p: '0.5rem', flexWrap: 'wrap' }}
                    >
                        {
                            accionAgregar ?
                                <Button
                                    color="secondary"
                                    onClick={() => openModalNew()}
                                    variant="contained"
                                >
                                    <PlaylistAddIcon />
                                </Button> :
                                null
                        }
                        {
                            exportData ?
                                <Button
                                    color="primary"
                                    //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                    onClick={handleExportData}
                                    startIcon={<FileDownloadIcon />}
                                    variant="contained"
                                >
                                    Exportar todo
                                </Button> : null
                        }
                        {/* {
                            exportData ?
                                <Button
                                    disabled={table.getPrePaginationRowModel().rows.length === 0}
                                    //export all rows, including from the next page, (still respects filtering and sorting)
                                    onClick={() =>
                                        handleExportRows(table.getPrePaginationRowModel().rows)
                                    }
                                    startIcon={<FileDownloadIcon />}
                                    variant="contained"
                                >
                                    Exportar Filas
                                </Button> : null
                        } */}
                        {/* {
                            exportData ?
                                <Button
                                    disabled={table.getRowModel().rows.length === 0}
                                    //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                                    onClick={() => handleExportRows(table.getRowModel().rows)}
                                    startIcon={<FileDownloadIcon />}
                                    variant="contained"
                                >
                                    Exportar Pagina
                                </Button> : null
                        } */}
                        {
                            exportData ?
                                <Button
                                    disabled={
                                        !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                                    }
                                    //only export selected rows
                                    onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                                    startIcon={<FileDownloadIcon />}
                                    variant="contained"
                                >
                                    Exportar seleccionados
                                </Button> : null
                        }
                        <Typography variant="subtitle" p="2px">{titulo}</Typography>
                    </Box>
                )}
            />
        </ThemeProvider>
    )
}

export default Tabla