import React from 'react'
import { Box } from '@mui/material'
import styles from "./LoaderAnimado.module.css";

const LoaderAnimado = () => {
    return (
        <Box sx={{ display:"flex", justifyContent:"center", alignItems:"center"}}>
            <span className={`${styles.loader}`}></span>
        </Box>
    )
}

export default LoaderAnimado