import React from 'react'
import {
    Controller,
    useFormState
} from 'react-hook-form'

const CustomTextField = ({ name, label, Component, rules, helperText, type, readOnly = false, req, ...restProps }) => {
    const formState = useFormState()

    return (
        <Controller
            name={name}
            render={({ field: { onChange, onBlur, value, ref } }) => (
                <Component
                    margin="normal"
                    disabled={readOnly}
                    type={type}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    ref={ref}
                    error={Boolean(formState.errors && formState.errors[name])}
                    color="success"
                    helperText={formState.errors[name] ? helperText : null}
                    label={label}
                    // variant="outlined"
                    fullWidth
                    required={req}
                    {...restProps}
                />
            )}
            rules={rules}
        />
    )
}

export default CustomTextField