import React, { useState, useEffect } from 'react'
import { Button, Menu, MenuItem, Box  } from '@mui/material'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import ModalOperaciones from '../modales/ModalOperacionSindicada';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
const SeleccionarFilaOperacionesSindicadas = ({ value }) => {

    const [anchorEl, setAnchorEl] = useState(null)
    const [openModal, setOpenModal] = useState(false)
    const [ids, setIds] = useState(value.split(' '))

    React.useEffect(() => {
      debugger
      console.log(value)
    }, [value])

    const open = Boolean(anchorEl)
    const handleOpen = () => setOpenModal(true)

    const obtenerEstado = (valorEstado) => {
        switch (valorEstado) {
            case '1':
                return "Planificada"
            case '100000000':
                return "En Gestión"
            case '100000001':
                return "Vigente"
            default:
                return "Planificada"
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

  return (
    <Box textAlign="center">
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon sx={{ fontSize:{xs:"1.2rem", md:"1.5rem"} }}/>
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {ids[1] == '100000001' ? (
          <MenuItem
            onClick={() => {
              handleClose();
              handleOpen();
            }}
          >
            Ver Operacion Sindicada
          </MenuItem>
        ) : (
          <MenuItem
            onClick={() => {
              handleClose();
            }}
          >
            La serie se encuentra {obtenerEstado(ids[1])}
          </MenuItem>
        )}
      </Menu>
      <ModalOperaciones open={openModal} setOpen={setOpenModal} id={ids[0]}/>
    </Box>
  );
};

export default SeleccionarFilaOperacionesSindicadas;
