import axios from 'axios'
import { UrlApi, UrlApiDynamics, Entidad } from '../Keys'
import { toast } from 'react-toastify'

const dataInicial = {
    loading: false,
    operaciones: [],
    operacionesSindicadas: [],
    todasOperacionesSindicadas: [],
    serieVigenteFetch: [],
    resultadoOperacionesSindicadas: false,
    resultadoTodasOperacionesSindicadas: false,
    resultadoAdherirOperaciones: ""
}

const ToastError = (msj) => {
    toast.error(msj, {
        theme: "dark",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};
const ToastSuccess = (msj) => {
    toast.success(msj, {
        theme: "dark",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })
}

const LOADING = 'LOADING'
const OPERACIONESSINDICADAS_EXITO = 'OPERACIONESSINDICADAS_EXITO'
const OPERACION_STATUS = 'OPERACION_STATUS'
const TODAS_OPERACIONES_SINDICADAS_EXITO = 'TODAS_OPERACIONES_SINDICADAS_EXITO'
const TODAS_OPERACIONES_EXITO = 'TODAS_OPERACIONES_EXITO'
const ERROR_OPERACIONES = 'ERROR_OPERACIONES'
const SERIE_VIGENTE_EXITO = 'SERIE_VIGENTE_EXITO'
const ERROR_STATUS = 'ERROR_STATUS'

export default function operacionesReducer(state = dataInicial, action) {
    switch (action.type) {
        case OPERACIONESSINDICADAS_EXITO:
            return { ...state, operacionesSindicadas: action.payload, resultadoOperacionesSindicadas: true }
        case TODAS_OPERACIONES_SINDICADAS_EXITO:
            return { ...state, todasOperacionesSindicadas: action.payload, resultadoTodasOperacionesSindicadas: true }
        case TODAS_OPERACIONES_EXITO:
                return { ...state, operaciones: action.operaciones, loading: action.loading }
        case OPERACION_STATUS:
            return { ...state, resultadoAdherirOperaciones: action.resultado }
        case SERIE_VIGENTE_EXITO:
            return { ...state, serieVigenteFetch: action.payload }
        case LOADING:
            return { ...state, operacionesSindicadas: [], resultadoOperacionesSindicadas: false, loading: action.loading }
        case ERROR_STATUS:
                return { ...dataInicial }
        default:
            return { ...state }
    }
}


export const obtenerOperacionesSindicadas = ( token, idSGR) => async (dispatch) => {
    dispatch({
        type: TODAS_OPERACIONES_SINDICADAS_EXITO,

    })
    if(idSGR !== undefined && idSGR !== ""){
    
        var entidad = "new_operacionsindicadas"
        var fetch = "<fetch mapping='logical'>" +
        "<entity name='new_operacionsindicada'>" +
        "<attribute name='new_name'/>" +
        "<attribute name='new_operacionsindicadaid'/>" +
        "<attribute name='createdon'/> " +
        "<attribute name='new_socioparticipe'/> " +
        "<attribute name='new_numerodecheque'/> " +
        "<attribute name='new_motivoderechazo'/> " +
        "<attribute name='new_monto'/>" +
        "<attribute name='new_importeenpesos'/> " +
        "<attribute name='new_fechavencimientoadhesion'/> " +
        "<attribute name='new_fechadevencimiento'/> " +
        "<attribute name='transactioncurrencyid'/> " +
        "<attribute name='new_garantia'/> " +
        "<attribute name='statuscode'/> " +
        "<attribute name='new_porcentaje'/> " +
        "<order attribute='createdon' descending='true' />" +
        "<filter type='and'>" +
        "<condition attribute='new_sgr' operator='eq' value='" + idSGR + "' />" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "</filter>" +
        "<link-entity name='new_garantia' from='new_garantiaid' to='new_garantia' link-type='inner' alias='garantia' >" +
        "<attribute name='new_nmerodeserie'/>" +
        "<attribute name='statuscode'/>" +
        "<attribute name='new_name'/>" +
        "<attribute name='new_garantiaid'/>" +
        "<filter type='and'>" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "</filter>" +
        "<link-entity name='new_seriedeoperacinsindicada' from='new_seriedeoperacinsindicadaid' to='new_nmerodeserie' link-type='inner' alias='serie' >" +
        "<attribute name='new_name'/>"+
        "<attribute name='statuscode'/> "+
        "<attribute name='new_seriedeoperacinsindicadaid'/>" + 
        "<filter type='and'>" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "</filter>" +
        "</link-entity>" +
        "</link-entity>" +
        "</entity>" +
        "</fetch>";
        try {
            await axios.post(`${UrlApi}api/consultafetch`,
                {
                    "entidad": entidad,
                    "fetch": fetch
                },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then((response) => {
                    dispatch({
                        type: OPERACIONESSINDICADAS_EXITO,
                        payload: response.data
                    })
                })
                .catch(err => {
                    console.log(err)
                })
    
        }
    
        catch (error) {
            // dispatch({
            //     type: ERROR
            // })
        }
    }
}

export const obtenerTodasOperacionesSindicadas = ( token, sgrid ) => async (dispatch) => {
    dispatch({
        type: TODAS_OPERACIONES_SINDICADAS_EXITO,
        resultado: "LOADING"
    })
    if(token !== undefined && token !== "" && sgrid){
    var entidad = "new_operacionsindicadas"
    var fetch = "<fetch mapping='logical'>" +
        "<entity name='new_operacionsindicada'>" +
        "<attribute name='new_name'/>" +
        "<attribute name='createdon'/> " +
        "<attribute name='new_socioparticipe'/> " +
        "<attribute name='new_numerodecheque'/> " +
        "<attribute name='new_motivoderechazo'/> " +
        "<attribute name='new_monto'/>" +
        "<attribute name='new_importeenpesos'/> " +
        "<attribute name='new_fechavencimientoadhesion'/> " +
        "<attribute name='new_fechadevencimiento'/> " +
        "<attribute name='transactioncurrencyid'/> " +
        "<attribute name='new_garantia'/> " +
        "<attribute name='statuscode'/> " +
        "<attribute name='new_porcentaje'/> " +
        "<attribute name='new_sgr'/> " +
        "<order attribute='createdon' descending='true' />" +
        "<filter type='and'>" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "</filter>" +
        "<link-entity name='new_garantia' from='new_garantiaid' to='new_garantia' link-type='inner' alias='garantia' >" +
        "<attribute name='new_name'/>" +
        "<attribute name='statuscode'/> " +
        "<attribute name='new_garantiaid'/>" +
        "<filter type='and'>" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "</filter>" +
        "<link-entity name='new_seriedeoperacinsindicada' from='new_seriedeoperacinsindicadaid' to='new_nmerodeserie' link-type='inner' alias='serie' >" +
        "<attribute name='new_name'/>"+
        "<attribute name='statuscode'/> "+
        "<attribute name='new_seriedeoperacinsindicadaid'/>" + 
        "<filter type='and'>" +
        "<condition attribute='new_sgrlider' operator='eq' value='" + sgrid + "' />" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "</filter>" +
        "</link-entity>" +
        "</link-entity>" +
        "</entity>" +
        "</fetch>";
    try {

        await axios.post(`${UrlApi}api/consultafetch`,
        {
            "entidad": entidad,
            "fetch": fetch
        },
        {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        .then((response) => {
            dispatch({
                type: TODAS_OPERACIONES_SINDICADAS_EXITO,
                payload: response.data,
                resultado: "EXITO"
            })
        })
        .catch(err => {
            console.log(err)
            dispatch({
                type: TODAS_OPERACIONES_SINDICADAS_EXITO,
                resultado: "ERROR"
            })
        })
    }
    catch (error) {
        dispatch({
            type: TODAS_OPERACIONES_SINDICADAS_EXITO,
            resultado: "ERROR"
        })
    }
    }
}

export const adherirOperacionSindicada = (token, id, adhesion, motivoRechazo = null) => async (dispatch) => {
    dispatch({
        type: OPERACION_STATUS,
        resultado: 'LOADING'
    })

    if(!adhesion){
        ToastError('Se debe seleccionar "Adherir/Rechazar garantía')
        dispatch({
            type: OPERACION_STATUS,
            resultado: ''
        })
        return
    }
    if(token && adhesion){
        try {
            await axios.put(`${UrlApi}api/portalcasfog/opsindicadas`,
            {
                "new_operacionsindicadaid": id,
                "statuscode": adhesion,
                "new_motivoderechazo": motivoRechazo
            },
            {
            headers: {
                Authorization: `Bearer ${token}`,
            },
            }
        ).then((response) => {
        console.log("response", response)
        dispatch({
            type: OPERACION_STATUS,
            resultado: "EXITO",
        })

        ToastSuccess('La operación fue exitosa')
        
            setTimeout(() => {
                dispatch({
                    type: OPERACION_STATUS,
                    resultado: ''
                })
            }, 4000);
          }
        )
        .catch((err) => {
        console.log(err)
        dispatch({
            type: OPERACION_STATUS,
            resultado: "ERROR",
        })

        ToastError('No pudimos procesar la operación, por favor, vuelva a intentarlo...')
        })
        
        } catch (error) {
            dispatch({
                type: OPERACION_STATUS,
                resultado: 'ERROR'
            })
            ToastError('No pudimos procesar la operación, por favor, vuelva a intentarlo...')
        }
    }
}

export const obtenerOperaciones = (accountid) => async (dispatch) => {
    dispatch({
        type: LOADING,
        loading: true
    })

    try {
        const resp = await axios.get(`${UrlApiDynamics}Operaciones?filter=_new_socioparticipe_value eq ${accountid}&cuit=${Entidad}`)
        dispatch({
            type: TODAS_OPERACIONES_EXITO,
            operaciones: resp.data,
            loading: false
        })
    } catch (error) {
        console.log(error)
        dispatch({
            type: ERROR_OPERACIONES,
            loading: false
        })
    }
}

export const obtenerSerieVigenteFetch = (token) => async (dispatch) => {
    dispatch({
        type: LOADING
    })

    if(token){
        var entidad = "new_seriedeoperacinsindicadas"
        var fetch = "<fetch mapping='logical'>" +
            "<entity name='new_seriedeoperacinsindicada'>" +
            "<attribute name='new_seriedeoperacinsindicadaid'/> " +
            "<attribute name='new_name'/> " +
            "<attribute name='new_sgrlider'/>" +
            "<attribute name='new_numerodeserie'/> " +
            "<attribute name='createdon'/> " +
            "<attribute name='new_tasa'/> " +
            "<attribute name='new_sistemadeamortizacion'/>" +
            "<attribute name='new_porcentajeavaladodelaserie'/> " +
            "<attribute name='new_periodicidadpagos'/> " +
            "<attribute name='new_intersptosporcentuales'/> " +
            "<attribute name='new_denominacindelalneaaseravaladaporelsind' />" +
            "<attribute name='new_acreedor' />" +
            "<order attribute ='createdon' descending='true' />" +
            "<filter type='and'>" +
                "<condition attribute='statuscode' operator='eq' value='100000001' />" +
                "<condition attribute='new_habilitarensolicituddealta' operator='eq' value='1' />"+
            "</filter>" +
            "</entity>" +
            "</fetch>";
            
        try {
            await axios.post(`${UrlApi}api/consultafetch`,
            {
                "entidad": entidad,
                "fetch": fetch
            },
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            .then((response) => {
                dispatch({
                    type: SERIE_VIGENTE_EXITO,
                    payload: response.data
                })
            })
            .catch(err => {
                console.log(err)
                dispatch({
                    type: ERROR_STATUS
                })
            })
        }
        catch (error) {
            dispatch({
                type: ERROR_STATUS
            })
        }
    }
}