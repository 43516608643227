import React, { useState, useContext, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {
    Dialog, Box, DialogContent, DialogContentText, DialogTitle, Button, IconButton, TextField,
    DialogActions, FormControl, Divider, CircularProgress, Tooltip, Typography, useTheme
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useSelector, useDispatch } from "react-redux"
import CustomTextField from '../CustomTextField'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import Moment from 'moment'
import TokenContext from '../../context/TokenContext'
import { actualizarFichaTecnica, obtenerSgrs } from '../../Redux/Indicadores'
import { green } from '@mui/material/colors';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { es } from 'date-fns/locale'
import { Controller } from 'react-hook-form';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/es';

const ModalActualizarFichaTecnica = ({ open, setOpen, idSgr, datos }) => {
    const dispatch = useDispatch()
    const token = useContext(TokenContext)
    const theme = useTheme()
    const resultadoModifSGR = useSelector(store => store.indicadores.resultadoModifSGR)
    const [defaultValues, setDefaultValues] = useState({
        nombredelacalificadora: '',
        responsable: '',
        fechaInicio: null,
        fechadeasociacinencasfog: '',
        fechainscripcinantebcra: '',
        nombredelacalificadora: '',
        calificacion: '',
        fechaultimacalificacion: '',
        sgrId: '',
        new_cuitcalificadora: '',
    })

    useEffect(() => {
        if (datos?.length > 0) {
            let fecha = ''
            let fechaFormateada = ''
            fecha = datos[0]["new_fechadeiniciodeactividades@OData.Community.Display.V1.FormattedValue"]
            debugger
            if(fecha != undefined && fecha != ''){
                fechaFormateada = fecha.split("/").reverse().join("-") //AL SER FECHA Y HORA HAY QUE CONVERTIRLA DISTINTO
            }
            methods.reset({
                nombredelacalificadora: datos[0].new_nombredelacalificadora,
                responsable: datos[0]['_new_responsable_value@OData.Community.Display.V1.FormattedValue'],
                fechaInicio: fechaFormateada,
                fechadeasociacinencasfog: datos[0].new_fechadeasociacinencasfog ? datos[0].new_fechadeasociacinencasfog : "",
                fechainscripcinantebcra: datos[0].new_fechainscripcinantebcra ? datos[0].new_fechainscripcinantebcra : "",
                // nombredelacalificadora: datos[0].new_nombredelacalificadora,
                calificacion: datos[0].new_calificacin,
                fechaultimacalificacion: datos[0].new_fechaultimacalificacion ? datos[0].new_fechaultimacalificacion : "",
                sgrId: datos[0].new_sgrid,
                new_cuitcalificadora: datos[0].new_cuitcalificadora
            });
        }
    }, [datos])

    useEffect(() => {
        if (resultadoModifSGR === "EXITO") {
            handleClose()
        }
    }, [resultadoModifSGR])

    const validationSchema = yup.object().shape({
        fechaInicio: yup.date().required('La fecha de inicio es requerida'),
        fechadeasociacinencasfog: yup.date().required('Fecha de asociación en CASFOG es requerido'),
        fechainscripcinantebcra: yup.date().required('Fecha inscripción ante BCRA como entidad de garantía es requerido'),
        nombredelacalificadora: yup.string().required('El nombre es requerido'),
        calificacion: yup.string().required('La calificacion es requerido'),
        fechaultimacalificacion: yup.date().required('La fecha de la ultima actulización es requerido'),
        new_cuitcalificadora: yup.string().required('El cuit es requerido'),
    })

    const methods = useForm({
        shouldUnregister: false,
        defaultValues,
        resolver: yupResolver(validationSchema),
        mode: "onChange",
    })

    const { handleSubmit } = methods

    const actulizarFicha = (datos) => {
        const { sgrId, fechaInicio, fechadeasociacinencasfog, fechainscripcinantebcra, nombredelacalificadora, calificacion, fechaultimacalificacion, new_cuitcalificadora } = datos
        // dispatch(actualizarFichaTecnica(token, sgrId, fechaInicio ? Moment(new Date(fechaInicio), 'YYYY-MM-DD').format("YYYY-MM-DD") : "",
        // fechadeasociacinencasfog ? Moment(new Date(fechadeasociacinencasfog), 'YYYY-MM-DD').format("YYYY-MM-DD") : "",
        // fechainscripcinantebcra ? Moment(new Date(fechainscripcinantebcra), 'YYYY-MM-DD').format("YYYY-MM-DD") : "",
        // nombredelacalificadora, calificacion,
        // fechaultimacalificacion ? Moment(new Date(fechaultimacalificacion), 'YYYY-MM-DD').format("YYYY-MM-DD") : "", new_cuitcalificadora))
        dispatch(actualizarFichaTecnica(token, sgrId, fechaInicio, fechadeasociacinencasfog, fechainscripcinantebcra,
            nombredelacalificadora, calificacion, fechaultimacalificacion, new_cuitcalificadora))
    }

    const handleClose = () => {
        setOpen(false)
    }

    const buttonSx = {
        ml: 0,
        ...(resultadoModifSGR === 'LOADING' && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        })
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                fullWidth
            >
                <Box sx={{ backgroundColor: theme.palette.background.default, display: "flex", justifyContent: "space-between", px: 2 }}>
                    <DialogTitle id="alert-dialog-title" sx={{ fontSize: { xs: "1rem", md: "1.1rem" } }} >
                        Actualizar ficha fécnica
                    </DialogTitle>
                    <Tooltip title={<Typography sx={{ color: '#fff' }}>Cerrar</Typography>} >
                        <IconButton
                            edge="end"
                            color="warning"
                            onClick={handleClose}
                            aria-label="close"
                            sx={{ width: 40, height: 40, mr: 1, mt: 1 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Divider />
                <DialogContent sx={{ backgroundColor: theme.palette.background.default }}>
                    <DialogContentText id="alert-dialog-description">
                        <FormProvider {...methods}>
                            <Box component="div" sx={{ display: "flex", flexDirection: { md: "row", xs: "column" }, justifyContent: "space-between", alignItems: "center" }}>
                                <FormControl sx={{ m: 1, width: { md: '70%', xs: "90%" } }}>
                                    <CustomTextField
                                        Component={TextField}
                                        type="text"
                                        name="nombredelacalificadora"
                                        label="Nombre de la calificadora"
                                    />
                                </FormControl>
                                <Controller
                                    fullwidth="true"
                                    name="fechaultimacalificacion"
                                    sx={{ m: 1 }}
                                    render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
                                        <FormControl sx={{ m: 1, mt: 2, width: { md: '70%', xs: "90%" } }}>
                                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
                                                <DesktopDatePicker
                                                    {...field}
                                                    views={['day', 'month', 'year']}
                                                    inputRef={ref}
                                                    label="Fecha de la ultima actualización:"
                                                    renderInput={(inputProps) => (
                                                        <TextField
                                                            {...inputProps}
                                                            onBlur={onBlur}
                                                            name={name}
                                                            error={!!fieldState.error}
                                                            helperText={fieldState.error ? fieldState.error.message : null}
                                                            rules={{ required: "Required!" }}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    )}
                                />
                            </Box>
                            <Box component="div" sx={{ display: "flex", flexDirection: { md: "row", xs: "column" }, justifyContent: "space-between", alignItems: "center" }}>
                                <Controller
                                    fullwidth="true"
                                    name="fechaInicio"
                                    sx={{ m: 1 }}
                                    render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
                                        <FormControl sx={{ m: 1, mt: 2, width: { md: '70%', xs: "90%" } }}>
                                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
                                                <DesktopDatePicker
                                                    {...field}
                                                    views={['day', 'month', 'year']}
                                                    inputRef={ref}
                                                    label="Fecha de inicio de actividades:"
                                                    renderInput={(inputProps) => (
                                                        <TextField
                                                            {...inputProps}
                                                            onBlur={onBlur}
                                                            name={name}
                                                            error={!!fieldState.error}
                                                            helperText={fieldState.error ? fieldState.error.message : null}
                                                            rules={{ required: "Required!" }}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    )}
                                />
                                {/* <FormControl sx={{ m: 1, width: { md: '70%', xs: "90%" } }}>
                                    <CustomTextField
                                        Component={TextField}
                                        type="date"
                                        name="fechaInicio"
                                        label="Fecha de inicio de actividades"
                                    />
                                </FormControl> */}
                                <Controller
                                    fullwidth="true"
                                    name="fechadeasociacinencasfog"
                                    sx={{ m: 1 }}
                                    render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
                                        <FormControl sx={{ m: 1, mt: 2, width: { md: '70%', xs: "90%" } }}>
                                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
                                                <DesktopDatePicker
                                                    {...field}
                                                    views={['day', 'month', 'year']}
                                                    inputRef={ref}
                                                    label="Fecha de asociación en CASFOG:"
                                                    renderInput={(inputProps) => (
                                                        <TextField
                                                            {...inputProps}
                                                            onBlur={onBlur}
                                                            name={name}
                                                            error={!!fieldState.error}
                                                            helperText={fieldState.error ? fieldState.error.message : null}
                                                            rules={{ required: "Required!" }}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    )}
                                />
                                {/* <FormControl sx={{ m: 1, width: { md: '70%', xs: "90%" } }}>
                                    <CustomTextField
                                        Component={TextField}
                                        type="date"
                                        name="fechadeasociacinencasfog"
                                        label="Fecha de asociación en CASFOG"
                                    />
                                </FormControl> */}
                            </Box>
                            <Box component="div" sx={{ backgroundColor: theme.palette.background.default, display: "flex", flexDirection: { md: "row", xs: "column" }, justifyContent: "space-between", alignItems: "center" }}>
                                <Controller
                                    fullwidth="true"
                                    name="fechainscripcinantebcra"
                                    sx={{ m: 1 }}
                                    render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
                                        <FormControl sx={{ m: 1, mt: 2, width: { md: '70%', xs: "90%" } }}>
                                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
                                                <DesktopDatePicker
                                                    {...field}
                                                    views={['day', 'month', 'year']}
                                                    inputRef={ref}
                                                    label="Fecha inscripción ante BCRA como entidad de garantía:"
                                                    renderInput={(inputProps) => (
                                                        <TextField
                                                            {...inputProps}
                                                            onBlur={onBlur}
                                                            name={name}
                                                            error={!!fieldState.error}
                                                            helperText={fieldState.error ? fieldState.error.message : null}
                                                            rules={{ required: "Required!" }}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    )}
                                />
                                {/* <FormControl sx={{ m: 1, width: { md: '70%', xs: "90%" } }}>
                                    <CustomTextField
                                        Component={TextField}
                                        type="date"
                                        name="fechainscripcinantebcra"
                                        label="Fecha inscripción ante BCRA como entidad de garantía"
                                    />
                                </FormControl> */}
                                <FormControl sx={{ m: 1, width: { md: '70%', xs: "90%" } }}>
                                    <CustomTextField
                                        Component={TextField}
                                        type="text"
                                        name="calificacion"
                                        label="Calificación"
                                    />
                                </FormControl>
                            </Box>
                            <Box component="div" sx={{ display: "flex", flexDirection: { md: "row", xs: "column" }, justifyContent: "space-between", alignItems: "center" }}>
                                <FormControl sx={{ m: 1, width: { md: '50%', xs: "90%" } }}>
                                    <CustomTextField
                                        Component={TextField}
                                        type="text"
                                        name="new_cuitcalificadora"
                                        label="CUIT Calificadora"
                                        helperText="El cuit es requerido"
                                    />
                                </FormControl>
                                {/* <FormControl sx={{ m: 1, width: { md: '70%', xs: "90%" } }}>
                                    <CustomTextField
                                        Component={TextField}
                                        type="text"
                                        name="mailcalificadora"
                                        label="Mail Calificadora"
                                    />
                                </FormControl> */}
                            </Box>
                        </FormProvider>
                    </DialogContentText>
                    <Box sx={{  mt: 2, mx: 1, p: 0, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <Button
                            type="button"
                            onClick={handleSubmit(actulizarFicha)}
                            variant="contained"
                            sx={buttonSx}
                            disabled={resultadoModifSGR === 'LOADING'}
                        >
                            {resultadoModifSGR === 'LOADING' && (
                                <CircularProgress
                                    size={24}
                                    color="inherit"
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: -12,
                                        marginLeft: -12
                                    }}
                                />
                            )}
                            Actualizar
                        </Button>
                    </Box>
                </DialogContent>
                {/* <DialogActions>
                    <Box sx={{ mt: 3, mb: 2, position: 'relative' }}>
                        <Button
                            type="button"
                            onClick={handleSubmit(actulizarFicha)}
                            variant="contained"
                            disabled={resultadoModifSGR === 'LOADING'}
                        >
                            Actualizar
                        </Button>
                        {resultadoModifSGR === 'LOADING' && (
                            <CircularProgress
                                size={27}
                                sx={{
                                    color: "#fff",
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </DialogActions> */}
            </Dialog>
        </div>
    )
}

export default ModalActualizarFichaTecnica
