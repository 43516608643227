import React from 'react'
import Chart from 'react-apexcharts';
import { Select, MenuItem, Box, Container } from '@mui/material';
import DashboardCard from '../DashboardCard';
import { useTheme } from '@mui/material/styles';
let dollarUS = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

const GraficoLineaApex = ({ datos, opciones, titulo, width = "100%" }) => {
    const [datosGrafico, setDatosGraficos] = React.useState([])
    const [opcion, setOpcion] = React.useState([])
    const theme = useTheme();
    const primary = theme.palette.primary.main;
    const secondary = theme.palette.secondary.main;

    React.useEffect(() => {
        let opcion = opciones[0]
        setOpcion(opcion)
        setDatosGraficos(datos.filter(item => item.Opcion === opcion))
    }, [datos])

    const handleChange = (event) => {
        setOpcion(event.target.value);
        setDatosGraficos(datos.filter(item => item.Opcion === event.target.value))
    };

    const optionscolumnchart = {
        chart: {
            locales: [{
                "name": "en",
                "options": {
                    "months": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
                    "shortMonths": ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                    "days": ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
                    "shortDays": ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
                    "toolbar": {
                        "exportToSVG": "Descargar SVG",
                        "exportToPNG": "Descargar PNG",
                        "exportToCSV": "Descargar CSV",
                        "menu": "Menu",
                        "selection": "Seleccionar",
                        "selectionZoom": "Seleccionar Zoom",
                        "zoomIn": "Zoom",
                        "zoomOut": "Zoom",
                        "pan": "Panorámica",
                        "reset": "Resetear Zoom"
                    }
                }
            }],
            defaultLocale: "en",
            type: 'line',
            fontFamily: "'Plus Jakarta Sans', sans-serif;",
            foreColor: '#adb0bb',
            toolbar: {
                show: true,
                export: {
                    csv: {
                        filename: titulo,
                        columnDelimiter: ',',
                        headerCategory: 'Serie',
                        headerValue: 'Monto',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString()
                        }
                    },
                    png: {
                        filename: titulo,
                        columnDelimiter: ',',
                        headerCategory: 'Serie',
                        headerValue: 'Monto',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString()
                        }
                    },
                    svg: {
                        filename: titulo,
                        columnDelimiter: ',',
                        headerCategory: 'Serie',
                        headerValue: 'Monto',
                        dateFormatter(timestamp) {
                            return new Date(timestamp).toDateString()
                        }
                    }
                }
            },
        },
        grid: {
            show: true,
            borderColor: '#90A4AE',
            strokeDashArray: 2,
            position: 'back',
            xaxis: {
                lines: {
                    show: true
                }
            },
            yaxis: {
                lines: {
                    show: true
                }
            },
            row: {
                colors: undefined,
                opacity: 0.5
            },
            padding: {
                top: 0,
                right: 0,
                bottom: 0,
                left: 0
            },
        },
        labels: {
            show: true
        },
        stroke: {
            curve: 'smooth',
            width: 5,
        },
        yaxis: {
            axisTicks: {
                show: false
            },
            labels: {
                style: {
                    colors: ['#82ca9d'],
                    fontWeight: 'bold',
                },
                formatter: function (value) {
                    return dollarUS.format(value);
                }
            },
            tickAmount: 4,
        },
        xaxis: {
            labels: {
                style: {
                    colors: '#8884d8',
                    fontWeight: 'bold',
                },
            },
            style: {
                colors: '#8884d8',
                fontWeight: 'bold',
            },
            categories: datosGrafico.map(item => item.Serie),
        },
        fill: {
            colors: [secondary],
            type: 'solid',
            opacity: 0.9,
        },
        markers: {
            size: 1,
        },
        tooltip: {
            theme: theme.palette.mode === 'dark' ? 'dark' : 'light',
        },
    };

    const seriescolumnchart = [
        {
            name: 'Total',
            color: secondary,
            data: datosGrafico.map(item => item.Monto),
        },
    ];

    return (
        <DashboardCard title={titulo} action={
            <Select
                labelId="month-dd"
                id="month-dd"
                value={opcion}
                size="small"
                onChange={handleChange}
            >
                {opciones.map(item => {
                    return (
                        <MenuItem value={item}>{item}</MenuItem>
                    )
                })}
            </Select>
        }>
            <Box sx={{
                "& .apexcharts-menu": {
                    background: '#223354!important',
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 'normal',
                    color: "rgba(255, 255, 255, 0.8)"
                },
                "& .apexcharts-menu:hover": {
                    color: "#223354!important"
                },
                "& .apexcharts-toolbar": {
                    // background: '#223354!important',
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 'normal',
                    color: "rgba(255, 255, 255, 0.8)!important"
                },
                "& .apexcharts-toolbar:hover": {
                    color: "#223354!important"
                },
                "& .apexcharts-zoomin-icon": {
                    // background: '#223354!important',
                    fontSize: '12px',
                    fontFamily: 'Helvetica, Arial, sans-serif',
                    fontWeight: 'normal',
                    color: "rgba(255, 255, 255, 0.8)!important"
                },
            }}>
                <Chart options={optionscolumnchart} series={seriescolumnchart} type="line" height="280px" width={width} />
            </Box>
        </DashboardCard>
    )
}

export default GraficoLineaApex