import React, { useState, useEffect, useContext } from "react"
import {
  Dialog, Box, DialogContent, DialogContentText, Typography, DialogTitle, CircularProgress,
  Button, IconButton, Checkbox, FormControlLabel, Modal
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { MuiFileInput } from 'mui-file-input'
import { useSelector, useDispatch } from "react-redux"
import { cargarDocumentacionPorCuenta } from "../../Redux/CarpetaDigital"
import { toast } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"
import TokenContext from '../../context/TokenContext'
import Fade from 'react-reveal/Fade';
import Chip from '@mui/material/Chip';
import Tooltip from '@mui/material/Tooltip';

const ModalCargarDocumentacion = ({ open, setOpen, id }) => {
  const dispatch = useDispatch()
  const token = useContext(TokenContext)

  const [file, setFile] = useState(null)
  const [checked, setChecked] = useState(false);
  const [newEstado, setNewEstado] = useState({})
  const [documento, setDocumento] = useState('')
  const [FechaCreacion, setFechaCreacion] = useState('')
  const [visibleDesdePortal, setVisibleDesdePortal] = useState({})
  const [fechaVencimiento, setFechaVencimiento] = useState('')

  const todosDocumentosCuentasSelector = useSelector(store => store.documentosPorCuenta.documentosPorCuenta)
  const cargaDocumento = useSelector(store => store.documentosPorCuenta.cargaDocumento)

  useEffect(() => {
    if (id) {
      if (todosDocumentosCuentasSelector.length > 0) {
        todosDocumentosCuentasSelector.filter(item => item.new_documentacionporcuentaid == id).forEach(item => {
          setNewEstado({ value: item.statuscode, label: item['statuscode@OData.Community.Display.V1.FormattedValue'] })
          setDocumento(item.new_name)
          setFechaCreacion(item['createdon@OData.Community.Display.V1.FormattedValue'])
          setVisibleDesdePortal({ label: item.new_visibleenportal ? 'Si' : 'No', value: item.new_visibleenportal })
          if (item.new_fechadevencimiento != null) {
            var fechaYhora = item.new_fechadevencimiento.split("T")
            setFechaVencimiento(fechaYhora[0])
          } else {
            setFechaVencimiento('')
          }
        })
      }
    }
  }, [id])

  useEffect(() => {
    if (cargaDocumento === 'EXITO') {
      setTimeout(() => {
        setFile(null)
        setChecked(false)
        handleClose()
      }, 3500);
    }
  }, [cargaDocumento])

  const loadingError = (msj) => {
    toast.error(msj, {
      theme: "dark",
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const subirDocumentacion = (e) => {
    e.preventDefault()
    if (file?.length === 0 || file === null) {
      loadingError("El archivo adjunto es requerido!")
      return
    }
    let sizes = 0
    const formData = new FormData()
    for (let index = 0; index < file.length; index++) {
      sizes += file[index].size
      if (file[index].size >= 15000000) {
        loadingError("El archivo no puede superar los 15 megas")
        setFile(null)
        return
      }
      let element = file[index];
      formData.append(`body${index}`, element);
    }
    if (sizes >= 15000000) {
      loadingError("Los archivos adjuntos no puede superar los 15 megas!")
      setFile(null)
      return
    }

    dispatch(cargarDocumentacionPorCuenta(token, formData, id, checked))
  }

  const handleChangeFile = (newFile) => {
    setFile(newFile)
  }

  const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
  };

  const handleClose = () => {
    setOpen(false)
  }

  //style del modal
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    maxWidth: 450,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 2,
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography id="alert-dialog-title" sx={{ fontSize: "1.2rem", pt: 1 }}>
              Cargar documentación
            </Typography>
            <Tooltip title={<Typography sx={{ color: '#fff' }}>Cerrar</Typography>} sx={{ mt: 3 }} >
              <IconButton
                edge="end"
                color="warning"
                onClick={handleClose}
                aria-label="close"
                sx={{ mr: 0 }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Chip label={documento} sx={{ mt: 2, mb: 2 }} />
          <Box sx={{ py: 2 }}>
            <MuiFileInput
              value={file}
              multiple
              label="Adjuntar documentación"
              fullWidth={true}
              onChange={handleChangeFile} />
            <FormControlLabel
              sx={{ mt: 2 }}
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleChangeChecked}
                  name="estado"
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label={<Typography sx={{ fontSize: { xs: ".8rem", md: "1rem" } }}>"Mantener documentación en estado Pendiente"</Typography>}
            />
          </Box>
          <Box sx={{ mt: 1, mb: 2, position: 'relative' }}>
            <Button
              variant="contained"
              type="submit"
              fullWidth
              onClick={subirDocumentacion}
              disabled={cargaDocumento === 'LOADING'}
              sx={{ fontSize: { xs: ".7rem", md: ".9rem" } }}
            >
              Subir Documentación
            </Button>
            {cargaDocumento === 'LOADING' && (
              <CircularProgress
                size={27}
                sx={{
                  color: "#fff",
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
            <Box sx={{ height: '100%' }} >
              <ErrorOutlineIcon color="warning" sx={{ mr: 1 }} />
            </Box>
            <Box sx={{ height: '100%' }} >
              <Typography>
                El tamaño máximo permitido por archivo es de 15 MB
              </Typography>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
    // <div>
    //   <Dialog
    //     open={open}
    //     onClose={handleClose}
    //     aria-labelledby="alert-dialog-title"
    //     aria-describedby="alert-dialog-description"
    //     maxWidth="xl"
    //   >
    //     <Box sx={{ display:"flex", justifyContent:"space-around"}}>
    //         <DialogTitle id="alert-dialog-title" sx={{ fontSize: {xs:"1rem", md:"1.2rem"} }} >
    //             Cargar Documentación
    //         </DialogTitle>
    //         <IconButton
    //             edge="end"
    //             color="warning"
    //             onClick={handleClose}
    //             aria-label="close"
    //             >
    //             <CloseIcon />
    //         </IconButton>
    //     </Box>
    //     <DialogContent>
    //       <DialogContentText id="alert-dialog-description">
    //         <Typography variant="subtitle1" color="inherit" sx={{fontWeight:600, py:2, fontSize: {xs:".8rem", md:"1rem"}}}>{documento}</Typography>
    //         <Box 
    //           component="form" 
    //           onSubmit={subirDocumentacion}
    //           sx={{display:"flex", flexDirection:"column", alignItems:"flex-start", gap:3}}
    //           >
    //           <MuiFileInput 
    //             value={file} 
    //             multiple
    //             label="Adjuntar documentación"
    //             onChange={handleChangeFile} />
    //           <FormControlLabel
    //             control={
    //               <Checkbox 
    //                 checked={checked} 
    //                 onChange={handleChangeChecked} 
    //                 name="estado" 
    //                 inputProps={{ 'aria-label': 'controlled' }}
    //                 />
    //             }
    //             label={<Typography sx={{ fontSize: {xs:".8rem", md:"1rem"} }}>"Mantener documentación en estado Pendiente"</Typography>}
    //             />
    //           <Box sx={{ mt: 3, mb:2, position: 'relative' }}>
    //             <Button
    //               variant="contained" 
    //               type="submit"
    //               disabled={cargaDocumento === 'LOADING'}
    //               sx={{ fontSize:{ xs:".7rem", md:".9rem"}}}
    //               >
    //                 Subir Documentación
    //             </Button>
    //             {cargaDocumento === 'LOADING' && (
    //               <CircularProgress
    //                 size={27}
    //                 sx={{
    //                     color: "#fff",
    //                     position: 'absolute',
    //                     top: '50%',
    //                     left: '50%',
    //                     marginTop: '-12px',
    //                     marginLeft: '-12px',
    //                 }}
    //               />
    //             )}
    //           </Box>
    //         </Box>
    //       </DialogContentText>
    //     </DialogContent>
    //     <Typography 
    //         variant="subtitle1" 
    //         color="inherit" 
    //         sx={{fontSize:{xs:".8rem", md:".9rem"}, p:2, display:"flex", gap:1}}
    //         >
    //             <ErrorOutlineIcon fontSize="small" color="warning" /> El tamaño máximo permitido por archivo es de 15 MB
    //     </Typography>
    //   </Dialog>
    // </div>
  );
};

export default ModalCargarDocumentacion;
