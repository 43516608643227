import React, { useEffect, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Box,
    Typography,
    Card,
    CardHeader,
    CardContent,
    useTheme,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    Button,
} from "@mui/material"
import Moment from 'moment'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Header from '../Components/Header'
import TokenContext from '../context/TokenContext'
import Tabla from '../Components/Tabla';
import { COLUMNSIND } from '../Columnas/ColumnasIndicadores';
import Fade from 'react-reveal/Fade'
import { COLUMNASACCIONISTAS } from '../Columnas/ColumnasAccionistas';
import { COLUMNASINTEGRANTES } from '../Columnas/ColumnasIntegrantesConsejoAdm';
import { COLUMNASINTEGRANTESCFISCA } from '../Columnas/ColumnasIntegrantesComisionFiscalizadora';
import { COLUMNASGERENTEGEN } from '../Columnas/ColumnasGerenteGeneral';
import { COLUMNASAUDITOR } from '../Columnas/ColumnasAuditor';
import { COLUMNASBALANCES } from '../Columnas/ColumnasBalances';
import { COLUMNASCALIFICACION } from '../Columnas/ColumnasCalificacion';
import { obtenerDocumentacionSgrs, obtenerEstructuraSgr, obtenerSgrs } from '../Redux/Indicadores';
import ModalEstructuraSGR from '../Components/modales/ModalEstructuraSGR';
import { obtenerTodosContactos } from '../Redux/Contacto';
import TablaIndicadores from "../Components/TablaIndicadores"
import { obtenerIndicadoresAtomizacion, obtenerIndicadoresAtomizacionProtectores, obtenerIndicadoresSgr, obtenerLibradores } from '../Redux/Indicadores';
import ModalIndicadores from '../Components/modales/ModalIndicadores';
import ModalActualizarFichaTecnica from '../Components/modales/ModalActualizarFichaTecnica'
import ModalNuevaDocumentacionSGR from '../Components/modales/ModalNuevaDocumentacionSGR';
import ModalActualizarEstructuraSGR from '../Components/modales/ModalActualizarEstructuraSGR';

const DatosSgr = () => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const token = useContext(TokenContext)
    const [value, setValue] = React.useState('1');
    const [valueTablas, setValueTablas] = React.useState('1')
    //##### FICHA TECNICA ##### //PONER LOS ESTADOS DEBAJO PARA EVITAR CONFLICTOS EN MERGE
    const estructuraSgr = useSelector(store => store.indicadores.estructura)
    const sgrDocuFecth = useSelector(store => store.indicadores.sgrDocu)
    const contactos = useSelector(store => store.contactos.todosContactos)
    const resultadoModif = useSelector(store => store.indicadores.resultadoModif)
    const resultadoModifSGR = useSelector(store => store.indicadores.resultadoModifSGR)
    const docuResultado = useSelector(store => store.indicadores.sgrDocuResultado)
    const [openModalEstruc, setOpenModalEstruc] = React.useState(false)
    const [openModalFicha, setOpenModalFicha] = React.useState(false)
    const [openModalCrearDocu, setOpenModalCrearDocu] = React.useState(false)
    const [openModalActuDocu, setOpenModalActuDocu] = React.useState(false)
    const [nombreSgr, setNombreSgr] = React.useState('')
    const [sgrDatos, setSgrDatos] = React.useState('')
    const [sgrDatosCalif, setSgrDatosCalif] = React.useState('')
    const [sgrDocu, setSgrDocu] = React.useState('')
    const [idSgr, setIdSgr] = React.useState('')
    const [logEstructura, setLogEstructura] = React.useState(true)
    const [estructura, setEstructura] = React.useState([])
    const [idEstructura, setIdEstructura] = React.useState('')
    //##### INDICADORES #####
    const [indicadorId, setIndicadorId] = React.useState('')
    const [indicadoresSGR, setIndicadoresSGR] = React.useState([])
    const [indicadorSeleccionado, setIndicadorSeleccionado] = React.useState([])
    const [llamadaIndicador, setLlamadaIndicador] = React.useState(false)
    const [columnaIndicadorSGR, setColumnaIndicadorSGR] = React.useState(COLUMNSIND)
    const indicadoresSelector = useSelector(store => store.indicadores.indicadores)
    const crearIndicadorM = useSelector(store => store.indicadores.crearIndicadorM)
    const modificacionIndicador = useSelector(store => store.indicadores.modificacionIndicador)
    const rolEstructura = useSelector(store => store.indicadores.rol)
    //##### LIBRADORES #####
    const [libradores, setLibradores] = React.useState([])
    const [llamadaLibradores, setLlamadaLibradores] = React.useState(false)
    const libradoresSelector = useSelector(store => store.indicadores.libradores)
    const crearSocioyLibrador = useSelector(store => store.indicadores.crearSocioyLibrador)
    const tipoSocio = useSelector(store => store.indicadores.tipoSocio)
    //##### ATOMIZACION EN CARTERA #####
    const [sociosparticipes, setSociosParticipes] = React.useState([])
    const [llamadaParticipes, setLlamadaParticipes] = React.useState(false)
    const participesYtercerosSelector = useSelector(store => store.indicadores.atomizacion)
    const [protectores, setProtectores] = React.useState([])
    const [llamadaProtectores, setLlamadaProtectores] = React.useState(false)
    const protectoresSelector = useSelector(store => store.indicadores.atomizacion2)
    const sgrsSelector = useSelector(store => store.indicadores.sgrs)
    //##### MODAL #####
    const [open, setOpen] = React.useState(false)
    const [esCrear, setEsCrear] = React.useState(false)
    const [modo, setModo] = React.useState('')

    useEffect(() => {
        setNombreSgr(JSON.parse(localStorage.getItem('Nombre SGR')))
        setIdSgr(JSON.parse(localStorage.getItem('Id SGR')))
        setSgrDatos(JSON.parse(localStorage.getItem('SGR Datos')))
    }, [])

    useEffect(() => {
        if (resultadoModifSGR === "EXITO" || resultadoModif === "EXITO" || docuResultado === "EXITO") {
            dispatch(obtenerEstructuraSgr(token, idSgr))
            dispatch(obtenerTodosContactos(token))
            dispatch(obtenerDocumentacionSgrs(token, idSgr))
            dispatch(obtenerSgrs(token, idSgr))
            setSgrDatos(JSON.parse(localStorage.getItem('SGR Datos')))
        }
        if (docuResultado === "EXITO") {
            setValueTablas('5')
        }
        if (resultadoModif === "EXITO") {
            switch (rolEstructura) {
                case 100000004: //Accionistas
                    setValueTablas('0')
                    break;
                case 100000000: //Integrantes del Consejo de Administración
                    setValueTablas('1')
                    break;
                case 100000001: //Integrantes de la Comisión Fiscalizadora
                    setValueTablas('2')
                    break;
                case 100000002: //Gerente General
                    setValueTablas('3')
                    break;
                case 100000003: //Auditor
                    setValueTablas('4')
                    break;
                default:
                    break;
            }
        }
    }, [resultadoModifSGR, resultadoModif, docuResultado])

    useEffect(() => {
        if (idSgr && token) {
            dispatch(obtenerEstructuraSgr(token, idSgr))
            dispatch(obtenerDocumentacionSgrs(token, idSgr))
            dispatch(obtenerTodosContactos(token))
            dispatch(obtenerIndicadoresSgr(idSgr, token))
        }
    }, [idSgr, token])

    useEffect(() => {
        if (sgrsSelector?.length > 0) {
            setSgrDatos(sgrsSelector)
        }
    }, [sgrsSelector])


    useEffect(() => {
        if (estructuraSgr?.length > 0) {
            let array = []
            estructuraSgr.forEach(item => {
                let estructura = {
                    cuit: item['contact.new_cuitcuil@OData.Community.Display.V1.FormattedValue'],
                    nombre: item['_new_contacto_value@OData.Community.Display.V1.FormattedValue'] ? item['_new_contacto_value@OData.Community.Display.V1.FormattedValue'] : item.new_name,
                    mail: item['contact.emailaddress1'],
                    cargo: item['new_cargo'],
                    rol: item.new_rol,
                    sgrId: item['_new_sgr_value'],
                    contactoId: item['_new_contacto_value'],
                    porcentaje: item.new_porcentaje,
                    id: item.new_estructurasgrid,
                    new_numerodedocumento: item["new_numerodedocumento"],
                    new_correoelectronico: item["new_correoelectronico"],
                    new_porcentaje: item.new_porcentaje
                }
                array.push(estructura)
            })
            console.log(array)
            setEstructura(array)
        }
        if (sgrDatos.length > 0) {
            let array = []
            sgrDatos.forEach(item => {
                let estructura = {
                    cuit: item.new_cuit,
                    razon_social: item.new_nombredelacalificadora,
                    mail: "",
                    fechaUltima_calificacion: item['new_fechaultimacalificacion@OData.Community.Display.V1.FormattedValue'],
                    ultima_calificacion: item.new_calificacin,
                    new_cuitcalificadora: item.new_cuitcalificadora
                }
                array.push(estructura)
            })
            setSgrDatosCalif(array)
        }
        if (sgrDocuFecth.length > 0) {
            let array = []
            sgrDocuFecth.forEach(item => {
                let documento = {
                    sgr: item['_new_sgr_value@OData.Community.Display.V1.FormattedValue'],
                    documentacion: item['_new_documentacion_value@OData.Community.Display.V1.FormattedValue'],
                    fecha_vencimiento: item['new_fechadevencimiento@OData.Community.Display.V1.FormattedValue'],
                }
                array.push(documento)
            })
            setSgrDocu(array)
        }
    }, [estructuraSgr, sgrDatos, sgrDocuFecth, logEstructura])

    //effect de indicadores:
    React.useEffect(() => {
        if (indicadoresSelector?.length > 0) {
            var indicaSGRS = []
            indicadoresSelector.forEach(item => {
                var indicador = {
                    id: item.new_indicadoresmensualessgrid,
                    new_fechahasta: item.new_fechahasta,
                    new_fondoderiesgocontingente: item.new_fondoderiesgocontingente,
                    new_fondoderiesgointegrado: item.new_fondoderiesgointegrado,
                    new_solvencia: item.new_solvencia,
                    new_fondoderiesgoavalordemercado: item.new_fondoderiesgoavalordemercado,
                    new_fechahasta_value: item["new_fechahasta@OData.Community.Display.V1.FormattedValue"],
                    new_fondoderiesgodisponible: item.new_fondoderiesgodisponible,
                    new_saldonetodegarantiasvigentes: item.new_saldonetodegarantiasvigentes,
                    new_chequedepagodiferidocantidadinstrumentos: item.new_chequedepagodiferidocantidadinstrumentos,
                    new_chequedepagodiferidomontodisponible: item.new_chequedepagodiferidomontodisponible,
                    new_chequedepagodiferidomontoencustodia: item.new_chequedepagodiferidomontoencustodia,
                    new_chequedepagodiferidomontomaximo: item.new_chequedepagodiferidomontomaximo,
                    new_chequeelectronicocantidadinstrumentos: item.new_chequeelectronicocantidadinstrumentos,
                    new_chequeelectronicomontodisponible: item.new_chequeelectronicomontodisponible,
                    new_chequeelectronicomontoencustodia: item.new_chequeelectronicomontoencustodia,
                    new_chequeelectronicomontomaximo: item.new_chequeelectronicomontomaximo,
                    new_pagareelectronicocantidadinstrumentos: item.new_pagareelectronicocantidadinstrumentos,
                    // new_pagareelectronicomontodisponible: item.new_pagareelectronicomontodisponible,
                    new_pagareelectronicomontoencustodia: item.new_pagareelectronicomontoencustodia,
                    new_pagareelectronicomontomaximo: item.new_pagareelectronicomontomaximo,
                    new_pagareelectronicousdcantidadinstrumentos: item.new_pagareelectronicousdcantidadinstrumentos,
                    new_pagareelectronicousdmontodisponible: item.new_pagareelectronicousdmontodisponible,
                    new_pagareelectronicousdmontoencustodia: item.new_pagareelectronicousdmontoencustodia,
                    new_pagareelectronicousdmontomaximo: item.new_pagareelectronicousdmontomaximo,
                    new_porcentajecumplimiento48hschpd: item.new_porcentajecumplimiento48hschpd,
                    new_porcentajecumplimiento48hspagare: item.new_porcentajecumplimiento48hspagare,
                    new_porcentajecumplimiento72hschpd: item.new_porcentajecumplimiento72hschpd,
                    new_porcentajecumplimiento72hspagare: item.new_porcentajecumplimiento72hspagare,
                    new_porcentajecumplimiento96hschpd: item.new_porcentajecumplimiento96hschpd,
                    new_porcentajecumplimiento96hspagare: item.new_porcentajecumplimiento96hspagare,
                    new_porcentajecumplimientode96hschpd: item.new_porcentajecumplimientode96hschpd,
                    new_porcentajecumplimientode96hspagare: item.new_porcentajecumplimientode96hspagare,
                    new_porcentajecumplimiento96hschpd: item.new_porcentajecumplimientode96hschpd,
                    new_porcentajecumplimientode96hspagare: item.new_porcentajecumplimientode96hspagare,
                    new_porcentajefianza: item.new_porcentajefianza,
                    new_porcentajeincumplimiento48hschpd: item.new_porcentajeincumplimiento48hschpd,
                    new_porcentajeincumplimiento48hspagare: item.new_porcentajeincumplimiento48hspagare,
                    new_porcentajeincumplimiento72hschpd: item.new_porcentajeincumplimiento72hschpd,
                    new_porcentajeincumplimiento72hspagare: item.new_porcentajeincumplimiento72hspagare,
                    new_porcentajeincumplimiento96hschpd: item.new_porcentajeincumplimiento96hschpd,
                    new_porcentajeincumplimiento96hspagare: item.new_porcentajeincumplimiento96hspagare,
                    new_porcentajeincumplimientode96hschpd: item.new_porcentajeincumplimientode96hschpd,
                    new_porcentajeincumplimientode96hspagare: item.new_porcentajeincumplimientode96hspagare,
                    new_porcentajeprenda: item.new_porcentajeprenda,
                    new_porcentajeriesgopropio: item.new_porcentajeriesgopropio,
                    new_porcentajeriesgoterceros: item.new_porcentajeriesgoterceros,
                    new_porcentajehipoteca: item.new_porcentajehipoteca,
                    new_porcentajeotras: item.new_porcentajeotras,
                    new_valoresdecortoplazocnv: item.new_valoresdecortoplazocnv,
                    new_pagarbursatilcnv: item.new_pagarbursatilcnv,
                    new_obligacionesnegociablescnv: item.new_obligacionesnegociablescnv,
                    new_chequedepagodiferidocnv: item.new_chequedepagodiferidocnv,
                    new_mercadodecapitalescnv: item.new_mercadodecapitalescnv,
                    new_garantastecnicascnv: item.new_garantastecnicascnv,
                    new_garantiascomercialescnv: item.new_garantiascomercialescnv,
                    new_entidadesfinancierascnv: parseInt(item.new_entidadesfinancierascnv),
                    new_garantasvigentesrvenpymesensituacion1: item.new_garantasvigentesrvenpymesensituacion1,
                    new_garantasvigentesrvenpymesensituacion2: item.new_garantasvigentesrvenpymesensituacion2,
                    new_garantasvigentesrvenpymesensituacion3: item.new_garantasvigentesrvenpymesensituacion3,
                    new_garantasvigentesrvenpymesensituacion4: item.new_garantasvigentesrvenpymesensituacion4,
                    new_garantasvigentesrvenpymesensituacion5: item.new_garantasvigentesrvenpymesensituacion5
                }
                indicaSGRS.push(indicador)

            })
            setIndicadoresSGR(indicaSGRS)
        }
    }, [indicadoresSelector])

    //effect de libradores
    React.useEffect(() => {
        // if (idSGR !== '') {
        if (libradoresSelector.length > 0 && llamadaLibradores === true) {
            var libs = []
            libradoresSelector.forEach(item => {
                var librador = {
                    id: item.new_indicadormensualsocioylibradoresid,
                    new_fecha: item["new_fecha@OData.Community.Display.V1.FormattedValue"],
                    new_librador: item.new_librador,
                    new_porcentajelibrador: item.new_porcentajelibrador
                }
                libs.push(librador)
            })
            setLibradores(libs)

        } else if (llamadaLibradores === false && idSgr !== '') {

            obtenerLibrador(idSgr)
            setLlamadaLibradores(true)
        }
        // }
    }, [libradoresSelector, idSgr])
    //effect de atomizacion socioparticipe/tercero
    React.useEffect(() => {
        // if (idSGR !== '') {
        if (participesYtercerosSelector.length > 0 && llamadaParticipes === true) {
            var partYprotec = []
            participesYtercerosSelector.forEach(item => {
                var socios = {
                    id: item.new_indicadormensualsocioylibradoresid,
                    new_fecha: item["new_fecha@OData.Community.Display.V1.FormattedValue"],
                    new_socioparticipetercero: item.new_socioparticipetercero,
                    new_porcentajesocioparticipetercero: item.new_porcentajesocioparticipetercero
                }
                partYprotec.push(socios)
            })
            setSociosParticipes(partYprotec)

        } else if (llamadaParticipes === false && idSgr !== '') {

            obtenerSociosYprotectores(idSgr)
            setLlamadaParticipes(true)
        }
        // }
    }, [participesYtercerosSelector, idSgr])
    //effect de atomizacion protectores
    React.useEffect(() => {
        // if (idSGR !== '') {
        if (protectoresSelector.length > 0 && llamadaProtectores === true) {
            var protec = []
            protectoresSelector.forEach(item => {
                let socios = {
                    id: item.new_indicadormensualsocioylibradoresid,
                    new_fecha: item["new_fecha@OData.Community.Display.V1.FormattedValue"],
                    new_socioprotector: item.new_socioprotector,
                    new_porcentajesocioprotector: item.new_porcentajesocioprotector
                }
                protec.push(socios)
            })
            setProtectores(protec)

        } else if (llamadaProtectores === false && idSgr !== '') {

            obtenerProtectores(idSgr)
            setLlamadaProtectores(true)
        }
        // }
    }, [protectoresSelector, idSgr])

    React.useEffect(() => {
        if (crearSocioyLibrador === "exito") {
            if (tipoSocio == "librador") {
                obtenerLibrador(idSgr)
            } else if (tipoSocio == "protectores") {
                obtenerProtectores(idSgr)
            } else {
                obtenerSociosYprotectores(idSgr)
            }
        }
    }, [crearSocioyLibrador])

    //handle de la tabulacion
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    const handleChangeTablas = (event, newValue) => {
        setValueTablas(newValue);
    };

    const handleOpenModalEstruc = () => {
        setOpenModalEstruc(true)
    }
    const handleOpenModalFichaTecnica = () => {
        setOpenModalFicha(true)
    }
    const handleOpenModalCrearDocu = () => {
        setOpenModalCrearDocu(true)
    }
    const handleOpenModalActuDocu = (id) => {
        setOpenModalActuDocu(true)
        setIdEstructura(id)
    }

    const handleOpenModalTable = (id, editar) => {
        if (editar === true) {
            setOpen(true)
            setIndicadorId(id)
            debugger
            setIndicadorSeleccionado(indicadoresSGR?.find(item => item.id === id))
            setEsCrear(false)
            setModo('Edit')
        } else {
            setOpen(true)
            setEsCrear(true)
            setModo('New')
        }
    }

    const cerrarModalIndicadores = () => {
        setOpen(false)
        setModo('')
    }

    const handleClose = () => setOpen(false);

    const obtenerIndicador = (id, token) => {
        dispatch(obtenerIndicadoresSgr(id, token))
    }

    const obtenerLibrador = (id) => {
        dispatch(obtenerLibradores(id))
    }

    const obtenerSociosYprotectores = (id) => {
        dispatch(obtenerIndicadoresAtomizacion(id))
    }

    const obtenerProtectores = (id) => {
        dispatch(obtenerIndicadoresAtomizacionProtectores(id))
    }
    //#################################

    return (
        <Box sx={{ margin: { md: "1.5rem 2.5rem" }, xs: 0 }}>
            <Fade>
                {/* <Header title={sgr.new_name} subtitle={`Responsable:  ${sgr["_new_responsable_value@OData.Community.Display.V1.FormattedValue"]}`} /> */}
                <Header
                    title={nombreSgr}
                    subtitle={`Responsable:  ${sgrDatos[0] ? sgrDatos[0]['_new_responsable_value@OData.Community.Display.V1.FormattedValue'] : '---'}`} />

                <TabContext value={value}>
                    <Box sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}>
                        <TabList
                            aria-label="scrollable force tabs example"
                            onChange={handleChange} variant="scrollable"
                            scrollButtons="auto"
                            allowScrollButtonsMobile
                            textColor="primary"
                            indicatorColor="primary"
                        >
                            <Tab label="Ficha Técnica" value="1" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} />
                            <Tab label="Indicadores" value="2" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} />
                        </TabList>
                    </Box>

                    <TabPanel value="1">
                        <Grid container spacing={3} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Grid item xs={12} >
                                <Card
                                    sx={{
                                        borderRadius: "0.55rem",
                                        mx: { md: "10rem", xs: "0rem" }
                                    }}
                                >
                                    <CardContent>

                                        <Typography sx={{ mb: "1.7rem", fontWeight: 600 }} variant="subtitle1" component="div" align='center'>
                                            Fecha de inicio de actividades
                                            <Typography variant="body1" sx={{ fontWeight: 400 }}>
                                                {sgrDatos[0] ? sgrDatos[0]["new_fechadeiniciodeactividades@OData.Community.Display.V1.FormattedValue"] : '---'}
                                            </Typography>
                                        </Typography>

                                        <Typography sx={{ mb: "1.7rem", fontWeight: 600 }} variant="subtitle1" component="div" align='center'>
                                            Fecha de asociación en CASFOG
                                            <Typography variant="body1" sx={{ fontWeight: 400 }}>
                                                {sgrDatos[0] ? sgrDatos[0]["new_fechadeasociacinencasfog@OData.Community.Display.V1.FormattedValue"] : '---'}
                                            </Typography>
                                        </Typography>

                                        <Typography sx={{ mb: "1.7rem", fontWeight: 600 }} variant="subtitle1" component="div" align='center'>
                                            Fecha inscripción ante BCRA como entidad de garantía
                                            <Typography variant="body1" sx={{ fontWeight: 400 }}>
                                                {sgrDatos[0] ? sgrDatos[0]['new_fechainscripcinantebcra@OData.Community.Display.V1.FormattedValue'] : '--/--/--'}
                                            </Typography>
                                        </Typography>

                                    </CardContent>
                                </Card>
                                <Box sx={{ display: "flex", flexDirection: { md: "row", xs: "column" }, justifyContent: "center", alignItems: "center", my: 3, gap: 2 }}>
                                    <Button
                                        sx={{ fontSize: ".8rem" }}
                                        variant="contained"
                                        onClick={handleOpenModalEstruc}
                                        type="warning"
                                    >
                                        Crear estructura SGR
                                    </Button>
                                    <Button
                                        sx={{ fontSize: ".8rem" }}
                                        variant="contained"
                                        onClick={handleOpenModalFichaTecnica}
                                        type="warning"
                                    >
                                        Actualizar Ficha Técnica
                                    </Button>
                                    <Button
                                        sx={{ fontSize: ".8rem" }}
                                        variant="contained"
                                        type="warning"
                                        onClick={handleOpenModalCrearDocu}
                                    >
                                        Crear Documentación
                                    </Button>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <TabContext value={valueTablas}>
                                    <Box sx={{ borderBottom: 1, mb: 1, borderColor: 'divider', px: 4 }}>
                                        <TabList
                                            onChange={handleChangeTablas}
                                            aria-label="basic tabs example"
                                            flexContainer="true"
                                            scroller="true"
                                            variant="scrollable"
                                            indicator
                                        >
                                            <Tab label="Accionistas" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="0" />
                                            <Tab label="Integrantes del Consejo de Administración" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="1" />
                                            <Tab label="Integrantes de la Comisión Fiscalizadora" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="2" />
                                            <Tab label="Gerente General" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="3" />
                                            <Tab label="Auditor" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="4" />
                                            <Tab label="Balances Anuales, Trimestrales y Calificación" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="5" />
                                            <Tab label="Calificación" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="6" />
                                        </TabList>
                                    </Box>
                                    <TabPanel value="0" sx={{ p: "0px" }}>
                                        {estructura?.length > 0 && <Tabla
                                            datos={estructura.filter(item => item.rol === 100000004)}
                                            columnas={COLUMNASACCIONISTAS(theme)}
                                            handleOpen={handleOpenModalActuDocu}
                                            acciones={true}
                                        />}
                                    </TabPanel>
                                    <TabPanel value="1" sx={{ p: "0px" }}>
                                        {estructura?.length > 0 && <Tabla
                                            datos={estructura.filter(item => item.rol === 100000000)}
                                            columnas={COLUMNASINTEGRANTES(theme)}
                                            handleOpen={handleOpenModalActuDocu}
                                            acciones={true}
                                        />}
                                    </TabPanel>
                                    <TabPanel value="2" sx={{ p: "0px" }}>
                                        <Tabla
                                            datos={estructura.filter(item => item.rol === 100000001)}
                                            columnas={COLUMNASINTEGRANTESCFISCA(theme)}
                                            handleOpen={handleOpenModalActuDocu}
                                            acciones={true}
                                        />
                                    </TabPanel>
                                    <TabPanel value="3" sx={{ p: "0px" }}>
                                        {estructura?.length > 0 && <Tabla
                                            datos={estructura.filter(item => item.rol === 100000002)}
                                            columnas={COLUMNASGERENTEGEN(theme)}
                                            handleOpen={handleOpenModalActuDocu}
                                            acciones={true}
                                        />}
                                    </TabPanel>
                                    <TabPanel value="4" sx={{ p: "0px" }}>
                                        <Tabla
                                            datos={estructura.filter(item => item.rol === 100000003)}
                                            columnas={COLUMNASAUDITOR(theme)}
                                            handleOpen={handleOpenModalActuDocu}
                                            acciones={true}
                                        />
                                    </TabPanel>
                                    <TabPanel value="5" sx={{ p: "0px" }}>
                                        <Tabla
                                            datos={sgrDocu}
                                            columnas={COLUMNASBALANCES(theme)}
                                        />
                                    </TabPanel>
                                    <TabPanel value="6" sx={{ p: "0px" }}>
                                        <Tabla
                                            datos={sgrDatosCalif}
                                            columnas={COLUMNASCALIFICACION(theme)}
                                        />
                                    </TabPanel>
                                </TabContext>
                            </Grid>
                        </Grid>
                    </TabPanel>

                    <TabPanel value="2">
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12} sx={{ mt: 1, mb: 2 }}>
                                <Card
                                    sx={{
                                        backgroundImage: "none",
                                        backgroundColor: theme.palette.background.alt,
                                        borderRadius: "0.55rem",
                                    }}
                                >
                                    <CardHeader title={<Typography variant="subtitle2">INDICADORES MENSUALES SGR</Typography>} />
                                    <CardContent>
                                        <TablaIndicadores
                                            lineas={indicadoresSGR}
                                            columnas={columnaIndicadorSGR}
                                            handleOpen={handleOpenModalTable}
                                            acciones={true}
                                        />

                                    </CardContent>
                                </Card>
                            </Grid>
                        </Grid>
                    </TabPanel>
                </TabContext>
            </Fade>
            <ModalIndicadores
                open={open}
                handleClose={cerrarModalIndicadores}
                indicadores={indicadorSeleccionado}
                libradores={libradores}
                participesYterceros={sociosparticipes}
                protectores={protectores}
                modo={modo}
                indicadorid={indicadorId}
                sgrid={idSgr}
            />
            {openModalEstruc && idSgr && <ModalEstructuraSGR
                open={openModalEstruc}
                setOpen={setOpenModalEstruc}
                idSgr={idSgr}
                contactos={contactos}
            />}
            {sgrDatos.length > 0 && openModalFicha && <ModalActualizarFichaTecnica
                open={openModalFicha}
                setOpen={setOpenModalFicha}
                datos={sgrDatos}
            />}
            {openModalCrearDocu && <ModalNuevaDocumentacionSGR
                open={openModalCrearDocu}
                setOpen={setOpenModalCrearDocu}
                idSgr={idSgr}
            />}
            {openModalActuDocu && <ModalActualizarEstructuraSGR
                open={openModalActuDocu}
                setOpen={setOpenModalActuDocu}
                datos={estructura.filter(item => item.id === idEstructura)}
                idSgr={idSgr}
            />}
        </Box>
    )
}

export default DatosSgr