import React, { useContext } from 'react'
import { Box, Typography, Divider, Button, Paper, Tooltip, DialogContent, Dialog } from '@mui/material';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Container from '@mui/material/Container';
import CustomSearchSelect from '../CustomSearchSelect';
import { FormProvider, useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import CustomSelect from '../CustomSelect';
import { useSelector, useDispatch } from 'react-redux';
import { cargarGarantia } from '../../Redux/Operaciones';
import CustomTextField from '../CustomTextField';
import CustomCurrencyField from '../CustomCurrencyField';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { toast } from 'react-toastify';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { es } from 'date-fns/locale'
import { obtenerGarantiasFetch } from '../../Redux/Garantias';
import TokenContext from '../../context/TokenContext';

const validacionSchema = yup.object().shape({
    tipoDeOperacion: yup.string().required('El apellido es requerido'),
    formatoDelCheque: yup.string().when('tipoDeOperacion', (tipoDeOperacion) => {
        return tipoDeOperacion == '4' || tipoDeOperacion == '13' ? yup.string().required('Requerido') : yup.string().notRequired()
    }),
    fechaVencimiento: yup.date().min(new Date(), 'La fecha de vencimiento no puede ser manor a hoy').required('Required field')
        .nullable()
        .default(undefined)
        .typeError("La fecha es requerida"),
    tipoCHPD: yup.string().required('El tipo CHPD es requerido'),
    razonSocialLibrador: yup.string().when('tipoCHPD', (tipoCHPD) => {
        return tipoCHPD == '100000001' ? yup.string().required('Requerido') : yup.string().notRequired()
    }),
    cuitLibrador: yup.string().when('tipoCHPD', (tipoCHPD) => {
        return tipoCHPD == '100000001' ? yup.string().required('Requerido') : yup.string().notRequired()
    }),
    montoBruto: yup.number().min(1, "El monto de la garantia debe ser mayor a 0").required('El monto es requerido'),
    acreedor: yup.object({
        value: yup.string().required()
    }),

});

const ModalNuevaGarantia = ({ open, handleClose, acreedores, destinoDeFondos }) => {
    const dispatch = useDispatch()
    const token = useContext(TokenContext)
    const [habilitarNGarantia, setHabilitarNGarantia] = React.useState(false)

    const cargaGarantiaSelector = useSelector(store => store.operaciones.cargaGarantia)
    const accountid = useSelector(store => store.usuarios.accountid)

    
    React.useEffect(() => {
        if (cargaGarantiaSelector == "EXITO" || cargaGarantiaSelector == "ERROR") {
            if (cargaGarantiaSelector != "ERROR") {
                dispatch(obtenerGarantiasFetch(accountid, token))
            }
            if (habilitarNGarantia == true) {
                setHabilitarNGarantia(!habilitarNGarantia)
                setTimeout(() => {
                    reset()
                    handleClose()
                }, 1500)
            }
        }
    }, [cargaGarantiaSelector, token])

    // React.useEffect(() => {
    //     setValue('acreedor', acreedor)
    // }, [acreedor])

    const cerrarModal = () => {
        reset()
        handleClose()
    }

    const tipoCHPDOpciones = [
        { value: '100000000', label: 'Propio' },
        { value: '100000001', label: 'Tercero' },
    ]

    const tipoDeOperacionOpciones = [
        { value: '4', label: 'Cheque de Pago Diferido' },
        { value: '17', label: 'Cheque de Pago Diferido Acreedor Bancario' },
        { value: '13', label: 'Valores de Corto Plazo' },
        { value: '10', label: 'Pagare Bursatil' },
    ]

    const formatoDelChequeOpciones = [
        { value: '100000000', label: 'Físico' },
        { value: '100000001', label: 'Echeck' },
        { value: '100000002', label: 'E-Pagaré' },
    ]

    const defaultValues = {
        acreedor: null,
        // destionDeFondos: null,
        tipoCHPD: '',
        razonSocialLibrador: '',
        cuitLibrador: '',
        montoBruto: 0,
        tipoDeOperacion: '',
        formatoDelCheque: '',
        fechaVencimiento: '',
        numeroCheque: ''
    }

    const methods = useForm({
        shouldUnregister: false,
        defaultValues,
        resolver: yupResolver(validacionSchema),
        mode: "onChange"
    })

    const { handleSubmit, trigger, reset, setValue } = methods;

    const tipoDeOperacion = methods.watch('tipoDeOperacion')

    const submitDatos = (datos) => {
        if (tipoCHPD == "100000000") {
            setValue('razonSocialLibrador', '')
            setValue('cuitLibrador', '')
        } else {
            var cuitValido = true
            cuitValido = ValidateCUITCUIL(cuitLibrador)
            if (!cuitValido) {
                toast.error('El cuit del librador debe ser válido!', {
                    theme: "dark",
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return
            }
        }
        if (tipoDeOperacion == '13' || tipoDeOperacion == '17') {
            setValue('formatoDelCheque', '');
        }
        dispatch(cargarGarantia(datos, accountid, token))
        setHabilitarNGarantia(!habilitarNGarantia)
    }

    const tipoCHPD = methods.watch('tipoCHPD')
    const cuitLibrador = methods.watch('cuitLibrador')

    const ValidateCUITCUIL = (cuit) => {
        let acumulado = 0;
        let respuesta = false;
        let digitos = cuit.split('');
        let digito = parseInt(digitos.pop());

        for (let i = 0; i < digitos.length; i++) {
            acumulado += digitos[9 - i] * (2 + (i % 6));
        }

        let verif = 11 - (acumulado % 11);
        if (verif === 11) {
            verif = 0;
        } else if (verif === 10) {
            verif = 9;
        }
        respuesta = (digito === verif);
        return respuesta;
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableEscapeKeyDown
            BackdropClick={false}
            maxWidth="lg"
        >
            <DialogContent sx={{ m: 0, p: 0 }}>
                <Paper elevation={4} sx={{ m: 0, p: 0 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
                        <Typography id="alert-dialog-title" sx={{ fontSize: "1.2rem", pt: 1 }}>
                            Nueva Garantía
                        </Typography>
                        <Tooltip title={<Typography sx={{ color: '#fff' }}>Cerrar</Typography>}>
                            <IconButton
                                edge="end"
                                color="warning"
                                onClick={cerrarModal}
                                aria-label="close"
                                sx={{ mr: 2 }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Divider />
                    <Container component="main">
                        <FormProvider {...methods}>
                            <form id="Garantia">
                                <Grid container>
                                    <Grid xs={12} sm={6} sx={{ p: 1 }}>
                                        <CustomSelect
                                            name="tipoDeOperacion"
                                            label="Tipo de Operación"
                                            helperText="Por favor seleccione un valor"
                                            items={tipoDeOperacionOpciones}
                                        />
                                    </Grid>
                                    {
                                        tipoDeOperacion == '4' || tipoDeOperacion == '13' ?
                                            <Grid xs={12} sm={6} sx={{ p: 1 }}>
                                                <CustomSelect
                                                    name="formatoDelCheque"
                                                    label="Formato del Cheque"
                                                    helperText="Por favor seleccione un valor"
                                                    items={formatoDelChequeOpciones}
                                                />
                                            </Grid> : null
                                    }
                                    <Grid xs={12} sm={6} sx={{ p: 1 }}>
                                        <CustomTextField
                                            Component={TextField}
                                            label="Nro. Cheque"
                                            name="numeroCheque"
                                        // helperText="La razon social del librador es requerida"
                                        // rules={{ required: "Required!" }}
                                        // req="true"
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6} sx={{ p: 1 }}>
                                        <CustomSelect
                                            name="tipoCHPD"
                                            label="Tipo CHPD"
                                            helperText="Por favor seleccione un valor"
                                            items={tipoCHPDOpciones}
                                            rules={{ required: "Required!" }}
                                        />
                                    </Grid>
                                    {
                                        tipoCHPD == '100000001' ?
                                            <Grid xs={12} sm={6} sx={{ p: 1 }}>
                                                <CustomTextField
                                                    Component={TextField}
                                                    label="Razon Social Librador"
                                                    name="razonSocialLibrador"
                                                    helperText="La razon social del librador es requerida"
                                                    rules={{ required: "Required!" }}
                                                    req="true"
                                                />
                                            </Grid> : null
                                    }
                                    {
                                        tipoCHPD == '100000001' ?
                                            <Grid xs={12} sm={6} sx={{ p: 1 }}>
                                                <CustomTextField
                                                    Component={TextField}
                                                    label="Cuit Librador"
                                                    name="cuitLibrador"
                                                    helperText="Por favor escribe tu CUIT/CUIL sin espacios ni guiones"
                                                    rules={{ required: "Required!" }}
                                                    req="true"
                                                />
                                            </Grid> : null
                                    }
                                    <Grid xs={12} sm={6} sx={{ p: 1 }}>
                                        <CustomCurrencyField
                                            name="montoBruto"
                                            label="Monto Bruto"
                                            rules={{ required: "Required!" }}
                                            req="true"
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6} sx={{ p: 1, mt: 1 }}>
                                        <Controller
                                            fullwidth="true"
                                            name="fechaVencimiento"
                                            render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
                                                <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                                                    <DesktopDatePicker
                                                        {...field}
                                                        views={['day', 'month', 'year']}
                                                        inputRef={ref}
                                                        label="Fecha de Vencimiento:"
                                                        renderInput={(inputProps) => (
                                                            <TextField
                                                                sx={{ mt: 1 }}
                                                                {...inputProps}
                                                                onBlur={onBlur}
                                                                name={name}
                                                                error={!!fieldState.error}
                                                                helperText={fieldState.error ? fieldState.error.message : null}
                                                                rules={{ required: "Required!" }}
                                                            />
                                                        )}
                                                    />
                                                </LocalizationProvider>
                                            )}
                                        />
                                    </Grid>
                                    <Grid xs={12} sm={6} sx={{ p: 1 }}>
                                        <CustomSearchSelect
                                            name="acreedor"
                                            lab="Acreedor"
                                            helperText="Por favor seleccione un valor"
                                            options={acreedores}
                                            req="true"
                                        />
                                    </Grid>
                                    {/* <Grid xs={6} sx={{ p: 1 }}>
                                        <CustomSearchSelect
                                            name="destionDeFondos"
                                            lab="Destino de Fondos"
                                            helperText="Por favor seleccione un valor"
                                            options={destinoDeFondos}
                                            req="true"
                                        />
                                    </Grid> */}
                                    <Grid xs={12} sx={{ pr: 1 }}>
                                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                onClick={handleSubmit(submitDatos)}
                                                disabled={habilitarNGarantia}
                                                sx={{ my: 3, ml: 1 }}
                                            >Guardar</Button>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </form>
                        </FormProvider>
                    </Container>
                </Paper>
            </DialogContent>
        </Dialog >
    )
}

export default ModalNuevaGarantia