import React from 'react'
import { Box, Button, Typography, Container, Alert, AlertTitle, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import BeenhereOutlinedIcon from '@mui/icons-material/BeenhereOutlined';
import Stack from '@mui/material/Stack';
import { useDispatch, useSelector } from 'react-redux'
import { redirect } from 'react-router-dom';
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import CardContent from '@mui/material/CardContent';
import FilePresentIcon from '@mui/icons-material/FilePresent';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import { CorreoAdministracion } from '../../Keys'

const SolicitudAltaFinal = ({ ResetOnboarding }) => {
    const navigate = useNavigate()
    const [mostrarIconoFinal, setMostrarIconoFinal] = React.useState(false)
    const [mostrarTextoFinal, setMostrarTextoFinal] = React.useState(false)
    const [progress, setProgress] = React.useState(0);
    const estadoOnboardingSelector = useSelector(store => store.datos.estadoOnboarding)
    const loadingOnboarding = useSelector(store => store.datos.loadingOnboarding)
    const porcentaje = useSelector(store => store.datos.porcentajeOnboarding)
    const documentosNoCargados = useSelector(store => store.datos.documentosNoCargados)
    const razonSocial = useSelector(store => store.datos.razonSocial)

    React.useEffect(() => {
        setProgress(porcentaje);
    }, [porcentaje]);

    const reinciarOnboarding = () => {
        localStorage.clear();
        ResetOnboarding()
        navigate('/')
    }

    React.useEffect(() => {
        if (estadoOnboardingSelector === 'EXITO') {
            setTimeout(() => {
                setMostrarIconoFinal(true)
            }, 500);
            setTimeout(() => {
                setMostrarTextoFinal(true)
            }, 700);
        }
    }, [estadoOnboardingSelector])

    const Mailto = ({ email, subject = '', body = '', children }) => {
        let params = subject || body ? '?' : '';
        if (subject) params += `subject=${encodeURIComponent(subject)}`;
        if (body) params += `${subject ? '&' : ''}body=${encodeURIComponent(body)}`;

        return <a href={`mailto:${email}${params}`}>{children}</a>;
    };

    return (
        <CardContent>
            {
                loadingOnboarding ?
                    <Container component="main" maxWidth="md">
                        <Box
                            sx={{
                                width: '100%',
                            }}
                        >
                            <LinearProgress sx={{ height: '15px', borderRadius: "50px" }} variant="determinate" value={progress} />
                            <Box
                                sx={{
                                    width: '100%',
                                }}
                            >
                                <Typography align="center" variant="h5" sx={{ mt: 5 }}>
                                    Aguarde por favor, no cierre la ventana ya que se está procesando la información. Esta tarea puede demorar unos minutos....
                                </Typography>
                            </Box>
                        </Box>
                    </Container>
                    : null
            }
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                p: 1,
                m: 1,
                borderRadius: 1,
            }}>
                <Collapse in={mostrarIconoFinal} timeout={{ enter: 800 }}>
                    <IconButton fontSize="inherit" color="primary" sx={{ fontSize: '120px' }}>
                        <BeenhereOutlinedIcon sx={{ fontSize: '80px' }} />
                    </IconButton>
                </Collapse>
            </Box>
            <Collapse in={mostrarTextoFinal} timeout={{ appear: 400, enter: 800 }}>
                <Typography variant="h5" align="center" sx={{ fontFamily: 'Roboto' }}>
                    Felicitaciones! has completado el primer paso. En breve nos comunicaremos contigo vía correo electrónico para
                    informarte del estado de tu solicitud. Cualquier duda podés enviar un correo informando nombre completo o razón
                    social y Cuit/Cuil al siguiente correo administracion@casfog.com.ar
                </Typography>
                {
                    documentosNoCargados?.length > 0 ?
                        <Box sx={{ mt: 3 }}>
                            <Alert severity="warning" variant="outlined">
                                <AlertTitle><Typography variant="h6">Advertencia</Typography></AlertTitle>
                                <Typography variant="h6" >
                                    Lamentablemente, no pudimos procesar algunos de los archivos adjuntos que intentaste enviar.
                                    Por favor, envie por <Mailto
                                        email={CorreoAdministracion}
                                        subject={`Documentación faltante onboarding digital ${razonSocial}`}
                                        body="">
                                        correo
                                    </Mailto> los siguientes documentos:
                                </Typography>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    <List sx={{ width: '100%' }}>
                                        {
                                            documentosNoCargados.map(item => {
                                                return (
                                                    <ListItem>
                                                        <ListItemIcon>
                                                            <InsertDriveFileIcon color="warning" sx={{ fontSize: '24px' }} />
                                                        </ListItemIcon>
                                                        <ListItemText primary={<Typography variant="h6">{item}</Typography>} />
                                                    </ListItem>
                                                )
                                            })
                                        }
                                    </List>
                                </Box>
                            </Alert>
                        </Box> : null
                }
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    p: 1,
                    m: 1,
                    mt: 5,
                    borderRadius: 1,
                }}>
                    <Stack direction="row" spacing={2}>
                        <Button size="large" color="primary" variant="outlined" onClick={() => reinciarOnboarding()}>
                            Finalizar
                        </Button>
                    </Stack>
                </Box>
            </Collapse>
        </CardContent>
    )
}

export default SolicitudAltaFinal