import axios from 'axios'
import { UrlApi } from '../Keys'

//Const
const dataInicial = {
    SgrsPorContactos: [],
    idSGR:"",
    nameSGR:"",
    resultado: ""
}

//Types
const SGR_POR_CONTACTO = 'SGR_POR_CONTACTO'
const ID_SGR ='ID_SGR'

//Reducers
export default function sgrsReducers(state = dataInicial, action) {
    switch (action.type) {
        case SGR_POR_CONTACTO:
            return { ...state, SgrsPorContactos: action.payload,  resultado: action.resultado}
        case ID_SGR:
            return { ...state, idSGR: action.idSGR, nameSGR: action.nameSGR}
        default:
            return { ...dataInicial }
    }
}

//Actions

export const obtenerSGRsPorContacto = ( token, contactid ) => async (dispatch) => {
    dispatch({
        type: SGR_POR_CONTACTO,
        resultado: "LOADING"
    })

    if(token && contactid){
        try {
            var entidad = "new_sgrs"
            fetch = "<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='true'>"+
            "<entity name='new_sgr'>"+
              "<attribute name='new_sgrid' />"+
              "<attribute name='new_name' />"+
              "<attribute name='createdon' />"+
              "<attribute name='new_responsable' />"+
              "<attribute name='statuscode' />"+
              "<attribute name='ownerid' />"+
              "<attribute name='statecode' />"+
              "<order attribute='new_name' descending='false' />"+
              "<link-entity name='new_contactoporsgr' from='new_sgr' to='new_sgrid' link-type='inner' alias='usuario'>"+
                "<filter type='and'>"+
                  "<condition attribute='new_contacto' operator='eq' uitype='contact' value='"+ contactid +"' />"+
                "</filter>"+
              "</link-entity>"+
            "</entity>"+
          "</fetch>";
    
          await axios.post(`${UrlApi}api/consultafetch`,
          {
              "entidad": entidad,
              "fetch": fetch
          },
          {
              headers: {
                  "Authorization": `Bearer ${token}`
              }
          })
          .then((response) => {
            dispatch({
                type: SGR_POR_CONTACTO,
                payload: response.data,
                resultado: "EXITO"
            })
          })
          .catch(err => {
              console.log(err)
          })
        }
        catch (error) {
            dispatch({
                type: SGR_POR_CONTACTO,
                resultado: "ERROR"
            })
        }
    }
}

export const setIdSGR = (idSGR = "", nameSGR = "") => async (dispatch) => {

    localStorage.setItem("Id SGR",JSON.stringify(idSGR))
    localStorage.setItem("Nombre SGR",JSON.stringify(nameSGR))
    dispatch({
        type: ID_SGR,
        idSGR: idSGR,
        nameSGR: nameSGR
    })
}