import React, { useState, useEffect } from 'react'
import { Grid } from '@mui/material'
import { Typography, Divider, Box } from '@mui/material'
import CircleIcon from '@mui/icons-material/Circle';

export const MiSgr = () => {

    const [nameSGR, setNameSGR] = useState(JSON.parse(localStorage.getItem('Nombre SGR')))

    useEffect (() => {
        setNameSGR(JSON.parse(localStorage.getItem('Nombre SGR')))
    },[])

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 0, mt:1 }}>
            <Grid xs={12} sx={{ mb: 2 }}>
                <Typography variant="h6" sx={{ fontSize:{xs:".9rem", md:"1.1rem"}, mb: 1 }}>
                    <CircleIcon sx={{ fontSize: 16, mr: 1, color: "green" }} />
                    {nameSGR && nameSGR[0]}
                </Typography>
                <Divider />
            </Grid>
        </Box>
    )
}
