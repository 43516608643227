import React, { PureComponent } from 'react'
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const COLORS = ['#ffa726', '#f44336', '#f57c00', '#66bb6a', '#42a5f5', '#d32f2f', '#388e3c'];

const GraficoBarra = ({ data, x, y }) => {
    return (
        <ResponsiveContainer width="100%" height="100%" sx={{ mt: 2 }}>
            <BarChart
                minwidth={300}
                minheight={150}
                data={data}
                margin={{
                    top: 5,
                    right: 30,
                    left: 20,
                    bottom: 5,
                }}
            >
                <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
                <XAxis dataKey={x} tick={{ fontSize: 12 }} />
                <YAxis tick={{ fontSize: 12 }} />
                <Tooltip />
                <Legend />
                {/* <Bar dataKey={y} fill={COLORS} /> */}
                <Bar dataKey={y} fill="#1b5e20" label={{ position: 'top' }} inputProps={{ style: { textTransform: "uppercase" } }}>
                    {data.map((entry, index) => (
                        <Cell key={data[index]} fill={COLORS[index]} />
                    ))}
                </Bar>
                {/* <Bar dataKey="uv" fill="#82ca9d" /> */}
            </BarChart>
        </ResponsiveContainer>
    )
}

export default GraficoBarra