import React from "react";
import { Button } from "@mui/material";
import { Link } from "react-router-dom";
import { getSerieId } from "../Redux/Series";
import { useDispatch } from "react-redux";

const VerSerie = ({ idSerie }) => {
  const dispatch = useDispatch();

  const getIdSerie = (id) => {
    if (id) {
      dispatch(getSerieId(id));
    }
  };

  return (
    <Link to="/Serie" style={{ textDecoration: "none", color: "#000" }}>
      <Button
        onClick={() => getIdSerie(idSerie)}
        variant="contained"
        sx={{
          fontSize: { md: ".7rem", xs: ".6rem" },
          fontWeight:600,
          bgcolor: "#90caf9",
          color:"#000",
          "&:hover": "#64b5f6",
        }}
      >
        Ver serie
      </Button>
    </Link>
  );
};

export default VerSerie;
