import React from "react";
import { Chip, Typography } from "@mui/material";
import Moment from 'moment'
import SeleccionarFilaDocumentacion from "../Components/seleccionarFila/SeleccionarFilaDocumentacion"

export const COLUMNSCARPD = (tema) => [
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>DOCUMENTACIÓN POR CUENTA</Typography>,
    minSize: 160, //min size enforced during resizing
    maxSize: 300, //max size enforced during resizing
    accessorKey: "new_name",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold'  }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>ESTADO DE LA DOCUMENTACIÓN</Typography>,
    accessorKey: "statuscode",
    Cell: ({ cell }) => {
      switch (cell.getValue()) {
        case 1:
          return (
            // <Chip label="Pendiente" sx={{ bgcolor: theme.palette.mode === 'dark' ? 'rgba(241, 241, 19, 0.1)' : 'rgba(241, 241, 19, 0.5)', color: 'rgb(241, 241, 19)', fontSize: '.8rem' }} />
            <Chip label="Pendiente" sx={{
              bgcolor: tema.palette.mode === "dark" ? 'rgba(241, 241, 19, 0.2)' : 'rgb(241, 241, 19, 0.8)',
              color: tema.palette.mode === "dark" ? 'rgb(241, 241, 19)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
            );
        case 100000000:
          return (
            <Chip label="Recibido" sx={{
              bgcolor: tema.palette.mode === "dark" ? 'rgba(87, 202, 34, 0.2)' : 'rgb(87, 202, 34, 0.8)',
              color: tema.palette.mode === "dark" ? 'rgb(87, 202, 34)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
          );
        case 100000001:
          return (
            <Chip label="Aprobado" sx={{
              bgcolor: tema.palette.mode === "dark" ? 'rgba(255, 163, 25, 0.2)' : 'rgb(255, 163, 25, 0.8)',
              color: tema.palette.mode === "dark" ? 'rgb(255, 163, 25)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
          );
        default:
          return "---";
      }
    }
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>FECHA DE VENCIMIENTO</Typography>,
    accessorKey: "fechaDeVencimiento",
    minSize: 120, //min size enforced during resizing
    maxSize: 200, //max size enforced during resizing
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold'  }}>
        {cell.getValue() ? Moment(cell.getValue(), 'YYYY-MM-DD').format("DD-MM-YYYY") : '-'}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>VISIBLE EN PORTAL DEL SOCIO</Typography>,
    accessorKey: "visiblePortalName",
    minSize: 120, //min size enforced during resizing
    maxSize: 200, //max size enforced during resizing
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold'  }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    accessorKey: 'objeto',
    minSize: 60, //min size enforced during resizing
    maxSize: 80, //max size enforced during resizing
    Cell: ({ cell }) => {
      return (
        <SeleccionarFilaDocumentacion value={cell.getValue()} />
      )
    }
  }
]