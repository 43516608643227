import axios from 'axios'
import { Entidad, UrlApi, UrlApiDynamics } from '../Keys'

//Const
const dataInicial = {
    loading: false,
    actividades: [],
    condicionesPyme: [],
    condicionPyme:[]
}

const TODAS_ACTIVIDADES_EXITO = 'TODAS_ACTIVIDADES_EXITO'
const LOADING = "LOADING"
const ERROR = 'ERROR'
const TODAS_CONDICIONES_EXITO = 'TODAS_CONDICIONES_EXITO'
const TODAS_CONDICIONES_EXITO2 = 'TODAS_CONDICIONES_EXITO2'

//Reducers
export default function actividadesAFIPReducers(state = dataInicial, action) {
    switch (action.type) {
        case ERROR:
            return { ...dataInicial }
        case LOADING:
            return { ...state, loading: true }
        case TODAS_ACTIVIDADES_EXITO:
            return { ...state, actividades: action.payload, loading: false }
        case TODAS_CONDICIONES_EXITO:
            return { ...state, condicionesPyme: action.payload}
        case TODAS_CONDICIONES_EXITO2:
            return { ...state, condicionPyme: action.payload}
        default:
            return { ...state }
    }
}

//Actions
export const obtenerActividadesAFIP = () => async (dispatch) => {
    dispatch({
        type: LOADING
    })

    try {
        ////;
        const response = await axios.get(`${UrlApiDynamics}Actividadafip?filter=&cuit=${Entidad}`)
        dispatch({
            type: TODAS_ACTIVIDADES_EXITO,
            payload: response.data
        })
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}

export const obtenerCondicionPymes = (token) => async (dispatch) => {
    dispatch({
        type: LOADING
    })

    try {
        var entidad = "new_condicionpymes"
        var fetch = "<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='false'>"+
        "<entity name='new_condicionpyme'>"+
            "<attribute name='new_condicionpymeid' />"+
            "<attribute name='new_name' />"+
            "<attribute name='createdon' />"+
            "<order attribute='new_name' descending='false' />"+
        "</entity>"+
        "</fetch>";
        await axios.post(`${UrlApi}api/consultafetch`,
        {
            "entidad": entidad,
            "fetch": fetch
        },
        {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        .then((response) => {
            dispatch({
                type: TODAS_CONDICIONES_EXITO,
                payload: response.data
            })
        })
        .catch(err => {
            dispatch({
                type: ERROR
            })
        })
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}

export const obtenerCondicionPyme = (token) => async (dispatch) => {
    dispatch({
        type: LOADING
    })
    try {
        const entidad = "new_condicionpymes";
        const fetch = `
            <fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='false'>"+
              <entity name='new_condicionpyme'>
                <attribute name='new_condicionpymeid' />
                <attribute name='new_name' />
                <attribute name='createdon' />
                <order attribute='new_name' descending='false' />
              </entity>
            </fetch>
          `;
    
        const response = await axios.post(
          `${UrlApi}api/consultafetch`,
          {
            entidad: entidad,
            fetch: fetch,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        dispatch({
            type: TODAS_CONDICIONES_EXITO2,
            payload: response.data
        })
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}