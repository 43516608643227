import React from "react"
import { Paper, Typography } from "@mui/material"
import Moment from 'moment'
import VerSerie from "../Components/VerSerie"
import SeleccionarFilaGarantia from "../Components/seleccionarFila/SeleccionarFilaGarantia"
import Chip from '@mui/material/Chip'

let dollarUS = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

//en cartera
export const COLUMNSGEC = (tema) => [
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold' }}>SERIE</Typography>,
    accessorKey: "serie",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontWeight: 600, fontSize: { xs: ".8rem", md: ".9rem" } }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold' }}>FECHA DE INICIO</Typography>,
    accessorKey: "fechaInicio",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: { xs: ".7rem", md: ".8rem" } }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold' }}>ESTADO</Typography>,
    accessorKey: "estado",
    Cell: ({ cell }) => {
      switch (cell.getValue()) {
        case 100000000:
          return (
            <Chip label="En Gestión" sx={{ bgcolor: 'rgba(241, 241, 19, 0.1)', color: 'rgb(241, 241, 19)', fontSize: '.8rem' }} />
          );
        case 1:
          return (
            <Chip label="Planificada" sx={{ bgcolor: 'rgba(255, 163, 25, 0.1)', color: 'rgb(255, 163, 25)', fontSize: '.8rem' }} />
          );
        case 100000001:
          return (
            <Chip label="Vigente" sx={{
              bgcolor: tema.palette.mode === "dark" ? 'rgba(87, 202, 34, 0.2)' : 'rgb(87, 202, 34, 0.8)',
              color: tema.palette.mode === "dark" ? 'rgb(87, 202, 34)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
          );
        case 2:
          return (
            <Chip label="Cancelada" sx={{ bgcolor: 'rgba(255, 163, 25, 0.1)', color: 'rgb(255, 163, 25)', fontSize: '.8rem' }} />
          );
        case 100000002:
          return (
            <Chip label="Finalizada" sx={{ bgcolor: 'rgba(255, 163, 25, 0.1)', color: 'rgb(255, 163, 25)', fontSize: '.8rem' }} />
          );
        case 100000003:
          return (
            <Chip label="Cerrada" sx={{ bgcolor: 'rgba(255, 163, 25, 0.1)', color: 'rgb(255, 163, 25)', fontSize: '.8rem' }} />
          );
        default:
          return "---";
      }
    },
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold' }}>MONTO GLOBAL</Typography>,
    accessorKey: "montoGlobal",
    Cell: ({ cell }) => (
      <Typography color="primary" sx={{ fontWeight: 600, fontSize: { xs: ".7rem", md: ".8rem" } }}>
        {dollarUS.format(cell.getValue())}
      </Typography>
    ),
  },
  {
    header: "Ver serie",
    accessorKey: "serieDeOperacinSindicadaId",
    Cell: ({ cell }) => {
      return <VerSerie idSerie={cell.getValue()} />
    }
  },
];

export const COLUMNSSGRLIDER = (tema) => [
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold' }}>SERIE</Typography>,
    accessorKey: "serie",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', fontWeight: 'bold' }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold' }}>FECHA DE INICIO</Typography>,
    accessorKey: "fechaInicio",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', fontWeight: 'bold' }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold' }}>MONTO PENDIENTE</Typography>,
    accessorKey: "montoPendiente",
    Cell: ({ cell }) => (
      <Typography color="primary" sx={{ fontSize: '.8rem', fontWeight: 'medium', fontWeight: 'bold' }}>
        {dollarUS.format(cell.getValue())}
      </Typography>
    ),
  },
];

export const COLUMNSGARANTIAS = (tema) => [
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>N° ORDEN</Typography>,
    minSize: 100, //min size enforced during resizing
    maxSize: 120, //max size enforced during resizing
    accessorKey: "numeroDeOrden",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>SERIE</Typography>,
    accessorKey: "serie",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>FECHA DE CARGA</Typography>,
    accessorKey: "fechaCarga",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {cell.getValue() ? Moment(cell.getValue(), 'YYYY-MM-DD').format("DD-MM-YYYY") : '-'}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>SOCIO PARTICIPE</Typography>,
    accessorKey: "socio",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>ACREEDOR</Typography>,
    accessorKey: "acreedor",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>MONTO</Typography>,
    accessorKey: "monto",
    Cell: ({ cell }) => (
      <Typography color="primary" sx={{ fontSize: '.8rem', fontWeight: 'medium', fontWeight: 'bold' }}>
        {dollarUS.format(cell.getValue())}
      </Typography>
    ),
  },
  {
    accessorKey: 'new_garantiaid',
    minSize: 60, //min size enforced during resizing
    maxSize: 80, //max size enforced during resizing
    Cell: ({ cell }) => {
      return (
        <SeleccionarFilaGarantia value={cell.getValue()} />
      )
    }
  }
];

export const COLUMNSGARANTIASVIGENTES = (tema) => [
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>N° ORDEN</Typography>,
    minSize: 100, //min size enforced during resizing
    maxSize: 120, //max size enforced during resizing
    accessorKey: "numeroDeOrden",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>SERIE</Typography>,
    accessorKey: "serie",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  // {
  //   header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>FECHA DE CARGA</Typography>,
  //   accessorKey: "fechaCarga",
  //   Cell: ({ cell }) => (
  //     <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
  //       {cell.getValue() ? Moment(cell.getValue(), 'YYYY-MM-DD').format("DD-MM-YYYY") : '-'}
  //     </Typography>
  //   ),
  // },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>SOCIO PARTICIPE</Typography>,
    accessorKey: "socio",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  // {
  //   header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>ACREEDOR</Typography>,
  //   accessorKey: "acreedor",
  //   Cell: ({ cell }) => (
  //     <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
  //       {cell.getValue()}
  //     </Typography>
  //   ),
  // },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>FECHA DE MONETIZACIÓN</Typography>,
    accessorKey: "fechaMonetizacion",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {cell.getValue() ? Moment(cell.getValue(), 'YYYY-MM-DD').format("DD-MM-YYYY") : '-'}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>MONTO</Typography>,
    accessorKey: "monto",
    Cell: ({ cell }) => (
      <Typography color="primary" sx={{ fontSize: '.8rem', fontWeight: 'medium', fontWeight: 'bold' }}>
        {dollarUS.format(cell.getValue())}
      </Typography>
    ),
  },
  {
    accessorKey: 'new_garantiaid',
    minSize: 60, //min size enforced during resizing
    maxSize: 80, //max size enforced during resizing
    Cell: ({ cell }) => {
      return (
        <SeleccionarFilaGarantia value={cell.getValue()} />
      )
    }
  }
];

export const COLUMNSGARANTIASPRINCIPAL = (tema) => [
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>N° ORDEN</Typography>,
    minSize: 100, //min size enforced during resizing
    maxSize: 120, //max size enforced during resizing
    accessorKey: "numeroDeOrden",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {cell.getValue() ? cell.getValue() : '-'}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>SERIE</Typography>,
    minSize: 100, //min size enforced during resizing
    maxSize: 120, //max size enforced during resizing
    accessorKey: "serie",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>SOCIO PARTICIPE</Typography>,
    minSize: 120, //min size enforced during resizing
    maxSize: 140, //max size enforced during resizing
    accessorKey: "socio",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>ESTADO</Typography>,
    minSize: 100, //min size enforced during resizing
    maxSize: 120, //max size enforced during resizing
    accessorKey: "descripcionEstado",
    // Cell: ({ cell }) => (
    //   <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
    //     {cell.getValue()}
    //   </Typography>
    // ),
    Cell: ({ cell }) => {
      switch (cell.getValue()) {
        case "Vigente":
          return (
            <Chip label="Vigente" sx={{
              bgcolor: tema.palette.mode === "dark" ? 'rgba(87, 202, 34, 0.2)' : 'rgb(87, 202, 34, 0.8)',
              color: tema.palette.mode === "dark" ? 'rgb(87, 202, 34)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
          );
        case "En Gestión":
          return (
            <Chip label="En Gestión" sx={{
              bgcolor: tema.palette.mode === "dark" ? 'rgba(241, 241, 19, 0.2)' : 'rgb(241, 241, 19, 0.8)',
              color: tema.palette.mode === "dark" ? 'rgb(241, 241, 19)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
          );
        case "Desestimada":
          return (
            <Chip label="Desestimada" sx={{
              bgcolor: tema.palette.mode === "dark" ? 'rgba(255, 45, 0, 0.2)' : 'rgb(255, 45, 0, 0.8)',
              color: tema.palette.mode === "dark" ? 'rgb(255, 45, 0)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
          );
        case "En Cartera":
          return (
            <Chip label="En Cartera" sx={{
              bgcolor: tema.palette.mode === "dark" ? 'rgba(255, 163, 25, 0.2)' : 'rgb(255, 163, 25, 0.8)',
              color: tema.palette.mode === "dark" ? 'rgb(255, 163, 25)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
          );
        case "Anulada":
          return (
            <Chip label="Anulada" sx={{
              bgcolor: tema.palette.mode === "dark" ? 'rgba( 255, 87, 51, 0.2)' : 'rgb( 255, 87, 51, 0.8)',
              color: tema.palette.mode === "dark" ? 'rgb(255, 87, 51)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
          );
        case "Rechazada":
          return (
            <Chip label="Rechazada" sx={{
              bgcolor: tema.palette.mode === "dark" ? 'rgba(254, 61, 20 , 0.2)' : 'rgb(254, 61, 20 , 0.8)',
              color: tema.palette.mode === "dark" ? 'rgb(254, 61, 20)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
          );
        case "Afrontada":
          return (
            <Chip label="Afrontada" sx={{
              bgcolor: tema.palette.mode === "dark" ? 'rgba(194, 34, 0, 0.2)' : 'rgb(194, 34, 0, 0.8)',
              color: tema.palette.mode === "dark" ? 'rgb(194, 34, 0)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
          );
        default:
          return "---";
      }
    }
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>FECHA MONETIZACIÓN</Typography>,
    accessorKey: "fechaMonetizacion",
    minSize: 120, //min size enforced during resizing
    maxSize: 160, //max size enforced during resizing
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {cell.getValue() ? Moment(cell.getValue(), 'YYYY-MM-DD').format("DD-MM-YYYY") : '-'}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>MONTO</Typography>,
    minSize: 100, //min size enforced during resizing
    maxSize: 120, //max size enforced during resizing
    accessorKey: "monto",
    Cell: ({ cell }) => (
      <Typography color="primary" sx={{ fontSize: '.8rem', fontWeight: 'medium', fontWeight: 'bold' }}>
        {dollarUS.format(cell.getValue())}
      </Typography>
    ),
  },
  {
    accessorKey: 'new_garantiaid',
    minSize: 60, //min size enforced during resizing
    maxSize: 80, //max size enforced during resizing
    Cell: ({ cell }) => {
      return (
        <SeleccionarFilaGarantia value={cell.getValue()} />
      )
    }
  }
];

export const COLUMNSGARANTIASENCARTERA = (tema) => [
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>SERIE</Typography>,
    accessorKey: "serie",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>FECHA DE CARGA</Typography>,
    accessorKey: "fechaCarga",
    Cell: ({ cell }) => (
      <Typography sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {cell.getValue() ? Moment(cell.getValue(), 'YYYY-MM-DD').format("DD-MM-YYYY") : '-'}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>SOCIO PARTICIPE</Typography>,
    accessorKey: "socio",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>ACREEDOR</Typography>,
    accessorKey: "acreedor",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>MONTO</Typography>,
    accessorKey: "monto",
    Cell: ({ cell }) => (
      <Typography color="primary" sx={{ fontSize: '.8rem', fontWeight: 'medium', fontWeight: 'bold' }}>
        {dollarUS.format(cell.getValue())}
      </Typography>
    ),
  },
  {
    accessorKey: 'new_garantiaid',
    minSize: 60, //min size enforced during resizing
    maxSize: 80, //max size enforced during resizing
    Cell: ({ cell }) => {
      return (
        <SeleccionarFilaGarantia value={cell.getValue()} />
      )
    }
  }
];

export const COLUMNSGT = (tema) => [
  {
    accessorKey: 'id',
  },
  {
    header: 'N° Orden',
    minSize: 60, //min size enforced during resizing
    maxSize: 80, //max size enforced during resizing
    accessorKey: 'new_ndeordendelagarantiaotorgada',
    Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
  },
  {
    header: 'Tipo de Operacion',
    accessorKey: 'new_tipodeoperacion',
    Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
  },
  {
    header: 'Acreedor',
    accessorKey: 'new_acreedor',
    Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
  },
  {
    header: 'Estado',
    accessorKey: 'statuscode_value',
    Cell: ({ cell }) => obtenerChip(cell.getValue())
  },
  {
    header: 'Monto',
    accessorKey: 'new_monto',
    Cell: ({ cell }) => <strong>{dollarUS.format(cell.getValue())}</strong>
  },
  {
    header: 'Fecha de Creación',
    accessorKey: 'createdon',
    Cell: ({ cell }) => { cell.getValue() }
  },

]

export const COLUMNSGECP = (tema) => [
  {
    accessorKey: 'id',
  },
  {
    header: 'N° Orden',
    accessorKey: 'new_ndeordendelagarantiaotorgada',
    Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
  },
  {
    header: 'Tipo de Operacion',
    accessorKey: 'new_tipodeoperacion',
    Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
  },
  {
    header: 'Acreedor',
    accessorKey: 'new_acreedor',
    Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
  },
  {
    header: 'Monto',
    accessorKey: 'new_monto',
    Cell: ({ cell }) => <strong>{dollarUS.format(cell.getValue())}</strong>
  },
  {
    header: 'Fecha de vencimiento',
    accessorKey: 'new_fechadevencimiento',
    Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
  },
]

function obtenerChip(data) {

  switch (data) {
    case "En Cartera":
      return <Chip label="En Cartera" sx={{ bgcolor: '#ff6d00' }} />
    case "En Gestión":
      return <Chip label="En Gestión" sx={{ bgcolor: '#ffab00' }} />
    case "Vigente":
      return <Chip label="Vigente" sx={{ bgcolor: '#4caf50' }} />
    case "Vencida":
      return <Chip label="Vencida" sx={{ bgcolor: '#f44336' }} />
    case "Afrontada":
      return <Chip label="Afrontada" sx={{ bgcolor: '#4caf50' }} />
    case "Honrada":
      return <Chip label="Honrada" sx={{ bgcolor: '#1b5e20' }} />
    case "Anulada":
      return <Chip label="Anulada" sx={{ bgcolor: '#d50000' }} />
    default:
      break;
  }
}

export const COLUMNSGOPERACIONES = [
  {
    accessorKey: 'id',
  },
  {
    header: 'Tipo de Operación',
    accessorKey: 'new_tipodeoperacion',
    Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
  },
  {
    header: 'Acreedor',
    accessorKey: 'new_acreedor',
    Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
  },
  {
    header: 'Fecha de Carga',
    accessorKey: 'createdon',
    Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
  },
  {
    header: 'Estado',
    accessorKey: 'statuscode_value',
    Cell: ({ cell }) => obtenerChip(cell.getValue())
  },
  {
    header: 'Monto Bruto',
    accessorKey: 'new_monto',
    Cell: ({ cell }) => <strong>{dollarUS.format(cell.getValue())}</strong>
  },
]


