import React from 'react'
import { IconButton } from '@mui/material';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { Box } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import Tooltip from '@mui/material/Tooltip';

const DescargarArchivo = (nota) => {

    function downloadBase64File() {
        if (nota != null && nota.nota != null) {
            var archivo = nota.nota
            if (archivo.filename != null && archivo.mimetype != null && archivo.documentbody != null) {
                const linkSource = `data:${archivo.mimetype};base64,${archivo.documentbody}`;
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = archivo.filename;
                downloadLink.click();
            }
        }
    }

    return (
        <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            {
                nota.nota.filename != null ?
                    <Tooltip title="Descargar Comprobante" sx={{ mt: 3 }}>
                        <IconButton
                            edge="end"
                            aria-label=""
                            sx={{ mx: 1 }}
                            onClick={() => downloadBase64File()}>
                            <CloudDownloadIcon />
                        </IconButton>
                    </Tooltip> : '-'

            }
            {
                nota.nota.urlafip != null ?
                    <Tooltip title="Ver Comprobante en AFIP" sx={{ mt: 3 }}>
                        <IconButton
                            edge="end"
                            aria-label=""
                            sx={{ mx: 1 }}
                            onClick={() => window.open(nota.nota.urlafip, "_blank")}>
                            <ExitToAppIcon />
                        </IconButton>
                    </Tooltip> : '-'
            }
        </Box>
    )
}

export default DescargarArchivo
