import React from "react";
import { Paper, Typography, Tooltip, IconButton, Box, Chip } from "@mui/material";
import Moment from 'moment'
import SeleccionarFilaOperacionesSindicadas from "../Components/seleccionarFila/SeleccionarFilaOperacionesSindicadas";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

let dollarUS = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const COLUMNSOPERACIONES = (tema) => [
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>GARANTÍA</Typography>,
    accessorKey: "garantia",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>SERIE</Typography>,
    minSize: 120, //min size enforced during resizing
    maxSize: 140, //max size enforced during resizing
    accessorKey: "serie",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>DIVISA</Typography>,
    minSize: 120, //min size enforced during resizing
    maxSize: 140, //max size enforced during resizing
    accessorKey: "divisa",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>MONTO</Typography>,
    minSize: 100, //min size enforced during resizing
    maxSize: 120, //max size enforced during resizing
    accessorKey: "monto",
    Cell: ({ cell }) => (
      <Typography color="primary" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {dollarUS.format(cell.getValue())}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>PORCENTAJE</Typography>,
    minSize: 100, //min size enforced during resizing
    maxSize: 120, //max size enforced during resizing
    accessorKey: "porcentaje",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        % {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>PLAZO APROBACIÓN</Typography>,
    accessorKey: "fechavencimientoadhesion",
    Cell: ({ cell }) => (
      <Chip label={cell.getValue()} sx={{
        bgcolor: tema.palette.mode === "dark" ? 'rgba(255, 45, 0 , 0.2)' : 'rgb(255, 45, 0 , 0.8)',
        color: tema.palette.mode === "dark" ? 'rgb(255, 45, 0)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
      }} />
    ),
  },
  {
    accessorFn: (row) => `${row.seriedeoperacinsindicadaid} ${row.estadoSerie}`,
    id: 'seleccionar operacion',
    minSize: 60, //min size enforced during resizing
    maxSize: 80, //max size enforced during resizing
    Cell: ({ cell }) => {
      return (
        <SeleccionarFilaOperacionesSindicadas value={cell.getValue()} />
      )
    }
  }
];

export const COLUMNSOPERACIONESRECHAZADAS = (tema) => [
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>GARANTÍA</Typography>,
    accessorKey: "garantia",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>SERIE</Typography>,
    accessorKey: "serie",
    minSize: 100, //min size enforced during resizing
    maxSize: 120, //max size enforced during resizing
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>ESTADO</Typography>,
    minSize: 100, //min size enforced during resizing
    maxSize: 120, //max size enforced during resizing
    accessorKey: "estado",
    Cell: ({ cell }) => {
      switch (cell.getValue()) {
        case 100000000:
          return (
            <Chip label="Pendiente de Confirmación" sx={{ bgcolor: 'rgba(241, 241, 19, 0.1)', color: 'rgb(241, 241, 19)', fontSize: '.8rem' }} />
          );
        case 1:
          return (
            <Chip label="Adherida" sx={{ bgcolor: 'rgba(87, 202, 34, 0.1)', color: 'rgb(87, 202, 34)', fontSize: '.8rem' }} />
          );
        case 100000001:
          return (
            <Chip label="RECHAZADA" sx={{
              bgcolor: tema.palette.mode === "dark" ? 'rgba(255, 45, 0 , 0.2)' : 'rgb(255, 45, 0 , 0.8)',
              color: tema.palette.mode === "dark" ? 'rgb(255, 45, 0)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
          );
        default:
          return "---";
      }
    }
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>MOTIVO DE RECHAZO</Typography>,
    minSize: 120, //min size enforced during resizing
    maxSize: 140, //max size enforced during resizing
    accessorKey: "motivoderechazo",
    Cell: ({ cell }) => (
      <Box textAlign="center">
        <Tooltip title={<Typography sx={{ color: '#fff' }}>{cell.getValue()}</Typography>} sx={{ mt: 1 }}>
          <IconButton sx={{ m: 0 }}>
            <HelpOutlineIcon />
          </IconButton>
        </Tooltip>
      </Box>
    ),
  },
];


// <Typography color="inherit" sx={{ fontWeight: 600, fontSize: { xs: ".7rem", md: ".8rem" } }}>
//         {cell.getValue()}
//       </Typography>


export const COLUMNSOPERACIONESADHERIDAS = (tema) => [
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>GARANTÍA</Typography>,
    accessorKey: "garantia",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>SERIE</Typography>,
    accessorKey: "serie",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>ESTADO</Typography>,
    accessorKey: "estado",
    Cell: ({ cell }) => {
      switch (cell.getValue()) {
        case 100000000:
          return (
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "#ffeb3b",
                py: 1,
                px: 2,
                width: { md: "9vw", xs: "24vw" },
              }}
            >
              <Typography
                variant="body2"
                color="#000"
                sx={{
                  textAlign: "center",
                  fontWeight: 600,
                  fontSize: { xs: ".7rem", md: ".8rem" },
                }}
              >
                Pendiente de Confirmación
              </Typography>
            </Paper>
          );
        case 1:
          return (
            <Chip label="Adherida" sx={{
              bgcolor: tema.palette.mode === "dark" ? 'rgba(87, 202, 34, 0.2)' : 'rgb(87, 202, 34, 0.8)',
              color: tema.palette.mode === "dark" ? 'rgb(87, 202, 34)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
          );
        case 100000001:
          return (
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "#b91400",
                py: 1,
                px: 2,
                width: { md: "8vw", xs: "20vw" },
              }}
            >
              <Typography
                variant="body2"
                color="#fff"
                sx={{
                  textAlign: "center",
                  fontWeight: 600,
                  fontSize: { xs: ".7rem", md: ".8rem" },
                }}
              >
                Rechazada
              </Typography>
            </Paper>
          );
        default:
          return "---";
      }
    }
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>DIVISA</Typography>,
    accessorKey: "divisa",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>MONTO</Typography>,
    accessorKey: "monto",
    Cell: ({ cell }) => (
      <Typography color="primary" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {dollarUS.format(cell.getValue())}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>PORCENTAJE</Typography>,
    accessorKey: "porcentaje",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        % {cell.getValue()}
      </Typography>
    ),
  },
];