import React, { useContext } from 'react'
import { Box, Typography, Button, Paper, Grid, Tooltip, useTheme } from '@mui/material';
import Modal from '@mui/material/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import TextField from '@mui/material/TextField';
import { cargarLibrador } from '../../Redux/Indicadores';
import TokenContext from '../../context/TokenContext';

const ModalSociosYlibradores = ({ open, handleClose, tablaActiva, indicadorid }) => {
  const dispatch = useDispatch()
  const token = useContext(TokenContext)
  const crearSocioyLibrador = useSelector(store => store.indicadores.crearSocioyLibrador)
  const theme = useTheme();
  const schema = yup.object().shape({
    socio: yup.string().required('Debe indicar el socio'),
    porcentaje: yup.number()
      .min(1, 'El porcentaje debe ser mayor a cero')
      .max(100, 'El porcentaje no puede ser mayor al 100%')
      .required('Debe indicar un porcentaje'),
    fecha: yup.string().required('Debe indicar la fecha')
  });

  const { register, handleSubmit, formState: { errors }, reset } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = (data) => {
    dispatch((dispatch) => {
      dispatch(cargarLibrador(tablaActiva, data, indicadorid, token)).then(() => {
      });
    });
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    // bgcolor: '#24292d',
    backgroundColor: theme.palette.background.default,
    border: '2px solid #000',
    boxShadow: 24,
  };

  React.useEffect(() => {
    if (crearSocioyLibrador === "exito") {
      cerrarModal()
    }
  }, [crearSocioyLibrador])

  const cerrarModal = () => {
    reset()
    handleClose()
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Paper elevation={4} sx={{ py: 1, backgroundColor: theme.palette.background.default }} >
          <Box sx={{ display: "flex", justifyContent: "space-between", px: 1 }}>
            <Typography id="alert-dialog-title" sx={{ fontSize: "1.1rem", p: 1, }}>
              Nuevo Indicador mensual socio y libradores
            </Typography>
            <Tooltip title={<Typography sx={{ color: '#fff' }}>Cerrar</Typography>} >
              <IconButton
                edge="end"
                color="warning"
                onClick={handleClose}
                aria-label="close"
                sx={{ width: 40, height: 40, mr: 1, mt: 1 }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Box
            component="div"
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              px: 2,
            }}
          >


            <form onSubmit={handleSubmit(onSubmitHandler)}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="date"
                    id="fecha"
                    name="fecha"
                    // label="Fecha"
                    {...register("fecha")}
                    helperText={errors.fecha?.message}
                    error={errors.fecha}
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    type="text"
                    id="socio"
                    name="socio"
                    label="Socio"
                    {...register("socio")}
                    helperText={errors.socio?.message}
                    error={errors.socio}
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Porcentaje"
                    type="number"
                    id="porcentaje"
                    name="porcentaje"
                    {...register("porcentaje")}
                    helperText={errors.porcentaje?.message}
                    error={errors.porcentaje}
                  // sx={{ input: { color: '#fff' }, fieldset: { borderColor: "#fff" } }}
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  />
                </Grid>
                <Grid sx={{ p: 2 }}>
                  <Button
                    type="submit"
                    sx={{ mt: 1, mb: 1, backgroundColor: "#ad387f", color: "#FFFFFF" }}
                    fullWidth
                    variant="contained"
                  >
                    Cargar socio
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      </Box >
    </Modal >
  )
}

export default ModalSociosYlibradores