import React from "react";
import { Paper, Typography } from "@mui/material"
import SeleccionarFilaRelacion from "../Components/seleccionarFila/SeleccionarFilaRelacion"
import Chip from '@mui/material/Chip';

function obtenerChip(data) {

    switch (data) {
        case "En Cartera":
            return <Chip label="En Cartera" sx={{ bgcolor: '#ff6d00' }} />
        case "En Gestión":
            return <Chip label="En Gestión" sx={{ bgcolor: '#ffab00' }} />
        case "Vigente":
            return <Chip label="Vigente" sx={{ bgcolor: '#4caf50' }} />
        case "Vencida":
            return <Chip label="Vencida" sx={{ bgcolor: '#f44336' }} />
        case "Afrontada":
            return <Chip label="Afrontada" sx={{ bgcolor: '#4caf50' }} />
        case "Honrada":
            return <Chip label="Honrada" sx={{ bgcolor: '#1b5e20' }} />
        case "Anulada":
            return <Chip label="Anulada" sx={{ bgcolor: '#d50000' }} />
        default:
            break;
    }
}

export const COLUMNRELACIONES = [
    {
        header: "Cuenta Vinculada",
        accessorKey: "new_cuentacontactovinculado",
        Cell: ({ cell }) => (
            <Typography sx={{ fontSize: { xs: ".7rem", md: ".8rem" } }}>
                {cell.getValue()}
            </Typography>
        ),
    },
    {
        header: "Tipo de Relación",
        accessorKey: "new_tipoderelacion",
        Cell: ({ cell }) => (
            <Typography sx={{ fontSize: { xs: ".7rem", md: ".8rem" } }}>
                {cell.getValue()}
            </Typography>
        ),
    },
    {
        header: '',
        minSize: 60, //min size enforced during resizing
        maxSize: 80, //max size enforced during resizing
        accessorKey: 'valor',
        Cell: ({ cell }) => {
            return (
                <SeleccionarFilaRelacion value={cell.getValue()} />
            )
        }
    }
]

export const COLUMNASBENEFICIARIOS = [
    {
        header: 'Contacto vinculado',
        accessorKey: 'new_name',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Porcentaje',
        accessorKey: 'new_porcentajebeneficiario',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
]

export const COLUMNASACCIONISTASOCIOS = [
    {
        header: 'Cuenta/Contacto vinculado',
        accessorKey: '_new_cuentacontactovinculado_value',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: '% de Participación',
        accessorKey: 'new_porcentajedeparticipacion',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
]

export const COLUMNASREPRESENTANTES = [
    {
        header: 'Contacto vinculado',
        accessorKey: '_new_cuentacontactovinculado_value',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
]

export const COLUMNASMIEMBROS = [
    {
        header: 'Contacto vinculado',
        accessorKey: 'new_name',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Cargo',
        accessorKey: 'new_cargo',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
]

export const COLUMNASGLOBAL = [
    {
        accessorKey: 'id'
    },
    {
        header: 'Tipo de relación',
        accessorKey: 'new_tipoderelacion',
        Cell: ({ cell }) => <strong>{obtenerChip(cell.getValue())}</strong>
    },
    {
        header: 'Vinculado con',
        accessorKey: '_new_cuentacontactovinculado_value',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
]