import axios from 'axios'
import { Entidad, UrlApiDynamics, UrlApi } from '../Keys'
import { toast } from 'react-toastify'
import moment from 'moment/moment'

//constantes
const dataInicial = {
    loading: false,
    loadingEstructura: false,
    loadingIndicadores: false,
    loadingLibradores: false,
    loadingAtomizacion: false,
    loadingAtomizacion2: false,
    sgrs: [],
    estructura: [],
    indicadores: [],
    libradores: [],
    atomizacion: [],
    tipoSocio: "",
    sgrDocu: [],
    documentacion: [],
    resultadoModif: "",
    resultadoModifSGR: "",
    resultado: "",
    sgrDocuResultado: "",
    atomizacion2: [],
    crearSocioyLibrador: "",
    crearIndicadorM: "",
    modificacionIndicador: "",
    rol: 0
}

const LOADING = 'LOADING'
const ERROR = 'ERROR'
const ESTRUCTURA_EXITO = 'ESTRUCTURA_EXITO'
const LOADING_ESTRUCTURA = 'LOADING_ESTRUCTURA'
const INDICADORES_EXITO = 'INDICADORES_EXITO'
const LOADING_INDICADORES = 'LOADING_INDICADORES'
const LIBRADORES = 'LIBRADORES'
const LOADING_LIBRADORES = 'LOADING_LIBRADORES'
const ATOMIZACION = 'ATOMIZACION'
const ATOMIZACION_2 = 'ATOMIZACION_2'
const LOADING_ATOMIZACION = 'LOADING_ATOMIZACION'
const SGRS_EXITO = 'SGRS_EXITO'
const SGR_DOCU_EXITO = 'SGR_DOCU_EXITO'
const ESTRUCTURA_CREADA_EXITO = 'ESTRUCTURA_CREADA_EXITO'
const SGR_ACTUA_EXITO = 'SGR_ACTUA_EXITO'
const SGR_DOCUCARGAR_EXITO = 'SGR_DOCUCARGAR_EXITO'
const DOCU_EXITO = 'DOCU_EXITO'
const ESTRUCTURA_EDITADA_EXITO = 'ESTRUCTURA_EDITADA_EXITO'
const CARGA_INDICADOR_EXITO = 'CARGA_INDICADOR_EXITO'
const LOADING_CARGA_INDICADOR = 'LOADING_CARGA_INDICADOR'
const MODIFICAR_INDICADOR_EXITO = 'MODIFICAR_INDICADOR_EXITO'
const CARGA_SYL_EXITO = 'CARGA_SYL_EXITO'
const LOADING_CARGA_SYL = 'LOADING_CARGA_SYL'
const LOADING_ESTRUCTURA_CREAR = 'LOADING_ESTRUCTURA_CREAR'
const ESTRUCTURA_CREADA_ERROR = 'ESTRUCTURA_CREADA_ERROR'

const ToastError = (msj) => {
    toast.error(msj, {
        theme: "dark",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};
const ToastSuccess = (msj) => {
    toast.success(msj, {
        theme: "dark",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })
}
const LOADING_ATOMIZACION_2 = 'LOADING_ATOMIZACION_2'


export default function indicadoresReducer(state = dataInicial, action) {
    switch (action.type) {
        case LOADING:
            return { ...state, loading: action.loading }
        case INDICADORES_EXITO:
            return { ...state, indicadores: action.payload, loadingIndicadores: action.loadingIndicadores }
        case LOADING_INDICADORES:
            return { ...state, loadingIndicadores: action.loadingIndicadores }
        case ESTRUCTURA_EXITO:
            return { ...state, estructura: action.payload, loadingEstructura: action.loadingEstructura }
        case LOADING_ESTRUCTURA:
            return { ...state, loadingEstructura: action.loadingEstructura }
        case LIBRADORES:
            return { ...state, libradores: action.payload, loadingLibradores: action.loadingLibradores }
        case LOADING_LIBRADORES:
            return { ...state, loadingLibradores: action.loadingLibradores }
        case ATOMIZACION:
            return { ...state, atomizacion: action.payload, loadingAtomizacion: action.loadingAtomizacion }
        case LOADING_ATOMIZACION:
            return { ...state, loadingAtomizacion: action.loadingAtomizacion }
        case SGRS_EXITO:
            return { ...state, sgrs: action.payload, loading: action.loading, resultado: action.resultado }
        case SGR_DOCU_EXITO:
            return { ...state, sgrDocu: action.payload, loading: action.loading }
        case DOCU_EXITO:
            return { ...state, documentacion: action.payload, loading: action.loading }
        case ESTRUCTURA_CREADA_EXITO:
            return { ...state, resultadoModif: action.resultado, rol: action.rol }
        case LOADING_ESTRUCTURA_CREAR:
            return { ...state, resultadoModif: action.resultado, rol: 0 }
        case ESTRUCTURA_CREADA_ERROR:
            return { ...state, resultadoModif: action.resultado, rol: 0 }
        case ESTRUCTURA_EDITADA_EXITO:
            return { ...state, resultadoModif: action.resultado }
        case SGR_ACTUA_EXITO:
            return { ...state, resultadoModifSGR: action.resultado }
        case SGR_DOCUCARGAR_EXITO:
            return { ...state, sgrDocuResultado: action.resultado }
        case ATOMIZACION_2:
            return { ...state, atomizacion2: action.payload, loadingAtomizacion2: action.loadingAtomizacion2 }
        case LOADING_ATOMIZACION_2:
            return { ...state, loadingAtomizacion2: action.loadingAtomizacion2 }
        case CARGA_INDICADOR_EXITO:
            return { ...state, crearIndicadorM: action.crearIndicadorM }
        case LOADING_CARGA_INDICADOR:
            return { ...state, crearIndicadorM: action.crearIndicadorM }
        case MODIFICAR_INDICADOR_EXITO:
            return { ...state, modificacionIndicador: action.modificacionIndicador }
        case LOADING_CARGA_SYL:
            return { ...state, crearSocioyLibrador: action.crearSocioyLibrador }
        case CARGA_SYL_EXITO:
            return { ...state, crearSocioyLibrador: action.crearSocioyLibrador, tipoSocio: action.tipoSocio }
        default:
            return { ...state }
    }
}

//Actions


export const obtenerEstructuraSgr = (token, id) => async (dispatch) => {
    dispatch({
        type: LOADING_ESTRUCTURA,
        loadingEstructura: true
    })

    var entidad = "new_estructurasgrs"
    var fetch = "<fetch  mapping='logical' distinct='false'>" +
        "<entity name='new_estructurasgr'>" +
        "<attribute name='new_contacto' />" +
        "<attribute name='new_estructurasgrid' />" +
        "<attribute name='new_rol' />" +
        "<attribute name='new_cargo' />" +
        "<attribute name='new_name' />" +
        "<attribute name='createdon' />" +
        " <attribute name='new_porcentaje' />" +
        "<attribute name='owningbusinessunit' />" +
        "<attribute name='new_sgr' />" +
        "<attribute name='statuscode' />" +
        "<attribute name='ownerid' />" +
        "<attribute name='modifiedonbehalfby' />" +
        "<attribute name='modifiedby' />" +
        "<attribute name='modifiedon' />" +
        "<attribute name='overriddencreatedon' />" +
        "<attribute name='createdonbehalfby' />" +
        "<attribute name='createdby' />" +
        "<attribute name='new_numerodedocumento' />" +
        "<attribute name='new_correoelectronico' />" +
        "<order attribute='new_contacto' descending='false' />" +
        "<filter type='and'>" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "<condition attribute='new_rol' operator='in'>" +
        "<value>100000004</value>" +
        "<value>100000003</value>" +
        "<value>100000002</value>" +
        "<value>100000001</value>" +
        "<value>100000000</value>" +
        "</condition>" +
        "<condition attribute='new_sgr' operator='eq' uitype='new_sgr' value='" + id + "' />" +
        "</filter>" +
        "<link-entity name='contact' from='contactid' to='new_contacto' visible='false' link-type='outer' alias='contact'>" +
        "<attribute name='new_cuitcuil' />" +
        "<attribute name='emailaddress1' />" +
        "<attribute name='new_cargofuncinjerarquayenteendondetrabaja' />" +
        "</link-entity>" +
        "</entity>" +
        "</fetch>";

    try {
        await axios.post(`${UrlApi}api/consultafetch`,
            {
                "entidad": entidad,
                "fetch": fetch
            },
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            .then((response) => {
                dispatch({
                    type: ESTRUCTURA_EXITO,
                    payload: response.data,
                    loadingEstructura: false
                })
            })
            .catch(err => {
                console.log(err)
                dispatch({
                    type: ERROR,
                    loadingEstructura: false
                })
            })
    }
    catch (error) {
        dispatch({
            type: ERROR,
            loadingEstructura: false
        })
    }
}

export const obtenerIndicadoresSgr = (id, token) => async (dispatch) => {
    dispatch({
        type: LOADING_INDICADORES,
        loadingIndicadores: true
    })

    var entidad = "new_indicadoresmensualessgrs"
    var fetch = "<fetch mapping='logical' distinct='false'>" +
        "<entity name='new_indicadoresmensualessgr'>" +
        "<attribute name='new_fechahasta' />" +
        "<attribute name='new_saldonetodegarantiasvigentes' />" +
        "<attribute name='new_saldodegarantasvigentesmercadodecapitales' />" +
        "<attribute name='new_fondoderiesgointegrado' />" +
        "<attribute name='new_fondoderiesgodisponible' />" +
        "<attribute name='new_fondoderiesgocontingente' />" +
        "<attribute name='new_fondoderiesgoavalordemercado' />" +
        "<attribute name='new_solvencia' />" +
        "<attribute name='new_indicadoresmensualessgrid' />" +
        "<attribute name='new_pagareelectronicomontomaximo' />" +
        "<attribute name='new_pagareelectronicomontoencustodia' />" +
        "<attribute name='new_pagareelectronicomontodisponible' />" +
        "<attribute name='new_pagareelectronicocantidadinstrumentos' />" +
        "<attribute name='new_porcentajeriesgoterceros' />" +
        "<attribute name='new_porcentajeriesgopropio' />" +
        "<attribute name='new_porcentajeprenda' />" +
        "<attribute name='new_porcentajeotras' />" +
        "<attribute name='new_porcentajehipoteca' />" +
        "<attribute name='new_porcentajefianza' />" +
        "<attribute name='new_pagareelectronicousdmontomaximo' />" +
        "<attribute name='new_pagareelectronicousdmontoencustodia' />" +
        "<attribute name='new_pagareelectronicousdmontodisponible' />" +
        "<attribute name='new_pagareelectronicousdcantidadinstrumentos' />" +
        "<attribute name='new_chequeelectronicomontomaximo' />" +
        "<attribute name='new_chequeelectronicomontoencustodia' />" +
        "<attribute name='new_chequeelectronicomontodisponible' />" +
        "<attribute name='new_chequeelectronicocantidadinstrumentos' />" +
        "<attribute name='new_chequedepagodiferidomontomaximo' />" +
        "<attribute name='new_chequedepagodiferidomontoencustodia' />" +
        "<attribute name='new_chequedepagodiferidomontodisponible' />" +
        "<attribute name='new_chequedepagodiferidocantidadinstrumentos' />" +
        "<attribute name='new_porcentajecumplimiento96hschpd' />" +
        "<attribute name='new_porcentajecumplimiento72hschpd' />" +
        "<attribute name='new_porcentajecumplimiento48hschpd' />" +
        "<attribute name='new_porcentajecumplimientode96hschpd' />" +
        "<attribute name='new_porcentajecumplimientode96hspagare' />" +
        "<attribute name='new_porcentajecumplimiento96hspagare' />" +
        "<attribute name='new_porcentajecumplimiento72hspagare' />" +
        "<attribute name='new_porcentajecumplimiento48hspagare' />" +
        "<attribute name='new_porcentajeincumplimiento96hspagare' />" +
        "<attribute name='new_porcentajeincumplimiento96hschpd' />" +
        "<attribute name='new_porcentajeincumplimiento72hspagare' />" +
        "<attribute name='new_porcentajeincumplimiento72hschpd' />" +
        "<attribute name='new_porcentajeincumplimiento48hspagare' />" +
        "<attribute name='new_porcentajeincumplimiento48hschpd' />" +
        "<attribute name='new_porcentajeincumplimientode96hspagare' />" +
        "<attribute name='new_porcentajeincumplimientode96hschpd' />" +
        "<attribute name='new_garantasvigentesrvenpymesensituacion5' />" +
        "<attribute name='new_garantasvigentesrvenpymesensituacion4' />" +
        "<attribute name='new_garantasvigentesrvenpymesensituacion3' />" +
        "<attribute name='new_garantasvigentesrvenpymesensituacion2' />" +
        "<attribute name='new_garantasvigentesrvenpymesensituacion1' />" +
        "<attribute name='new_valoresdecortoplazocnv' />" +
        "<attribute name='new_pagarbursatilcnv' />" +
        "<attribute name='new_obligacionesnegociablescnv' />" +
        "<attribute name='new_chequedepagodiferidocnv' />" +
        "<attribute name='new_mercadodecapitalescnv' />" +
        "<attribute name='new_garantastecnicascnv' />" +
        "<attribute name='new_garantiascomercialescnv' />" +
        "<attribute name='new_entidadesfinancierascnv' />" +
        "<attribute name='createdon' />" +
        "<order attribute='createdon' descending='true' />" +
        "<filter type='and'>" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "<condition attribute='new_sgr' operator='eq' uitype='new_sgr' value='" + id + "' />" +
        "</filter>" +
        "</entity>" +
        "</fetch>";

    try {
        await axios.post(`${UrlApi}api/consultafetch`,
            {
                entidad: entidad,
                fetch: fetch,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
            .then((response) => {
                dispatch({
                    type: INDICADORES_EXITO,
                    payload: response.data,
                    loadingIndicadores: false
                })
            })
            .catch((err) => {
                console.log(err);
            });

    }
    catch (error) {
        dispatch({
            type: ERROR,
            loadingIndicadores: false
        })
    }
}

export const obtenerLibradores = (id) => async (dispatch) => {
    dispatch({
        type: LOADING_LIBRADORES,
        loadingLibradores: true
    })

    var entidad = "new_indicadormensualsocioylibradoreses";
    var fetch = "<fetch mapping='logical' distinct='false'>" +
        "<entity name='new_indicadormensualsocioylibradores'>" +
        "<attribute name='new_fecha' />" +
        "<attribute name='new_librador' />" +
        "<attribute name='new_porcentajelibrador' />" +
        "<attribute name='new_indicadormensualsocioylibradoresid' />" +
        "<order attribute='new_porcentajelibrador' descending='true' />" +
        "<filter type='and'>" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "<condition attribute='new_librador' operator='not-null' />" +
        "</filter>" +
        "<link-entity name='new_indicadoresmensualessgr' from='new_indicadoresmensualessgrid' to='new_indicadormensualsgr' link-type='inner' alias='ab'>" +
        "<filter type='and'>" +
        "<condition attribute='new_sgr' operator='eq' uitype='new_sgr' value='" + id + "' />" +
        "</filter>" +
        "</link-entity>" +
        "</entity>" +
        "</fetch>";

    try {
        const response = await axios.get(`${UrlApiDynamics}ConsultaFetch?Entidad=${entidad}&fetch=${fetch}&cuit=${Entidad}`)
        dispatch({
            type: LIBRADORES,
            payload: response.data,
            loadingLibradores: false
        })
    } catch (error) {
        dispatch({
            type: ERROR,
            loadingLibradores: false
        })

    }
}

export const obtenerIndicadoresAtomizacion = (id) => async (dispatch) => {
    dispatch({
        type: LOADING_ATOMIZACION,
        loadingAtomizacion: true
    })

    var entidad = 'new_indicadormensualsocioylibradoreses';
    var fetch = "<fetch  mapping='logical' distinct='false'>" +
        "<entity name='new_indicadormensualsocioylibradores'>" +
        "<attribute name='new_fecha' />" +
        "<attribute name='new_socioparticipetercero' />" +
        "<attribute name='new_porcentajesocioparticipetercero' />" +
        "<attribute name='new_indicadormensualsocioylibradoresid' />" +
        "<order attribute='new_porcentajesocioparticipetercero' descending='true' />" +
        "<filter type='and'>" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "<condition attribute='new_socioparticipetercero' operator='not-null' />" +
        "</filter>" +
        "<link-entity name='new_indicadoresmensualessgr' from='new_indicadoresmensualessgrid' to='new_indicadormensualsgr' link-type='inner' alias='ac'>" +
        "<filter type='and'>" +
        "<condition attribute='new_sgr' operator='eq' uitype='new_sgr' value='" + id + "' />" +
        "</filter>" +
        "</link-entity>" +
        "</entity>" +
        "</fetch>";


    try {
        const response = await axios.get(`${UrlApiDynamics}ConsultaFetch?Entidad=${entidad}&fetch=${fetch}&cuit=${Entidad}`)
        dispatch({
            type: ATOMIZACION,
            payload: response.data,
            loadingAtomizacion: false
        })
    } catch (error) {
        dispatch({
            type: ERROR,
            loadingAtomizacion: false
        })
    }
}

export const obtenerSgrs = (token, sgrid) => async (dispatch) => {
    dispatch({
        type: LOADING,
        loading: true
    })

    var entidad = "new_sgrs"
    var fetch = "<fetch  mapping='logical' distinct='false'>" +
        "<entity name='new_sgr'>" +
        "<attribute name='new_sgrid' />" +
        "<attribute name='new_name' />" +
        "<attribute name='createdon' />" +
        "<attribute name='new_nombredelacalificadora' />" +
        "<attribute name='new_fechaultimacalificacion' />" +
        "<attribute name='new_fechainscripcinantebcra' />" +
        "<attribute name='new_fechadeiniciodeactividades' />" +
        "<attribute name='new_fechadeasociacinencasfog' />" +
        "<attribute name='statecode' />" +
        "<attribute name='new_cuit' />" +
        "<attribute name='new_calificacin' />" +
        "<attribute name='new_responsable' />" +
        "<attribute name='new_cuitcalificadora' />" +
        "<order attribute='new_name' descending='false' />" +
        "<filter type='and'>" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "<condition attribute='new_sgrid' operator='eq' value='" + sgrid + "' />" +
        "</filter>" +
        "</entity>" +
        "</fetch>";

    try {
        await axios.post(`${UrlApi}api/consultafetch`,
            {
                "entidad": entidad,
                "fetch": fetch
            },
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            .then((response) => {
                localStorage.setItem("SGR Datos", JSON.stringify(response.data))
                dispatch({
                    type: SGRS_EXITO,
                    payload: response.data,
                    loading: false,
                    resultado: "EXITO"
                })
            })
            .catch(err => {
                console.log(err)
                dispatch({
                    type: ERROR,
                    loading: false
                })
            })
    }
    catch (error) {
        dispatch({
            type: ERROR,
            loading: false
        })
    }
}

export const obtenerDocumentacion = (token) => async (dispatch) => {
    dispatch({
        type: LOADING,
        loading: true
    })

    var entidad = "new_documentacions"
    var fetch = "<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='false'>" +
        "<entity name='new_documentacion'>" +
        "<attribute name='new_documentacionid' />" +
        "<attribute name='new_name' />" +
        "<attribute name='createdon' />" +
        "<order attribute='new_name' descending='false' />" +
        "<filter type='and'>" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "<condition attribute='statuscode' operator='eq' value='1' />" +
        "<condition attribute='new_mostrarportalsgr' operator='eq' value='1' />" +
        "</filter>" +
        "</entity>" +
        "</fetch>";

    try {
        await axios.post(`${UrlApi}api/consultafetch`,
            {
                "entidad": entidad,
                "fetch": fetch
            },
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            .then((response) => {
                dispatch({
                    type: DOCU_EXITO,
                    payload: response.data,
                    loading: false
                })
            })
            .catch(err => {
                console.log(err)
                dispatch({
                    type: ERROR,
                    loading: false
                })
            })
    }
    catch (error) {
        dispatch({
            type: ERROR,
            loading: false
        })
    }
}

export const obtenerDocumentacionSgrs = (token, sgrid) => async (dispatch) => {
    dispatch({
        type: LOADING,
        loading: true
    })

    var entidad = "new_documentacionporsgrs"
    var fetch = "<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='false'>" +
        "<entity name='new_documentacionporsgr'>" +
        "<attribute name='new_documentacionporsgrid' />" +
        "<attribute name='new_name' />" +
        "<attribute name='createdon' />" +
        "<attribute name='new_sgr' />" +
        "<attribute name='new_fechadevencimiento' />" +
        "<attribute name='modifiedon' />" +
        "<attribute name='new_documentacion' />" +
        "<order attribute='new_name' descending='false' />" +
        "<filter type='and'>" +
        "<condition attribute='new_sgr' operator='eq' uitype='new_sgr' value='" + sgrid + "' />" +
        "</filter>" +
        "</entity>" +
        "</fetch>";

    try {
        await axios.post(`${UrlApi}api/consultafetch`,
            {
                "entidad": entidad,
                "fetch": fetch
            },
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            .then((response) => {
                dispatch({
                    type: SGR_DOCU_EXITO,
                    payload: response.data,
                    loading: false
                })
            })
            .catch(err => {
                console.log(err)
                dispatch({
                    type: ERROR,
                    loading: false
                })
            })
    }
    catch (error) {
        dispatch({
            type: ERROR,
            loading: false
        })
    }
}

export const agregarEstructuraSGR = (token, sgr, rol = 0, cargo, nombre, new_numerodedocumento, new_porcentaje, new_correoelectronico) => async (dispatch) => {
    dispatch({
        type: LOADING_ESTRUCTURA_CREAR,
        resultado: 'LOADING'
    })

    if (token) {
        try {
            debugger
            await axios.post(`${UrlApi}api/portalcasfog/estructurasgr`,
                {
                    "new_sgr": sgr,
                    "new_rol": rol,
                    "new_cargo": cargo,
                    "new_name": nombre,
                    "new_numerodedocumento": new_numerodedocumento,
                    "new_porcentaje": new_porcentaje > 0 ? parseInt(new_porcentaje) : 0,
                    "new_correoelectronico": new_correoelectronico
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            ).then((response) => {
                dispatch({
                    type: ESTRUCTURA_CREADA_EXITO,
                    rol: rol,
                    resultado: "EXITO",
                })
                setTimeout(() => {
                    dispatch({
                        type: ESTRUCTURA_CREADA_EXITO,
                        rol: 0,
                        resultado: "",
                    }, 3000)
                })
                ToastSuccess('La operación fue exitosa')
            }
            )
                .catch((err) => {
                    console.log(err)
                    dispatch({
                        type: ESTRUCTURA_CREADA_ERROR,
                        resultado: "ERROR",
                    })
                    ToastError('No pudimos procesar la operación, por favor, vuelva a intentarlo...')
                })

        } catch (error) {
            dispatch({
                type: ESTRUCTURA_CREADA_ERROR,
                resultado: 'ERROR'
            })
            ToastError('No pudimos procesar la operación, por favor, vuelva a intentarlo...')
        }
    }
}

export const editarEstructuraSGR = (token, sgr, id, rol = 0, cargo, nombre, new_numerodedocumento, new_porcentaje, new_correoelectronico) => async (dispatch) => {
    dispatch({
        type: ESTRUCTURA_EDITADA_EXITO,
        resultado: 'LOADING'
    })

    if (token) {
        try {
            await axios.put(`${UrlApi}api/portalcasfog/estructurasgr`,
                {
                    "new_estructurasgrid": id,
                    "new_sgr": sgr,
                    "new_rol": rol,
                    "new_cargo": cargo,
                    "new_name": nombre,
                    "new_numerodedocumento": new_numerodedocumento,
                    "new_porcentaje": new_porcentaje > 0 ? parseInt(new_porcentaje) : 0,
                    "new_correoelectronico": new_correoelectronico
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            ).then((response) => {
                console.log("response", response)
                dispatch({
                    type: ESTRUCTURA_EDITADA_EXITO,
                    resultado: "EXITO",
                })
                ToastSuccess('La operación fue exitosa')
                setTimeout(() => {
                    dispatch({
                        type: ESTRUCTURA_EDITADA_EXITO,
                        resultado: ''
                    })
                }, 4000);
            }
            )
                .catch((err) => {
                    console.log(err)
                    dispatch({
                        type: ESTRUCTURA_EDITADA_EXITO,
                        resultado: "ERROR",
                    })

                    ToastError('No pudimos procesar la operación, por favor, vuelva a intentarlo...')
                })

        } catch (error) {
            dispatch({
                type: ESTRUCTURA_EDITADA_EXITO,
                resultado: 'ERROR'
            })
            ToastError('No pudimos procesar la operación, por favor, vuelva a intentarlo...')
        }
    }
}

export const actualizarFichaTecnica = (token, sgr, fechaInicio, fechaAsociacionCafsfog, fechaIncBC, nombreCalificadora, calificacion, fechaUltimaActuliz, new_cuitcalificadora) => async (dispatch) => {
    dispatch({
        type: SGR_ACTUA_EXITO,
        resultado: 'LOADING'
    })

    if (token) {
        try {
            debugger
            await axios.put(`${UrlApi}api/portalcasfog/sgr`,
                {
                    "new_sgrid": sgr,
                    "new_fechadeiniciodeactividades": fechaInicio,
                    "new_fechadeasociacinencasfog": fechaAsociacionCafsfog,
                    "new_fechainscripcinantebcra": fechaIncBC,
                    "new_nombredelacalificadora": nombreCalificadora,
                    "new_calificacin": calificacion,
                    "new_fechaultimacalificacion": fechaUltimaActuliz,
                    "new_cuitcalificadora": new_cuitcalificadora
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    }
                }
            ).then((response) => {
                console.log("response", response)
                dispatch({
                    type: SGR_ACTUA_EXITO,
                    resultado: "EXITO",
                })
                ToastSuccess('La operación fue exitosa')
                setTimeout(() => {
                    dispatch({
                        type: SGR_ACTUA_EXITO,
                        resultado: ''
                    })
                }, 4000);
            }
            )
                .catch((err) => {
                    console.log(err)
                    dispatch({
                        type: SGR_ACTUA_EXITO,
                        resultado: "ERROR",
                    })

                    ToastError('No pudimos procesar la operación, por favor, vuelva a intentarlo...')
                })

        } catch (error) {
            dispatch({
                type: SGR_ACTUA_EXITO,
                resultado: 'ERROR'
            })
            ToastError('No pudimos procesar la operación, por favor, vuelva a intentarlo...')
        }
    }
}

export const crearDocumentacion = (token, sgr, documentacion_id, fechaVencimiento, formData) => async (dispatch) => {
    dispatch({
        type: SGR_DOCUCARGAR_EXITO,
        resultado: 'LOADING'
    })
    let documentacionSGR_id
    if (token) {
        try {
            const documentacionSGR = () => new Promise(async (resolve, reject) => {
                await crearDocumentacionSGR(sgr, documentacion_id, fechaVencimiento, token)
                    .then(resp => {
                        if (resp.data != null) {
                            documentacionSGR_id = resp.data
                        }
                    })
                    .catch(e => {
                        reject(e)
                    })
                if (documentacionSGR_id != '') {
                    await enviarArchivos(formData, documentacionSGR_id, token)
                        .then(resp => {
                            resolve(resp.data)
                        })
                        .catch(e => {
                            reject(e)
                        })
                }
                dispatch({
                    type: SGR_DOCUCARGAR_EXITO,
                    resultado: 'EXITO'
                })
                resolve("Documentacion por SGR creada")
            })

            const response = await toast.promise(
                documentacionSGR,
                {
                    pending: 'Procesando...',
                    success: 'Documentación creada!',
                    error: {
                        render({ data }) {
                            dispatch({
                                type: SGR_DOCUCARGAR_EXITO,
                                resultado: 'ERROR'
                            })
                            return `${data}`
                        }
                    }
                },
                {
                    theme: "dark",
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            )
        } catch (error) {
            dispatch({
                type: SGR_DOCUCARGAR_EXITO,
                resultado: 'ERROR'
            })
        }
    }
}

const crearDocumentacionSGR = (sgr, documentacion_id, fechaVencimiento, token) => new Promise(async (resolve, reject) => {
    axios.post(`${UrlApi}api/socioparticipe/documentacionporsgr`,
        {
            "sgr_id": sgr,
            "documentacion_id": documentacion_id,
            "fechaVencimiento": fechaVencimiento,
        },
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        }
    )
        .then((data) => { return resolve(data) })
        .catch((error) => { return reject(error) })
})

const enviarArchivos = (archivos, documentacionSGR, token) => new Promise((resolve, reject) => {
    const configDocumentos = {
        headers: {
            'Authorization': `Bearer ${token}`,
            'content-type': 'multipart/form-data',
        },
    };

    axios.post(`${UrlApi}api/socioparticipe/notadocumentacionporsgr?documentacion_id=${documentacionSGR}`, archivos, configDocumentos)
        .then((data) => { return resolve(data) })
        .catch((error) => { return reject(error) })
})

export const obtenerIndicadoresAtomizacionProtectores = (id) => async (dispatch) => {
    dispatch({
        type: LOADING_ATOMIZACION_2,
        loadingAtomizacion2: true
    })

    var entidad = 'new_indicadormensualsocioylibradoreses';
    var fetch = "<fetch mapping='logical' distinct='false'>" +
        "<entity name='new_indicadormensualsocioylibradores'>" +
        "<attribute name='new_fecha' />" +
        "<attribute name='new_socioprotector' />" +
        "<attribute name='new_porcentajesocioprotector' />" +
        "<attribute name='new_indicadormensualsocioylibradoresid' />" +
        "<order attribute='new_fecha' descending='false' />" +
        "<filter type='and'>" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "<condition attribute='new_socioprotector' operator='not-null' />" +
        "</filter>" +
        "<link-entity name='new_indicadoresmensualessgr' from='new_indicadoresmensualessgrid' to='new_indicadormensualsgr' link-type='inner' alias='ah'>" +
        "<filter type='and'>" +
        "<condition attribute='new_sgr' operator='eq' uitype='new_sgr' value='" + id + "' />" +
        "</filter>" +
        "</link-entity>" +
        "</entity>" +
        "</fetch>";


    try {
        const response = await axios.get(`${UrlApiDynamics}ConsultaFetch?Entidad=${entidad}&fetch=${fetch}&cuit=${Entidad}`)
        dispatch({
            type: ATOMIZACION_2,
            payload: response.data,
            loadingAtomizacion2: false
        })
    } catch (error) {
        dispatch({
            type: ERROR,
            loadingAtomizacion2: false
        })
    }
}

export const cargarIndicador = (datos, id, token) => async (dispatch) => {
    dispatch({
        type: LOADING_CARGA_INDICADOR,
        crearIndicadorM: 'cargando'
    })
    var objetoIndicador = {
        "new_fechahasta": moment(datos.new_fechahasta).format('YYYY-MM-DD'),
        "new_sgr": id,
        "new_saldonetodegarantiasvigentes": parseInt(datos.new_saldonetodegarantiasvigentes),
        "new_solvencia": datos?.new_solvencia,
        "new_fondoderiesgointegrado": datos?.new_fondoderiesgointegrado,
        "new_fondoderiesgodisponible": datos?.new_fondoderiesgodisponible,
        "new_fondoderiesgocontingente": datos?.new_fondoderiesgocontingente,
        "new_fondoderiesgoavalordemercado": datos?.new_fondoderiesgoavalordemercado,
        "new_porcentajeriesgopropio": datos?.new_porcentajeriesgopropio,
        "new_porcentajeriesgoterceros": datos?.new_porcentajeriesgoterceros,
        "new_porcentajeprenda": datos?.new_porcentajeprenda,
        "new_porcentajehipoteca": datos?.new_porcentajehipoteca,
        "new_porcentajefianza": datos?.new_porcentajefianza,
        "new_porcentajeotras": datos?.new_porcentajeotras,
        "new_entidadesfinancierascnv": datos?.new_entidadesfinancierascnv == 0 ? '' : datos.new_entidadesfinancierascnv,
        "new_garantiascomercialescnv": datos?.new_garantiascomercialescnv,
        "new_garantastecnicascnv": datos?.new_garantastecnicascnv,
        "new_mercadodecapitalescnv": datos?.new_mercadodecapitalescnv,
        "new_chequedepagodiferidocnv": datos?.new_chequedepagodiferidocnv,
        "new_pagarbursatilcnv": datos?.new_pagarbursatilcnv,
        "new_valoresdecortoplazocnv": datos?.new_valoresdecortoplazocnv,
        "new_obligacionesnegociablescnv": datos?.new_obligacionesnegociablescnv,
        "new_garantasvigentesrvenpymesensituacion1": datos?.new_garantasvigentesrvenpymesensituacion1,
        "new_garantasvigentesrvenpymesensituacion2": datos?.new_garantasvigentesrvenpymesensituacion2,
        "new_garantasvigentesrvenpymesensituacion3": datos?.new_garantasvigentesrvenpymesensituacion3,
        "new_garantasvigentesrvenpymesensituacion4": datos?.new_garantasvigentesrvenpymesensituacion4,
        "new_garantasvigentesrvenpymesensituacion5": datos?.new_garantasvigentesrvenpymesensituacion5
    }
    debugger
    try {
        const crearIndicadorMensual = () => new Promise(async (resolve, reject) => {

            await axios.post(`${UrlApi}api/portalcasfog/indicadorsgr`,
                objetoIndicador,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then((response) => {
                    dispatch({
                        type: CARGA_INDICADOR_EXITO,
                        crearIndicadorM: 'Indicador creado',
                    })
                    resolve(response.data)
                })
                .catch(error => {
                    let erroData = ''
                    if (error?.response?.data?.errors?.length > 0) {
                        erroData = error?.response?.data?.errors[0]
                    } else {
                        erroData = error
                    }
                    crearExcepcion(`Error al crear indicador - ${erroData}`, token)
                    dispatch({
                        type: ERROR,
                    })
                    reject(error)
                })
        })

        const response = await toast.promise(
            crearIndicadorMensual,
            {
                pending: 'Procesando...',
                success: 'Indicador creado!',
                error: {
                    render({ data }) {
                        return `${data}`
                    }
                }
            },
            {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
        )
    } catch (error) {
        let erroData = ''
        if (error?.response?.data?.errors?.length > 0) {
            erroData = error?.response?.data?.errors[0]
        } else {
            erroData = error
        }
        crearExcepcion(`Error al crear indicador - ${erroData}`, token)
        dispatch({
            type: ERROR,
            crearIndicadorM: 'error'
        })
    }
}

export const cargarLibrador = (tipo, datos, id, token) => async (dispatch) => {
    dispatch({
        type: LOADING_CARGA_SYL,
        crearSocioyLibrador: 'cargando'
    })

    var objeto = {}
    debugger
    if (tipo === 'librador') {
        objeto = {
            "new_indicadormensualsgr": id,
            "new_name": "",
            "new_librador": datos.socio,
            "new_porcentajelibrador": datos.porcentaje,
            "new_socioparticipetercero": "",
            "new_porcentajesocioparticipetercero": 0,
            "new_socioprotector": "",
            "new_porcentajesocioprotector": 0,
            "new_fecha": datos.fecha
        }
    } else if (tipo === 'protectores') {
        objeto = {
            "new_indicadormensualsgr": id,
            "new_name": "",
            "new_librador": "",
            "new_porcentajelibrador": 0,
            "new_socioparticipetercero": "",
            "new_porcentajesocioparticipetercero": 0,
            "new_socioprotector": datos.socio,
            "new_porcentajesocioprotector": datos.porcentaje,
            "new_fecha": datos.fecha
        }
    } else {
        objeto = {
            "new_indicadormensualsgr": id,
            "new_name": "",
            "new_librador": "",
            "new_porcentajelibrador": 0,
            "new_socioparticipetercero": datos.socio,
            "new_porcentajesocioparticipetercero": datos.porcentaje,
            "new_socioprotector": "",
            "new_porcentajesocioprotector": 0,
            "new_fecha": datos.fecha
        }
    }

    debugger
    try {
        const crearSocioOlibrador = () => new Promise(async (resolve, reject) => {
            await axios.post(`${UrlApi}api/portalcasfog/indicadorsocioylibradores`,
                objeto,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then((response) => {
                    dispatch({
                        type: CARGA_SYL_EXITO,
                        crearSocioyLibrador: 'exito',
                        tipoSocio: tipo
                    })
                    resolve(response.data)
                })
                .catch(err => {
                    console.log(err)
                    dispatch({
                        type: ERROR,
                    })
                    reject(err)
                })

        })

        const response = await toast.promise(
            crearSocioOlibrador,
            {
                pending: 'Procesando...',
                success: 'éxito!',
                error: {
                    render({ data }) {
                        console.log(data)
                        return `${data}`
                    }
                }
            },
            {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
        )
    } catch (error) {
        console.log(error)
        dispatch({
            type: ERROR,
            crearSocioyLibrador: 'ERROR'
        })
    }
}

export const modificarIndicador = (datos, indicadorid, sgrid, token) => async (dispatch) => {
    debugger
    var objetoIndicador = {
        "new_fechahasta": datos.new_fechahasta,
        "new_indicadoresmensualessgrid": indicadorid,
        "new_sgr": sgrid,
        "new_saldonetodegarantiasvigentes": datos.new_saldonetodegarantiasvigentes,
        "new_solvencia": datos.new_solvencia,
        "new_fondoderiesgointegrado": datos.new_fondoderiesgointegrado,
        "new_fondoderiesgodisponible": datos.new_fondoderiesgodisponible,
        "new_fondoderiesgocontingente": datos.new_fondoderiesgocontingente,
        "new_fondoderiesgoavalordemercado": datos.new_fondoderiesgoavalordemercado,
        "new_porcentajeriesgopropio": datos.new_porcentajeriesgopropio,
        "new_porcentajeriesgoterceros": datos.new_porcentajeriesgoterceros,
        "new_porcentajeprenda": datos.new_porcentajeprenda,
        "new_porcentajehipoteca": datos.new_porcentajehipoteca,
        "new_porcentajefianza": datos.new_porcentajefianza,
        "new_porcentajeotras": datos.new_porcentajeotras,
        "new_entidadesfinancierascnv": datos.new_entidadesfinancierascnv,
        "new_garantiascomercialescnv": datos.new_garantiascomercialescnv,
        "new_garantastecnicascnv": datos.new_garantastecnicascnv,
        "new_mercadodecapitalescnv": datos.new_mercadodecapitalescnv,
        "new_chequedepagodiferidocnv": datos.new_chequedepagodiferidocnv,
        "new_pagarbursatilcnv": datos.new_pagarbursatilcnv,
        "new_valoresdecortoplazocnv": datos.new_valoresdecortoplazocnv,
        "new_obligacionesnegociablescnv": datos.new_obligacionesnegociablescnv,
        "new_garantasvigentesrvenpymesensituacion1": datos.new_garantasvigentesrvenpymesensituacion1,
        "new_garantasvigentesrvenpymesensituacion2": datos.new_garantasvigentesrvenpymesensituacion2,
        "new_garantasvigentesrvenpymesensituacion3": datos.new_garantasvigentesrvenpymesensituacion3,
        "new_garantasvigentesrvenpymesensituacion4": datos.new_garantasvigentesrvenpymesensituacion4,
        "new_garantasvigentesrvenpymesensituacion5": datos.new_garantasvigentesrvenpymesensituacion5
    }

    try {
        const editarIndicadorMensual = () => new Promise(async (resolve, reject) => {

            await axios.put(`${UrlApi}api/portalcasfog/indicadorsgr`,
                objetoIndicador,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then((response) => {
                    dispatch({
                        type: MODIFICAR_INDICADOR_EXITO,
                        modificacionIndicador: 'exito',
                    })
                    resolve(response.data)
                })
                .catch(err => {
                    console.log(err)
                    dispatch({
                        type: ERROR,
                    })
                    reject(err)
                })
        })

        const response = await toast.promise(
            editarIndicadorMensual,
            {
                pending: 'Procesando...',
                success: 'éxito!',
                error: {
                    render({ data }) {
                        console.log(data)
                        return `${data}`
                    }
                }
            },
            {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
        )
    } catch (error) {
        dispatch({
            type: ERROR,
            modificacionIndicador: 'error'
        })
        console.log(error);
    }
}

export const modificarIndicadorsyl = (objeto, token) => async (dispatch) => {

    const objetoModificado = {
        ...objeto,
        new_indicadormensualsocioylibradoresid: objeto.id,
        new_fecha: moment(objeto.new_fecha, "DD/MM/YYYY").format("YYYY-MM-DD"),
        id: undefined,
    };


    try {
        delete objetoModificado.id;
        const editarIndicadorSYLMensual = () => new Promise(async (resolve, reject) => {

            await axios.put(`${UrlApi}api/portalcasfog/indicadorsocioylibradores`,
                objetoModificado,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then((response) => {
                    dispatch({
                        type: MODIFICAR_INDICADOR_EXITO,
                        modificacionIndicador: 'exito',
                    })
                    resolve(response.data)
                })
                .catch(err => {
                    console.log(err)
                    dispatch({
                        type: ERROR,
                    })
                    reject(err)
                })
        })

        const response = await toast.promise(
            editarIndicadorSYLMensual,
            {
                pending: 'Procesando...',
                success: 'éxito!',
                error: {
                    render({ data }) {
                        console.log(data)
                        return `${data}`
                    }
                }
            },
            {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
        )
    } catch (error) {
        dispatch({
            type: ERROR,
            modificacionIndicador: 'error'
        })
        console.log(error);
    }
}

const crearExcepcion = (error, token) => {
    axios.post(`${UrlApi}api/excepcion`,
        {
            "descripcion": error
        },
        {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        })
}
