import React from "react";
import { Paper, Typography } from "@mui/material"

export const COLUMNSBANCOS = [
    {
        header: "Banco",
        accessorKey: "new_banco",
        Cell: ({ cell }) => (
          <Typography sx={{ fontSize: {xs:".7rem", md:".8rem"}  }}>
            {cell.getValue()}
          </Typography>
        ),
    },
    {
        header: "CBU",
        accessorKey: "new_cbu",
        Cell: ({ cell }) => (
          <Typography sx={{ fontSize: {xs:".7rem", md:".8rem"}  }}>
            {cell.getValue()}
          </Typography>
        ),
    },
    {
        header: "Número de Cuenta",
        accessorKey: "new_numerodecuenta",
        Cell: ({ cell }) => (
          <Typography sx={{ fontSize: {xs:".7rem", md:".8rem"} }}>
            {cell.getValue()}
          </Typography>
        ),
    },
]