import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Menu, Sidebar, MenuItem } from "react-pro-sidebar";
import { useLocation } from "react-router-dom";
import { useProSidebar } from "react-pro-sidebar";
import { useSidebarContext } from "./sidebarContext";
import { Link } from "react-router-dom";
import { useTheme, Box, Typography, IconButton } from "@mui/material";
import { HomeOutlined, ReceiptLongOutlined } from "@mui/icons-material";
import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";
import TableRowsIcon from "@mui/icons-material/TableRows";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import BusinessIcon from "@mui/icons-material/Business";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import logo from "../../../src/img/LogoColorHorizontal.png";
import logolight from "../../../src/img/LogoBlancoHorizontal.png";
import SpinnerF from "../SpinnerF";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import Tooltip from '@mui/material/Tooltip';
import logoSOC from "../../img/WebPortal.png"
import logoSOCDark from '../../img/WebPortalDark.png'
import logoBY from "../../img/ByHW-blanco.png"
import logoBYDark from "../../img/ByHW-dark.png"

const MyProSidebar = ({ loggedUser, loadingAPP }) => {
  const theme = useTheme();
  const [selected, setSelected] = useState("");
  const { sidebarRTL, sidebarImage } = useSidebarContext();
  const { collapseSidebar, toggleSidebar, collapsed, broken, rtl } =
    useProSidebar();
  const { pathname } = useLocation();

  const tipo = useSelector((store) => store.usuarios.tipo);
  const idSGR = useSelector((store) => store.sgrs.idSGR);

  React.useEffect(() => {
    setSelected(pathname);
  }, [pathname]);

  // import logoSOCDark from '../../img/WebPortal.png'
  const Item = ({ title, to, icon, selected, setSelected }) => {
    const theme = useTheme();
    return (
      <MenuItem
        active={selected === to}
        style={{
          color: theme.palette.primary.menu,
        }}
        onClick={() => setSelected(to)}
        icon={icon}
        component={<Link />}
        to={to}
      >
        <Typography>{title}</Typography>
      </MenuItem>
    );
  };

  return loggedUser && !loadingAPP ? (
    <Box
      sx={{
        position: "sticky",
        display: "flex",
        height: "100vh",
        top: 0,
        px: 0,
        bottom: 0,
        zIndex: 2,
        "& .ps-sidebar-root.css-1604tlu": {
          borderRight: ".2px solid",
          borderColor: theme.palette.mode == "dark" ? 'rgba(255, 255, 255, 0.2)!important' : 'rgb(26, 32, 53, 0.2)!important'
        },
        "& .ps-sidebar-root.ps-collapsed.css-1wvake5": {
          borderRight: ".2px solid",
          borderColor: theme.palette.mode == "dark" ? 'rgba(255, 255, 255, 0.2)!important' : 'rgb(26, 32, 53, 0.2)!important'
        },
        "& .ps-sidebar-root.css-1wvake5": {
          borderRight: ".2px solid",
          borderColor: theme.palette.mode == "dark" ? 'rgba(255, 255, 255, 0.2)!important' : 'rgb(26, 32, 53, 0.2)!important'
        },
        "& .menu-icon": {
          backgroundColor: "transparent !important",
          color: theme.palette.primary.menu,
        },
        "& .ps-menu-button:nth-child(0)": {
          my: '0!important',
          padding: "0!important",
        },
        "& .ps-menu-button": {
          borderRadius: '5px',
          my: '6px',
          padding: "4px 2px!important",
          color: theme.palette.primary.menu,
          backgroundColor: "transparent !important",
          transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms'
        },
        "& .ps-menu-button": {
          color: theme.palette.primary.menu,
          backgroundColor: "transparent !important",
        },
        "& .ps-menu-button:hover": {
          backgroundColor: 'transparent!important',
          color: '#43a047!important',
          '& .menu-icon': { color: '#43a047!important' }
        },
        "& .ps-menu-button.ps-active": {
          backgroundColor: 'transparent!important',
          color: '#43a047!important',
          '& .menu-icon': { color: '#43a047!important' }
        },
      }}
    >
      <Sidebar
        breakPoint="md"
        rtl={sidebarRTL}
        backgroundColor={broken && !rtl ? theme.palette.background.main : null}
        image={sidebarImage}
        defaultCollapsed={true}
        width={tipo === "SGR" ? "200px" : "250px"}
      >
        <Menu iconshape="square">
          <MenuItem
            icon={
              collapsed && (
                <MenuOutlinedIcon sx={{ mt: 2 }} onClick={() => collapseSidebar()} />
              )
            }

          >
            {!collapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                // ml="2%"
                style={{ maxWidth: "100%" }}
              >
                <IconButton
                  onClick={
                    broken ? () => toggleSidebar() : () => collapseSidebar()
                  }
                  style={{ flexShrink: 0 }}
                  sx={{ mt: 2 }}
                >
                  <CloseOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>
          {tipo === "SGR" ? (
            broken && !rtl ? (
              <Box sx={{ mt: 2 }}>
                <Item
                  title="Inicio"
                  to="/inicio"
                  icon={<HomeOutlined />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Series"
                  to="/series"
                  icon={<TableRowsIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Pymes"
                  to="/pymes"
                  icon={<SnippetFolderIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Garantias"
                  to="/garantias"
                  icon={<AutoAwesomeMotionIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Operaciones"
                  to="/operaciones"
                  icon={<AnalyticsIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Datos SGR"
                  to="/datos-sgr"
                  icon={<AccountTreeIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Mis SGR"
                  to="/mis-sgr"
                  icon={<BusinessIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </Box>
            ) : (
              <Box sx={{ mt: 2 }}>
                <Item
                  title="Inicio"
                  to="/inicio"
                  icon={
                    <Tooltip placement="right" title={<Typography sx={{ color: '#fff' }}>Inicio</Typography>}>
                      <HomeOutlined />
                    </Tooltip>}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Series"
                  to="/series"
                  icon={
                    <Tooltip placement="right" title={<Typography sx={{ color: '#fff' }}>Series</Typography>}>
                      <TableRowsIcon />
                    </Tooltip>}
                  selected={selected}
                  setSelected={setSelected}
                />

                <Item
                  title="Pymes"
                  to="/pymes"
                  icon={
                    <Tooltip placement="right" title={<Typography sx={{ color: '#fff' }}>Pymes</Typography>}>
                      <SnippetFolderIcon />
                    </Tooltip>}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Garantias"
                  to="/garantias"
                  icon={
                    <Tooltip placement="right" title={<Typography sx={{ color: '#fff' }}>Garantias</Typography>}>
                      <AutoAwesomeMotionIcon />
                    </Tooltip>}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Operaciones"
                  to="/operaciones"
                  icon={
                    <Tooltip placement="right" title={<Typography sx={{ color: '#fff' }}>Operaciones</Typography>}>
                      <AnalyticsIcon />
                    </Tooltip>}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Datos SGR"
                  to="/datos-sgr"
                  icon={
                    <Tooltip placement="right" title={<Typography sx={{ color: '#fff' }}>Datos SGR</Typography>}>
                      <AccountTreeIcon />
                    </Tooltip>}
                  selected={selected}
                  setSelected={setSelected}
                />
                <Item
                  title="Mis SGR"
                  to="/mis-sgr"
                  icon={
                    <Tooltip placement="right" title={<Typography sx={{ color: '#fff' }}>Mis SGR</Typography>}>
                      <BusinessIcon />
                    </Tooltip>}
                  selected={selected}
                  setSelected={setSelected}
                />
              </Box>
            )
          ) : broken && !rtl ? (
            <Box sx={{ mt: 3 }}>
              <Item
                title="Resumen de posición"
                to="/resumendeposicion"
                icon={<HomeOutlined />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Mi documentación digital"
                to="/midocumentaciondigital"
                icon={<SnippetFolderIcon />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title="Mis garantías"
                to="/misgarantias"
                icon={<AutoAwesomeMotionIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              {/* <Item
                title="Mis Operaciones"
                to="/misoperaciones"
                icon={<ReceiptLongOutlined />}
                selected={selected}
                setSelected={setSelected}
              /> */}
              <Item
                title="Mi Cuenta"
                to="/micuenta"
                icon={<AccountCircleIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            </Box>
          ) : (
            <Box sx={{ mt: 3 }}>
              <Item
                title="Resumen de posición"
                to="/resumendeposicion"
                icon={collapsed ?
                  <Tooltip placement="right" title={<Typography sx={{ color: '#fff' }}>Resumen de Posición</Typography>}>
                    <HomeOutlinedIcon />
                  </Tooltip> : <HomeOutlinedIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              <Item
                title="Mi documentación digital"
                to="/midocumentaciondigital"
                icon={collapsed ?
                  <Tooltip placement="right" title={<Typography sx={{ color: '#fff' }}>Mi Documentación Digital</Typography>}>
                    <SnippetFolderIcon />
                  </Tooltip> : <SnippetFolderIcon />}
                selected={selected}
                setSelected={setSelected}
              />

              <Item
                title="Mis garantías"
                to="/misgarantias"
                icon={collapsed ?
                  <Tooltip placement="right" title={<Typography sx={{ color: '#fff' }}>Mis Garantías</Typography>}>
                    <AutoAwesomeMotionIcon />
                  </Tooltip> : <AutoAwesomeMotionIcon />}
                selected={selected}
                setSelected={setSelected}
              />
              {/* <Item
                title="Mis Operaciones"
                to="/misoperaciones"
                icon={collapsed ?
                  <Tooltip placement="right" title={<Typography sx={{ color: '#fff' }}>Mis Operaciones</Typography>}>
                    <ReceiptLongOutlined />
                  </Tooltip> : <ReceiptLongOutlined />}
                selected={selected}
                setSelected={setSelected}
              /> */}
              <Item
                title="Mi Cuenta"
                to="/micuenta"
                icon={collapsed ?
                  <Tooltip placement="right" title={<Typography sx={{ color: '#fff' }}>Mi Cuenta</Typography>}>
                    <AccountCircleIcon />
                  </Tooltip> : <AccountCircleIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            </Box>
          )}
        </Menu>
        {!collapsed ?
          <Box sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "20px"
          }}>
            {
              theme.palette.mode == 'dark' ?
                <img src={logoSOC} alt="Logo Responsive" style={!collapsed ? { height: "3.2rem" } : { height: "1.8rem" }} /> :
                <img src={logoSOCDark} alt="Logo Responsive" style={!collapsed ? { height: "3.2rem", animation: "width-increase 3s ease infinite" } : { height: "1.8rem", animation: "width-increase 3s ease infinite" }} />
            }
          </Box>
          : <Box sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            display: "flex",
            justifyContent: "center",
            padding: "20px"
          }}>
            {
              theme.palette.mode == 'dark' ?
                <img src={logoBY} alt="Logo Responsive" style={{ height: "3.2rem" }} /> :
                <img src={logoBYDark} alt="Logo Responsive" style={{ height: "3.2rem" }} />
            }
          </Box>
        }
        {!collapsed ? (
          <Box
            sx={{
              position: "absolute",
              bottom: 0,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              padding: "20px",
              display: { xs: "block", sm: "none" },
            }}
          >
            {theme.palette.mode == "dark" ? (
              <img
                src={logo}
                alt="Logo Responsive"
                style={{ height: "1.8rem" }}
              />
            ) : (
              <img
                src={logolight}
                alt="Logo Responsive"
                style={{ height: "1.8rem" }}
              />
            )}
          </Box>
        ) : null}
      </Sidebar>
    </Box>
  ) : !loggedUser && loadingAPP ? (
    <Box
      component="main"
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <SpinnerF />
    </Box>
  ) : null;
};

export default MyProSidebar;
