import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import {TextField, Grid, Box, Typography, Container, CircularProgress} from '@mui/material/'
import logo from '../../Imagenes/casfog-logo.png'
import { registrarUsuarioContacto } from '../../Redux/Usuarios'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from "react-router-dom"
import { toast } from "react-toastify"
import ReCAPTCHA from "react-google-recaptcha"
import { RecaptchaKey } from "../../Keys"

const schema = yup.object().shape({
    nombre: yup.string().required('El nombre es requerido'),
    apellido: yup.string().required('El apellido es requerido'),
    correo: yup.string().email('El correo electrónico no es válido').required('El correo electrónico es requerido'),
    password: yup.string().min(6, 'La contraseña debe tener al menos 6 caracteres').
        max(16, 'La contraseña no puede superar los 16 caracteres').required('La contraseña es requerida'),
    repassword: yup.string().oneOf([yup.ref('password'), 'Las contraseñas deben coincidir']).required('Repetir contraseña es contraseña es requerida')
});

const RegistroSgr = ({token}) => {
    const dispatch = useDispatch()
    let navigate = useNavigate()

    const [robot, setRobot] = useState(false)
    const resultadoRegistro = useSelector(store => store.usuarios.resultadoRegistro)

    React.useEffect(() => {
      if (resultadoRegistro === "EXITO") {
            reset();
            navigate('/mis-sgr')
      }
      if (resultadoRegistro === "ERROR") {
        reset()
        setRobot(false)
  }
  }, [resultadoRegistro])

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmitHandler = (data) => {
        if (!robot) {
            loadingError("Debes confirmar que no eres un robot!");
            return;
        }
        dispatch(registrarUsuarioContacto(data.correo, data.password, "SGR", data.nombre, data.apellido, token))
    };

    const robotOnChange = (value) => {
        let valor = "";
        if (value) {
          valor = "True";
          setRobot(true);
        } else {
          valor = "False";
          setRobot(false);
        }
    }
      const loadingError = (msj) => {
        toast.error(msj, {
          position: "top-center",
          theme: "dark",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
    }

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 0, width: 90, height: 90 }}
                    src={logo}
                >
                </Avatar>
                <Typography component="h1" variant="h5" sx={{ mb: 2 }}>
                    Crear Cuenta
                </Typography>
                <form onSubmit={handleSubmit(onSubmitHandler)}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                {...register("nombre")}
                                fullWidth
                                id="nombre"
                                label={<Typography variant="subtitle2" sx={{fontWeight:600, color:"#fff"}}>Nombre</Typography>}
                                autoFocus
                                type="text"
                                aria-describedby="nombre-text"
                                helperText={errors.nombre?.message}
                                error={errors.nombre}
                                sx={{ input: { color: '#fff' }, fieldset: { borderColor: "#fff" } }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                id="lastName"
                                label={<Typography variant="subtitle2" sx={{fontWeight:600, color:"#fff"}}>Apellido</Typography>}
                                {...register("apellido")}
                                type="text"
                                helperText={errors.apellido?.message}
                                error={errors.apellido}
                                sx={{ input: { color: '#fff' }, fieldset: { borderColor: "#fff" } }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                id="correo"
                                label={<Typography variant="subtitle2" sx={{fontWeight:600, color:"#fff"}}>Correo</Typography>}
                                {...register("correo")}
                                helperText={errors.correo?.message}
                                error={errors.correo}
                                sx={{ input: { color: '#fff' }, fieldset: { borderColor: "#fff" } }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={<Typography variant="subtitle2" sx={{fontWeight:600, color:"#fff"}}>Contraseña</Typography>}
                                type="password"
                                id="password"
                                {...register("password")}
                                helperText={errors.password?.message}
                                error={errors.password}
                                sx={{ input: { color: '#fff' }, fieldset: { borderColor: "#fff" } }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label={<Typography variant="subtitle2" sx={{fontWeight:600, color:"#fff"}}>Repetir Contraseña</Typography>}
                                type="password"
                                id="repassword"
                                {...register("repassword")}
                                helperText={errors.repassword?.message}
                                error={errors.repassword}
                                sx={{ input: { color: '#fff' }, fieldset: { borderColor: "#fff" } }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                    <Box sx={{mt:2}}>
                    <ReCAPTCHA
                        size={12}
                        render="explicit"
                        sitekey={RecaptchaKey}
                        onChange={(e) => robotOnChange(e)}
                        />
                    </Box>
                    <Box sx={{ mt: 1, mb:2, position: 'relative' }}>
                    <Button
                        type="submit"
                        disabled={resultadoRegistro === 'LOADING'}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 1 }}
                    >
                        Crear cuenta
                    </Button>
                    {resultadoRegistro === 'LOADING' && (
                    <CircularProgress
                        size={27}
                        sx={{
                            color: "#fff",
                            position: 'absolute',
                            top: '60%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                        />
                        )}
                    </Box>
                </form>
                <Box sx={{ mt: 1 }}>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link to="/IniciarSesion" style={{ textDecoration: 'none', color: '#fff' }}>
                                <Typography variant="subtitle2" sx={{color:"#fff"}}>
                                    Ya tienes una cuenta? ir a iniciar sesion
                                </Typography>
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container>
    );
};

export default RegistroSgr;