import React, { useState, useEffect, useContext } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { crearDocumentacion, obtenerDocumentacion } from '../../Redux/Indicadores'
import {
    Dialog, Box, DialogContent, DialogContentText, DialogTitle, Button, IconButton, TextField,
    DialogActions, FormControl, Divider, CircularProgress, Tooltip, Typography, useTheme
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { MuiFileInput } from 'mui-file-input'
import { useSelector, useDispatch } from "react-redux"
import TokenContext from '../../context/TokenContext'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"
import CustomSearchSelect from '../CustomSearchSelect'
import CustomTextField from '../CustomTextField'
import { green } from '@mui/material/colors';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { es } from 'date-fns/locale'
import { Controller } from 'react-hook-form';
import Moment from 'moment'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/es';

const ModalNuevaDocumentacionSGR = ({ open, setOpen, idSgr }) => {
    const dispatch = useDispatch()
    const token = useContext(TokenContext)
    const theme = useTheme()
    const docuResultado = useSelector(store => store.indicadores.sgrDocuResultado)
    const documentacion = useSelector(store => store.indicadores.documentacion)

    const [file, setFile] = useState(null)
    const [opcionesDocumentos, setOpcionesDocumentos] = useState([])
    const [defaultValues, setDefaultValues] = useState({
        documentacion: null,
        fechaVencimiento: ""
    })

    useEffect(() => {
        dispatch(obtenerDocumentacion(token))
    }, [])

    useEffect(() => {
        if (documentacion.length > 0) completarOpcionesDocumentos(documentacion)
    }, [documentacion])

    const completarOpcionesDocumentos = (documentos) => {
        const array = [];
        documentos.forEach(documento => {
            let item = { value: documento.new_documentacionid, label: documento.new_name }
            array.push(item);
        });
        setOpcionesDocumentos(array)
    }

    const validationSchema = yup.object().shape({
        documentacion: yup.object().required('El rol es requerido'),
        fechaVencimiento: yup.date().required('La fecha es requerida'),
    })

    const methods = useForm({
        shouldUnregister: false,
        defaultValues,
        resolver: yupResolver(validationSchema),
        mode: "onChange",
    })

    const { handleSubmit } = methods

    const enviarDocumentacion = (datos) => {
        const { documentacion, fechaVencimiento } = datos

        if (file?.length === 0 || file === null) {
            loadingError("El archivo adjunto es requerido!")
            return
        }

        let sizes = 0
        const formData = new FormData()
        for (let index = 0; index < file.length; index++) {
            sizes += file[index].size
            if (file[index].size >= 15000000) {
                loadingError("El archivo no puede superar los 15 megas")
                setFile(null)
                return
            }
            let element = file[index];
            formData.append(`body${index}`, element);
        }
        if (sizes >= 15000000) {
            loadingError("Los archivos adjuntos no puede superar los 15 megas!")
            setFile(null)
            return
        }
        debugger
        dispatch(crearDocumentacion(token, idSgr, documentacion.value,
            fechaVencimiento, formData))
    }

    useEffect(() => {
        if (docuResultado === 'EXITO') {
            setFile(null)
            handleClose()
        }
    }, [docuResultado])

    const loadingError = (msj) => {
        toast.error(msj, {
            theme: "dark",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const handleChangeFile = (newFile) => {
        setFile(newFile)
    }

    const handleClose = () => {
        setOpen(false)
    }

    const buttonSx = {
        ml: 0,
        ...(docuResultado === 'LOADING' && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        })
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="xl"
            >
                <Box sx={{ backgroundColor: theme.palette.background.default, display: "flex", justifyContent: "space-between" }}>
                    <DialogTitle id="alert-dialog-title" sx={{ fontSize: { xs: "1rem", md: "1.1rem" } }} >
                        Documentación por SGR
                    </DialogTitle>
                    <Tooltip title={<Typography sx={{ color: '#fff' }}>Cerrar</Typography>} >
                        <IconButton
                            edge="end"
                            color="warning"
                            onClick={handleClose}
                            aria-label="close"
                            sx={{ width: 40, height: 40, mr: 1, mt: 1 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <DialogContent sx={{ backgroundColor: theme.palette.background.default }}>
                    <DialogContentText id="alert-dialog-description">
                        <Box
                            sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 2 }}
                        >
                            <FormProvider {...methods}>
                                <FormControl fullWidth >
                                    <CustomSearchSelect
                                        options={opcionesDocumentos}
                                        // values="---"
                                        type="text"
                                        name="documentacion"
                                        lab="Documentación"
                                        helperText="Por favor seleccione la documentación"
                                        rules={{ required: "Required!" }}
                                        req="true"
                                    />
                                </FormControl>
                                <Controller
                                    fullwidth="true"
                                    name="fechaVencimiento"
                                    sx={{ m: 1 }}
                                    render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
                                        <FormControl sx={{ m: 1 }} fullWidth>
                                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
                                                <DesktopDatePicker
                                                    {...field}
                                                    views={['day', 'month', 'year']}
                                                    inputRef={ref}
                                                    label="Fecha de vencimiento:"
                                                    renderInput={(inputProps) => (
                                                        <TextField
                                                            {...inputProps}
                                                            onBlur={onBlur}
                                                            name={name}
                                                            error={!!fieldState.error}
                                                            helperText="La fecha es requerida"
                                                            rules={{ required: "Required!" }}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        </FormControl>
                                    )}
                                />
                                {/* <CustomTextField
                                        Component={TextField}
                                        type="date"
                                        name="fechaVencimiento"
                                        label="Fecha de vencimiento"
                                    /> */}
                            </FormProvider>
                            <Box sx={{ mt: 2 }}>
                                <MuiFileInput
                                    value={file}
                                    multiple
                                    label="Adjuntar documentación"
                                    onChange={handleChangeFile}
                                />
                            </Box>
                        </Box>
                    </DialogContentText>
                    <Box sx={{ mt: 2, p: 0, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <Button
                            type="button"
                            onClick={handleSubmit(enviarDocumentacion)}
                            variant="contained"
                            sx={buttonSx}
                            disabled={docuResultado === 'LOADING'}
                        >
                            {docuResultado === 'LOADING' && (
                                <CircularProgress
                                    size={24}
                                    color="inherit"
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: -12,
                                        marginLeft: -12
                                    }}
                                />
                            )}
                            Crear Documentación
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default ModalNuevaDocumentacionSGR
