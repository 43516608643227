import React, { useEffect, useContext, useState } from 'react'
import { Container, Grid, Typography, Paper, Box } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import GraficoPie from '../Graficos/GraficoPie'
import { obtenerSeriesYGarantias } from '../Redux/Series'
import TokenContext from '../context/TokenContext'
import LoaderAnimado from './loaderAnimado/LoaderAnimado'
import GraficoDonaApex from './Graficos/GraficoDonaApex'

const GraficosEstadioPymes = ({ dataCargada = false }) => {
    const dispatch = useDispatch()
    const token = useContext(TokenContext)
    //Selectores
    const seriesSelector = useSelector(store => store.series.series)
    const resultadoSerieSelector = useSelector(store => store.series.resultadoSerie)
    //Hooks
    const [garantiaYPymes, setGarantiaYPymes] = useState([])
    const [log, setLog] = useState(true)
    const [idSGR, setIdSGR] = useState(JSON.parse(localStorage.getItem('Id SGR')))
    const [pymesAnalizadas, setPymesAnalizadas] = React.useState([])
    const [pymesComprometidas, setPymesComprometidas] = React.useState([])
    const [pymesMonetizadas, setPymesMonetizadas] = React.useState([])
    const [opciones, setOpciones] = React.useState([])

    useEffect(() => {
        dispatch(obtenerSeriesYGarantias(token, idSGR))
        setLog(true)
    }, [])

    useEffect(() => {
        if (seriesSelector?.length > 0 && log) {
            graficos(seriesSelector)
            setLog(false)
        } else if (log) {
            dispatch(obtenerSeriesYGarantias(token, idSGR))
        }
    }, [idSGR, token, seriesSelector])

    const graficos = (series) => {
        var pymesGarantias = []
        let pymesAnalizadas = []
        let pymesComprometidas = []
        let pymesMonetizadas = []
        let Acreedores = []
        // let seriesUnicas = [...new Set(series.filter(item => item.statuscode != 2 && item.statuscode != 100000002).map(item => item["serie.new_name"]))] //100000002 "Afrontada"
        let seriesUnicas = [...new Set(series.map(item => item["serie.new_name"]))]
        let pymesUnicasComprometidas = [...new Map(series.map(item => [item.new_garantiaid, item])).values()]
        let pymesUnicasMonetizadas = [...new Map(series.filter(item => item.new_fechadenegociacion != null).map(item => [item.new_garantiaid, item])).values()]

        seriesUnicas.forEach(item => {
            debugger
            let nombreSeparado = item.split('-')
            let nombreSerie = nombreSeparado[0].trim()
            let nombreAcreedor = nombreSeparado[1].trim()
            var pyme = {
                serie: nombreSerie,
                pymesAnalizadas: pymesUnicasComprometidas.filter(pyme => pyme["serie.new_name"] == item).length,
                pymesGarantiasComprometidas: pymesUnicasComprometidas.filter(pyme => pyme["serie.new_name"] == item && (pyme.statuscode == 1 || pyme.statuscode == 100000001) && pyme.new_dictamendelaval != 100000003).length,
                pymesGarantiasMonetizadas: pymesUnicasMonetizadas.filter(pyme => pyme["serie.new_name"] == item && pyme.new_fechadenegociacion != null && pyme.new_dictamendelaval != 100000003).length
            }
            var serie = {
                // item["serie.new_name"]
                filtro: nombreAcreedor,
                opcion: nombreSerie,
                cantidad: pymesUnicasComprometidas.filter(pyme => pyme["serie.new_name"] == item).length,
            }
            var serieC = {
                // item["serie.new_name"]
                filtro: nombreAcreedor,
                opcion: nombreSerie,
                cantidad: pymesUnicasComprometidas.filter(pyme => pyme["serie.new_name"] == item && (pyme.statuscode == 1 || pyme.statuscode == 100000001) && pyme.new_dictamendelaval != 100000003).length,
            }
            var serieM = {
                // item["serie.new_name"]
                filtro: nombreAcreedor,
                opcion: nombreSerie,
                cantidad: pymesUnicasMonetizadas.filter(pyme => pyme["serie.new_name"] == item && pyme.new_fechadenegociacion != null && pyme.new_dictamendelaval != 100000003).length,
            }
            Acreedores.push(nombreAcreedor)
            pymesAnalizadas.push(serie)
            pymesComprometidas.push(serieC)
            pymesMonetizadas.push(serieM)
            pymesGarantias.push(pyme)
        })
        let acreedoresUnicos = [...new Set(Acreedores.map(item => item))]
        acreedoresUnicos.sort((a, b) => (a > b) ? 1 : ((b > a) ? -1 : 0))
        setOpciones(acreedoresUnicos)
        pymesAnalizadas.sort((a, b) => (a.opcion > b.opcion) ? 1 : ((b.opcion > a.opcion) ? -1 : 0))
        pymesComprometidas.sort((a, b) => (a.opcion > b.opcion) ? 1 : ((b.opcion > a.opcion) ? -1 : 0))
        pymesMonetizadas.sort((a, b) => (a.opcion > b.opcion) ? 1 : ((b.opcion > a.opcion) ? -1 : 0))
        setPymesAnalizadas(pymesAnalizadas)
        setPymesComprometidas(pymesComprometidas)
        setPymesMonetizadas(pymesMonetizadas)
        pymesGarantias.sort((a, b) => (a.serie > b.serie) ? 1 : ((b.serie > a.serie) ? -1 : 0))
        setGarantiaYPymes(pymesGarantias)
    }

    return (
        <Box component="main" sx={{ flexGrow: 1 }}>
            {
                !dataCargada ?
                    <Grid
                        container
                    >
                        <Grid item xs={12} md={4}>
                            {log ?
                                <Paper
                                    sx={{
                                        background: 'paper',
                                        display: 'flex',
                                        justifyContent: "center",
                                        alignItems: "center",
                                        // minwidth: 600,
                                        // height: 400,
                                        boxShadow: 1,
                                        borderRadius: 2,
                                        py: 2,
                                        m: 1,
                                        p: 4,
                                    }}>
                                    <LoaderAnimado />
                                </Paper>
                                :
                                <GraficoDonaApex
                                    datos={pymesAnalizadas}
                                    opciones={opciones}
                                    titulo="Cantidad de Pymes Analizadas por Serie"
                                    opcionPrincipal="FONDEP"
                                />
                            }
                        </Grid>
                        <Grid item xs={12} md={4}>
                            {log ?
                                <Paper
                                    sx={{
                                        background: 'paper',
                                        display: 'flex',
                                        justifyContent: "center",
                                        alignItems: "center",
                                        // minwidth: 600,
                                        // height: 400,
                                        boxShadow: 1,
                                        borderRadius: 2,
                                        py: 2,
                                        m: 1,
                                        p: 4,
                                    }}>
                                    <LoaderAnimado />
                                </Paper>
                                :
                                <GraficoDonaApex
                                    datos={pymesComprometidas}
                                    opciones={opciones}
                                    titulo="Cantidad de Pymes con Garantías Comprometidas por Serie"
                                    opcionPrincipal="FONDEP"
                                />
                            }
                        </Grid>
                        <Grid item xs={12} md={4}>
                            {log ?
                                <Paper
                                    sx={{
                                        background: 'paper',
                                        display: 'flex',
                                        justifyContent: "center",
                                        alignItems: "center",
                                        // minwidth: 600,
                                        // height: 400,
                                        boxShadow: 1,
                                        borderRadius: 2,
                                        py: 2,
                                        m: 1,
                                        p: 4,
                                    }}>
                                    <LoaderAnimado />
                                </Paper>
                                :
                                <GraficoDonaApex
                                    datos={pymesMonetizadas}
                                    opciones={opciones}
                                    titulo="Cantidad de Pymes con Garantías Monetizadas Por Serie"
                                    opcionPrincipal="FONDEP"
                                />
                            }
                        </Grid>
                    </Grid>
                    : null
            }
        </Box>
    )
}

export default GraficosEstadioPymes