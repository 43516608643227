import React, { useContext } from 'react'
import { styled } from '@mui/material/styles'
import { Box, Typography, Divider, useTheme } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import Fade from 'react-reveal/Fade'
import Avatar from '@mui/material/Avatar'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import ListSubheader from '@mui/material/ListSubheader'
import CloudDoneIcon from '@mui/icons-material/CloudDone'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import { IconButton } from '@mui/material'
import { obtenerDocumentosPorCuenta } from '../Redux/CarpetaDigital'
import { obtenerRelacionesFETCH } from '../Redux/RelacionDeVinculacion'
import { obtenerTodosLimitesPorLineas } from '../Redux/LimitePorLinea';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import AutoAwesomeMotionIcon from '@mui/icons-material/AutoAwesomeMotion'
import Paper from '@mui/material/Paper'
import Chip from '@mui/material/Chip'
import Stack from '@mui/material/Stack'
import GraficoPie from '../Components/Graficos/GraficoPie'
import ListItemAvatar from '@mui/material/ListItemAvatar'
import MovingIcon from '@mui/icons-material/Moving'
import WorkIcon from '@mui/icons-material/Work'
import FileOpenIcon from '@mui/icons-material/FileOpen'
import { useNavigate } from 'react-router-dom'
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Tooltip from '@mui/material/Tooltip'
import TokenContext from '../context/TokenContext'
import ExtensionOffOutlinedIcon from '@mui/icons-material/ExtensionOffOutlined'
import { obtenerGarantiasFetch } from '../Redux/Garantias'
import GraficoPieTexto from "../Components/Graficos/GraficoPieText"
import TableRowsIcon from '@mui/icons-material/TableRows';
import LoaderAnimado from '../Components/loaderAnimado/LoaderAnimado'
import { LocalGasStationRounded } from '@mui/icons-material'

const COLORS = [];
let COLORSEstados = [];
let ESTADIOONBOARDING = []

const ResumenPosicion = ({ cuenta }) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const token = useContext(TokenContext)

    //HOOKS DEL ACCORDEON
    const [expanded, setExpanded] = React.useState('panel1');

    const [carpetas, setCarpetas] = React.useState([])
    const [llamadaDocumentosCuentas, setLlamadaDocumentosCuentas] = React.useState(false)
    const documentosCuentasSelector = useSelector(store => store.documentosPorCuenta.documentos)
    const accountid = useSelector(store => store.usuarios.accountid)

    const [garantias, setGarantias] = React.useState([])
    const [llamadaGarantias, setLlamadaGarantias] = React.useState(false)
    const garantiasSelector = useSelector(store => store.garantias.garantiasFetch)

    const [limite, setLimite] = React.useState({})
    const [dataLimite, setDataLimite] = React.useState({})
    const [llamadaLimites, setLlamadaLimites] = React.useState(false)
    const limiteSelector = useSelector(store => store.limites.limite)
    const limiteLoading = useSelector(store => store.limites.loadingLimite)

    const [relacionesFETCH, setRelacionesFETCH] = React.useState([])
    const [llamadaRelaciones, setLlamadaRelaciones] = React.useState(false)
    const relacionesFetchSelector = useSelector(store => store.relaciones.relacionesFetch)

    const [usuario, setUsuario] = React.useState([])
    const usuarioSelector = useSelector(store => store.usuarios.user)
    const loadingSelector = useSelector(store => store.documentosPorCuenta.loadingDocumentos)
    const retrieveDocsExitoSelector = useSelector(store => store.documentosPorCuenta.retrieveDocsExito)
    const [loading, setLoading] = React.useState(false)
    const [loadingRelaciones, setLoadingRelaciones] = React.useState(false)
    const retrieveRelacionesSelector = useSelector(store => store.relaciones.retrieveRelaciones)
    const loadingRelacionesSelector = useSelector(store => store.relaciones.loadingRelaciones)
    const [retrieveRelaciones, setRetrieveRelaciones] = React.useState(false)
    const [retrieveDocs, setRetrieveDocs] = React.useState(false)
    const [loadingDataOnb, setLoadingDataOnb] = React.useState(true)
    const [estadosOnb, setEstadosOnb] = React.useState([])

    let dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    React.useEffect(() => {
        if (usuarioSelector) {
            setUsuario(usuarioSelector)
        }
    }, [usuarioSelector])

    React.useEffect(() => {
        if ((cuenta != undefined && Object.entries(cuenta).length > 0) &&
            (carpetas != undefined && carpetas.length > 0)) {
            if (ESTADIOONBOARDING.length > 0) {
                ESTADIOONBOARDING = []
            }

            if (COLORSEstados.length > 0) {
                COLORSEstados = []
            }

            let docuPendiente = true
            let datosPendiente = true
            setEstadosOnb([])

            if (carpetas.filter((carpeta) => carpeta.new_visibleenportal == true).length > 0) {
                if (carpetas.filter((carpeta) => carpeta.new_visibleenportal == true &&
                    carpeta.statuscodeNOMBRE == "Pendiente").length == 0) {
                    docuPendiente = false
                }
            } else {
                docuPendiente = false
            }

            if (cuenta != undefined &&
                (cuenta.new_estadodelsocio == 100000006 || cuenta.new_estadodelsocio == 100000000)) {
                datosPendiente = false
            }

            if (docuPendiente == true && datosPendiente == true) {
                ESTADIOONBOARDING.push({ name: "Documentación", value: 30, texto: "Pendiente" })
                COLORSEstados.push("#ffab00")
                ESTADIOONBOARDING.push({ name: "Datos mi cuenta", value: 30, texto: "Pendiente" })
                COLORSEstados.push("#ffab00")
            } else if (docuPendiente == false && datosPendiente == true) {
                ESTADIOONBOARDING.push({ name: "Datos mi cuenta", value: 30, texto: "Pendiente" })
                COLORSEstados.push("#ffab00")
                ESTADIOONBOARDING.push({ name: "Documentación", value: 30, texto: "Completada" })
                COLORSEstados.push("#43a047")
            } else if (docuPendiente == false && datosPendiente == false) {
                ESTADIOONBOARDING.push({ name: "Documentación", value: 30, texto: "Completada" })
                COLORSEstados.push("#43a047")
                ESTADIOONBOARDING.push({ name: "Datos mi cuenta", value: 30, texto: "Completada" })
                COLORSEstados.push("#43a047")
            } else if (docuPendiente == true && datosPendiente == false) {
                ESTADIOONBOARDING.push({ name: "Documentación", value: 30, texto: "Pendiente" })
                COLORSEstados.push("#ffab00")
                ESTADIOONBOARDING.push({ name: "Datos mi cuenta", value: 30, texto: "Completada" })
                COLORSEstados.push("#43a047")
            }

            ESTADIOONBOARDING.push({ name: "Solicitud de Alta", value: 40, texto: "Completada" })
            COLORSEstados.push("#43a047")

            setLoadingDataOnb(false)
        }
    }, [carpetas, cuenta])

    React.useEffect(() => {
        setLoading(loadingSelector)
    }, [loadingSelector])

    React.useEffect(() => {
        setLoadingRelaciones(loadingRelacionesSelector)
    }, [loadingRelacionesSelector])

    // React.useEffect(() => {
    //     try {
    //         if (accountid !== '') {
    //             if (limiteSelector !== undefined && limiteSelector.length > 0) {
    //                 var limiteGeneral = limiteSelector.filter(item => item.new_lineatipodeoperacion == 100000000)
    //                 if (limiteGeneral.length > 0) {
    //                     let utilizado = 0
    //                     let disponible = 0
    //                     let datosLimites = []
    //                     setLimite(limiteGeneral[0])
    //                     var tope = parseInt(limiteGeneral[0].new_topeporlineacomercial)
    //                     utilizado = parseInt(limiteGeneral[0].new_montoutilizadogeneral)
    //                     if (utilizado > 0) {
    //                         datosLimites.push({ name: "Utilizado", value: utilizado })
    //                         COLORS.push('#43a047')
    //                     }
    //                     disponible = tope - utilizado
    //                     if (disponible > 0) {
    //                         datosLimites.push({ name: "Disponible", value: disponible })
    //                         COLORS.push('#ffab00')
    //                     }
    //                     setTimeout(() => {
    //                         setDataLimite(datosLimites)
    //                     }, 500)
    //                 }
    //             } else if (token != undefined && token != '' && llamadaLimites == false) {
    //                 obtenerLimite()
    //                 setLlamadaLimites(true)
    //             }
    //         }
    //     } catch (err) {
    //         // console.log(err)
    //     }
    // }, [limiteSelector, accountid, token])

    React.useEffect(() => {
        try {
            if (accountid !== '') {
                if (relacionesFetchSelector != undefined && relacionesFetchSelector.length > 0) {
                    var relaciones = []
                    relacionesFetchSelector.forEach(item => {
                        var relacion = {
                            new_participacionaccionariaid: item.new_participacionaccionariaid,
                            new_tienedomicilioenusa: item["ab.new_tienedomicilioenusa"],
                            new_tienedomiciliofiscalenelexterior: item["ab.new_tienedomiciliofiscalenelexterior"],
                            firstname: item["ab.firstname"],
                            lastname: item["ab.lastname"],
                            statuscode: item["ab.statuscode"],
                            new_cuitcuil: item["ab.new_cuitcuil"],
                            emailaddress1: item["ab.emailaddress1"],
                            lastname: item["ab.lastname"],
                            birthdate: item["ab.birthdate"],
                            familystatuscode: item["ab.familystatuscode"],
                            familystatuscode_value: item["ab.familystatuscode@OData.Community.Display.V1.FormattedValue"],
                            new_nrodedocumentoconyuge: item["ab.new_nrodedocumentoconyuge"],
                            new_nrodedocumento: item["ab.new_nrodedocumento"],
                            address3_line1: item["ab.address3_line1"],
                            spousesname: item["ab.spousesname"],
                            address3_postalcode: item["ab.address3_postalcode"],
                            address3_city: item["ab.address3_city"],
                            address3_stateorprovince: item["ab.address3_stateorprovince"],
                            address3_country: item["ab.address3_country"],
                            new_einss: item["ab.new_einss"],
                            new_nit: item["ab.new_nit"],
                            address1_line1: item["ab.address1_line1"],
                            address1_postalcode: item["ab.address1_postalcode"],
                            address1_city: item["ab.address1_city"],
                            address1_stateorprovince: item["ab.address1_stateorprovince"],
                            address1_country: item["ab.address1_country"],
                            new_tipodedocumentoconyuge: item["ab.new_tipodedocumentoconyuge"],
                            new_tipodedocumentoconyuge_value: item["ab.new_tipodedocumentoconyuge@OData.Community.Display.V1.FormattedValue"],
                            new_generodelconyuge: item["ab.new_generodelconyuge"],
                            new_generodelconyuge_value: item["ab.new_generodelconyuge@OData.Community.Display.V1.FormattedValue"],
                            new_conyugetrabaja: item["ab.new_conyugetrabaja"],
                            new_tiposujetoobligado: item["ab.new_tiposujetoobligado"],
                            new_tiposujetoobligado_value: item["ab.new_tiposujetoobligado@OData.Community.Display.V1.FormattedValue"],
                            new_cuentacontactovinculado: item._new_cuentacontactovinculado_value,
                            tipo: item["_new_cuentacontactovinculado_value@Microsoft.Dynamics.CRM.lookuplogicalname"],
                            new_tipoderelacion_value: item["new_tipoderelacion@OData.Community.Display.V1.FormattedValue"],
                            _new_cuentacontactovinculado_value: item["_new_cuentacontactovinculado_value@OData.Community.Display.V1.FormattedValue"],
                            statecode: item.statecode,
                            cuenta_new_nmerodedocumento: item["aa.new_nmerodedocumento"],
                            cuenta_razon_social: item["aa.name"],
                            cuenta_email: item["aa.emailaddress1"],
                            new_porcentajedeparticipacion: item.new_porcentajedeparticipacion,
                            new_personaexpuestapoliticamente: item["ab.new_personaexpuestapoliticamente"],
                            new_cargofuncinjerarquayenteendondetrabaja: item["ab.new_cargofuncinjerarquayenteendondetrabaja"],
                            new_relacinconlapersonaexpuestapolticamente: item["ab.new_relacinconlapersonaexpuestapolticamente"],
                            new_fechadealtabaja: item["ab.new_fechadealtabaja"],
                            new_fechadebaja: item["ab.new_fechadebaja"],
                            new_entedondetrabaja: item["ab.new_entedondetrabaja"]
                        }
                        relaciones.push(relacion)
                    })
                    setTimeout(() => {
                        setRelacionesFETCH(relaciones)
                        if (retrieveRelacionesSelector == true) {
                            setRetrieveRelaciones(true)
                        }
                    }, 1500)
                } else if (token != undefined && token != '' && llamadaRelaciones === false) {
                    obtenerRelacionesFetch()
                    setLlamadaRelaciones(true)
                } else if (relacionesFetchSelector.length == 0 && retrieveRelacionesSelector == true) {
                    setRetrieveRelaciones(true)
                }
            }
        } catch (err) {
            // console.log(err)
        }
    }, [relacionesFetchSelector, accountid, token])

    React.useEffect(() => {
        try {
            if (accountid !== '') {
                if (documentosCuentasSelector != undefined && documentosCuentasSelector.length > 0) {
                    var arrayDocumentosXcuentas = []
                    documentosCuentasSelector.forEach(item => {
                        var documento = {
                            createdon: item.createdon,
                            new_documentacionporcuentaid: item.new_documentacionporcuentaid,
                            new_fechadevencimiento: item.new_fechadevencimiento,
                            new_name: item.new_name,
                            new_vinculocompartido: item.new_vinculocompartido,
                            new_visibleenportal: item.new_visibleenportal,
                            statuscode: item.statuscode,
                            statuscodeNOMBRE: item["statuscode@OData.Community.Display.V1.FormattedValue"],
                            _new_cuentaid_value: item["_new_cuentaid_value@OData.Community.Display.V1.FormattedValue"]
                        }
                        arrayDocumentosXcuentas.push(documento)
                    })
                    setTimeout(() => {
                        setCarpetas(arrayDocumentosXcuentas)
                        if (retrieveDocsExitoSelector == true) {
                            setRetrieveDocs(true)
                        }
                    }, 100)
                } else if (token != undefined && token != '' && llamadaDocumentosCuentas === false) {
                    dispatch(obtenerDocumentosPorCuenta(token, accountid))
                    setLlamadaDocumentosCuentas(true)
                } else if (documentosCuentasSelector.length == 0 && retrieveDocsExitoSelector == true) {
                    setRetrieveDocs(true)
                    setLoadingDataOnb(false)
                }
            }
        } catch (err) {
            // console.log(err)
        }
    }, [documentosCuentasSelector, accountid, token, retrieveDocsExitoSelector])

    // React.useEffect(() => {
    //     debugger
    //     ESTADIOONBOARDING.push({ name: "Datos mi cuenta", value: 50, texto: "Pendiente" })
    //     COLORSEstados.push("#43a047")
    //     if (carpetas.filter((carpeta) => carpeta.new_visibleenportal == true).length > 0) {
    //         if (carpetas.filter((carpeta) => carpeta.new_visibleenportal == true &&
    //             carpeta.statuscodeNOMBRE == "Pendiente").length > 0) {
    //             ESTADIOONBOARDING.push({ name: "Documentación", value: 50, texto: "Pendiente" })
    //             COLORSEstados.push("#43a047")
    //         } else {
    //             ESTADIOONBOARDING.push({ name: "Documentación", value: 50, texto: "Completo" })
    //             COLORSEstados.push("#ffab00")
    //         }
    //     }
    // }, [carpetas])

    React.useEffect(() => {
        try {
            if (accountid) {
                if (garantiasSelector?.length > 0) {
                    setTimeout(() => {
                        setGarantias(garantiasSelector.sort((a, b) => b - a).slice(0, 4))
                    }, 1000)
                } else if (token != undefined && token != '' && llamadaGarantias === false) {
                    dispatch(obtenerGarantiasFetch(accountid, token))
                    setLlamadaGarantias(true)
                }
            }
        } catch (err) {
            // console.log(err)
        }
    }, [garantiasSelector, accountid, token])

    const obtenerLimite = async () => {
        dispatch(obtenerTodosLimitesPorLineas(token, accountid))
    }

    const obtenerRelacionesFetch = async () => {
        dispatch(obtenerRelacionesFETCH(accountid, token))
    }

    //drawer header es necesario para que tome bien centrado los componentes
    const DrawerHeader = styled('div')(({ theme }) => ({
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
    }));

    //############ ACORDEON //############

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    const IrCarpetaDigital = (e) => {
        e.preventDefault()
        navigate('/midocumentaciondigital')
    }

    const IrMiCuenta = (e) => {
        e.preventDefault()
        navigate('/micuenta')
    }

    const IrGarantias = (e) => {
        e.preventDefault()
        navigate('/misgarantias')
    }

    const IrLineas = (e) => {
        e.preventDefault()
        navigate('/mislineas')
    }

    const Accordion = styled((props) => (
        <MuiAccordion disableGutters elevation={0} square {...props} />
    ))(({ theme }) => ({
        border: `1px solid ${theme.palette.divider}`,
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
    }));

    const AccordionSummary = styled((props) => (
        <MuiAccordionSummary
            expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
            {...props}
        />
    ))(({ theme }) => ({
        backgroundColor:
            theme.palette.mode === 'dark'
                ? 'rgba(255, 255, 255, .05)'
                : 'rgba(0, 0, 0, .03)',
        flexDirection: 'row-reverse',
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(90deg)',
        },
        '& .MuiAccordionSummary-content': {
            marginLeft: theme.spacing(1),
        },
    }));

    const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
        padding: theme.spacing(2),
        borderTop: '1px solid rgba(0, 0, 0, .125)',
    }));
    console.log("usuario.estado != '100000000'", usuario.estado != '100000000')
    return (
        <Box component="" sx={{ flexGrow: 1, p: 3 }}>
            {/* <DrawerHeader /> */}
            <Fade bottom>
                <Paper elevation={4}>
                    {
                        usuario.estado != '100000000' ?
                            // <Typography sx={{ mb: 3 }} variant="h5">Bienvenido, su proceso de onboarding se encuentra en estado pendiente.</Typography>
                            <Box sx={{ mb: 4 }}>
                                <Alert severity="warning" sx={{ mb: 2 }}>
                                    <AlertTitle>Bienvenido</AlertTitle>
                                    Su proceso de onboarding se encuentra en estado <strong>pendiente</strong>.
                                </Alert>
                            </Box>
                            :
                            <Alert variant="outlined" severity="success" sx={{ mb: 2 }}>
                                <AlertTitle>Bienvenido!</AlertTitle>
                                <strong>Resumen de posición</strong>
                            </Alert>
                        // <Typography sx={{ mb: 2 }}>Bienvenido - Resumen de posición</Typography>
                    }
                </Paper>
                {/*  WorkIcon */}
                {/* <Grid container spacing={2}>
                    {
                        usuario.estado != '100000000' ?
                            null
                            : (
                                <>
                                    <Grid item xs={12} sm={6} md={5}>
                                        <Grid sx={{ pb: 1, px: 0, pt: 0, m: 0 }}>
                                            {/* <Card> */}
                                            {/* <Paper elevation={4} sx={{ height: '152px', py: 1, px: 1 }}>
                                                <Grid container wrap="nowrap" spacing={2} sx={{ p: 1, m: 0 }}>
                                                    <Grid item sx={{ p: 0, m: 0 }}>
                                                        <Stack direction="row" spacing={2}>
                                                            <Avatar sx={{ bgcolor: '#43a047', color: '#fff' }} variant="rounded">
                                                                <WorkIcon />
                                                            </Avatar>
                                                        </Stack>
                                                        <Typography variant="h5"
                                                            sx={{
                                                                mt: 1,
                                                                color:
                                                                    theme.palette.mode === 'dark' ?
                                                                        '#f5f5f5' : '#3a3541de',
                                                                fontWeight: 800,
                                                                fontSize: '20px'
                                                            }}
                                                        >
                                                            {

                                                                limite.new_topeporlineacomercial ?
                                                                    dollarUS.format(limite.new_topeporlineacomercial)
                                                                    : '$0'
                                                            }
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                color:
                                                                    theme.palette.mode === 'dark' ?
                                                                        '#fefefe' : '#3a3541de',
                                                                fontWeight:
                                                                    theme.palette.mode === 'dark' ?
                                                                        400 : 600,
                                                                mt: 1,
                                                                fontSize: '14px'
                                                            }}
                                                        >Tope por línea comercial</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                        </Grid> */}
                                        {/* <Grid sx={{ px: 0, py: 0, m: 0, mt: 1 }}>
                                            <Paper elevation={4} sx={{ height: '152px', pt: 2, px: 1, m: 0 }}>
                                                <Grid container wrap="nowrap" spacing={2} sx={{ p: 1, m: 0 }}>
                                                    <Grid item sx={{ p: 0, m: 0 }}>
                                                        <Stack direction="row" spacing={2}>
                                                            <Avatar sx={{ bgcolor: '#43a047', color: '#fff' }} variant="rounded">
                                                                <MovingIcon />
                                                            </Avatar>
                                                        </Stack>
                                                        <Typography variant="h5"
                                                            sx={{
                                                                mt: 1,
                                                                color:
                                                                    theme.palette.mode === 'dark' ?
                                                                        '#f5f5f5' : '#3a3541de',
                                                                fontWeight: 800,
                                                                fontSize: '20px'
                                                            }}
                                                        >

                                                            {
                                                                limite.new_montoutilizadogeneral ? dollarUS.format(limite.new_montoutilizadogeneral)
                                                                    : '$0'
                                                            }
                                                        </Typography>
                                                        <Typography
                                                            sx={{
                                                                color:
                                                                    theme.palette.mode === 'dark' ?
                                                                        '#fefefe' : '#3a3541de',
                                                                fontWeight:
                                                                    theme.palette.mode === 'dark' ?
                                                                        400 : 600,
                                                                mt: 1,
                                                                fontSize: '14px'
                                                            }}
                                                        >Monto utilizado general</Typography>
                                                    </Grid>
                                                </Grid>
                                            </Paper>
                                            {/* </Card> */}
                                        {/* </Grid>
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={7} >
                                        <Paper elevation={4} sx={{ height: '320px', py: 2, px: 1 }}>
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                                height: '100%',
                                            }}>
                                                {
                                                    limiteLoading ? (
                                                        <LoaderAnimado />
                                                    ) : !limiteLoading && dataLimite.length > 0 ? (
                                                        <GraficoPie data={dataLimite} COLORS={COLORS} />
                                                    ) : !limiteLoading && limiteSelector.length === 0 && llamadaLimites == true ?
                                                        <ExtensionOffOutlinedIcon sx={{ fontSize: "40px" }} /> : null
                                                }
                                            </Box>
                                        </Paper>
                                    </Grid> */}
                                {/* </>
                            )
                    }
                </Grid>  */}
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={7}>
                        {
                            usuario.estado != '100000000' ?
                                <Grid sx={{ p: 0, m: 0, mb: 1, mt: { sx: 0, md: 1 } }}>
                                    <Paper elevation={4} sx={{ height: '340px', pt: 1, pb: 2, px: 1 }}>
                                        <Box sx={{ m: 0, p: 0 }}>
                                            <Typography sx={{
                                                color:
                                                    theme.palette.mode === 'dark' ?
                                                        '#f5f5f5' : '#3a3541de',
                                                fontWeight:
                                                    theme.palette.mode === 'dark' ?
                                                        500 : 600,
                                            }}>
                                                Mi Onboarding
                                            </Typography>
                                        </Box>
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '100%',
                                            height: '100%',
                                        }}>
                                            {
                                                loadingDataOnb ? (
                                                    <LoaderAnimado />
                                                ) : !loadingDataOnb && ESTADIOONBOARDING.length > 0 ? (
                                                    <GraficoPieTexto data={ESTADIOONBOARDING} COLORS={COLORSEstados} />
                                                ) : <ExtensionOffOutlinedIcon sx={{ fontSize: "40px" }} />
                                            }
                                        </Box>
                                    </Paper>
                                </Grid>
                                : null
                        }
                        <Grid sx={{ p: 0, m: 0, mt: 1 }}>
                            <Paper elevation={4}>
                                <Box>
                                    <Typography sx={{
                                        p: 2,
                                        color:
                                            theme.palette.mode === 'dark' ?
                                                '#f5f5f5' : '#3a3541de',
                                        fontWeight:
                                            theme.palette.mode === 'dark' ?
                                                500 : 600,
                                    }}>
                                        Relaciones de Vinculación
                                        <Tooltip title={<Typography sx={{ color: '#fff' }}>Ir a Mi Cuenta</Typography>} sx={{ mt: 3 }} >
                                            <IconButton onClick={(e) => IrMiCuenta(e)} sx={{ ml: 1 }}>
                                                <PeopleAltIcon style={{ color: '#03a9f4' }} />
                                            </IconButton>
                                        </Tooltip>
                                    </Typography>
                                    <Divider />
                                </Box>
                                <List
                                    sx={{
                                        width: '100%',
                                        overflow: 'auto',
                                        maxHeight: 450,
                                        // bgcolor: 'background.paper',
                                        '& ul': { padding: 0 },
                                    }}
                                    className="lista-scrollbar"
                                    subheader={<li />}
                                >
                                    {
                                        loadingRelaciones == true && relacionesFETCH.length == 0 ||
                                            retrieveRelaciones === false && relacionesFETCH.length == 0 ?
                                            <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                                height: '100%',
                                                py: 2
                                            }}>
                                                <LoaderAnimado />
                                            </Box>
                                            : relacionesFETCH.filter((itemRelacion) => itemRelacion.statecode == "0").length > 0 ?
                                                relacionesFETCH.filter((itemRelacion) => itemRelacion.statecode == "0").map((item) => {
                                                    return (
                                                        <>
                                                            <ListItem
                                                                key={item.new_participacionaccionariaid}
                                                            >
                                                                <ListItemAvatar>
                                                                    <Avatar alt="Remy Sharp" src="" />
                                                                </ListItemAvatar>
                                                                <ListItemText
                                                                    primary={item._new_cuentacontactovinculado_value}
                                                                    secondary={item.new_tipoderelacion_value}
                                                                />

                                                            </ListItem>
                                                            <Divider variant="inset" component="li" />
                                                        </>

                                                    )
                                                }) : <Box sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    width: '100%',
                                                    height: '100%',
                                                    py: 2
                                                }}>
                                                    <Typography>
                                                        No hay registros para mostrar
                                                    </Typography>
                                                </Box>
                                    }
                                </List>
                            </Paper>
                        </Grid>
                        {
                            usuario.estado != '100000000' ?
                                null
                                : (
                                    <Grid item xs={12} sx={{ p: 0, m: 0 }}>
                                        <Paper elevation={4} sx={{ mt: 2 }}>

                                            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                                    <Typography
                                                        sx={{
                                                            color:
                                                                theme.palette.mode === 'dark' ?
                                                                    '#f5f5f5' : '#3a3541de',
                                                            fontWeight:
                                                                theme.palette.mode === 'dark' ?
                                                                    500 : 600,
                                                        }}
                                                    >Garantías
                                                        <Tooltip title={<Typography sx={{ color: '#fff' }}>Ir a Mis Garantías</Typography>} sx={{ mt: 3 }} >
                                                            <IconButton onClick={(e) => IrGarantias(e)} sx={{ ml: 1 }}>
                                                                <AutoAwesomeMotionIcon style={{ color: '#03a9f4' }} />
                                                            </IconButton>
                                                        </Tooltip></Typography>
                                                </AccordionSummary>
                                                <AccordionDetails sx={{ p: 0 }}>
                                                    <List
                                                        sx={{
                                                            width: '100%',
                                                            overflow: 'auto',
                                                            maxHeight: 250,
                                                            '& ul': { padding: 0 },
                                                        }}
                                                        className="lista-scrollbar"
                                                        subheader={<li />}
                                                    >

                                                        <ListSubheader
                                                            sx={{
                                                                color:
                                                                    theme.palette.mode === 'dark' ?
                                                                        '#f5f5f5' : '#3a3541de',
                                                                fontWeight:
                                                                    theme.palette.mode === 'dark' ?
                                                                        500 : 600,
                                                            }}
                                                        >{`Garantías - Fecha vencimiento`}</ListSubheader>
                                                        {
                                                            garantias.map((item) => {
                                                                return (
                                                                    <>
                                                                        <ListItem
                                                                            key={item.new_documentacionporcuentaid}
                                                                        >

                                                                            <ListItemText
                                                                                primary={item.new_name}
                                                                                secondary={item.new_fechadevencimiento}
                                                                            />

                                                                        </ListItem>
                                                                        <Divider />
                                                                    </>

                                                                )
                                                            })
                                                        }


                                                    </List>
                                                </AccordionDetails>
                                            </Accordion>
                                        </ Paper>
                                    </Grid>

                                )
                        }
                    </Grid>
                    <Grid item xs={12} sm={6} md={5} sx={{ mt: { xs: 0, md: 1 } }}>
                        <Paper elevation={4} >
                            <Box>
                                <Typography sx={{
                                    p: 2,
                                    color:
                                        theme.palette.mode === 'dark' ?
                                            '#f5f5f5' : '#3a3541de',
                                    fontWeight:
                                        theme.palette.mode === 'dark' ?
                                            500 : 600,
                                }}>
                                    Carpeta Digital
                                    <Tooltip title={<Typography sx={{ color: '#fff' }}>Ir a Carpeta Digital</Typography>} sx={{ mt: 3 }} >
                                        <IconButton onClick={(e) => IrCarpetaDigital(e)} sx={{ ml: 1 }}>
                                            <CloudDoneIcon style={{ color: '#03a9f4' }} />
                                        </IconButton>
                                    </Tooltip>
                                </Typography>
                                <Divider />
                            </Box>
                            <List
                                sx={{
                                    width: '100%',
                                    overflow: 'auto',
                                    maxHeight: 450,
                                    '& ul': { padding: 0 },
                                }}
                                className="lista-scrollbar"
                                subheader={<li />}
                            >
                                {
                                    loading == true && carpetas.length == 0 || retrieveDocs === false && carpetas.length == 0 ?
                                        <Box sx={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '100%',
                                            height: '100%',
                                            py: 2
                                        }}>
                                            <LoaderAnimado />
                                        </Box>
                                        : carpetas.filter((carpeta) => carpeta.new_visibleenportal == true).length > 0 ?
                                            carpetas.filter((carpeta) => carpeta.new_visibleenportal == true).map((item) => {
                                                return (
                                                    <>
                                                        <ListItem
                                                            key={item.new_documentacionporcuentaid}
                                                        >

                                                            <ListItemText
                                                                primary={
                                                                    <Grid container sx={{ p: 0, m: 0 }}>
                                                                        <Grid xs={10} sx={{ p: 0, m: 0 }}>
                                                                            {item.new_name}
                                                                            <Stack direction="row" spacing={1}>
                                                                                {
                                                                                    item.statuscodeNOMBRE == "Recibido" ?
                                                                                        <Chip label={item.statuscodeNOMBRE} sx={{
                                                                                            bgcolor: '#43a047',
                                                                                            color: '#fff'
                                                                                        }} /> :
                                                                                        <Chip label={item.statuscodeNOMBRE} sx={{
                                                                                            bgcolor: '#ffa726',
                                                                                            color: '#fff'
                                                                                        }} />
                                                                                }
                                                                            </Stack>
                                                                        </Grid>
                                                                        <Grid sx={{ p: 0, m: 0, display: 'flex', alignItems: 'center' }}>
                                                                            {item.new_vinculocompartido ?
                                                                                <Tooltip title={<Typography sx={{ color: '#fff' }}>Abrir Carpeta</Typography>} sx={{ mt: 3 }} >
                                                                                    <IconButton edge="end" aria-label="" onClick={() => window.open(item.new_vinculocompartido, "_blank")}>
                                                                                        <FileOpenIcon />
                                                                                    </IconButton>
                                                                                </Tooltip>
                                                                                : null
                                                                            }
                                                                            {
                                                                                item.statuscodeNOMBRE == "Pendiente" ?
                                                                                    <Tooltip title={<Typography sx={{ color: '#fff' }}>Ir a Carpeta Digita</Typography>} sx={{ mt: 3 }} >
                                                                                        <IconButton edge="end" aria-label="" onClick={(e) => IrCarpetaDigital(e)}>
                                                                                            <ExitToAppIcon />
                                                                                        </IconButton>
                                                                                    </Tooltip>
                                                                                    : null
                                                                            }

                                                                        </Grid>
                                                                    </Grid>
                                                                }
                                                            />
                                                        </ListItem>
                                                        <Divider />
                                                    </>

                                                )
                                            }) : <Box sx={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                width: '100%',
                                                height: '100%',
                                                py: 2
                                            }}>
                                                <Typography>
                                                    No hay registros para mostrar
                                                </Typography>
                                            </Box>

                                }
                            </List>
                        </Paper>
                    </Grid>
                </Grid>
            </Fade>
        </Box >
    )
}

export default ResumenPosicion