import React, { useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    TextField,
} from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2' // Grid version 2
import Box from '@mui/material/Box'
import { CrearSociedadBolsaSocio, ModificarSociedadBolsaSocio, obtenerSociedadeDeBolsa, obtenerSociedadesXsocio } from '../../Redux/Cuenta'
import * as yup from 'yup'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import CustomTextField from '../CustomTextField'
import CustomSearchSelect from '../CustomSearchSelect'
import TokenContext from '../../context/TokenContext'

const ModalSociedadBolsa = ({ open, close, modo, id, objeto }) => {
    const dispatch = useDispatch()
    const token = useContext(TokenContext)
    const [desabilitado, setDesabilitado] = React.useState(false)
    const [opcionesSociedadBolsa, setOpcionesSociedadBolsa] = React.useState([])
    const [llamadaSociedadesDeBolsa, setLlamadaSociedadesDeBolsa] = React.useState(false)

    const accountid = useSelector(store => store.usuarios.accountid)
    const sociedadesDeBolsaSelector = useSelector(store => store.cuenta.sociedadDeBolsa)
    const resultado = useSelector(store => store.cuenta.crearCuentaSociedad)
    const resultadoModificacion = useSelector(store => store.cuenta.modificarSociedadBolsa)
    const resultadoInactivacion = useSelector(store => store.cuenta.inactivarCuentaSocioMensaje)

    const defaultValues = {
        sociedades: null,
        cuentaComitente: '',
    };

    const validationSchema = yup.object().shape({
        sociedades: yup.object().required(),
        cuentaComitente: yup.string().required("Required")
    });

    const methods = useForm({
        shouldUnregister: false,
        defaultValues,
        resolver: yupResolver(validationSchema),
        mode: "onChange"
    });

    const {
        handleSubmit,
        reset,
        // trigger 
    } = methods;

    const ProcesarRelacion = (datos) => {
        dispatch(CrearSociedadBolsaSocio(accountid, datos, token))
        setDesabilitado(!desabilitado)
    }

    const dispatchearAccion = () => {
        dispatch(obtenerSociedadesXsocio(accountid))
    }

    React.useEffect(() => {
        //selector de opciones de sociedad de bolsa
        if (sociedadesDeBolsaSelector.length > 0 && llamadaSociedadesDeBolsa === true) {
            let opcionesSociedades = []
            sociedadesDeBolsaSelector.forEach(item => {
                let sociedad = { label: item.new_name, value: item.new_sociedaddebolsaid }
                opcionesSociedades.push(sociedad)
            })
            setOpcionesSociedadBolsa(opcionesSociedades)
        } else if (llamadaSociedadesDeBolsa === false) {
            obtenerTodasSociedadDeBolsa()
            setLlamadaSociedadesDeBolsa(true)
        }
    }, [sociedadesDeBolsaSelector])


    React.useEffect(() => {
        if (modo === 'editar') {
            methods.reset(objeto)
        } else {
            methods.reset(defaultValues)
        }
    }, [modo])

    React.useEffect(() => {
        debugger
        if (resultado !== undefined && (resultado == "EXITO" || resultado == "ERROR")) {
            setTimeout(() => {
                setDesabilitado(!desabilitado)
            }, 1000)
        }
    }, [resultado])

    React.useEffect(() => {
        if (resultadoModificacion !== undefined) {
            if (resultadoModificacion !== '' && (resultadoModificacion == "EXITO" || resultadoModificacion == "ERROR")) {
                setTimeout(() => {
                    setDesabilitado(!desabilitado)
                }, 1000)
            }
        }
    }, [resultadoModificacion])

    // React.useEffect(() => {
    //     debugger
    //     if (resultadoInactivacion !== undefined) {
    //         if (resultadoInactivacion !== '' && (resultadoInactivacion == "EXITO" || resultadoInactivacion == "ERROR")) {
    //             setDesabilitado(!desabilitado)
    //         }
    //     }
    // }, [resultadoInactivacion])

    const ProcesarEdicion = (datos) => {
        dispatch(ModificarSociedadBolsaSocio(id, accountid, datos, token))
        setDesabilitado(!desabilitado)
    }


    const obtenerTodasSociedadDeBolsa = () => {
        dispatch(obtenerSociedadeDeBolsa(token))
    }


    return (
        <Dialog open={open}>
            <DialogTitle textAlign="center">{modo === 'editar' ? 'Editar sociedad de bolsa' : 'Agregar Sociedad de Bolsa'}</DialogTitle>
            <FormProvider {...methods}>
                <form>
                    <DialogContent>
                        <Box
                            component="form"
                            // sx={{
                            //     '& .MuiTextField-root': { m: 1, width: '25ch' },
                            // }}
                            noValidate
                            autoComplete="off"
                        >
                            <Grid
                                container
                                spacing={1}
                                direction="row"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                alignContent="stretch"
                                wrap="wrap"

                            >
                                {/* <Grid item sx={{ pt: 0 }}> */}
                                <CustomSearchSelect
                                    name="sociedades"
                                    lab="Sociedades"
                                    helperText="Por favor seleccione un valor"
                                    options={opcionesSociedadBolsa}
                                    rules={{ required: "Required!" }}
                                    req="true"
                                    fullWith
                                />
                                {/* </Grid>

                                <Grid item sx={{ pt: 2 }}> */}
                                <CustomTextField
                                    Component={TextField}
                                    label="Cuenta Comitente"
                                    name="cuentaComitente"
                                    helperText="Escribe el número de cuenta"
                                    type="number"
                                    fullWith
                                    maxLength='10'
                                />

                                {/* </Grid> */}
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions sx={{ p: '1.25rem' }}>
                        <Button
                            onClick={close}
                            disabled={desabilitado}
                        >Cerrar</Button>
                        {
                            modo === 'editar' ?
                                (
                                    <Button color="secondary"
                                        onClick={handleSubmit(ProcesarEdicion)}
                                        variant="contained"
                                        disabled={desabilitado}
                                    >
                                        Editar
                                    </Button>
                                )
                                :
                                (
                                    <Button color="secondary"
                                        onClick={handleSubmit(ProcesarRelacion)}
                                        variant="contained"
                                        disabled={desabilitado}
                                    >
                                        Agregar
                                    </Button>
                                )
                        }

                    </DialogActions>
                </form>
            </FormProvider>
        </Dialog >
    )
}

export default ModalSociedadBolsa