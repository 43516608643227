import React, { useEffect } from 'react'
import { Container } from '@mui/material'
import RegistroSgr from '../Components/registro/RegistroSgr'
import fondo from "../img/fondoMisSGR.png"
import { useDispatch, useSelector } from 'react-redux'
import { loginToken } from '../Redux/Token'

export const RegistroSGR = () => {
    const dispatch = useDispatch()
    const token = useSelector(store => store.token.token)

    useEffect(() => {
        dispatch(loginToken())
      },[])

    return (
            <Container 
                sx={{
                backgroundImage: `url(${fondo})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover",
                minWidth:"100vw",
                minHeight:"100vh",
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent:"center"
                }}
            >
                <RegistroSgr token={token?.token} />
            </Container>
    )
}
