import React, { useState } from "react"
import { Dialog, Box, Typography, DialogTitle, IconButton, Divider, CircularProgress, Tooltip } from "@mui/material"
import { useTheme } from '@mui/material/styles';
import CloseIcon from "@mui/icons-material/Close"
import { useSelector } from "react-redux"
import { useEffect } from "react"
import { COLUMNSDOCUMENTOS, COLUMNSDOCUMENTOSDESEMBOLSOS } from "../../Columnas/ColumnasDocumentosGarantias"
import Tabla from "../Tabla"
import TableCRUD from "../TableCRUD";

const ModalDocuDesembolsosAnteriores = ({ open, setOpenModal, idGarantia }) => {
  const theme = useTheme();
  const documentosGarantiasPorId = useSelector((store) => store.garantias.documentosGarantiasPorId)
  const resultadoDocumentoPorID = useSelector((store) => store.garantias.resultadoDocumentoPorID)
  const [documentos, setDocumentos] = useState([])

  useEffect(() => {
    if (documentosGarantiasPorId?.length > 0 && open && resultadoDocumentoPorID === "EXITO") {
      let docsGaran = []
      documentosGarantiasPorId.forEach((item) => {
        if (item["filename"] != undefined) {
          let garan = {
            garantia:
              item["ae.new_garantia@OData.Community.Display.V1.FormattedValue"],
              filename: item.filename,
              type: item["ae.new_tipo@OData.Community.Display.V1.FormattedValue"],
              annotationid: item.annotationid,
          };
          docsGaran.push(garan)
        }
      });
      setDocumentos(docsGaran)
    }
  }, [documentosGarantiasPorId, documentos, open, resultadoDocumentoPorID])

  const handleCloseModal = () => {
    setDocumentos([])
    setOpenModal(false)
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="xl"
      >
        <Box sx={{ backgroundColor: theme.palette.background.default }}>
          <Box sx={{
            display: "flex", alignItems: "center", justifyContent: "space-between"
          }}>
            {resultadoDocumentoPorID === "EXITO" ? (
              <DialogTitle id="alert-dialog-title" sx={{ fontSize: "1rem", display: "flex", justifyContent: "flex-start" }}>
                {documentos?.length > 0 ? documentos[0].garantia : "-"}
              </DialogTitle>
            ) : (
              <DialogTitle id="alert-dialog-title" sx={{ fontSize: "1rem", display: "flex", justifyContent: "flex-start" }}>
                <CircularProgress size="1.5rem" />
              </DialogTitle>
            )}
            <Tooltip title={<Typography sx={{ color: '#fff' }}>Cerrar</Typography>} >
              <IconButton
                edge="end"
                color="warning"
                onClick={handleCloseModal}
                aria-label="close"
                sx={{ width: 40, height: 40, mr: 2 }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <Divider />
          <Box sx={{ my: 2 }}>
            {resultadoDocumentoPorID === "EXITO" ? (
              <Box component="div" sx={{ mx: 2 }}>
                {
                  documentos?.length > 0 ?
                    <TableCRUD columnas={COLUMNSDOCUMENTOSDESEMBOLSOS}
                      lineas={documentos} /> : null
                }
              </Box>
            ) : (
              <Box component="div" sx={{ display: "flex", justifyContent: "flex-start", minWidth: "40vw", m: 2 }}>
                <CircularProgress size="1.5rem" sx={{ ml: 1, mx: 1 }} />
              </Box>
            )}
          </Box>
        </Box>
      </Dialog>
    </div>
  );
};

export default ModalDocuDesembolsosAnteriores;
