import React, { useMemo } from 'react'
import MaterialReactTable from 'material-react-table';
import {
    Typography,
    Box,
    Button,
    IconButton,
    Tooltip,
} from '@mui/material';
//Import Material React Table Translations
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { grey } from '@mui/material/colors';
import { darken, useTheme, createTheme, ThemeProvider } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Delete, Edit } from '@mui/icons-material';

const TablaIndicadores = ({ columnas, lineas, titulo, handleOpen, acciones = true }) => {
    const columns = useMemo(() => columnas, [])
    const [data, setData] = React.useState(lineas)
    const [pagination, setPagination] = React.useState({
        pageIndex: 0,
        pageSize: 3
    });
    const globalTheme = useTheme();

    const tableTheme = useMemo(
        () =>
            createTheme({
                palette: {
                    mode: globalTheme.palette.mode, //let's use the same dark/light mode as the global theme
                    primary: globalTheme.palette.secondary, //swap in the secondary color as the primary for the table
                    info: {
                        main: 'rgb(255,122,0)', //add in a custom color for the toolbar alert background stuff
                    },
                    background: {
                        default:
                            globalTheme.palette.mode === 'light'
                                ? grey[200] //random light yellow color for the background in light mode
                                : '#121212', //pure black table in dark mode for fun
                    },
                },
                typography: {
                    fontFamily: ["Inter", "sans-serif"].join(","),
                    fontWeight: 'Normal',
                    button: {
                        textTransform: 'none', //customize typography styles for all buttons in table by default
                        fontSize: '0.1rem',
                    },
                    color: globalTheme.palette.primary,
                },
                components: {
                    MuiTooltip: {
                        styleOverrides: {
                            tooltip: {
                                fontSize: '0.8rem', //override to make tooltip font size larger
                            },
                        },
                    },
                    MuiSwitch: {
                        styleOverrides: {
                            thumb: {
                                color: 'pink', //change the color of the switch thumb in the columns show/hide menu to pink
                            },
                        },
                    },
                },
            }),
        [globalTheme],
    );

    React.useEffect(() => {
        if (lineas.length > 0) {
            setData(lineas)
        }
    }, [lineas])

    const openModal = (row) => {
        var id = row.getValue('id')
        let editar = true
        handleOpen(id, editar)
    }

    const abrirModalAgregar = () => {
        let id = ''
        let editar = false
        handleOpen(id, editar)
    }

    return (
        <MaterialReactTable
            muiTablePaginationProps={{
                rowsPerPageOptions: [5, 10],
                showFirstLastPageButtons: false
            }}
            onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
            state={{ pagination }}
            columns={columns}
            data={data}
            muiTableBodyProps={{
                sx: (theme) => ({
                    '& tr:nth-of-type(odd)': {
                        backgroundColor: darken(theme.palette.background.default, 0.1),
                    },
                }),
            }}
            muiTablePaperProps={{
                sx: (theme) => ({
                    borderRadius: '0.55rem',
                    border: darken(theme.palette.background.default, 0.1),
                    bgcolor: darken(theme.palette.background.default, 0.1),
                    // maxWidth: '1000px',
                    m: 'auto',
                }),
            }}
            enableFullScreenToggle={false}
            enableColumnFilterModes={true}
            enableColumnOrdering={true} //mueve columnas
            enableColumnActions={true} //aciones de columnas como filtro individual
            enableColumnFilters={true}
            enableSorting={true}
            enableColumnResizing
            columnResizeMode="onChange"
            // enableGrouping
            enablePinning
            // enableRowActions={acciones}
            enableStickyHeader
            editingMode="modal" //default
            initialState={{ showColumnFilters: false, columnVisibility: { id: false }, density: 'compact', }}
            positionToolbarAlertBanner="bottom"
            //idiomas
            localization={MRT_Localization_ES}
            defaultColumn={{
                maxSize: 400,
                minSize: 40,
            }}
            enableEditing={true}
            // displayColumnDefOptions={{
            //     'mrt-row-actions': {
            //       header: 'Acciones', //change header text
            //       size: 120, //make actions column wider
            //     },
            //   }}
            renderRowActions={({ row, table }) => (
                <Box sx={{ display: 'flex' }}>
                    <Tooltip arrow placement="left" title="Ver/Editar">
                        <IconButton >
                            <VisibilityIcon onClick={() => openModal(row)} />
                        </IconButton>
                    </Tooltip>
                    {/* <Tooltip arrow placement="left" title="Editar">
                        <IconButton
                        // onClick={() => abrilModalEdicion(row)}
                        >
                            <Edit />
                        </IconButton>
                    </Tooltip> */}
                    {/* <Tooltip arrow placement="left" title="Borrar">
                        <IconButton color="error"
                        // onClick={() => handleDeleteRow(row)}
                        >
                            <Delete />
                        </IconButton>
                    </Tooltip> */}
                </Box>
            )}
            //OPCIONES
            renderTopToolbarCustomActions={() => (
                <>
                    <Button
                        style={{ backgroundColor: "#ad387f", color: "#FFFFFF" }}
                        onClick={() => abrirModalAgregar()}
                        variant="contained"
                    >
                        <PlaylistAddIcon />
                    </Button>
                    <Typography variant="subtitle" p="2px">{titulo}</Typography>
                </>
            )}
        />
    )
}

export default TablaIndicadores