import React, { useEffect, useState, useContext } from 'react'
import { Grid, Typography, Box, useTheme } from '@mui/material'
import Indicador from '../Components/Indicador'
import WorkHistoryIcon from '@mui/icons-material/WorkHistory'
import WorkIcon from '@mui/icons-material/Work'
import AttachMoneyIcon from '@mui/icons-material/AttachMoney'
import { MiSgr } from '../Components/MiSgr'
import Fade from 'react-reveal/Fade'
import { useDispatch, useSelector } from 'react-redux'
import { obtenerSeriesYGarantiasInicio } from '../Redux/Series'
import { obtenerCuentaAdheridaGarantiaFetchInicio } from '../Redux/Cuenta'
import LoaderAnimado from '../Components/loaderAnimado/LoaderAnimado'
import TokenContext from '../context/TokenContext'
import GraficoLineaApex from '../Components/Graficos/GraficoLineaApex'
import GraficoBarraApex from '../Components/Graficos/GraficoBarraApex'

export const Inicio = ({ nameSGR }) => {
    const dispatch = useDispatch()
    const token = useContext(TokenContext)
    const theme = useTheme()
    //Selectores
    const seriesSelector = useSelector(store => store.series.seriesInicio)
    const cuentaConGarantiaSelector = useSelector(store => store.cuenta.cuentaConGarantiaInicio)
    const resultado = useSelector(store => store.series.resultado)
    //Hooks
    const [series, setSeries] = useState([])
    const [montoComprometido, setMontoComprometido] = useState(0)
    const [montoMonetizado, setMontoMonetizado] = useState(0)
    const [seriesEncontradas, setSeriesEncontradas] = useState(false)
    const [montoGlobal, setMontoGlobal] = useState(0)
    const [cantidadGarantias, setCantidadGarantias] = useState(0)
    const [datosGraficoComprometido, setDatosGraficoComprometido] = React.useState([])
    const [datosGraficoMonetizado, setDatosGraficoMonetizado] = React.useState([])
    const [datosGraficoVigente, setDatosGraficoVigente] = React.useState([])
    const [datosGraficoGlobal, setDatosGraficoGlobal] = React.useState([])
    const [opciones, setOpciones] = React.useState([])
    const [pymes, setPymes] = useState([])
    //Funciones
    let dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    useEffect(() => {
        dispatch(obtenerCuentaAdheridaGarantiaFetchInicio(token))
        dispatch(obtenerSeriesYGarantiasInicio(token))
        setSeriesEncontradas(false)
    }, [token])

    useEffect(() => {
        if (seriesSelector?.length > 0 && resultado == "EXITO") {
            setSeries(seriesSelector)
            indicadores(seriesSelector)
            graficos(seriesSelector)
        }
    }, [seriesSelector, resultado])

    useEffect(() => {
        if (cuentaConGarantiaSelector?.length > 0) {
            let estadioPymes = []
            var socioS = [...new Map(cuentaConGarantiaSelector.map(item => [item["accountid"], item])).values()]
            let estadios = [...new Set(socioS.map(item => item["new_estadodelsocio@OData.Community.Display.V1.FormattedValue"]))]
            estadios.forEach(elemento => {
                var estado = {
                    opcion: elemento,
                    cantidad: socioS.filter(pyme => pyme["new_estadodelsocio@OData.Community.Display.V1.FormattedValue"] == elemento).length
                }
                estadioPymes.push(estado)
            })
            setPymes(estadioPymes)
        }
    }, [cuentaConGarantiaSelector])

    const indicadores = (series) => {
        let montoComprometido = 0
        let montoMonetizado = 0
        series.filter(item => (item.statuscode == 1 || item.statuscode == 100000001) && item.new_dictamendelaval != 100000003).forEach(item => {
            if (item.new_montocomprometidodelaval) {
                montoComprometido += item.new_montocomprometidodelaval
            }
        })
        series.filter(garantia => garantia.new_fechadenegociacion != null && garantia.new_dictamendelaval != 100000003).forEach(item => {
            if (item.new_monto != null) {
                montoMonetizado += item.new_monto
            }
        })
        setMontoComprometido(montoComprometido)
        setMontoMonetizado(montoMonetizado)
    }

    const graficos = (series) => {
        var montoGlobalTotal = 0
        let Comprometidos = []
        let Monetizados = []
        let Vigentes = []
        let Globales = []
        let Acreedores = []
        let seriesUnicas = [...new Set(series.map(item => item["serie.new_name"]))]
        let garantiasUnicas = [...new Set(series.map(item => item["new_garantiaid"]))]
        if (garantiasUnicas.length > 0) {
            setCantidadGarantias(garantiasUnicas.length)
        }
        seriesUnicas.forEach(serie => {
            let nombreSeparado = serie.split('-')
            let nombreSerie = nombreSeparado[0]
            let nombreAcreedor = nombreSeparado[1]
            let seriesMontoGlobal = [...new Set(series.filter(series => series["serie.new_name"].trim() == serie.trim()).map(item =>
                item["serie.new_importetotalaavalar"]))]
            var garantiasXserie = [...new Set(series.filter(series => series["serie.new_name"].trim() == serie.trim()))]
            var montoComprometidoXserie = 0
            var montoMonetizadoXserie = 0
            var montoVigenteXserie = 0
            var montoGlobal = seriesMontoGlobal.length > 0 ? seriesMontoGlobal[0] : 0
            if (montoGlobal != 0) {
                montoGlobalTotal += montoGlobal
            }
            garantiasXserie.forEach(garantia => {
                if(garantia["new_montocomprometidodelaval"]){
                    if((garantia.statuscode == 1 || garantia.statuscode == 100000001) && garantia.new_dictamendelaval != 100000003){
                        montoComprometidoXserie += garantia["new_monto"]
                    }
                }
                if (garantia["new_monto"]) {
                    if (garantia["new_fechadenegociacion"] && garantia.new_dictamendelaval != 100000003) {
                        montoMonetizadoXserie += garantia["new_monto"]
                    }
                }
                if (garantia["new_saldovigente"] && garantia["new_fechadenegociacion"]) {
                    montoVigenteXserie += garantia["new_saldovigente"]
                }
            })
            var ObjComprometidos = {
                Serie: nombreSerie,
                Opcion: nombreAcreedor,
                Monto: montoComprometidoXserie
            }
            var ObjMonetizados = {
                Serie: nombreSerie,
                Opcion: nombreAcreedor,
                Monto: montoMonetizadoXserie
            }
            var ObjVigentes = {
                Serie: nombreSerie,
                Opcion: nombreAcreedor,
                Monto: montoVigenteXserie
            }
            var ObjGlobales = {
                Serie: nombreSerie,
                Opcion: nombreAcreedor,
                Monto: montoGlobal
            }
            Comprometidos.push(ObjComprometidos)
            Monetizados.push(ObjMonetizados)
            Vigentes.push(ObjVigentes)
            Globales.push(ObjGlobales)
            Acreedores.push(nombreAcreedor)
        })
        let acreedoresUnicos = [...new Set(Acreedores.map(item => item))]
        setOpciones(acreedoresUnicos)
        Comprometidos.sort((a, b) => (a.Serie > b.Serie) ? 1 : ((b.Serie > a.Serie) ? -1 : 0))
        Monetizados.sort((a, b) => (a.Serie > b.Serie) ? 1 : ((b.Serie > a.Serie) ? -1 : 0))
        Vigentes.sort((a, b) => (a.Serie > b.Serie) ? 1 : ((b.Serie > a.Serie) ? -1 : 0))
        Globales.sort((a, b) => (a.Serie > b.Serie) ? 1 : ((b.Serie > a.Serie) ? -1 : 0))
        setDatosGraficoComprometido(Comprometidos)
        setDatosGraficoMonetizado(Monetizados)
        setDatosGraficoVigente(Vigentes)
        setDatosGraficoGlobal(Globales)
        setMontoGlobal(montoGlobalTotal)
        setSeriesEncontradas(true)
    }

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3, py: 0 }}>
            <MiSgr nameSGR={nameSGR} />
            <Fade bottom>
                <Grid container>
                    {!seriesEncontradas ?
                        <Grid xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} item>
                            <LoaderAnimado />
                        </Grid>
                        : null
                    }
                    {
                        seriesEncontradas ?
                            <Grid xs={12} item>
                                <Typography variant="h6" sx={{ fontFamily: theme.typography.fontFamily, color: theme.typography.primary.main, mx: 1 }}>
                                    KPI del Sistema de Garantias Sindicadas
                                </Typography>
                            </Grid>
                            : null
                    }
                    {
                        seriesEncontradas ?
                            <Grid xs={12} md={6} lg={3} sx={{ background: "graficos" }} item>
                                <Indicador
                                    titulo="Garantías Comprometidas"
                                    //estado en gestion y vigentedictamen != rechazado
                                    valor={series.filter(item => (item.statuscode == 1 || item.statuscode == 100000001) && item.new_dictamendelaval != 100000003).length}
                                    icon={<WorkHistoryIcon sx={{ color: '#fdd835', fontSize: "1.7rem" }} />}
                                />
                            </Grid>
                            : null
                    }
                    {
                        seriesEncontradas ?
                            <Grid xs={12} md={6} lg={3} item>
                                <Indicador
                                    titulo="Monto Comprometido"
                                    valor={dollarUS.format(montoComprometido)}
                                    // valor="$2.927.183.765"
                                    icon={<AttachMoneyIcon sx={{ color: '#fdd835', fontSize: "1.7rem" }} />}
                                />
                            </Grid>
                            : null
                    }
                    {
                        seriesEncontradas ?
                            <Grid xs={12} md={6} lg={3} item>
                                <Indicador
                                    titulo="Garantías Otorgadas/Monetizadas"
                                    valor={series.filter(garantia => garantia.new_fechadenegociacion != null && garantia.new_dictamendelaval != 100000003).length}
                                    icon={<WorkIcon sx={{ color: '#4caf50', fontSize: "1.7rem" }} />}
                                />
                            </Grid>
                            : null
                    }
                    {
                        seriesEncontradas ?
                            <Grid xs={12} md={6} lg={3} item>
                                <Indicador
                                    titulo="Monto Otorgado/Monetizado"
                                    valor={dollarUS.format(montoMonetizado)}
                                    icon={<AttachMoneyIcon sx={{ color: '#4caf50', fontSize: "1.7rem" }} />}
                                />
                            </Grid>
                            : null
                    }
                    {
                        seriesEncontradas ?
                            <Grid
                                xs={12}
                                md={6}
                                item
                            >
                                <GraficoLineaApex datos={datosGraficoComprometido} opciones={opciones} titulo="Monto Comprometido Por Serie" />
                            </Grid>
                            : null
                    }
                    {
                        seriesEncontradas ?
                            <Grid
                                xs={12}
                                md={6} item>
                                <GraficoLineaApex datos={datosGraficoMonetizado} opciones={opciones} titulo="Monto Monetizado Por Serie" />
                            </Grid>
                            : null
                    }
                    {
                        seriesEncontradas ?
                            <Grid xs={12} item>
                                <GraficoLineaApex datos={datosGraficoVigente} opciones={opciones} titulo="Saldo Vigente Por Serie" width="90%" />
                            </Grid> : null
                    }
                    {
                        seriesEncontradas ?
                            <Grid xs={12} md={4} item>
                                <Indicador
                                    titulo="SGRs Participantes"
                                    valor="37"
                                    icon={<WorkHistoryIcon sx={{ color: '#4caf50', fontSize: "1.7rem" }} />}
                                />
                            </Grid>
                            : null
                    }
                    {
                        seriesEncontradas ?
                            <Grid xs={12} md={4} item>
                                <Indicador
                                    titulo="Monto Global"
                                    valor={dollarUS.format(montoGlobal)}
                                    icon={<AttachMoneyIcon sx={{ color: '#4caf50', fontSize: "1.7rem" }} />}
                                />
                            </Grid>
                            : null
                    }
                    {
                        seriesEncontradas ?
                            <Grid xs={12} md={4} item>
                                <Indicador
                                    titulo="Total Garantías Analizadas"
                                    valor={cantidadGarantias}
                                    icon={<WorkIcon sx={{ color: '#4caf50', fontSize: "1.7rem" }} />}
                                />
                            </Grid>
                            : null
                    }
                    {
                        seriesEncontradas ?
                            <Grid
                                xs={12}
                                md={6} item>
                                <GraficoLineaApex datos={datosGraficoGlobal} opciones={opciones} titulo="Monto Global por Serie" />
                            </Grid>
                            : null
                    }
                    {
                        seriesEncontradas ?
                            <Grid
                                xs={12}
                                md={6} item>
                                <GraficoBarraApex datos={pymes} titulo="Estadios de las Pymes" />
                            </Grid>
                            : null
                    }
                </Grid>
            </Fade>
        </Box>
    )
}
