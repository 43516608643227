import React, { useState, useContext } from "react"
import { Button, Box, Tooltip, useTheme, Typography } from "@mui/material"
import { useDispatch } from "react-redux"
import { obtenerDocumentosGarantiasPorId, obtenerGarantiaIdDesembolsos } from "../../Redux/Garantias"
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye"
import TokenContext from "../../context/TokenContext"
import ModalDocuDesembolsosAnteriores from "../modales/ModalDocuDesembolsosAnteriores"

const SeleccionarFilaDesembolsosAnteriores = ({ value }) => {
  const dispatch = useDispatch();
  const token = useContext(TokenContext)
  const theme = useTheme()

  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const obtenerId = (id) => {
    dispatch(obtenerGarantiaIdDesembolsos(id))
    dispatch(obtenerDocumentosGarantiasPorId(id, token));
  };

  const handleOpen = () => {
    setOpenModal(true)
  };

  return (
    <>
      <Box textAlign="center">
        <Tooltip title={<Typography sx={{ color: '#fff' }}>Ver Documentos</Typography>} >
          <Button
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            // onClick={(e) => {
            //   obtenerId(value)
            //   handleClick(e)
            // }}
            onClick={() => {
              handleCloseMenu();
              handleOpen();
              obtenerId(value);
            }}
          >
            <RemoveRedEyeIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.2rem", md: "1.5rem" } }} />
          </Button>
        </Tooltip>
        {/* <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleCloseMenu}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              handleOpen();
              obtenerId(value);
            }}
          >
            Ver documentos
          </MenuItem>
        </Menu> */}
      </Box>
      <ModalDocuDesembolsosAnteriores
        open={openModal}
        setOpenModal={setOpenModal}
        idGarantia={value}
      />
    </>
  );
};

export default SeleccionarFilaDesembolsosAnteriores;
