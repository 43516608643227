import React, { useState, useEffect } from "react";
import { Button, Menu, MenuItem, Box } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { obtenerDocumentoSeleccionado } from "../../Redux/CarpetaDigital";
import { useDispatch, useSelector } from "react-redux";
import ModalCargarDocumentacion from "../modales/ModalCargarDocumentacion";
import ModalDatosDocumentacion from "../modales/ModalDatosDocumentacion";

const SeleccionarFilaDocumentacion = ({ value }) => {
  const dispatch = useDispatch()

  const todosDocumentosCuentasSelector = useSelector(store => store.documentosPorCuenta.documentosPorCuenta)

  const [anchorEl, setAnchorEl] = useState(null)
  const [openModalSubirDoc, setOpenModalSubirDoc] = useState(false)
  const [openModalDatosDoc, setOpenModalDatosDoc] = useState(false)
  const [documentos, setDocumentos] = useState(todosDocumentosCuentasSelector.filter(item => item.new_documentacionporcuentaid == value.new_documentacionporcuentaid))

  const handleOpenSubirDoc = () => setOpenModalSubirDoc(true)
  const handleOpenDatosDoc = () => setOpenModalDatosDoc(true)

  useEffect(() => {
    if (todosDocumentosCuentasSelector.length > 0) {
      setDocumentos(todosDocumentosCuentasSelector.filter(item => item.new_documentacionporcuentaid == value.new_documentacionporcuentaid))
    }
  }, [todosDocumentosCuentasSelector, value])

  const open = Boolean(anchorEl)

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  const obtenerId = (id) => {
    dispatch(obtenerDocumentoSeleccionado(id))
  }

  return (
    <Box textAlign='center'>
      <Button
        id="basic-button"
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <MoreHorizIcon sx={{ fontSize: { xs: "1.2rem", md: "1.5rem" } }} />
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => value.new_vinculocompartido ? (window.open(value.new_vinculocompartido, "_blank")) : ''}
        >
          {value.new_vinculocompartido ? "Ver Documentos" : "Documento no disponible"}
        </MenuItem>
        {value.desabilitado == false ?
          [
            <MenuItem
              key="Subir Documentos"
              onClick={() => {
                obtenerId(value.new_documentacionporcuentaid)
                handleOpenSubirDoc()
                handleClose()
              }}
            >
              Subir Documentos
            </MenuItem>,
            <MenuItem
              key="Datos de la Documentación"
              onClick={() => {
                obtenerId(value.new_documentacionporcuentaid)
                handleOpenDatosDoc()
                handleClose()
              }}
            >
              Datos de la Documentación
            </MenuItem>
          ]
          : null}
      </Menu>
      <ModalCargarDocumentacion open={openModalSubirDoc} setOpen={setOpenModalSubirDoc} titulo="Carpeta Digital" id={value.new_documentacionporcuentaid} />
      {openModalDatosDoc && <ModalDatosDocumentacion open={openModalDatosDoc} setOpen={setOpenModalDatosDoc} id={value.new_documentacionporcuentaid} documentos={documentos} />}
    </Box>
  );
};

export default SeleccionarFilaDocumentacion;
