import { Box } from '@mui/material'
import React, { useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { obtenerDocumentosPorSerie } from '../Redux/DocumentosPorSerie'
import TokenContext from '../context/TokenContext'
import Tabla from './Tabla'
import { COLUMNSDOCUMENTOSSERIE } from '../Columnas/ColumnasDocumentosPorSerie'
import LoaderAnimado from './loaderAnimado/LoaderAnimado'
import ModalNuevaDocumentacionSerie from './modales/ModalNuevaDocumentacionSerie'

export const DocumentosPorSerie = ({ idSerie, lider }) => {
    const dispatch = useDispatch()
    const token = useContext(TokenContext)
    const [documentos, setDocumentos] = React.useState([])
    const [loading, setLoading] = React.useState([])
    const [openNew, setOpenNew] = React.useState(false)
    const docuResultado = useSelector(store => store.documentosPorSerie.resultadoCarga)

    const handleOpenNew = () => {
        setOpenNew(true)
    }

    const handleCloseNew = () => setOpenNew(false);

    React.useEffect(() => {
        if (token && idSerie) {
            buscarDocumentosPorSerie()
        }
    }, [token])

    React.useEffect(() => {
        if (docuResultado == "EXITO") {
            buscarDocumentosPorSerie()
        }
    }, [docuResultado])

    const buscarDocumentosPorSerie = () => {
        setLoading(true)
        dispatch(obtenerDocumentosPorSerie(token, idSerie))
            .then(data => {
                if (data?.length > 0) {
                    let docs = []
                    data?.forEach(element => {
                        let doc = {
                            filename: element["nota.filename"],
                            new_fechadevencimiento: element["new_fechadevencimiento@OData.Community.Display.V1.FormattedValue"],
                            annotationid: element["nota.annotationid"],
                            new_documento: element["_new_documento_value@OData.Community.Display.V1.FormattedValue"],
                        }
                        docs.push(doc)
                    })
                    setDocumentos(docs)
                    setLoading(false)
                } else {
                    setDocumentos([])
                    setLoading(false)
                }
            })
            .catch(() => {
                setDocumentos([])
                setLoading(false)
            })
    }

    return (
        <>
            <Box component="main" sx={{ flexGrow: 1, p: 3, py: 0 }}>
                {
                    loading ?
                        <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", p: 4 }}>
                            <LoaderAnimado />
                        </Box>
                        :
                        <>
                            {lider ?
                                <Tabla
                                    columnas={COLUMNSDOCUMENTOSSERIE}
                                    datos={documentos}
                                    handleOpenNew={handleOpenNew}
                                    accionAgregar={true}
                                /> : <Tabla
                                    columnas={COLUMNSDOCUMENTOSSERIE}
                                    datos={documentos}

                                />
                            }
                        </>
                }
                <ModalNuevaDocumentacionSerie open={openNew} handleClose={handleCloseNew} idSerie={idSerie} />
            </Box>
        </>
    )
}
