import React from 'react';
import { PieChart, Pie, Tooltip, Cell, ResponsiveContainer, Legend, Line } from 'recharts'
import { Typography, Box, useTheme } from '@mui/material'


const COLORS = ["rgb(180, 255, 255)", "rgb(255, 87, 51)", "rgb(241, 196, 15)", "rgb(93, 173, 226)", "rgb(187, 143, 206)",
    "rgb(46, 204, 113)", "rgb(243, 156, 18)", "rgb(87, 124, 96)", "rgb(77, 103, 117)", "rgb(239, 226, 152)"];


const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
        <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
            {`${(percent * 100).toFixed(0)}%`}
        </text>
    );
};


const GraficoPie = ({ data, dkey }) => {
    const theme = useTheme()
    const CustomTooltip = ({ active, payload, label, y }) => {
        if (active && payload && payload.length) {
            return (
                <Box sx={{ backgroundColor: "#000", p: 2 }}>
                    {payload[0].payload.serie ? <Typography sx={{ fontSize: ".9rem" }}>{`${payload[0].payload.serie}`}</Typography>
                        :
                        <Typography sx={{ color: "#fff", fontSize: ".9rem" }}>{`${payload[0].payload.estado}`}</Typography>
                    }
                    <Typography sx={{ color: "#fff", fontSize: ".9rem" }}>{`Cantidad: ${payload[0].payload[y]}`}</Typography>
                </Box>
            );
        }

        return null;
    };
    const renderColorfulLegendText = (value, payload) => {
        return (
            theme.palette.mode == 'dark' ?
                <Typography sx={{ fontSize: ".9rem", color: "#fff" }}>{payload?.payload.serie}</Typography>
                : <Typography sx={{ fontSize: ".9rem", color: "#000" }}>{payload?.payload.serie}</Typography>
        );
    };

    return (
        <ResponsiveContainer width="100%" height="100%">
            <PieChart width={600} height={600}>
                <Legend verticalAlign="top" align="center" formatter={renderColorfulLegendText} />
                <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={120}
                    fill="#8884d8"
                    dataKey={dkey}
                >
                    {data.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
                <Tooltip content={<CustomTooltip y={dkey} />} />
            </PieChart>
        </ResponsiveContainer>
    )
}

export default GraficoPie