let dollarUS = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

export const COLUMNSIND = [
    {
        accessorKey: 'id',
    },
    {
        header: 'Fecha hasta',
        accessorKey: 'new_fechahasta_value',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Saldo neto de Garantias Vigentes',
        accessorKey: 'new_saldonetodegarantiasvigentes',
        Cell: ({ cell }) => <strong>{cell.getValue() > 0 ? dollarUS.format(cell.getValue()): '$0'}</strong>
    },
    // {
    //     header: 'Fondo de riesgo contingente',
    //     accessorKey: 'new_fondoderiesgocontingente',
    //     Cell: ({ cell }) => <strong>{cell.getValue() > 0 ? dollarUS.format(cell.getValue()): '$0'}</strong>
    // },
    {
        header: '% SOLVENCIA',
        accessorKey: 'new_solvencia',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Fondo de riesgo disponible',
        accessorKey: 'new_fondoderiesgodisponible',
        Cell: ({ cell }) => <strong>{cell.getValue() > 0 ? dollarUS.format(cell.getValue()): '$0'}</strong>
    },
    // {
    //     header: 'Fondo de riesgo integrado',
    //     accessorKey: 'new_fondoderiesgointegrado',
    //     Cell: ({ cell }) => <strong>{cell.getValue() > 0 ? dollarUS.format(cell.getValue()): '$0'}</strong>
    // },
    // {
    //     header: 'Fondo de riesgo a valor de mercado',
    //     accessorKey: 'new_fondoderiesgoavalordemercado',
    //     Cell: ({ cell }) => <strong>{cell.getValue() > 0 ? dollarUS.format(cell.getValue()): '$0'}</strong>
    // },
]