import React, { useEffect, useState, useContext } from "react"
import { Box, Paper, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux"
import { obtenerSGRsPorContacto, setIdSGR } from "../Redux/SGR"
import fondoMisSGR from "../img/fondoMisSGR.png"
import { useNavigate } from "react-router-dom"
import TokenContext from '../context/TokenContext'
import { cerrarSesion } from '../Redux/Usuarios'
import LoaderAnimado from "../Components/loaderAnimado/LoaderAnimado";
import { obtenerSgrs } from "../Redux/Indicadores";

const MisSGR = () => {
  const dispatch = useDispatch()
  let navigate = useNavigate()
  const token = useContext(TokenContext)

  const contactid = useSelector((store) => store.usuarios.contactid)
  const sgrs = useSelector((store) => store.sgrs.SgrsPorContactos)
  const resultado = useSelector((store) => store.sgrs.resultado)
  const [logSgrs, setLogSgrs] = useState(true)
  const [Sgrs, setSgrs] = useState([])

  useEffect(() => {
    localStorage.removeItem('Id SGR')
    localStorage.removeItem('Nombre SGR')
    dispatch(setIdSGR())
  }, [])

  useEffect(() => {
    if (contactid && token) {
      dispatch(obtenerSGRsPorContacto(token, contactid))
    }
  }, [contactid, token])

  useEffect(() => {
    if (sgrs?.length > 0 && resultado === "EXITO") {
      setSgrs(sgrs)
      setLogSgrs(false)
    } else if (sgrs?.length === 0 && resultado === "EXITO") {
      setLogSgrs(false)
    }
  }, [sgrs, resultado])

  const setId = (id) => {
    if (id && Sgrs?.length > 0) {
      dispatch(setIdSGR(id, Sgrs?.filter((item) => item.new_sgrid == id).map((item) => item.new_name)))
      dispatch(obtenerSgrs(token, id))
      navigate("/Inicio")
    }
  }

  const CerrarSesion = (e) => {
    e.preventDefault()
    dispatch(cerrarSesion())
    navigate('/login')
  }

  return (
    <Box sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundImage: `url(${fondoMisSGR})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      minHeight: "100vh"
    }}>
      <Typography variant="h1" color="white" sx={{ fontSize: { xs: "1.5rem", md: "2.5rem" }, fontWeight: 600 }}>
        Mis SGR
      </Typography>
      <Typography variant="subtitle1" color="white" sx={{ fontSize: { xs: ".9rem", md: "1.5rem" }, fontWeight: 600, textAlign: "center", mt: 3, mx: 2 }}>
        Seleccionar la SGR desde la cual desea Operar
      </Typography>
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 4, mt: 10 }}
      >
        {logSgrs ? <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", p: 4 }}>
          <LoaderAnimado />
        </Box>
          :
          Sgrs.length > 0 ? Sgrs?.map((item) => {
            return (
              <Paper
                key={item.new_sgrid}
                sx={{ backgroundColor: "#484AAD", py: 2, px: 4, border: "1px solid #fff", '&:hover': { background: "#ffd166", color: "#2D2E82" } }}>
                <Box
                  component="div"
                  onClick={() => setId(item.new_sgrid)}
                  sx={{ cursor: "pointer" }}
                >
                  <Typography variant="h1" sx={{ fontSize: { xs: ".9rem", md: "1.2rem" }, fontWeight: 600, color: "#fff" }}>
                    {item.new_name}
                  </Typography>
                </Box>
              </Paper>
            );
          })
            :
            <Paper
              sx={{ backgroundColor: "#484AAD", py: 2, px: 4, border: "1px solid #fff", '&:hover': { background: "#ffd166", color: "#2D2E82" } }}>
              <Box
                component="div"
                onClick={CerrarSesion}
                sx={{ cursor: "pointer" }}
              >
                <Typography variant="h1" sx={{ fontSize: { xs: ".9rem", md: "1.2rem" }, fontWeight: 600, color: "#fff" }}>
                  Sin SGR para este usuario
                </Typography>
                <Typography variant="h1" sx={{ fontSize: { xs: ".9rem", md: "1.2rem" }, fontWeight: 600, color: "#fff" }}>
                  Cerrar sesión
                </Typography>
              </Box>
            </Paper>
        }
      </Box>
    </Box>
  );
};

export default MisSGR;
