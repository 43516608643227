import React, { useContext } from 'react'
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Unstable_Grid2';
import Autocomplete from '@mui/material/Autocomplete';
import { useSelector, useDispatch } from 'react-redux';
import Avatar from '@mui/material/Avatar';
import TextField from '@mui/material/TextField';
import { Box, Button, Typography } from '@mui/material';
import perfilRandom from "../img/perfilRandom.jpg"
import { toast } from 'react-toastify';
import { actualizarFotoUid } from '../Redux/Usuarios';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { actualizarDatosCuentaPyme, obtenerCuenta, obtenerPaises, obtenerProvincias } from '../Redux/Cuenta';
import TokenContext from '../context/TokenContext'
import { obtenerCondicionImpositiva } from '../Redux/DatosSolicitudAlta'
import Compressor from 'compressorjs';

const Perfil = () => {
    const dispatch = useDispatch()
    const token = useContext(TokenContext)

    const [value, setValue] = React.useState("1")
    //foto del perfil
    const [fotoURL, setFotoURL] = React.useState()
    const fotoselector = useSelector(store => store.usuarios.photoURL)
    //################ MIS DATOS GENERAL ####################
    const [cuenta, setCuenta] = React.useState(null)
    const [llamadaDatosCuenta, setLlamadaDatosCuenta] = React.useState(false)
    const [datosCompletados, setDatosCompletados] = React.useState(false)
    const [razonSocial, setRazonSocial] = React.useState('')
    const [telefono, setTelefono] = React.useState('')
    const [correoElectronico, setCorreoElectronico] = React.useState('')
    const [calle, setCalle] = React.useState('')
    const [numero, setNumero] = React.useState('')
    const [piso, setPiso] = React.useState('')
    const [depto, setDepto] = React.useState('')
    const [codigoPostal, setCodigoPostal] = React.useState('')
    const [municipio, setMunicipio] = React.useState('')
    const [localidad, setLocalidad] = React.useState('')
    const [provincia, setProvincia] = React.useState(null)
    const [pais, setPais] = React.useState(null)
    const [inscripcionGanancias, setInscripcionGanancias] = React.useState({})
    const [cuit, setCuit] = React.useState('')
    const [llamadaProvincias, setLlamadaProvincias] = React.useState(false)
    const [opcionesProvincias, setOpcionesProvincias] = React.useState([])
    const [opcionesPaises, setOpcionesPaises] = React.useState([])
    const [llamadaPais, setLlamadaPais] = React.useState(false)
    const [contactoNotificaciones, setContactoNotificaciones] = React.useState()

    const [deshabilitadoActCuenta, setDeshabilitadoActCuenta] = React.useState(false)
    //referencia al input para cambio de foto
    const fileInput = React.useRef();

    const [open, setOpen] = React.useState(false);
    const loading = open && opcionesProvincias.length === 0;
    const [llamadaCondicion, setLlamadaCondicion] = React.useState(false)
    const [condiciones, setCondiciones] = React.useState([])
    const [openPais, setOpenPais] = React.useState(false);
    const loadingPais = openPais && opcionesPaises.length === 0;

    const accountid = useSelector(store => store.usuarios.accountid)
    const cuentaSelector = useSelector(store => store.cuenta.cuenta)
    const provinciasSelector = useSelector(store => store.cuenta.provincias)
    const paisesSelector = useSelector(store => store.cuenta.paises)
    const actualizacionDatosCuenta = useSelector(store => store.cuenta.actualizacionDatos)
    const contactosNotificacionesSelector = useSelector(store => store.cuenta.contactosNotificaciones)
    const condicionAfipSelector = useSelector(store => store.datos.condiciones)

    // React.useEffect(() => {
    //     obtenerInfoMiCuenta(token, accountid)
    //     dispatch(obtenerCondicionImpositiva(token))
    // }, [])

    React.useEffect(() => {
        var active = true;
        if (!loading) {
            return undefined;
        }
        return () => {
            active = false;
        };
    }, [loading]);

    React.useEffect(() => {
        var active = true;
        if (!loadingPais) {
            return undefined;
        }
        return () => {
            active = false;
        };
    }, [loadingPais]);

    React.useEffect(() => {
        if (actualizacionDatosCuenta === "EXITO" || actualizacionDatosCuenta === "ERROR") {
            setDeshabilitadoActCuenta(false)
            setTimeout(() => {
                obtenerInfoMiCuenta()
                setLlamadaDatosCuenta(true)
            }, 2500);
        }
    }, [actualizacionDatosCuenta]);

    React.useEffect(() => {
        if (accountid !== '') {
            if (cuentaSelector != undefined && datosCompletados === false && llamadaDatosCuenta === true) {
                setCuenta(cuentaSelector)
                setDatosCompletados(true)
            } else if (token != undefined && token != '' && llamadaDatosCuenta == false) {
                obtenerInfoMiCuenta()
                setLlamadaDatosCuenta(true)
            }
            if (datosCompletados === false && Object.keys(cuentaSelector).length > 0) {
                completarDatos(cuentaSelector)
                setDatosCompletados(true)
            }
            if (fotoselector) {
                setFotoURL(fotoselector)
            }
        }
    }, [cuentaSelector, fotoselector])

    React.useEffect(() => {
        debugger
        if (provinciasSelector.length > 0 && llamadaProvincias === true) {
            var opciones = []
            provinciasSelector.forEach(prov => {
                var provincia = { value: prov.new_provinciaid, label: prov.new_name }
                opciones.push(provincia);
            });
            //ordeno alfabeticamente
            opciones.sort(function (a, b) {
                if (a.label < b.label) { return -1; }
                if (a.label > b.label) { return 1; }
                return 0;
            })
            setOpcionesProvincias(opciones)
            if (cuentaSelector != undefined) {
                provinciasSelector.filter(prov => prov.new_provinciaid === cuentaSelector._new_provincia_value).map(itemProv => {
                    setProvincia({ label: itemProv.new_name, value: itemProv.new_provinciaid })
                })
            }
        } else if (token != undefined && token != '' && llamadaProvincias === false) {
            debugger
            obtenerTodasProvincias()
            setLlamadaProvincias(true)
        }
    }, [provinciasSelector])

    React.useEffect(() => {
        if (paisesSelector.length > 0 && llamadaPais === true) {
            var opciones = []
            paisesSelector.forEach(pais => {
                var pais = { value: pais.new_paisid, label: pais.new_name }
                opciones.push(pais);
            });
            //ordeno alfabeticamente
            opciones.sort(function (a, b) {
                if (a.label < b.label) { return -1; }
                if (a.label > b.label) { return 1; }
                return 0;
            })
            setOpcionesPaises(opciones)
            if (cuentaSelector != undefined) {
                paisesSelector.filter(prov => prov.new_paisid === cuentaSelector._new_pais_value).map(itemPais => {
                    setPais({ value: itemPais.new_paisid, label: itemPais.new_name })
                })
            }
        } else if (token != undefined && token != '' && llamadaPais === false) {
            setLlamadaPais(true)
            obtenerTodosPaises()
        }
    }, [paisesSelector])

    React.useEffect(() => {
        if (condicionAfipSelector.length > 0 && llamadaCondicion === true) {
            const condicionesAfip = [];
            condicionAfipSelector.forEach(element => {
                var condicion = { value: element.new_condiciondeinscipcionanteafipid, label: element.new_name }
                condicionesAfip.push(condicion);
            });
            setCondiciones(condicionesAfip)
        } else if (token != '' && llamadaCondicion === false) {
            obtenerCondiciones()
            setLlamadaCondicion(true)
        }
    }, [condicionAfipSelector, token])

    const obtenerInfoMiCuenta = () => {
        dispatch(obtenerCuenta(accountid, token))
    }

    const obtenerTodasProvincias = () => {
        dispatch(obtenerProvincias(token))
    }

    const obtenerTodosPaises = () => {
        dispatch(obtenerPaises(token))
    }

    const obtenerCondiciones = () => {
        dispatch(obtenerCondicionImpositiva(token))
    }
    //cambiar foto de cuenta
    const seleccionarArchivo = (e) => {
        const imgCliente = e.target.files[0];

        if (imgCliente === undefined) {
            console.log('no se selecciono ninguna imagen');
            return;
        }

        if (imgCliente.type !== "image/png" && imgCliente.type !== "image/jpg" && imgCliente.type !== "image/jpeg") {
            toast.error('El formato de la foto debe ser png/jpg/jpeg', {
                theme: "dark",
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }

        const reader = new FileReader();

        reader.onload = (event) => {
            const blob = new Blob([event.target.result], { type: imgCliente.type });
            const compressedImg = new Compressor(blob, {
                quality: 0.3,
                success(result) {
                    const blob = new Blob([result], { type: imgCliente.type });
                    console.log("Tamaño de archivo comprimido:", blob.size, "bytes");
                    dispatch(actualizarFotoUid(blob));
                },
                error(err) {
                    console.log(err.message);
                },
            });
        };

        reader.readAsArrayBuffer(imgCliente);
    };

    const actualizarCuenta = () => {
        let provincia_id = ''
        let pais_id = ''
        let inscripcion_id = ''
        let nroDireccion = ''
        if (calle === '' || calle === null || calle === undefined) {
            toast.error('La calle es requerida!', {
                theme: "dark",
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }

        if (numero === '' || numero === null || numero === undefined) {
            toast.error('El nro de la calle requerida!', {
                theme: "dark",
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }

        if (codigoPostal === '' || codigoPostal === null || codigoPostal === undefined) {
            toast.error('El código postal es requerido!', {
                theme: "dark",
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }

        if (localidad === '' || localidad === null || localidad === undefined) {
            toast.error('La localidad es requerido!', {
                theme: "dark",
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }

        if (provincia === '' || provincia === null) {
            toast.error('La provincia es requerida!', {
                theme: "dark",
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }

        if (pais === '' || pais === null) {
            toast.error('El país es requerido!', {
                theme: "dark",
                position: "top-center",
                autoClose: 1500,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }

        if (localidad !== null) {
            if (!localidad.trim()) {
                setLocalidad(localidad)
            }
        }
        if (provincia !== null) {
            provincia_id = provincia.value
        }

        if (pais !== null) {
            pais_id = pais.value
        }

        if (inscripcionGanancias !== null) {
            inscripcion_id = inscripcionGanancias.value
        }

        if (numero != undefined) {
            nroDireccion = numero.toString()
        }
        dispatch(actualizarDatosCuentaPyme(accountid, token, telefono, calle, nroDireccion, piso, depto, provincia_id, localidad,
            municipio, codigoPostal, inscripcion_id, pais_id))
        setDeshabilitadoActCuenta(true)
    }

    const completarDatos = (cuentas) => {
        if (cuentas != undefined) {
            //General
            // setCuenta(cuentas)
            paisesSelector.filter(prov => prov.new_paisid === cuentas._new_pais_value).map(itemPais => {
                setPais({ value: itemPais.new_paisid, label: itemPais.new_name })
            })
            setRazonSocial(cuentas.name)
            setTelefono(cuentas.telephone2)
            setCorreoElectronico(cuentas.emailaddress1)
            setCalle(cuentas.address1_line1)
            setNumero(cuentas.new_direccion1numero)
            setPiso(cuentas.address1_name)
            setDepto(cuentas.new_direccion1depto)
            setCodigoPostal(cuentas.address1_postalcode)
            setMunicipio(cuentas.address1_county)
            setLocalidad(cuentas.new_localidad)
            setCuit(cuentas.new_nmerodedocumento)
            provinciasSelector.filter(prov => prov.new_provinciaid === cuentas._new_provincia_value).map(itemProv => {
                setProvincia({ label: itemProv.new_name, value: itemProv.new_provinciaid })
            })
            if (cuentas._new_condiciondeinscripcionanteafip_value != null) {
                setInscripcionGanancias({ value: cuentas._new_condiciondeinscripcionanteafip_value, label: cuentas["_new_condiciondeinscripcionanteafip_value@OData.Community.Display.V1.FormattedValue"] })
            }
            contactosNotificacionesSelector.filter(item => item.contactid === cuentas._new_contactodenotificaciones_value).map(item => {
                var valor = `${item.fullname} - ${item["aa.new_tipoderelacion@OData.Community.Display.V1.FormattedValue"]}`
                setContactoNotificaciones({ value: item.contactid, label: valor })
            })
            // if (cuentas.new_inscripcionganancias != null) {
            //     setInscripcionGanancias({ value: cuentas.new_inscripcionganancias, label: obtenerValorInscripcionGanancias(cuentas.new_inscripcionganancias) })
            // }
        }
    }


    return (
        <Grid container spacing={2} sx={{ pt: 2 }}>
            <Grid item xs={12} sm={4} md={4}>
                <Paper elevation={4} sx={{ py: 2 }}>
                    <Grid display="flex" justifyContent="center" alignItems="center">
                        {
                            fotoURL !== undefined ?
                                <Tooltip title="Cambiar Foto de Perfil">
                                    <IconButton onClick={() => fileInput.current.click()}>
                                        <Avatar
                                            alt="Remy Sharp"
                                            src={fotoURL}
                                            sx={{ width: 140, height: 140 }}
                                            display="flex" justifyContent="center" alignItems="center"
                                        />
                                    </IconButton>
                                </Tooltip>
                                :
                                <Tooltip title="Cambiar Foto de Perfil" >
                                    <IconButton onClick={() => fileInput.current.click()}>
                                        <Avatar
                                            alt="Remy Sharp"
                                            src={perfilRandom}
                                            sx={{ width: 140, height: 140 }}
                                            display="flex" justifyContent="center" alignItems="center"
                                        />
                                    </IconButton>
                                </Tooltip>
                        }
                        <input
                            ref={fileInput}
                            type="file"
                            style={{ display: 'none' }}
                            onChange={e => seleccionarArchivo(e)}
                        />
                    </Grid>
                    <Typography align="center">{razonSocial}</Typography>
                    <Typography align="center">{cuit}</Typography>
                    <Typography align="center">{correoElectronico}</Typography>
                </Paper>
            </Grid>
            <Grid item xs={12} sm={8} md={8}>
                <Paper elevation={4} sx={{ py: 2 }}>
                    <Grid container xs={12} sx={{ px: 1 }}>
                        <Grid item xs={12} sm={6} md={6} sx={{ px: 2, pb: 2 }}>
                            <TextField
                                value={calle}
                                margin="dense"
                                id="Calle"
                                label="Calle"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={e => setCalle(e.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} sx={{ px: 2, pb: 2 }}>
                            <TextField
                                value={numero}
                                margin="dense"
                                id="CalleNum"
                                label="N° de calle"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={e => setNumero(e.target.value)}
                                required
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} sx={{ px: 2, pb: 2 }}>
                            <TextField
                                value={piso}
                                margin="dense"
                                id="piso"
                                label="Piso"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={e => setPiso(e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} sx={{ px: 2, pb: 2 }}>
                            <TextField
                                value={depto}
                                margin="dense"
                                id="dpto"
                                label="Departamento"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={e => setDepto(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} sx={{ px: 2, pb: 2 }}>
                            <TextField
                                value={codigoPostal}
                                margin="dense"
                                id="codPostal"
                                label="Código Postal"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={e => setCodigoPostal(e.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} sx={{ px: 2, pb: 2 }}>
                            <TextField
                                value={municipio}
                                margin="dense"
                                id="munParCom"
                                label="Municipio / Partido / Comuna"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={e => setMunicipio(e.target.value)}
                            // required
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} sx={{ px: 2, pb: 2 }}>
                            <TextField
                                value={localidad}
                                margin="dense"
                                id="localidad"
                                label="Localidad"
                                type="text"
                                fullWidth
                                variant="standard"
                                onChange={e => setLocalidad(e.target.value)}
                                required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} sx={{ px: 2, pb: 2 }}>
                            <Autocomplete
                                open={open}
                                onOpen={() => { setOpen(true) }}
                                onClose={() => { setOpen(false) }}
                                loading={loading}
                                loadingText="Cargando..."
                                options={opcionesProvincias}
                                getOptionSelected={(option) => option.value === value.value}
                                getOptionLabel={(option) => option.label}
                                color="success"
                                value={provincia}
                                required
                                onChange={(_, value) => {
                                    setProvincia(value)
                                }}
                                fullWidth
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Provincia"
                                        color="success"
                                        variant="standard"
                                        // error={Boolean(formState.errors && formState.errors[name])}
                                        required="false"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} sx={{ px: 2, pb: 2 }}>
                            <Autocomplete
                                open={openPais}
                                onOpen={() => { setOpenPais(true) }}
                                onClose={() => { setOpenPais(false) }}
                                loading={loadingPais}
                                loadingText="Cargando..."
                                options={opcionesPaises}
                                getOptionSelected={(option) => option.value === value.value}
                                getOptionLabel={(option) => option.label}
                                color="success"
                                value={pais}
                                required
                                onChange={(_, value) => {
                                    setPais(value)
                                }}
                                fullWidth
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Pais"
                                        color="success"
                                        variant="standard"
                                        // error={Boolean(formState.errors && formState.errors[name])}
                                        required="false"
                                        InputProps={{
                                            ...params.InputProps,
                                            endAdornment: (
                                                <React.Fragment>
                                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                                    {params.InputProps.endAdornment}
                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} sx={{ px: 2, pb: 2 }}>
                            <Autocomplete
                                id="controlled-demo"
                                loadingText="Cargando..."
                                getOptionLabel={(GananciasOpciones) => GananciasOpciones.label || ""}
                                isOptionEqualToValue={(option, value) => option.label === value.label}
                                value={inscripcionGanancias}
                                options={condiciones}
                                required
                                onChange={(event, newValue) => {
                                    setInscripcionGanancias(newValue);
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Condición de Inscripción ante AFIP" variant="standard" />
                                )}
                            />
                        </Grid>
                        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end', py: 2, mr: 1 }}>
                            <Button
                                type="submit"
                                variant="contained"
                                onClick={actualizarCuenta}
                                disabled={deshabilitadoActCuenta}
                            >
                                Actualizar
                            </Button>
                        </Box>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    )
}

export default Perfil
