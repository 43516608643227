import React, { useState } from 'react'
import { Button, Menu, MenuItem, Box, Tooltip, Typography } from '@mui/material'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { useDispatch } from "react-redux"
import { obtenerGarantiaIdSeleccionada } from "../../Redux/Garantias"
import ModalGarantias from '../modales/ModalGarantias'
import { setModalGarantias } from '../../Redux/AbrirModal'

const SeleccionarFilaGarantia = ({ value }) => {
  const dispatch = useDispatch()


  const [anchorEl, setAnchorEl] = useState(null)

  const open = Boolean(anchorEl)


  const obtenerId = (id) => {
    dispatch(obtenerGarantiaIdSeleccionada(id))
  }

  const handleOpen = () => {
    dispatch(setModalGarantias(true))
  }

  const handleClick = (event) => {
    // setAnchorEl(event.currentTarget)
    // handleClose();
    // handleOpen();
    // obtenerId(value);
  }

  const handleClose = () => {
    setAnchorEl(null);
  }

  return (
    <Box textAlign='center'>
      <Tooltip title={<Typography sx={{ color: '#fff' }}>Ver</Typography>}>
        <Button
          id="basic-button"
          aria-controls={open ? "basic-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          // onClick={handleClick}
          onClick={() => {
            handleClose();
            handleOpen();
            obtenerId(value);
          }}
        >
          <RemoveRedEyeIcon sx={{ fontSize: { xs: "1.2rem", md: "1.5rem" } }} />
        </Button>
      </Tooltip>
      {/* <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleOpen();
            obtenerId(value);
          }}
        >
          Ver Garantía
        </MenuItem>
      </Menu> */}
    </Box>
  );
};

export default SeleccionarFilaGarantia;
