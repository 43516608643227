import axios from 'axios'
import { Entidad, UrlApiDynamics, UrlApi } from '../Keys'
import { toast } from 'react-toastify'
//Const
const dataInicial = {
    loading: false,
    loadingTemplate: false,
    retrievePlanDeCuotas: false,
    planCuotas: [],
    planCuota: '',
    resultado: '',
    resultadoPlanCuotas: "",
    resultadoTemplate: "",
    resultadoActualizacionTemplate: ""
}

const ToastError = (msj) => {
    toast.error(msj, {
        theme: "dark",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};
const ToastSuccess = (msj) => {
    toast.success(msj, {
        theme: "dark",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })
}

const ToastSuccessTemplate = (msj) => {
    toast.success(msj, {
        theme: "dark",
        position: "top-center",
        autoClose: 3500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })
}

//Types
const LOADING_PLANDECUOTAS = "LOADING_PLANDECUOTAS"
const ERROR_TEMPLATEGARANTIA = 'ERROR_TEMPLATEGARANTIA'
const CARGA_TEMPLATEGARANTIA_EXITOSA = 'CARGA_TEMPLATEGARANTIA_EXITOSA'
const LOADING_TEMPLATEGARANTIA = "LOADING_TEMPLATEGARANTIA"

const ERROR_ACTUALIZACION_TEMPLATEGARANTIA = 'ERROR_ACTUALIZACION_TEMPLATEGARANTIA'
const CARGAR_ACTUALIZACION_TEMPLATEGARANTIA_EXITOSA = 'CARGAR_ACTUALIZACION_TEMPLATEGARANTIA_EXITOSA'
const LOADINGR_ACTUALIZACION_TEMPLATEGARANTIA = "LOADINGR_ACTUALIZACION_TEMPLATEGARANTIA"

const ERROR_PLANDECUOTAS = 'ERROR_PLANDECUOTAS'
const ADHERIR_PLANCUOTAS_EXITO = 'ADHERIR_PLANCUOTAS_EXITO'
const OBTENER_PLANCUOTAS = 'OBTENER_PLANCUOTAS'
const CARGA_DOCUMENTOGARANTIA = 'CARGA_DOCUMENTOGARANTIA'


//Reducers
export default function planCuotasReducer(state = dataInicial, action) {
    switch (action.type) {
        case ADHERIR_PLANCUOTAS_EXITO:
            return { ...state, planCuota: action.planCuota }
        case OBTENER_PLANCUOTAS:
            return { ...state, planCuotas: action.payload, resultadoPlanCuotas: action.resultado, retrievePlanDeCuotas: true }
        case CARGA_DOCUMENTOGARANTIA:
            return { ...state, resultado: action.resultado }
        case ERROR_PLANDECUOTAS:
            return { ...state, retrievePlanDeCuotas: false, resultadoPlanCuotas: action.resultado, planCuotas: [] }
        case LOADING_PLANDECUOTAS:
            return { ...state, retrievePlanDeCuotas: false, planCuotas: [], resultadoPlanCuotas: action.resultado }
        case CARGA_TEMPLATEGARANTIA_EXITOSA:
            return { ...state, loadingTemplate: false, resultadoTemplate: 'EXITO' }
        case ERROR_TEMPLATEGARANTIA:
            return { ...state, loadingTemplate: false, resultadoTemplate: 'ERROR' }
        case LOADING_TEMPLATEGARANTIA:
            return { ...state, loadingTemplate: true, resultadoTemplate: 'LOADING' }
        case CARGAR_ACTUALIZACION_TEMPLATEGARANTIA_EXITOSA:
            return { ...state, loadingTemplate: false, resultadoActualizacionTemplate: 'EXITO' }
        case ERROR_ACTUALIZACION_TEMPLATEGARANTIA:
            return { ...state, loadingTemplate: false, resultadoActualizacionTemplate: 'ERROR' }
        case LOADINGR_ACTUALIZACION_TEMPLATEGARANTIA:
            return { ...state, loadingTemplate: true, resultadoActualizacionTemplate: 'LOADING' }
        default:
            return { ...dataInicial }
    }
}

//Actions

export const obtenerPlanCuotas = (id, token) => async (dispatch) => {
    dispatch({
        type: LOADING_PLANDECUOTAS,
        resultado: "LOADING"
    })

    try {
        var entidad = "new_prestamoses"
        var fetch = "<fetch mapping='logical'>" +
            "<entity name='new_prestamos'>" +
            "<attribute name='new_numero'/> " +
            "<attribute name='new_montocuota'/>" +
            "<attribute name='statuscode'/> " +
            "<attribute name='new_interesperiodo'/> " +
            "<attribute name='new_fechadevencimiento'/> " +
            "<attribute name='new_amortizacion'/> " +
            "<attribute name='new_prestamosid'/> " +
            "<order attribute ='new_numero' descending='false' />" +
            "<filter type='and'>" +
            "<condition attribute='new_garantia' operator='eq' value='" + id + "'/>" +
            "</filter>" +
            "</entity>" +
            "</fetch>";
        // const response = await axios.get(`${UrlApiDynamics}Plandecuota?filter=_new_garantia_value eq ${id}&cuit=${Entidad}`)
        await axios.post(`${UrlApi}api/consultafetch`,
            {
                "entidad": entidad,
                "fetch": fetch
            },
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            .then((response) => {
                dispatch({
                    type: OBTENER_PLANCUOTAS,
                    payload: response.data,
                    resultado: "EXITO"
                })
            })
            .catch(err => {
                dispatch({
                    type: ERROR_PLANDECUOTAS,
                    resultado: "ERROR"
                })
            })
    } catch (error) {
        dispatch({
            type: ERROR_PLANDECUOTAS,
            resultado: "ERROR"
        })
    }
}

export const cargarDocumentacionEnGarantia = (token, formData, garantiaId, tipoDoc, visiblePortal) => async (dispatch) => {
    dispatch({
        type: CARGA_DOCUMENTOGARANTIA,
        resultado: 'LOADING'
    })
    try {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                "content-type": "multipart/form-data",
            },
        }
        debugger
        var url = ""
        if(visiblePortal != null){
            url = `garantia_id=${garantiaId}&tipo=${tipoDoc}&visiblePortal=${visiblePortal}`
        }
        else{
            url = `garantia_id=${garantiaId}&tipo=${tipoDoc}`
        }
        const response = await axios.post(`${UrlApi}api/portalcasfog/adjuntosgarantia?${url}`, formData, config)
            .then((respuesta) => {
                console.log("Exito Adjunto", respuesta)
                dispatch({
                    type: CARGA_DOCUMENTOGARANTIA,
                    resultado: 'EXITO'
                })
                ToastSuccess('Archivo cargado con exito!')
            })
            .catch((error) => {
                console.log("Error Adjunto", error)
                dispatch({
                    type: CARGA_DOCUMENTOGARANTIA,
                    resultado: 'ERROR'
                })

                ToastError('La actualización de la garantía falló!')
            });
    } catch (error) {
        dispatch({
            type: CARGA_DOCUMENTOGARANTIA,
            resultado: 'ERROR'
        })
        ToastError('La actualización de la garantía falló!')
    }
}


export const cargarTemplateGarantia = (token, garantiaId, tipoDoc, serieId) => async (dispatch) => {
    dispatch({
        type: LOADING_TEMPLATEGARANTIA
    })

    try {
        await axios.post(`${UrlApi}api/portalcasfog/adjuntogarantiatemplate`,
            {
                "serie": serieId,
                "garantia": garantiaId,
                "tipoTemplate": parseInt(tipoDoc)
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        ).then((respuesta) => {
            dispatch({
                type: CARGA_TEMPLATEGARANTIA_EXITOSA
            })
            ToastSuccessTemplate('Estamos generando el template, este proceso puede demorar algunos minutos.')
        })
            .catch((error) => {
                dispatch({
                    type: ERROR_TEMPLATEGARANTIA
                })

                ToastError('Error al generar template!' + error)
            });
    } catch (error) {
        dispatch({
            type: ERROR_TEMPLATEGARANTIA
        })
        ToastError('Error al generar template!' + error)
    }
}


export const actualizarTemplate = (token, formData, garantiaId, notaid, visiblePortal) => async (dispatch) => {
    dispatch({
        type: LOADINGR_ACTUALIZACION_TEMPLATEGARANTIA
    })

    try {
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                "content-type": "multipart/form-data",
            },
        }
        const response = await axios.post(`${UrlApi}api/portalcasfog/actualizarTempalte?adjuntoGarantiaId=${garantiaId}&notaid=${notaid}&visiblePortal=${visiblePortal}`, formData, config)
            .then((respuesta) => {
                dispatch({
                    type: CARGAR_ACTUALIZACION_TEMPLATEGARANTIA_EXITOSA,
                })
                ToastSuccess('Template actualizado con exito!')
            })
            .catch((error) => {
                dispatch({
                    type: ERROR_ACTUALIZACION_TEMPLATEGARANTIA
                })

                ToastError('La actualización del template falló!')
            });
    } catch (error) {
        dispatch({
            type: ERROR_ACTUALIZACION_TEMPLATEGARANTIA,
        })
        ToastError('La actualización del template falló!')
    }
}