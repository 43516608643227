import React, { useState, useContext, useEffect } from "react"
import { Typography, Button, IconButton, FormControl, InputLabel, Box, Divider, Select, MenuItem, CircularProgress, Tooltip } from "@mui/material/"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import CloseIcon from "@mui/icons-material/Close"
import { adherirOperacionSindicada } from "../../Redux/OperacionesSindicadas"
import TokenContext from "../../context/TokenContext";
import { useDispatch, useSelector } from "react-redux"

const ModalOperacionSindicada = ({ open, setOpen, id }) => {
    const dispatch = useDispatch();
    const token = useContext(TokenContext)

    const resultadoAdherirOperaciones = useSelector(store => store.operacione.resultadoAdherirOperaciones)

    const [adhesion, setAdhesion] = useState("")
    const [rechazo, setRechazo] = useState("")

    useEffect(() => {
        if (resultadoAdherirOperaciones === "EXITO") {
            setTimeout(() => {
                handleClose()
            }, 4000);
        }
    }, [resultadoAdherirOperaciones])

    const adherirOperacion = () => {
        dispatch(adherirOperacionSindicada(token, id, Number(adhesion.value), rechazo.value))
    }

    const opcionesAdhesion = [
        { value: "1", label: "Adherida" },
        { value: "100000001", label: "Rechazada" },
    ]

    const opcionesRechazo = [
        {
            value: "100000000",
            label:
                "La SGR SINDICADA tiene tomado el cupo del 5% con el SOCIO PARTICIPE/CLIENTE PYME en análisis y/o de participar en el aval sindicado alcanzaría dicho límite,",
        },
        {
            value: "100000001",
            label:
                "El SOCIO PARTICIPE/CLIENTE PYME y/o sus socios y/o sus vinculadas han tenido y/o tienen deuda vigente con la SGR SINDICADA y/o están y/o ha/n estado en instancia judicial por mora",
        },
    ]

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="sm"
                fullWidth
            >
                <Box sx={{ display: "flex", justifyContent: "space-between", px: 1 }}>
                    <DialogTitle
                        id="alert-dialog-title"
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <Typography
                            variant="h1"
                            color="inherit"
                            sx={{ fontSize: "1.2rem", fontWeight: 600, m: 0, p: 0 }}
                        >
                            Adherirse a Garantía
                        </Typography>
                    </DialogTitle>
                    <Tooltip title={<Typography sx={{ color: '#fff' }}>Cerrar</Typography>} >
                        <IconButton
                            edge="end"
                            color="warning"
                            onClick={handleClose}
                            aria-label="close"
                            sx={{ width: 40, height: 40, mr: 1, mt: 1 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Divider />
                <DialogContent
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                    }}
                >
                    <FormControl variant="standard" sx={{ m: 1, width: { md: "80%", xs: "90%" } }}>
                        <InputLabel
                            variant="standard"
                            htmlFor="adherir"
                            sx={{
                                display: "flex",
                                justifyContent: "start",
                                alignItems: "center",
                                gap: 2,
                            }}
                        >
                            Adherir/Rechazar garantía
                        </InputLabel>
                        <Select
                            defaultValue={adhesion.value}
                            onChange={(e) => {
                                setAdhesion({ value: e.target.value });
                            }}
                            inputProps={{
                                name: "adherir",
                                id: "adherir",
                                style: { fontSize: ".8rem" },
                            }}
                        >
                            {opcionesAdhesion.map((item) => {
                                return (
                                    <MenuItem key={item.value} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                    {adhesion?.value == "100000001" && (
                        <FormControl variant="standard" sx={{ m: 1, width: { md: "80%", xs: "90%" } }}>
                            <InputLabel
                                variant="standard"
                                htmlFor="dictamenAval"
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: 2,
                                }}
                            >
                                Motivo de Rechazo
                            </InputLabel>
                            <Select
                                defaultValue={rechazo.value}
                                onChange={(e) => {
                                    setRechazo({ value: e.target.value });
                                }}
                                inputProps={{
                                    name: "rechazo",
                                    id: "rechazo",
                                    style: { fontSize: ".8rem" },
                                }}
                            >
                                {opcionesRechazo.map((item) => {
                                    return (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.label}
                                        </MenuItem >
                                    );
                                })}
                            </Select>
                        </FormControl>
                    )}
                </DialogContent>
                <DialogActions
                    sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                    }}
                >
                    <Box sx={{ mt: 3, mb: 2, position: 'relative', mx: 2 }}>
                        <Button
                            type="button"
                            disabled={resultadoAdherirOperaciones === 'LOADING' || resultadoAdherirOperaciones === 'EXITO'}
                            onClick={adherirOperacion}
                            variant="contained"
                        >
                            {adhesion.value !== "100000001" ? "Adherir" : "Rechazar"}
                        </Button>
                        {resultadoAdherirOperaciones === 'LOADING' && (
                            <CircularProgress
                                size={27}
                                sx={{
                                    color: "#fff",
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ModalOperacionSindicada;