import axios from 'axios'
import { UrlApi, UrlApiDynamics, Entidad, authMail, authPass } from '../Keys'
import { auth, firebase, storage } from '../firebase'
import { toast } from 'react-toastify';

//constantes
const dataInicial = {
    loading: false,
    activo: false,
    error: false,
    logoff: false,
    accountid: '',
    contactid: '',
    tipo: '',
    resultado: '',
    resultadoLogin: '',
    resultadoRecupero: '',
    resultadoRegistro: '',
    photoURL: '',
    user: [],
    status: false,
}

//Types auth/wrong-password
const LOADING = 'LOADING'
const USUARIO_ERROR = 'USUARIO_ERROR'
const USUARIO_EXITO = 'USUARIO_EXITO'
const CERRAR_SESION = 'CERRAR_SESION'
const REGISTRO_USUARIO = 'REGISTRO_USUARIO'
const USUARIO_ACTUALIZACION = 'USUARIO_ACTUALIZACION'
const REGISTRO_USUARIO_EXISTENTE = 'REGISTRO_USUARIO_EXISTENTE'
const RECUPERO_CONTRASEÑA = 'RECUPERO_CONTRASEÑA'
const USUARIO_ACTIVADO = 'USUARIO_ACTIVADO'

export default function usuariosReducer(state = dataInicial, action) {
    switch (action.type) {
        case USUARIO_ACTUALIZACION:
            return { ...state, accountid: action.accountid, contactid: action.contactid, tipo: action.tipo, photoURL: action.payload }
        case REGISTRO_USUARIO:
            return { ...state, user: action.payload, activo: action.activo, accountid: action.accountid, contactid: action.contactid, resultado: action.resultado, tipo: action.tipo, ALYC: action.ALYC, SGR: action.SGR, Personeria: action.Personeria, Estado: action.Estado, Name: action.Name, Cuit: action.Cuit, photoURL: action.photoURL }
        case CERRAR_SESION:
            return { ...dataInicial, loading: false, activo: false, logoff: true, accountid: '', contactid: '', tipo: '', resultado: '', user: null }
        case USUARIO_EXITO:
            return { ...state, user: action.payload, activo: action.activo, accountid: action.accountid, contactid: action.contactid, resultadoLogin: action.resultado, tipo: action.tipo, Personeria: action.Personeria, Estado: action.Estado, Name: action.Name, Cuit: action.Cuit, photoURL: action.photoURL }
        case REGISTRO_USUARIO_EXISTENTE:
            return { ...state, user: action.payload, activo: action.activo, accountid: action.accountid, contactid: action.contactid, resultadoRegistro: action.resultado, tipo: action.tipo, ALYC: action.ALYC, SGR: action.SGR, Personeria: action.Personeria, Estado: action.Estado, Name: action.Name, Cuit: action.Cuit, photoURL: action.photoURL }
        case RECUPERO_CONTRASEÑA:
            return { ...dataInicial, resultadoRecupero: action.resultado }
        case USUARIO_ERROR:
            return { ...dataInicial, error: true, resultado: action.resultado }
        case LOADING:
            return { ...state, resultado: action.resultado, loading: action.loading }
        case USUARIO_ACTIVADO:
            return { ...state, user: action.payload }
        default:
            return { ...state }
    }
}

const loadingError = (msj) => {
    toast.error(msj, {
        theme: "dark",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};
const loadingExito = (msj) => {
    toast.success(msj, {
        theme: "dark",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })
}

export const login = (email, pass) => async (dispatch) => {
    dispatch({
        type: USUARIO_EXITO,
        resultado: 'LOADING',
        activo: false
    })

    try {
        const resp = await auth.signInWithEmailAndPassword(email, pass)
        let estado = ''
        await firebase.collection('usuarios').doc(resp.user.uid).get()
            .then((doc) => {
                if (doc.exists) {
                    const documento = doc.data()

                    if (documento.tipo == "Pyme") {
                        debugger
                        let respCuenta
                        var respAuth
                        let token = ''
                        let existeDynamics = true
                        var entidad = "accounts"
                        var fetch = "<fetch mapping='logical'>" +
                            "<entity name='account'>" +
                            "<attribute name='accountid'/> " +
                            "<attribute name='new_estadodelsocio'/> " +
                            "<order attribute ='createdon' descending='false' />" +
                            "<filter type='and'>" +
                            "<condition attribute='emailaddress1' operator='eq' value='" + email + "' />" +
                            "<condition attribute='statecode' operator='eq' value='0' />" +
                            "</filter>" +
                            "</entity>" +
                            "</fetch>";

                        const login = () => new Promise(async (resolve, reject) => {
                            await axios.post(`${UrlApi}api/Usuarios/login`, {
                                "email": authMail,
                                "password": authPass
                            }).then(resp => {
                                token = resp.data
                            }).catch(() => {
                                reject('Error de Autenticacion')
                            })
                            await axios.post(`${UrlApi}api/consultafetch`,
                                {
                                    "entidad": entidad,
                                    "fetch": fetch
                                },
                                {
                                    headers: {
                                        "Authorization": `Bearer ${token.token}`
                                    }
                                })
                                .then((respMail) => {
                                    if (respMail.data.length == 0) {
                                        debugger
                                        existeDynamics = false
                                        reject('No existe una cuenta con ese correo en nuestro sistema')
                                        return
                                    } else {
                                        debugger
                                        respCuenta = respMail.data
                                    }
                                })
                                .catch(() => {
                                    debugger
                                    existeDynamics = false
                                    reject("Hubo un error al inciar sesión, por favor intente mas tarde")
                                    return
                                })
                            debugger
                            const documento = doc.data()
                            if (respCuenta != null && respCuenta[0].new_estadodelsocio !== documento.estado) {
                                firebase.collection('usuarios').doc(respAuth.user.uid).update("Estado", respCuenta[0].new_estadodelsocio)
                                estado = respCuenta[0].new_estadodelsocio
                            }
                        })
                    } else {
                        estado = documento.estado
                    }

                    localStorage.setItem('usuario', JSON.stringify({
                        email: resp.user.email,
                        uid: resp.user.uid,
                        accountid: documento.accountid,
                        contactid: documento.contactid,
                        tipo: documento.tipo,
                        personeria: documento.personeria,
                        fullname: documento.fullname,
                        name: documento.name,
                        apellido: documento.apellido,
                        servicio: documento.servicio,
                        estado: estado,
                        cuit: documento.cuit,
                        photoURL: documento.photoURL,
                    }))

                    dispatch({
                        type: USUARIO_EXITO,
                        resultado: 'EXITO',
                        activo: true,
                        tipo: documento.tipo,
                        accountid: documento.accountid,
                        contactid: documento.contactid,
                        photoURL: documento.photoURL,
                        payload: {
                            email: resp.user.email,
                            uid: resp.user.uid,
                            accountid: documento.accountid,
                            contactid: documento.contactid,
                            tipo: documento.tipo,
                            estado: estado,
                            personeria: documento.personeria,
                            fullname: documento.fullname,
                            name: documento.name,
                            apellido: documento.apellido,
                            servicio: documento.servicio,
                            cuit: documento.cuit,
                            photoURL: documento.photoURL,
                        }
                    })
                    loadingExito('Ingreso completado 👌')
                }
            })
    } catch (error) {
        console.log("error", error)
        dispatch({
            type: USUARIO_EXITO,
            resultado: 'ERROR',
            activo: false
        })
        if (error.code == "auth/user-not-found") {
            loadingError("Usuario no encontrado!")
        }
        if (error.code == "auth/wrong-password") {
            loadingError("La contraseña es incorrecta!")
        }
    }
}

export const logincasfog = (email, pass) => async (dispatch) => {
    dispatch({
        type: USUARIO_EXITO,
        resultado: 'LOADING',
        activo: false
    })

    try {
        const onboarding = () => new Promise(async (resolve, reject) => {
            debugger
            let resp
            await auth.signInWithEmailAndPassword(email, pass)
                .then(response => {
                    debugger
                    resp = response
                })
                .catch(err => {
                    debugger
                    if (err.code == "auth/wrong-password") {
                        reject("La contraseña es incorrecta")
                    } else if (err.code = 'auth/user-not-found') {
                        reject("El correo es inexistente")
                    } else {
                        reject("El correo o la contraseña son incorrectos")
                    }
                    return
                })
            debugger
            let estado = ''
            let documento
            await firebase.collection('usuarios').doc(resp.user.uid).get()
                .then((doc) => {
                    if (doc.exists) {
                        documento = doc.data()
                    }
                })
            if (documento !== undefined) {
                if (documento.tipo == "Pyme") {
                    let respCuenta
                    let token = ''
                    let existeDynamics = true
                    var entidad = "accounts"
                    var fetch = "<fetch mapping='logical'>" +
                        "<entity name='account'>" +
                        "<attribute name='accountid'/> " +
                        "<attribute name='new_estadodelsocio'/> " +
                        "<order attribute ='createdon' descending='false' />" +
                        "<filter type='and'>" +
                        "<condition attribute='emailaddress1' operator='eq' value='" + email + "' />" +
                        "<condition attribute='statecode' operator='eq' value='0' />" +
                        "</filter>" +
                        "</entity>" +
                        "</fetch>";

                    await loginDynamics()
                        .then(data => {
                            token = data
                        })
                        .catch(error => {
                            console.log(error)
                        })

                    await axios.post(`${UrlApi}api/consultafetch`,
                        {
                            "entidad": entidad,
                            "fetch": fetch
                        },
                        {
                            headers: {
                                "Authorization": `Bearer ${token.token}`
                            }
                        })
                        .then((respMail) => {
                            if (respMail.data.length == 0) {
                                existeDynamics = false
                                reject('No existe una cuenta con ese correo en nuestro sistema')
                                return
                            } else {
                                respCuenta = respMail.data
                            }
                        })
                        .catch(() => {
                            existeDynamics = false
                            reject("Hubo un error al inciar sesión, por favor intente mas tarde")
                            return
                        })
                    if (respCuenta != null && respCuenta[0].new_estadodelsocio !== documento.estado) {
                        firebase.collection('usuarios').doc(resp.user.uid).update("Estado", respCuenta[0].new_estadodelsocio)
                        estado = respCuenta[0].new_estadodelsocio
                    }
                } else {
                    estado = documento.estado
                }
            } else {
                dispatch({
                    type: USUARIO_EXITO,
                    resultado: 'ERROR',
                    activo: false
                })
            }

            localStorage.setItem('usuario', JSON.stringify({
                email: resp.user.email,
                uid: resp.user.uid,
                accountid: documento.accountid,
                contactid: documento.contactid,
                tipo: documento.tipo,
                personeria: documento.personeria,
                fullname: documento.fullname,
                name: documento.name,
                apellido: documento.apellido,
                servicio: documento.servicio,
                estado: estado,
                cuit: documento.cuit,
                photoURL: documento.photoURL,
            }))

            dispatch({
                type: USUARIO_EXITO,
                resultado: 'EXITO',
                activo: true,
                tipo: documento.tipo,
                accountid: documento.accountid,
                contactid: documento.contactid,
                photoURL: documento.photoURL,
                payload: {
                    email: resp.user.email,
                    uid: resp.user.uid,
                    accountid: documento.accountid,
                    contactid: documento.contactid,
                    tipo: documento.tipo,
                    estado: estado,
                    personeria: documento.personeria,
                    fullname: documento.fullname,
                    name: documento.name,
                    apellido: documento.apellido,
                    servicio: documento.servicio,
                    cuit: documento.cuit,
                    photoURL: documento.photoURL,
                }
            })
        })

        const response = await toast.promise(
            onboarding,
            {
                // pending: 'Procesando solicitud...',
                success: 'Ingreso completado 👌',
                error: {
                    render({ data }) {
                        dispatch({
                            type: USUARIO_EXITO,
                            resultado: 'ERROR',
                            activo: false
                        })
                        return `${data}`
                    }
                }
            },
            {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
        )
    } catch (error) {
        console.log("error", error)
        dispatch({
            type: USUARIO_EXITO,
            resultado: 'ERROR',
            activo: false
        })
        if (error.code == "auth/user-not-found") {
            loadingError("Usuario no encontrado!")
        }
        if (error.code == "auth/wrong-password") {
            loadingError("La contraseña es incorrecta!")
        }
    }
}

const loginDynamics = () => new Promise(async (resolve, reject) => {
    await axios.post(`${UrlApi}api/Usuarios/login`, {
        "email": authMail,
        "password": authPass
    }).then(resp => {
        return resolve(resp.data)
    }).catch(error => {
        return reject(error)
    })
})

const validarUsuarioEnDynamics = (solicitud, token) => new Promise(async (resolve, reject) => {
    var entidad = "accounts"
    var fetch = "<fetch mapping='logical'>" +
        "<entity name='account'>" +
        "<attribute name='accountid'/>" +
        "<attribute name='new_invitacion'/> " +
        "<filter type='and'>" +
        "<filter type='or'>" +
        "<condition attribute='emailaddress1' operator='eq' value='" + solicitud.email + "' />" +
        "<condition attribute='name' operator='eq' value='" + solicitud.razonSocial + "' />" +
        "<condition attribute='new_nmerodedocumento' operator='eq' value='" + solicitud.cuitCuilCdi + "' />" +
        "</filter>" +
        "</filter>" +
        "</entity>" +
        "</fetch>";

    axios.post(`${UrlApi}api/consultafetch`,
        {
            "entidad": entidad,
            "fetch": fetch
        },
        {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        }
    )
        .then((data) => { return resolve(data) })
        .catch((error) => { return reject(error) })
})


export const registrarUsuarioContacto = (email, pass, tipo, nombre, apellido, token) => async (dispatch) => {
    dispatch({
        type: REGISTRO_USUARIO_EXISTENTE,
        resultado: 'LOADING',
        activo: false
    })
    let responseMail
    console.log("tipo", tipo)
    try {
        if (tipo === "SGR") {
            let entidad = 'contacts'
            let fetch = "<fetch mapping='logical' distinct='false'>" +
                "<entity name='contact'>" +
                "<attribute name='contactid' />" +
                "<attribute name='fullname' />" +
                "<filter type='and'>" +
                "<condition attribute='emailaddress1' operator='eq' value='" + email + "' />" +
                "</filter>" +
                "</entity>" +
                "</fetch>";
            responseMail = await axios.post(`${UrlApi}api/consultafetch`,
                {
                    "entidad": entidad,
                    "fetch": fetch
                },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
        } else {
            let entidad = 'accounts'
            let fetch = "<fetch mapping='logical' distinct='false'>" +
                "<entity name='account'>" +
                "<attribute name='name' />" +
                "<attribute name='primarycontactid' />" +
                "<attribute name='telephone1' />" +
                "<attribute name='accountid' />" +
                "<attribute name='new_productoservicio' />" +
                "<attribute name='new_personeria' />" +
                "<attribute name='new_estadodelsocio' />" +
                "<attribute name='address1_name' />" +
                "<attribute name='new_categoria' />" +
                "<attribute name='new_calificacion' />" +
                "<attribute name='new_nmerodedocumento' />" +
                "<order attribute='name' descending='false' />" +
                "<filter type='and'>" +
                "<condition attribute='emailaddress1' operator='eq' value='" + email + "' />" +
                "</filter>" +
                "</entity>" +
                "</fetch>";
            responseMail = await axios.post(`${UrlApi}api/consultafetch`,
                {
                    "entidad": entidad,
                    "fetch": fetch
                },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
        }

        if (responseMail?.data.length > 0) {
            let contactid = ""
            let accountid = ""
            let servicio = ""
            let personeria = ""
            let name = ""
            let fullname = ""
            let estado = ""
            let cuit = ""
            if (responseMail?.data[0].contactid) {
                contactid = responseMail?.data[0].contactid
                fullname = responseMail.data[0].fullname
            }
            if (responseMail?.data[0].accountid) {
                accountid = responseMail?.data[0].accountid
                servicio = responseMail?.data[0].new_productoservicio
                personeria = responseMail.data[0].new_personeria
                name = responseMail.data[0].name
                estado = responseMail.data[0].new_estadodelsocio
                cuit = responseMail.data[0].new_nmerodedocumento
            }

            const resp = await auth.createUserWithEmailAndPassword(email, pass)
            if (resp.user) {
                await firebase.collection('usuarios').doc(resp.user.uid).set({
                    email: resp.user.email,
                    uid: resp.user.uid,
                    contactid: contactid,
                    accountid: accountid,
                    fullname: fullname,
                    nombre: nombre,
                    apellido: apellido,
                    tipo: tipo,
                    servicio: servicio,
                    personeria: personeria,
                    name: name,
                    estado: estado,
                    cuit: cuit
                })

                localStorage.setItem('usuario', JSON.stringify({
                    email: resp.user.email,
                    uid: resp.user.uid,
                    contactid: contactid,
                    accountid: accountid,
                    fullname: fullname,
                    nombre: nombre,
                    apellido: apellido,
                    tipo: tipo,
                    servicio: servicio,
                    personeria: personeria,
                    name: name,
                    estado: estado,
                    cuit: cuit
                }))

                dispatch({
                    type: REGISTRO_USUARIO_EXISTENTE,
                    contactid: contactid,
                    accountid: accountid,
                    tipo: tipo,
                    resultado: 'EXITO',
                    activo: true,
                    payload: {
                        email: resp.user.email,
                        uid: resp.user.uid,
                        fullname: fullname,
                        nombre: nombre,
                        apellido: apellido,
                        contactid: contactid,
                        accountid: accountid,
                        tipo: tipo,
                        servicio: servicio,
                        personeria: personeria,
                        name: name,
                        estado: estado,
                        cuit: cuit

                    }
                })
                loadingExito('Registro completado 👌')
            }

        } else {
            dispatch({
                type: REGISTRO_USUARIO_EXISTENTE,
                resultado: 'ERROR',
                activo: false
            })
            tipo === "SGR" ? loadingError("El usuario no existe!") : loadingError("La cuenta no existe!")
        }

    } catch (error) {
        console.log("error", error)
        dispatch({
            type: REGISTRO_USUARIO_EXISTENTE,
            resultado: 'ERROR',
            activo: false
        })
        if (error.code == "auth/account-exists-with-different-credential") {
            loadingError("La cuenta ya existe con diferentes credenciales!")
        }
        if (error.code == "auth/email-already-in-use") {
            loadingError("El correo ya esta en uso!")
        }
        if (error.code == "auth/credential-already-in-use") {
            loadingError("Las credenciales estan en uso!")
        }
        if (error.code == "auth/network-request-failed") {
            loadingError("Error de red, actualice el navegador 'F5'!")
        }
    }
}

export const cerrarSesion = () => async (dispatch) => {
    dispatch({
        type: LOADING
    })

    try {
        window.location.reload()
        setTimeout(() => {
            emptyCache()
            //Eliminamos el usuario del local storage
            localStorage.removeItem('usuario')
            localStorage.removeItem('Id SGRLider')
            localStorage.removeItem('Id SGR')
            localStorage.removeItem('Nombre SGR')
            localStorage.removeItem('SGR Datos')
            //Cierra sesion en firestore
            auth.signOut()
            window.location.reload()
        }, 0);
        dispatch({
            type: CERRAR_SESION
        })
    } catch (error) {
        dispatch({
            type: USUARIO_ERROR
        })
    }
}

export const leerUsuarioActivo = () => (dispatch) => {
    if (localStorage.getItem('usuario')) {
        const user = JSON.parse(localStorage.getItem('usuario'))
        dispatch({
            type: USUARIO_EXITO,
            activo: true,
            resultado: 'EXITO',
            payload: user,
            accountid: user.accountid,
            contactid: user.contactid,
            tipo: user.tipo,
            personeria: user.personeria,
            estado: user.estado,
            name: user.name,
            servicio: user.servicio,
            cuit: user.cuit,
            photoURL: user.photoURL
        })
    }
}

function emptyCache() {
    if ('caches' in window) {
        caches.keys().then((names) => {
            // Delete all the cache files
            names.forEach(name => {
                caches.delete(name);
            })
        });

        // // Makes sure the page reloads. Changes are only visible after you refresh.
        // window.location.reload(true);
    }
}


export const recuperarContraseña = (email) => async (dispatch) => {
    dispatch({
        type: RECUPERO_CONTRASEÑA,
        resultado: 'LOADING',
    })

    try {
        const resp = await auth.sendPasswordResetEmail(email)

        dispatch({
            type: RECUPERO_CONTRASEÑA,
            resultado: 'EXITO',
        })
        toast.success('El recupero de contraseña fue exitosa', {
            theme: "dark",
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        })

    } catch (error) {
        dispatch({
            type: RECUPERO_CONTRASEÑA,
            resultado: 'ERROR',
        })
        toast.error('No pudimos procesar la operación, por favor, vuelva a intentarlo...', {
            theme: "dark",
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    }
}

export const actualizarFotoUid = (imgEditada) => async (dispatch) => {
    dispatch({
        type: LOADING
    })

    const user = auth.currentUser;
    let urlImg
    // console.log(user)
    try {
        const subirFoto = () => new Promise(async (resolve, reject) => {
            const imgRef = storage.ref().child(user.uid).child('foto perfil')
            await imgRef.put(imgEditada)
            const imgURL = await imgRef.getDownloadURL()
            urlImg = imgURL
            //uid
            await firebase.collection('usuarios').doc(user.uid).update({
                photoURL: imgURL,
            }).then(() => {
                if (localStorage.getItem('usuario')) {
                    const user = JSON.parse(localStorage.getItem('usuario'))
                    user.photoURL = urlImg
                    localStorage.setItem("usuario", JSON.stringify(user))
                }
                resolve()
            }).catch((error) => {
                reject(error)
            })
        })
        // await firebase.collection('usuarios').doc(user.uid).update({
        //     photoURL: imgURL,
        // }).then(() => {
        //     toast.success('Foto subida con éxito!', {
        //         theme: "dark",
        //         position: "bottom-right",
        //         autoClose: 2000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //     });
        // }).catch((error) => {
        //     toast.error('Ocurrió un problema', {
        //         theme: "dark",
        //         position: "bottom-right",
        //         autoClose: 2000,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //     });
        //     console.log(error)
        // })

        const response = await toast.promise(
            subirFoto,
            {
                pending: 'Procesando...',
                success: 'Foto subida con éxito!',
                error: {
                    render({ data }) {
                        // console.log(data)
                        return `${data}`
                    }
                }
            },
            {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
        )

        await firebase.collection('usuarios').doc(user.uid).get()
            .then((doc) => {
                if (doc.exists) {
                    // debugger
                    const documento = doc.data()
                    let tipo = documento.tipo

                    if (tipo === "PYME") {
                        let accountid = documento.accountid
                        axios.post(`${UrlApiDynamics}account/fotodeperfil?accountId=${accountid}&url=${encodeURIComponent(urlImg)}&cuit=${Entidad}`)
                    } else {
                        // console.log("No es una pyme.")
                    }

                }
            })
        debugger
        dispatch({
            type: USUARIO_ACTUALIZACION,
            payload: urlImg
        })


    } catch (error) {
        // console.log(error)
    }
}

export const activarUsuarioFirebase = (uid, estado) => async (dispatch) => {
    try {
        await firebase.collection('usuarios').doc(uid).get()
            .then((doc) => {
                if (doc.exists) {
                    firebase.collection('usuarios').doc(uid).update("Estado", estado)
                        .then(() => {
                            let usuarioStorage = JSON.parse(localStorage.getItem("usuario"))
                            if (usuarioStorage != null) {
                                usuarioStorage.estado = estado
                                localStorage.setItem("usuario", JSON.stringify(usuarioStorage))
                                dispatch({
                                    type: USUARIO_ACTIVADO,
                                    payload: usuarioStorage
                                })

                            }
                        })
                }
            })
    } catch {
        console.log("Error")
    }
}