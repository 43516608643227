import React, { useState, useEffect, useContext } from 'react'
import { Box, Button, Menu, MenuItem, Typography } from '@mui/material'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import { useDispatch, useSelector } from 'react-redux'
import { obtenerRelacionSeleccionada } from "../../Redux/RelacionDeVinculacion"
import ModalRelaciones from '../modales/ModalRelaciones'
import { obtenerSocios } from '../../Redux/Cuenta'
import TokenContext from '../../context/TokenContext'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

const SeleccionarFilaRelacion = ({ value, boton = false, socioId = null }) => {

    const dispatch = useDispatch()
    const token = useContext(TokenContext)

    const socioIdSelector = useSelector(store => store.cuenta.cuentaIdSeleccionada)
    const sociosSelector = useSelector(store => store.cuenta.socios)

    const [anchorEl, setAnchorEl] = useState(null)
    const [openModal, setOpenModal] = useState(false)
    const [relaciones, setRelaciones] = useState([])
    const [relacionid, setRelacionid] = useState('')

    const open = Boolean(anchorEl)
    const handleOpen = () => setOpenModal(true)

    useEffect(() => {
        if (open) {
            dispatch(obtenerSocios(token))
        }
    }, [open])

    useEffect(() => {
        let relacionesPorSocio = [...new Map(sociosSelector.map(item =>
            [item["ad.new_participacionaccionariaid"], item])).values()]
        let relacionesXS = []
        relacionesPorSocio.filter(item => item.accountid == socioId ? socioId : socioIdSelector).forEach(item => {
            setRelacionid(item["ad.new_participacionaccionariaid"])
            let relacion = {
                new_cuentacontactovinculadoid: item["ad.new_cuentacontactovinculado"],
                new_cuentacontactovinculado: item["ad.new_cuentacontactovinculado@OData.Community.Display.V1.FormattedValue"],
                new_tipoderelacion: item["ad.new_tipoderelacion@OData.Community.Display.V1.FormattedValue"],
                new_participacionid: item["ad.new_participacionaccionariaid"],
                name: item["cuenta.name"],
                accountid: item["cuenta.accountid"],
                emailaddress1: item["cuenta.emailaddress1"],
                cuenta_emailaddress1: item["cuenta.emailaddress1"],
                dniCuenta: item["cuenta.new_nmerodedocumento"],
                new_nmerodedocumento: item["cuenta.new_nmerodedocumento"],
                firstname: item["contacto.firstname"],
                lastname: item["contacto.lastname"],
                dni: item["contacto.new_nrodedocumento"],
                new_tienedomicilioenusa: item["contacto.new_tienedomicilioenusa"],
                new_tienedomiciliofiscalenelexterior: item["contacto.new_tienedomiciliofiscalenelexterior"],
                fechaNacimiento: item["contacto.birthdate"],
                contactid: item["contacto.contactid"],
                fullname: item["contacto.fullname"],
                familystatuscode: item["contacto.familystatuscode"],
                familystatuscode_value: item["contacto.familystatuscode@OData.Community.Display.V1.FormattedValue"],
                new_tipodedocumentoconyuge: item["contacto.new_tipodedocumentoconyuge"],
                new_tipodedocumentoconyuge_value: item["contacto.new_tipodedocumentoconyuge@OData.Community.Display.V1.FormattedValue"],
                new_generodelconyuge: item["contacto.new_generodelconyuge"],
                new_generodelconyuge_value: item["contacto.new_generodelconyuge@OData.Community.Display.V1.FormattedValue"],
                new_tiposujetoobligado: item["contacto.new_tiposujetoobligado"],
                new_tiposujetoobligado_value: item["contacto.new_tiposujetoobligado@OData.Community.Display.V1.FormattedValue"],
                new_einss: item["contacto.new_einss"],
                new_nit: item["contacto.new_nit"],
                new_nrodedocumentoconyuge: item["contacto.new_nrodedocumentoconyuge"],
                new_nrodedocumento: item["contacto.new_nrodedocumento"],
                new_lugardenacimiento: item["contacto.new_lugardenacimiento"],
                new_cuitcuil: item["contacto.new_cuitcuil"],
                contact_emailaddress1: item["contacto.emailaddress1"],
                address3_line1: item["contacto.address3_line1"],
                address3_postalcode: item["contacto.address3_postalcode"],
                address3_stateorprovince: item["contacto.address3_stateorprovince"],
                address3_city: item["contacto.address3_city"],
                address3_line1: item["contacto.address3_line1"],
                new_paisdeorgenid: item["contacto.new_paisdeorgenid"],
                new_paisdeorgen_value: item["contacto.new_paisdeorgenid@OData.Community.Display.V1.FormattedValue"],
                address1_postalcode: item["contacto.address1_postalcode"],
                address1_line1: item["contacto.address1_line1"],
                address1_stateorprovince: item["contacto.address1_stateorprovince"],
                address1_city: item["contacto.address1_city"],
                address1_country: item["contacto.address1_country"],
                address1_line1: item["contacto.address1_line1"],
                new_personaexpuestapoliticamente: item["contacto.new_personaexpuestapoliticamente"],
                new_cargofuncinjerarquayenteendondetrabaja: item["contacto.new_cargofuncinjerarquayenteendondetrabaja"],
                new_relacinconlapersonaexpuestapolticamente: item["contacto.new_relacinconlapersonaexpuestapolticamente"],
                new_entedondetrabaja: item["contacto.new_entedondetrabaja"],
                new_fechadealtabaja: item["contacto.new_fechadealtabaja"],
                new_fechadebaja: item["contacto.new_fechadebaja"],
                new_conyugetrabaja: item["contacto.new_conyugetrabaja"],
                spousesname: item["contacto.spousesname"],
                valor: {
                    relacionid: item["ad.new_participacionaccionariaid"],
                    tipo: item["ad.new_tipoderelacion"]
                }
            }
            relacionesXS.push(relacion)
        })
        if (value) {
            setRelaciones(relacionesXS.filter(item => item["new_participacionid"] == value.relacionid))
        } else {
            setRelaciones(relacionesXS)
        }
    }, [sociosSelector, socioId, boton, relacionid, value])


    const obtenerId = (id) => {
        dispatch(obtenerRelacionSeleccionada(id));
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <>
            {boton ?
                <Box>
                    <Button
                        type="button"
                        onClick={handleOpen}
                        variant="contained"
                    >
                        <Typography variant="subtitle2" color="inherit" sx={{ fontSize: { xs: ".7rem", md: ".8rem" } }}>Agregar Fiador</Typography>
                    </Button>
                </Box>
                :
                <Box textAlign='center'>
                    <Button
                        id="basic-button"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <MoreHorizIcon sx={{ fontSize: { xs: "1.2rem", md: "1.5rem" } }} />
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            'aria-labelledby': 'basic-button',
                        }}
                    >
                        {
                            value.tipo == 100000006 ?
                                <MenuItem onClick={() => {
                                    handleClose()
                                    handleOpen()
                                    obtenerId(value.relacionid)
                                }}>
                                    Editar Fiador
                                </MenuItem>
                                :
                                <MenuItem onClick={() => {
                                    handleClose()
                                }}>
                                    No se puede editar
                                </MenuItem>
                        }
                    </Menu>
                </Box>
            }
            {relaciones.length > 0 && <ModalRelaciones
                open={openModal}
                setOpen={setOpenModal}
                relaciones={boton ? [] : relaciones}
                socioIdSelector={socioId ? socioId : socioIdSelector}
                relacionid={boton ? "" : relacionid}
                boton={boton}
            />}
        </>

    )
}

export default SeleccionarFilaRelacion