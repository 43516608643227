import axios from 'axios'
import { UrlApi, authMail, authPass } from '../Keys'

const dataInicial = {
    loading: false,
    token: '',
    resultado: ''
}

const LOADING = 'LOADING'
const TOKEN_EXITO = 'TOKEN_EXITO'
const TOKEN_ERROR = 'TOKEN_ERROR'


//Reducers
export default function tokenReducer(state = dataInicial, action) {
    switch (action.type) {
        case TOKEN_EXITO:
            return { ...state, token: action.payload }
        case TOKEN_ERROR:
            return { ...dataInicial, resultado: action.resultado }
        case LOADING:
            return { ...state, resultado: action.resultado }
        default:
            return { ...state }
    }
}

export const loginToken = () => async (dispatch) => {
    dispatch({
        type: LOADING,
        resultado: 'LOADING',
    })
    try {
        const response = await axios.post(`${UrlApi}api/Usuarios/login`, {
            "email": authMail,
            "password": authPass
        })
        dispatch({
            type: TOKEN_EXITO,
            payload: response.data,
            resultado: 'EXITO!'
        })
    }
    catch (error) {
        dispatch({
            type: TOKEN_ERROR,
            resultado: 'ERROR'
        })
    }
}

export const RenovarToken = (token) => async (dispatch) => {
    dispatch({
        type: LOADING,
        resultado: 'LOADING',
    })
    try {
        const response = await axios.get(`https://hw365api.azurewebsites.net/api/Usuarios/RenovarToken`,{
            headers: {
                "Authorization": `Bearer ${token.token}`
            }
        })
        console.log("response renovar token", response)
        dispatch({
            type: TOKEN_EXITO,
            payload: response.data,
            resultado: 'EXITO!'
        })
    }
    catch (error) {
        console.log("response renovar token", error)
        dispatch({
            type: TOKEN_ERROR,
            resultado: 'ERROR'
        })
    }
}

// new_concepto
{/* <fetch version="1.0" output-format="xml-platform" mapping="logical" distinct="false">
  <entity name="new_itemdecomprobantedeventa">
    <attribute name="new_itemdecomprobantedeventaid" />
    <attribute name="new_name" />
    <attribute name="createdon" />
    <attribute name="new_totalsinimpuestos" />
    <attribute name="new_totalimportenogravado" />
    <attribute name="new_total" />
    <attribute name="new_precio" />
    <attribute name="new_porcentajedescuento" />
    <attribute name="new_observaciones" />
    <attribute name="new_nogravado" />
    <attribute name="new_ivacalculado" />
    <attribute name="new_iva" />
    <attribute name="new_importe" />
    <attribute name="new_cantidad" />
    <attribute name="new_alicuotaiva" />
    <attribute name="new_concepto" />
    <order attribute="new_name" descending="false" />
    <filter type="and">
      <condition attribute="new_comprobantedeventa" operator="eq" value="{0}" />
    </filter>
  </entity>
</fetch> */}
{/* <link-entity name="new_productoservicio" from="new_productoservicioid" to="new_productoservicio" link-type="inner" alias="producto">
      <attribute name="new_name" />
    </link-entity> */}

    