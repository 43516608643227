import React, { useContext, useState } from "react"
import {
    Dialog, Box, DialogContent, DialogContentText, DialogTitle, CircularProgress, Button, IconButton, useTheme,
    TextField, DialogActions, FormControl, Divider, Tab, Typography, InputLabel, NativeSelect, Switch, Tooltip, FormControlLabel
} from "@mui/material"
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import CloseIcon from "@mui/icons-material/Close"
import { useSelector, useDispatch } from "react-redux"
import { obtenerTipoDeDocumentos } from "../../Redux/TipoDeDocumento"
import "react-toastify/dist/ReactToastify.css"
import { FormProvider, useForm } from 'react-hook-form'
import CustomTextField from "../CustomTextField"
import { useEffect } from "react"
import { CrearRelacion, editarRelacion } from "../../Redux/RelacionDeVinculacion"
import { toast } from 'react-toastify';
import TokenContext from '../../context/TokenContext'
import { obtenerCuentaAdheridaGarantiaFetch, obtenerPaises } from "../../Redux/Cuenta"

const ModalRelaciones = ({ open, setOpen, relaciones, socioIdSelector, relacionid, boton }) => {
    const dispatch = useDispatch()
    const token = useContext(TokenContext)
    const theme = useTheme()
    const relacionIdSeleccionadaSelector = useSelector(store => store.relaciones.relacionIdSeleccionada)
    const resultado = useSelector(store => store.relaciones.CreacionDatos)
    const tipoDocumentoSelector = useSelector(store => store.tiposDocumentos.tiposDocumentos)
    const paisesSelector = useSelector(store => store.cuenta.paises)
    const cuentaConGarantiaSelector = useSelector(store => store.cuenta.cuentaConGarantia)

    const [idrelacion, setIdrelacion] = useState("")
    const [actualizacion, setActualizacion] = useState(false)
    const [value, setValue] = useState('0')
    const [opcionesTiposDocumentos, setOpcionesTiposDocumentos] = useState([])
    const [tipoDeCarga, setTipoDeCarga] = useState('')
    const [contactoid, setContactoid] = useState('')
    const [correo, setCorreo] = useState('')
    const [estado, setEstado] = React.useState(0)
    const [porcentajeParticipacion, setPorcentajeParticipacion] = useState(0)
    const [relacion, setRelacion] = useState('')
    const [porcentajeBeneficiario, setPorcentajeBeneficiario] = useState(0)
    const [cargo, setCargo] = useState('')
    const [tipoRelacion, setTipoRelacion] = useState('')
    const [tipo, setTipo] = useState({ value: 100000006, label: 'Fiador' })
    const [personeria, setPersoneria] = useState({ label: relaciones[0]?.name ? 'Jurídica' : 'Humana', value: relaciones[0]?.name ? 'Cuenta' : 'Contacto' })
    const [estadoCivil, setEstadoCivil] = useState({ value: relaciones[0]?.familystatuscode === undefined ? 0 : relaciones[0]?.familystatuscode, label: relaciones[0]?.familystatuscode_value !== undefined })
    const [tipoDeDocumento, setTipoDeDocumento] = useState({ value: relaciones[0]?.new_tipodedocumentoconyuge === undefined ? "0" : relaciones[0]?.new_tipodedocumentoconyuge, label: relaciones[0]?.new_tipodedocumentoconyuge_value !== undefined })
    const [generoDelConyuge, setGeneroDelConyuge] = useState({ value: relaciones[0]?.new_generodelconyuge === undefined ? "0" : relaciones[0]?.new_generodelconyuge, label: relaciones[0]?.new_generodelconyuge_value !== undefined })
    const [conyugeTrabaja, setConyugeTrabaja] = useState(relaciones[0]?.new_conyugetrabaja == true)
    const [contactid, setContactid] = useState(relaciones[0]?.contactid)
    const [accountid, setAccountid] = useState(relaciones[0]?.accountid)
    const [defaultValues, setDefaultValues] = useState({
        razonSocial: relaciones[0]?.name,
        nombre: relaciones[0]?.firstname,
        apellido: relaciones[0]?.lastname,
        cuitCuil: relaciones[0]?.new_cuitcuil,
        dniCuenta: relaciones[0]?.dniCuenta,
        dni: relaciones[0]?.dni,
        fechaDeNacimiento: relaciones[0]?.fechaNacimiento,
        lugarDeNacimiento: relaciones[0]?.new_lugardenacimiento,
        correoElectronico: relaciones[0]?.contact_emailaddress1 ? relaciones[0]?.contact_emailaddress1 : relaciones[0]?.cuenta_emailaddress1,
        nombreConyuge: relaciones[0]?.spousesname,
        nroDocumentoConyuge: relaciones[0]?.new_nrodedocumentoconyuge,
        observaciones: "",
        relacionPersona: relaciones[0]?.new_relacinconlapersonaexpuestapolticamente,
        fechaAlta: relaciones[0]?.new_fechadealtabaja,
        fechaBaja: relaciones[0]?.new_fechadebaja,
        personaPolitica: relaciones[0]?.new_personaexpuestapoliticamente,
        funcion: relaciones[0]?.new_entedondetrabaja,
        ente: relaciones[0]?.new_entedondetrabaja,
    })

    useEffect(() => {
        if (relacionIdSeleccionadaSelector !== undefined && relacionIdSeleccionadaSelector !== '') {
            relaciones.filter(item => item.new_participacionid == relacionIdSeleccionadaSelector).map(item => {
                setActualizacion(true)
                setTipoDeCarga('Update')
                setContactoid(item.new_cuentacontactovinculadoid)
                if (item.name != null && item.name != undefined) {
                    setTipoRelacion('Cuenta')
                } else {
                    setTipoRelacion('Contacto')
                    setDefaultValues({})
                }
            })

        }
        if (boton) {
            setTipoDeCarga("Create")
        }
        dispatch(obtenerTipoDeDocumentos(token))
        dispatch(obtenerPaises())
        dispatch(obtenerCuentaAdheridaGarantiaFetch(token))
        setIdrelacion(relacionid)
    }, [])

    useEffect(() => {
        if (cuentaConGarantiaSelector.length > 0) {
            cuentaConGarantiaSelector.filter(item => item.accountid == socioIdSelector).map(item => {
                setCorreo(item.emailaddress1)
            })
        }
    }, [cuentaConGarantiaSelector])

    useEffect(() => {
        if (tipoDocumentoSelector.length > 0) {
            const opcionesDocumentos = [];
            tipoDocumentoSelector.forEach(element => {
                if (element.new_onboarding === true) {
                    var tipo = { value: element.new_tipodedocumentoid, label: element.new_name }
                    opcionesDocumentos.push(tipo);
                }
            });
            setOpcionesTiposDocumentos(opcionesDocumentos)
        }
    }, [tipoDocumentoSelector])

    const ToastError = (msj) => {
        toast.error(msj, {
            theme: "dark",
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };


    const crearRelacionVinculacion = (datos) => {
        debugger
        let vinculadoCon = ""
        const { nombre, apellido, cuitCuil, razonSocial, observaciones, dni, dniCuenta, fechaDeNacimiento, lugarDeNacimiento, correoElectronico, nombreConyuge, nroDocumentoConyuge, funcion, relacionPersona, fechaAlta, fechaBaja, personaPolitica, ente } = datos

        let cuit_cuil = dniCuenta ? dniCuenta : cuitCuil

        if (!personeria?.value) {
            ToastError('La personeria es requerida!')
            return
        }
        if (personeria.value === 'Cuenta' && !razonSocial) {
            ToastError('La razón social es requerida!')
            return
        }
        if (personeria.value === 'Contacto' && !nombre) {
            ToastError('El nombre es requerido!')
            return
        }
        if (personeria.value === 'Contacto' && !apellido) {
            ToastError('El apellido es requerido!')
            return
        }
        if (personeria.value === 'Contacto' && !dni) {
            ToastError('El DNI es requerido')
            return
        }
        if (!cuit_cuil) {
            ToastError('El CUIT/CUIL es requerido!')
            return
        }
        if (personeria.value === 'Contacto' && !fechaDeNacimiento) {
            ToastError('La fecha de nacimiento es requerida!')
            return
        }
        if (personeria.value === 'Contacto' && !lugarDeNacimiento) {
            ToastError('El lugar de nacimiento es requerida!')
            return
        }
        if (personeria.value === 'Contacto' && !estadoCivil?.value) {
            ToastError('El estado civil es requerido!')
            return
        }
        if (!correoElectronico) {
            ToastError('El correo electrónico es requerido!')
            return
        }

        let validacionPorcentaje
        if (personeria.value === "100000001") {
            let accionistas = relaciones.filter(item => item.statecode == "0" && item.new_tipoderelacion == "100000001" && item.new_participacionaccionariaid != idrelacion)
            validacionPorcentaje = validarPorcentaje(accionistas)
        }
        if (validacionPorcentaje > 100) {
            ToastError('El porcentaje total de los accionistas no puede ser mayor a 100%!')
            return
        }

        if (tipoDeCarga === "Create") {
            dispatch(CrearRelacion(token, socioIdSelector, personeria.value, cuit_cuil, nombre, apellido, razonSocial, vinculadoCon, tipo, porcentajeParticipacion, relacion, porcentajeBeneficiario, cargo, observaciones,
                Number(dni), fechaDeNacimiento, lugarDeNacimiento, correoElectronico, estadoCivil.value, nombreConyuge, nroDocumentoConyuge, contactoid, tipoDeCarga, idrelacion, estado, personaPolitica, funcion, ente,
                relacionPersona, fechaAlta, fechaBaja, tipoDeDocumento, generoDelConyuge, conyugeTrabaja))
        } else {
            dispatch(editarRelacion(token, socioIdSelector, personeria.value, cuit_cuil, nombre, apellido, razonSocial, vinculadoCon, tipo, porcentajeParticipacion, relacion, porcentajeBeneficiario, cargo, observaciones,
                Number(dni), fechaDeNacimiento, lugarDeNacimiento, correoElectronico, estadoCivil.value, nombreConyuge, nroDocumentoConyuge, contactid, accountid, tipoDeCarga, idrelacion, estado, personaPolitica, funcion, ente,
                relacionPersona, fechaAlta, fechaBaja, tipoDeDocumento, generoDelConyuge, conyugeTrabaja))
        }
    }

    const validarPorcentaje = (accionis, porcen = "") => {
        let porcentaje = 0
        accionis.forEach(element => {
            porcentaje = porcentaje + parseInt(element.new_porcentajedeparticipacion)
        });
        return porcentaje = porcentaje + parseInt(porcen)
    }

    const methods = useForm({
        shouldUnregister: false,
        defaultValues,
        mode: "onChange"
    })
    const { handleSubmit } = methods

    const handleClose = () => {
        setOpen(false)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const handleConyugeTrabaja = (event) => {
        setConyugeTrabaja(event.target.checked)
    }

    const tiposDeRelacionesOpciones = [
        { value: 'Contacto', label: 'Humana' },
        { value: 'Cuenta', label: 'Jurídica' }
    ]
    const estadoCivilOpciones = [
        { value: 0, label: 'Seleccione una opción' },
        { value: 1, label: 'Soltero/a' },
        { value: 2, label: 'Casado/a' },
        { value: 100000000, label: 'Concubinato' },
        { value: 3, label: 'Divorciado/a' },
        { value: 4, label: 'Viudo/a' }
    ]
    const sexoOpciones = [
        { value: '0', label: 'Seleccione una opción' },
        { value: '100000000', label: 'Masculino' },
        { value: '100000001', label: 'Femenino' },
        { value: '100000002', label: 'Otro' },
        { value: '100000003', label: 'No Especificar' },
    ]
    const sujetoOpciones = [
        { value: '0', label: 'Seleccione una opción' },
        { value: '100000000', label: 'Sujeto Obligado' },
        { value: '100000001', label: 'Sujeto No Obligado' }
    ]

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                fullWidth
            >
                <Box sx={{ backgroundColor: theme.palette.background.default, display: "flex", justifyContent: "space-between", px: 2, alignItems: "center" }}>
                    <DialogTitle id="alert-dialog-title" sx={{ fontSize: "1.2rem" }}>
                        {boton ? "Agregar" : "Editar"} Fiador
                    </DialogTitle>
                    <Tooltip title={<Typography sx={{ color: '#fff' }}>Cerrar</Typography>} >
                        <IconButton
                            edge="end"
                            color="warning"
                            onClick={handleClose}
                            aria-label="close"
                            sx={{ width: 40, height: 40, mr: 1, mt: 1 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Divider />
                <DialogContent sx={{ backgroundColor: theme.palette.background.default, py: 1 }}>
                    <DialogContentText id="alert-dialog-description">
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, mb: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="basic tabs example" flexContainer="true" scroller="true" variant="scrollable" indicator>
                                    <Tab label="Resumen" sx={{ fontSize: { xs: ".7rem", md: ".9rem" } }} value="0" />
                                </TabList>
                            </Box>
                            <FormProvider {...methods}>
                                <TabPanel value="0" sx={{ p: "0px" }}>
                                    <Typography variant="h6" sx={{ fontFamily: theme.typography.fontFamily, mt: 3, mb: 1, mx: 5 }}>General</Typography>
                                    <Box component="div" sx={{ display: "flex", flexDirection: { md: "row", xs: "column" }, justifyContent: "space-between", alignItems: "center" }}>
                                        <FormControl sx={{ m: 1, width: { md: '47.5%', xs: "90%" } }}>
                                            <InputLabel variant="standard" htmlFor="personeria">
                                                Personería
                                            </InputLabel>
                                            <NativeSelect
                                                defaultValue={personeria.value}
                                                onChange={(e) => { setPersoneria({ value: e.target.value }) }}
                                                inputProps={{
                                                    name: "personeria",
                                                    id: "personeria",
                                                    style: { fontSize: ".8rem" },
                                                }}
                                            >
                                                {tiposDeRelacionesOpciones.map((item) => {
                                                    return <option key={item.value} value={item.value}>{item.label}</option>;
                                                })}
                                            </NativeSelect>
                                        </FormControl>
                                    </Box>
                                    {
                                        personeria.value === "Contacto"
                                            ?
                                            <Box>
                                                <Box component="div" sx={{ display: "flex", flexDirection: { md: "row", xs: "column" }, justifyContent: "space-between", alignItems: "center" }}>
                                                    <FormControl sx={{ m: 1, width: { md: '70%', xs: "90%" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            type="text"
                                                            name="nombre"
                                                            label="Nombre"
                                                        />
                                                    </FormControl>
                                                    <FormControl sx={{ m: 1, width: { md: '70%', xs: "90%" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            type="text"
                                                            name="apellido"
                                                            label="Apellido"
                                                        />
                                                    </FormControl>
                                                </Box>
                                                <Box component="div" sx={{ display: "flex", flexDirection: { md: "row", xs: "column" }, justifyContent: "space-between", alignItems: "center" }}>
                                                    <FormControl sx={{ m: 1, width: { md: '70%', xs: "90%" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            type="number"
                                                            name="cuitCuil"
                                                            label="CUIT/CUIL"
                                                        />
                                                    </FormControl>
                                                    <FormControl sx={{ m: 1, width: { md: '70%', xs: "90%" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            type="number"
                                                            name="dni"
                                                            label="DNI"
                                                        />
                                                    </FormControl>
                                                </Box>
                                                <Box component="div" sx={{ display: "flex", flexDirection: { md: "row", xs: "column" }, justifyContent: "space-between", alignItems: "center" }}>
                                                    <FormControl sx={{ m: 1, width: { md: '70%', xs: "90%" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            type="date"
                                                            name="fechaDeNacimiento"
                                                            label="Fecha de Nacimiento"
                                                        />
                                                    </FormControl>
                                                    <FormControl sx={{ m: 1, width: { md: '70%', xs: "90%" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            type="text"
                                                            name="lugarDeNacimiento"
                                                            label="Lugar de Nacimiento"
                                                        />
                                                    </FormControl>
                                                </Box>
                                                <Box component="div" sx={{ display: "flex", flexDirection: { md: "row", xs: "column" }, justifyContent: "space-between", alignItems: "center" }}>
                                                    <FormControl sx={{ m: 1, width: { md: '70%', xs: "90%" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            type="text"
                                                            name="correoElectronico"
                                                            label="Correo Electrónico"
                                                        />
                                                    </FormControl>
                                                    <FormControl sx={{ m: 1, width: { md: '70%', xs: "90%" } }}>
                                                        <InputLabel variant="standard" htmlFor="estadoCivil">
                                                            Estado Civil
                                                        </InputLabel>
                                                        <NativeSelect
                                                            defaultValue={estadoCivil.value}
                                                            onChange={(e) => { setEstadoCivil({ value: e.target.value }) }}
                                                            inputProps={{
                                                                name: "estadoCivil",
                                                                id: "estadoCivil",
                                                                style: { fontSize: ".8rem" },
                                                            }}
                                                        >
                                                            {estadoCivilOpciones.map((item) => {
                                                                return <option key={item.value} value={item.value}>{item.label}</option>;
                                                            })}
                                                        </NativeSelect>
                                                    </FormControl>
                                                </Box>
                                                {
                                                    estadoCivil.value == '2' ?
                                                        <Box>
                                                            <Box component="div" sx={{ display: "flex", flexDirection: { md: "row", xs: "column" }, justifyContent: "space-between", alignItems: "center" }}>
                                                                <FormControl sx={{ m: 1, width: { md: '70%', xs: "90%" } }}>
                                                                    <CustomTextField
                                                                        Component={TextField}
                                                                        type="text"
                                                                        name="nombreConyuge"
                                                                        label="Nombre Conyuge"
                                                                    />
                                                                </FormControl>
                                                                <FormControl sx={{ m: 1, width: { md: '70%', xs: "90%" } }}>
                                                                    <InputLabel variant="standard" htmlFor="tipoDeDocumento">
                                                                        Tipo de Documento
                                                                    </InputLabel>
                                                                    <NativeSelect
                                                                        defaultValue={tipoDeDocumento.value}
                                                                        onChange={(e) => { setTipoDeDocumento({ value: e.target.value }) }}
                                                                        inputProps={{
                                                                            name: "tipoDeDocumento",
                                                                            id: "tipoDeDocumento",
                                                                            style: { fontSize: ".8rem" },
                                                                        }}
                                                                    >
                                                                        {opcionesTiposDocumentos.map((item) => {
                                                                            return <option key={item.value} value={item.value}>{item.label}</option>;
                                                                        })}
                                                                    </NativeSelect>
                                                                </FormControl>
                                                            </Box>
                                                            <Box component="div" sx={{ display: "flex", flexDirection: { md: "row", xs: "column" }, justifyContent: "space-between", alignItems: "center" }}>
                                                                <FormControl sx={{ m: 1, width: { md: '70%', xs: "90%" } }}>
                                                                    <CustomTextField
                                                                        Component={TextField}
                                                                        type="number"
                                                                        name="nroDocumentoConyuge"
                                                                        label="Nro. de Documento Cónyuge"
                                                                    />
                                                                </FormControl>
                                                                <FormControl sx={{ m: 1, width: { md: '70%', xs: "90%" } }}>
                                                                    <InputLabel variant="standard" htmlFor="generoDelConyuge">
                                                                        Género del Cónyuge
                                                                    </InputLabel>
                                                                    <NativeSelect
                                                                        defaultValue={generoDelConyuge.value}
                                                                        onChange={(e) => { setTipoDeDocumento({ value: e.target.value }) }}
                                                                        inputProps={{
                                                                            name: "generoDelConyuge",
                                                                            id: "generoDelConyuge",
                                                                            style: { fontSize: ".8rem" },
                                                                        }}
                                                                    >
                                                                        {sexoOpciones.map((item) => {
                                                                            return <option key={item.value} value={item.value}>{item.label}</option>;
                                                                        })}
                                                                    </NativeSelect>
                                                                </FormControl>
                                                            </Box>
                                                            <Box component="div" sx={{ display: "flex", flexDirection: { md: "row", xs: "column" }, justifyContent: "space-between", alignItems: "center" }}>
                                                                <FormControl sx={{ m: 1, width: { md: '70%', xs: "90%" } }}>
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Switch
                                                                                checked={conyugeTrabaja}
                                                                                onChange={handleConyugeTrabaja}
                                                                                inputProps={{ 'aria-label': 'controlled' }}
                                                                            />
                                                                        }
                                                                        label={<Typography sx={{ fontSize: ".8rem" }}>Cónyuge Trabaja?</Typography>}
                                                                    />
                                                                </FormControl>
                                                            </Box>
                                                        </Box>
                                                        :
                                                        null
                                                }
                                            </Box>
                                            :
                                            <Box>
                                                <Box component="div" sx={{ display: "flex", flexDirection: { md: "row", xs: "column" }, justifyContent: "space-between", alignItems: "center" }}>
                                                    <FormControl sx={{ m: 1, width: { md: '70%', xs: "90%" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            type="text"
                                                            name="razonSocial"
                                                            label="Razón Social"
                                                        />
                                                    </FormControl>
                                                    <FormControl sx={{ m: 1, width: { md: '70%', xs: "90%" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            type="text"
                                                            name="dniCuenta"
                                                            label="CUIT/CUIL"
                                                        />
                                                    </FormControl>
                                                </Box>
                                                <Box component="div" sx={{ display: "flex", flexDirection: { md: "row", xs: "column" }, justifyContent: "flex-start", alignItems: "center" }}>
                                                    <FormControl sx={{ m: 1, width: { md: '48%', xs: "90%" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            type="text"
                                                            name="correoElectronico"
                                                            label="Correo Electrónico"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Box>
                                    }
                                    <Divider />
                                    <Box>
                                        <FormControl sx={{ m: 1, minWidth: "100%" }}>
                                            <CustomTextField
                                                Component={TextField}
                                                multiline={true}
                                                rows={4}
                                                type="text"
                                                name="observaciones"
                                                label="Observaciones"
                                            />
                                        </FormControl>
                                    </Box>
                                </TabPanel>
                            </FormProvider>
                        </TabContext>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ backgroundColor: theme.palette.background.default }}>
                    {/* <Box sx={{ m: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                        <Button
                            type="button"
                            onClick={handleSubmit(crearRelacionVinculacion)}
                            variant="contained"
                            sx={buttonSx}
                            disabled={resultado === 'LOADING'}
                        >
                            {resultado === 'LOADING' && (
                                <CircularProgress
                                    size={24}
                                    color="inherit"
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: -12,
                                        marginLeft: -12
                                    }}
                                />
                            )}
                            {boton ? "Agregar" : "Editar"}
                        </Button>
                    </Box> */}
                    <Box sx={{ mt: 3, mb: 2, position: 'relative' }}>
                        <Button
                            type="button"
                            onClick={handleSubmit(crearRelacionVinculacion)}
                            variant="contained"
                            disabled={resultado === 'LOADING'}
                        >
                            {boton ? "Agregar" : "Editar"}
                        </Button>
                        {resultado === 'LOADING' && (
                            <CircularProgress
                                size={27}
                                sx={{
                                    color: "#fff",
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Box>
                </DialogActions>
            </Dialog>
        </div >
    )
}

export default ModalRelaciones
