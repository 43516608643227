import React from "react"
import { Container } from "@mui/material"
import Login from "../Components/Login"
import fondo from "../img/fondoMisSGR.png"

export const InicioSesion = () => {
  return (
    <Container
      sx={{
        backgroundImage: `url(${fondo})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        minWidth:"100vw",
        minHeight:"100vh",
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent:"center"
      }}
    >
      <Login />
    </Container>
  );
};
