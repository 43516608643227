import { Paper, Typography } from "@mui/material"

export const COLUMNASCALIFICACION = (tema) => [
    {
        accessorKey: 'id',
    },
    {
        header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>CUIT</Typography>,
        accessorKey: 'new_cuitcalificadora',
        Cell: ({ cell }) => (
            <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
                {cell.getValue()}
            </Typography>
        ),
    },
    {
        header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>RAZÓN SOCIAL</Typography>,
        accessorKey: 'razon_social',
        Cell: ({ cell }) => (
            <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
                {cell.getValue()}
            </Typography>
        ),
    },
    // {
    //     header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>MAIL</Typography>,
    //     accessorKey: 'mail',
    //     Cell: ({ cell }) => (
    //         <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
    //             {cell.getValue()}
    //         </Typography>
    //     ),
    // },
    {
        header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>FECHA ÚLTIMA CALIFICACIÓN</Typography>,
        accessorKey: 'fechaUltima_calificacion',
        Cell: ({ cell }) => (
            <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
                {cell.getValue()}
            </Typography>
        ),
    },
    {
        header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>ÚLTIMA CALIFICACIÓN</Typography>,
        accessorKey: 'ultima_calificacion',
        Cell: ({ cell }) => (
            <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
                {cell.getValue()}
            </Typography>
        ),
    },
]
