import React, { useState, useEffect, useContext } from 'react'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Box, Grid, Tab, Paper, useTheme } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { obtenerTodasOperacionesSindicadas } from '../Redux/OperacionesSindicadas'
import Tabla from '../Components/Tabla'
import { COLUMNSOPERACIONES } from '../Columnas/ColumnasOperacionesSindicadas'
import { COLUMNSOPERACIONESADHERIDAS } from '../Columnas/ColumnasOperacionesSindicadas'
import { COLUMNSOPERACIONESRECHAZADAS } from '../Columnas/ColumnasOperacionesSindicadas'
import TokenContext from "../context/TokenContext";
import LoaderAnimado from '../Components/loaderAnimado/LoaderAnimado'

const TodasLasOperacionesSindicadas = ({ nameSGR, esPorSerie = false, idSerie }) => {
    const dispatch = useDispatch()
    const token = useContext(TokenContext)
    const theme = useTheme()
    const operacionesSindicadasSelector = useSelector(store => store.operacione.todasOperacionesSindicadas)
    const resultadoOperacionesSindicadasSelector = useSelector(store => store.operacione.resultadoTodasOperacionesSindicadas)
    const resultadoAdherirOperaciones = useSelector(store => store.operacione.resultadoAdherirOperaciones)
    const headerDataExp = ["Garantía", "Serie", "Estado", "Divisa", "Monto", "Porcentaje"]
    const [dataEXP, setDataEXP] = React.useState([])
    const [dataEXPXserie, setDataEXPXserie] = React.useState([])
    const [operacionesSindicadas, setOperacionesSindicadas] = useState([])
    const [value, setValue] = useState('0')
    const [log, setLog] = useState(true)
    const [idSGRLider, setIdSGRLider] = useState(JSON.parse(localStorage.getItem('Id SGRLider')) ? JSON.parse(localStorage.getItem('Id SGRLider')) : "")

    useEffect(() => {
        setIdSGRLider(JSON.parse(localStorage.getItem('Id SGRLider')))
    }, [])

    useEffect(() => {
        if (idSGRLider && token) {
            dispatch(obtenerTodasOperacionesSindicadas(token, idSGRLider))
            setLog(true)
        }
    }, [idSGRLider, token])

    useEffect(() => {
        if (operacionesSindicadasSelector?.length > 0 && resultadoOperacionesSindicadasSelector == true) {
            if (esPorSerie) {
                setearOPXserie(operacionesSindicadasSelector, idSerie)
            }
            setearOperacionesSindicadas(operacionesSindicadasSelector)
        }
    }, [resultadoOperacionesSindicadasSelector, operacionesSindicadasSelector, idSerie])

    useEffect(() => {
        if (resultadoAdherirOperaciones === "EXITO") {
            setTimeout(() => {
                dispatch(obtenerTodasOperacionesSindicadas(token, idSGRLider))
                setLog(true)
            }, 4500);
        }
    }, [resultadoAdherirOperaciones])

    const setearOperacionesSindicadas = (series) => {
        var operacionesEXP = []
        var operacionesT = []
        let operacionesUnicas = [...new Map(series.map(item => [item["new_operacionsindicadaid"], item])).values()]
        operacionesUnicas.forEach(item => {
            var operacion = {
                garantia: item["garantia.new_name"],
                serie: item["serie.new_name"],
                estado: item["statuscode"],
                estadoSerie: item["serie.statuscode"],
                // monto: item["new_importeenpesos@OData.Community.Display.V1.FormattedValue"],
                monto: item["new_importeenpesos"],
                porcentaje: item["new_porcentaje"],
                descripcionEstado: item["statuscode@OData.Community.Display.V1.FormattedValue"],
                divisa: item["_transactioncurrencyid_value@OData.Community.Display.V1.FormattedValue"],
                seriedeoperacinsindicadaid: item['serie.new_seriedeoperacinsindicadaid'],
                fechavencimientoadhesion: item['new_fechavencimientoadhesion@OData.Community.Display.V1.FormattedValue'],
                motivoderechazo: item['new_motivoderechazo@OData.Community.Display.V1.FormattedValue'],
                id: item['new_operacionsindicadaid'],
            }
            var operacionEXP = {
                id: item['new_operacionsindicadaid'],
                garantia: item["garantia.new_name"] ? item["garantia.new_name"] : "-",
                serie: item["serie.new_name"] ? item["serie.new_name"] : "-",
                estado: item["statuscode@OData.Community.Display.V1.FormattedValue"] ? item["statuscode@OData.Community.Display.V1.FormattedValue"] : "-",
                divisa: item["_transactioncurrencyid_value@OData.Community.Display.V1.FormattedValue"] ? item["_transactioncurrencyid_value@OData.Community.Display.V1.FormattedValue"] : "-",
                monto: item["new_importeenpesos"] ? item["new_importeenpesos"] : "-",
                porcentaje: item["new_porcentaje"] ? item["new_porcentaje"] : "-",
            }
            operacionesT.push(operacion)
            operacionesEXP.push(operacionEXP)
        })
        operacionesT.sort((a, b) => (a.garantia < b.garantia) ? 1 : ((b.garantia < a.garantia) ? -1 : 0))
        operacionesEXP.sort((a, b) => (a.garantia < b.garantia) ? 1 : ((b.garantia < a.garantia) ? -1 : 0))
        setOperacionesSindicadas(operacionesT)
        setDataEXP(operacionesEXP)
        setLog(false)
    }

    const setearOPXserie = (series, idserie) => {
        if (series?.length > 0) {
            let operacionesEXP = []
            let opUnicas = [...new Map(series.map(item => [item["new_operacionsindicadaid"], item])).values()]
            opUnicas?.filter(item => item['serie.new_seriedeoperacinsindicadaid'] === idserie)?.forEach(item => {
                var operacionEXP = {
                    id: item['new_operacionsindicadaid'],
                    garantia: item["garantia.new_name"] ? item["garantia.new_name"] : "-",
                    serie: item["serie.new_name"] ? item["serie.new_name"] : "-",
                    estado: item["statuscode@OData.Community.Display.V1.FormattedValue"] ? item["statuscode@OData.Community.Display.V1.FormattedValue"] : "-",
                    divisa: item["_transactioncurrencyid_value@OData.Community.Display.V1.FormattedValue"] ? item["_transactioncurrencyid_value@OData.Community.Display.V1.FormattedValue"] : "-",
                    monto: item["new_importeenpesos"] ? item["new_importeenpesos"] : "-",
                    porcentaje: item["new_porcentaje"] ? item["new_porcentaje"] : "-",
                }
                operacionesEXP.push(operacionEXP)
            })
            debugger
            setDataEXPXserie(operacionesEXP)
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3, py: 0 }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, mb: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="basic tabs example" flexContainer="true" scroller="true" variant="scrollable" indicator>
                        <Tab label="Operaciones Sindicadas Pendientes de Confirmación" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="0" />
                        <Tab label="Operaciones Sindicadas Rechazadas" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="1" />
                        <Tab label="Operaciones Sindicadas Adheridas" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="2" />
                    </TabList>
                </Box>
                <TabPanel value="0" sx={{ p: "0px" }}>
                    <Paper elevation={3}>
                        {log ? <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", p: 4 }}>
                            <LoaderAnimado />
                        </Box>
                            :
                            esPorSerie ?
                                <Tabla
                                    columnas={COLUMNSOPERACIONES(theme)}
                                    datos={operacionesSindicadas.filter(item => item.estado == 100000000 && item.seriedeoperacinsindicadaid == idSerie)}
                                    exportData={true}
                                    dataExp={dataEXPXserie.filter(item => item.estado == "Pendiente de Confirmación")}
                                    headerDataExp={headerDataExp}
                                    nombreArchivo="Operaciones Sindicadas Pendientes de Confirmación" />
                                :
                                <Tabla
                                    columnas={COLUMNSOPERACIONES(theme)}
                                    datos={operacionesSindicadas.filter(item => item.estado == 100000000)}
                                    exportData={true}
                                    dataExp={dataEXP.filter(item => item.estado == "Pendiente de Confirmación")}
                                    headerDataExp={headerDataExp}
                                    nombreArchivo="Operaciones Sindicadas Pendientes de Confirmación" />
                        }
                    </Paper>
                </TabPanel>
                <TabPanel value="1" sx={{ p: "0px" }}>
                    <Paper elevation={3}>
                        {log ? <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", p: 4 }}>
                            <LoaderAnimado />
                        </Box>
                            :
                            esPorSerie ?
                                <Tabla
                                    columnas={COLUMNSOPERACIONESRECHAZADAS(theme)}
                                    datos={operacionesSindicadas.filter(item => item.estado == 100000001 && item.seriedeoperacinsindicadaid == idSerie)}
                                    exportData={true}
                                    dataExp={dataEXPXserie.filter(item => item.estado == "Rechazada")}
                                    headerDataExp={headerDataExp}
                                    nombreArchivo="Operaciones Sindicadas Rechazadas" />
                                :
                                <Tabla
                                    columnas={COLUMNSOPERACIONESRECHAZADAS(theme)}
                                    datos={operacionesSindicadas.filter(item => item.estado == 100000001)}
                                    exportData={true}
                                    dataExp={dataEXP.filter(item => item.estado == "Rechazada")}
                                    headerDataExp={headerDataExp}
                                    nombreArchivo="Operaciones Sindicadas Rechazadas" />
                        }
                    </Paper>
                </TabPanel>
                <TabPanel value="2" sx={{ p: "0px" }}>
                    <Paper elevation={3}>
                        {log ? <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", p: 4 }}>
                            <LoaderAnimado />
                        </Box>
                            :
                            esPorSerie ?
                                <Tabla
                                    columnas={COLUMNSOPERACIONESADHERIDAS(theme)}
                                    datos={operacionesSindicadas.filter(item => item.estado == 1 && item.seriedeoperacinsindicadaid == idSerie)}
                                    exportData={true}
                                    dataExp={dataEXPXserie.filter(item => item.estado == "Adherida")}
                                    headerDataExp={headerDataExp}
                                    nombreArchivo="Operaciones Sindicadas Adheridas" />
                                :
                                <Tabla
                                    columnas={COLUMNSOPERACIONESADHERIDAS(theme)}
                                    datos={operacionesSindicadas.filter(item => item.estado == 1)}
                                    exportData={true}
                                    dataExp={dataEXP.filter(item => item.estado == "Adherida")}
                                    headerDataExp={headerDataExp}
                                    nombreArchivo="Operaciones Sindicadas Adheridas" />
                        }
                    </Paper>
                </TabPanel>
            </TabContext>
        </Box>
    )
}

export default TodasLasOperacionesSindicadas