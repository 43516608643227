import axios from 'axios'
import { Entidad, UrlApiDynamics,  UrlApi } from '../Keys'
//Const
const dataInicial = {
    loading: false,
    contactos: [],
    todosContactos:[],
    contacto: {}
}

//Types
const CONTACTOS_EXITO = 'CONTACTOS_EXITO'
const CONTACTO_EXITO = 'CONTACTO_EXITO'
const TODOS_CONTACTO_EXITO = 'TODOS_CONTACTO_EXITO'
const LOADING = "LOADING"
const ERROR = 'ERROR'

//Reducers
export default function contactosReducers(state = dataInicial, action) {
    switch (action.type) {
        case CONTACTOS_EXITO:
            return { ...state, contactos: action.payload }
        case TODOS_CONTACTO_EXITO:
            return { ...state, todosContactos: action.payload }
        case CONTACTO_EXITO:
            return { ...state, contacto: action.payload }
        default:
            return { ...dataInicial }
    }
}

//Actions

export const obtenerContactosPorID = (contactid) => async (dispatch) => {
    dispatch({
        type: LOADING
    })

    try {
        const response = await axios.get(`${UrlApiDynamics}Contacto?filter=contactid eq ${contactid}&cuit=${Entidad}`)
        dispatch({
            type: CONTACTO_EXITO,
            payload: response.data
        })
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}

export const obtenerTodosContactos= (token) => async (dispatch) => {
    dispatch({
        type: LOADING
    })

    try {
        var entidad = "contacts"
        var fetch = "<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='false'>"+
        "<entity name='contact'>"+
          "<attribute name='fullname' />"+
          "<attribute name='telephone1' />"+
          "<attribute name='contactid' />"+
          "<attribute name='emailaddress1' />"+
          "<attribute name='jobtitle' />"+
          "<attribute name='new_nrodedocumento' />"+
          "<attribute name='gendercode' />"+
          "<attribute name='birthdate' />"+
          "<attribute name='new_fechadealtabaja' />"+
          "<attribute name='familystatuscode' />"+
          "<attribute name='new_domiciliocompleto' />"+
          "<attribute name='new_cuitcuil' />"+
          "<attribute name='new_conyugetrabaja' />"+
          "<attribute name='lastname' />"+
          "<order attribute='fullname' descending='false' />"+
          "<filter type='and'>"+
            "<condition attribute='statecode' operator='eq' value='0' />"+
            "<condition attribute='statuscode' operator='eq' value='1' />"+
          "</filter>"+
        "</entity>"+
      "</fetch>";
        
      await axios.post(`${UrlApi}api/consultafetch`,
      {
          "entidad": entidad,
          "fetch": fetch
      },
      {
          headers: {
              "Authorization": `Bearer ${token}`
          }
      })
      .then((response) => {
          dispatch({
              type: TODOS_CONTACTO_EXITO,
              payload: response.data,
              loading: false
          })
      })
      .catch(err => {
          console.log(err)
          dispatch({
              type: ERROR,
              loading: false
          })
      })
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}
