import React, { useMemo } from 'react'
import MaterialReactTable from 'material-react-table';
import {
    Typography,
    Box,
    Button,
    IconButton,
    Tooltip,
} from '@mui/material';
//Import Material React Table Translations
import { MRT_Localization_ES } from 'material-react-table/locales/es';
import { grey } from '@mui/material/colors';
import { darken, useTheme, createTheme, ThemeProvider } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

const TablaLibradores = ({ columnas, lineas, titulo, handleOpen, acciones = true, indicadorid, sgrid, handleEdit }) => {
    const columns = useMemo(() => columnas, [])
    const [data, setData] = React.useState(lineas)
    const [pagination, setPagination] = React.useState({
        pageIndex: 0,
        pageSize: 3
    });

    React.useEffect(() => {
        if (lineas.length > 0) {
            setData(lineas)
        }
    }, [lineas])


    const abrirModalAgregar = () => {
        let id = ''
        let editar = false
        let tipo = ''

        switch (titulo) {
            case "Concentración de riesgo por librador (superior al 5%)":
                tipo = "librador"
                break;
            case "Atomización de cartera % Socio Participe/Tercero":
                tipo = "participes"
                break;
            case "Atomización % Socio Protector":
                tipo = "protectores"
                break;
            default:
                break;
        }

        handleOpen(id, editar, tipo)
    }

    const handleSaveRow = async ({ exitEditingMode, row, values }) => {
        var objetoModificado = data[row.index] = values
        const cuerpoPeticion = {
            "new_indicadormensualsocioylibradoresid": "",
            "new_indicadormensualsgr": indicadorid,
            "new_name": sgrid,
            "new_librador": "",
            "new_porcentajelibrador": 0,
            "new_socioparticipetercero": "",
            "new_porcentajesocioparticipetercero": 0,
            "new_socioprotector": "",
            "new_porcentajesocioprotector": 0,
            "new_fecha": "",
            ...objetoModificado, // keys dinámicas - Esto solo actualiza las keys que le envio
        };
        handleEdit(cuerpoPeticion)
        //send/receive api updates here
        setData([...data]);
        exitEditingMode(); //required to exit editing mode
    };

    return (
        <MaterialReactTable
            muiTablePaginationProps={{
                rowsPerPageOptions: [5, 10],
                showFirstLastPageButtons: false
            }}
            onPaginationChange={setPagination} //hoist pagination state to your state when it changes internally
            state={{ pagination }}
            columns={columns}
            data={data}

            muiTableBodyProps={{
                sx: (theme) => ({
                    '& tr:nth-of-type(odd)': {
                        backgroundColor: darken(theme.palette.background.default, 0.1),
                    },
                }),
            }}
            muiTablePaperProps={{
                // sx: {
                //     maxWidth: '1000px',
                //     m: 'auto',
                // },
                sx: (theme) => ({
                    borderRadius: '0.55rem',
                    border: darken(theme.palette.background.default, 0.1),
                    bgcolor: darken(theme.palette.background.default, 0.1),
                    // maxWidth: '1000px',
                    m: 'auto',
                }),
            }}
            enableFullScreenToggle={false}
            enableColumnFilterModes={true}
            enableColumnOrdering={true} //mueve columnas
            enableColumnActions={true} //aciones de columnas como filtro individual
            enableColumnFilters={true}
            enableSorting={true}
            enableColumnResizing
            columnResizeMode="onChange"
            // enableGrouping
            enablePinning
            enableRowActions={acciones}
            enableStickyHeader
            // enableRowSelection
            initialState={{ showColumnFilters: false, columnVisibility: { id: false }, density: 'compact', }}
            positionToolbarAlertBanner="bottom"
            //idiomas
            localization={MRT_Localization_ES}
            defaultColumn={{
                maxSize: 400,
                minSize: 40,
            }}
            editingMode="modal" //default
            enableEditing
            onEditingRowSave={handleSaveRow}

            renderTopToolbarCustomActions={() => (
                <>
                    <Button
                        style={{ backgroundColor: "#ad387f", color: "#FFFFFF" }}
                        onClick={() => abrirModalAgregar()}
                        variant="contained"
                    >
                        <PlaylistAddIcon />
                    </Button>
                    <Typography variant="subtitle" p="2px">{titulo}</Typography>
                </>
            )}
        />

    )
}

export default TablaLibradores