import Chip from '@mui/material/Chip';

export const COLUMNASDOCUMENTOSOPERACION = [
    {
        accessorKey: 'id',
    },
    {
        header: 'Documento',
        // accessorKey: '_new_documento_value',
        accessorKey: 'new_name',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Estado',
        accessorKey: 'statuscode',
        Cell: ({ cell }) => {
            switch (cell.getValue()) {
                case 100000000:
                    return <Chip label="Recibida" sx={{ bgcolor: '#388e3c' }} />
                case 1:
                    return <Chip label="Pendiente" sx={{ bgcolor: '#f57c00' }} />
                case 100000001:
                    return <Chip label="Aprobado" sx={{ bgcolor: '#0288d1' }} />
                default:
                    return ""
            }
        }
    },
    {
        header: 'Fecha de Vencimiento',
        accessorKey: 'new_fechadevencimiento',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
]