import { Paper, Typography } from "@mui/material"

export const COLUMNASINTEGRANTES = (tema) => [
    {
        accessorKey: 'id',
    },
    {
        header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>CUIT</Typography>,
        accessorKey: 'new_numerodedocumento',
        Cell: ({ cell }) => (
            <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
                {cell.getValue()}
            </Typography>
        ),
    },
    {
        header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>NOMBRE</Typography>,
        accessorKey: 'nombre',
        Cell: ({ cell }) => (
            <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
                {cell.getValue()}
            </Typography>
        ),
    },
    {
        header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>MAIL</Typography>,
        accessorKey: 'new_correoelectronico',
        Cell: ({ cell }) => (
            <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
                {cell.getValue()}
            </Typography>
        ),
    },
    {
        header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>CARGO</Typography>,
        accessorKey: 'cargo',
        Cell: ({ cell }) => (
            <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
                {cell.getValue()}
            </Typography>
        ),
    },
]