import { obtenerNotaADescargar } from '../../Redux/CarpetaDigital';
import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, Tooltip, Typography } from '@mui/material';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { LoadingButton } from '@mui/lab';
import ActualizarTemplate from '../modales/ActualizarTemplate';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { IconButton } from '@mui/material';

const DescargarNotaTemplate = (notaid) => {
    const dispatch = useDispatch()
    const token = useSelector(store => store.token.token)
    const [loading, setLoading] = React.useState(false)
    const [openModalTemplate, setOpenModalTemplate] = useState(false)

    const OpenModalTemplate = () => {
        setOpenModalTemplate(true)
    }

    function downloadBase64File(event) {
        event.preventDefault()
        if (notaid?.value != null && notaid?.value != undefined) {
            if (event?.currentTarget?.id === notaid.value?.annotationid) {
                setLoading(true)
                dispatch(obtenerNotaADescargar(notaid.value?.annotationid, token.token))
                    .then(data => {
                        if (data?.length > 0) {
                            let documentBody = data[0]['documentbody']
                            let filename = data[0]['filename']
                            let mimetype = data[0]['mimetype']
                            if (documentBody != null && filename != null && mimetype != null) {
                                const linkSource = `data:${mimetype};base64,${documentBody}`;
                                const downloadLink = document.createElement("a");
                                downloadLink.href = linkSource;
                                downloadLink.download = filename;
                                downloadLink.click();
                                setLoading(false)
                            }
                        }
                    })
                    .catch(error => {
                        setLoading(false)
                    })
            }
        }
    }

    return (
        <>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {
                    notaid?.value != null ?
                        <LoadingButton
                            id={notaid?.value?.annotationid}
                            sx={{ borderRadius: 5 }}
                            size="small"
                            onClick={(e) => downloadBase64File(e)}
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<CloudDownloadIcon />}
                            variant="contained"
                        >
                            <span>Descargar</span>
                        </LoadingButton> : '-'
                }
                {
                    (notaid?.value != null && notaid?.value.upload == true) ?
                        <Tooltip title={<Typography sx={{ color: '#fff' }}>Actualizar Template</Typography>}>
                            <IconButton
                                id="basic-button"
                                aria-haspopup="true"
                                onClick={OpenModalTemplate}
                            >
                                <CloudUploadIcon sx={{ fontSize: { xs: "1.2rem", md: "1.5rem" } }} />
                            </IconButton>
                        </Tooltip>
                        :
                        null
                }
            </Box>
            <ActualizarTemplate open={openModalTemplate}
                setOpen={setOpenModalTemplate}
                idGarantia={notaid?.value?.garantiaId}
                idAdjuntoGarantia={notaid?.value?.new_adjuntosid}
                idNota={notaid?.value?.annotationid}
                visibleEnPortal={notaid?.value?.visiblePortal}
            />
        </>
    )
}

export default DescargarNotaTemplate
