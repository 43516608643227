import axios from 'axios'
import { UrlApi, UrlApiDynamics, Entidad } from '../Keys'
import { toast } from 'react-toastify'
import moment from 'moment/moment'

//Const
const dataInicial = {
    loading: false,
    relaciones: [],
    CreacionDatos: '',
    InactivacionDatos: '',
    relacionIdSeleccionada: '',
    relacionesFetch: [],
    inactivar: '',
    loadingRelaciones: false,
    loadingRelacion: false,
    retrieveRelaciones: false
}

const ToastError = (msj) => {
    toast.error(msj, {
        theme: "dark",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};
const ToastSuccess = (msj) => {
    toast.success(msj, {
        theme: "dark",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })
}

//Types
const RELACIONES_EXITO = 'RELACIONES_EXITO'
const RELACIONES_FETCH_EXITO = 'RELACIONES_FETCH_EXITO'
const RELACION_SELECCIONADA = 'RELACION_SELECCIONADA'
const INACTIVAR_CUENTA = 'INACTIVAR_CUENTA'
const EDITAR_RELACION = 'EDITAR_RELACION'
const ELIMINAR_RELACION = 'ELIMINAR_RELACION'
const CREAR_CUENTA = 'CREAR_CUENTA'
const LOADING_RELACIONES = "LOADING_RELACIONES"
const ERROR = 'ERROR'
const LOADING_RELACION = 'LOADING_RELACION'
const ERROR_RELACION = 'ERROR_RELACION'

//Reducers
export default function relacionesReducers(state = dataInicial, action) {
    switch (action.type) {
        case RELACIONES_EXITO:
            return { ...state, relaciones: action.payload, loadingRelaciones: action.loadingRelaciones, retrieveRelaciones: action.retrieveRelaciones }
        case RELACIONES_FETCH_EXITO:
            return { ...state, relacionesFetch: action.payload, loadingRelaciones: action.loadingRelaciones, retrieveRelaciones: action.retrieveRelaciones }
        case RELACION_SELECCIONADA:
            return { ...state, relacionIdSeleccionada: action.relacionIdSeleccionada }
        case CREAR_CUENTA:
            return { ...state, CreacionDatos: action.CreacionDatos, loadingRelacion: action.loadingRelacion }
        case EDITAR_RELACION:
            return { ...state, edicionDatos: action.edicionDatos }
        case ELIMINAR_RELACION:
            return { ...state, inactivar: action.inactivar }
        case INACTIVAR_CUENTA:
            return { ...state, InactivacionDatos: action.InactivacionDatos }
        case LOADING_RELACIONES:
            return { ...state, loadingRelaciones: action.loadingRelaciones, retrieveRelaciones: action.retrieveRelaciones }
        case LOADING_RELACION:
            return { ...state, loadingRelacion: action.loadingRelacion, CreacionDatos: action.CreacionDatos }
        case ERROR_RELACION:
            return { ...state, loadingRelacion: action.loadingRelacion, CreacionDatos: action.CreacionDatos }
        case ERROR:
            return { ...state, CreacionDatos: action.CreacionDatos, loadingRelaciones: action.loadingRelaciones, retrieveRelaciones: action.retrieveRelaciones }
        default:
            return { ...dataInicial }
    }
}

//Actions

export const CrearRelacion = (token, socioIdSelector, personeria, cuitCuil, nombre, apellido, razonSocial, vinculadoCon, tipo, porcentajeParticipacion, relacion, porcentajeBeneficiario, cargo, observaciones,
    dni, fechaDeNacimiento, lugarDeNacimiento, correoElectronico, estadoCivil, nombreConyuge, nroDocumentoConyuge, contactoid, tipoDeCarga, relacionid, estado, personaPolitica, funcion, ente,
    relacionPersona, fechaAlta, fechaBaja, tipoDeDocumento, generoDelConyuge, conyugeTrabaja) => async (dispatch) => {
        dispatch({
            type: CREAR_CUENTA,
            CreacionDatos: 'LOADING'
        })

        console.log("estadoCivil", estadoCivil)

        try {

            let cuenta = null
            let contacto = null

            if (personeria === 'Contacto') {
                contacto = {
                    "contactid": socioIdSelector,
                    "firstname": nombre,
                    "lastname": apellido,
                    "new_cuitcuil": Number(cuitCuil),
                    "new_nrodedocumento": dni,
                    "emailaddress1": correoElectronico,
                    "birthdate": moment(fechaDeNacimiento).format("MM-DD-YYYY"),
                    "new_lugardenacimiento": lugarDeNacimiento,
                    "familystatuscode": Number(estadoCivil),
                    "spousesname": nombreConyuge
                }
            }
            if (personeria === 'Cuenta') {
                cuenta = {
                    "accountid": socioIdSelector,
                    "name": razonSocial,
                    "new_nmerodedocumento": cuitCuil,
                    "emailaddress1": correoElectronico
                }
            }

            await axios.post(`${UrlApi}api/socioparticipe/relaciondevinculacion`,
                {
                    "new_participacionaccionariaid": "",
                    "accountid": socioIdSelector,
                    "new_tipoderelacion": tipo.value,
                    "new_porcentajedeparticipacion": porcentajeParticipacion,
                    "new_observaciones": observaciones,
                    "new_porcentajebeneficiario": porcentajeBeneficiario,
                    "new_cargo": cargo,
                    "cuenta": cuenta,
                    "contacto": contacto
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }).then((response) => {
                    console.log("response", response)
                    dispatch({
                        type: CREAR_CUENTA,
                        payload: response.data,
                        CreacionDatos: 'EXITO'
                    })
                    setTimeout(() => {
                        dispatch({
                            type: CREAR_CUENTA,
                            CreacionDatos: ''
                        })
                    }, 2500)

                    ToastSuccess('Relación actualizada con exito!')

                }).catch((err) => {
                    console.log(err)
                    dispatch({
                        type: CREAR_CUENTA,
                        CreacionDatos: 'ERROR'
                    })

                    ToastError('La relación no se pudo actualizar!')

                })
        } catch (error) {
            dispatch({
                type: CREAR_CUENTA,
                CreacionDatos: 'ERROR'
            })
            ToastError('La relación no se pudo actualizar!')
        }

    }

export const editarRelacion = (token, socioIdSelector, personeria, cuitCuil, nombre, apellido, razonSocial, vinculadoCon, tipo, porcentajeParticipacion, relacion, porcentajeBeneficiario, cargo, observaciones,
    dni, fechaDeNacimiento, lugarDeNacimiento, correoElectronico, estadoCivil, nombreConyuge, nroDocumentoConyuge, contactid, accountid, tipoDeCarga, relacionid, estado, personaPolitica, funcion, ente,
    relacionPersona, fechaAlta, fechaBaja, tipoDeDocumento, generoDelConyuge, conyugeTrabaja) => async (dispatch) => {
        dispatch({
            type: CREAR_CUENTA,
            CreacionDatos: 'LOADING'
        })

        try {

            let cuenta = null
            let contacto = null

            if (personeria === 'Contacto') {
                contacto = {
                    "contactid": contactid,
                    "firstname": nombre,
                    "lastname": apellido,
                    "new_cuitcuil": Number(cuitCuil),
                    "new_nrodedocumento": dni,
                    "emailaddress1": correoElectronico,
                    "birthdate": moment(fechaDeNacimiento).format("MM-DD-YYYY"),
                    "new_lugardenacimiento": lugarDeNacimiento,
                    "familystatuscode": Number(estadoCivil),
                    "spousesname": nombreConyuge
                }
            }
            if (personeria === 'Cuenta') {
                cuenta = {
                    "accountid": accountid,
                    "name": razonSocial,
                    "new_nmerodedocumento": cuitCuil,
                    "emailaddress1": correoElectronico
                }
            }

            await axios.put(`${UrlApi}api/socioparticipe/relaciondevinculacion`,
                {
                    "new_participacionaccionariaid": "",
                    "accountid": socioIdSelector,
                    "new_tipoderelacion": tipo.value,
                    "new_porcentajedeparticipacion": porcentajeParticipacion,
                    "new_observaciones": observaciones,
                    "new_porcentajebeneficiario": porcentajeBeneficiario,
                    "new_cargo": cargo,
                    "cuenta": cuenta,
                    "contacto": contacto
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }).then((response) => {
                    console.log("response", response)
                    dispatch({
                        type: CREAR_CUENTA,
                        payload: response.data,
                        CreacionDatos: 'EXITO'
                    })
                    setTimeout(() => {
                        dispatch({
                            type: CREAR_CUENTA,
                            CreacionDatos: ''
                        })
                    }, 2500)

                    ToastSuccess('Relación editada con éxito 🤝!')

                }).catch((err) => {
                    console.log(err)
                    dispatch({
                        type: CREAR_CUENTA,
                        CreacionDatos: 'ERROR'
                    })

                    ToastError('La relación no se pudo editadar!')

                })
        } catch (error) {
            dispatch({
                type: CREAR_CUENTA,
                CreacionDatos: 'ERROR'
            })

            ToastError('La relación no se pudo editadar!')
        }

    }

export const obtenerRelacionSeleccionada = (id) => async (dispatch) => {
    if (id !== undefined) {
        dispatch({
            type: RELACION_SELECCIONADA,
            relacionIdSeleccionada: id
        })
    }
}

export const obtenerRelacionesFETCH = (accountid, token) => async (dispatch) => {
    dispatch({
        type: LOADING_RELACIONES,
        loadingRelaciones: true,
        retrieveRelaciones: false
    })

    var entidad = "new_participacionaccionarias"
    var fetch = "<fetch mapping='logical'>" +
        "<entity name='new_participacionaccionaria'>" +
        "<attribute name='new_participacionaccionariaid'/>" +
        "<attribute name='new_cuentacontactovinculado'/> " +
        "<attribute name='new_tipoderelacion'/> " +
        "<attribute name='statecode'/> " +
        "<filter type='and'>" +
        "<condition attribute='new_cuentaid' operator='eq' value='" + accountid + "' />" +
        "</filter>" +
        "<link-entity name='contact' from='contactid' to='new_cuentacontactovinculado' link-type='outer' alias='ab'>" +
        "<attribute name='new_personaexpuestapoliticamente'/>" +
        "<attribute name='new_cargofuncinjerarquayenteendondetrabaja'/>" +
        "<attribute name='contactid'/> " +
        // "<attribute name='new_fechaultimavalidacionidentidadrenaper'/>" +
        // "<attribute name='new_resultadoultimavalidacionidentidadrenaper'/>" +
        "<attribute name='new_entedondetrabaja'/> " +
        "<attribute name='new_relacinconlapersonaexpuestapolticamente'/>" +
        "<attribute name='new_fechadealtabaja'/> " +
        "<attribute name='new_fechadebaja'/> " +
        "<attribute name='new_tienedomiciliofiscalenelexterior'/>" +
        "<attribute name='new_tienedomicilioenusa'/> " +
        "<attribute name='statuscode'/>" +
        "<attribute name='address3_stateorprovince'/> " +
        "<attribute name='address1_stateorprovince'/>" +
        "<attribute name='address3_country'/> " +
        "<attribute name='address1_country'/>" +
        "<attribute name='new_nrodedocumentoconyuge'/> " +
        "<attribute name='new_nrodedocumento'/>" +
        "<attribute name='firstname'/> " +
        "<attribute name='new_nit'/>" +
        "<attribute name='birthdate'/> " +
        "<attribute name='familystatuscode'/>" +
        "<attribute name='new_cuitcuil'/> " +
        "<attribute name='emailaddress1'/>" +
        "<attribute name='lastname'/> " +
        "<attribute name='address3_line1'/>" +
        "<attribute name='address3_postalcode'/> " +
        "<attribute name='address3_city'/>" +
        "<attribute name='address1_line1'/>" +
        "<attribute name='address1_postalcode'/> " +
        "<attribute name='address1_city'/>" +
        "<attribute name='new_einss'/> " +
        "<attribute name='new_tipodedocumentoconyuge'/> " +
        "<attribute name='new_generodelconyuge'/>" +
        "<attribute name='new_conyugetrabaja'/> " +
        "<attribute name='spousesname'/> " +
        "<attribute name='new_tiposujetoobligado'/> " +
        "<attribute name='new_lugardenacimiento'/> " +
        "<attribute name='new_paisdeorgen'/> " +
        // "<attribute name='new_pais'/> " +
        "<attribute name='new_profesionoficioactividad'/> " +
        "<attribute name='telephone1'/> " +
        "<attribute name='new_correoelectrnicopararecibirestadodecuenta'/> " +
        // "<attribute name='new_paisresidenciafiscalenelexterior'/> " +
        "</link-entity>" +
        "<link-entity name='account' from='accountid' to='new_cuentacontactovinculado' link-type='outer' alias='aa'>" +
        "<attribute name='name'/> " +
        "<attribute name='emailaddress1'/> " +
        "<attribute name='new_nmerodedocumento'/> " +
        "<attribute name='emailaddress1'/> " +
        "</link-entity>" +
        "</entity>" +
        "</fetch>";

    try {
        await axios.post(`${UrlApi}api/consultafetch`,
            {
                "entidad": entidad,
                "fetch": fetch
            },
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            .then((response) => {
                dispatch({
                    type: RELACIONES_FETCH_EXITO,
                    payload: response.data,
                    loadingRelaciones: false,
                    retrieveRelaciones: true
                })
            })
            .catch(err => {
                console.log(err)
            })
    }
    catch (error) {
        console.log(error)
        dispatch({
            type: ERROR,
            loadingRelaciones: false,
            retrieveRelaciones: false
        })
    }
}

export const crearRelacionVinculacion = (objeto, accountid, token) => async (dispatch) => {
    dispatch({
        type: LOADING_RELACION,
        loadingRelacion: true,
        CreacionDatos: 'LOADING',
    })

    try {
        const { tipo, tipoRelacion, razonSocial, Nombre, Apellido, CUITCUIL, DNI, fecha, lugarNacimiento, correo, correo2,
            ProfesionOficioActividad, telefonoLaboral, estadoCivil, NombreConyuge, tipoDeDocumento, nrodoConyuge, generoConyuge,
            conyugeTrabaja, cargo, porcentaje, porcentajeParticipacion, Observaciones } = objeto
        debugger
        var cuenta = null
        var contacto = null
        var porcentajeParticipacionNumero = porcentajeParticipacion ? parseInt(porcentajeParticipacion) : 0;
        var tipoDeRelacion = tipoRelacion ? parseInt(tipoRelacion) : null
        var cuitCuil = CUITCUIL ? CUITCUIL.toString() : ""
        debugger
        if (tipo === 'Humana') {
            contacto = {
                "contactid": accountid,
                "firstname": Nombre,
                "lastname": Apellido,
                "new_cuitcuil": CUITCUIL,
                "new_nrodedocumento": DNI,
                "emailaddress1": correo,
                "birthdate": moment(fecha).format("DD-MM-YYYY"),
                "new_lugardenacimiento": lugarNacimiento,
                // "familystatuscode": estadoCivil,
                // "spousesname": NombreConyuge
            }
        } else {
            cuenta = {
                "accountid": accountid,
                "name": razonSocial,
                "new_nmerodedocumento": cuitCuil,
                "emailaddress1": correo
            }
        }
        const crearVinculacion = () => new Promise(async (resolve, reject) => {
            debugger
            await axios.post(`${UrlApi}api/socioparticipe/relaciondevinculacion`,
                {
                    "new_participacionaccionariaid": "",
                    "accountid": accountid,
                    "new_tipoderelacion": tipoDeRelacion,
                    "new_porcentajedeparticipacion": porcentajeParticipacionNumero,
                    "new_observaciones": Observaciones,
                    "new_porcentajebeneficiario": porcentaje,
                    "new_cargo": cargo,
                    "cuenta": cuenta,
                    "contacto": contacto
                },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }
            )
                .then((response) => {
                    dispatch({
                        type: CREAR_CUENTA,
                        CreacionDatos: 'EXITO',
                        loadingRelacion: false
                    })
                    resolve(response.data)
                })
                .catch(err => {
                    debugger
                    reject(err)
                    console.log(err)
                })

        })

        const response = await toast.promise(
            crearVinculacion,
            {
                pending: 'Procesando...',
                success: 'Relación cargada con éxito 🤝',
                error: {
                    render({ data }) {
                        dispatch({
                            type: ERROR_RELACION,
                            CreacionDatos: 'ERROR',
                            loadingRelacion: false
                        })
                        return `${data}`
                    }
                }
            },
            {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
        )
    } catch (error) {
        dispatch({
            type: ERROR,
            CreacionDatos: 'ERROR'
        })
    }
}

export const editarRelacionVinculacion = (objeto, cuentaid, id, token) => async (dispatch) => {
    dispatch({
        type: LOADING_RELACION,
        loadingRelacion: true,
        CreacionDatos: 'LOADING',
    })
    try {
        const { tipo, tipoRelacion, razonSocial, Nombre, Apellido, CUITCUIL, DNI, fecha, lugarNacimiento, correo, correo2,
            ProfesionOficioActividad, telefonoLaboral, estadoCivil, NombreConyuge, tipoDeDocumento, nrodoConyuge, generoConyuge,
            conyugeTrabaja, cargo, porcentaje, porcentajeParticipacion, Observaciones, contactid, accountid } = objeto
        debugger
        var cuenta = null
        var contacto = null

        var fechaNacimiento = moment(fecha).format("YYYY-MM-DD")
        var cuitCuil = CUITCUIL ? CUITCUIL.toString() : ""

        if (tipo === 'Humana') {
            contacto = {
                "contactid": contactid,
                "firstname": Nombre,
                "lastname": Apellido,
                "new_cuitcuil": cuitCuil,
                "new_nrodedocumento": DNI,
                "emailaddress1": correo,
                "birthdate": fechaNacimiento,
                "new_lugardenacimiento": lugarNacimiento,
                "familystatuscode": estadoCivil,
                "spousesname": NombreConyuge,
                "new_profesionoficioactividad": ProfesionOficioActividad,
                "new_correoelectrnicopararecibirestadodecuenta": correo2,
                "telephone1": telefonoLaboral
            }
        } else {
            cuenta = {
                "accountid": accountid,
                "name": razonSocial,
                "new_nmerodedocumento": cuitCuil,
                "emailaddress1": correo,
                // "new_tipodedocumentoid": tipoDeDocumento != undefined ? tipoDeDocumento.value : null
            }
        }

        var tipoDeRelacion = parseFloat(tipoRelacion)

        const editVinculacion = () => new Promise(async (resolve, reject) => {
            debugger
            await axios.put(`https://hw365api.azurewebsites.net/api/socioparticipe/relaciondevinculacion`,
                {
                    "new_participacionaccionariaid": id,
                    "accountid": cuentaid,
                    "new_tipoderelacion": tipoDeRelacion,
                    "new_porcentajedeparticipacion": parseFloat(porcentajeParticipacion),
                    "new_observaciones": Observaciones,
                    "new_porcentajebeneficiario": porcentaje,
                    "new_cargo": cargo,
                    "cuenta": cuenta,
                    "contacto": contacto
                },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }
            )
                .then((response) => {
                    debugger
                    dispatch({
                        type: CREAR_CUENTA,
                        CreacionDatos: 'EXITO',
                        loadingRelacion: false
                    })
                    resolve(response.data)
                })
                .catch(err => {
                    debugger
                    reject(err)
                    console.log(err)
                })

        })

        const response = await toast.promise(
            editVinculacion,
            {
                pending: 'Procesando...',
                success: 'Relación editada con éxito!',
                error: {
                    render({ data }) {
                        dispatch({
                            type: ERROR_RELACION,
                            CreacionDatos: 'ERROR',
                            loadingRelacion: false
                        })
                        return `${data}`
                    }
                }
            },
            {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
        )
    } catch (error) {
        debugger
        dispatch({
            type: ERROR_RELACION,
            CreacionDatos: 'ERROR',
            loadingRelacion: false
        })
    }
}

export const obtenerRelaciones = (accountid, token) => async (dispatch) => {
    dispatch({
        type: LOADING_RELACIONES,
        loadingRelaciones: true
    })
    var entidad = "new_participacionaccionarias"
    var fetch = "<fetch mapping='logical' distinct='false'>" +
        "<entity name='new_participacionaccionaria'>" +
        "<attribute name='new_name' />" +
        "<attribute name='new_participacionaccionariaid'/>" +
        "<attribute name='new_cuentacontactovinculado'/> " +
        "<attribute name='new_tipoderelacion'/> " +
        "<attribute name='statecode'/> " +
        "<attribute name='new_porcentajedeparticipacion'/> " +
        "<attribute name='new_porcentajebeneficiario'/> " +
        "<attribute name='new_cargo' />" +
        "<filter type='and'>" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "<condition attribute='new_cuentaid' operator='eq' value='" + accountid + "' />" +
        "</filter>" +
        "<link-entity name='contact' from='contactid' to='new_cuentacontactovinculado' link-type='outer' alias='ab'>" +
        "<attribute name='new_personaexpuestapoliticamente'/>" +
        "<attribute name='new_cargofuncinjerarquayenteendondetrabaja'/>" +
        "<attribute name='contactid'/> " +
        // "<attribute name='new_fechaultimavalidacionidentidadrenaper'/>" +
        // "<attribute name='new_resultadoultimavalidacionidentidadrenaper'/>" +
        "<attribute name='new_entedondetrabaja'/> " +
        "<attribute name='new_relacinconlapersonaexpuestapolticamente'/>" +
        "<attribute name='new_fechadealtabaja'/> " +
        "<attribute name='new_fechadebaja'/> " +
        "<attribute name='new_tienedomiciliofiscalenelexterior'/>" +
        "<attribute name='new_tienedomicilioenusa'/> " +
        "<attribute name='statuscode'/>" +
        "<attribute name='address3_stateorprovince'/> " +
        "<attribute name='address1_stateorprovince'/>" +
        "<attribute name='address3_country'/> " +
        "<attribute name='address1_country'/>" +
        "<attribute name='new_nrodedocumentoconyuge'/> " +
        "<attribute name='new_nrodedocumento'/>" +
        "<attribute name='firstname'/> " +
        "<attribute name='new_nit'/>" +
        "<attribute name='birthdate'/> " +
        "<attribute name='familystatuscode'/>" +
        "<attribute name='new_cuitcuil'/> " +
        "<attribute name='emailaddress1'/>" +
        "<attribute name='lastname'/> " +
        "<attribute name='address3_line1'/>" +
        "<attribute name='address3_postalcode'/> " +
        "<attribute name='address3_city'/>" +
        "<attribute name='address1_line1'/>" +
        "<attribute name='address1_postalcode'/> " +
        "<attribute name='address1_city'/>" +
        "<attribute name='new_einss'/> " +
        "<attribute name='new_tipodedocumentoconyuge'/> " +
        "<attribute name='new_generodelconyuge'/>" +
        "<attribute name='new_conyugetrabaja'/> " +
        "<attribute name='spousesname'/> " +
        "<attribute name='new_tiposujetoobligado'/> " +
        "<attribute name='new_lugardenacimiento'/> " +
        "<attribute name='new_paisdeorgen'/> " +
        // "<attribute name='new_pais'/> " +
        "<attribute name='new_profesionoficioactividad'/> " +
        "<attribute name='telephone1'/> " +
        "<attribute name='new_correoelectrnicopararecibirestadodecuenta'/> " +
        // "<attribute name='new_paisresidenciafiscalenelexterior'/> " +
        "</link-entity>" +
        "<link-entity name='account' from='accountid' to='new_cuentacontactovinculado' link-type='outer' alias='aa'>" +
        "<attribute name='name'/> " +
        "<attribute name='emailaddress1'/> " +
        "<attribute name='new_nmerodedocumento'/> " +
        "<attribute name='emailaddress1'/> " +
        "<attribute name='new_personeria'/> " +
        "</link-entity>" +
        "</entity>" +
        "</fetch>";

    try {
        await axios.post(`${UrlApi}api/consultafetch`,
            {
                "entidad": entidad,
                "fetch": fetch
            },
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            .then((response) => {
                dispatch({
                    type: RELACIONES_EXITO,
                    payload: response.data,
                    loadingRelaciones: false
                })
            })
            .catch(err => {
                console.log(err)
            })
    }
    catch (error) {
        dispatch({
            type: ERROR,
            loadingRelaciones: false
        })
    }
}

export const eliminarRelacionVinculacion = (objeto, cuentaid, id, token) => async (dispatch) => {
    try {
        const { id } = objeto



        const deleteVinculacion = () => new Promise(async (resolve, reject) => {

            await axios.delete(`${UrlApi}api/socioparticipe/relaciondevinculacion?new_participacionaccionariaid=${id}`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }
            )
                .then((response) => {
                    console.log(response)
                    dispatch({
                        type: ELIMINAR_RELACION,
                        inactivar: 'EXITO'
                    })
                    resolve(response.data)
                })
                .catch(err => {
                    reject(err)
                    console.log(err)
                })

        })

        const response = await toast.promise(
            deleteVinculacion,
            {
                pending: 'Procesando...',
                success: 'Relación eliminada con éxito!',
                error: {
                    render({ data }) {
                        return `${data}`
                    }
                }
            },
            {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
        )
    } catch (error) {
        dispatch({
            type: ERROR,
            inactivar: 'ERROR'
        })
    }
}