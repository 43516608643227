import React, { useContext } from 'react'
import TableCRUD from './TableCRUD';
import { useSelector, useDispatch } from 'react-redux';
import { COLUMNASSOCXBOLSA } from '../Columnas/ColumnasSociedadXbolsa';
import ModalSociedadBolsa from './modales/ModalSociedadBolsa';
import { inactivarSociedadBolsa, obtenerSociedadesXsocio } from '../Redux/Cuenta';
import TokenContext from '../context/TokenContext'
import { Box, Grid, Paper } from '@mui/material';
import LoaderAnimado from './loaderAnimado/LoaderAnimado';

const SociedadesDeBolsa = () => {
    const dispatch = useDispatch()
    const token = useContext(TokenContext)

    const accountid = useSelector(store => store.usuarios.accountid)
    const sociedadesXsocioSelector = useSelector(store => store.cuenta.sociedadXsocio)
    const [sociedadXbolsa, setSociedadXbolsa] = React.useState([])
    const [llamadaSociedad, setLlamadaSociedad] = React.useState(false)
    const [columnaSociedadBolsa, setColumnaSociedadBolsa] = React.useState(COLUMNASSOCXBOLSA)
    const loadingSociedades = useSelector(store => store.cuenta.loadingSociedades)
    const [abrirModal, setAbrirModal] = React.useState(false)
    const [sociedadBolsaId, setSociedadBolsaId] = React.useState('')
    const [valoresAeditar, setValoresAeditar] = React.useState()
    const [modo, setModo] = React.useState('')
    const [loadingData, setLoadingData] = React.useState(false)
    const resultado = useSelector(store => store.cuenta.crearCuentaSociedad)
    const resultadoModificacion = useSelector(store => store.cuenta.modificarSociedadBolsa)
    const resultadoInactivacion = useSelector(store => store.cuenta.inactivarCuentaSocioMensaje)

    React.useEffect(() => {
        setLoadingData(loadingSociedades)
    }, [loadingSociedades])

    React.useEffect(() => {
        if (resultado !== undefined) {
            if (resultado !== '' && resultado == "EXITO") {
                obtenerSociedadXbolsa()
                setTimeout(() => {
                    cerrarModal()
                }, 1000)
            }
        }
    }, [resultado])

    React.useEffect(() => {
        if (resultadoModificacion !== undefined) {
            if (resultadoModificacion !== '' && resultadoModificacion == "EXITO") {
                obtenerSociedadXbolsa()
                setTimeout(() => {
                    cerrarModal()
                }, 1000)
            }
        }
    }, [resultadoModificacion])

    React.useEffect(() => {
        if (resultadoInactivacion !== undefined) {
            debugger
            if (resultadoInactivacion !== '' && resultadoInactivacion == "EXITO") {
                obtenerSociedadXbolsa()
                setTimeout(() => {
                    cerrarModal()
                }, 1000)
            } else if (resultadoInactivacion !== '' && resultadoInactivacion == "ERROR") {

            }
        }
    }, [resultadoInactivacion])

    const modalEditarSociedadBolsa = (id, obj, editar) => {
        if (editar === true) {
            const { _new_sociedaddebolsa_value, _new_sociedaddebolsa_value_id, new_cuentacomitente } = obj

            const defaultValues = {
                sociedades: { label: _new_sociedaddebolsa_value, value: _new_sociedaddebolsa_value_id },
                cuentaComitente: new_cuentacomitente,
            };
            setModo('editar')
            setSociedadBolsaId(id)
            setValoresAeditar(defaultValues)
        } else {
            setModo('agregar')
        }

        setAbrirModal(true)
    }

    const cerrarModal = () => {
        setAbrirModal(false)
        setModo('')
    }

    const obtenerSociedadXbolsa = () => {
        dispatch(obtenerSociedadesXsocio(accountid, token))
    }

    React.useEffect(() => {
        if (sociedadesXsocioSelector.length > 0 && llamadaSociedad === true) {
            var socXsoc = []
            sociedadesXsocioSelector.filter(item => item.statecode == '0').forEach(item => {
                var sociedad = {
                    new_cuentacomitente: item.new_cuentacomitente,
                    id: item.new_sociedaddebolsaporsocioid,
                    statuscode: item.statuscode,
                    _new_sociedaddebolsa_value: item["_new_sociedaddebolsa_value@OData.Community.Display.V1.FormattedValue"],
                    _new_sociedaddebolsa_value_id: item._new_sociedaddebolsa_value,
                    _new_socio_value: item["_new_socio_value@OData.Community.Display.V1.FormattedValue"],
                    _new_socio_value_id: item._new_socio_value,
                    statecode: item.statecode
                }
                socXsoc.push(sociedad)

            })
            setSociedadXbolsa(socXsoc)
            if (llamadaSociedad == false) {
                setLlamadaSociedad(true)
            }
        } else if (token != undefined && token != '' && llamadaSociedad === false) {
            obtenerSociedadXbolsa()
            setLlamadaSociedad(true)
        } else if (sociedadesXsocioSelector.length == 0 && llamadaSociedad === true && resultadoInactivacion === "EXITO") {
            setSociedadXbolsa([])
        }
    }, [sociedadesXsocioSelector, token])

    const funcionEliminarSociedad = (objeto) => {
        const { id, new_cuentacomitente, _new_sociedaddebolsa_value_id, _new_socio_value_id } = objeto
        if (token !== '') {
            dispatch(inactivarSociedadBolsa(id, token))
        }
    }

    const dispatchearAccionSociedades = () => {
        dispatch(obtenerSociedadesXsocio(accountid, token))
    }

    return (
        <Grid container spacing={4} sx={{ pt: 2 }}>
            <Grid item xs={12}>
                <Paper elevation={4} >
                    {
                        loadingData ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    py: 2
                                }}>
                                <LoaderAnimado />
                            </Box>
                        )
                            : !loadingData ? (
                                <TableCRUD
                                    lineas={sociedadXbolsa}
                                    columnas={columnaSociedadBolsa}
                                    handleOpen={modalEditarSociedadBolsa}
                                    funcionEliminar={funcionEliminarSociedad}
                                    acciones={true}
                                    accionAgregar={true}
                                    edicionRegistro={true}
                                />
                            ) : null
                        // : !loadingData && sociedadXbolsa.length > 0 ? (
                        //     <TableCRUD
                        //         lineas={sociedadXbolsa}
                        //         columnas={columnaSociedadBolsa}
                        //         handleOpen={modalEditarSociedadBolsa}
                        //         funcionEliminar={funcionEliminarSociedad}
                        //     />
                        // ) : !loadingData && sociedadesXsocioSelector.length == 0 && llamadaSociedad == true ?
                        //     <TableCRUD
                        //         lineas={[]}
                        //         columnas={columnaSociedadBolsa}
                        //     /> : null
                    }

                </Paper>
            </Grid>
            <ModalSociedadBolsa
                open={abrirModal}
                close={cerrarModal}
                modo={modo}
                id={sociedadBolsaId}
                objeto={valoresAeditar}
            />
        </Grid>
    )
}

export default SociedadesDeBolsa