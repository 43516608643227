import React, { useContext } from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Box, Typography, useMediaQuery, useTheme, Divider, Grid, Card, CardContent, TextField, Container, FormControl } from "@mui/material"
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FlexBetween from '../FlexBetween';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { darken, createTheme, ThemeProvider } from '@mui/material';
import LockIcon from '@mui/icons-material/Lock';
import { FormProvider, useForm } from "react-hook-form"
import CustomTextField from '../CustomTextField';
import TablaLibradores from '../TablaLibradores';
import { COLUMNSLIB } from '../../Columnas/ColumnasLibradores';
import { COLUMNSPRO, COLUMNSPYT } from '../../Columnas/ColumnasAtomizacion';
import ModalSociosYlibradores from './ModalSociosYlibradores';
import { cargarIndicador, modificarIndicador, modificarIndicadorsyl, obtenerIndicadoresSgr } from '../../Redux/Indicadores';
import TokenContext from '../../context/TokenContext';
import { useDispatch, useSelector } from 'react-redux';
import CustomCurrencyField from '../CustomCurrencyField';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { es } from 'date-fns/locale'
import { Controller } from 'react-hook-form';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/es';
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

const ModalIndicadores = ({ open, handleClose, indicadores, libradores, participesYterceros, protectores, modo, indicadorid, sgrid }) => {
    const theme = useTheme();
    const token = useContext(TokenContext)
    const dispatch = useDispatch()
    const [value, setValue] = React.useState("1");
    const [columnasLibradores, setColumnasLibradores] = React.useState(COLUMNSLIB)
    const [columnasParticipe, setColumnasParticipe] = React.useState(COLUMNSPYT)
    const [columnasProtector, setColumnasProtector] = React.useState(COLUMNSPRO)

    //##### MODAL #####
    const [abrir, setAbrir] = React.useState(false)
    const [tablaActiva, setTablaActiva] = React.useState('') //con esto le indico a que modal de la tabla pertenece
    const [crear, setCrear] = React.useState(false)
    const [idTabla, setIdTabla] = React.useState('')
    const [objeto, setObjeto] = React.useState()

    const defaultValues = {
        new_fechahasta: null,
        new_saldonetodegarantiasvigentes: 0,
        new_solvencia: 0,
        new_fondoderiesgointegrado: 0,
        new_fondoderiesgodisponible: 0,
        new_fondoderiesgocontingente: 0,
        new_fondoderiesgoavalordemercado: 0,
        new_porcentajeriesgopropio: 0,
        new_porcentajeriesgoterceros: 0,
        new_porcentajeprenda: 0,
        new_porcentajehipoteca: 0,
        new_porcentajefianza: 0,
        new_porcentajeotras: 0,
        new_entidadesfinancierascnv: 0,
        new_garantiascomercialescnv: 0,
        new_garantastecnicascnv: 0,
        new_mercadodecapitalescnv: 0,
        new_chequedepagodiferidocnv: 0,
        new_pagarbursatilcnv: 0,
        new_valoresdecortoplazocnv: 0,
        new_obligacionesnegociablescnv: 0,
        new_garantasvigentesrvenpymesensituacion1: 0,
        new_garantasvigentesrvenpymesensituacion2: 0,
        new_garantasvigentesrvenpymesensituacion3: 0,
        new_garantasvigentesrvenpymesensituacion4: 0,
        new_garantasvigentesrvenpymesensituacion5: 0
    }

    const validacionSchema = yup.object().shape({
        new_fechahasta: yup.string().required('La fecha es requerida'),
    });

    const methods = useForm({
        shouldUnregister: false,
        defaultValues,
        resolver: yupResolver(validacionSchema),
        mode: "onChange"
    });

    const { handleSubmit } = methods;

    React.useEffect(() => {
        if (modo === "Edit") {
            methods.reset(indicadores);
        } else {
            methods.reset(defaultValues)
        }
    }, [modo]);

    const onSubmitHandler = (data) => {
        if (modo === "New") {
            dispatch((dispatch) => {
                dispatch(cargarIndicador(data, sgrid, token)).then(() => {
                    dispatch(obtenerIndicadoresSgr(sgrid, token))
                    handleClose()
                });
            });
        } else {
            dispatch((dispatch) => {
                dispatch(modificarIndicador(data, indicadorid, sgrid, token)).then(() => {
                    dispatch(obtenerIndicadoresSgr(sgrid, token))
                });
            });
        }
    };

    //tabulacion
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleOpenModalTable = (id, editar, tipo, obj) => {
        if (editar === true) {
            setIdTabla(id)
            setAbrir(true)
            setTablaActiva(tipo);
            setCrear(false)
            setObjeto(obj)
        } else {
            setAbrir(true)
            setTablaActiva(tipo);
            setCrear(true)
        }
    }

    const handleCloseModalTable = () => {
        setAbrir(false)
        setTablaActiva('')
    }

    const onSubmitEdit = (objeto) => {
        dispatch((dispatch) => {
            dispatch(modificarIndicadorsyl(objeto, token)).then(() => {
            });
        });
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                // maxWidth={'xl'}
                fullScreen
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <FormProvider {...methods} sx={{ backgroundColor: theme.palette.background.common }}>
                    <TabContext value={value} sx={{ backgroundColor: theme.palette.background.common }} >
                        <TabList
                            sx={{ backgroundColor: theme.palette.background.common }}
                            onChange={handleChange}
                            variant="scrollable"
                            scrollButtons="auto"
                            aria-label="scrollable auto tabs example"
                            allowScrollButtonsMobile
                        // textColor="primary"
                        // indicatorColor="primary"
                        >
                            <Tab sx={{ color: theme.palette.primary.main }} label="Fuente SGR/FONDOS" value="1" />
                            {modo === "Edit" ? <Tab sx={{ color: theme.palette.primary.main }} label="Concentración de riesgo por librador" value="2" /> : null}
                            {modo === "Edit" ? <Tab sx={{ color: theme.palette.primary.main }} label="Atomización de cartera " value="3" /> : null}
                            {/* Concentración de riesgo por librador (superior al 5%) */}
                        </TabList>
                        <Divider />
                        <DialogContent style={{ backgroundColor: theme.palette.background.default }}>
                            <TabPanel value="1">
                                <Container component="main" maxWidth="md">
                                    <Grid container spacing={2} >
                                        <Grid item xs={12}>
                                            <Controller
                                                fullwidth="true"
                                                name="new_fechahasta"
                                                render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
                                                    <FormControl fullWidth>
                                                        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
                                                            <DesktopDatePicker
                                                                {...field}
                                                                views={['day', 'month', 'year']}
                                                                inputRef={ref}
                                                                label="Fecha Hasta:"
                                                                renderInput={(inputProps) => (
                                                                    <TextField
                                                                        {...inputProps}
                                                                        onBlur={onBlur}
                                                                        size="small"
                                                                        name={name}
                                                                        error={!!fieldState.error}
                                                                        helperText="La fecha es requerida"
                                                                        rules={{ required: "Required!" }}
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    </FormControl>
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomCurrencyField
                                                name="new_saldonetodegarantiasvigentes"
                                                label="Saldo Neto de Garantias Vigentes"
                                                readOnly={false}
                                                helperText="Escribe tu facturacion/ingresos del último año"
                                                size="small"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                Component={TextField}
                                                disabled
                                                readOnly={true}
                                                size="small"
                                                type="number"
                                                name="new_solvencia"
                                                label="% SOLVENCIA"
                                                endAdornment={<LockIcon />}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Divider>FONDOS DE RIESGO</Divider>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomCurrencyField
                                                name="new_fondoderiesgointegrado"
                                                label="Fondo de Riesgo Integrado"
                                                readOnly={false}
                                                size="small"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomCurrencyField
                                                name="new_fondoderiesgodisponible"
                                                label="Fondo de Riesgo Disponible"
                                                readOnly={false}
                                                size="small"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomCurrencyField
                                                name="new_fondoderiesgocontingente"
                                                label="Fondo de Riesgo Contingente"
                                                readOnly={false}
                                                size="small"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomCurrencyField
                                                name="new_fondoderiesgoavalordemercado"
                                                label="Fondo de Riesgo a valor de mercado"
                                                readOnly={false}
                                                size="small"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} >

                                        <Grid item xs={12}>
                                            <Divider>RIESGO MERCADO DE CAPITALES POR ENTIDAD DE GARANTÍA</Divider>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                Component={TextField}
                                                readOnly={false}
                                                size="small"
                                                type="number"
                                                name="new_porcentajeriesgopropio"
                                                label="% Riesgo Propio "

                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                Component={TextField}
                                                readOnly={false}
                                                size="small"
                                                type="number"
                                                name="new_porcentajeriesgoterceros"
                                                label="% Riesgo Terceros"

                                            />
                                        </Grid>

                                    </Grid>
                                    <Grid container spacing={2} >
                                        <Grid item xs={12}>
                                            <Divider>COMPOSICIÓN CONTRAGARANTÍAS SEGÚN PYMES CON GARANTÍAS VIGENTES</Divider>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                Component={TextField}
                                                readOnly={false}
                                                size="small"
                                                type="number"
                                                name="new_porcentajeprenda"
                                                label="% Prenda"

                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                Component={TextField}
                                                readOnly={false}
                                                size="small"
                                                type="number"
                                                name="new_porcentajehipoteca"
                                                label="% Hipoteca"

                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                Component={TextField}
                                                readOnly={false}
                                                size="small"
                                                type="number"
                                                name="new_porcentajefianza"
                                                label="% Fianza"

                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                Component={TextField}
                                                readOnly={false}
                                                size="small"
                                                type="number"
                                                name="new_porcentajeotras"
                                                label="% Otras"

                                            />
                                        </Grid>

                                    </Grid>
                                    <Grid container spacing={2} >
                                        <Grid item xs={12}>
                                            <Divider>GARANTÍAS VIGENTES (RIESGO VIVO) CNV POR TIPO DE ACREEDOR</Divider>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                Component={TextField}
                                                readOnly={false}
                                                size="small"
                                                type="number"
                                                name="new_entidadesfinancierascnv"
                                                label="% Entidades Financieras"

                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                Component={TextField}
                                                readOnly={false}
                                                size="small"
                                                type="number"
                                                name="new_garantiascomercialescnv"
                                                label="% Garantías Comerciales"

                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                Component={TextField}
                                                readOnly={false}
                                                size="small"
                                                type="number"
                                                name="new_garantastecnicascnv"
                                                label="% Garantías Técnicas"

                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                Component={TextField}
                                                readOnly={false}
                                                size="small"
                                                type="number"
                                                name="new_mercadodecapitalescnv"
                                                label="% Mercado de Capitales"

                                            />
                                        </Grid>

                                    </Grid>
                                    <Grid container spacing={2} >
                                        <Grid item xs={12}>
                                            <Divider>GARANTÍAS VIGENTES (RIESGO VIVO) CNV POR TIPO DE INSTRUMENTO DEL MERCADO DE CAPITALES</Divider>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomCurrencyField
                                                name="new_chequedepagodiferidocnv"
                                                label="Cheque de Pago Diferido CNV"
                                                readOnly={false}
                                                size="small"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomCurrencyField
                                                name="new_pagarbursatilcnv"
                                                label="Pagaré Bursátil CNV"
                                                readOnly={false}
                                                size="small"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomCurrencyField
                                                name="new_valoresdecortoplazocnv"
                                                label="Valores de corto plazo CNV"
                                                readOnly={false}
                                                size="small"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomCurrencyField
                                                name="new_obligacionesnegociablescnv"
                                                label="Obligaciones Negociables CNV"
                                                readOnly={false}
                                                size="small"
                                            />
                                        </Grid>
                                    </Grid>
                                    <Grid container spacing={2} >
                                        <Grid item xs={12}>
                                            <Divider>GARANTÍAS VIGENTES (RIESGO VIVO) PORCENTAJE POR SITUACIÓN</Divider>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                Component={TextField}
                                                readOnly={false}
                                                size="small"
                                                type="number"
                                                name="new_garantasvigentesrvenpymesensituacion1"
                                                label="% Situación 1"

                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                Component={TextField}
                                                readOnly={false}
                                                size="small"
                                                type="number"
                                                name="new_garantasvigentesrvenpymesensituacion2"
                                                label="% Situación 2"

                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                Component={TextField}
                                                readOnly={false}
                                                size="small"
                                                type="number"
                                                name="new_garantasvigentesrvenpymesensituacion3"
                                                label="% Situación 3"

                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                Component={TextField}
                                                readOnly={false}
                                                size="small"
                                                type="number"
                                                name="new_garantasvigentesrvenpymesensituacion4"
                                                label="% Situación 4"

                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextField
                                                Component={TextField}
                                                readOnly={false}
                                                size="small"
                                                type="number"
                                                name="new_garantasvigentesrvenpymesensituacion5"
                                                label="% Situación 5"

                                            />
                                        </Grid>
                                    </Grid>
                                </Container>
                            </TabPanel>

                            <TabPanel value="2">
                                <Container component="main" maxWidth="md">
                                    <Grid container spacing={2} >
                                        <Grid item xs={12}>
                                            {
                                                modo === "New" ? //si es crear el array de lineas va a ser vacio, lo que obliga a que si es necesario crea un nuevo librador
                                                    (
                                                        <TablaLibradores
                                                            lineas={[]}
                                                            columnas={columnasLibradores}
                                                            handleOpen={handleOpenModalTable}
                                                            titulo={"Concentración de riesgo por librador (superior al 5%)"}
                                                        />
                                                    )
                                                    :
                                                    (
                                                        <TablaLibradores
                                                            lineas={libradores}
                                                            columnas={columnasLibradores}
                                                            handleOpen={handleOpenModalTable}
                                                            titulo={"Concentración de riesgo por librador (superior al 5%)"}
                                                            indicadorid={indicadorid}
                                                            sgrid={sgrid}
                                                            handleEdit={onSubmitEdit}
                                                        />

                                                    )
                                            }
                                        </Grid>
                                    </Grid>
                                </Container>
                            </TabPanel>

                            <TabPanel value="3">
                                <Container component="main" maxWidth="md">
                                    <Grid container spacing={2} >
                                        <Grid item xs={12}>
                                            {
                                                modo === "New" ?
                                                    (
                                                        <TablaLibradores
                                                            lineas={[]}
                                                            columnas={columnasParticipe}
                                                            handleOpen={handleOpenModalTable}
                                                            titulo={"Atomización de cartera % Socio Participe/Tercero"}
                                                        />
                                                    )
                                                    :
                                                    (
                                                        <TablaLibradores
                                                            lineas={participesYterceros}
                                                            columnas={columnasParticipe}
                                                            handleOpen={handleOpenModalTable}
                                                            titulo={"Atomización de cartera % Socio Participe/Tercero"}
                                                            indicadorid={indicadorid}
                                                            sgrid={sgrid}
                                                            handleEdit={onSubmitEdit}
                                                        />
                                                    )
                                            }
                                        </Grid>

                                        <Grid item xs={12}>
                                            {
                                                modo === "New" ?
                                                    (
                                                        <TablaLibradores
                                                            lineas={[]}
                                                            columnas={columnasProtector}
                                                            handleOpen={handleOpenModalTable}
                                                            titulo={"Atomización % Socio Protector"}
                                                        />
                                                    )
                                                    :
                                                    (
                                                        <TablaLibradores
                                                            lineas={protectores}
                                                            columnas={columnasProtector}
                                                            handleOpen={handleOpenModalTable}
                                                            titulo={"Atomización % Socio Protector"}
                                                            indicadorid={indicadorid}
                                                            sgrid={sgrid}
                                                            handleEdit={onSubmitEdit}
                                                        />
                                                    )
                                            }
                                        </Grid>
                                    </Grid>
                                </Container>
                            </TabPanel>
                        </DialogContent>
                    </TabContext>
                    <DialogActions sx={{ backgroundColor: theme.palette.background.common }}>
                        <Button onClick={handleSubmit(onSubmitHandler)} variant="contained">Guardar Cambios</Button>
                        <Button onClick={handleClose} autoFocus variant="contained">Volver atrás</Button>
                    </DialogActions>
                </FormProvider>
            </Dialog >
            <ModalSociosYlibradores open={abrir} handleClose={handleCloseModalTable} tablaActiva={tablaActiva} indicadorid={indicadorid} tablaid={idTabla} crear={crear} objeto={objeto} />
        </>
    )
}

export default ModalIndicadores