import axios from 'axios'
import { Entidad, UrlApiDynamics, UrlApi } from '../Keys'
import { toast } from 'react-toastify'

//Const
const dataInicial = {
    loading: false,
    loadingPymes: false,
    loadingPymesInicio: false,
    loadingActualizarPyme: false,
    loadingActivarPyme: false,
    refreshPyme: false,
    cuenta: {},
    cuentaConGarantia: [],
    cuentaConGarantiaInicio: [],
    sociedadXsocio: {},
    sociedadDeBolsa: {},
    sociedadesDeBolsa: [],
    certificadosPymes: [],
    provincias: [],
    paises: [],
    socios: [],
    pymesXserie: [],
    contactosNotificaciones: [],
    cuentasPorSgr: [],
    cuentaIdSeleccionada: '',
    actualizacionDatosAlyc: '',
    actualizacionDatos: '',
    sociedadBolsaSocio: {},
    crearCuentaSociedad: '',
    modificarSociedadXSocio: '',
    inactivarCuentaMensaje: '',
    resultadoCuestas: '',
    resultadoActualizar: '',
    resultadoActivarPyme: '',
    comprobantesDeVenta: [],
    loadingComprobante: false,
    modificarSociedadBolsa: '',
    loadingSociedades: false,
    loadingSociedadesDeBolsa: false,
    inactivarCuentaSocioMensaje: '',
}

const ToastError = (msj) => {
    toast.error(msj, {
        theme: "dark",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};

const ToastSuccess = (msj) => {
    toast.success(msj, {
        theme: "dark",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })
}

//Types
const CUENTA_EXITO = 'CUENTA_EXITO'
const ACTUALIZAR_CUENTA = 'ACTUALIZAR_CUENTA'
const ACTUALIZAR_CUENTAALYC_EXITO = 'ACTUALIZAR_CUENTAALYC_EXITO'
const ACTUALIZAR_CUENTA_EXITO = 'ACTUALIZAR_CUENTA_EXITO'
const PROVINCIA_EXITO = 'PROVINCIA_EXITO'
const PYMEXSERIE_EXITO = 'PYMEXSERIE_EXITO'
const CERTIFICADO_EXITO = 'CERTIFICADO_EXITO'
const SOCIEDADXSOCIO_EXITO = 'SOCIEDADXSOCIO_EXITO'
const SOCIEDADBOLSA_EXITO = 'SOCIEDADBOLSA_EXITO'
const SOCIOS_EXITO = 'SOCIOS_EXITO'
const LOADING = "LOADING"
const LOADING_ACTUALIZACION = 'LOADING_ACTUALIZACION'
const ERROR = 'ERROR'
const ERROR_ACTUALIZACION_ALYC = 'ERROR_ACTUALIZACION_ALYC'
const ERROR_GENERAL = 'ERROR_GENERAL'
const CUENTA_ID_SELECCIONADA_EXITO = 'CUENTA_ID_SELECCIONADA_EXITO'
const PYMES_ADHERIDAS_A_GARANTIAS = 'PYMES_ADHERIDAS_A_GARANTIAS'
const PYMES_ADHERIDAS_INICIO = 'PYMES_ADHERIDAS_INICIO'
const ERROR_OBTENER_PYME_INICIO = 'ERROR_OBTENER_PYME_INICIO'
const ERROR_OBTENER_PYME = 'ERROR_OBTENER_PYME'
const PAISES_FETCH_EXITO = 'PAISES_FETCH_EXITO'
const CONTACTOS_NOTI_FETCH_EXITO = 'CONTACTOS_NOTI_FETCH_EXITO'
const SOCIEDAD_BOLSA_SOCIO_EXITO = 'SOCIEDAD_BOLSA_SOCIO_EXITO'
const LOADING_CUENTA_SOCIEDAD_SOCIO = "LOADING_CUENTA_SOCIEDAD_SOCIO"
const CUENTA_SOCIEDAD_SOCIO_EXITO = "CUENTA_SOCIEDAD_SOCIO_EXITO"
const MODIFICAR_SOCIEDADXSOCIO_EXITO = "MODIFICAR_MODIFICAR_SOCIEDADXSOCIO"
const INACTIVAR_CUENTA_EXITO = "INACTIVAR_CUENTA_EXITO"
const ACTUALIZAR_MENSAJE_ALYC = 'ACTUALIZAR_MENSAJE_ALYC'
const ACTUALIZAR_MENSAJE_CUENTA = 'ACTUALIZAR_MENSAJE_CUENTA'
const CUENTAS_POR_SGR = 'CUENTAS_POR_SGR'
const ACTUALIZAR_REFRESH_CUENTA = 'ACTUALIZAR_REFRESH_CUENTA'
const LOADING_COMPROBANTE = 'LOADING_COMPROBANTE'
const COMPROBANTE_VENTA_EXITO = 'COMPROBANTE_VENTA_EXITO'
const MODIFICAR_SOCIEDAD_EXITO = 'MODIFICAR_SOCIEDAD_EXITO'
const LOADING_SOCIEDADES = 'LOADING_SOCIEDADES'
const LOADING_INACTIVACION = 'LOADING_INACTIVACION'
const LOADING_SOCIEDADES_BOLSA = 'LOADING_SOCIEDADES_BOLSA'
const ERROR_SOCIEDAD_BOLSA = 'ERROR_SOCIEDAD_BOLSA'
const LOADING_MOD_SOCIEDAD = 'LOADING_MOD_SOCIEDAD'
const LOADING_PYMES = 'LOADING_PYMES'
const LOADING_PYMES_INICIO = 'LOADING_PYMES_INICIO'
const LOADING_ACTUALIZAR_PYME = 'LOADING_ACTUALIZAR_PYME'
const ERROR_ACTUALIZAR_CUENTA = 'ERROR_ACTUALIZAR_CUENTA'
const LOADING_ACTIVACION_PYME = 'LOADING_ACTIVACION_PYME'
const ACTIVACIONPYME_EXITO = 'ACTIVACIONPYME_EXITO'
const ACTIVACIONPYME_ERROR = 'ACTIVACIONPYME_ERROR'
const ERROR_CUENTA_SOCIEDAD_SOCIO = 'ERROR_CUENTA_SOCIEDAD_SOCIO'
const ERROR_MOD_CUENTA_SOCIEDAD_SOCIO = 'ERROR_MOD_CUENTA_SOCIEDAD_SOCIO'
//Reducers
export default function cuentaReducers(state = dataInicial, action) {
    switch (action.type) {
        case MODIFICAR_SOCIEDAD_EXITO:
            return { ...state, modificarSociedadBolsa: action.modificarSociedadBolsa }
        case LOADING_MOD_SOCIEDAD:
            return { ...state, modificarSociedadBolsa: action.modificarSociedadBolsa }
        case SOCIEDADBOLSA_EXITO:
            return { ...state, sociedadDeBolsa: action.payload }
        case SOCIEDADXSOCIO_EXITO:
            return { ...state, sociedadXsocio: action.payload, loadingSociedades: action.loadingSociedades }
        case CERTIFICADO_EXITO:
            return { ...state, certificadosPymes: action.payload }
        case ERROR_ACTUALIZACION_ALYC:
            return { ...state, actualizacionDatosAlyc: action.actualizacionDatosAlyc }
        case LOADING_INACTIVACION:
            return { ...state, inactivarCuentaSocioMensaje: action.inactivarCuentaSocioMensaje }
        case ERROR_GENERAL:
            return { ...state, actualizacionDatos: action.actualizacionDatos }
        case LOADING:
            return { ...state, actualizacionDatos: action.actualizacionDatos }
        case LOADING_ACTUALIZACION:
            return { ...state, actualizacionDatosAlyc: action.actualizacionDatosAlyc, actualizacionDatos: action.actualizacionDatos }
        case CUENTA_EXITO:
            return { ...state, cuenta: action.payload }
        case PYMES_ADHERIDAS_A_GARANTIAS:
            return { ...state, cuentaConGarantia: action.payload, refreshPyme: action.refreshPyme }
        case PYMES_ADHERIDAS_INICIO:
            return { ...state, cuentaConGarantiaInicio: action.payload }
        case CUENTA_ID_SELECCIONADA_EXITO:
            return { ...state, cuentaIdSeleccionada: action.cuentId }
        case PROVINCIA_EXITO:
            return { ...state, provincias: action.payload }
        case ACTUALIZAR_CUENTA:
            return { ...state, actualizacionDatos: action.actualizacionDatos, resultadoActualizar: action.resultado, loadingActualizarPyme: action.loadingActualizarPyme }
        case ACTUALIZAR_CUENTAALYC_EXITO:
            return { ...state, actualizacionDatosAlyc: action.actualizacionDatosAlyc }
        case SOCIEDAD_BOLSA_SOCIO_EXITO:
            return { ...state, sociedadBolsaSocio: action.payload, crearCuentaSociedad: action.crearCuentaSociedad, crearCuentaSociedad: 'LOADING', inactivarCuentaSocioMensaje: 'LOADING', modificarSociedadXSocio: 'LOADING' }
        case LOADING_CUENTA_SOCIEDAD_SOCIO:
            return { ...state, crearCuentaSociedad: action.crearCuentaSociedad }
        case CUENTA_SOCIEDAD_SOCIO_EXITO:
            return { ...state, crearCuentaSociedad: action.crearCuentaSociedad }
        case MODIFICAR_SOCIEDADXSOCIO_EXITO:
            return { ...state, modificarSociedadXSocio: action.modificarSociedadXSocio }
        case INACTIVAR_CUENTA_EXITO:
            return { ...state, inactivarCuentaSocioMensaje: action.inactivarCuentaSocioMensaje }
        case PYMEXSERIE_EXITO:
            return { ...state, pymesXserie: action.payload }
        case SOCIOS_EXITO:
            return { ...state, socios: action.payload }
        case PAISES_FETCH_EXITO:
            return { ...state, paises: action.payload }
        case CONTACTOS_NOTI_FETCH_EXITO:
            return { ...state, contactosNotificaciones: action.payload }
        case ACTUALIZAR_MENSAJE_ALYC:
            return { ...state, actualizacionDatosAlyc: action.actualizacionDatosAlyc }
        case ACTUALIZAR_MENSAJE_CUENTA:
            return { ...state, actualizacionDatos: action.actualizacionDatos }
        case CUENTAS_POR_SGR:
            return { ...state, cuentasPorSgr: action.payload, resultadoCuestas: action.resultado }
        case ACTUALIZAR_REFRESH_CUENTA:
            return { ...state, refreshPyme: action.refreshPyme }
        case COMPROBANTE_VENTA_EXITO:
            return { ...state, comprobantesDeVenta: action.payload, loadingComprobante: action.loadingComprobante }
        case LOADING_COMPROBANTE:
            return { ...state, loadingComprobante: action.loadingComprobante }
        case ACTUALIZAR_CUENTA_EXITO:
            return { ...state, actualizacionDatos: action.actualizacionDatos }
        case ERROR_SOCIEDAD_BOLSA:
            return { ...state, loadingSociedadesDeBolsa: action.loadingSociedadesDeBolsa }
        case LOADING_SOCIEDADES_BOLSA:
            return { ...state, loadingSociedadesDeBolsa: action.loadingSociedadesDeBolsa }
        case LOADING_SOCIEDADES:
            return { ...state, loadingSociedades: action.loadingSociedades }
        case LOADING_PYMES:
            return { ...state, loadingPymes: action.loadingPymes }
        case LOADING_PYMES_INICIO:
            return { ...state, loadingPymesInicio: action.loadingPymesInicio }
        case ERROR_OBTENER_PYME:
            return { ...state, loadingPymes: action.loadingPymes }
        case ERROR_OBTENER_PYME_INICIO:
            return { ...state, loadingPymesInicio: action.loadingPymesInicio }
        case LOADING_ACTUALIZAR_PYME:
            return { ...state, loadingActualizarPyme: action.loadingActualizarPyme, resultadoActualizar: action.resultado }
        case ERROR_ACTUALIZAR_CUENTA:
            return { ...state, loadingActualizarPyme: action.loadingActualizarPyme, resultadoActualizar: action.resultado }
        case LOADING_ACTIVACION_PYME:
            return { ...state, loadingActivarPyme: action.loadingActivarPyme, resultadoActivarPyme: action.resultadoActivarPyme }
        case ACTIVACIONPYME_ERROR:
            return { ...state, loadingActivarPyme: action.loadingActivarPyme, resultadoActivarPyme: action.resultadoActivarPyme }
        case ACTIVACIONPYME_EXITO:
            return { ...state, loadingActivarPyme: action.loadingActivarPyme, resultadoActivarPyme: action.resultadoActivarPyme }
        case ERROR_CUENTA_SOCIEDAD_SOCIO:
            return { ...state, crearCuentaSociedad: action.crearCuentaSociedad }
        case ERROR_MOD_CUENTA_SOCIEDAD_SOCIO:
            return { ...state, modificarSociedadBolsa: action.modificarSociedadBolsa }
        default:
            return { ...state }
    }
}

//Actions

export const obtenerSocios = (token) => async (dispatch) => {
    dispatch({
        type: LOADING
    })
    var entidad = "accounts"
    var fetch = "<fetch mapping='logical'>" +
        "<entity name='account'>" +
        "<attribute name='accountid'/>" +
        "<attribute name='name'/> " +
        "<attribute name='createdon'/> " +
        "<attribute name='primarycontactid'/> " +
        "<attribute name='new_observaciones'/> " +
        "<attribute name='statuscode'/> " +
        "<attribute name='new_nmerodedocumento'/> " +
        "<attribute name='new_estadodelsocio'/> " +
        "<attribute name='new_personeria'/>" +
        "<attribute name='new_tiposocietario'/> " +
        "<attribute name='new_condicionimpositiva'/> " +
        "<attribute name='new_tipodedocumentoid'/> " +
        "<attribute name='new_contactofirmante'/> " +
        "<attribute name='emailaddress1'/> " +
        "<attribute name='new_piso'/> " +
        "<attribute name='telephone2'/> " +
        "<attribute name='address1_line1'/> " +
        "<attribute name='new_direccion1numero'/>" +
        "<attribute name='address1_name'/> " +
        "<attribute name='new_saldobrutogarantasvigentes'/> " +
        "<attribute name='new_cantidadsgrconlasqueopera'/> " +
        "<attribute name='new_direccion1depto'/> " +
        "<attribute name='address1_postalcode'/> " +
        "<attribute name='address1_county'/> " +
        "<attribute name='new_localidad'/> " +
        "<attribute name='new_provincia'/> " +
        "<attribute name='new_pais'/> " +
        "<attribute name='new_actividadafip'/>" +
        "<attribute name='new_facturacionultimoanio'/> " +
        "<attribute name='new_fechadealta'/> " +
        "<attribute name='new_nrodeexpediente'/> " +
        "<attribute name='new_calificacion'/> " +
        "<attribute name='new_contactodenotificaciones'/> " +
        "<attribute name='new_condicionpyme'/> " +
        "<attribute name='new_categoria'/> " +
        "<order attribute ='new_estadodelsocio' descending='false' />" +
        "<filter type='and'>" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "<condition attribute='new_estadodelsocio' operator='not-in'>" +
        "<value>100000002</value>" +
        "<value>100000005</value>" +
        "<value>100000004</value>" +
        "</condition>" +
        "</filter>" +
        "<link-entity name='new_certificadopyme' from='new_socioparticipe' to='accountid' link-type='outer' alias='aa'>" +
        "<attribute name='new_certificadopymeid'/>" +
        "<attribute name='new_numeroderegistro'/> " +
        "<attribute name='new_name'/> " +
        "<attribute name='new_vigenciadesde'/> " +
        "<attribute name='statuscode'/> " +
        "<attribute name='createdon'/> " +
        "<attribute name='new_fechadeemision'/> " +
        "<attribute name='new_categoria'/>" +
        "<attribute name='new_vigenciahasta'/> " +
        "<filter type='and'>" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "</filter>" +
        "</link-entity>" +
        "<link-entity name='new_bancosporsocio' from='new_socio' to='accountid' link-type='outer' alias='ab'>" +
        "<attribute name='new_bancosporsocioid'/>" +
        "<attribute name='new_numerodecuenta'/> " +
        "<attribute name='new_name'/> " +
        "<attribute name='new_socio'/> " +
        "<attribute name='statuscode'/> " +
        "<attribute name='createdon'/> " +
        "<attribute name='new_cbu'/>" +
        "<attribute name='new_banco'/> " +
        "<filter type='and'>" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "</filter>" +
        "</link-entity>" +
        "<link-entity name='new_sociedaddebolsaporsocio' from='new_socio' to='accountid' link-type='outer' alias='ac'>" +
        "<attribute name='new_sociedaddebolsaporsocioid'/>" +
        "<attribute name='new_name'/> " +
        "<attribute name='createdon'/> " +
        "<attribute name='new_socio'/> " +
        "<attribute name='statuscode'/> " +
        "<attribute name='new_sociedaddebolsa'/> " +
        "<attribute name='new_idbolsa'/> " +
        "<attribute name='new_cuentacomitente'/>" +
        "<attribute name='new_codigo'/> " +
        "<filter type='and'>" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "</filter>" +
        "</link-entity>" +
        "<link-entity name='new_participacionaccionaria' from='new_cuentaid' to='accountid' link-type='outer' alias='ad'>" +
        "<attribute name='new_participacionaccionariaid'/> " +
        "<attribute name='createdon'/> " +
        "<attribute name='new_tipoderelacion'/> " +
        "<attribute name='statuscode'/> " +
        "<attribute name='new_cuentacontactovinculado'/> " +
        "<attribute name='new_cargo'/> " +
        "<attribute name='new_accionista'/>" +
        "<attribute name='new_porcentajedeparticipacion'/> " +
        "<filter type='and'>" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "</filter>" +
        "<link-entity name='contact' from='contactid' to='new_cuentacontactovinculado' link-type='outer' alias='contacto'>" +
        "<attribute name='contactid'/> " +
        "<attribute name='fullname'/>" +
        "<attribute name='new_entedondetrabaja'/> " +
        "<attribute name='new_relacinconlapersonaexpuestapolticamente'/>" +
        "<attribute name='new_fechadealtabaja'/> " +
        "<attribute name='new_fechadebaja'/> " +
        "<attribute name='new_tienedomiciliofiscalenelexterior'/>" +
        "<attribute name='new_tienedomicilioenusa'/> " +
        "<attribute name='statuscode'/>" +
        "<attribute name='address3_stateorprovince'/> " +
        "<attribute name='address1_stateorprovince'/>" +
        "<attribute name='address3_country'/> " +
        "<attribute name='address1_country'/>" +
        "<attribute name='new_nrodedocumentoconyuge'/> " +
        "<attribute name='new_nrodedocumento'/>" +
        "<attribute name='firstname'/> " +
        "<attribute name='new_nit'/>" +
        "<attribute name='birthdate'/> " +
        "<attribute name='familystatuscode'/>" +
        "<attribute name='new_cuitcuil'/> " +
        "<attribute name='emailaddress1'/>" +
        "<attribute name='lastname'/> " +
        "<attribute name='address3_line1'/>" +
        "<attribute name='address3_postalcode'/> " +
        "<attribute name='address3_city'/>" +
        "<attribute name='address1_line1'/>" +
        "<attribute name='address1_postalcode'/> " +
        "<attribute name='address1_city'/>" +
        "<attribute name='new_einss'/> " +
        "<attribute name='new_tipodedocumentoconyuge'/> " +
        "<attribute name='new_generodelconyuge'/>" +
        "<attribute name='new_conyugetrabaja'/> " +
        "<attribute name='spousesname'/> " +
        "<attribute name='new_tiposujetoobligado'/> " +
        "<attribute name='new_lugardenacimiento'/> " +
        "<attribute name='new_paisdeorgen'/> " +
        "<filter type='and'>" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "</filter>" +
        "</link-entity>" +
        "<link-entity name='account' from='accountid' to='new_cuentacontactovinculado' link-type='outer' alias='cuenta'>" +
        "<attribute name='name'/> " +
        "<attribute name='accountid'/>" +
        "<attribute name='emailaddress1'/> " +
        "<attribute name='new_nmerodedocumento'/> " +
        "<filter type='and'>" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "</filter>" +
        "</link-entity>" +
        "</link-entity>" +
        "<link-entity name='new_garantia' from='new_socioparticipe' to='accountid' link-type='outer' alias='ae'>" +
        "<link-entity name='new_seriedeoperacinsindicada' from='new_seriedeoperacinsindicadaid' to='new_nmerodeserie' link-type='outer' alias='af'>" +
        "<attribute name='new_sgrlider'/> " +
        "<filter type='and'>" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "</filter>" +
        "</link-entity>" +
        "</link-entity>" +
        "</entity>" +
        "</fetch>";

    try {
        await axios.post(`${UrlApi}api/consultafetch`,
            {
                entidad: entidad,
                fetch: fetch,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
            .then((response) => {
                dispatch({
                    type: SOCIOS_EXITO,
                    payload: response.data
                })
            })
            .catch((err) => {
                console.log(err)
                dispatch({
                    type: ERROR
                })
            });
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}

export const obtenerProvincias = (token) => async (dispatch) => {
    try {
        var entidad = "new_provincias"
        var fetch = "<fetch mapping='logical'>" +
            "<entity name='new_provincia'>" +
            "<attribute name='new_provinciaid'/> " +
            "<attribute name='new_name'/> " +
            "<attribute name='createdon'/>" +
            "<order attribute ='new_name' descending='false' />" +
            "</entity>" +
            "</fetch>";

        await axios.post(`${UrlApi}api/consultafetch`,
            {
                "entidad": entidad,
                "fetch": fetch
            },
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            .then((response) => {
                dispatch({
                    type: PROVINCIA_EXITO,
                    payload: response.data
                })
            })
            .catch(err => {
                console.log(err)
            })
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}

export const actualizarDatosCuentaPyme = (accountid, token, telefono = "", calle = "", numero = "", piso = "", depto = "",
    provinciaId = "", localidad = "", municipio = "", codigoPostal = "", inscripcion = "", pais = "", firmante = "") => async (dispatch) => {
        dispatch({
            type: LOADING_ACTUALIZACION,
            actualizacionDatos: 'LOADING',
            actualizacionDatosAlyc: 'PENDING',
        })

        try {
            const actualizarCuenta = () => new Promise(async (resolve, reject) => {

                await axios.put(`${UrlApi}/api/socioparticipe/micuenta`,
                    {
                        "accountid": accountid,
                        "telephone2": telefono,
                        "address1_line1": calle,
                        "new_direccion1numero": numero,
                        "address1_name": piso,
                        "new_direccion1depto": depto,
                        "new_provincia": provinciaId,
                        "new_localidad": localidad,
                        "address1_county": municipio,
                        "address1_postalcode": codigoPostal,
                        "new_condiciondeinscripcionanteafip": inscripcion == "" ? 0 : inscripcion,
                        // "new_inscripcionganancias": inscripcion == "" ? 0 : inscripcion,
                        "new_pais": pais,
                    },
                    {
                        headers: {
                            'Authorization': `Bearer ${token}`
                        }
                    })
                    .then(resp => {

                        dispatch({
                            type: ACTUALIZAR_CUENTA_EXITO,
                            payload: resp.data,
                            actualizacionDatos: 'EXITO'
                        })
                        resolve(resp.data)
                    })
                    .catch(err => {

                        reject(err)
                    })
            })

            const response = await toast.promise(
                actualizarCuenta,
                {
                    pending: 'Procesando...',
                    success: 'Cuenta actualizada',
                    error: {
                        render({ data }) {
                            // console.log(data)
                            return `${data}`
                        }
                    }
                },
                {
                    theme: "dark",
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                }
            )
        } catch (error) {
            dispatch({
                type: ERROR_GENERAL,
                actualizacionDatos: 'ERROR'
            })
        }
    }



export const actualizarDatosCuenta = (token, accountid, calle = "", numero = "", piso = "", depto = "", codigoPostal = "", municipioPartido = "", localidad = "", provinciaId = "", pais = "",
    telefono = "", ganancias = 0, calificacion, firmante = "", estadoDocu = 0, contactoNotificaciones = "", cambiarCalificacion, estadoSocio = 0) => async (dispatch) => {

        dispatch({
            type: LOADING_ACTUALIZAR_PYME,
            loadingActualizarPyme: true,
            resultado: 'LOADING',
            actualizacionDatosAlyc: 'PENDING',
        })

        let datos = {}
        if (cambiarCalificacion) {
            datos = {
                "accountid": accountid,
                "new_calificacion": calificacion
            }
        } else if (estadoSocio != 0) {
            datos = {
                "accountid": accountid,
                "new_estadodelsocio": estadoSocio
            }
        } else {
            datos = {
                "accountid": accountid,
                "telephone2": telefono != 'undefined' ? telefono : '',
                "address1_line1": calle != 'undefined' ? calle : '',
                "new_direccion1numero": numero != 'undefined' ? numero : '',
                "address1_name": piso != 'undefined' ? piso : '',
                "new_direccion1depto": depto != 'undefined' ? depto : '',
                "new_provincia": provinciaId != 'undefined' ? provinciaId : '',
                "new_localidad": localidad != 'undefined' ? localidad : '',
                "address1_county": municipioPartido != 'undefined' ? municipioPartido : '',
                "address1_postalcode": codigoPostal != 'undefined' ? codigoPostal : '',
                "new_inscripcionganancias": ganancias != 'undefined' ? ganancias : 0,
                "new_pais": pais != 'undefined' ? pais : '',
                "new_firmante": firmante != 'undefined' ? firmante : '',
                "new_estadodeactividad": estadoDocu != 'undefined' ? estadoDocu : 0,
                "new_estadodelsocio": estadoSocio != 'undefined' ? estadoSocio : 0,
                "new_contactodenotificaciones": contactoNotificaciones != 'undefined' ? contactoNotificaciones : '',
                "new_fechaltimaconsulta": "",
                "new_respuestanosis": "",
                "new_calificacion": calificacion != 'undefined' ? calificacion : 0
            }
        }
        try {
            await axios.put(`${UrlApi}api/portalcasfog/pyme`,
                datos,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }).then((response) => {
                    dispatch({
                        type: ACTUALIZAR_CUENTA,
                        payload: response.data,
                        resultado: "EXITO",
                        loadingActualizarPyme: false
                    })
                    ToastSuccess('La cuenta se actualizó con exito!')
                }).catch((error) => {
                    dispatch({
                        type: ERROR_ACTUALIZAR_CUENTA,
                        resultado: 'ERROR',
                        loadingActualizarPyme: false
                    })
                    ToastError('La cuenta no se pudo actualizar!')
                })
        } catch (error) {
            dispatch({
                type: ERROR_ACTUALIZAR_CUENTA,
                resultado: 'ERROR',
                loadingActualizarPyme: false
            })
            ToastError('La cuenta no se pudo actualizar!')
        }
    }

export const activarPyme = (token, accountid) => async (dispatch) => {
    dispatch({
        type: LOADING_ACTIVACION_PYME,
        loadingActivarPyme: true,
    })

    let datos = { "accountid": accountid }
    try {
        await axios.put(`${UrlApi}api/portalcasfog/activarpyme`,
            datos,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }).then((response) => {
                dispatch({
                    type: ACTIVACIONPYME_EXITO,
                    payload: response.data,
                    resultadoActivarPyme: "EXITO",
                    loadingActivarPyme: false
                })
                ToastSuccess('Pyme activada!')
            }).catch((error) => {
                dispatch({
                    type: ACTIVACIONPYME_ERROR,
                    resultadoActivarPyme: 'ERROR',
                    loadingActivarPyme: false
                })
                ToastError('La pyme no se pudo activar!')
            })
    } catch (error) {
        dispatch({
            type: ACTIVACIONPYME_ERROR,
            resultadoActivarPyme: 'ERROR',
            loadingActivarPyme: false
        })
        ToastError('La pyme no se pudo activar!')
    }
}

export const obtenerCuentaIdSeleccionada = (id) => (dispatch) => {
    if (id !== undefined) {
        dispatch({
            type: CUENTA_ID_SELECCIONADA_EXITO,
            cuentId: id
        })
    }
}

export const obtenerCuentaAdheridaGarantiaFetch = (token) => async (dispatch) => {
    dispatch({
        type: LOADING_PYMES,
        loadingPymes: true
    })
    var entidad = "accounts"
    var fetch = "<fetch mapping='logical'>" +
        "<entity name='account'>" +
        "<attribute name='accountid'/>" +
        "<attribute name='name'/> " +
        "<attribute name='new_urlfotodeperfil'/> " +
        "<attribute name='createdon'/> " +
        "<attribute name='primarycontactid'/> " +
        "<attribute name='statuscode'/> " +
        "<attribute name='new_nmerodedocumento'/> " +
        "<attribute name='new_estadodelsocio'/> " +
        "<attribute name='new_personeria'/>" +
        "<attribute name='new_tiposocietario'/> " +
        "<attribute name='new_condicionimpositiva'/> " +
        "<attribute name='new_tipodedocumentoid'/> " +
        "<attribute name='new_contactofirmante'/> " +
        "<attribute name='emailaddress1'/> " +
        "<attribute name='telephone2'/> " +
        "<attribute name='address1_line1'/> " +
        "<attribute name='new_direccion1numero'/>" +
        "<attribute name='address1_name'/> " +
        "<attribute name='new_direccion1depto'/> " +
        "<attribute name='address1_postalcode'/> " +
        "<attribute name='address1_county'/> " +
        "<attribute name='new_localidad'/> " +
        "<attribute name='new_provincia'/> " +
        "<attribute name='new_pais'/> " +
        "<attribute name='new_actividadafip'/>" +
        "<attribute name='new_facturacionultimoanio'/> " +
        "<attribute name='new_fechadealta'/> " +
        "<attribute name='new_nrodeexpediente'/> " +
        "<attribute name='new_calificacion'/> " +
        "<attribute name='new_saldobrutogarantasvigentes'/> " +
        "<attribute name='new_cantidadsgrconlasqueopera'/> " +
        "<attribute name='new_firmante'/> " +
        "<attribute name='new_estadodeactividad'/> " +
        "<attribute name='new_contactodenotificaciones'/> " +
        "<attribute name='new_cantidadgarantiasvigentessepyme'/> " +
        "<attribute name='new_saldobrutogaratiasvigentessepyme'/> " +
        "<attribute name='new_montototalgaratiasotorgadassepyme'/> " +
        "<attribute name='new_cantidadtotalgaratiasotorgadassepyme'/> " +
        "<attribute name='new_condicionpyme'/> " +
        "<attribute name='new_categoria'/> " +
        // "<attribute name='new_vinculodocumentacionhistorica'/> " +
        "<order attribute ='new_estadodelsocio' descending='false' />" +
        "<link-entity name='contact' from='contactid' to='new_contactodenotificaciones' link-type='outer' alias='az'>" +
        "<attribute name='telephone1'/> " +
        "<attribute name='emailaddress1'/>" +
        "</link-entity>" +
        "<link-entity name='new_garantia' from='new_socioparticipe' to='accountid' link-type='inner' alias='ae'>" +
        // "<attribute name='statuscode'/> " +
        // "<attribute name='new_fechadenegociacion'/> " +
        // "<attribute name='new_dictamendelaval'/> " +
        "<filter type='and'>" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "</filter>" +
        "<link-entity name='new_seriedeoperacinsindicada' from='new_seriedeoperacinsindicadaid' to='new_nmerodeserie' link-type='inner' alias='af'>" +
        "<filter type='and'>" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "</filter>" +
        "<attribute name='new_seriedeoperacinsindicadaid'/> " +
        "<attribute name='new_name'/> " +
        "<attribute name='new_sgrlider'/>" +
        "</link-entity>" +
        "</link-entity>" +
        // "<link-entity name='new_participacionaccionaria' from='new_cuentaid' to='accountid' link-type='outer' alias='ad'>" +
        // "<attribute name='new_participacionaccionariaid'/> " +
        // "<attribute name='createdon'/> " +
        // "<attribute name='new_tipoderelacion'/> " +
        // "<attribute name='statuscode'/> " +
        // "<attribute name='new_cuentacontactovinculado'/> " +
        // "<attribute name='new_cargo'/> " +
        // "<attribute name='new_accionista'/>" +
        // "<attribute name='new_porcentajedeparticipacion'/> " +
        // "<filter type='and'>" +
        // "<condition attribute='statecode' operator='eq' value='0' />" +
        // "</filter>" +
        // "<link-entity name='contact' from='contactid' to='new_cuentacontactovinculado' link-type='outer' alias='contacto'>" +
        // "<attribute name='contactid'/> " +
        // "<attribute name='fullname'/>" +
        // "<attribute name='new_entedondetrabaja'/> " +
        // "<attribute name='new_relacinconlapersonaexpuestapolticamente'/>" +
        // "<attribute name='new_fechadealtabaja'/> " +
        // "<attribute name='new_fechadebaja'/> " +
        // "<attribute name='new_tienedomiciliofiscalenelexterior'/>" +
        // "<attribute name='new_tienedomicilioenusa'/> " +
        // "<attribute name='statuscode'/>" +
        // "<attribute name='address3_stateorprovince'/> " +
        // "<attribute name='address1_stateorprovince'/>" +
        // "<attribute name='address3_country'/> " +
        // "<attribute name='address1_country'/>" +
        // "<attribute name='new_nrodedocumentoconyuge'/> " +
        // "<attribute name='new_nrodedocumento'/>" +
        // "<attribute name='firstname'/> " +
        // "<attribute name='new_nit'/>" +
        // "<attribute name='birthdate'/> " +
        // "<attribute name='familystatuscode'/>" +
        // "<attribute name='new_cuitcuil'/> " +
        // "<attribute name='emailaddress1'/>" +
        // "<attribute name='lastname'/> " +
        // "<attribute name='address3_line1'/>" +
        // "<attribute name='address3_postalcode'/> " +
        // "<attribute name='address3_city'/>" +
        // "<attribute name='address1_line1'/>" +
        // "<attribute name='address1_postalcode'/> " +
        // "<attribute name='address1_city'/>" +
        // "<attribute name='new_einss'/> " +
        // "<attribute name='new_tipodedocumentoconyuge'/> " +
        // "<attribute name='new_generodelconyuge'/>" +
        // "<attribute name='new_conyugetrabaja'/> " +
        // "<attribute name='spousesname'/> " +
        // "<attribute name='new_tiposujetoobligado'/> " +
        // "<attribute name='new_lugardenacimiento'/> " +
        // "<attribute name='new_paisdeorgen'/> " +
        // "<filter type='and'>" +
        // "<condition attribute='statecode' operator='eq' value='0' />" +
        // "</filter>" +
        // "</link-entity>" +
        // "<link-entity name='account' from='accountid' to='new_cuentacontactovinculado' link-type='outer' alias='cuenta'>" +
        // "<attribute name='name'/> " +
        // "<attribute name='accountid'/>" +
        // "<attribute name='emailaddress1'/> " +
        // "<attribute name='new_nmerodedocumento'/> " +
        // "<filter type='and'>" +
        // "<condition attribute='statecode' operator='eq' value='0' />" +
        // "</filter>" +
        // "</link-entity>" +
        // "</link-entity>" +
        "</entity>" +
        "</fetch>";
    try {
        await axios.post(`${UrlApi}api/consultafetch`,
            {
                entidad: entidad,
                fetch: fetch,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
            .then((response) => {
                dispatch({
                    type: PYMES_ADHERIDAS_A_GARANTIAS,
                    payload: response.data,
                    refreshPyme: true,
                    loadingPymes: false
                })
            })
            .catch((err) => {
                console.log(err)
                dispatch({
                    type: ERROR_OBTENER_PYME,
                    loadingPymes: false
                })
            });
    } catch (error) {
        dispatch({
            type: ERROR_OBTENER_PYME,
            loadingPymes: false
        })
    }
}

export const obtenerCuentaAdheridaGarantiaFetchInicio = (token) => async (dispatch) => {
    dispatch({
        type: LOADING_PYMES_INICIO,
        loadingPymesInicio: true
    })
    var entidad = "accounts"
    var fetch = "<fetch mapping='logical'>" +
        "<entity name='account'>" +
        "<attribute name='accountid'/>" +
        "<attribute name='name'/> " +
        "<attribute name='new_urlfotodeperfil'/> " +
        "<attribute name='createdon'/> " +
        "<attribute name='primarycontactid'/> " +
        "<attribute name='statuscode'/> " +
        "<attribute name='new_nmerodedocumento'/> " +
        "<attribute name='new_estadodelsocio'/> " +
        "<attribute name='new_personeria'/>" +
        "<attribute name='new_tiposocietario'/> " +
        "<attribute name='new_condicionimpositiva'/> " +
        "<attribute name='new_tipodedocumentoid'/> " +
        "<attribute name='new_contactofirmante'/> " +
        "<attribute name='emailaddress1'/> " +
        "<attribute name='telephone2'/> " +
        "<attribute name='address1_line1'/> " +
        "<attribute name='new_direccion1numero'/>" +
        "<attribute name='address1_name'/> " +
        "<attribute name='new_direccion1depto'/> " +
        "<attribute name='address1_postalcode'/> " +
        "<attribute name='address1_county'/> " +
        "<attribute name='new_localidad'/> " +
        "<attribute name='new_provincia'/> " +
        "<attribute name='new_pais'/> " +
        "<attribute name='new_actividadafip'/>" +
        "<attribute name='new_facturacionultimoanio'/> " +
        "<attribute name='new_fechadealta'/> " +
        "<attribute name='new_nrodeexpediente'/> " +
        "<attribute name='new_calificacion'/> " +
        "<attribute name='new_saldobrutogarantasvigentes'/> " +
        "<attribute name='new_cantidadsgrconlasqueopera'/> " +
        "<attribute name='new_firmante'/> " +
        "<attribute name='new_estadodeactividad'/> " +
        "<attribute name='new_contactodenotificaciones'/> " +
        "<attribute name='new_cantidadgarantiasvigentessepyme'/> " +
        "<attribute name='new_saldobrutogaratiasvigentessepyme'/> " +
        "<attribute name='new_montototalgaratiasotorgadassepyme'/> " +
        "<attribute name='new_cantidadtotalgaratiasotorgadassepyme'/> " +
        "<order attribute ='new_estadodelsocio' descending='false' />" +
        "<link-entity name='new_garantia' from='new_socioparticipe' to='accountid' link-type='inner' alias='ae'>" +
        "<attribute name='statuscode'/> " +
        "<attribute name='new_fechadenegociacion'/> " +
        "<attribute name='new_dictamendelaval'/> " +
        "<filter type='and'>" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "</filter>" +
        "<link-entity name='new_seriedeoperacinsindicada' from='new_seriedeoperacinsindicadaid' to='new_nmerodeserie' link-type='inner' alias='af'>" +
        "<filter type='and'>" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "</filter>" +
        "<attribute name='new_seriedeoperacinsindicadaid'/> " +
        "<attribute name='new_name'/> " +
        "<attribute name='new_sgrlider'/>" +
        "</link-entity>" +
        "</link-entity>" +
        "</entity>" +
        "</fetch>";
    try {
        await axios.post(`${UrlApi}api/consultafetch`,
            {
                entidad: entidad,
                fetch: fetch,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
            .then((response) => {
                dispatch({
                    type: PYMES_ADHERIDAS_INICIO,
                    payload: response.data,
                    refreshPyme: true,
                    loadingPymesInicio: false
                })
            })
            .catch((err) => {
                console.log(err)
                dispatch({
                    type: ERROR_OBTENER_PYME_INICIO,
                    loadingPymesInicio: false
                })
            });
    } catch (error) {
        dispatch({
            type: ERROR_OBTENER_PYME_INICIO,
            loadingPymesInicio: false
        })
    }
}


export const obtenerPaises = (token) => async (dispatch) => {
    dispatch({
        type: LOADING
    })

    if (token) {
        var entidad = "new_paises"
        var fetch = "<fetch mapping='logical'>" +
            "<entity name='new_pais'>" +
            "<attribute name='new_paisid'/>" +
            "<attribute name='new_name'/> " +
            "<attribute name='createdon'/> " +
            "</entity>" +
            "</fetch>";

        try {
            await axios.post(`${UrlApi}api/consultafetch`,
                {
                    entidad: entidad,
                    fetch: fetch,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
                .then((response) => {
                    dispatch({
                        type: PAISES_FETCH_EXITO,
                        payload: response.data
                    })
                })
                .catch((err) => {
                    console.log(err)
                    dispatch({
                        type: ERROR
                    })
                });
        }
        catch (error) {
            dispatch({
                type: ERROR
            })
        }
    }
}

export const obtenerCuentasPorSGR = (token, accountid) => async (dispatch) => {
    dispatch({
        type: CUENTAS_POR_SGR,
        resultado: "LOADING"
    })

    var entidad = "new_cuentasporsgrs"
    var fetch = "<fetch mapping='logical'>" +
        "<entity name='new_cuentasporsgr'>" +
        "<attribute name='new_sgr'/>" +
        "<attribute name='createdon'/> " +
        "<attribute name='new_rol'/> " +
        "<attribute name='new_cuentasporsgrid'/>" +
        "<attribute name='new_saldobrutogaratiasvigentes'/> " +
        "<attribute name='new_saldodeudaporgtiasabonada'/> " +
        "<attribute name='new_cantidadgtiasenmora'/>" +
        "<attribute name='new_situaciondeladueda'/> " +
        "<attribute name='new_diasdeatraso'/> " +
        "<attribute name='new_socio'/> " +
        "<filter type='and'>" +
        "<condition attribute='new_socio' operator='eq' value='" + accountid + "' />" +
        "</filter>" +
        "</entity>" +
        "</fetch>";

    try {
        await axios.post(`${UrlApi}api/consultafetch`,
            {
                entidad: entidad,
                fetch: fetch,
            },
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        )
            .then((response) => {
                dispatch({
                    type: CUENTAS_POR_SGR,
                    payload: response.data,
                    resultado: "EXITO"
                })
            })
            .catch((err) => {
                console.log(err)
                dispatch({
                    type: CUENTAS_POR_SGR,
                    resultado: "ERROR"
                })
            });
    }

    catch (error) {
        dispatch({
            type: CUENTAS_POR_SGR,
            resultado: "ERROR"
        })
    }
}

export const actualizarDatosAlyc = (accountid, estadoSocio = null, actividad = null, opera = null, montoEstimado = null, proposito = null,
    otros = null, metodoEmision = null, fechaContrato = null, fechaInscripcion = null, numeroInscripcion = null) => async (dispatch) => {
        dispatch({
            type: LOADING_ACTUALIZACION,
            actualizacionDatosAlyc: 'LOADING',
            actualizacionDatos: 'PENDING'
        })

        let socio = null;
        let operaXcuenta = null;
        let metodo = null;

        if (opera !== null) {
            operaXcuenta = opera.value;
        }

        if (estadoSocio !== null) {
            socio = estadoSocio.value;
        }

        if (metodoEmision !== null) {
            metodo = metodoEmision.value;
        }

        if (fechaContrato === '') {
            fechaContrato = null
        }

        try {
            const response = await axios.post(`${UrlApiDynamics}Cuentaalyc?accountId=${accountid}&estadoSocio=${socio}
            &actividad=${actividad}&opera=${operaXcuenta}&montoEstimado=${montoEstimado}&proposito=${proposito}&otros=${otros}
            &metodoEmision=${metodo}&fechaContrato=${fechaContrato}&fechaInscripcion=${fechaInscripcion}
            &numeroInscripcion=${numeroInscripcion}&cuit=${Entidad}`)
            dispatch({
                type: ACTUALIZAR_CUENTAALYC_EXITO,
                payload: response.data,
                actualizacionDatosAlyc: 'EXITO'
            })
        } catch (error) {
            dispatch({
                type: ERROR_ACTUALIZACION_ALYC,
                actualizacionDatosAlyc: 'ERROR'
            })
        }
    }

export const obtenerCertificadoPyme = (accountid) => async (dispatch) => {
    // dispatch({
    //     type: LOADING
    // })

    try {
        if (accountid !== undefined) {
            const response = await axios.get(`${UrlApiDynamics}Certificadopyme?filter=_new_socioparticipe_value eq ${accountid}&cuit=${Entidad}`)
            dispatch({
                type: CERTIFICADO_EXITO,
                payload: response.data
            })
        }
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}

export const obtenerContactoDeNotificaciones = (accountid) => async (dispatch) => {
    dispatch({
        type: LOADING
    })

    var entidad = "contacts"
    var fetch = "<fetch mapping='logical'>" +
        "<entity name='contact'>" +
        "<attribute name='fullname'/>" +
        "<attribute name='telephone1'/> " +
        "<attribute name='contactid'/> " +
        "<link-entity name='new_participacionaccionaria' from='new_cuentacontactovinculado' to='contactid' link-type='inner' alias='aa'>" +
        "<attribute name='new_tipoderelacion'/> " +
        "<filter type='and'>" +
        "<condition attribute='new_cuentaid' operator='eq' value='" + accountid + "' />" +
        "</filter>" +
        "</link-entity>" +
        "</entity>" +
        "</fetch>";

    try {
        const response = await axios.get(`${UrlApiDynamics}ConsultaFetch?Entidad=${entidad}&fetch=${fetch}&cuit=${Entidad}`)
        dispatch({
            type: CONTACTOS_NOTI_FETCH_EXITO,
            payload: response.data
        })
    }

    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}
export const obtenerCuenta = (accountid, token) => async (dispatch) => {
    dispatch({
        type: LOADING
    })
    try {
        var entidad = "accounts"
        var fetch = "<fetch mapping='logical'>" +
            "<entity name='account'>" +
            "<attribute name='name'/> " +
            "<attribute name='accountid'/> " +
            "<attribute name='emailaddress1'/>" +
            "<attribute name='telephone2'/>" +
            "<attribute name='address1_line1'/>" +
            "<attribute name='new_direccion1numero'/>" +
            "<attribute name='address1_name'/>" +
            "<attribute name='new_direccion1depto'/>" +
            "<attribute name='address1_postalcode'/>" +
            "<attribute name='address1_county'/>" +
            "<attribute name='new_localidad'/>" +
            "<attribute name='new_nmerodedocumento'/>" +
            "<attribute name='new_provincia'/>" +
            "<attribute name='new_inscripcionganancias'/>" +
            "<attribute name='new_contactodenotificaciones'/>" +
            "<attribute name='new_pais'/>" +
            "<attribute name='new_condiciondeinscripcionanteafip'/>" +
            "<attribute name='new_estadodelsocio'/>" +
            "<order attribute ='name' descending='false' />" +
            "<filter type='and'>" +
            "<condition attribute='accountid' operator='eq' value='" + accountid + "' />" +
            "<condition attribute='statecode' operator='eq' value='0' />" +
            "</filter>" +
            "</entity>" +
            "</fetch>";

        if (accountid !== undefined) {
            await axios.post(`${UrlApi}api/consultafetch`,
                {
                    "entidad": entidad,
                    "fetch": fetch
                },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then((response) => {
                    console.log("response", response)
                    dispatch({
                        type: CUENTA_EXITO,
                        payload: response.data[0]
                    })
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }
    catch (error) {
        dispatch({
            type: ERROR
        })
    }
}

export const obtenerComprobantesDeVenta = (accountid, token) => async (dispatch) => {
    dispatch({
        type: LOADING_COMPROBANTE,
        loadingComprobante: true
    })


    var entidad = "new_comprobantedeventas"
    var fetch = "<fetch mapping='logical'>" +
        "<entity name='new_comprobantedeventa'>" +
        "<attribute name='new_comprobantedeventaid'/>" +
        "<attribute name='new_name'/> " +
        "<attribute name='createdon'/>" +
        "<attribute name='new_total'/> " +
        "<attribute name='new_nrocomprobante'/>" +
        "<attribute name='new_tipodecomprobante'/> " +
        // "<attribute name='new_urlafip'/> " +
        "<filter type='and'>" +
        "<condition attribute='new_cliente' operator='eq' value='" + accountid + "' />" +
        "</filter>" +
        "<link-entity name='annotation' from='objectid' to='new_comprobantedeventaid' link-type='outer' alias='nota'>" +
        "<attribute name='annotationid'/> " +
        "<attribute name='documentbody'/>" +
        "<attribute name='filename'/> " +
        "<attribute name='mimetype'/>" +
        "</link-entity>" +
        "</entity>" +
        "</fetch>";

    axios.post(`https://hw365api.azurewebsites.net/api/consultafetch`,
        {
            "entidad": entidad,
            "fetch": fetch
        },
        {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        })
        .then((response) => {
            dispatch({
                type: COMPROBANTE_VENTA_EXITO,
                payload: response.data,
                loadingComprobante: false
            })
        })
        .catch(err => {
            console.log(err)
        })
}

export const obtenerSociedadesXsocio = (accountid, token) => async (dispatch) => {
    dispatch({
        type: LOADING_SOCIEDADES,
        loadingSociedades: true
    })

    try {

        var entidad = "new_sociedaddebolsaporsocios"
        var fetch = "<fetch mapping='logical'>" +
            "<entity name='new_sociedaddebolsaporsocio'>" +
            "<attribute name='new_cuentacomitente' />" +
            "<attribute name='new_sociedaddebolsaporsocioid' />" +
            "<attribute name='new_socio' />" +
            "<attribute name='new_sociedaddebolsa' />" +
            "<attribute name='new_name' />" +
            "<attribute name='statecode' />" +
            "<order attribute='new_cuentacomitente' descending='false' />" +
            "<filter type='and'>" +
            "<condition attribute='statecode' operator='eq' value='0' />" +
            "<condition attribute='new_socio' operator='eq' uitype='account' value='" + accountid + "' />" +
            "</filter>" +
            "</entity>" +
            "</fetch>";

        if (accountid !== undefined) {
            await axios.post(`${UrlApi}api/consultafetch`,
                {
                    "entidad": entidad,
                    "fetch": fetch
                },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then((response) => {
                    dispatch({
                        type: SOCIEDADXSOCIO_EXITO,
                        payload: response.data,
                        loadingSociedades: false
                    })
                })
                .catch(err => {
                    console.log(err)
                })
        }
    }
    catch (error) {
        dispatch({
            type: ERROR,
            loadingSociedades: false
        })
    }
}

export const inactivarSociedadBolsa = (id, token) => async (dispatch) => {
    dispatch({
        type: LOADING_INACTIVACION,
        inactivarCuentaSocioMensaje: 'LOADING'
    })

    try {

        const eliminarSociedadBolsa = () => new Promise(async (resolve, reject) => {
            await axios.delete(`${UrlApi}api/socioparticipe/sociedaddebolsa?new_sociedaddebolsaporsocioid=${id}`,
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                    }
                }
            )
                .then((response) => {
                    dispatch({
                        type: INACTIVAR_CUENTA_EXITO,
                        inactivarCuentaSocioMensaje: 'EXITO'
                    })
                    resolve(response.data)
                })
                .catch(err => {
                    console.log(err)
                    reject(err)
                })


        })

        const respuesta = await toast.promise(
            eliminarSociedadBolsa,
            {
                pending: 'Cargando...',
                success: 'Sociedad de bolsa eliminada con éxito! 👍',
                error: {
                    render({ data }) {
                        return `${data}`
                    }
                }
            },
            {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
        )
    } catch (error) {
        dispatch({
            type: ERROR,
            inactivarCuentaSocioMensaje: 'ERROR'
        })

    }
}

export const CrearSociedadBolsaSocio = (accountid, datos, token) => async (dispatch) => {
    dispatch({
        type: LOADING_CUENTA_SOCIEDAD_SOCIO,
        crearCuentaSociedad: 'LOADING'
    })

    try {

        const crearSociedadBolsa = () => new Promise(async (resolve, reject) => {
            const { cuentaComitente, sociedades } = datos
            await axios.post(`${UrlApi}api/socioparticipe/sociedaddebolsa`,
                {
                    "new_sociedaddebolsaporsocioid": "",
                    "new_socio": accountid,
                    "new_sociedaddebolsa": sociedades.value,
                    "new_cuentacomitente": cuentaComitente
                },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }
            )
                .then((response) => {
                    // console.log(response)
                    dispatch({
                        type: CUENTA_SOCIEDAD_SOCIO_EXITO,
                        crearCuentaSociedad: 'EXITO'
                    })
                    resolve(response.data)
                })
                .catch(err => {
                    reject(err)
                })


        })

        const response = await toast.promise(
            crearSociedadBolsa,
            {
                pending: 'Procesando...',
                success: 'Sociedad de bolsa creada con éxito!',
                error: {
                    render({ data }) {
                        dispatch({
                            type: ERROR_CUENTA_SOCIEDAD_SOCIO,
                            crearCuentaSociedad: 'ERROR'
                        })
                        return `${data}`
                    }
                }
            },
            {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
        )

    } catch (error) {
        dispatch({
            type: ERROR_CUENTA_SOCIEDAD_SOCIO,
            crearCuentaSociedad: 'ERROR'
        })
    }
}

export const ModificarSociedadBolsaSocio = (id, accountid, datos, token) => async (dispatch) => {
    dispatch({
        type: LOADING_MOD_SOCIEDAD,
        modificarSociedadBolsa: 'LOADING'
    })

    try {

        const editarSociedadBolsa = () => new Promise(async (resolve, reject) => {
            const { cuentaComitente, sociedades } = datos
            await axios.put(`${UrlApi}api/socioparticipe/sociedaddebolsa`,
                {
                    "new_sociedaddebolsaporsocioid": id,
                    "new_socio": accountid,
                    "new_sociedaddebolsa": sociedades.value,
                    "new_cuentacomitente": cuentaComitente
                },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                }
            )
                .then((response) => {
                    console.log(response)
                    dispatch({
                        type: MODIFICAR_SOCIEDAD_EXITO,
                        modificarSociedadBolsa: 'EXITO'
                    })
                    resolve(response.data)
                })
                .catch(err => {
                    reject(err)
                })


        })

        const response = await toast.promise(
            editarSociedadBolsa,
            {
                pending: 'Procesando...',
                success: 'Sociedad de bolsa editada con éxito!',
                error: {
                    render({ data }) {
                        return `${data}`
                    }
                }
            },
            {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            }
        )

    } catch (error) {
        dispatch({
            type: ERROR_MOD_CUENTA_SOCIEDAD_SOCIO,
            modificarSociedadBolsa: 'ERROR'
        })
    }
}

export const obtenerSociedadeDeBolsa = (token) => async (dispatch) => {
    dispatch({
        type: LOADING_SOCIEDADES_BOLSA,
        loadingSociedadesDeBolsa: true
    })

    try {
        var entidad = "new_sociedaddebolsas"
        var fetch = "<fetch mapping='logical'>" +
            "<entity name='new_sociedaddebolsa'>" +
            "<attribute name='new_sociedaddebolsaid' />" +
            "<attribute name='new_name' />" +
            "<order attribute='new_name' descending='false' />" +
            "</entity>" +
            "</fetch>";

        await axios.post(`${UrlApi}api/consultafetch`,
            {
                "entidad": entidad,
                "fetch": fetch
            },
            {
                headers: {
                    "Authorization": `Bearer ${token}`
                }
            })
            .then((response) => {
                dispatch({
                    type: SOCIEDADBOLSA_EXITO,
                    payload: response.data,
                    loadingSociedadesDeBolsa: false
                })
            })
            .catch(err => {
                dispatch({
                    type: ERROR_SOCIEDAD_BOLSA,
                    loadingSociedadesDeBolsa: false
                })
            })
    }
    catch (error) {
        dispatch({
            type: ERROR_SOCIEDAD_BOLSA,
            loadingSociedadesDeBolsa: false
        })
    }
}