import React from "react";
import { Paper, Typography } from "@mui/material"

export const COLUMNSSOCIE = [
    {
        header: "Sociedad de Bolsa",
        accessorKey: "new_sociedaddebolsa",
        Cell: ({ cell }) => (
          <Typography sx={{ fontSize: {xs:".7rem", md:".8rem"}  }}>
            {cell.getValue()}
          </Typography>
        ),
    },
    {
        header: "Cuenta Comitente",
        accessorKey: "new_cuentacomitente",
        Cell: ({ cell }) => (
          <Typography sx={{ fontSize: {xs:".7rem", md:".8rem"}  }}>
            {cell.getValue()}
          </Typography>
        ),
    },
]