import React from "react";
import { Typography } from "@mui/material"
import DescargarDocumento from "../Components/seleccionarFila/DescargarDocumento";
import DescargarNota from "../Components/seleccionarFila/DescargarNota";
import DescargarNotaTemplate from "../Components/seleccionarFila/DescargarNotaTemplate";

export const COLUMNSDOCUMENTOS = [
  {
    header: "Garantia",
    accessorKey: "garantia",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontWeight: 600, fontSize: { xs: ".7rem", md: ".8rem" } }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: "Documento",
    accessorKey: "filename",
    Cell: ({ cell }) => (
      <Typography color="primary" sx={{ fontWeight: 600, fontSize: { xs: ".7rem", md: ".8rem" } }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: "Tipo de Documento",
    minSize: 80, //min size enforced during resizing
    maxSize: 120, //max size enforced during resizing
    accessorKey: "type",
    Cell: ({ cell }) => (
      <Typography color="primary" sx={{ fontWeight: 600, fontSize: { xs: ".7rem", md: ".8rem" } }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: "Descargar",
    minSize: 60, //min size enforced during resizing
    maxSize: 80, //max size enforced during resizing
    accessorKey: "nota",
    // accessorKey: "annotationid",
    Cell: ({ cell }) => {
        return <DescargarNotaTemplate value={cell.getValue()} />
      // return <DescargarDocumento value={cell.getValue()} />
    }
  },
]

export const COLUMNSDOCUMENTOSDESEMBOLSOS = [
  {
    header: "Garantia",
    accessorKey: "garantia",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontWeight: 600, fontSize: { xs: ".7rem", md: ".8rem" } }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: "Documento",
    accessorKey: "filename",
    Cell: ({ cell }) => (
      <Typography color="primary" sx={{ fontWeight: 600, fontSize: { xs: ".7rem", md: ".8rem" } }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: "Tipo de Documento",
    minSize: 80, //min size enforced during resizing
    maxSize: 120, //max size enforced during resizing
    accessorKey: "type",
    Cell: ({ cell }) => (
      <Typography color="primary" sx={{ fontWeight: 600, fontSize: { xs: ".7rem", md: ".8rem" } }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: "Descargar",
    minSize: 60, //min size enforced during resizing
    maxSize: 80, //max size enforced during resizing
    // accessorKey: "nota",
    accessorKey: "annotationid",
    Cell: ({ cell }) => {
        return <DescargarNota value={cell.getValue()} />
    }
  },
]

export const COLUMNASDOCUMENTOSGARANTIA = [
  {
    accessorKey: 'id',
  },
  {
    header: 'Archivo',
    accessorKey: 'filename',
    Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
  },
]