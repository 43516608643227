import { useState } from "react"
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import {TextField, CircularProgress, Grid, Box, Typography} from '@mui/material/'
import logo from '../Imagenes/casfog-logo.png'
import FmdBadIcon from '@mui/icons-material/FmdBad'
import fondo from "../img/fondoMisSGR.png"
import Container from '@mui/material/Container'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, Link } from "react-router-dom"
import { recuperarContraseña } from '../Redux/Usuarios'
import { toast } from "react-toastify"
import ReCAPTCHA from "react-google-recaptcha"
import { RecaptchaKey } from "../Keys"

const RecuperarContraseña = () => {

    const dispatch = useDispatch()

    const [robot, setRobot] = useState(false)
    const resultadoRecupero = useSelector(store => store.usuarios.resultadoRecupero)

    const schema = yup.object().shape({
        correo:yup.string().email('El correo electrónico no es válido').required('El correo electrónico es requerido'),
    })

    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema),
    });

    const onSubmitHandler = (data) => {
        if (!robot) {
            loadingError("Debes confirmar que no eres un robot!");
            return;
        }
        dispatch(recuperarContraseña(data.correo))
    };
    const loadingError = (msj) => {
        toast.error(msj, {
          position: "top-center",
          theme: "dark",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
    }

    const robotOnChange = (value) => {
        let valor = "";
        if (value) {
          valor = "True";
          setRobot(true);
        } else {
          valor = "False";
          setRobot(false);
        }
    }

  return (
    <Container 
        component="main" 
        maxWidth="xs"
        sx={{
            backgroundImage: `url(${fondo})`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: "cover",
            minWidth:"100vw",
            minHeight:"100vh",
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent:"center"
          }}
    >
        <Box 
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >
            <Avatar sx={{ m: 0, width: 90, height: 90 }}
                src={logo}
            >
            </Avatar>
            <Typography component="h1" variant="h5" sx={{ mb: 3, color:"#fff" }}>
                Recuperar contraseña
            </Typography>
            <form onSubmit={handleSubmit(onSubmitHandler)}>
                <Grid 
                    container 
                    spacing={2}
                    sx={{ maxWidth:"400px"}}
                    >
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            id="correo"
                            label={<Typography variant="subtitle2" sx={{fontWeight:600, color:"#fff"}}>Correo electrónico</Typography>}
                            {...register("correo")}
                            helperText={errors.correo?.message}
                            error={errors.correo}
                            autoFocus
                            sx={{ input: { color: '#fff' }, fieldset: { borderColor: "#fff" } }}
                            
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <ReCAPTCHA
                            size={12}
                            render="explicit"
                            sitekey={RecaptchaKey}
                            onChange={(e) => robotOnChange(e)}
                        />
                    </Grid>
                </Grid>
                <Box sx={{ mt: .5, position: 'relative' }}>
                    <Button
                        type="submit"
                        disabled={resultadoRecupero === 'LOADING'}
                        fullWidth
                        variant="contained"
                        sx={{ mt: 1, mb: 1 }}
                    >
                        Recuperar
                    </Button>
                    {resultadoRecupero === 'LOADING' && (
                    <CircularProgress
                        size={27}
                        sx={{
                            color: "#fff",
                            position: 'absolute',
                            top: '60%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                        }}
                        />
                        )}
                </Box>
                <Grid 
                    item xs={12}
                    sx={{display:"flex", flexDirection:"row", justifyContent:"center", alignItems:"center"}}>
                    <FmdBadIcon fontSize="small" sx={{color:"orange"}}/>
                    <Typography variant="subtitle2" color="inherit">Revise el buzón de correo no deseado </Typography>
                </Grid>
            </form>
            <Box sx={{ mt: 1 }}>
                <Grid container justifyContent="flex-start">
                    <Grid item>
                        <Link to="/IniciarSesion" style={{ textDecoration: 'none', color: '#fff' }}>
                            <Typography variant="subtitle2" sx={{color:"#fff"}}>
                                Regresar a Iniciar Sesion
                            </Typography>
                        </Link>
                    </Grid>
                </Grid>
            </Box>
        </Box>
</Container>
  )
}

export default RecuperarContraseña