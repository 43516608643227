import axios from 'axios'
import { UrlApi } from '../Keys'
import { toast } from 'react-toastify'

const dataInicial = {
    loading: false,
    loadingCarga: false,
    documentos: [],
    resultadoCarga: '',
    retrieveDocsExito: false
}

const LOADING_DOCUMENTOPORSERIE = 'LOADING_DOCUMENTOPORSERIE'
const LOADING_CARGADOCUMENTOPORSERIE = 'LOADING_CARGADOCUMENTOPORSERIE'
const DOCUMENTOXSERIE_EXITO = 'DOCUMENTOXSERIE_EXITO'
const CARGA_DOCUMENTOXSERIE_EXITO = 'CARGA_DOCUMENTOXSERIE_EXITO'
const ERROR_DOCUMENTOPORSERIE = 'ERROR_DOCUMENTOPORSERIE'
const ERROR_CARGA_DOCUMENTOPORSERIE = 'ERROR_CARGA_DOCUMENTOPORSERIE'

const ToastError = (msj) => {
    toast.error(msj, {
        theme: "dark",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    });
};

const ToastSuccess = (msj) => {
    toast.success(msj, {
        theme: "dark",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
    })
}

//Reducers
export default function documentosPorSerieReducers(state = dataInicial, action) {
    switch (action.type) {
        case LOADING_DOCUMENTOPORSERIE:
            return { ...state, loading: true, retrieveDocsExito: false }
        case LOADING_CARGADOCUMENTOPORSERIE:
            return { ...state, loadingCarga: true, resultadoCarga: 'LOADING' }
        case DOCUMENTOXSERIE_EXITO:
            return { ...state, documentos: action.payload, loading: false, retrieveDocsExito: true }
        case CARGA_DOCUMENTOXSERIE_EXITO:
            return { ...state, resultadoCarga: 'EXITO', loadingCarga: false }
        case ERROR_DOCUMENTOPORSERIE:
            return { ...dataInicial, documentos: [], loading: false, retrieveDocsExito: false }
        case ERROR_CARGA_DOCUMENTOPORSERIE:
            return { ...state, resultadoCarga: 'ERROR', loadingCarga: false }
        default:
            return { ...state }
    }
}

//Actions
export const obtenerDocumentosPorSerie = (token, idSerie) => async (dispatch) => {
    dispatch({
        type: LOADING_DOCUMENTOPORSERIE
    })

    try {
        var entidad = "new_documentacionporseries"
        var fetch = "<fetch mapping='logical'>" +
            "<entity name='new_documentacionporserie'>" +
            "<attribute name='new_documentacionporserieid'/> " +
            "<attribute name='new_name'/>" +
            "<attribute name='createdon'/> " +
            "<attribute name='new_serie'/> " +
            "<attribute name='statuscode'/> " +
            "<attribute name='new_fechadevencimiento'/> " +
            "<attribute name='new_documento'/> " +
            "<filter type='and'>" +
            "<condition attribute='statecode' operator='eq' value='0' />" +
            `<condition attribute='new_serie' operator='eq' value='${idSerie}' />` +
            "</filter>" +
            "<link-entity name='annotation' from='objectid' to='new_documentacionporserieid' link-type='outer' alias='nota'>" +
            "<attribute name='annotationid'/> " +
            "<attribute name='filename'/> " +
            "</link-entity>" +
            "</entity>" +
            "</fetch>";

        return new Promise((resolve, reject) => {
            axios.post(`${UrlApi}api/consultafetch`,
                {
                    "entidad": entidad,
                    "fetch": fetch
                },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then((response) => {
                    resolve(response.data)
                })
                .catch(err => {
                    ToastError("Error al buscar documentos")
                    reject(err)
                })
        })
    }
    catch (error) {
        ToastError("Error al descargar archivo")
        dispatch({
            type: ERROR_DOCUMENTOPORSERIE
        })
    }
}

//MOSTRAR EN DOCUMENTACION POR SERIE
export const obtenerTodasDocumentaciones = (token) => async () => {
    var entidad = "new_documentacions"
    var fetch = "<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='false'>" +
        "<entity name='new_documentacion'>" +
        "<attribute name='new_documentacionid' />" +
        "<attribute name='new_name' />" +
        "<attribute name='createdon' />" +
        "<order attribute='new_name' descending='false' />" +
        "<filter type='and'>" +
        "<condition attribute='statecode' operator='eq' value='0' />" +
        "<condition attribute='statuscode' operator='eq' value='1' />" +
        "<condition attribute='new_mostrarendocumentacionporserie' operator='eq' value='true' />" +
        "</filter>" +
        "</entity>" +
        "</fetch>";

    try {
        return new Promise((resolve, reject) => {
            axios.post(`${UrlApi}api/consultafetch`,
                {
                    "entidad": entidad,
                    "fetch": fetch
                },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then((response) => {
                    resolve(response.data)
                })
                .catch(err => {
                    ToastError("Error al buscar documentos")
                    reject(err)
                })
        })
    }
    catch (error) {
        ToastError("Error al buscar documentos")
    }
}

export const cargarDocumentacionPorSerie = (token, formData, serie_id, documentoId, fechaVencimiento) => async (dispatch) => {
    dispatch({
        type: LOADING_CARGADOCUMENTOPORSERIE,
    })

    try {

        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "multipart/form-data",
            },
        }
        debugger
        await axios.post(`${UrlApi}api/portalcasfog/adjuntosserie?serie_id=${serie_id}&documento_id=${documentoId}&fechaVencimiento=${fechaVencimiento}`, formData, config)
            .then(() => {
                dispatch({
                    type: CARGA_DOCUMENTOXSERIE_EXITO,
                })
                ToastSuccess('La carga del archivo fue exitosa!')
            })
            .catch((error) => {
                debugger
                dispatch({
                    type: ERROR_CARGA_DOCUMENTOPORSERIE,
                })
                ToastError(`La carga del archivo fallo! ${error}`)
            });
    } catch (error) {
        dispatch({
            type: ERROR_CARGA_DOCUMENTOPORSERIE,
        })
        ToastError('La carga del archivo fallo!')
    }
}
