import React from "react"
import { Chip, Typography } from "@mui/material"
import Moment from 'moment'

export const COLUMNSCERTIPYMES = (tema) => [
    {
        header: "Nro. de Registro",
        accessorKey: "new_numeroderegistro",
        Cell: ({ cell }) => (
          <Typography color="inherit" sx={{ fontWeight: 600, fontSize: ".9rem" }}>
            {cell.getValue()}
          </Typography>
        ),
    },
    {
        header: "Categoría",
        accessorKey: "new_categoria",
        Cell: ({ cell }) => (
          <Typography color="inherit" sx={{ fontWeight: 600, fontSize: ".9rem" }}>
            {cell.getValue()}
          </Typography>
        ),
    },
    {
        header: "Fecha de Inicio",
        accessorKey: "new_fechadeemision",
        Cell: ({ cell }) => (
          <Typography color="inherit" sx={{ fontWeight: 600, fontSize: ".9rem" }}>
            {cell.getValue() ? cell.getValue() : '-'}
          </Typography>
        ),
    },
    {
        header: "Vigencia Desde",
        accessorKey: "new_vigenciadesde",
        Cell: ({ cell }) => (
          <Typography color="inherit" sx={{ fontWeight: 600, fontSize: ".9rem" }}>
            {cell.getValue() ? cell.getValue() : '-'}
          </Typography>
        ),
    },
    {
        header: "Vigencia Hasta",
        accessorKey: "new_vigenciahasta",
        Cell: ({ cell }) => (
          <Typography color="inherit" sx={{ fontWeight: 600, fontSize: ".9rem" }}>
            {cell.getValue() ? cell.getValue() : '-'}
          </Typography>
        ),
    },
    {
        header: 'Estado',
        accessorKey: 'statuscode',
        Cell: ({ cell }) => {
            switch (cell.getValue()) {
                case 100000000:
                    return (
                      <Chip label="Análisis" sx={{
                        bgcolor: tema.palette.mode === "dark" ? 'rgba(255, 163, 25, 0.2)' : 'rgb(255, 163, 25, 0.8)',
                        color: tema.palette.mode === "dark" ? 'rgb(255, 163, 25)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
                      }} />
                      );
                case 1:
                    return (
                      <Chip label="Activo" sx={{
                        bgcolor: tema.palette.mode === "dark" ? 'rgba(87, 202, 34, 0.2)' : 'rgb(87, 202, 34, 0.8)',
                        color: tema.palette.mode === "dark" ? 'rgb(87, 202, 34)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
                      }} />
                    )
                case 100000001:
                    return (
                      <Chip label="Inicio" sx={{
                        bgcolor: tema.palette.mode === "dark" ? 'rgba(241, 241, 19, 0.2)' : 'rgb(241, 241, 19, 0.8)',
                        color: tema.palette.mode === "dark" ? 'rgb(241, 241, 19)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
                      }} />
                    )
                case 100000002:
                    return (
                      <Chip label="Rechazada" sx={{
                        bgcolor: tema.palette.mode === "dark" ? 'rgba(255, 163, 25, 0.2)' : 'rgb(255, 163, 25, 0.8)',
                        color: tema.palette.mode === "dark" ? 'rgb(255, 163, 25)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
                      }} />
                      );
                default:
                    return '---'
            }
        }
    },
]