import React, { useState, useEffect, useContext } from 'react'
import Typography from '@mui/material/Typography'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Box, Grid, Tab, Paper, useTheme } from '@mui/material'
import { MiSgr } from '../Components/MiSgr'
import { useDispatch, useSelector } from 'react-redux'
import Tabla from '../Components/Tabla'
import { COLUMNSPYMES } from '../Columnas/ColumnasPymes'
import ModalDatosPyme from '../Components/modales/ModalDatosPyme'
import { obtenerCuentaAdheridaGarantiaFetch, obtenerSocios } from '../Redux/Cuenta'
import ModalCarpetaDigital from '../Components/modales/ModalCarpetaDigital'
import { setModalDatosPymes } from '../Redux/AbrirModal'
import LoaderAnimado from '../Components/loaderAnimado/LoaderAnimado'
import TokenContext from '../context/TokenContext'

export const Pymes = ({ nameSGR, esPorSerie = false, idSerie }) => {
    const dispatch = useDispatch()
    const token = useContext(TokenContext)
    const theme = useTheme()
    //Selectores
    const seriesSelector = useSelector(store => store.series.series)
    const actualizacionDatos = useSelector(store => store.cuenta.resultadoActualizar)
    const socioIdSelector = useSelector(store => store.cuenta.cuentaIdSeleccionada)
    const cuentaConGarantiaSelector = useSelector(store => store.cuenta.cuentaConGarantia)
    const sociosSelector = useSelector(store => store.cuenta.socios)
    const setModalDatosPyme = useSelector(store => store.setModal.modalDatosPyme)
    const setModalCarpetaDigital = useSelector(store => store.setModal.modalDatosCarpeta)
    const resultadoRelaciones = useSelector(store => store.relaciones.CreacionDatos)
    const resultadoActivarPyme = useSelector(store => store.cuenta.resultadoActivarPyme)
    //Hooks
    const [pymes, setPymes] = useState([])
    const [value, setValue] = useState('0')
    const [logSelector, setLogSelector] = useState(true)
    const [log, setLog] = useState(true)
    const [logSeries, setLogSeries] = useState(true)
    const [idSGRLider, setIdSGRLider] = useState(JSON.parse(localStorage.getItem("Id SGRLider")))
    const [idSGR, setIdSGR] = useState(JSON.parse(localStorage.getItem('Id SGR')) ? JSON.parse(localStorage.getItem('Id SGR')) : "")
    const [documento, setDocumento] = useState([])
    //Hooks modales
    const [openModalDatosPyme, setOpenModalDatosPyme] = useState(false)
    const [openModalCarpetaDigital, setOpenModalCarpetaDigital] = useState(false)
    //Juan
    const [llamadaPymes, setLlamdaPymes] = React.useState(false)
    //Data Export
    const headerDataExp = ["Fecha de Alta", "Razón Social", "CUIT", "Estado del Socio"]
    const [dataEXP, setDataEXP] = React.useState([])
    const [dataEXPXserie, setDataEXPXserie] = React.useState([])

    useEffect(() => {
        setIdSGR(JSON.parse(localStorage.getItem('Id SGR')))
    }, [])

    useEffect(() => {
        if (token) {
            dispatch(obtenerCuentaAdheridaGarantiaFetch(token))
            dispatch(obtenerSocios(token))
            setLog(true)
        }
    }, [token])

    useEffect(() => {
        if (cuentaConGarantiaSelector?.length > 0) {
            debugger
            if (esPorSerie) {
                setearPymesXserie(cuentaConGarantiaSelector, idSerie)
            }
            setearPymes2(cuentaConGarantiaSelector, esPorSerie, idSerie)
            setLogSelector(false)
            setLogSeries(false)
        }
    }, [cuentaConGarantiaSelector, idSerie])

    useEffect(() => {
        if ((actualizacionDatos === "EXITO" || resultadoRelaciones === "EXITO") && idSGR && token) {
            setLog(true)
            setPymes([])
            setDocumento([])
            dispatch(obtenerCuentaAdheridaGarantiaFetch(token))
            setLogSeries(true)
            setTimeout(() => {
                dispatch(setModalDatosPymes(false))
            }, 2000)
        }
    }, [token, actualizacionDatos, resultadoRelaciones])

    useEffect(() => {
        if ((resultadoActivarPyme === "EXITO") && idSGR && token) {
            setLog(true)
            setPymes([])
            setDocumento([])
            dispatch(obtenerCuentaAdheridaGarantiaFetch(token))
            setLogSeries(true)
            setTimeout(() => {
                dispatch(setModalDatosPymes(false))
            }, 2000)
        }
    }, [resultadoActivarPyme])

    useEffect(() => {
        setDocumento([])
        if (setModalDatosPyme) {
            debugger
            let pymes = cuentaConGarantiaSelector.filter(item => item.accountid == socioIdSelector)
            setDocumento(cuentaConGarantiaSelector.filter(item => item.accountid == socioIdSelector))
        }
    }, [socioIdSelector, setModalDatosPyme, cuentaConGarantiaSelector])

    const setearPymes2 = (pymesF, esPorSerie, idSerie) => {
        var pymesT = []
        let pymesED = []
        if (esPorSerie) {
            let pymesUnicas = [...new Map(pymesF.filter(item => item["af.new_seriedeoperacinsindicadaid"] == idSerie).map(item => [item["accountid"], item])).values()]
            pymesUnicas.forEach(item => {
                var pyme = {
                    new_urlfotodeperfil: item['new_urlfotodeperfil'],
                    nombre: item["name"],
                    fechaAlta: item["createdon"],
                    fechaCreacion: new Date(item["createdon"]),
                    cuit: item["new_nmerodedocumento"],
                    estado: item["new_estadodelsocio"],
                    descripcionEstado: item["new_estadodelsocio@OData.Community.Display.V1.FormattedValue"],
                    seriedeoperacinsindicadaid: item['af.new_seriedeoperacinsindicadaid'],
                    accountid: item['accountid'],
                    id: item['accountid']
                }
                var pymeED = {
                    fechaAlta: item["createdon"],
                    nombre: item["name"],
                    cuit: item["new_nmerodedocumento"],
                    estado: item["new_estadodelsocio@OData.Community.Display.V1.FormattedValue"],
                    id: item['accountid']
                }
                pymesT.push(pyme)
                pymesED.push(pymeED)
            })
            setDataEXP(pymesED)
            pymesT.sort(function (a, b) {
                return new Date(b.fechaCreacion) - new Date(a.fechaCreacion);
            });
            setPymes(pymesT)
        } else {
            let pymesUnicas = [...new Map(pymesF.map(item => [item["accountid"], item])).values()]
            pymesUnicas.forEach(item => {
                var pyme = {
                    new_urlfotodeperfil: item['new_urlfotodeperfil'],
                    nombre: item["name"],
                    fechaAlta: item["createdon"],
                    fechaCreacion: new Date(item["createdon"]),
                    cuit: item["new_nmerodedocumento"],
                    estado: item["new_estadodelsocio"],
                    descripcionEstado: item["new_estadodelsocio@OData.Community.Display.V1.FormattedValue"],
                    seriedeoperacinsindicadaid: item['af.new_seriedeoperacinsindicadaid'],
                    accountid: item['accountid'],
                    id: item['accountid']
                }
                var pymeED = {
                    fechaAlta: item["createdon"],
                    nombre: item["name"],
                    cuit: item["new_nmerodedocumento"],
                    estado: item["new_estadodelsocio@OData.Community.Display.V1.FormattedValue"],
                    id: item['accountid']
                }
                pymesT.push(pyme)
                pymesED.push(pymeED)
            })
            setDataEXP(pymesED)
            pymesT.sort(function (a, b) {
                return new Date(b.fechaCreacion) - new Date(a.fechaCreacion);
            });
            setPymes(pymesT)
        }

        setLog(false)
    }

    const setearPymesXserie = (pymesF, idserie) => {
        if (pymesF?.length > 0) {
            let pymesED = []
            let pymesUnicas = [...new Map(pymesF.map(item => [item["accountid"], item])).values()]
            pymesUnicas?.filter(item => item['af.new_seriedeoperacinsindicadaid'] === idserie)?.forEach(item => {
                var pymeED = {
                    fechaAlta: item["createdon"],
                    nombre: item["name"],
                    cuit: item["new_nmerodedocumento"],
                    estado: item["new_estadodelsocio@OData.Community.Display.V1.FormattedValue"],
                    id: item['accountid']
                }
                pymesED.push(pymeED)
            })
            setDataEXPXserie(pymesED)
        }
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <>
            <Box component="main" sx={{ flexGrow: 1, p: 3, py: 0 }}>
                {/* <Fade> */}
                {!esPorSerie && <MiSgr nameSGR={nameSGR} />}
                {!esPorSerie && <Grid xs={12}>
                    <Typography variant="h5" sx={{ fontFamily: theme.typography.fontFamily, color: theme.typography.primary.main }}>
                        Mis Pymes
                    </Typography>
                </Grid>}
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, mb: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="basic tabs example" flexContainer="true" scroller="true" variant="scrollable" indicator>
                            {/* <Tab label="Pymes en Estado Inicial" sx={{ fontSize: { xs: ".7rem", md: ".9rem" } }} value="0" /> */}
                            <Tab label="Pymes" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="0" />
                            <Tab label="Pymes en Estado Análisis de Riesgo" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="1" />
                            <Tab label="Pymes en Estado Alta Pendiente" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="2" />
                            <Tab label="Pymes en Estado Pendiente de Firmas" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="3" />
                            <Tab label="Pymes en Estado Activo" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem" }, fontWeight: '500' }} value="4" />
                        </TabList>
                    </Box>
                    <TabPanel value="0" sx={{ p: "0px" }}>
                        <Paper elevation={3}>
                            {log ? <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", p: 4 }}>
                                <LoaderAnimado />
                            </Box>
                                :
                                esPorSerie ?
                                    <Tabla
                                        columnas={COLUMNSPYMES(theme)}
                                        datos={pymes.filter(item => item.seriedeoperacinsindicadaid == idSerie)}
                                        exportData={true}
                                        dataExp={dataEXPXserie}
                                        headerDataExp={headerDataExp}
                                        nombreArchivo="Pymes"
                                    />
                                    :
                                    <Tabla
                                        columnas={COLUMNSPYMES(theme)}
                                        datos={pymes}
                                        exportData={true}
                                        dataExp={dataEXP}
                                        headerDataExp={headerDataExp}
                                        nombreArchivo="Pymes"
                                    />
                            }
                        </Paper>
                    </TabPanel>
                    <TabPanel value="1" sx={{ p: "0px" }}>
                        <Paper elevation={3}>
                            {log ? <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", p: 4 }}>
                                <LoaderAnimado />
                            </Box>
                                :
                                esPorSerie ?
                                    <Tabla
                                        columnas={COLUMNSPYMES(theme)}
                                        datos={pymes.filter(item => item.estado == 100000003 && item.seriedeoperacinsindicadaid == idSerie)}
                                        exportData={true}
                                        dataExp={dataEXPXserie.filter(item => item.estado == "En Análisis de Riesgo")}
                                        headerDataExp={headerDataExp}
                                        nombreArchivo="Pymes en Estado Análisis de Riesgo"
                                    />
                                    :
                                    <Tabla
                                        columnas={COLUMNSPYMES(theme)}
                                        datos={pymes.filter(item => item.estado == 100000003)}
                                        exportData={true}
                                        dataExp={dataEXP.filter(item => item.estado == "En Análisis de Riesgo")}
                                        headerDataExp={headerDataExp}
                                        nombreArchivo="Pymes en Estado Análisis de Riesgo"
                                    />
                            }
                        </Paper>
                    </TabPanel>
                    <TabPanel value="2" sx={{ p: "0px" }}>
                        <Paper elevation={3}>
                            {log ? <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", p: 4 }}>
                                <LoaderAnimado />
                            </Box>
                                :
                                esPorSerie ?
                                    <Tabla
                                        columnas={COLUMNSPYMES(theme)}
                                        datos={pymes.filter(item => item.estado == 100000001 && item.seriedeoperacinsindicadaid == idSerie)}
                                        exportData={true}
                                        dataExp={dataEXPXserie.filter(item => item.estado == "Alta Pendiente")}
                                        headerDataExp={headerDataExp}
                                        nombreArchivo="Pymes en Estado Alta Pendiente"
                                    />
                                    :
                                    <Tabla
                                        columnas={COLUMNSPYMES(theme)}
                                        datos={pymes.filter(item => item.estado == 100000001)}
                                        exportData={true}
                                        dataExp={dataEXP.filter(item => item.estado == "Alta Pendiente")}
                                        headerDataExp={headerDataExp}
                                        nombreArchivo="Pymes en Estado Alta Pendiente"
                                    />
                            }
                        </Paper>
                    </TabPanel>
                    <TabPanel value="3" sx={{ p: "0px" }}>
                        <Paper elevation={3}>
                            {log ? <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", p: 4 }}>
                                <LoaderAnimado />
                            </Box>
                                :
                                esPorSerie ?
                                    <Tabla
                                        columnas={COLUMNSPYMES(theme)}
                                        datos={pymes.filter(item => item.estado == 100000006 && item.seriedeoperacinsindicadaid == idSerie)}
                                        exportData={true}
                                        dataExp={dataEXPXserie.filter(item => item.estado == "Pendiente de firma")}
                                        headerDataExp={headerDataExp}
                                        nombreArchivo="Pymes en Estado Pendiente de Firmas"
                                    />
                                    :
                                    <Tabla
                                        columnas={COLUMNSPYMES(theme)}
                                        datos={pymes.filter(item => item.estado == 100000006)}
                                        exportData={true}
                                        dataExp={dataEXP.filter(item => item.estado == "Pendiente de firma")}
                                        headerDataExp={headerDataExp}
                                        nombreArchivo="Pymes en Estado Pendiente de Firmas"
                                    />
                            }
                        </Paper>
                    </TabPanel>
                    <TabPanel value="4" sx={{ p: "0px" }}>
                        <Paper elevation={3}>
                            {log ? <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", p: 4 }}>
                                <LoaderAnimado />
                            </Box>
                                :
                                esPorSerie ?
                                    <Tabla
                                        columnas={COLUMNSPYMES(theme)}
                                        datos={pymes.filter(item => item.estado == 100000000 && item.seriedeoperacinsindicadaid == idSerie)}
                                        exportData={true}
                                        dataExp={dataEXPXserie.filter(item => item.estado == "Activo")}
                                        headerDataExp={headerDataExp}
                                        nombreArchivo="Pymes en Estado Activo"
                                    />
                                    :
                                    <Tabla
                                        columnas={COLUMNSPYMES(theme)}
                                        datos={pymes.filter(item => item.estado == 100000000)}
                                        exportData={true}
                                        dataExp={dataEXP.filter(item => item.estado == "Activo")}
                                        headerDataExp={headerDataExp}
                                        nombreArchivo="Pymes en Estado Activo"
                                    />
                            }
                        </Paper>
                    </TabPanel>
                </TabContext>
                {/* </Fade> */}
            </Box>
            {setModalCarpetaDigital &&
                <ModalCarpetaDigital
                    open={setModalCarpetaDigital}
                    setOpen={setOpenModalCarpetaDigital}
                    titulo="Carpeta Digital"
                />
            }
            {documento.length > 0 && setModalDatosPyme &&
                <ModalDatosPyme
                    open={setModalDatosPyme}
                    setOpen={setOpenModalDatosPyme}
                    id={socioIdSelector}
                    idSGRLider={idSGRLider}
                    idSGR={idSGR}
                    documentos={documento}
                    sociosSelector={sociosSelector}
                // sociosSelector={cuentaConGarantiaSelector}
                />
            }

        </>
    )
}