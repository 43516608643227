let dollarUS = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

export const COLUMNSCERTIFICADOS = [
    {
        header: 'Número de Registro',
        accessorKey: 'new_numeroderegistro',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Vigencia Desde',
        accessorKey: 'new_vigenciadesde',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Vigencia Hasta',
        accessorKey: 'new_vigenciahasta',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Razón para el estado',
        accessorKey: 'statuscode',
        Cell: ({ cell }) => {
            switch (cell.getValue()) {
                case "1":
                    return "Aprobado"
                case "100000000":
                    return "Analisis"
                case "100000001":
                    return "Inicio"
                case "100000002":
                    return "Reprobado"
                default:
                    return "Analisis"
            }
        }
    },
]