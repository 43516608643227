import axios from 'axios'

//Const
const dataInicial = {
    modalDatosPyme: false,
    modalDatosCarpeta: false,
    modalDatosGarantia: false,
    modalDocuDesembolsoAnterior: false
}

const ABRIR_MODAL_DATOS_PYME = 'ABRIR_MODAL_DATOS_PYME'
const ABRIR_MODAL_CARPETA_DIGTAL = 'ABRIR_MODAL_CARPETA_DIGTAL'
const ABRIR_MODAL_GARANTIA = 'ABRIR_MODAL_GARANTIA'
const ABRIR_MODAL_DOCU_DESEMBOLSO_ANTERIOR = 'ABRIR_MODAL_DOCU_DESEMBOLSO_ANTERIOR'

export default function abrirModal(state = dataInicial, action) {
    switch (action.type) {
        case ABRIR_MODAL_DATOS_PYME:
            return { ...dataInicial, modalDatosPyme: action.modalDatosPyme }
        case ABRIR_MODAL_CARPETA_DIGTAL:
            return { ...dataInicial, modalDatosCarpeta: action.modalDatosCarpeta }
        case ABRIR_MODAL_GARANTIA:
            return { ...dataInicial, modalDatosGarantia: action.modalDatosGarantia }
        case ABRIR_MODAL_DOCU_DESEMBOLSO_ANTERIOR:
            return { ...dataInicial, modalDocuDesembolsoAnterior: action.modalDocuDesembolsoAnterior }
        default:
            return { ...state }
    }
}

export const setModalDatosPymes = (value) => async (dispatch) => {
    if (value !== undefined) {
        dispatch({
            type: ABRIR_MODAL_DATOS_PYME,
            modalDatosPyme: value
        })
    }
}

export const setModalCarpetaDigital = (value) => async (dispatch) => {
    if (value !== undefined) {
        dispatch({
            type: ABRIR_MODAL_CARPETA_DIGTAL,
            modalDatosCarpeta: value
        })
    }
}

export const setModalGarantias = (value) => async (dispatch) => {
    if (value !== undefined) {
        dispatch({
            type: ABRIR_MODAL_GARANTIA,
            modalDatosGarantia: value
        })
    }
}

export const setModalDocumentosDesembolsoAnteriores = (value) => async (dispatch) => {
    if (value !== undefined) {
        dispatch({
            type: ABRIR_MODAL_DOCU_DESEMBOLSO_ANTERIOR,
            modalDocuDesembolsoAnterior: value
        })
    }
}