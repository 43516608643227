import React, { useState, useEffect, useContext } from "react";
import {
  Dialog, Box, useTheme, DialogContent, DialogContentText, Toolbar, Typography, IconButton, CircularProgress, AppBar, Tab,
  FormControl, TextField, Button, Tooltip, Paper, InputLabel, NativeSelect, Grid, Divider, Avatar
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import InfoIcon from '@mui/icons-material/Info'
import LockIcon from '@mui/icons-material/Lock'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { useDispatch, useSelector } from "react-redux"
import Tabla from "../Tabla"
import { actualizarDatosCuenta, obtenerCuentasPorSGR, obtenerPaises, obtenerProvincias, activarPyme } from "../../Redux/Cuenta"
import ProgressBarPyme from "../ProgressBarPyme"
import { FormProvider, useForm } from "react-hook-form"
import CustomTextField from "../CustomTextField"
import CustomSearchSelect from "../CustomSearchSelect"
import { COLUMNRELACIONES } from "../../Columnas/ColumnasRelaciones"
import { COLUMNSCUENTASGR } from "../../Columnas/ColumnasDatosCasfog"
import { COLUMNSCERTIPYMES } from "../../Columnas/ColumnasCertificadosPymes"
import { COLUMNSSOCIE } from "../../Columnas/ColumnasSociedades"
import { COLUMNSBANCOS } from "../../Columnas/ColumnasCuentasBancarias"
import { setModalDatosPymes } from "../../Redux/AbrirModal"
import SeleccionarFilaRelacion from "../seleccionarFila/SeleccionarFilaRelacion"
import TokenContext from '../../context/TokenContext'
import { green } from '@mui/material/colors';
import perfiRandom from '../../img/perfilRandom.jpg'

const ModalDatosPyme = ({ open, setOpen, id, documentos, sociosSelector, idSGRLider, idSGR }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const token = useContext(TokenContext)
  const provinciasSelector = useSelector(store => store.cuenta.provincias)
  const paisesSelector = useSelector(store => store.cuenta.paises)
  const socioIdSelector = useSelector(store => store.cuenta.cuentaIdSeleccionada)
  const cuentasPorSgrSelector = useSelector(store => store.cuenta.cuentasPorSgr)
  const resultadoCuestas = useSelector(store => store.cuenta.resultadoCuestas)
  const resultadoActualizar = useSelector(store => store.cuenta.resultadoActualizar)
  const loadingActivarPyme = useSelector(store => store.cuenta.loadingActivarPyme)

  const [value, setValue] = useState('0')
  const [contactoNotificaciones, setContactoNotificaciones] = useState([])
  const [socios, setSocios] = useState([])
  const [calificacion, setCalificacion] = useState({ value: documentos[0].new_calificacion, label: documentos[0]["new_calificacion@OData.Community.Display.V1.FormattedValue"] })
  const [calificacionDisabled, setCalificacionDisabled] = useState(false)
  const [visibleFirmanteYDocu, setVisibleFirmanteYDocu] = useState(false)
  const [firmante, setFirmante] = useState({ value: documentos[0]._new_firmante_value, label: documentos[0]["_new_firmante_value@OData.Community.Display.V1.FormattedValue"] })
  const [firmanteDisabled, setFirmanteDisabled] = useState(false)
  const [opcionesFirmante, setOpcionesFirmante] = useState([])
  const [estadoDocumento, setEstadoDocumento] = useState({ value: documentos[0].new_estadodeactividad, label: documentos[0]["new_estadodeactividad@OData.Community.Display.V1.FormattedValue"] })
  const [disabled, setDisabled] = useState(true)
  const [activarDisabled, setActivarDisabled] = useState(false)
  const [estadoDocuDisabled, setEstadoDocuDisabled] = useState(false)
  const [estadoPyme, setEstadoPyme] = useState(100000000)
  const [defaultValues, setDefaultValues] = useState({
    personeria: documentos[0]["new_personeria@OData.Community.Display.V1.FormattedValue"],
    razonSocial: documentos[0].name,
    tipodeDocumento: documentos[0]["_new_tipodedocumentoid_value@OData.Community.Display.V1.FormattedValue"],
    cuitCuil: documentos[0].new_nmerodedocumento,
    createdon: documentos[0]["createdon@OData.Community.Display.V1.FormattedValue"],
    estadodelSocio: documentos[0]["new_estadodelsocio@OData.Community.Display.V1.FormattedValue"],
    condicionImpositiva: documentos[0]["new_condicionimpositiva@OData.Community.Display.V1.FormattedValue"],
    correoDeNotificaciones: documentos[0]["az.emailaddress1"],
    telefonoDeNotificaciones: documentos[0]["az.telephone1"],
    calle: documentos[0].address1_line1,
    numero: documentos[0].new_direccion1numero,
    piso: documentos[0].address1_name,
    depto: documentos[0].new_direccion1depto,
    codigoPostal: documentos[0].address1_postalcode,
    municipioPartido: documentos[0].address1_county,
    localidad: documentos[0].new_localidad,
    provincia: { value: documentos[0]._new_provincia_value, label: documentos[0]["_new_provincia_value@OData.Community.Display.V1.FormattedValue"] ? documentos[0]["_new_provincia_value@OData.Community.Display.V1.FormattedValue"] : "---" },
    pais: { value: documentos[0]._new_pais_value, label: documentos[0]["_new_pais_value@OData.Community.Display.V1.FormattedValue"] ? documentos[0]["_new_pais_value@OData.Community.Display.V1.FormattedValue"] : "---" },
    telefono: documentos[0].telephone2,
    actividadAFIP: documentos[0]["_new_actividadafip_value@OData.Community.Display.V1.FormattedValue"],
    facturacionUltimoAño: documentos[0]["new_facturacionultimoanio@OData.Community.Display.V1.FormattedValue"],
    nrodeExpediente: documentos[0].new_nrodeexpediente,
    saldoBrutoGarantiasVigentes: documentos[0]["new_saldobrutogarantasvigentes@OData.Community.Display.V1.FormattedValue"],
    cantidadDeSgr: documentos[0].new_cantidadsgrconlasqueopera,
    cantidadGarantíasVigentes: documentos[0]['new_cantidadgarantiasvigentessepyme@OData.Community.Display.V1.FormattedValue'],
    cantidadGarantíasOtorgadas: documentos[0]['new_montototalgaratiasotorgadassepyme@OData.Community.Display.V1.FormattedValue'],
    saldoBrutoGarantiasVigentesSepyme: documentos[0]['new_saldobrutogaratiasvigentessepyme@OData.Community.Display.V1.FormattedValue'],
    cantidadTotalGarantiasOtorgadasSepyme: documentos[0]['new_cantidadtotalgaratiasotorgadassepyme'],
    condicionPyme: documentos[0]["_new_condicionpyme_value@OData.Community.Display.V1.FormattedValue"],
    categoria: documentos[0]["_new_categoria_value@OData.Community.Display.V1.FormattedValue"]
  })
  const [step, setStep] = useState(1)
  const [opcionesProvincias, setOpcionesProvincias] = useState([])
  const [opcionesPaises, setOpcionesPaises] = useState([])
  const [relaciones, setRelaciones] = useState([])
  const [relacionid, setRelacionid] = useState('')
  const [logRelaciones, setLogRelaciones] = useState(false)
  const [cuentasPorSgr, setCuentasPorSgr] = useState([])
  const [logCuentas, setLogCuentas] = useState(false)
  const [certificados, setCertificados] = useState([])
  const [sociedadXbolsa, setSociedadXbolsa] = useState({})
  const [bancos, setBancos] = useState([])
  const [contactosNotificacionesOpciones, setContactosNotificacionesOpciones] = useState([])
  const [cambiarCalificacion, setCambiarCalificacion] = useState(false)

  useEffect(() => {
    if (provinciasSelector?.length == 0) {
      dispatch(obtenerProvincias(token))
    }
    if (paisesSelector?.length == 0) {
      dispatch(obtenerPaises(token))
    }
  }, [])

  useEffect(() => {
    // if (sociosSelector.length > 0 && id.length > 0) {
    if (documentos.length > 0 && id.length > 0) {
      let relacionesPorSocio = [...new Map(sociosSelector.map(item =>
        [item["ad.new_participacionaccionariaid"], item])).values()]
      let relacionesXS = []
      relacionesPorSocio.filter(item => item.accountid == id).forEach(item => {
        setRelacionid(item["ad.new_participacionaccionariaid"])
        var relacion = {
          new_cuentacontactovinculadoid: item["ad.new_cuentacontactovinculado"],
          new_cuentacontactovinculado: item["ad.new_cuentacontactovinculado@OData.Community.Display.V1.FormattedValue"],
          new_tipoderelacion: item["ad.new_tipoderelacion@OData.Community.Display.V1.FormattedValue"],
          new_participacionid: item["ad.new_participacionaccionariaid"],
          name: item["cuenta.name"],
          emailaddress1: item["cuenta.emailaddress1"],
          new_nmerodedocumento: item["cuenta.new_nmerodedocumento"],
          firstname: item["contacto.firstname"],
          lastname: item["contacto.lastname"],
          new_tienedomicilioenusa: item["contacto.new_tienedomicilioenusa"],
          new_tienedomiciliofiscalenelexterior: item["contacto.new_tienedomiciliofiscalenelexterior"],
          birthdate: item["contacto.birthdate"],
          contactid: item["contacto.contactid"],
          fullname: item["contacto.fullname"],
          familystatuscode: item["contacto.familystatuscode"],
          familystatuscode_value: item["contacto.familystatuscode@OData.Community.Display.V1.FormattedValue"],
          new_tipodedocumentoconyuge: item["contacto.new_tipodedocumentoconyuge"],
          new_tipodedocumentoconyuge_value: item["contacto.new_tipodedocumentoconyuge@OData.Community.Display.V1.FormattedValue"],
          new_generodelconyuge: item["contacto.new_generodelconyuge"],
          new_generodelconyuge_value: item["contacto.new_generodelconyuge@OData.Community.Display.V1.FormattedValue"],
          new_tiposujetoobligado: item["contacto.new_tiposujetoobligado"],
          new_tiposujetoobligado_value: item["contacto.new_tiposujetoobligado@OData.Community.Display.V1.FormattedValue"],
          new_einss: item["contacto.new_einss"],
          new_nit: item["contacto.new_nit"],
          new_nrodedocumentoconyuge: item["contacto.new_nrodedocumentoconyuge"],
          new_nrodedocumento: item["contacto.new_nrodedocumento"],
          new_lugardenacimiento: item["contacto.new_lugardenacimiento"],
          new_cuitcuil: item["contacto.new_cuitcuil"],
          contact_emailaddress1: item["contacto.emailaddress1"],
          address3_line1: item["contacto.address3_line1"],
          address3_postalcode: item["contacto.address3_postalcode"],
          address3_stateorprovince: item["contacto.address3_stateorprovince"],
          address3_city: item["contacto.address3_city"],
          address3_line1: item["contacto.address3_line1"],
          new_paisdeorgenid: item["contacto.new_paisdeorgenid"],
          new_paisdeorgen_value: item["contacto.new_paisdeorgenid@OData.Community.Display.V1.FormattedValue"],
          address1_postalcode: item["contacto.address1_postalcode"],
          address1_line1: item["contacto.address1_line1"],
          address1_stateorprovince: item["contacto.address1_stateorprovince"],
          address1_city: item["contacto.address1_city"],
          address1_country: item["contacto.address1_country"],
          address1_line1: item["contacto.address1_line1"],
          new_personaexpuestapoliticamente: item["contacto.new_personaexpuestapoliticamente"],
          new_cargofuncinjerarquayenteendondetrabaja: item["contacto.new_cargofuncinjerarquayenteendondetrabaja"],
          new_relacinconlapersonaexpuestapolticamente: item["contacto.new_relacinconlapersonaexpuestapolticamente"],
          new_entedondetrabaja: item["contacto.new_entedondetrabaja"],
          new_fechadealtabaja: item["contacto.new_fechadealtabaja"],
          new_fechadebaja: item["contacto.new_fechadebaja"],
          new_conyugetrabaja: item["contacto.new_conyugetrabaja"],
          spousesname: item["contacto.spousesname"],

          valor: {
            relacionid: item["ad.new_participacionaccionariaid"],
            tipo: item["ad.new_tipoderelacion"]
          }
        }
        relacionesXS.push(relacion)
      })
      setLogRelaciones(true)
      setRelaciones(relacionesXS)
      completarOpcionesContactosNotificaciones(relacionesXS)
      debugger
      if (sociosSelector.length > 0 && documentos.length > 0) {
        let todasPymes = [...new Map(documentos.map(item =>
          [item["accountid"], item])).values()]
        todasPymes.forEach(item => {
          // if (item["af.new_sgrlider"] === idSGR) {
          //   setDisabled(false)
          // } else {
          //   setDisabled(true)
          // }
          if (item._new_contactodenotificaciones_value != undefined && item._new_contactodenotificaciones_value != null) {
            setContactoNotificaciones({
              value: item._new_contactodenotificaciones_value,
              label: `${item["_new_contactodenotificaciones_value@OData.Community.Display.V1.FormattedValue"]}`
            })
          }
        })
      }
      if (sociosSelector.length > 0) {
        let opcionesContactos = []
        sociosSelector.filter((item) => item.accountid == id).forEach(item => {
          let nombre = `${item['contacto.fullname']} - ${item['ad.new_tipoderelacion@OData.Community.Display.V1.FormattedValue']}`
          let contactoNotificaciones = { value: item['contacto.contactid'], label: nombre }
          opcionesContactos.push(contactoNotificaciones);
        });
        setContactoNotificaciones(opcionesContactos)
        setSocios(sociosSelector)
      }

      let certificadosPymes = [...new Map(sociosSelector.map(item =>
        [item["aa.new_certificadopymeid"], item])).values()]
      var certificadoS = []
      certificadosPymes.filter(item => item.accountid == id).forEach(element => {
        let certi = {
          new_numeroderegistro: element["aa.new_numeroderegistro@OData.Community.Display.V1.FormattedValue"],
          new_categoria: element["aa.aa.new_categoria@OData.Community.Display.V1.FormattedValue"],
          new_fechadeemision: element["aa.new_fechadeemision@OData.Community.Display.V1.FormattedValue"],
          new_vigenciadesde: element["aa.new_vigenciadesde@OData.Community.Display.V1.FormattedValue"],
          new_vigenciahasta: element["aa.new_vigenciahasta@OData.Community.Display.V1.FormattedValue"],
          statuscode: element["aa.statuscode"]
        }
        certificadoS.push(certi)
      })
      setCertificados(certificadoS)

      let sociedadesSocios = [...new Map(sociosSelector.map(item =>
        [item["ac.new_sociedaddebolsaporsocioid"], item])).values()]
      let sociedades = []
      sociedadesSocios.filter(item => item.accountid == id).forEach(item => {
        let sociedad = {
          new_sociedaddebolsa: item["ac.new_sociedaddebolsa@OData.Community.Display.V1.FormattedValue"],
          _new_socio_value: item["ac.new_socio"],
          new_cuentacomitente: item["ac.new_cuentacomitente@OData.Community.Display.V1.FormattedValue"]
        }
        sociedades.push(sociedad)
      })
      setSociedadXbolsa(sociedades)

      let bancosPorSocio = [...new Map(sociosSelector.map(item =>
        [item["ab.new_bancosporsocioid"], item])).values()]
      let cuentas = []
      bancosPorSocio.filter(item => item.accountid == socioIdSelector).forEach(item => {
        let cuenta = {
          new_banco: item["ab.new_banco@OData.Community.Display.V1.FormattedValue"],
          new_cbu: item["ab.new_cbu"],
          new_numerodecuenta: item["ab.new_numerodecuenta"]
        }
        cuentas.push(cuenta)
      })
      setBancos(cuentas)
    }
  }, [id, sociosSelector])

  useEffect(() => {
    if (provinciasSelector?.length > 0) {
      let opciones = []
      provinciasSelector.forEach(prov => {
        let provincia = { value: prov.new_provinciaid, label: prov.new_name }
        opciones.push(provincia);
      });
      setOpcionesProvincias(opciones)
    }
    if (paisesSelector?.length > 0) {
      let opciones = []
      paisesSelector.forEach(pais => {
        var Pais = { value: pais.new_paisid, label: pais.new_name }
        opciones.push(Pais);
      });
      setOpcionesPaises(opciones)
    }
    if (relaciones.length > 0) {
      var firmantes = []
      relaciones.forEach(firmante => {
        var firma = { value: firmante.new_participacionid, label: firmante.new_cuentacontactovinculado }
        firmantes.push(firma)
      })
      setOpcionesFirmante(firmantes)
    }
  }, [provinciasSelector, paisesSelector, relaciones])

  useEffect(() => {
    if (documentos.length > 0) {
      switch (documentos[0].new_estadodelsocio) {
        case 100000003: //Analisis de riesgo 
          setStep(2)
          setCalificacionDisabled(false)
          setVisibleFirmanteYDocu(true)
          setFirmanteDisabled(true)
          setEstadoDocuDisabled(true)
          setActivarDisabled(true)
          break;
        case 100000001: //Alta Pendiente Pendiente de firma
          setStep(3)
          setCalificacionDisabled(true)
          setVisibleFirmanteYDocu(true)
          setFirmanteDisabled(false)
          setEstadoDocuDisabled(false)
          setActivarDisabled(true)
          break;
        case 100000006: // Pendiente de firma
          setStep(4)
          setCalificacionDisabled(true)
          setVisibleFirmanteYDocu(true)
          setFirmanteDisabled(true)
          setEstadoDocuDisabled(true)
          setActivarDisabled(false)
          break;
        case 100000000: //Activo
          setStep(5)
          setCalificacionDisabled(true)
          setVisibleFirmanteYDocu(true)
          setFirmanteDisabled(true)
          setEstadoDocuDisabled(true)
          setActivarDisabled(true)
          break;
        default:
          setCalificacionDisabled(true)
          setFirmanteDisabled(true)
          setEstadoDocuDisabled(true)
          setActivarDisabled(true)
          break;
      }
      var todasPymes = [...new Map(documentos.map(item =>
        [item["accountid"], item])).values()]
      todasPymes.filter(item => item.accountid == socioIdSelector).forEach(item => {
        if (item["af.new_sgrlider"] === idSGR) {
          setDisabled(false)
        } else {
          setDisabled(true)
          setCalificacionDisabled(true)
          setFirmanteDisabled(true)
          setEstadoDocuDisabled(true)
          setActivarDisabled(true)
        }
      })
    }
  }, [documentos, open])

  useEffect(() => {
    if (cuentasPorSgrSelector?.length > 0) {
      let cuentasSgr = []
      cuentasPorSgrSelector.forEach(item => {
        var cuenta = {
          _new_sgr_value: item["_new_sgr_value@OData.Community.Display.V1.FormattedValue"],
          createdon: item["createdon@OData.Community.Display.V1.FormattedValue"],
          new_saldobrutogaratiasvigentes: item["new_saldobrutogaratiasvigentes@OData.Community.Display.V1.FormattedValue"],
          new_saldodeudaporgtiasabonada: item["new_saldodeudaporgtiasabonada@OData.Community.Display.V1.FormattedValue"],
          new_cantidadgtiasenmora: item["new_cantidadgtiasenmora@OData.Community.Display.V1.FormattedValue"],
          new_situaciondeladueda: item["new_situaciondeladueda@OData.Community.Display.V1.FormattedValue"],
          new_rol: item["new_rol@OData.Community.Display.V1.FormattedValue"],
          new_diasdeatraso: item["new_diasdeatraso@OData.Community.Display.V1.FormattedValue"],
          new_socio: item._new_socio_value
        }
        cuentasSgr.push(cuenta)
      })
      setCuentasPorSgr(cuentasSgr)
    }
  }, [cuentasPorSgrSelector])

  const completarOpcionesContactosNotificaciones = (contactosNotificaciones) => {
    const opcionesContactos = []
    contactosNotificaciones.forEach(item => {
      var nombre = item.fullname && item.new_tipoderelacion ? `${item.fullname} - ${item.new_tipoderelacion}` : `Sin contacto de notificaciones`
      var contactoNotificaciones = { value: item.contactid ? item.contactid : 0, label: nombre }
      opcionesContactos.push(contactoNotificaciones);
    });
    setContactosNotificacionesOpciones(opcionesContactos)
  }

  const modificarDatosCuenta = (datos) => {
    const { calle, numero, piso, depto, codigoPostal, municipioPartido, localidad, provincia, pais, telefono } = datos
    dispatch(actualizarDatosCuenta(token, id, calle, numero + "", piso + "", depto, codigoPostal + "", municipioPartido, localidad, provincia.value, pais.value, telefono, 0, Number(calificacion.value), firmante.value, Number(estadoDocumento.value), contactoNotificaciones.value, cambiarCalificacion, 0))
  }

  const activarPymeF = (e) => {
    e.preventDefault()
    dispatch(activarPyme(token, id))
  }

  const obtenerMisCuentasPorSgr = (e) => {
    e.preventDefault()
    dispatch(obtenerCuentasPorSGR(token, socioIdSelector))
    setTimeout(() => {
      setLogCuentas(true)
    }, 1500);
  }

  const methods = useForm({
    shouldUnregister: false,
    defaultValues,
    mode: "onChange"
  })
  const { handleSubmit } = methods


  const handleClose = () => {
    setOpen(false)
    dispatch(setModalDatosPymes(false))
    setLogCuentas(false)
  }
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const opcionesCalificacion = [
    { value: '100000000', label: 'Pendiente' },
    { value: '100000001', label: 'Aprobada' },
    { value: '100000003', label: 'No Requiere' },
    { value: '100000002', label: 'Rechazada' },
  ]
  const opcionesDocumentacion = [
    { value: '100000004', label: 'En Gestión Documental' },
    { value: '100000000', label: 'Completa' },
  ]

  const buttonSx = {
    ...(resultadoActualizar === 'LOADING' && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <AppBar sx={{ position: 'relative', backgroundColor: theme.palette.background.common }}>
        <Toolbar sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <Typography sx={{ fontSize: { xs: "1rem", md: "1rem" }, ml: 0 }}>
            {defaultValues.razonSocial}
          </Typography>
          <Box sx={{ minWidth: "60vw", display: { xs: "none", md: "inline-block" } }}>
            <ProgressBarPyme currentStep={step} />
          </Box>
          <Tooltip title={<Typography sx={{ color: '#fff' }}>Cerrar</Typography>}>
            <IconButton
              edge="end"
              color="warning"
              onClick={handleClose}
              aria-label="close"
              sx={{ mr: 2 }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <Toolbar sx={{ display: { xs: "flex", md: "none" }, justifyContent: "center", alignItems: "center" }}>
          <ProgressBarPyme currentStep={step} />
        </Toolbar>
      </AppBar>
      <DialogContent sx={{ backgroundColor: theme.palette.background.default }}>
        <DialogContentText id="alert-dialog-description">
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, mb: 1, borderColor: 'divider', px: 4 }}>
              <TabList
                onChange={handleChange}
                aria-label="basic tabs example"
                flexContainer="true"
                scroller="true"
                variant="scrollable"
                indicator
              >
                <Tab label="General" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="0" />
                <Tab label="Vinculaciones" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="1" />
                <Tab label="Análisis de Riesgo" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="2" />
                <Tab label="Datos Casfog" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="3" />
                <Tab label="Certificados Pymes" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="4" />
                <Tab label="Sociedad de Bolsa" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="5" />
                <Tab label="Cuentas Bancarias" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="6" />
              </TabList>
            </Box>
            <FormProvider {...methods}>
              <TabPanel value="0" sx={{ p: "0px" }}>
                <Paper elevation={3} sx={{ p: 3 }}>
                  <Typography variant="h6" sx={{ fontFamily: theme.typography.fontFamily, color: theme.typography.primary.main, mt: 3, mb: 1, mx: 5 }}>Información de la cuenta</Typography>
                  <Grid container spacing={1} sx={{ px: { xs: 1, md: 4 } }}>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <LockIcon sx={{ color: theme.palette.icons.default }} />
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomTextField
                            Component={TextField}
                            readOnly={true}
                            size="small"
                            type="text"
                            name="personeria"
                            label="Personeria"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <LockIcon sx={{ color: theme.palette.icons.default }} />
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomTextField
                            Component={TextField}
                            readOnly={true}
                            size="small"
                            type="text"
                            name="razonSocial"
                            label="Razón Social"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <LockIcon sx={{ color: theme.palette.icons.default }} />
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomTextField
                            Component={TextField}
                            readOnly={true}
                            size="small"
                            type="text"
                            name="tipodeDocumento"
                            label="Tipo de Documento"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <LockIcon sx={{ color: theme.palette.icons.default }} />
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomTextField
                            Component={TextField}
                            readOnly={true}
                            size="small"
                            type="text"
                            name="cuitCuil"
                            label="CUIT/CUIL"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <LockIcon sx={{ color: theme.palette.icons.default }} />
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomTextField
                            Component={TextField}
                            readOnly={true}
                            size="small"
                            type="text"
                            name="estadodelSocio"
                            label="Estado del Socio"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <LockIcon sx={{ color: theme.palette.icons.default }} />
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomTextField
                            Component={TextField}
                            readOnly={true}
                            size="small"
                            type="text"
                            name="condicionImpositiva"
                            label="Condición Impositiva"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {disabled ? <LockIcon sx={{ color: theme.palette.icons.default }} /> : <LockIcon sx={{ color: "transparent" }} />}
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          {/* <CustomSearchSelect
                            options={contactoNotificaciones?.length > ? contactoNotificaciones : []}
                            readOnly={disabled}
                            type="text"
                            name="contactoNotificaciones"
                            lab="Contacto de Notificaciones"
                            sizeButton="small"
                            variantButton="standard"
                          /> */}
                          <InputLabel variant="standard" htmlFor="contactoNotificaciones" focused={true} shrink={true} sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}>
                            Contacto de Notificaciones
                          </InputLabel>
                          <NativeSelect

                            defaultValue={contactoNotificaciones.label}
                            onChange={(e) => { setContactoNotificaciones({ value: e.target.value }) }}
                            disabled={disabled}
                            inputProps={{
                              name: "contactoNotificaciones",
                              id: "contactoNotificaciones",
                              style: { fontSize: ".8rem", py: 2 },
                            }}
                          >
                            {contactosNotificacionesOpciones.map((item) => {
                              return <option sx={{ py: 2 }} key={item.value} value={item.value}>{item.label}</option>;
                            })}
                          </NativeSelect>
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <LockIcon sx={{ color: theme.palette.icons.default }} />
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomTextField
                            Component={TextField}
                            readOnly={true}
                            size="small"
                            type="text"
                            name="correoDeNotificaciones"
                            label="Correo de Notificaciones"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <LockIcon sx={{ color: theme.palette.icons.default }} />
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomTextField
                            Component={TextField}
                            readOnly={true}
                            size="small"
                            name="telefonoDeNotificaciones"
                            label="Teléfono de Notificaciones"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <LockIcon sx={{ color: theme.palette.icons.default }} />
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomTextField
                            Component={TextField}
                            readOnly={true}
                            size="small"
                            type="text"
                            name="createdon"
                            label="Fecha de Alta"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                  <Divider sx={{ my: 3 }} />
                  <Typography variant="h6" sx={{ fontFamily: theme.typography.fontFamily, color: theme.typography.primary.main, mt: 3, mb: 1, mx: 5 }}>Datos de Contacto</Typography>
                  <Grid container spacing={1} sx={{ px: { xs: 1, md: 4 } }}>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {disabled ? <LockIcon sx={{ color: theme.palette.icons.default }} /> : <LockIcon sx={{ color: "transparent" }} />}
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomTextField
                            Component={TextField}
                            readOnly={disabled}
                            size="small"
                            type="text"
                            name="calle"
                            label="Calle"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {disabled ? <LockIcon sx={{ color: theme.palette.icons.default }} /> : <LockIcon sx={{ color: "transparent" }} />}
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomTextField
                            Component={TextField}
                            readOnly={disabled}
                            size="small"
                            type="number"
                            name="numero"
                            label="Número"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {disabled ? <LockIcon sx={{ color: theme.palette.icons.default }} /> : <LockIcon sx={{ color: "transparent" }} />}
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomTextField
                            Component={TextField}
                            readOnly={disabled}
                            size="small"
                            type="number"
                            name="piso"
                            label="Piso"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {disabled ? <LockIcon sx={{ color: theme.palette.icons.default }} /> : <LockIcon sx={{ color: "transparent" }} />}
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomTextField
                            Component={TextField}
                            readOnly={disabled}
                            size="small"
                            type="text"
                            name="depto"
                            label="Depto"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {disabled ? <LockIcon sx={{ color: theme.palette.icons.default }} /> : <LockIcon sx={{ color: "transparent" }} />}
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomTextField
                            Component={TextField}
                            readOnly={disabled}
                            size="small"
                            type="number"
                            name="codigoPostal"
                            label="Código Postal"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {disabled ? <LockIcon sx={{ color: theme.palette.icons.default }} /> : <LockIcon sx={{ color: "transparent" }} />}
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomTextField
                            Component={TextField}
                            readOnly={disabled}
                            size="small"
                            type="text"
                            name="municipioPartido"
                            label="Municipio / Partido"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {disabled ? <LockIcon sx={{ color: theme.palette.icons.default }} /> : <LockIcon sx={{ color: "transparent" }} />}
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomTextField
                            Component={TextField}
                            readOnly={disabled}
                            size="small"
                            type="text"
                            name="localidad"
                            label="Localidad"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {disabled ? <LockIcon sx={{ color: theme.palette.icons.default }} /> : <LockIcon sx={{ color: "transparent" }} />}
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomSearchSelect
                            options={opcionesProvincias}
                            readOnly={disabled}
                            type="text"
                            name="provincia"
                            lab="Provincia"
                            sizeButton="small"
                            variantButton="standard"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {disabled ? <LockIcon sx={{ color: theme.palette.icons.default }} /> : <LockIcon sx={{ color: "transparent" }} />}
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomSearchSelect
                            options={opcionesPaises}
                            readOnly={disabled}
                            type="text"
                            name="pais"
                            lab="País"
                            size="small"
                            sizeButton="small"
                            variantButton="standard"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {disabled ? <LockIcon sx={{ color: theme.palette.icons.default }} /> : <LockIcon sx={{ color: "transparent" }} />}
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomTextField
                            Component={TextField}
                            readOnly={disabled}
                            size="small"
                            type="number"
                            name="telefono"
                            label="Teléfono"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                </Paper>
              </TabPanel>
              <TabPanel value="1">
                <Paper elevation={3}>
                  {logRelaciones && <Tabla columnas={COLUMNRELACIONES} datos={relaciones} />}
                </Paper>
                <Box sx={{ mt: 4, mb: 2, ml: 4, position: 'relative' }}>
                  {open && <SeleccionarFilaRelacion boton={true} socioId={socioIdSelector} />}
                </Box>
              </TabPanel>
              <TabPanel value="2" sx={{ p: "0px" }}>
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Typography variant="h6" sx={{ fontFamily: theme.typography.fontFamily, color: theme.typography.primary.main, mt: 3, mb: 1, mx: 5 }}>Datos Comerciales</Typography>
                  <Grid container spacing={1} sx={{ px: { xs: 1, md: 4 } }}>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <LockIcon sx={{ color: theme.palette.icons.default }} />
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomTextField
                            Component={TextField}
                            readOnly={true}
                            size="small"
                            type="text"
                            name="actividadAFIP"
                            label="Actividad AFIP"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <LockIcon sx={{ color: theme.palette.icons.default }} />
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomTextField
                            Component={TextField}
                            readOnly={true}
                            size="small"
                            type="text"
                            name="facturacionUltimoAño"
                            label="Facturación Último Año"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <LockIcon sx={{ color: theme.palette.icons.default }} />
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomTextField
                            Component={TextField}
                            readOnly={true}
                            size="small"
                            type="text"
                            name="nrodeExpediente"
                            label="Nro. de Expediente"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <LockIcon sx={{ color: theme.palette.icons.default }} />
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomTextField
                            Component={TextField}
                            readOnly={true}
                            size="small"
                            type="text"
                            name="saldoBrutoGarantiasVigentes"
                            label="Saldo Bruto Garantías Vigentes"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <LockIcon sx={{ color: theme.palette.icons.default }} />
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomTextField
                            Component={TextField}
                            readOnly={true}
                            size="small"
                            type="text"
                            name="cantidadDeSgr"
                            label="Cantidad de sgr con las que opera"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {disabled ? <LockIcon sx={{ color: theme.palette.icons.default }} /> : <LockIcon sx={{ color: "transparent" }} />}
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <InputLabel variant="standard" htmlFor="calificacion" focused={true} shrink={true} sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}>
                            Calificación
                            <Tooltip
                              title={<Typography sx={{ color: '#fff' }}>Calificación: Se debe calificar la PyME para completar la fase de “Análisis de Riesgo”. Las opciones “Aprobada” o “No Requiere” permiten avanzar al estado “Alta Pendiente”.</Typography>}
                            >
                              <InfoIcon sx={{ color: theme.palette.icons.default }} />
                            </Tooltip>
                          </InputLabel>
                          <NativeSelect
                            defaultValue={calificacion.value}
                            onChange={(e) => {
                              setCalificacion({ value: e.target.value })
                              setCambiarCalificacion(true)
                            }}
                            disabled={calificacionDisabled}
                            inputProps={{
                              name: "calificacion",
                              id: "calificacion",
                              style: { fontSize: ".8rem" },
                            }}
                          >
                            {opcionesCalificacion.map((item) => {
                              return <option key={item.value} value={item.value}>{item.label}</option>;
                            })}
                          </NativeSelect>
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                  {visibleFirmanteYDocu &&
                    <Grid container spacing={1} sx={{ px: { xs: 1, md: 4 } }}>
                      <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <LockIcon sx={{ color: "transparent" }} />
                          <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                            <InputLabel variant="standard" htmlFor="firmante" focused={true} shrink={true} sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}>
                              Firmante
                              <Tooltip
                                title={<Typography sx={{ color: '#fff' }}>Firmante: Se debe cargar un firmante, a partir de las distintas relaciones de vinculación, el cual se utilizará para completar los datos de la solicitud de otorgamiento de garantía.</Typography>}
                              >
                                <InfoIcon sx={{ color: theme.palette.icons }} />
                              </Tooltip>
                            </InputLabel>
                            <NativeSelect
                              defaultValue={firmante.value}
                              onChange={(e) => { setFirmante({ value: e.target.value }) }}
                              disabled={firmanteDisabled}
                              inputProps={{
                                name: "firmante",
                                id: "firmante",
                                style: { fontSize: ".8rem" },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            >
                              {opcionesFirmante.map((item) => {
                                return <option key={item.value} value={item.value}>{item.label}</option>;
                              })}
                            </NativeSelect>
                          </FormControl>
                        </Box>
                      </Grid>
                      <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <LockIcon sx={{ color: "transparent" }} />
                          <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                            <InputLabel variant="standard" htmlFor="estadoDocumentacion" focused={true} shrink={true} sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}>
                              Estado Documentación
                              <Tooltip
                                title={<Typography sx={{ color: '#fff' }}>Estado de la documentación: Una vez que la PyME ya completó su carpeta digital, se debe seleccionar la opción “Completa”, que junto con el firmante permiten avanzar a la fase “Pendiente de Firmas”.</Typography>}
                              >
                                <InfoIcon sx={{ color: theme.palette.icons }} />
                              </Tooltip>
                            </InputLabel>
                            <NativeSelect
                              defaultValue={estadoDocumento.value}
                              onChange={(e) => { setEstadoDocumento({ value: e.target.value }) }}
                              disabled={estadoDocuDisabled}
                              inputProps={{
                                name: "estadoDocumentacion ",
                                id: "estadoDocumentacion",
                                style: { fontSize: ".8rem" },
                              }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            >
                              {opcionesDocumentacion.map((item) => {
                                return <option key={item.value} value={item.value}>{item.label}</option>;
                              })}
                            </NativeSelect>
                          </FormControl>
                        </Box>
                      </Grid>
                    </Grid>
                  }
                  {
                    !activarDisabled &&
                    <Box sx={{ mt: 3, mb: 2, position: 'relative' }}>
                      <Button
                        type="button"
                        onClick={e => activarPymeF(e)}
                        variant="contained"
                        disabled={loadingActivarPyme}
                        sx={buttonSx}
                      >
                        Activar Pyme
                      </Button>
                      {loadingActivarPyme === true && (
                        <CircularProgress
                          size={27}
                          sx={{
                            color: theme.palette.icons,
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                          }}
                        />
                      )}
                    </Box>
                  }
                </Paper>
              </TabPanel>
              <TabPanel value="3">
                <Paper elevation={3} sx={{ p: 2 }}>
                  <Grid container spacing={1} sx={{ px: { xs: 1, md: 4 } }}>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {disabled ? <LockIcon sx={{ color: theme.palette.icons.default }} /> : <LockIcon sx={{ color: "transparent" }} />}
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomTextField
                            Component={TextField}
                            readOnly={true}
                            size="small"
                            type="text"
                            name="cantidadGarantíasVigentes"
                            label="Cantidad Garantías vigentes (sepyme)"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {disabled ? <LockIcon sx={{ color: theme.palette.icons.default }} /> : <LockIcon sx={{ color: "transparent" }} />}
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomTextField
                            Component={TextField}
                            readOnly={true}
                            size="small"
                            type="text"
                            name="cantidadGarantíasOtorgadas"
                            label="Monto total garantías otorgadas (sepyme)"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {disabled ? <LockIcon sx={{ color: theme.palette.icons.default }} /> : <LockIcon sx={{ color: "transparent" }} />}
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomTextField
                            Component={TextField}
                            readOnly={true}
                            size="small"
                            type="text"
                            name="saldoBrutoGarantiasVigentesSepyme"
                            label="Saldo bruto garantías vigentes (sepyme)"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        {disabled ? <LockIcon sx={{ color: theme.palette.icons.default }} /> : <LockIcon sx={{ color: "transparent" }} />}
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomTextField
                            Component={TextField}
                            readOnly={true}
                            size="small"
                            type="text"
                            name="cantidadTotalGarantiasOtorgadasSepyme"
                            label="Cantidad total garantías otorgadas (sepyme)"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box sx={{ m: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                    <Button
                      type="button"
                      onClick={(e) => obtenerMisCuentasPorSgr(e)}
                      variant="contained"
                      sx={buttonSx}
                    >
                      <Typography variant="subtitle2" color="inherit" sx={{ fontSize: { xs: ".7rem", md: ".8rem" } }}>Ver cuentas por sgr</Typography>
                      {resultadoCuestas === 'LOADING' && (
                        <CircularProgress
                          size={24}
                          color="inherit"
                          style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: -12,
                            marginLeft: -12
                          }}
                        />
                      )}
                    </Button>
                  </Box>
                  {logCuentas &&
                    <Box component="div" sx={{ ml: 4 }}>
                      {cuentasPorSgr.filter(item => item.new_socio == socioIdSelector).length > 0 ?
                        <Tabla columnas={COLUMNSCUENTASGR} datos={cuentasPorSgr.filter(item => item.new_socio == socioIdSelector)} titulo="Cuentas Por SGR" />
                        :
                        <Typography variant="h6" color="inherit" sx={{ fontFamily: theme.typography.fontFamily, color: theme.typography.primary.main, mt: 3, mb: 1, mx: 5 }}>No hay cuentas por SGR</Typography>
                      }
                    </Box>
                  }
                </Paper>
              </TabPanel>
              <TabPanel value="4">
                <Paper elevation={3}>
                  <Grid container spacing={1} sx={{ px: { xs: 1, md: 4 } }}>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <LockIcon sx={{ color: theme.palette.icons.default }} />
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomTextField
                            Component={TextField}
                            readOnly={true}
                            size="small"
                            type="text"
                            name="condicionPyme"
                            label="Condicion Pyme"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                    <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <LockIcon sx={{ color: theme.palette.icons.default }} />
                        <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                          <CustomTextField
                            Component={TextField}
                            readOnly={true}
                            size="small"
                            type="text"
                            name="categoria"
                            label="Categoria"
                          />
                        </FormControl>
                      </Box>
                    </Grid>
                  </Grid>
                  {certificados.length > 0 ?
                    <Tabla columnas={COLUMNSCERTIPYMES(theme)} datos={certificados} />
                    :
                    <Typography variant="h6" color="inherit" sx={{ fontFamily: theme.typography.fontFamily, color: theme.typography.primary.main, p: 3, textAlign: "center" }}>No hay registros</Typography>
                  }
                </Paper>
              </TabPanel>
              <TabPanel value="5">
                <Paper elevation={3}>
                  {sociedadXbolsa.length > 0 ?
                    <Tabla columnas={COLUMNSSOCIE} datos={sociedadXbolsa} />
                    :
                    <Typography variant="h6" color="inherit" sx={{ fontFamily: theme.typography.fontFamily, color: theme.typography.primary.main, p: 3, textAlign: "center" }}>No hay registros</Typography>
                  }
                </Paper>
              </TabPanel>
              <TabPanel value="6">
                <Paper elevation={3}>
                  {bancos.length > 0 ?
                    <Tabla columnas={COLUMNSBANCOS} datos={bancos} />
                    :
                    <Typography variant="h6" color="inherit" sx={{ fontFamily: theme.typography.fontFamily, color: theme.typography.primary.main, p: 3, textAlign: "center" }}>No hay registros</Typography>
                  }
                </Paper>
              </TabPanel>
            </FormProvider>
          </TabContext>
          <Box sx={{ m: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Button
              type="button"
              onClick={handleSubmit(modificarDatosCuenta)}
              variant="contained"
              sx={buttonSx}
              disabled={resultadoActualizar === 'LOADING'}
            >
              {resultadoActualizar === 'LOADING' && (
                <CircularProgress
                  size={24}
                  color="inherit"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12
                  }}
                />
              )}
              Actualizar
            </Button>
          </Box>
          {/* <Box sx={{ mt: 3, mb: 2, position: 'relative' }}>
            <Button
              type="button"
              onClick={handleSubmit(modificarDatosCuenta)}
              disabled={disabled || resultadoActualizar === 'LOADING'}
              variant="contained"
            >
              <Typography variant="subtitle2" color="inherit" sx={{ fontSize: { xs: ".7rem", md: ".8rem" } }}>Actualizar</Typography>
            </Button>
            {resultadoActualizar === 'LOADING' && (
              <CircularProgress
                size={27}
                sx={{
                  color: theme.palette.icons,
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box> */}
        </DialogContentText>
      </DialogContent>
    </Dialog >
  )
}

export default ModalDatosPyme