import React, { useState, useEffect } from "react"
import { Box, Divider, Typography, Tab, useTheme, Tooltip } from "@mui/material"
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { useSelector } from "react-redux"
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft"
import { Link } from "react-router-dom"
import { Pymes } from "./Pymes"
import { Garantias } from "./Garantias"
import { OperacionesSindicadas } from "./OperacionesSindicadas"
import TodasLasOperacionesSindicadas from "./TodasLasOperacionesSindicadas"
import { DocumentosPorSerie } from "../Components/DocumentosPorSerie"

const Serie = () => {
  const theme = useTheme()
  const idSerie = useSelector((store) => store.series.idSerie);
  const participacionesSelector = useSelector((store) => store.series.participacionesEnSerie);
  const [nombreSGR, setNombreSGR] = useState([]);
  const [serie, setSerie] = useState([]);
  const [idSGR, setIdSGR] = useState("");
  const [value, setValue] = useState('0')
  const [isSGRLider, setIsSGRLider] = useState(false)

  useEffect(() => {
    setIdSGR(JSON.parse(localStorage.getItem('Id SGR')))
  }, [])

  useEffect(() => {
    if (idSerie && idSGR && participacionesSelector.length > 0) {
      var seriesUnicas = [...new Map(participacionesSelector.filter(item => item["_new_sgr_value"] == idSGR).map(item => [item["serie.new_seriedeoperacinsindicadaid"], item])).values()]
      if (seriesUnicas.length > 0) {
        var series = seriesUnicas.filter(item =>  item["serie.new_seriedeoperacinsindicadaid"] == idSerie)
        var idLider = series[0]['serie.new_sgrlider']
        if(idLider == idSGR){
          setIsSGRLider(true)
        }
        setNombreSGR(series[0]['serie.new_sgrlider@OData.Community.Display.V1.FormattedValue'])
        setSerie(series[0]['serie.new_name'])
      }
    }
  }, [idSGR])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", py: 2, p: 2 }}>
        <Tooltip title={<Typography sx={{ color: '#fff' }}>Volver</Typography>} >
          <Link to="/Series">
            <ArrowCircleLeftIcon fontSize="large" sx={{ flex: '2 2 auto', color: '#43a047' }} />
          </Link>
        </Tooltip>
        <Typography variant="h1" sx={{ color: theme.typography.primary.main, fontSize: { xs: "1rem", md: "1.4rem" }, flex: '2 2 auto', textAlign: "center" }}>
          {nombreSGR} - {serie}
        </Typography>
      </Box>
      <Divider />
      <Box>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, mb: 1, borderColor: 'divider', px: 4 }}>
            <TabList
              onChange={handleChange}
              aria-label="basic tabs example"
              flexContainer="true"
              scroller="true"
              variant="scrollable"
              indicator
            >
              <Tab label="Mis Pymes" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="0" />
              <Tab label="Mis Garantías"sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="1" />
              <Tab label="Mis Operaciones Sindicadas" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="2" />
              {isSGRLider && <Tab label="Todas las Operaciones Sindicadas" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="3" />}
              <Tab label="Documentación por Serie" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="4" />
            </TabList>
          </Box>
          <TabPanel value="0" sx={{ p: "0px" }}>
            <Pymes esPorSerie={true} idSerie={idSerie} />
          </TabPanel>
          <TabPanel value="1" sx={{ p: "0px" }}>
            <Garantias esPorSerie={true} idSerie={idSerie} />
          </TabPanel>
          <TabPanel value="2" sx={{ p: "0px" }}>
            <OperacionesSindicadas esPorSerie={true} idSerie={idSerie} />
          </TabPanel>
          <TabPanel value="3" sx={{ p: "0px" }}>
            <TodasLasOperacionesSindicadas esPorSerie={true} idSerie={idSerie} />
          </TabPanel>
          <TabPanel value="4" sx={{ p: "0px" }}>
            <DocumentosPorSerie idSerie={idSerie} lider={isSGRLider} />
          </TabPanel>
        </TabContext>
      </Box>
    </>
  );
};

export default Serie;
