import Chip from '@mui/material/Chip';
import Utilidades from '../Components/Utilidades';

export const COLUMNASCARPETADIGITAL = [
    {
        accessorKey: 'id',
    },
    {
        header: 'Documento',
        minSize: 140, //min size enforced during resizing
        maxSize: 200, //max size enforced during resizing
        // accessorKey: '_new_documento_value',
        accessorKey: 'new_name',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Estado',
        minSize: 100, //min size enforced during resizing
        maxSize: 140, //max size enforced during resizing
        accessorKey: 'statuscodeNOMBRE',
        Cell: ({ cell }) => {
            switch (cell.getValue()) {
                case "Recibido":
                    return <Chip label="Recibido" sx={{ bgcolor: '#388e3c' }} />
                case "Pendiente":
                    return <Chip label="Pendiente" sx={{ bgcolor: '#ffa726' }} />
                case "Aprobado":
                    return <Chip label="Aprobado" sx={{ bgcolor: '#0288d1' }} />
            }
        }
    },
    {
        header: 'Fecha de Vencimiento',
        accessorKey: 'new_fechadevencimiento',
        minSize: 100, //min size enforced during resizing
        maxSize: 140, //max size enforced during resizing
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: '',
        accessorKey: 'utilidades',
        minSize: 100, //min size enforced during resizing
        maxSize: 140, //max size enforced during resizing
        Cell: ({ cell }) => { return cell != null ? <Utilidades utilidad={cell.getValue()} /> : '-' }
    }
]