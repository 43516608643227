import axios from "axios"
import { UrlApi, UrlApiDynamics, Entidad } from "../Keys"
import { toast } from 'react-toastify'

const dataInicial = {
  loading: false,
  retriveDesembolsoAnterior: false,
  garantias: [],
  garantiasYPymes: [],
  documentosGarantiasPorId: [],
  documentosDesembolsoAnterior: [],
  garantiasFetch: [],
  adjuntosGarantia: [],
  resultadoGarantiaYPymes: false,
  idGarantia: "",
  idGarantiaDesembolso: "",
  garantiaModificadaResultado: "",
  resultadoDocumentoPorID: "",
  resultadoDesembolsoAnterior: "",
  desembolsoGarantiaResultado: "",
  cargaDocumento: '',
  documentoid: '',
  loadingGarantias: false
};

const ToastError = (msj) => {
  toast.error(msj, {
    theme: "dark",
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
const ToastSuccess = (msj) => {
  toast.success(msj, {
    theme: "dark",
    position: "top-center",
    autoClose: 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  })
}

const LOADING = "LOADING"
const LOADING_DESEMBOLSO = "LOADING_DESEMBOLSO"
const GARANTIASYSERIES_EXITO = "GARANTIASYSERIES_EXITO"
const GARANTIA_ID_SELECCIONADA = "GARANTIA_ID_SELECCIONADA"
const TODAS_GARANTIAS_EXITO = 'TODAS_GARANTIAS_EXITO'
const MODIFICAR_GARANTIA = "MODIFICAR_GARANTIA"
const ADJUNTOS_GARANTIA_POR_ID = "ADJUNTOS_GARANTIA_POR_ID"
const DESEMBOLSO_ANTERIOR_POR_ID = "DESEMBOLSO_ANTERIOR_POR_ID"
const GARANTIA_ID_DESEMBOLSOS_ANTERIORES = "GARANTIA_ID_DESEMBOLSOS_ANTERIORES"
const DESEMBOLSO_GARANTIA = "DESEMBOLSO_GARANTIA"
const TODAS_GARANTIASFETCH_EXITO = 'TODAS_GARANTIASFETCH_EXITO'
const TODOS_ADJUNTOSGARANTIA_EXITO = 'TODOS_ADJUNTOSGARANTIA_EXITO'
const LOADING_CARGAADJUNTOGARANTIA = 'LOADING_CARGAADJUNTOGARANTIA'
const CARGA_ADJUNTOGARANTIA = 'CARGA_ADJUNTOGARANTIA'
const ERROR = 'ERROR'
const ERROR_DESEMBOLSO = 'ERROR_DESEMBOLSO'
const LOADING_GARANTIAS = 'LOADING_GARANTIAS'

export default function garantiasReducer(state = dataInicial, action) {
  switch (action.type) {
    case GARANTIASYSERIES_EXITO:
      return {
        ...state,
        garantiasYPymes: action.payload,
        resultadoGarantiaYPymes: true,
      };
    case LOADING:
      return { ...state, garantiasYPymes: [], resultadoGarantiaYPymes: false, loading: action.loading };
    case GARANTIA_ID_SELECCIONADA:
      return { ...state, idGarantia: action.garantId };
    case TODAS_GARANTIASFETCH_EXITO:
      return { ...state, garantiasFetch: action.payload, loadingGarantias: action.loadingGarantias }
    case GARANTIA_ID_DESEMBOLSOS_ANTERIORES:
      return { ...state, idGarantiaDesembolso: action.garantId };
    case TODAS_GARANTIAS_EXITO:
      return { ...state, garantias: action.payload, loading: action.loading }
    case MODIFICAR_GARANTIA:
      return { ...state, garantiaModificadaResultado: action.garantiaModificadaResultado };
    case DESEMBOLSO_GARANTIA:
      return { ...state, desembolsoGarantiaResultado: action.resultado };
    case ADJUNTOS_GARANTIA_POR_ID:
      return {
        ...state,
        documentosGarantiasPorId: action.payload,
        resultadoDocumentoPorID: action.resultado,
      };
    case DESEMBOLSO_ANTERIOR_POR_ID:
      return {
        ...state,
        documentosDesembolsoAnterior: action.payload,
        resultadoDesembolsoAnterior: action.resultado,
        retriveDesembolsoAnterior: true
      };
    case TODOS_ADJUNTOSGARANTIA_EXITO:
      return { ...state, adjuntosGarantia: action.payload }
    case LOADING_CARGAADJUNTOGARANTIA:
      return { ...state, cargaDocumento: action.cargaDocumento }
    case CARGA_ADJUNTOGARANTIA:
      return { ...state, cargaDocumento: action.cargaDocumento, documentoid: action.documentoid }
    case LOADING_GARANTIAS:
      return { ...state, loadingGarantias: action.loadingGarantias }
    case ERROR:
      return { ...state, loadingGarantias: action.loadingGarantias }
    case LOADING_DESEMBOLSO:
      return {
        ...state, retriveDesembolsoAnterior: false, documentosDesembolsoAnterior: [],
        resultadoDesembolsoAnterior: action.resultado
      }
    case ERROR_DESEMBOLSO:
      return {
        ...state, retriveDesembolsoAnterior: false, documentosDesembolsoAnterior: [],
        resultadoDesembolsoAnterior: action.resultado
      }
    default:
      return { ...state };
  }
}

export const obtenerGarantiasYPymes = (token) => async (dispatch) => {
  dispatch({
    type: LOADING,
  });
  if (token !== undefined && token !== "") {
    var entidad = "new_garantias";
    var fetch =
      "<fetch mapping='logical'>" +
      "<entity name='new_garantia'>" +
      "<attribute name='new_tipodeoperacion' />" +
      "<attribute name='new_socioparticipe' />" +
      "<attribute name='new_saldovigente' />" +
      "<attribute name='new_ndeordendelagarantiaotorgada' />" +
      "<attribute name='new_monto' />" +
      "<attribute name='new_fechadevencimiento' />" +
      "<attribute name='new_fechadeorigen' />" +
      "<attribute name='new_fechadenegociacion' />" +
      "<attribute name='statuscode' />" +
      "<attribute name='transactioncurrencyid' />" +
      "<attribute name='new_codigocvba' />" +
      "<attribute name='new_acreedor' />" +
      "<attribute name='new_garantiaid' />" +
      "<attribute name='new_nroordensepyme' />" +
      "<attribute name='new_oficialdecuentas'/>" +
      "<attribute name='createdon'/> " +
      "<attribute name='new_montocomprometidodelaval'/> " +
      "<attribute name='new_dictamendelaval'/> " +
      "<order attribute ='createdon' descending='false' />" +
      "<filter type='and'>" +
      "<condition attribute='statecode' operator='eq' value='0' />" +
      "</filter>" +
      "<link-entity name='new_seriedeoperacinsindicada' from='new_seriedeoperacinsindicadaid' to='new_nmerodeserie' link-type='inner' alias='serie'>" +
      "<attribute name='new_name'/>" +
      "<attribute name='new_seriedeoperacinsindicadaid'/>" +
      "<attribute name='new_importetotalaavalar'/>" +
      "<filter type='and'>" +
      "<condition attribute='statecode' operator='eq' value='0' />" +
      "</filter>" +
      "</link-entity>" +
      "<link-entity name='account' from='accountid' to='new_socioparticipe' link-type='inner' alias='cuenta'>" +
      "<attribute name='name'/>" +
      "<attribute name='accountid'/>" +
      "<attribute name='new_estadodelsocio'/>" +
      "</link-entity>" +
      "</entity>" +
      "</fetch>";
    try {
      await axios.post(`${UrlApi}api/consultafetch`,
        {
          entidad: entidad,
          fetch: fetch,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      )
        .then((response) => {
          dispatch({
            type: GARANTIASYSERIES_EXITO,
            payload: response.data,
          })
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (error) {
      // dispatch({
      //     type: ERROR
      // })
    }
  }
};

export const obtenerGarantiaIdSeleccionada = (id) => (dispatch) => {
  if (id !== undefined) {
    dispatch({
      type: GARANTIA_ID_SELECCIONADA,
      garantId: id,
    });
  }
};

export const obtenerGarantiaIdDesembolsos = (id) => (dispatch) => {
  if (id !== undefined) {
    dispatch({
      type: GARANTIA_ID_DESEMBOLSOS_ANTERIORES,
      garantId: id,
    });
  }
};

export const editarGarantias = (token, montoBruto, fechaDeCarga, acreedor, estadoGarantia, fechaEmision, nroPrestamo, fechaMonetizacion,
  sistemaAmortizacion, tasa, puntosPorcentuales, periocidadPagos, dictamen, creditoaprobado, codigo, tipodegarantias, fechaVencimiento,
  garantiaid, montoComprometido, determinadaAsamblea, socioparticipe, nmerodeserie, tipodeoperacion, plazodia = 0, nroexpedientetad = "",
  oficialdecuentas = "", referido = "", serie = "", desembolsoAnterior = "", condesembolsosparciales = "") => async (dispatch) => {

    dispatch({
      type: MODIFICAR_GARANTIA,
      garantiaModificadaResultado: "LOADING",
    });

    try {

      await axios.put(`${UrlApi}api/portalcasfog/garantia`,
        {
          "new_garantiaid": garantiaid,
          "new_tipodeoperacion": tipodeoperacion,
          "new_fechadeorigen": fechaDeCarga,
          "new_acreedor": acreedor,
          "new_serie": serie,
          "statuscode": estadoGarantia,
          "new_referido": referido,
          "new_fechaemisindelcheque": fechaEmision,
          "new_oficialdecuentas": oficialdecuentas,
          "new_fechadenegociacion": fechaMonetizacion,
          "new_sistemadeamortizacion": sistemaAmortizacion,
          "new_tasa": tasa,
          "new_puntosporcentuales": puntosPorcentuales,
          "new_periodicidadpagos": periocidadPagos,
          "new_dictamendelaval": dictamen,
          "new_creditoaprobado": creditoaprobado,
          "new_codigo": codigo,
          "new_tipodegarantias": tipodegarantias,
          "new_nroexpedientetad": nroexpedientetad,
          "new_plazodias": plazodia,
          "new_fechadevencimiento": fechaVencimiento,
          "new_montocomprometidodelaval": montoComprometido,
          "new_determinadaenasamblea": determinadaAsamblea,
          "new_monto": montoBruto,
          "new_socioparticipe": socioparticipe,
          "new_nmerodeserie": nmerodeserie,
          "new_numerodeprestamo": nroPrestamo,
          "new_DesembolsoAnterior": desembolsoAnterior,
          "new_condesembolsosparciales": condesembolsosparciales
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }).then((response) => {
          dispatch({
            type: MODIFICAR_GARANTIA,
            garantiaModificada: response.data,
            garantiaModificadaResultado: "EXITO",
          })
          setTimeout(() => {
            dispatch({
              type: MODIFICAR_GARANTIA,
              garantiaModificadaResultado: "fin",
            })
          }, 4000)

          ToastSuccess('La garantia se actualizo con exito!')

        }).catch((err) => {
          console.log(err)
          dispatch({
            type: MODIFICAR_GARANTIA,
            garantiaModificadaResultado: "ERROR",
          })

          ToastError('No pudimos procesar la operación, por favor, vuelva a intentarlo...')

        })

    } catch (error) {
      console.log(error)
      dispatch({
        type: MODIFICAR_GARANTIA,
        garantiaModificadaResultado: "ERROR",
      })

      ToastError('No pudimos procesar la operación, por favor, vuelva a intentarlo...')

    }
  }

export const desembolsoGarantia = (token, montoBruto, acreedor, estadoGarantia = 0, fechaEmision, nroPrestamo, fechaMonetizacion,
  sistemaAmortizacion, tasa, puntosPorcentuales, periocidadPagos, creditoaprobado, codigo, tipodegarantias, fechaVencimiento,
  garantiaid, montoComprometido, determinadaAsamblea, socioparticipe, nmerodeserie, tipodeoperacion, desembolsoAnterior, plazodia = 0, nroexpedientetad = "",
  oficialdecuentas = "", referido = "", serie = "", condesembolsosparciales = "true") => async (dispatch) => {

    dispatch({
      type: DESEMBOLSO_GARANTIA,
      resultado: "LOADING",
    })

    try {

      await axios.post(`${UrlApi}api/portalcasfog/desembolsogarantia`,
        {
          "new_garantiaid": garantiaid,
          "new_tipodeoperacion": tipodeoperacion,
          "new_acreedor": acreedor,
          "new_serie": serie,
          "statuscode": estadoGarantia,
          "new_referido": referido,
          "new_fechaemisindelcheque": fechaEmision,
          "new_oficialdecuentas": oficialdecuentas,
          "new_fechadenegociacion": fechaMonetizacion,
          "new_sistemadeamortizacion": sistemaAmortizacion,
          "new_tasa": tasa,
          "new_puntosporcentuales": puntosPorcentuales,
          "new_periodicidadpagos": periocidadPagos,
          "new_creditoaprobado": creditoaprobado,
          "new_codigo": codigo,
          "new_tipodegarantias": tipodegarantias,
          "new_nroexpedientetad": nroexpedientetad,
          "new_plazodias": plazodia,
          "new_fechadevencimiento": fechaVencimiento,
          "new_montocomprometidodelaval": montoComprometido,
          "new_determinadaenasamblea": determinadaAsamblea,
          "new_monto": montoBruto,
          "new_socioparticipe": socioparticipe,
          "new_nmerodeserie": nmerodeserie,
          "new_numerodeprestamo": nroPrestamo,
          "new_DesembolsoAnterior": desembolsoAnterior,
          "new_condesembolsosparciales": condesembolsosparciales
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      ).then((response) => {
        dispatch({
          type: DESEMBOLSO_GARANTIA,
          garantiaModificada: response.data,
          resultado: "EXITO",
        })

        ToastSuccess('El desembolso finalizó con exito!')

        setTimeout(() => {
          dispatch({
            type: DESEMBOLSO_GARANTIA,
            resultado: "fin",
          })
        }, 4000)
      })
        .catch((err) => {
          console.log(err)
          dispatch({
            type: DESEMBOLSO_GARANTIA,
            resultado: "ERROR",
          })

          ToastError('No pudimos procesar la operación, por favor, vuelva a intentarlo...')

        })

    } catch (error) {
      dispatch({
        type: MODIFICAR_GARANTIA,
        garantiaModificadaResultado: "ERROR",
      })
      ToastError('No pudimos procesar la operación, por favor, vuelva a intentarlo...')
    }
  };

export const obtenerDocumentosGarantiasPorId = (id, token) => async (dispatch) => {
  dispatch({
    type: ADJUNTOS_GARANTIA_POR_ID,
    resultado: "LOADING",
  });
  if (id && token) {
    var entidad = "annotations";
    var fetch = "<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='false'>" +
      "<entity name='annotation'>" +
      "<attribute name='subject' />" +
      "<attribute name='notetext' />" +
      "<attribute name='filename' />" +
      "<attribute name='subject'/>" +
      "<attribute name='isdocument'/>" +
      "<attribute name='mimetype'/>" +
      // "<filter type='and'>" +
      // "<condition attribute='statecode' operator='eq'value='0' />" +
      // "</filter>" +
      // "<attribute name='documentbody'/>" +
      "<attribute name='annotationid' />" +
      "<order attribute='subject' descending='false' />" +
      "<link-entity name='new_adjuntos' from='new_adjuntosid' to='objectid' link-type='inner' alias='ae'>" +
      "<attribute name='new_tipo' />" +
      "<attribute name='statuscode' />" +
      "<attribute name='ownerid' />" +
      "<attribute name='new_name' />" +
      "<attribute name='modifiedonbehalfby' />" +
      "<attribute name='modifiedby' />" +
      "<attribute name='new_garantia' />" +
      "<attribute name='modifiedon' />" +
      "<attribute name='overriddencreatedon' />" +
      "<attribute name='createdon' />" +
      "<attribute name='statecode' />" +
      "<attribute name='new_detalledeimportacion' />" +
      "<attribute name='createdonbehalfby' />" +
      "<attribute name='new_visibleenportal' />" +
      "<attribute name='createdby' />" +
      "<attribute name='new_adjuntosid' />" +
      "<filter type='and'>" +
      "<filter type='or'>" +
      "<condition attribute='new_garantia' operator='eq' uitype='new_garantia' value='" + id + "' />" +
      "</filter>" +
      "</filter>" +
      "</link-entity>" +
      "</entity>" +
      "</fetch>";

    try {
      await axios
        .post(`${UrlApi}api/consultafetch`,
          {
            entidad: entidad,
            fetch: fetch,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          dispatch({
            type: ADJUNTOS_GARANTIA_POR_ID,
            payload: response.data,
            resultado: "EXITO",
          })
        })
        .catch((err) => {
          console.log(err);
          dispatch({
            type: ADJUNTOS_GARANTIA_POR_ID,
            resultado: "ERROR",
          });
        });
    } catch (error) {
      dispatch({
        type: ADJUNTOS_GARANTIA_POR_ID,
        resultado: "ERROR",
      });
    }
  }
};

export const obtenerDesembolsoAnteriorId = (id, token) => async (dispatch) => {
  dispatch({
    type: LOADING_DESEMBOLSO,
    resultado: "LOADING",
  });

  if (token !== undefined && token !== "") {
    var entidad = "new_garantias";
    var fetch =
      "<fetch version='1.0' output-format='xml-platform' mapping='logical' distinct='false'>" +
      "<entity name='new_garantia'>" +
      "<attribute name='new_name'/>" +
      "<attribute name='new_tipodeoperacion' />" +
      "<attribute name='new_socioparticipe' />" +
      "<attribute name='new_saldovigente' />" +
      "<attribute name='new_ndeordendelagarantiaotorgada' />" +
      "<attribute name='new_monto' />" +
      "<attribute name='new_fechadevencimiento' />" +
      "<attribute name='new_fechadeorigen' />" +
      "<attribute name='new_fechadenegociacion' />" +
      "<attribute name='statuscode' />" +
      "<attribute name='transactioncurrencyid' />" +
      "<attribute name='new_codigocvba' />" +
      "<attribute name='new_acreedor' />" +
      "<attribute name='new_garantiaid' />" +
      "<attribute name='new_desembolsoanterior' />" +
      "<order attribute='statuscode' descending='false' />" +
      "<filter type='and'>" +
      "<condition attribute='new_garantiaid' operator='eq' uitype='new_garantia' value='" + id + "' />" +
      "</filter>" +
      "<link-entity name='new_garantia' from='new_garantiaid' to='new_desembolsoanterior' link-type='outer' alias='desembolso1'>" +
      "<attribute name='new_name'/>" +
      "<attribute name='new_tipodeoperacion' />" +
      "<attribute name='new_socioparticipe' />" +
      "<attribute name='new_saldovigente' />" +
      "<attribute name='new_ndeordendelagarantiaotorgada' />" +
      "<attribute name='new_monto' />" +
      "<attribute name='new_fechadevencimiento' />" +
      "<attribute name='new_fechadeorigen' />" +
      "<attribute name='new_fechadenegociacion' />" +
      "<attribute name='statuscode' />" +
      "<attribute name='transactioncurrencyid' />" +
      "<attribute name='new_codigocvba' />" +
      "<attribute name='new_acreedor' />" +
      "<attribute name='new_garantiaid' />" +
      "<attribute name='new_desembolsoanterior' />" +
      "<link-entity name='new_adjuntos' from='new_garantia' to='new_garantiaid' link-type='outer' alias='adjuntos1'>" +
      "<attribute name='new_tipo' />" +
      "<attribute name='new_name' />" +
      "<link-entity name='annotation' from='objectid' to='new_adjuntosid' link-type='outer' alias='notas1'>" +
      "<attribute name='subject' />" +
      "<attribute name='notetext' />" +
      "<attribute name='filename' />" +
      "<attribute name='subject'/>" +
      "<attribute name='isdocument'/>" +
      "<attribute name='mimetype'/>" +
      // "<attribute name='documentbody'/>" +
      "<attribute name='annotationid' />" +
      "</link-entity>" +
      "</link-entity>" +
      "<link-entity name='new_garantia' from='new_garantiaid' to='new_desembolsoanterior' link-type='outer' alias='desembolso2'>" +
      "<attribute name='new_name'/>" +
      "<attribute name='new_tipodeoperacion' />" +
      "<attribute name='new_socioparticipe' />" +
      "<attribute name='new_saldovigente' />" +
      "<attribute name='new_ndeordendelagarantiaotorgada' />" +
      "<attribute name='new_monto' />" +
      "<attribute name='new_fechadevencimiento' />" +
      "<attribute name='new_fechadeorigen' />" +
      "<attribute name='new_fechadenegociacion' />" +
      "<attribute name='statuscode' />" +
      "<attribute name='transactioncurrencyid' />" +
      "<attribute name='new_codigocvba' />" +
      "<attribute name='new_acreedor' />" +
      "<attribute name='new_garantiaid' />" +
      "<attribute name='new_desembolsoanterior' />" +
      "<link-entity name='new_adjuntos' from='new_garantia' to='new_garantiaid' link-type='outer' alias='adjuntos2'>" +
      "<attribute name='new_tipo' />" +
      "<attribute name='new_name' />" +
      "<link-entity name='annotation' from='objectid' to='new_adjuntosid' link-type='outer' alias='notas2'>" +
      "<attribute name='subject' />" +
      "<attribute name='notetext' />" +
      "<attribute name='filename' />" +
      "<attribute name='subject'/>" +
      "<attribute name='isdocument'/>" +
      "<attribute name='mimetype'/>" +
      // "<attribute name='documentbody'/>" +
      "<attribute name='annotationid' />" +
      "</link-entity>" +
      "</link-entity>" +
      "</link-entity>" +
      "</link-entity>" +
      "</entity>" +
      "</fetch>";

    try {
      await axios
        .post(
          `${UrlApi}api/consultafetch`,
          {
            entidad: entidad,
            fetch: fetch,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        )
        .then((response) => {
          dispatch({
            type: DESEMBOLSO_ANTERIOR_POR_ID,
            payload: response.data,
            resultado: "EXITO",
          });
        })
        .catch((err) => {
          dispatch({
            type: ERROR_DESEMBOLSO,
            resultado: "ERROR",
          });
        });
    } catch (error) {
      dispatch({
        type: ERROR_DESEMBOLSO,
        resultado: "ERROR",
      });
    }
  }
};

export const obtenerTodasGarantias = (accountid) => async (dispatch) => {
  dispatch({
    type: LOADING,
    loading: true
  })

  try {
    if (accountid != undefined) {
      const response = await axios(`${UrlApiDynamics}Garantia?filter=_new_socioparticipe_value eq ${accountid}&cuit=${Entidad}`)
      dispatch({
        type: TODAS_GARANTIAS_EXITO,
        payload: response.data,
        loading: false
      })
    }
  }
  catch (error) {
    dispatch({
      type: TODAS_GARANTIAS_EXITO,
      loading: false
    })
  }
}

export const obtenerGarantiasFetch = (cuentaid, token) => async (dispatch) => {
  dispatch({
    type: LOADING_GARANTIAS,
    loadingGarantias: true
  })
  var entidad = "new_garantias"
  var fetch = "<fetch mapping='logical'>" +
    "<entity name='new_garantia'>" +
    "<attribute name='new_monto'/> " +
    "<attribute name='new_name'/> " +
    "<attribute name='new_fechadenegociacion'/>" +
    "<attribute name='statuscode'/> " +
    "<attribute name='new_garantiaid'/> " +
    "<attribute name='createdon'/> " +
    "<attribute name='new_ndeordendelagarantiaotorgada'/> " +
    "<attribute name='new_fechadevencimiento'/> " +
    "<attribute name='new_tipodeoperacion'/> " +
    "<attribute name='new_acreedor'/> " +
    "<attribute name='new_tipodegarantias'/>" +
    "<attribute name='createdon'/> " +
    "<attribute name='transactioncurrencyid'/> " +
    "<attribute name='new_referido'/> " +
    "<attribute name='new_sociosprotector'/> " +
    "<attribute name='new_fechadecancelada'/> " +
    "<attribute name='new_fechadeanulada'/> " +
    "<attribute name='new_fechadenegociacion'/> " +
    "<order attribute ='createdon' descending='false' />" +
    "<filter type='and'>" +
    "<condition attribute='new_socioparticipe' operator='eq' value='" + cuentaid + "' />" +
    "</filter>" +
    "</entity>" +
    "</fetch>";
  try {
    if (token) {
      await axios.post(`${UrlApi}api/consultafetch`,
        {
          "entidad": entidad,
          "fetch": fetch
        },
        {
          headers: {
            "Authorization": `Bearer ${token}`
          }
        })
        .then((response) => {
          dispatch({
            type: TODAS_GARANTIASFETCH_EXITO,
            payload: response.data,
            loadingGarantias: false
          })
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
  catch (error) {
    dispatch({
      type: ERROR,
      loadingGarantias: false
    })
  }
}

export const obtenerAdjuntosGarantias = (cuentaid, token) => async (dispatch) => {
  // dispatch({
  //     type: LOADING_GARANTIAS,
  //     loadingGarantias: true
  // })

  var entidad = "new_adjuntoses"
  var fetch = "<fetch mapping='logical'>" +
    "<entity name='new_adjuntos'>" +
    "<attribute name='new_adjuntosid'/> " +
    "<attribute name='new_name'/> " +
    "<attribute name='new_tipo'/>" +
    "<attribute name='createdon'/> " +
    "<attribute name='new_garantia'/> " +
    "<attribute name='statuscode'/> " +
    "<order attribute ='createdon' descending='false' />" +
    "<link-entity name='new_garantia' from='new_garantiaid' to='new_garantia' link-type='inner' alias='garantia'>" +
    "<filter type='and'>" +
    "<condition attribute='new_socioparticipe' operator='eq' value='" + cuentaid + "' />" +
    "</filter>" +
    "</link-entity>" +
    "<link-entity name='annotation' from='objectid' to='new_adjuntosid' link-type='outer' alias='nota'>" +
    "<attribute name='filename'/> " +
    "</link-entity>" +
    "</entity>" +
    "</fetch>";

  try {
    await axios.post(`${UrlApi}api/consultafetch`,
      {
        "entidad": entidad,
        "fetch": fetch
      },
      {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      })
      .then((response) => {
        dispatch({
          type: TODOS_ADJUNTOSGARANTIA_EXITO,
          payload: response.data,
        })
      })
      .catch(err => {
        console.log(err)
      })
  }
  catch (error) {
    dispatch({
      type: ERROR,
      loadingGarantias: false
    })
  }
}

export const cargarAdjuntoGarantia = (file, garantiaid, token) => async (dispatch) => {
  dispatch({
    type: LOADING_CARGAADJUNTOGARANTIA,
    cargaDocumento: 'LOADING',
    documentoid: ''
  })

  try {
    const configDocumentos = {
      headers: {
        'Authorization': `Bearer ${token}`,
        'content-type': 'multipart/form-data',
      },
    };

    const subirDocumento = () => new Promise(async (resolve, reject) => {
      await axios.post(`${UrlApi}api/socioparticipe/adjuntosgarantia?garantia_id=${garantiaid}`,
        file, configDocumentos)
        .then(data => {
          dispatch({
            type: CARGA_ADJUNTOGARANTIA,
            cargaDocumento: 'EXITO',
            documentoid: data.data
          })
          // console.log(data)
          resolve(data.data)
        })
        .catch(err => {
          // console.log(err)
          reject(err)
        })
    })

    const response = await toast.promise(
      subirDocumento,
      {
        pending: 'Procesando...',
        success: 'Proceso exitoso',
        error: {
          render({ data }) {
            return `Error al cargar documento, ponganse en contacto con mesa de ayuda`
          }
        }
      },
      {
        theme: "dark",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      }
    )
  } catch (err) {
    // console.log(err)
    crearExcepcion(`Excepcion al subir documento` + err, token)
  }
}

const crearExcepcion = (error, token) => {
  axios.post(`https://hw365api.azurewebsites.net/api/excepcion`,
    {
      "descripcion": error
    },
    {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    })
}