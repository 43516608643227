import React, { useContext } from 'react'
import { Box, Paper, useTheme } from '@mui/material'
import { useSelector, useDispatch } from 'react-redux'
import Grid from '@mui/material/Unstable_Grid2/Grid2'
import Fade from 'react-reveal/Fade'
import Tabla from '../Components/Tabla'
import { obtenerAdjuntosGarantias, obtenerGarantiasFetch } from '../Redux/Garantias'
import { COLUMNSGT, COLUMNSGECP } from '../Columnas/ColumnasGarantias'
import GraficoBarra from '../Components/Graficos/GraficoBarra'
import GraficoPieCantidad from '../Components/Graficos/GraficoPieCantidad'
import LoaderAnimado from '../Components/loaderAnimado/LoaderAnimado'
import Tab from '@mui/material/Tab'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import Container from '@mui/material/Container'
import ModalGarantias from '../Components/modales/ModalGarantiasPyme'
import ExtensionOffOutlinedIcon from '@mui/icons-material/ExtensionOffOutlined'
import moment from 'moment'
import { obteneAcreedores, obtenerDestinoDeFondos } from '../Redux/Operaciones';
import TokenContext from '../context/TokenContext'
import ModalNuevaGarantia from '../Components/modales/ModalNuevaGarantia'

const estadosGarantia = ['Afrontada', 'Anulada', 'En Cartera', 'En Gestión', 'Honrada', 'Vencida', 'Vigente']
const estadosYcolores = [{ estado: "Afrontada", color: '#ffa726' }, { estado: "Anulada", color: '#f44336' },
{ estado: "En Cartera", color: '#f57c00' }, { estado: "En Gestión", color: '#66bb6a' }, { estado: "Honrada", color: '#42a5f5' },
{ estado: "Vencida", color: '#d32f2f' }, { estado: "Vigente", color: '#388e3c' }]

const Garantias = () => {
    const dispatch = useDispatch()
    const token = useContext(TokenContext)
    const theme = useTheme()
    const [garantias, setGarantias] = React.useState([])
    const [llamadaGarantias, setLlamadaGarantias] = React.useState(false)
    const [estadoGarantias, setEstadoGarantias] = React.useState([])
    const [dataGarantia, setDataGarantia] = React.useState([])
    const [dataGarantiaCargada, setDataGarantiaCargada] = React.useState(false)
    const [open, setOpen] = React.useState(false)
    const [openNew, setOpenNew] = React.useState(false)
    const [garantiaid, setGarantiaid] = React.useState('')
    const [loadingData, setLoadingData] = React.useState(false)
    const [llamadaAcreedores, setLlamadaAcreedores] = React.useState(false)
    const [llamadaDestinoDeFondos, setLlamadaDestinoDeFondos] = React.useState(false)
    const [llamadaAdjuntosgarantia, setLlamadaAdjuntosgarantia] = React.useState(false)
    const [acreedores, setAcreedores] = React.useState([])
    const [destinoDeFondos, setDestinoDeFondos] = React.useState([])
    const [adjuntosGarantia, setAdjuntosGarantia] = React.useState([])

    const accountid = useSelector(store => store.usuarios.accountid)
    const garantiasSelector = useSelector(store => store.garantias.garantias)
    const garantiasFetchSelector = useSelector(store => store.garantias.garantiasFetch)
    const loadingGarantias = useSelector(store => store.garantias.loadingGarantias)
    const adjuntosGarantiaSelector = useSelector(store => store.garantias.adjuntosGarantia)
    const acredoresSelector = useSelector(store => store.operaciones.acreedores)
    const destinoDeFondosSelector = useSelector(store => store.operaciones.destinoDeFondos)
    //Columnas
    const [garantiasT, setGarantiasT] = React.useState([])
    const [garantiasEC, setGarantiasEC] = React.useState([])
    const [coloresGarantias, setColoresGarantias] = React.useState([])

    //values de los tabs - siempre el numero como string
    const [value, setValue] = React.useState("0")

    React.useEffect(() => {
        if (accountid !== '') {
            if (llamadaGarantias === true && garantiasFetchSelector != undefined && garantiasFetchSelector.length > 0) {
                setGarantiasT(COLUMNSGT(theme))
                setGarantiasEC(COLUMNSGECP(theme))
                let GarantiasFormat = []
                garantiasFetchSelector.forEach(element => {
                    let garantiaFormat = {
                        id: element["new_garantiaid"],
                        new_ndeordendelagarantiaotorgada: element["new_ndeordendelagarantiaotorgada"],
                        new_tipodeoperacion: element["new_tipodeoperacion@OData.Community.Display.V1.FormattedValue"],
                        new_acreedor: element["_new_acreedor_value@OData.Community.Display.V1.FormattedValue"],
                        new_acreedor_value: element["_new_acreedor_value"],
                        statuscode_value: element["statuscode@OData.Community.Display.V1.FormattedValue"],
                        statuscode: element["statuscode"],
                        new_monto: element["new_monto"],
                        new_fechadevencimiento: moment(new Date(element["new_fechadevencimiento"])).format('DD/MM/yyyy'),
                        new_tipodegarantias: element["new_tipodegarantias@OData.Community.Display.V1.FormattedValue"],
                        fechaCreacion: new Date(element["createdon"]),
                        createdon: moment(new Date(element["createdon"])).format('DD/MM/yyyy HH:mm'),
                        transactioncurrencyid: element["_transactioncurrencyid_value@OData.Community.Display.V1.FormattedValue"],
                        new_sociosprotector: element["_new_sociosprotector_value@OData.Community.Display.V1.FormattedValue"],
                        new_fechadecancelada: moment(new Date(element["new_fechadecancelada"])).format('DD/MM/yyyy'),
                        new_fechadeanulada: moment(new Date(element["new_fechadeanulada"])).format('DD/MM/yyyy'),
                        new_librador: element["_new_libradorcheque_value@OData.Community.Display.V1.FormattedValue"],
                        new_formatodelcheque: element["new_formatodelcheque@OData.Community.Display.V1.FormattedValue"],
                        new_numerodecheque: element["new_numerodecheque"],
                        new_tipochpd: element["new_tipochpd@OData.Community.Display.V1.FormattedValue"]
                    }
                    GarantiasFormat.push(garantiaFormat)
                })

                GarantiasFormat.sort(function (a, b) {
                    return new Date(b.fechaCreacion) - new Date(a.fechaCreacion);
                });

                setGarantias(GarantiasFormat)
                let estadiosGarantias = []
                let cantidadesGarantias = []
                let coloresG = []
                // let estadios = [...new Set(garantiasFetchSelector.map(item => item["statuscode@OData.Community.Display.V1.FormattedValue"]))]
                estadosGarantia.forEach(elemento => {
                    var nombre = elemento
                    var cantidad = garantiasFetchSelector.filter(pyme =>
                        pyme["statuscode@OData.Community.Display.V1.FormattedValue"] == elemento).length > 0 ?
                        garantiasFetchSelector.filter(pyme => pyme["statuscode@OData.Community.Display.V1.FormattedValue"] == elemento).length : 0

                    var estado = {
                        estado: nombre,
                        Cantidad: cantidad
                    }

                    estadiosGarantias.push(estado)

                    if (cantidad > 0) {
                        var garantiaCantidad = {
                            name: nombre,
                            value: cantidad
                        }
                        cantidadesGarantias.push(garantiaCantidad)

                        var color = ''
                        estadosYcolores.filter(item => item.estado == elemento).map(estadosYC => {
                            color = estadosYC.color
                        })

                        if (color != '') {
                            coloresG.push(color)
                        }
                    }
                })
                setEstadoGarantias(estadiosGarantias.sort(function (a, b) {
                    if (a.estado < b.estado) {
                        return -1;
                    }
                    if (a.estado > b.estado) {
                        return 1;
                    }
                    return 0;
                }))
                setDataGarantia(cantidadesGarantias.sort(function (a, b) {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                }))
                setColoresGarantias(coloresG)
                setDataGarantiaCargada(true)
                if (llamadaGarantias == false) {
                    setLlamadaGarantias(true)
                }
            } else if (token != undefined && llamadaGarantias === false) {
                obtenerGarantias(accountid, token)
                setLlamadaGarantias(true)
            }
        }
    }, [garantiasFetchSelector, loadingGarantias, accountid, token])

    React.useEffect(() => {
        setLoadingData(loadingGarantias)
    }, [loadingGarantias])

    React.useEffect(() => {
        if (acredoresSelector.length > 0 && llamadaAcreedores === true) {
            const acreedoresArray = [];
            acredoresSelector.forEach(element => {
                var opcion = { value: element.new_acreedorid, label: element.new_name }
                acreedoresArray.push(opcion);
            });
            setAcreedores(acreedoresArray)
        } else if (token != undefined && llamadaAcreedores === false) {
            dispatch(obteneAcreedores(token))
            setLlamadaAcreedores(true)
        }
    }, [acredoresSelector, token])

    React.useEffect(() => {
        if (destinoDeFondosSelector.length > 0 && llamadaDestinoDeFondos === true) {
            const destinoDeFondosArray = [];
            destinoDeFondosSelector.forEach(element => {
                var opcion = { value: element.new_destinodefondosid, label: element.new_name }
                destinoDeFondosArray.push(opcion);
            });
            setDestinoDeFondos(destinoDeFondosArray)
        } else if (token != undefined && llamadaDestinoDeFondos === false) {
            dispatch(obtenerDestinoDeFondos(token))
            setLlamadaDestinoDeFondos(true)
        }
    }, [destinoDeFondosSelector, token])

    React.useEffect(() => {
        if (adjuntosGarantiaSelector.length > 0 && llamadaAdjuntosgarantia === true) {
            let AdjuntosFormat = []
            adjuntosGarantiaSelector.forEach(element => {
                let adjuntoFormat = {
                    id: element["new_adjuntosid"],
                    new_name: element["new_name"],
                    new_tipo: element["new_tipo@OData.Community.Display.V1.FormattedValue"],
                    filename: element["nota.filename"],
                    statuscode: element["statuscode"],
                    new_garantia: element["_new_garantia_value"]
                }
                AdjuntosFormat.push(adjuntoFormat)
            })
            setAdjuntosGarantia(AdjuntosFormat)
        } else if (token != undefined && llamadaDestinoDeFondos === false) {
            dispatch(obtenerAdjuntosGarantias(accountid, token))
            setLlamadaAdjuntosgarantia(true)
        }
    }, [adjuntosGarantiaSelector, token])

    const obtenerGarantias = (cuent, tok) => {
        dispatch(obtenerGarantiasFetch(cuent, tok))
    }

    const handleChangeTabs = (event, newValue) => {
        setValue(newValue)
    }

    const handleOpenModalTable = (id) => {
        setOpen(true)
        setGarantiaid(id)
    }

    const handleClose = () => setOpen(false);

    //style del modal
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 800,
        bgcolor: 'background.default',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    };

    const handleOpenNewModalTable = () => {
        setOpenNew(true)
    }

    const handleCloseNew = () => setOpenNew(false);

    return (
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
            {/* <DrawerHeader /> */}
            <Fade>
                {/* <Box sx={{ display: 'flex', justifyContent: 'center' }}> */}
                <Grid sx={{ px: 2 }}>
                    {/* <Container sx={{ px: 0, mx: 0 }}> */}
                    <TabContext value={value}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            // bgcolor: 'background.paper'
                            px: 0, mx: 0
                        }}>
                            <TabList
                                aria-label="scrollable force tabs example"
                                onChange={handleChangeTabs} variant="scrollable"
                                scrollButtons="auto"
                                allowScrollButtonsMobile
                            >
                                <Tab label="Garantías" value="0" sx={{ fontWeight: 'bold', fontSize: '16px' }}></Tab>
                                <Tab label="Vigentes" value="3" sx={{ fontWeight: 'bold', fontSize: '16px' }}></Tab>
                                <Tab label="En Cartera" value="1" sx={{ fontWeight: 'bold', fontSize: '16px' }}></Tab>
                                <Tab label="En Gestión" value="2" sx={{ fontWeight: 'bold', fontSize: '16px' }}></Tab>
                                <Tab label="Vencidas" value="4" sx={{ fontWeight: 'bold', fontSize: '16px' }}></Tab>
                                <Tab label="Afrontadas" value="5" sx={{ fontWeight: 'bold', fontSize: '16px' }}></Tab>
                            </TabList>
                        </Box>
                        <TabPanel value="0" sx={{ p: 0 }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} >
                                    <Paper elevation={4} sx={{ mt: 2 }}>
                                        {
                                            loadingData ? (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '100%',
                                                        height: '100%',
                                                        py: 2
                                                    }}>
                                                    <LoaderAnimado />
                                                    {/* <span className="loaderP"></span> */}
                                                </Box>
                                            )
                                                :
                                                <Tabla sx={{ maxHeight: 50 }}
                                                    datos={garantias}
                                                    columnas={COLUMNSGT(theme)}
                                                    titulo={"Garantías"}
                                                    handleOpen={handleOpenModalTable}
                                                    acciones={true}
                                                    // handleOpenNew={handleOpenNewModalTable}
                                                    // accionAgregar={true}
                                                />
                                        }

                                    </Paper>
                                </Grid>
                                <Grid item xs={12} sm={12} md={7} sx={{ mt: 2 }}>
                                    <Paper elevation={4} sx={{ p: 0 }}>
                                        <Box sx={{
                                            minwidth: 450, height: 300,
                                            boxShadow: 1,
                                            borderRadius: 2,
                                            // pt: 2,
                                            m: 0,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}>
                                            {
                                                loadingData ? (
                                                    <LoaderAnimado />
                                                ) : !loadingData && dataGarantia.length > 0 ? (
                                                    <GraficoBarra data={estadoGarantias} x="estado" y="Cantidad" />
                                                ) : !loadingData && garantiasFetchSelector.length == 0 && llamadaGarantias == true ?
                                                    <ExtensionOffOutlinedIcon sx={{ fontSize: "40px" }} /> : null
                                            }
                                        </Box>
                                    </Paper>
                                </Grid>
                                <Grid xs={12} sm={12} md={5} sx={{ mt: 2 }}>
                                    <Paper elevation={4} sx={{ m: 0, p: 0 }}>
                                        <Box sx={{
                                            minwidth: 450, height: 300,
                                            boxShadow: 1,
                                            borderRadius: 2,
                                            // pt: 2,
                                            m: 0,
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}>
                                            {
                                                loadingData ? (
                                                    <LoaderAnimado />
                                                ) : !loadingData && dataGarantia.length > 0 ? (
                                                    <GraficoPieCantidad data={dataGarantia} COLORS={coloresGarantias} />
                                                ) : !loadingData && garantiasFetchSelector.length == 0 && llamadaGarantias == true ?
                                                    <ExtensionOffOutlinedIcon sx={{ fontSize: "40px" }} /> : null
                                            }
                                        </Box>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </ TabPanel>
                        <TabPanel value="1">
                            <Grid item xs={12}>
                                <Paper>
                                    {
                                        garantias.filter(item => item.statuscode == 100000004).length > 0 ? (
                                            <Tabla
                                                datos={garantias}
                                                columnas={garantiasEC}
                                                titulo={"Garantías en cartera"}
                                                handleOpen={handleOpenModalTable}
                                                acciones={true} />

                                        ) : <Tabla
                                            datos={[]}
                                            columnas={garantiasEC}
                                            titulo={"Garantías en cartera"} />
                                    }
                                </Paper>
                            </Grid>
                        </ TabPanel>
                        <TabPanel value="2">
                            <Paper>
                                {
                                    garantias.filter(item => item.statuscode == 1).length > 0 ? (
                                        <Tabla
                                            datos={garantias.filter(item => item.statuscode == 1)}
                                            columnas={garantiasEC}
                                            titulo={"Garantías en gestión"}
                                            handleOpen={handleOpenModalTable}
                                            acciones={true} />

                                    ) : <Tabla
                                        datos={[]}
                                        columnas={garantiasEC}
                                        titulo={"Garantías en gestión"}
                                    />
                                }
                            </Paper>
                        </ TabPanel>
                        <TabPanel value="3">
                            <Paper>
                                {
                                    garantias.filter(item => item.statuscode == 100000001).length > 0 ? (
                                        <Tabla
                                            datos={garantias.filter(item => item.statuscode == 100000001)}
                                            columnas={garantiasEC}
                                            titulo={"Garantías vigentes"}
                                            handleOpen={handleOpenModalTable}
                                            acciones={true} />

                                    ) : <Tabla
                                        datos={[]}
                                        columnas={garantiasEC}
                                        titulo={"Garantías vigentes"}
                                    />
                                }
                            </Paper>
                        </ TabPanel>
                        <TabPanel value="4">
                            <Paper>
                                {
                                    garantias.filter(item => item.statuscode == 100000000).length > 0 ? (
                                        <Tabla
                                            datos={garantias.filter(item => item.statuscode == 100000000)}
                                            columnas={garantiasEC}
                                            titulo={"Garantías vencidas"}
                                            handleOpen={handleOpenModalTable}
                                            acciones={true} />

                                    ) : <Tabla
                                        datos={[]}
                                        columnas={garantiasEC}
                                        titulo={"Garantías vencidas"}
                                    />
                                }
                            </Paper>
                        </ TabPanel>
                        <TabPanel value="5">
                            <Paper>
                                {
                                    garantias.filter(item => item.statuscode == 100000002).length > 0 ? (
                                        <Tabla
                                            datos={garantias.filter(item => item.statuscode == 100000002)}
                                            columnas={garantiasEC}
                                            titulo={"Garantías afrontadas"}
                                            handleOpen={handleOpenModalTable}
                                            acciones={true} />

                                    ) : <Tabla
                                        datos={[]}
                                        columnas={garantiasEC}
                                        titulo={"Garantías afrontadas"}
                                    />
                                }
                            </Paper>
                        </ TabPanel>
                    </TabContext>
                    {/* </Container> */}
                </Grid>
            </Fade>
            <ModalGarantias open={open} handleClose={handleClose} adjuntos={adjuntosGarantia.filter(item => item.new_garantia == garantiaid)} garantia={garantias.filter(item => item.id == garantiaid)} garantiaid={garantiaid} />
            <ModalNuevaGarantia open={openNew} handleClose={handleCloseNew} acreedores={acreedores} destinoDeFondos={destinoDeFondos} />
        </Box>
    )
}

export default Garantias