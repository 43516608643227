import React, { useState } from 'react'
import { Button, Menu, MenuItem, Box } from '@mui/material'
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { useDispatch } from 'react-redux'
import { obtenerCuentaIdSeleccionada } from '../../Redux/Cuenta'
import { setModalCarpetaDigital, setModalDatosPymes } from '../../Redux/AbrirModal'

const SeleccionarFilaPyme = ({ value }) => {
    const dispatch = useDispatch()
    const [anchorEl, setAnchorEl] = useState(null)
    const open = Boolean(anchorEl)

    const openModalCarpetaDigital = () => {
        dispatch(setModalCarpetaDigital(true))
    }

    const openModalDatosPyme = () => {
        dispatch(setModalDatosPymes(true))
    }

    const obtenerId = (id) => {
        dispatch(obtenerCuentaIdSeleccionada(id))
    }
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null);
    }

    return (
        <Box textAlign='center'>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
                sx={{ ml: 1 }}
            >
                <MoreHorizIcon sx={{ fontSize: { xs: "1.2rem", md: "1.5rem" } }} />
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem onClick={() => {
                    handleClose()
                    openModalCarpetaDigital()
                    obtenerId(value)
                }}>
                    Carpeta Digital
                </MenuItem>
                <MenuItem onClick={() => {
                    handleClose()
                    openModalDatosPyme()
                    obtenerId(value)
                }}>
                    Datos de la Pyme
                </MenuItem>
            </Menu>
        </Box>
    )
}

export default SeleccionarFilaPyme