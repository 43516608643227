import { Typography, Box, useTheme } from "@mui/material";
import React from "react";

const Header = ({ title, subtitle }) => {
  const theme = useTheme();
  return (
    <Box>
      <Typography
        variant="h5"
        color={theme.typography.primary.main}
        fontWeight="bold"
        sx={{ mb: "5px", ml:{ xs:"1rem", md:".5rem"}, fontSize:{ md:'1.2rem', xs:'.9rem'} }}
      >
        {title}
      </Typography>
      <Typography 
        variant="subtitle1" color={theme.typography.primary.main} 
        sx={{ mb: "5px", ml:{ xs:"1rem", md:".5rem"} }}>
        {subtitle}
      </Typography>
    </Box>
  );
};

export default Header;