import React, { useContext } from 'react'
import { Box, Paper } from '@mui/material';
import { COLUMNAOPERACIONES } from '../Columnas/ColumnasOperaciones';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Fade from 'react-reveal/Fade';
import Tabla from '../Components/Tabla';
import { obtenerOperaciones, refreshOperaciones, obtenerDocumentosPorOperacion, obtenerDocumentosDeOperacion } from '../Redux/Operaciones';
import LoaderAnimado from '../Components/loaderAnimado/LoaderAnimado';
import moment from 'moment'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Container from '@mui/material/Container';
import ModalOperaciones from '../Components/modales/ModalOperaciones';
import ModalNuevaOperacion from '../Components/modales/ModalNuevaOperacion';
import TokenContext from '../context/TokenContext';

const Operaciones = () => {
    const dispatch = useDispatch()
    const token = useContext(TokenContext)

    const [operaciones, setOperaciones] = React.useState([])
    const [cargaOperacion, setCargaOperacion] = React.useState(false)
    const [garantias, setGarantias] = React.useState([])
    const [columnasOperaciones, setColumnasOperaciones] = React.useState([])
    const [open, setOpen] = React.useState(false)
    const [openNew, setOpenNew] = React.useState(false)
    const [op, setOp] = React.useState([])
    const [iD, setID] = React.useState('')
    const [garantiaOp, setGarantiaOp] = React.useState([])
    const [operacionid, setOperacionid] = React.useState('')
    const [llamadaOperaciones, setLlamadaOperaciones] = React.useState(false)
    const [acreedores, setAcreedores] = React.useState([])
    const [destinoDeFondos, setDestinoDeFondos] = React.useState([])
    const [loadingData, setLoadingData] = React.useState(false)
    const [llamadaDocumentos, setLlamadaDocumentos] = React.useState(false)
    const [documentosOperacion, setDocumentosOperacion] = React.useState([])
    const [documentosOP, setDocumentosOP] = React.useState([])
    const [documentosDeOP, setDocumentosDeOP] = React.useState([])
    const [llamadaDocumentosDeOP, setLlamadaDocumentosDeOP] = React.useState(false)
    const [value, setValue] = React.useState("0")
    const operacionesSelector = useSelector(store => store.operaciones.operaciones)
    const accountid = useSelector(store => store.usuarios.accountid)
    const cargaOPSelector = useSelector(store => store.operaciones.cargaOP)
    const refreshOperacionesSelector = useSelector(store => store.operaciones.refreshOperaciones)
    const loadingOperaciones = useSelector(store => store.operaciones.loadingOperaciones)
    const documentosXOperacionSelector = useSelector(store => store.operaciones.documentosXOperacion)
    const documentoDeOPSelector = useSelector(store => store.operaciones.documentosDeOperacion)

    React.useEffect(() => {
        try {
            if (accountid !== '') {
                if (llamadaOperaciones === true && operacionesSelector != undefined && operacionesSelector.length > 0) {
                    setColumnasOperaciones(COLUMNAOPERACIONES)
                    let OperacionesFormat = []
                    let GarantiasFormat = []
                    let operacionesUnicas = [...new Map(operacionesSelector.map(item => [item["new_operacionid"], item])).values()]
                    let garantiasUnicas = [...new Map(operacionesSelector.map(item => [item["garantia.new_garantiaid"], item])).values()]
                    operacionesUnicas.forEach(element => {
                        if (element["new_operacionid"] != undefined && element["new_operacionid"] != "") {
                            let operacionFormat = {
                                id: element["new_operacionid"],
                                new_nrooperacion: element["new_nrooperacion"],
                                new_fechadeenvio: element["new_fechadeenvio"] ? new Date(element["new_fechadeenvio"]) : '',
                                new_destinodefondo: element["_new_destinodefondo_value@OData.Community.Display.V1.FormattedValue"],
                                new_montototalcomision: element["new_montototalcomision@OData.Community.Display.V1.FormattedValue"],
                                new_acreedor: element["_new_acreedor_value@OData.Community.Display.V1.FormattedValue"],
                                new_acreedor_value: element["_new_acreedor_value"],
                                new_referido: element["_new_referido_value@OData.Community.Display.V1.FormattedValue"],
                                new_socioprotector: element["_new_socioprotector_value@OData.Community.Display.V1.FormattedValue"],
                                new_destinodefondo: element["_new_destinodefondo_value@OData.Community.Display.V1.FormattedValue"],
                                new_tipooperacin: element["new_tipooperacin@OData.Community.Display.V1.FormattedValue"],
                                new_tipooperacin_value: element["new_tipooperacin"],
                                new_tipodecheque: element["new_tipodecheque@OData.Community.Display.V1.FormattedValue"],
                                new_productocomercial: element["_new_productocomercial_value@OData.Community.Display.V1.FormattedValue"],
                                new_tipogarantia: element["new_tipogarantia@OData.Community.Display.V1.FormattedValue"],
                                new_montototal: element["new_montototal"],
                                createdon: element["createdon@OData.Community.Display.V1.FormattedValue"],
                                fechaCreacion: new Date(element["createdon"]),
                                fechaCreacion_str: moment(new Date(element["createdon"])).format('DD/MM/yyyy HH:mm'),
                                new_fechadeinstrumentacion: element["new_fechadeinstrumentacion"] ? new Date(element["new_fechadeinstrumentacion"]) : '',
                                new_cantidadgarantias: element["new_cantidadgarantias"],
                                new_montodelaoperacion: element["new_montodelaoperacion@OData.Community.Display.V1.FormattedValue"],
                                statuscode: element["statuscode@OData.Community.Display.V1.FormattedValue"]
                            }
                            OperacionesFormat.push(operacionFormat)
                        }
                    })
                    OperacionesFormat.sort(function (a, b) {
                        return new Date(b.fechaCreacion) - new Date(a.fechaCreacion);
                    });
                    setOperaciones(OperacionesFormat)
                    garantiasUnicas.forEach(element => {
                        if (element["garantia.new_garantiaid"] != undefined && element["garantia.new_garantiaid"] != "") {
                            let garantiaFormat = {
                                id: element["garantia.new_garantiaid"],
                                new_operacionid: element["new_operacionid"],
                                new_ndeordendelagarantiaotorgada: element["garantia.new_ndeordendelagarantiaotorgada"],
                                new_tipodeoperacion: element["garantia.new_tipodeoperacion@OData.Community.Display.V1.FormattedValue"],
                                new_acreedor: element["garantia._new_acreedor_value@OData.Community.Display.V1.FormattedValue"],
                                statuscode_value: element["garantia.statuscode@OData.Community.Display.V1.FormattedValue"],
                                new_monto: element["garantia.new_monto"],
                                new_fechadevencimiento: moment(new Date(element["garantia.new_fechadevencimiento"])).format('DD/MM/yyyy HH:mm'),
                                new_tipodegarantias: element["garantia.new_tipodegarantias@OData.Community.Display.V1.FormattedValue"],
                                createdon: moment(new Date(element["createdon"])).format('DD/MM/yyyy HH:mm'),
                            }
                            GarantiasFormat.push(garantiaFormat)
                        }
                    })
                    setGarantias(GarantiasFormat)
                    dispatch(refreshOperaciones(false))
                    if (cargaOperacion == true) {
                        setLlamadaOperaciones(false)
                        setCargaOperacion(false)
                    }
                    if (llamadaOperaciones == false) {
                        setLlamadaOperaciones(true)
                    }
                } else if (refreshOperacionesSelector == false && token != undefined
                    && llamadaOperaciones === false) {
                    setLlamadaOperaciones(true)
                    obtenerOperacion(accountid, token)
                }
            }
        }
        catch (error) {
            // console.log(error)
        }
    }, [operacionesSelector, accountid, token])

    React.useEffect(() => {
        setLoadingData(loadingOperaciones)
    }, [loadingOperaciones])

    React.useEffect(() => {
        if (documentoDeOPSelector.length > 0) {
            var documentosOPAUX = []
            documentoDeOPSelector.forEach(element => {
                let documentosFormat = {
                    id: element["new_documentacionporoperacionid"],
                    new_documentacionporoperacionid: element["new_documentacionporoperacionid"],
                    new_documento: element["_new_documento_value@OData.Community.Display.V1.FormattedValue"],
                    _new_operacion_value: element["_new_operacion_value"],
                    new_name: element["new_name"],
                    statuscode: element["statuscode"],
                    new_fechadevencimiento: element["new_fechadevencimiento"] ? moment(new Date(element["new_fechadevencimiento"])).format('DD/MM/yyyy HH:mm') : null,
                }
                documentosOPAUX.push(documentosFormat)
            })
            setDocumentosDeOP(documentosOPAUX)
            if (llamadaDocumentosDeOP == true) {
                setLlamadaDocumentosDeOP(true)
            }
        } else if (token != undefined && llamadaDocumentosDeOP === false) {
            dispatch(obtenerDocumentosDeOperacion(accountid, token))
            setLlamadaDocumentosDeOP(true)
        }
    }, [documentoDeOPSelector, token])

    React.useEffect(() => {
        if (cargaOPSelector == "EXITO") {
            setLlamadaOperaciones(true)
            setCargaOperacion(true)
            obtenerOperacion(accountid, token.token)
        }
    }, [cargaOPSelector])


    React.useEffect(() => {
        if (documentosXOperacionSelector.length > 0) {
            setDocumentosOperacion(documentosXOperacionSelector)
        } else if (token != undefined && llamadaDocumentos === false) {
            dispatch(obtenerDocumentosPorOperacion(accountid, token))
            setLlamadaDocumentos(true)
        }
    }, [documentosXOperacionSelector, token])

    const obtenerOperacion = (cuenta_id, token_id) => {
        dispatch(obtenerOperaciones(cuenta_id, token_id))
    }


    const handleOpenModalTable = (id) => { 
        setOpen(true)
        setOperacionid(id)
        setOp(operaciones.filter(item => item.id == id))
        if (garantias != undefined && garantias.length > 0) {
            if (garantias.filter(item => item.new_operacionid == id).length > 0) {
                setGarantiaOp(garantias.filter(item => item.new_operacionid == id))
            } else {
                setGarantiaOp([])
            }
        } else {
            setGarantiaOp([])
        }

        if (documentosDeOP != undefined && documentosDeOP.length > 0) {
            if (documentosDeOP.filter(item => item._new_operacion_value == id).length > 0) {
                setDocumentosOP(documentosDeOP.filter(item => item._new_operacion_value == id))
            } else {
                setDocumentosOP([])
            }
        } else {
            setDocumentosOP([])
        }
    }

    const handleClose = () => setOpen(false);

    const handleCloseNew = () => setOpenNew(false);

    const handleChangeTabs = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <Box component="main" sx={{ flexGrow: 1, pt: 4, px: 3 }}>
            <Fade bottom>
                <Container>
                    <TabContext value={value}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            // bgcolor: 'background.paper'
                        }}>
                            <TabList
                                aria-label="scrollable force tabs example"
                                onChange={handleChangeTabs} variant="scrollable"
                                scrollButtons="auto"
                                allowScrollButtonsMobile
                            >
                                <Tab label="Operaciones" value="0" sx={{ fontWeight: 'bold', fontSize: '16px' }}></Tab>
                            </TabList>
                        </Box>
                        <TabPanel value="0" sx={{ p: 0 }}>
                            <Grid container spacing={4} sx={{ pt: 2 }}>
                                <Grid item xs={12}>
                                    <Paper elevation={4}>
                                        {
                                            loadingData ? (
                                                <Box
                                                    sx={{
                                                        display: 'flex',
                                                        justifyContent: 'center',
                                                        alignItems: 'center',
                                                        width: '100%',
                                                        height: '100%',
                                                        py: 2
                                                    }}>
                                                    <LoaderAnimado />
                                                </Box>
                                            )
                                                :
                                                <Tabla
                                                    datos={operaciones}
                                                    columnas={COLUMNAOPERACIONES}
                                                    handleOpen={handleOpenModalTable}
                                                    // handleOpenNew={handleOpenNewModalTable}
                                                    acciones={true}
                                                // accionAgregar={true}
                                                />
                                        }
                                    </Paper>
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </TabContext>
                </Container>
            </Fade>
            <ModalOperaciones open={open} handleClose={handleClose} operacion={op}
                garantias={garantiaOp} acreedores={acreedores} documentosDeOP={documentosOP} />
            <ModalNuevaOperacion open={openNew} handleClose={handleCloseNew}
                acreedores={acreedores} destinoDeFondos={destinoDeFondos} />
        </Box >
    )
}

export default Operaciones