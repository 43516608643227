import React from "react"
import { Avatar, Paper, Typography, Chip } from "@mui/material"
import Moment from 'moment'
import SeleccionarFilaPyme from "../Components/seleccionarFila/SeleccionarFilaPyme"
import perfiRandom from '../img/perfilRandom.jpg'

export const COLUMNSPYMES = (tema) => [
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>AVATAR</Typography>,
    accessorKey: 'new_urlfotodeperfil',
    minSize: 100, //min size enforced during resizing
    maxSize: 120, //max size enforced during resizing
    Cell: ({ cell }) => {
      return cell.getValue() ?
        <Avatar alt="Remy Sharp" src={cell.getValue()} />
        :
        <Avatar alt="Remy Sharp" src={perfiRandom} />
    }
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>FECHA DE ALTA</Typography>,
    accessorKey: "fechaAlta",
    Cell: ({ cell }) => (
      <Typography sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold'  }}>
        {cell.getValue() ? Moment(cell.getValue(), 'YYYY-MM-DD').format("DD-MM-YYYY") : '-'}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>RAZÓN SOCIAL</Typography>,
    accessorKey: "nombre",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold'  }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>CUIT</Typography>,
    accessorKey: "cuit",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold'  }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>ESTADO DEL SOCIO</Typography>,
    accessorKey: "estado",
    Cell: ({ cell }) => {
      switch (cell.getValue()) {
        case 100000000:
          return (
            <Chip label="Activo" sx={{
              bgcolor: tema.palette.mode === "dark" ? 'rgba(87, 202, 34, 0.2)' : 'rgb(87, 202, 34, 0.8)',
              color: tema.palette.mode === "dark" ? 'rgb(87, 202, 34)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
          );
        case 100000001:
          return (
            <Chip label="Alta Pendiente" sx={{
              bgcolor: tema.palette.mode === "dark" ? 'rgba(241, 241, 19, 0.2)' : 'rgb(241, 241, 19, 0.8)',
              color: tema.palette.mode === "dark" ? 'rgb(241, 241, 19)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
          );
        case 100000002:
          return (
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "#FF5733",
                py: 1,
                px: 2,
                width: { md: "9vw", xs: "24vw" },
              }}
            >
              <Typography
                variant="body2"
                color="#000"
                sx={{
                  textAlign: "center",
                  fontWeight: 600,
                  fontSize: { xs: ".7rem", md: ".8rem" }
                }}
              >
                Inicial
              </Typography>
            </Paper>
          );
        case 100000003:
          return (
            <Chip label="En Análisis de Riesgo" sx={{
              bgcolor: tema.palette.mode === "dark" ? 'rgba(255, 163, 25, 0.2)' : 'rgb(255, 163, 25, 0.8)',
              color: tema.palette.mode === "dark" ? 'rgb(255, 163, 25)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
          );
        case 100000004:
          return (
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "#b91400",
                py: 1,
                px: 2,
                fontSize: { xs: ".7rem", md: ".8rem" }
              }}
            >
              <Typography
                variant="body2"
                color="#000"
                sx={{
                  textAlign: "center",
                  fontWeight: 600,
                  fontSize: { xs: ".7rem", md: ".8rem" }
                }}
              >
                Suspendido
              </Typography>
            </Paper>
          );
        case 100000005:
          return (
            <Paper
              elevation={3}
              sx={{
                backgroundColor: "#b91400",
                py: 1,
                px: 2,
                width: { md: "9vw", xs: "24vw" },
              }}
            >
              <Typography
                variant="body2"
                color="#000"
                sx={{
                  textAlign: "center",
                  fontWeight: 600,
                  fontSize: { xs: ".7rem", md: ".8rem" }
                }}
              >
                Finalizada
              </Typography>
            </Paper>
          );
        case 100000006:
          return (
            <Chip label="Pendiente de Firma" sx={{
              bgcolor: tema.palette.mode === "dark" ? 'rgba(241, 241, 19, 0.2)' : 'rgb(241, 241, 19, 0.8)',
              color: tema.palette.mode === "dark" ? 'rgb(241, 241, 19)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
          );
        default:
          return "---";
      }
    }
  },
  {
    accessorKey: 'accountid',
    minSize: 60, //min size enforced during resizing
    maxSize: 80, //max size enforced during resizing
    Cell: ({ cell }) => {
      return (
        <SeleccionarFilaPyme value={cell.getValue()} />
      )
    }
  }
];