import React, { useState, useEffect, useContext } from 'react'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { Box, Grid, Tab, Paper, Typography, useTheme } from '@mui/material'
import { MiSgr } from '../Components/MiSgr'
import { useDispatch, useSelector } from 'react-redux'
import { obtenerSeriesYGarantias } from '../Redux/Series'
import Tabla from '../Components/Tabla'
import { COLUMNSGARANTIAS, COLUMNSGARANTIASVIGENTES, COLUMNSGARANTIASENCARTERA, COLUMNSGARANTIASPRINCIPAL } from '../Columnas/ColumnasGarantias'
import { obtenerCuentaAdheridaGarantiaFetch } from '../Redux/Cuenta'
import ModalGarantias from '../Components/modales/ModalGarantias'
import TokenContext from '../context/TokenContext'
import { setModalGarantias } from '../Redux/AbrirModal'
import LoaderAnimado from '../Components/loaderAnimado/LoaderAnimado'
import Fade from 'react-reveal/Fade'

export const Garantias = ({ nameSGR, esPorSerie = false, idSerie }) => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const token = useContext(TokenContext)
    //Selectores
    const seriesSelector = useSelector(store => store.series.series)
    const setModalGarantia = useSelector(store => store.setModal.modalDatosGarantia)
    const resultado = useSelector(store => store.series.resultado)
    const idGarantia = useSelector(store => store.garantias.idGarantia)
    const garantiaModificadaResultado = useSelector(store => store.garantias.garantiaModificadaResultado)
    const desembolsoGarantiaResultado = useSelector(store => store.garantias.desembolsoGarantiaResultado)
    //Hooks
    const [idSGR, setIdSGR] = useState(JSON.parse(localStorage.getItem('Id SGR')) ? JSON.parse(localStorage.getItem('Id SGR')) : "")
    const [garantias, setGarantias] = useState([])
    const [value, setValue] = useState('0')
    const [log, setLog] = useState(true)
    const [logFetch, setLogFetch] = useState(true)
    const [logModal, setLogModal] = useState(true)
    const [garantia, setGarantia] = useState([])
    const [garantiasFetch, setGarantiasFetch] = useState([])
    const [nameGarantia, setNameGarantia] = useState("")
    const [nameSGRLider, setNameSGRLider] = useState("")
    //Data Export
    const headerDataExp = ["N° de Orden", "Serie", "Fecha de Carga", "Fecha de Monetización", "Socio Participe", "Acreedor", "Estado", "Monto"]
    const [dataEXP, setDataEXP] = React.useState([])
    const [dataEXPXserie, setDataEXPXserie] = React.useState([])
    
    useEffect(() => {
        setIdSGR(JSON.parse(localStorage.getItem('Id SGR')))
    }, [])

    useEffect(() => {
        if (idSGR && token) {
            dispatch(obtenerSeriesYGarantias(token, idSGR))
            setLog(true)
        }
    }, [idSGR, token])

    useEffect(() => {
        if (garantiaModificadaResultado === "EXITO" || desembolsoGarantiaResultado == "EXITO") {
            dispatch(setModalGarantias(false))
            dispatch(obtenerSeriesYGarantias(token, idSGR))
            dispatch(obtenerCuentaAdheridaGarantiaFetch(token))
            setLog(true)
            setGarantias([])
        }
        if (desembolsoGarantiaResultado == "EXITO") {
            setValue('0')
        }
    }, [garantiaModificadaResultado, desembolsoGarantiaResultado])

    useEffect(() => {
        if (seriesSelector?.length > 0 && resultado === "EXITO") {
            if (esPorSerie) {
                setearGarantiasXserie(seriesSelector, idSerie)
            }
            setearGarantias(seriesSelector)
            setGarantiasFetch(seriesSelector)
            setLogFetch(false)
        }
    }, [token, idSerie, idSGR, seriesSelector, resultado])

    useEffect(() => {
        if (!setModalGarantia) {
            setGarantia([])
            setNameGarantia([])
            setNameSGRLider("")
            setLogModal(true)
        }
        if (garantiasFetch?.length > 0 && setModalGarantia && idGarantia.length > 0 && logModal) {
            setGarantia(garantiasFetch?.filter((item) => item.new_garantiaid == idGarantia))
            setNameGarantia(garantiasFetch?.filter((item) => item.new_garantiaid == idGarantia).map((item) => item['new_name']))
            setNameSGRLider(garantiasFetch?.filter((item) => item.new_garantiaid == idGarantia).map((item) => item['serie.new_sgrlider@OData.Community.Display.V1.FormattedValue']))
            setLogModal(false)
        }
    }, [garantiasFetch, setModalGarantia, idGarantia])

    const setearGarantias = (series) => {
        var garantiasExp = []
        var garantiasT = []
        let garantiasUnicas = [...new Map(series.map(item => [item["new_garantiaid"], item])).values()]
        garantiasUnicas.forEach(item => {
            var garantia = {
                serie: item["serie.new_name"],
                fechaCarga: item["new_fechadeorigen"],
                fechaMonetizacion: item["new_fechadenegociacion"],
                socio: item["cuenta.name"],
                acreedor: item["_new_acreedor_value@OData.Community.Display.V1.FormattedValue"],
                // monto: item["new_monto@OData.Community.Display.V1.FormattedValue"],
                fechaCreacion: new Date(item["createdon"]),
                monto: item["new_monto"],
                estado: item["statuscode"],
                numeroDeOrden: item["new_ndeordendelagarantiaotorgada"],
                descripcionEstado: item["statuscode@OData.Community.Display.V1.FormattedValue"],
                seriedeoperacinsindicadaid: item['serie.new_seriedeoperacinsindicadaid'],
                new_garantiaid: item.new_garantiaid,
                id: item.new_garantiaid
            }
            var garantiaEXP = {
                numeroDeOrden: item["new_ndeordendelagarantiaotorgada"] ? item["new_ndeordendelagarantiaotorgada"] : "-",
                serie: item["serie.new_name"] ? item["serie.new_name"] : "-",
                fechaCarga: item["new_fechadeorigen"] ? item["new_fechadeorigen"] : "-",
                fechaMonetizacion: item["new_fechadenegociacion"] ? item["new_fechadenegociacion"] : "-",
                socio: item["cuenta.name"] ? item["cuenta.name"] : "-",
                acreedor: item["_new_acreedor_value@OData.Community.Display.V1.FormattedValue"] ? item["_new_acreedor_value@OData.Community.Display.V1.FormattedValue"] : "-",
                estado: item["statuscode@OData.Community.Display.V1.FormattedValue"] ? item["statuscode@OData.Community.Display.V1.FormattedValue"] : "-",
                monto: item["new_monto"] ? item["new_monto"] : "-",
                id: item.new_garantiaid
            }
            garantiasExp.push(garantiaEXP)
            garantiasT.push(garantia)
        })
        // garantiasT.sort((a, b) => (a.fechaCarga < b.fechaCarga) ? 1 : ((b.fechaCarga < a.fechaCarga) ? -1 : 0))
        // garantiasExp.sort((a, b) => (a.fechaCarga < b.fechaCarga) ? 1 : ((b.fechaCarga < a.fechaCarga) ? -1 : 0))
        garantiasT.sort(function (a, b) {
            return new Date(b.fechaCreacion) - new Date(a.fechaCreacion);
        });
        garantiasExp.sort(function (a, b) {
            return new Date(b.fechaCreacion) - new Date(a.fechaCreacion);
        });
        setDataEXP(garantiasExp)
        setGarantias(garantiasT)
        setLog(false)
    }

    const setearGarantiasXserie = (series, idserie) => {
        let garantiasExp = []
        let garantiasUnicas = [...new Map(series.map(item => [item["new_garantiaid"], item])).values()]
        garantiasUnicas?.filter(item => item['serie.new_seriedeoperacinsindicadaid'] === idserie).forEach(item => {
            var garantiaEXP = {
                numeroDeOrden: item["new_ndeordendelagarantiaotorgada"] ? item["new_ndeordendelagarantiaotorgada"] : "-",
                serie: item["serie.new_name"] ? item["serie.new_name"] : "-",
                fechaCarga: item["new_fechadeorigen"] ? item["new_fechadeorigen"] : "-",
                fechaMonetizacion: item["new_fechadenegociacion"] ? item["new_fechadenegociacion"] : "-",
                socio: item["cuenta.name"] ? item["cuenta.name"] : "-",
                acreedor: item["_new_acreedor_value@OData.Community.Display.V1.FormattedValue"] ? item["_new_acreedor_value@OData.Community.Display.V1.FormattedValue"] : "-",
                estado: item["statuscode@OData.Community.Display.V1.FormattedValue"] ? item["statuscode@OData.Community.Display.V1.FormattedValue"] : "-",
                monto: item["new_monto"] ? item["new_monto"] : "-",
                id: item.new_garantiaid
            }
            garantiasExp.push(garantiaEXP)
        })
        setDataEXPXserie(garantiasExp)
    }

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <>
            <Box component="main" sx={{ flexGrow: 1, p: 3, py: 0 }}>
                {!esPorSerie && <MiSgr nameSGR={nameSGR} />}
                {!esPorSerie && <Grid xs={12}>
                    <Typography variant="h5" sx={{ fontFamily: theme.typography.fontFamily, color: theme.typography.primary.main }}>
                        Mis Garantias
                    </Typography>
                </Grid>}
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, mb: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} aria-label="basic tabs example" flexContainer="true" scroller="true" variant="scrollable" indicator>
                            <Tab label="Garantías" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="0" />
                            <Tab label="Garantías En Cartera" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="1" />
                            <Tab label="Garantías En Gestión" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="2" />
                            <Tab label="Garantías Vigentes" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="3" />
                            <Tab label="Garantías Vencidas" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="4" />
                            <Tab label="Garantías Afrontadas" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="5" />
                        </TabList>
                    </Box>
                    <TabPanel value="0" sx={{ p: "0px" }}>
                        <Paper elevation={3}>
                            {log ? <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", p: 4 }}>
                                <LoaderAnimado />
                            </Box>
                                :
                                esPorSerie ?
                                    <Tabla
                                        columnas={COLUMNSGARANTIASPRINCIPAL(theme)}
                                        datos={garantias.filter(item => item.seriedeoperacinsindicadaid == idSerie)}
                                        exportData={true}
                                        dataExp={dataEXPXserie}
                                        headerDataExp={headerDataExp}
                                        nombreArchivo="Garantías"
                                    />
                                    :
                                    <Tabla
                                        columnas={COLUMNSGARANTIASPRINCIPAL(theme)}
                                        datos={garantias}
                                        exportData={true}
                                        dataExp={dataEXP}
                                        headerDataExp={headerDataExp}
                                        nombreArchivo="Garantías"
                                    />
                            }
                        </Paper>
                    </TabPanel>
                    <TabPanel value="1" sx={{ p: "0px" }}>
                        <Paper elevation={3}>
                            {log ? <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", p: 4 }}>
                                <LoaderAnimado />
                            </Box>
                                :
                                esPorSerie ?
                                    <Tabla
                                        columnas={COLUMNSGARANTIASENCARTERA(theme)}
                                        datos={garantias.filter(item => item.estado == 100000004 && item.seriedeoperacinsindicadaid == idSerie)}
                                        exportData={true}
                                        dataExp={dataEXPXserie.filter(item => item.estado == "En Cartera")}
                                        headerDataExp={headerDataExp}
                                        nombreArchivo="Garantías En Cartera"
                                    />
                                    :
                                    <Tabla
                                        columnas={COLUMNSGARANTIASENCARTERA(theme)}
                                        datos={garantias.filter(item => item.estado == 100000004)}
                                        exportData={true}
                                        dataExp={dataEXP.filter(item => item.estado == "En Cartera")}
                                        headerDataExp={headerDataExp}
                                        nombreArchivo="Garantías En Cartera"
                                    />
                            }
                        </Paper>
                    </TabPanel>
                    <TabPanel value="2" sx={{ p: "0px" }}>
                        <Paper elevation={3}>
                            {log ? <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", p: 4 }}>
                                <LoaderAnimado />
                            </Box>
                                :
                                esPorSerie ?
                                    <Tabla
                                        columnas={COLUMNSGARANTIAS(theme)}
                                        datos={garantias.filter(item => item.estado == 1 && item.seriedeoperacinsindicadaid == idSerie)}
                                        exportData={true}
                                        dataExp={dataEXPXserie.filter(item => item.estado == "En Gestión")}
                                        headerDataExp={headerDataExp}
                                        nombreArchivo="Garantías En Gestión"
                                    />
                                    :
                                    <Tabla
                                        columnas={COLUMNSGARANTIAS(theme)}
                                        datos={garantias.filter(item => item.estado == 1)}
                                        exportData={true}
                                        dataExp={dataEXP.filter(item => item.estado == "En Gestión")}
                                        headerDataExp={headerDataExp}
                                        nombreArchivo="Garantías En Gestión"
                                    />
                            }
                        </Paper>
                    </TabPanel>
                    <TabPanel value="3" sx={{ p: "0px" }}>
                        <Paper elevation={3}>
                            {log ? <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", p: 4 }}>
                                <LoaderAnimado />
                            </Box>
                                :
                                esPorSerie ?
                                    <Tabla
                                        columnas={COLUMNSGARANTIASVIGENTES(theme)}
                                        datos={garantias.filter(item => item.estado == 100000001 && item.seriedeoperacinsindicadaid == idSerie)}
                                        exportData={true}
                                        dataExp={dataEXPXserie.filter(item => item.estado == "Vigente")}
                                        headerDataExp={headerDataExp}
                                        nombreArchivo="Garantías Vigentes"
                                    />
                                    :
                                    <Tabla
                                        columnas={COLUMNSGARANTIASVIGENTES(theme)}
                                        datos={garantias.filter(item => item.estado == 100000001)}
                                        exportData={true}
                                        dataExp={dataEXP.filter(item => item.estado == "Vigente")}
                                        headerDataExp={headerDataExp}
                                        nombreArchivo="Garantías Vigentes"
                                    />
                            }
                        </Paper>
                    </TabPanel>
                    <TabPanel value="4" sx={{ p: "0px" }}>
                        <Paper elevation={3}>
                            {log ? <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", p: 4 }}>
                                <LoaderAnimado />
                            </Box>
                                :
                                esPorSerie ?
                                    <Tabla
                                        columnas={COLUMNSGARANTIAS(theme)}
                                        datos={garantias.filter(item => item.estado == 100000000 && item.seriedeoperacinsindicadaid == idSerie)}
                                        exportData={true}
                                        dataExp={dataEXPXserie.filter(item => item.estado == "Vencida")}
                                        headerDataExp={headerDataExp}
                                        nombreArchivo="Garantías Vencidas"
                                    />
                                    :
                                    <Tabla
                                        columnas={COLUMNSGARANTIAS(theme)}
                                        datos={garantias.filter(item => item.estado == 100000000)}
                                        exportData={true}
                                        dataExp={dataEXP.filter(item => item.estado == "Vencida")}
                                        headerDataExp={headerDataExp}
                                        nombreArchivo="Garantías Vencidas"
                                    />
                            }
                        </Paper>
                    </TabPanel>
                    <TabPanel value="5" sx={{ p: "0px" }}>
                        <Paper elevation={3}>
                            {log ? <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", p: 4 }}>
                                <LoaderAnimado />
                            </Box>
                                :
                                esPorSerie ?
                                    <Tabla
                                        columnas={COLUMNSGARANTIAS(theme)}
                                        datos={garantias.filter(item => item.estado == 100000002 && item.seriedeoperacinsindicadaid == idSerie)}
                                        exportData={true}
                                        dataExp={dataEXPXserie.filter(item => item.estado == "Afrontada")}
                                        headerDataExp={headerDataExp}
                                        nombreArchivo="Garantías Afrontadas"
                                    />
                                    :
                                    <Tabla
                                        columnas={COLUMNSGARANTIAS(theme)}
                                        datos={garantias.filter(item => item.estado == 100000002)}
                                        exportData={true}
                                        dataExp={dataEXP.filter(item => item.estado == "Afrontada")}
                                        headerDataExp={headerDataExp}
                                        nombreArchivo="Garantías Afrontadas"
                                    />
                            }
                        </Paper>
                    </TabPanel>
                </TabContext>
            </Box>
            {nameGarantia.length > 0 &&
                <ModalGarantias
                    open={setModalGarantia}
                    id={idGarantia}
                    garantia={garantia}
                    name={nameGarantia}
                    nameSGRLider={nameSGRLider}
                />
            }
        </>
    )
}
