import React from 'react'
import { CardContent, Grid, Paper, TextField } from '@mui/material'
import CustomTextField from '../CustomTextField'
import CustomSearchSelect from '../CustomSearchSelect'
import { useDispatch, useSelector } from 'react-redux'
import { obtenerSerieVigenteFetch } from '../../Redux/OperacionesSindicadas'
import CustomCurrencyField from '../CustomCurrencyField'
import FormControl from '@mui/material/FormControl'

const SolicitudAltaAval = ({ serie, token }) => {
    const dispatch = useDispatch()

    const [llamadaSeries, setLlamadaSeries] = React.useState(false)
    const [series, setSeries] = React.useState([])
    const [sgrLider, setSgrLider] = React.useState("")

    const serieVigente = useSelector(store => store.operacione.serieVigenteFetch)

    React.useEffect(() => {
        if (serieVigente?.length > 0) {
            CompletarOpcionesSeries(serieVigente)
        } else if (llamadaSeries === false && token) {
            obtenerSeriesVigente(token)
            setLlamadaSeries(true)
        }
    }, [serieVigente, token])

    React.useEffect(() => {
        debugger
        setSgrLider(serie?.sgrLider)
    }, [serie])


    const obtenerSeriesVigente = (Token) => {
        if (Token) dispatch(obtenerSerieVigenteFetch(Token))
    }

    const CompletarOpcionesSeries = (series) => {
        const oprcionesSeries = [];
        series.forEach(element => {
            let serie = { value: element.new_seriedeoperacinsindicadaid, label: `${element.new_name} - ${element.new_denominacindelalneaaseravaladaporelsind}`, sgrLider: element['_new_sgrlider_value@OData.Community.Display.V1.FormattedValue'] }
            oprcionesSeries.push(serie);
        });
        setSeries(oprcionesSeries)
    }

    return (
        <>
            <Paper elevation={4}>
                <CardContent>
                    <Grid container spacing={1}>
                        <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                            <CustomSearchSelect
                                name="serie"
                                lab="Serie"
                                helperText="Por favor seleccione la serie"
                                options={series}
                                rules={{ required: "Required!" }}
                                req="true"
                            />
                        </Grid>
                        <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                            <FormControl variant="outlined" fullWidth margin="normal" color="success" required>
                                <TextField
                                    Component={TextField}
                                    fullWidth
                                    name="sgrLider"
                                    label="SGR Líder"
                                    value={sgrLider}
                                    readOnly={true}
                                // helperText="SGR Líder"
                                >
                                </TextField>
                            </FormControl>
                            {/* <CustomTextField
                                Component={TextField}
                                name="sgrLider"
                                label="SGR Líder"
                                values={sgrLider}
                                readOnly={true}
                                helperText="SGR Líder"

                            /> */}
                        </Grid>
                        <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                            <CustomTextField
                                Component={TextField}
                                name="divisa"
                                values="Pesos Argentinos"
                                label="Divisa"
                                value="Pesos Argentinos"
                                readOnly={true}
                                helperText="Divisa"

                            />
                        </Grid>
                        <Grid xs={12} sm={6} sx={{ pt: 0, pb: 0 }} item>
                            <CustomCurrencyField
                                name="montoCreditoAprobado"
                                label="Monto crédito aprobado"
                                rules={{ required: "Required!" }}
                                helperText="Escribe el monto del crédito aprobado"
                                req="true"
                            />
                        </Grid>
                        <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                            <CustomTextField
                                Component={TextField}
                                name="nroExpediente"
                                label="Nº de expediente asignado vía TAD (Solo créditos FONDEP)"
                                helperText=""

                            />
                        </Grid>
                    </Grid>
                </CardContent>
            </Paper >
        </>
    )
}

export default SolicitudAltaAval
