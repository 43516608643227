import React from 'react';
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField';
import CardContent from '@mui/material/CardContent';
import { Typography, Divider } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import FolderIcon from '@mui/icons-material/Folder';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import CustomTextField from '../CustomTextField';
import AddBoxIcon from '@mui/icons-material/AddBox';
import PersonPinIcon from '@mui/icons-material/PersonPin';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { Box } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import ReCAPTCHA from 'react-google-recaptcha'
import { RecaptchaKey } from '../../Keys'
import { setearRobot } from '../../Redux/DatosSolicitudAlta'
import DownloadIcon from '@mui/icons-material/Download';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const SolicitudAltaAdicional = (
    { personeria,
        condicionImpositiva,
        documentosFiltrados,
        condicionesAFIP,
        docsDTOs, handleOpen,
        setDatosDocumento,
        handleOpen2,
        handleOpenCarpeta,
        accionistas,
        editarAccionista,
        handleOpenEliminarAccionista,
        storage,
        agregarDocumentos
    }) => {

    const dispatch = useDispatch()
    const [dense, setDense] = React.useState(false);
    const [documentos, setDocumentos] = React.useState([])
    const [cargoStorage, setCargoStorage] = React.useState(false)
    const [robot, setRobot] = React.useState(false)
    const robotS = useSelector(store => store.datos.robot)

    React.useEffect(() => {
        if ((documentosFiltrados == undefined || documentosFiltrados.length == 0) && storage != null && storage.docsDTOs.length > 0) {
            if (condicionesAFIP != undefined) {
                setDocumentos(filtrarDocumentosPorCondicionAFIP(condicionesAFIP, storage.docsDTOs, condicionImpositiva))
            }
            setCargoStorage(true)
        } else { //No entra aca cuando carga primero del localstorage
            if (documentosFiltrados !== undefined && documentosFiltrados.length !== 0 && documentosFiltrados !== null) {
                setDocumentos(filtrarDocumentosPorCondicionAFIP(condicionesAFIP, documentosFiltrados, condicionImpositiva))
            } else if (docsDTOs != undefined && docsDTOs.length !== undefined && docsDTOs.length > 0) {
                var docs = docsDTOs
                var docsFiltrados = filtrarDocumentosPorCondicionAFIP(condicionesAFIP, docs, condicionImpositiva)
                setDocumentos(docsFiltrados)
            }
        }

        if (personeria !== '') {
            if (personeria === '100000000') {
                var juridica = document.getElementById('juridica')
                juridica.style.display = 'block';
            }
        }
    }, [personeria, condicionImpositiva, accionistas, condicionesAFIP])

    const filtrarDocumentos = (docs, condicionImpositiva) => {
        var documentosFiltrados = []
        docs.forEach(doc => {
            if (doc.new_condicionimpositiva != null) {
                var condiciones = doc.new_condicionimpositiva.split(',')
                condiciones.forEach(item => {
                    if (item === condicionImpositiva) {
                        documentosFiltrados.push(doc)
                    }
                })
            } else {
                documentosFiltrados.push(doc)
            }
        })
        return documentosFiltrados
    }

    const filtrarDocumentosPorCondicionAFIP = (condiciones, docs, condicionImpositiva) => {
        var documentosFiltrados = []
        var documentosFiltradosXAFIP = []
        if (condiciones != undefined) {
            condiciones.filter(item => item.new_condiciondeinscipcionanteafipid == condicionImpositiva.value).forEach(condicion => {
                if (documentosFiltrados.length > 0 && condicion.new_documentacionid != null && condicion.new_documentacionid != "") {
                    if (documentosFiltrados.filter(item => item == condicion.new_documentacionid).length == 0) {
                        documentosFiltrados.push(condicion.new_documentacionid)
                    }
                } else {
                    if (condicion.new_documentacionid != null && condicion.new_documentacionid != "") {
                        documentosFiltrados.push(condicion.new_documentacionid)
                    }
                }
            })
        }

        if (docs != undefined && docs.length > 0) {
            docs.forEach(documento => {
                if (documentosFiltrados.length > 0 &&
                    documentosFiltrados.filter(item => item == documento.new_documentacionid).length > 0) {
                    documentosFiltradosXAFIP.push(documento)
                } else if (condiciones != undefined && condiciones.length > 0) {
                    if (condiciones.filter(item => item.new_documentacionid == documento.new_documentacionid).length == 0) {
                        documentosFiltradosXAFIP.push(documento)
                    }
                } else {
                    documentosFiltradosXAFIP.push(documento)
                }
            })
        }

        if (documentosFiltradosXAFIP.length > 0) {
            agregarDocumentos(documentosFiltradosXAFIP)
        }

        return documentosFiltradosXAFIP
    }

    //obtener el nombre del doc a cargar
    const obtenerDocumento = async (e) => {
        let nombreDocumento = ""
        documentos.filter(elemento => elemento.new_documentacionid === e).map(item => {
            nombreDocumento = item.new_name
        })
        setDatosDocumento(nombreDocumento + ':' + e)
        handleOpen();
    }

    const obtenerCarpeta = async (e) => {
        let nombreDocumento = ""
        documentos.filter(elemento => elemento.new_documentacionid === e).map(item => {
            nombreDocumento = item.new_name
        })
        setDatosDocumento(nombreDocumento + ':' + e)
        handleOpenCarpeta();
    }

    const calcularMegabytes = (bytes) => {
        var sizes = ['bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes == 0) return '0 MB';
        var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
    }

    const obtenerPeso = (docs = []) => {
        var peso = 0
        docs.forEach(element => {
            if (element.pesoArchivo != undefined && element.pesoArchivo != null
                && element.pesoArchivo != 0 && element.pesoArchivo > 0) {
                peso += element.pesoArchivo
            }
        })
        return peso
    }

    const robotOnChange = (value) => {
        var valor = ""
        if (value) {
            valor = "True"
            setRobot(true)
            dispatch(setearRobot(true))
        } else {
            valor = "False"
            setRobot(false)
            dispatch(setearRobot(false))
        }
    }


    const theme = useTheme();

    return (
        <>
            <CardContent>
                <Grid container spacing={1}>
                    <Grid xs={12} sm={6} item>
                        <Paper elevation={4} sx={{ p: 0, m: 0 }}>
                            <Grid container sx={{ bgcolor: theme.palette.primary.main, p: 1, color: '#eeeeee', borderRadius: '5px', m: 0 }}>
                                <Grid item xs={7}>
                                    <Typography variant="subtitle1" sx={{ fontSize: '15px', m: 0 }} gutterBottom>
                                        Documentación requerida
                                    </Typography>
                                </Grid>
                                <Grid item xs={5}>
                                    <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                                        <Typography variant="subtitle1" sx={{ fontSize: '14px', m: 0 }} gutterBottom>
                                            {
                                                calcularMegabytes(obtenerPeso(documentos))
                                            }
                                            /20MB
                                            {/* <StorageIcon sx={{ fontSize: '16px', mx: 1}} /> */}
                                        </Typography>

                                    </Box>
                                </Grid>
                            </Grid>
                            <List dense={dense} sx={{ py: 1 }}>
                                {documentos.map(item => (
                                    <>
                                        <ListItem key={item.new_documentacionid} secondaryAction={
                                            <>
                                                {
                                                    item.new_descripcion ?
                                                        <Tooltip title={<Typography sx={{ color: '#fff' }}>{item.new_descripcion}</Typography>} sx={{ mt: 1 }}>
                                                            <IconButton sx={{ m: 0 }}>
                                                                <HelpOutlineIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                        :
                                                        null
                                                }
                                                {
                                                    item.new_urlplantilla ?
                                                        <Tooltip title={<Typography sx={{ color: '#fff' }}>Descargar Plantilla</Typography>}>
                                                            <IconButton onClick={() => window.open(item.new_urlplantilla, "_blank")}>
                                                                <DownloadIcon />
                                                            </IconButton>
                                                        </Tooltip> : null
                                                }
                                                <Tooltip title={<Typography sx={{ color: '#fff' }}>Abrir Carpeta</Typography>}>
                                                    <IconButton edge="end" aria-label="delete" onClick={e => obtenerCarpeta(item.new_documentacionid)}>
                                                        <FolderIcon />
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={<Typography sx={{ color: '#fff' }}>Subir Archivo</Typography>}>
                                                    <IconButton sx={{ ml: 1 }} edge="end" aria-label="delete" onClick={e => obtenerDocumento(item.new_documentacionid)} >
                                                        <FileUploadIcon />
                                                    </IconButton>
                                                </Tooltip>
                                            </>
                                        }>
                                            <Checkbox
                                                edge="start"
                                                checked={item.documentoCargado ? true : false}
                                            />
                                            {
                                                item.new_requeridoenportal ?
                                                    <ListItemText
                                                        primary={
                                                            <Box sx={{ m: 0, p: 0, maxWidth: "80%" }} >
                                                                <Typography variant="string" sx={{ fontSize: 14 }} >
                                                                    {item.new_name} <span class="prueba">*</span>
                                                                </Typography>
                                                            </Box>
                                                        }
                                                    /> :
                                                    <ListItemText
                                                        primary={
                                                            <Box sx={{ m: 0, p: 0, maxWidth: "80%" }} >
                                                                <Typography variant="string" sx={{ fontSize: 14 }} >
                                                                    {item.new_name}
                                                                </Typography>
                                                            </Box>
                                                        }
                                                    />
                                            }
                                        </ListItem>
                                        <Box sx={{ mx: 2 }}>
                                            <Divider sx={{ borderBottomWidth: 2 }} />
                                        </Box>

                                    </>
                                )
                                )}
                            </List>
                        </Paper>
                    </Grid>
                    <Grid xs={12} sm={6} item>
                        <Paper elevation={4} >
                            <Grid xs={12} id="juridica" sx={{ mb: 0, p: 0, pb: 2 }} style={{ display: 'none' }}>
                                <Grid xs={12} sx={{ bgcolor: theme.palette.primary.main, p: 1, color: '#eeeeee', borderRadius: '5px', m: 0 }}>
                                    <Typography variant="subtitle1" sx={{ m: 0 }} gutterBottom>
                                        Accionistas/socios
                                        <IconButton edge="end" aria-label="delete" onClick={handleOpen2}>
                                            <Tooltip title={<Typography sx={{ color: '#fff' }}>Agregar Accionista</Typography>}>
                                                <AddBoxIcon sx={{ color: '#eeeeee' }} />
                                            </Tooltip>
                                        </IconButton>
                                    </Typography>
                                </Grid>
                                <List dense={dense} sx={{ m: 0, p: 1 }}>
                                    {
                                        accionistas.length > 0 ?
                                            accionistas.map(item => (
                                                <>
                                                    <ListItem sx={{ m: 0, p: 0 }} key={item.uid}>
                                                        <Grid item xs={10}>
                                                            {
                                                                (item.razonSocial !== '') ?
                                                                    <ListItem fullWidth sx={{ m: 0, p: 0 }}>
                                                                        <ListItemAvatar sx={{ m: 0, p: 0 }}>
                                                                            {/* <Avatar> */}
                                                                            <AccountBalanceIcon sx={{ m: 0, p: 0 }} />
                                                                            {/* </Avatar> */}
                                                                        </ListItemAvatar>
                                                                        <ListItemText
                                                                            primary={item.razonSocial + " - " + item.cuitcuil + " - " + item.porcentaje + '%'}
                                                                        />
                                                                    </ListItem>
                                                                    :
                                                                    <ListItem fullWidth sx={{ m: 0, p: 0 }}>
                                                                        <ListItemAvatar sx={{ m: 0, p: 0 }}>
                                                                            {/* <Avatar> */}
                                                                            <PersonPinIcon sx={{ m: 0, p: 0 }} />
                                                                            {/* </Avatar> */}
                                                                        </ListItemAvatar>
                                                                        <ListItemText
                                                                            primary={item.nombre + " - " + item.cuitcuil + " - " + item.porcentaje + '%'}
                                                                        />
                                                                    </ListItem>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                            <>
                                                                <Tooltip title={<Typography sx={{ color: '#fff' }}>Editar Accionista</Typography>}>
                                                                    <IconButton edge="end" onClick={e => editarAccionista(item.uid)}>
                                                                        <DriveFileRenameOutlineIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                {
                                                                    !item.relacionDirecta ?
                                                                        <Tooltip title={<Typography sx={{ color: '#fff' }}>Eliminar Accionista</Typography>}>
                                                                            <IconButton sx={{ ml: 1 }} edge="end" aria-label="delete" onClick={() => handleOpenEliminarAccionista(item.uid)}>
                                                                                <DeleteIcon />
                                                                            </IconButton>
                                                                        </Tooltip>
                                                                        : null
                                                                }
                                                            </>
                                                        </Grid>
                                                    </ListItem>
                                                    <Box sx={{ mx: 0 }}>
                                                        <Divider sx={{ borderBottomWidth: 2 }} />
                                                    </Box>
                                                </>
                                            )) :
                                            <>
                                                <ListItem sx={{ m: 0, p: 0 }}>
                                                    <Grid item xs={10}>
                                                        {
                                                            <ListItem fullWidth sx={{ m: 0, p: 0 }}>

                                                                <ListItemText
                                                                    primary="No hay accionistas"
                                                                />
                                                            </ListItem>
                                                        }
                                                    </Grid>
                                                    <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-end' }}>

                                                    </Grid>
                                                </ListItem>
                                                <Box sx={{ mx: 0 }}>
                                                    <Divider sx={{ borderBottomWidth: 2 }} />
                                                </Box>
                                            </>
                                    }
                                </List>
                            </Grid>
                        </Paper>
                        <Paper elevation={4}>
                            {/* item sx={{bgcolor: theme.}} */}
                            <Grid xs={12} sx={{ bgcolor: theme.palette.primary.main, p: 1, color: '#eeeeee', borderRadius: '5px', m: 0 }}>
                                <Typography variant="subtitle1" sx={{ m: 0 }} gutterBottom>
                                    Información Adicional
                                </Typography>
                            </Grid>
                            <Grid xs={12} item sx={{ pt: 2 }}>
                                <CustomTextField sx={{ m: 0, p: 0 }}
                                    Component={TextField}
                                    name="cantidadMujeresDecision"
                                    label="Cant. Mujeres en Puestos de Toma de Decisión"
                                    helperText="Escribe la cantidad"
                                    rules={{ required: "Required!" }}
                                    req="true"
                                    type="number"
                                    variant="standard"
                                />
                            </Grid>
                            <Grid xs={12} item>
                                <CustomTextField sx={{ m: 0, p: 0 }}
                                    Component={TextField}
                                    name="cantidadEmujeres"
                                    label="Cant. Empleados Mujeres"
                                    // helperText="Escribe la cantidad" 
                                    rules={{ required: "Required!" }}
                                    type="number"
                                    variant="standard" />
                            </Grid>
                            <Grid xs={12} item>
                                <CustomTextField sx={{ m: 0, p: 0 }}
                                    Component={TextField}
                                    name="cantidadPdiscapacidad"
                                    label="Cant. Personas con Discapacidad"
                                    // helperText="Escribe la cantidad" 
                                    rules={{ required: "Required!" }}
                                    type="number"
                                    variant="standard" />
                            </Grid>
                            <Grid item xs={12}>
                                <ReCAPTCHA
                                    size={6}
                                    className=""
                                    render="explicit"
                                    sitekey={RecaptchaKey}
                                    onChange={e => robotOnChange(e)}
                                />
                            </Grid>
                        </Paper>
                    </Grid>
                    {/* <Grid xs={12} sm={6} item id="espacio-blanco" style={{ display: 'none' }}></Grid> */}
                </Grid>
            </CardContent >
        </>
    )
}

export default SolicitudAltaAdicional