import React from "react"
import PersonSearchIcon from "@mui/icons-material/PersonSearch"
import AccountTreeIcon from "@mui/icons-material/AccountTree"
import RestoreIcon from "@mui/icons-material/Restore"
import BorderColorIcon from "@mui/icons-material/BorderColor"
import HowToRegIcon from "@mui/icons-material/HowToReg"
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';
import { Stack, Step, StepLabel, Stepper, Typography } from "@mui/material"
import { styled } from '@mui/material/styles'
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector'
import Tooltip from '@mui/material/Tooltip';

const ProgressBarPyme = ({ currentStep }) => {
  var stepPercentage = 0

  if (currentStep === 1) {
    stepPercentage = 0;
  } else if (currentStep === 2) {
    stepPercentage = 1;
  } else if (currentStep === 3) {
    stepPercentage = 2;
  } else if (currentStep === 4) {
    stepPercentage = 3;
  } else if (currentStep === 5) {
    stepPercentage = 4;
  } else {
    stepPercentage = 0;
  }

  const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(21,113,9) 0%,rgb(21,113,9)50%,rgb(9,121,97) 100%)',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        backgroundImage:
          'linear-gradient( 95deg,rgb(21,113,9) 0%,rgb(21,113,9)50%,rgb(9,121,97) 100%)',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      height: 5,
      minWidth: 20,
      border: 0,
      backgroundColor:
        theme.palette.mode === 'dark' ? theme.palette.grey[800] : theme.palette.grey[600],
      borderRadius: 1,
    },
  }));

  return (
    <Stepper
      activeStep={stepPercentage}
      alternativeLabel
      connector={<ColorlibConnector />}
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        gap: { xs: 3, md: 2 },
      }}
    >
      <Step>
        <Tooltip title={<Typography sx={{ color: '#fff' }}>Inicial</Typography>}>
          <StepLabel StepIconComponent={PersonSearchIcon}></StepLabel>
        </Tooltip>
      </Step>
      <Step>
        <Tooltip title={<Typography sx={{ color: '#fff' }}>En Análisis de Riesgo</Typography>}>
          <StepLabel StepIconComponent={TroubleshootIcon} ></StepLabel>
        </Tooltip>
      </Step>
      <Step>
        <Tooltip title={<Typography sx={{ color: '#fff' }}>Alta Pendiente</Typography>}>
          <StepLabel StepIconComponent={RestoreIcon} ></StepLabel>
        </Tooltip>
      </Step>
      <Step>
        <Tooltip title={<Typography sx={{ color: '#fff' }}>Pendiente de Firma</Typography>}>
          <StepLabel StepIconComponent={BorderColorIcon} ></StepLabel>
        </Tooltip>
      </Step>
      <Step>
        <Tooltip title={<Typography sx={{ color: '#fff' }}>Activo</Typography>}>
          <StepLabel StepIconComponent={HowToRegIcon} ></StepLabel>
        </Tooltip>
      </Step>
    </Stepper >
  );
};

export default ProgressBarPyme;
