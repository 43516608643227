const GoogleMapKey = "AIzaSyDwKzicjMvfVsf9SbEAZn9N7i4CxX6YO1I";
const RecaptchaKey = "6LfVo94cAAAAAPyrP3Y07tXqlFsXAZbFYPTs_Tua";
const UrlApiDynamics = "https://sgroc365api.azurewebsites.net/api/";
const UrlApi = "https://hw365api.azurewebsites.net/"
const CorreoAdministracion = "administracion@casfog.com.ar"
//localhost
// const RecaptchaKey = "6LeALiAcAAAAABv5WAeRHUmaf5GfqNSBYn8wTKON";
// const UrlApi = "https://localhost:7035/"
//Sandbox
// const authMail = "admin@casfog.com"
// const authPass = "Casfog.2022$"
// const Entidad = "CasfogOneClick";   
//Produccion
const authMail = "adminprod@casfog.com"
const authPass = "Casfog.2022$"
const Entidad = "CasfogOneClickProd";


export {Entidad, GoogleMapKey, RecaptchaKey, UrlApiDynamics, UrlApi, authMail, authPass, CorreoAdministracion}