import React from 'react'
import { Box, Paper, Grid, Typography, useTheme } from '@mui/material'
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';

const Indicador = ({ titulo, valor, icon }) => {
    const theme = useTheme()
    return (
        <Paper
            elevation={3}
            sx={{
                background: "graficos",
                boxShadow: 1,
                borderRadius: 2,
                p: 2,
                m: 1,
                minWidth: 200,
            }}
        >
            <Grid container direction="row" alignItems="center">
                <Grid xs={11}>
                    <Box variant="h6" sx={{ p: 0, m: 0, fontFamily: theme.typography.fontFamily, color: theme.typography.primary.main }}>
                        <Typography variant="subtitle2" color="inherit" sx={{ fontSize: "1rem" }}>
                            {titulo}
                        </Typography>
                    </Box>
                    <Box>
                        <Typography variant="subtitle2" color="primary" sx={{ fontSize: "1rem", fontWeight: 'bold' }}>
                            {valor}
                        </Typography>
                    </Box>
                </Grid >
                <Grid xs={1}>
                    {icon}
                </Grid>
            </Grid >
        </Paper>
    )
}

export default Indicador