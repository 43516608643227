import React, { useState, useEffect, useContext } from "react"
import {
    Checkbox, Box, Typography, FormControlLabel, useTheme,
    CircularProgress, Button, IconButton, InputLabel, NativeSelect, Modal, fabClasses, FormControl, FormLabel, RadioGroup, Radio
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { MuiFileInput } from 'mui-file-input'
import { useSelector, useDispatch } from "react-redux"
import { actualizarTemplate } from "../../Redux/PlanDeCuotas"
import "react-toastify/dist/ReactToastify.css"
import TokenContext from '../../context/TokenContext'
import Fade from 'react-reveal/Fade';
import Tooltip from '@mui/material/Tooltip';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { toast } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css"
import { obtenerDocumentosGarantiasPorId } from "../../Redux/Garantias"

const ActualizarTemplate = ({ open, setOpen, idGarantia, idAdjuntoGarantia, idNota, visibleEnPortal }) => {
    const dispatch = useDispatch()
    const token = useContext(TokenContext)
    const theme = useTheme()
    const resultado = useSelector(store => store.planCuotas.resultadoActualizacionTemplate)
    const [visiblePortal, setVisiblePortal] = useState(false)
    const [file, setFile] = useState(null)

    useEffect(() => {
        if (visibleEnPortal === true) {
            setVisiblePortal(true)
        } else if (visibleEnPortal === false) {
            setVisiblePortal(false)
        }
    }, [visibleEnPortal])

    useEffect(() => {
        if (resultado === "EXITO") {
            dispatch(obtenerDocumentosGarantiasPorId(idGarantia, token))
            setFile(null)
            handleClose()
        }
    }, [resultado])

    const loadingError = (msj) => {
        toast.error(msj, {
            theme: "dark",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    const subirArchivo = () => {
        if (visiblePortal === null || visiblePortal === undefined) {
            loadingError("El tipo de documento es requerido!")
            return
        }
        // if (file?.length === 0 || file === null) {
        //     loadingError("El archivo adjunto es requerido!")
        //     return
        // }
        let sizes = 0
        const formData = new FormData()
        if (file?.length > 0) {
            for (let index = 0; index < file.length; index++) {
                sizes += file[index].size
                if (file[index].size >= 15000000) {
                    loadingError("El archivo no puede superar los 15 megas")
                    setFile(null)
                    return
                }
                let element = file[index];
                formData.append(`body${index}`, element);
            }
        }
        if (sizes >= 15000000) {
            loadingError("Los archivos adjuntos no puede superar los 15 megas!")
            setFile(null)
            return
        }
        dispatch(actualizarTemplate(token, formData, idAdjuntoGarantia, idNota, visiblePortal))
    }

    const handleChangeFile = (newFile) => {
        setFile(newFile)
    }

    const handleClose = () => {
        // setVisiblePortal({ value: false, label: 'No' })
        setFile(null)
        setOpen(false)
    }

    const opcionesVisible = [
        { value: false, label: ' No ' },
        { value: true, label: ' Si ' },
    ]

    //style del modal
    const style = {
        backgroundColor: theme.palette.background.paper,
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        minWidth: 300,
        maxWidth: 450,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 2,
    };

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            closeAfterTransition
        // sx={{ backgroundColor: theme.palette.background.default }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                        <Typography id="alert-dialog-title" sx={{ fontSize: "1.2rem", pt: 1 }}>
                            Actualizar Template
                        </Typography>
                        <Tooltip title={<Typography sx={{ color: '#fff' }}>Cerrar</Typography>} sx={{ mt: 3 }} >
                            <IconButton
                                edge="end"
                                color="warning"
                                onClick={handleClose}
                                aria-label="close"
                                sx={{ mr: 1 }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box sx={{ my: 1 }}>
                        <FormControl>
                            <FormLabel id="demo-radio-buttons-group-label"> Visible Portal (Sgr No Líder)</FormLabel>
                            <RadioGroup
                                row
                                aria-labelledby="demo-radio-buttons-group-label"
                                defaultValue="female"
                                name="radio-buttons-group"
                                value={visiblePortal}
                                onChange={(e) => setVisiblePortal(e.target.value)}
                            >
                                <FormControlLabel value={true} control={<Radio />} label="Si" />
                                <FormControlLabel value={false} control={<Radio />} label="No" />
                            </RadioGroup>
                        </FormControl>
                    </Box>
                    <Box sx={{ my: 1 }}>
                        <MuiFileInput
                            sx={{ my: 2 }}
                            value={file}
                            multiple
                            label="Adjuntar documentación"
                            onChange={handleChangeFile} />
                    </Box>
                    <Box sx={{ mt: 2, mb: 2, position: 'relative' }}>
                        <Button
                            variant="contained"
                            type="button"
                            disabled={resultado === 'LOADING'}
                            onClick={subirArchivo}
                            fullWidth
                        >
                            Actualizar
                        </Button>
                        {resultado === 'LOADING' && (
                            <CircularProgress
                                size={27}
                                sx={{
                                    color: "#fff",
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )} </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 1 }}>
                        <Box sx={{ height: '100%' }} >
                            <ErrorOutlineIcon color="warning" sx={{ mr: 1 }} />
                        </Box>
                        <Box sx={{ height: '100%' }} >
                            <Typography>
                                El tamaño máximo permitido por archivo es de 15 MB
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Fade>
        </Modal>
    )
}

export default ActualizarTemplate
