import React from "react"
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import InfoIcon from '@mui/icons-material/Info'

const ModalAgregarDesembolso = ({open, setOpen, IsAgregarDesembolso}) => {

    const handleClose = () => {
        setOpen(false)
    };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>
          <InfoIcon color="error" sx={{fontSize:"2rem"}}/>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{px:3}}>
            ¿Desea continuar con el desembolso?
            Se Generará una nueva garantía en cartera, y la actual se inactivará.
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{display:"flex", justifyContent:"space-around", alignItems:"center"}}>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={() => {
                IsAgregarDesembolso(true)
                handleClose()
                }}
                >
                    Aceptar
            </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalAgregarDesembolso;
