import React from "react";
import { Typography } from "@mui/material"
import DescargarNota from "../Components/seleccionarFila/DescargarNota";

export const COLUMNSDOCUMENTOSSERIE = [
  {
    header: "Archivo",
    accessorKey: "filename",
    Cell: ({ cell }) => (
      <Typography sx={{ fontWeight: 600, fontSize: { xs: ".7rem", md: ".8rem" } }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: "Documento",
    accessorKey: "new_documento",
    Cell: ({ cell }) => (
      <Typography sx={{ fontWeight: 600, fontSize: { xs: ".7rem", md: ".8rem" } }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: "Fecha de Vencimiento",
    accessorKey: "new_fechadevencimiento",
    Cell: ({ cell }) => (
      <Typography sx={{ fontWeight: 600, fontSize: { xs: ".7rem", md: ".8rem" } }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: "Descargar",
    minSize: 60, //min size enforced during resizing
    maxSize: 80, //max size enforced during resizing
    accessorKey: "annotationid",
    Cell: ({ cell }) => {
      return <DescargarNota value={cell.getValue()} />
    }
  },
]