import axios from 'axios'
import { Entidad, UrlApi, UrlApiDynamics } from '../Keys'

const dataInicial = {
    loadingGral: false,
    loadingLinea: false,
    loadingLimite: false,
    limites: [],
    limitesGeneral: [],
    limite: {}
}

const LIMITE_GENERAL_EXITO = 'LIMITE_GENERAL_EXITO'
const LIMITE_TODOS_EXITO = 'LIMITE_TODOS_EXITO'
const LIMITE_EXITO = 'LIMITE_EXITO'
const ERROR = 'ERROR'
const LOADING_LIMITE_GRAL = 'LOADING_LIMITE_GRAL'
const LOADING_LIMITE_LINEA = 'LOADING_LIMITE_LINEA'
const LOADING_LIMITE = 'LOADING_LIMITE'

export default function limitesReducers(state = dataInicial, action) {
    switch (action.type) {
        case LIMITE_GENERAL_EXITO:
            return { ...state, limitesGeneral: action.payload, loadingGral: action.loadingGral }
        case LIMITE_TODOS_EXITO:
            return { ...state, limites: action.payload, loadingLinea: action.loadingLinea }
        case LIMITE_EXITO:
            return { ...state, limite: action.payload, loadingLimite: action.loadingLimite }
        case LOADING_LIMITE:
            return { ...state, loadingLimite: action.loadingLimite }
        case LOADING_LIMITE_GRAL:
            return { ...state, loadingGral: action.loadingGral }
        case LOADING_LIMITE_LINEA:
            return { ...state, loadingLinea: action.loadingLinea }
        case ERROR:
            return { ...dataInicial }
        default:
            return { ...state }
    }
}

export const obtenerTodosLimitesPorLineasGeneral = (accountid) => async (dispatch) => {
    dispatch({
        type: LOADING_LIMITE_GRAL,
        loadingGral: true
    })

    try {

        var entidad = "new_productoses";
        var fetch = "<fetch mapping='logical' distinct='false'>" +
            "<entity name='new_productos'>" +
            "<attribute name='new_topeporlineacomercial' />" +
            "<attribute name='new_montodisponibleporoperacion' />" +
            "<attribute name='statuscode' />" +
            "<attribute name='transactioncurrencyid' />" +
            "<attribute name='new_topeporlineacomercialusd' />" +
            "<attribute name='new_montoutilizadoporoperacion' />" +
            "<attribute name='new_tipochpd' />" +
            "<attribute name='new_lineatipodeoperacion' />" +
            "<attribute name='new_productosid' />" +
            "<attribute name='new_montoutilizadogeneral' />" +
            "<attribute name='new_montodisponiblegeneral' />" +
            // "<attribute name='new_mostrarenportalsocio' />" +
            "<order attribute='transactioncurrencyid' descending='false' />" +
            "<filter type='and'>" +
            "<condition attribute='statecode' operator='eq' value='0' />" +
            "<condition attribute='new_lineatipodeoperacion' operator='eq' value='100000000' />" +
            "<condition attribute='new_cuenta' operator='eq' uitype='account' value='" + accountid + "' />" +
            // "<condition attribute='new_mostrarenportalsocio' operator='eq' value='1' />" +
            "</filter>" +
            "</entity>" +
            "</fetch>";

        const response = await axios.get(`${UrlApiDynamics}ConsultaFetch?Entidad=${entidad}&fetch=${fetch}&cuit=${Entidad}`)
        dispatch({
            type: LIMITE_GENERAL_EXITO,
            payload: response.data,
            loadingGral: false
        })
    } catch (error) {
        dispatch({
            type: ERROR,
            loadingGral: false
        })
    }
}

export const obtenerTodosLimitesPorLineas = (token, accountid) => async (dispatch) => {
    dispatch({
        type: LOADING_LIMITE_LINEA,
        loadingLinea: true
    })
    try {
        if(token && accountid){
            var entidad = "new_productoses";
            var fetch = "<fetch mapping='logical' distinct='false'>" +
                "<entity name='new_productos'>" +
                "<attribute name='new_topeporlineacomercial' />" +
                "<attribute name='new_montodisponibleporoperacion' />" +
                "<attribute name='statuscode' />" +
                "<attribute name='transactioncurrencyid' />" +
                "<attribute name='new_topeporlineacomercialusd' />" +
                "<attribute name='new_montoutilizadoporoperacion' />" +
                "<attribute name='new_tipochpd' />" +
                "<attribute name='new_lineatipodeoperacion' />" +
                "<attribute name='new_productosid' />" +
                // "<attribute name='new_mostrarenportalsocio' />" +
                "<order attribute='transactioncurrencyid' descending='false' />" +
                "<filter type='and'>" +
                "<condition attribute='statecode' operator='eq' value='0' />" +
                "<condition attribute='new_lineatipodeoperacion' operator='ne' value='100000000' />" +
                "<condition attribute='new_cuenta' operator='eq' uitype='account' value='" + accountid + "' />" +
                // "<condition attribute='new_mostrarenportalsocio' operator='eq' value='1' />" +
                "</filter>" +
                "</entity>" +
                "</fetch>";
    
                await axios.post(`${UrlApi}api/consultafetch`,
                {
                    "entidad": entidad,
                    "fetch": fetch
                },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then((response) => {
                    dispatch({
                        type: LIMITE_TODOS_EXITO,
                        payload: response.data,
                        loadingLinea: false
                    })
                })
                .catch(err => {
                    console.log(err)
                })
        }
    } catch (error) {
        dispatch({
            type: ERROR,
            loadingLinea: false
        })
    }
}

export const obtenerLimitePorLinea = (accountid) => async (dispatch) => {
    dispatch({
        type: LOADING_LIMITE,
        loadingLimite: true
    })

    try {
        if (accountid != undefined) {
            const response = await axios.get(`${UrlApiDynamics}Limiteporlinea?filter=new_lineatipodeoperacion eq 100000000 and _new_cuenta_value eq ${accountid}&cuit=${Entidad}`)
            const limite = response.data
            console.log("limite", limite)
            dispatch({
                type: LIMITE_EXITO,
                payload: limite[0],
                loadingLimite: false
            })
        }
    } catch (error) {
        dispatch({
            type: ERROR,
            loadingLimite: false
        })
    }
}