import React, { useState, useEffect, useContext } from "react";
import {
    Dialog, useTheme, Box, DialogContent, DialogContentText, Toolbar, Typography, IconButton,
    CircularProgress, AppBar, Tab, FormControl, TextField, Button, Paper, InputLabel, NativeSelect, Divider, Tooltip, Grid
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import LockIcon from '@mui/icons-material/Lock'
import Brightness1Icon from '@mui/icons-material/Brightness1'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from "react-redux"
import Tabla from "../Tabla"
import { FormProvider, useForm } from "react-hook-form"
import ModalSubirAdjuntoGarantia from "./ModalSubirAdjuntoGarantia"
import CustomTextField from "../CustomTextField"
import CurrencyTextField from "../CustomCurrencyField"
import { desembolsoGarantia, editarGarantias, obtenerDesembolsoAnteriorId, obtenerDocumentosGarantiasPorId } from "../../Redux/Garantias"
import TokenContext from '../../context/TokenContext'
import { setModalGarantias } from "../../Redux/AbrirModal"
import { obtenerPlanCuotas } from "../../Redux/PlanDeCuotas"
import { COLUMNSPLANCUOTAS } from "../../Columnas/ColumnsPlanesDeCuotas"
import { COLUMNSDOCUMENTOS } from "../../Columnas/ColumnasDocumentosGarantias"
import { COLUMNSDESEMBOLSOANTERIOR } from "../../Columnas/ColumnasGarantiaDesembolsoAnterio"
import Moment from 'moment'
import ModalAgregarDesembolso from "./ModalAgregarDesembolso";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { es } from 'date-fns/locale'
import { Controller } from 'react-hook-form';
import { green } from '@mui/material/colors';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import 'moment/locale/es';
import ModalTemplateGarantia from "./ModalTemplateGarantia";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

const ModalGarantias = ({ id, open, garantia, name, nameSGRLider }) => {
    const dispatch = useDispatch()
    const token = useContext(TokenContext)
    const theme = useTheme()
    const resultadoPlanCuotas = useSelector(store => store.planCuotas.resultadoPlanCuotas)
    const retrievePlanCuotas = useSelector(store => store.planCuotas.retrievePlanDeCuotas)
    const retrieveDesembolso = useSelector(store => store.planCuotas.retriveDesembolsoAnterior)
    const planCuotas = useSelector(store => store.planCuotas.planCuotas)
    const documentosGarantiasPorId = useSelector(store => store.garantias.documentosGarantiasPorId)
    const resultadoDocumentoPorID = useSelector(store => store.garantias.resultadoDocumentoPorID)
    const desembolsoAnteriorSelector = useSelector(store => store.garantias.documentosDesembolsoAnterior)
    const resultadoDesembolsoAnterior = useSelector(store => store.garantias.resultadoDesembolsoAnterior)
    const garantiaModificadaResultado = useSelector(store => store.garantias.garantiaModificadaResultado)
    const desembolsoGarantiaResultado = useSelector(store => store.garantias.desembolsoGarantiaResultado)
    const [value, setValue] = useState('0')
    const [openModalAdjunto, setOpenModalAdjunto] = useState(false)
    const [openModalTemplate, setOpenModalTemplate] = useState(false)
    const [disabled, setDisabled] = useState(false)
    const [disabledEstado, setDisabledEstado] = useState(false)
    const [isSGRLider, setIsSGRLider] = useState(garantia?.some(item => item['serie.new_sgrlider'] == JSON.parse(localStorage.getItem('Id SGR'))))
    const [estadoGarantia, setEstadoGarantia] = useState({ value: garantia[0].statuscode })
    const [determinadaAsamblea, setDeterminadaAsamblea] = useState({ value: garantia[0].new_determinadaenasamblea, label: garantia[0]['new_determinadaenasamblea@OData.Community.Display.V1.FormattedValue'] })
    const [opcionesDictamenAval, setOpcionesDictamenAval] = useState([])
    const [dictamenAval, setDictamenAval] = useState({ value: garantia[0].new_dictamendelaval, label: garantia[0]['statuscode@OData.Community.Display.V1.FormattedValue'] })
    const [defaultValues, setDefaultValues] = useState({
        tipoDeOperación: garantia[0]['new_tipodeoperacion@OData.Community.Display.V1.FormattedValue'],
        codigo: garantia[0].new_codigo,
        pyme: garantia[0]['cuenta.name'],
        cuit: garantia[0]['cuenta.new_nmerodedocumento'],
        estadoDelSocio: garantia[0]["cuenta.new_estadodelsocio"],
        fechaDeCarga: garantia[0].new_fechadeorigen ? garantia[0].new_fechadeorigen : '',
        montocomprometido: garantia[0].new_montocomprometidodelaval != null ? garantia[0].new_montocomprometidodelaval : 0,
        acreedor: garantia[0]['_new_acreedor_value@OData.Community.Display.V1.FormattedValue'],
        montoBruto: garantia[0].new_monto != null ? garantia[0].new_monto : 0,
        divisa: garantia[0]['_transactioncurrencyid_value@OData.Community.Display.V1.FormattedValue'],
        serie: garantia[0]['serie.new_name'],
        serieid: garantia[0]['serie.new_seriedeoperacinsindicadaid'],
        fechaCancelada: garantia[0].new_fechadecancelada ? garantia[0].new_fechadecancelada : '',
        tipoGarantía: garantia[0]['new_tipodegarantias@OData.Community.Display.V1.FormattedValue'],
        fechaEstimadaPago: garantia[0].new_fechadepago ? garantia[0].new_fechadepago : '',
        fechaRealPago: garantia[0].new_fecharealdepago ? garantia[0].new_fecharealdepago : '',
        saldoOperativo: garantia[0].new_saldooperativo,
        saldoVigente: garantia[0].new_saldovigente,
        saldoVigentePonderado: garantia[0].new_saldovigenteponderado,
        montoDesembolsa: garantia[0].new_montoadesembolsar,
        fechaEmision: garantia[0].new_fechaemisindelcheque ? garantia[0].new_fechaemisindelcheque : '',
        fechaVencimiento: garantia[0].new_fechadevencimiento ? garantia[0].new_fechadevencimiento : '',
        numeroPrestamo: garantia[0].new_numerodeprestamo,
        plazoDias: garantia[0].new_plazodiasprueba,
        oficialCuentas: garantia[0].new_oficialdecuentas,
        saldoCuotasOperativo: garantia[0].new_saldocuotasoperativo,
        saldoCuotasVigente: garantia[0].new_saldocuotasvigentes,
        fechaMonetizacion: garantia[0].new_fechadenegociacion ? garantia[0].new_fechadenegociacion : '',
        porcentaje: garantia[0].new_ponderacion,
        importePonderacion: garantia[0].new_importeponderacion,
        categoria: garantia[0].new_categoriatipodesocio,
        tipodeSocio: garantia[0].new_ponderacionportipodesocio,
        ponderadorAplicable: garantia[0].new_podenderaciondegarantias,
        sistemaAmortización: garantia[0]['new_sistemadeamortizacion@OData.Community.Display.V1.FormattedValue'],
        tasa: garantia[0]['new_tasa@OData.Community.Display.V1.FormattedValue'],
        puntosPorcentuales: garantia[0].new_puntosporcentuales,
        periodoGracia: garantia[0].new_periodogracia,
        periodicidadDePagos: garantia[0]['new_periodicidadpagos@OData.Community.Display.V1.FormattedValue'],
        new_observaciones: garantia[0].new_observaciones
    })
    const [tablaPlanDeCuotas, setTablaPlanDeCuotas] = useState([])
    const [logPlanesCuotas, setLogPlanesCuotas] = useState(false)
    const [documentos, setDocumentos] = useState([])
    const [logDocumentos, setLogDocumentos] = useState(false)
    const [garantiaAnterior, setGarantiaAnterior] = useState([])
    const [logGarantiasAnteriores, setLogGarantiasAnteriores] = useState(false)
    const [visibleDesembolso, setVisibleDesembolso] = useState(false)
    const [log, setLog] = useState(true)
    const [openModalAgregarDesembolso, setOpenModalAgregarDesembolso] = useState(false)
    const [isAgregarDesembolso, setIsAgregarDesembolso] = useState(false)
    const [planDeCuotasCargado, setPlanDeCuotasCargado] = useState(false)
    const [desembolsoCargado, setDesembolsoCargado] = useState(false)
    const [contieneTemplateGarantia, setContieneTemplateGarantia] = useState(false)

    useEffect(() => {
        if (isSGRLider && garantia[0]['serie.statuscode'] == 100000001) {
            setDisabled(false)
        } else {
            setDisabled(true)
        }
        if (garantia?.length > 0) {
            setOpcionesDictamenAval(CompletarOpcionesDeDictamenPorEstadoGarantia(garantia[0].statuscode))
        }
        if (!disabled && garantia[0].statuscode == 100000004) {
            setDisabledEstado(false)
        } else {
            setDisabledEstado(true)
        }
        if ((garantia[0].new_montoadesembolsar != undefined && garantia[0].new_montoadesembolsar > 0) &&
            (garantia[0]['statuscode@OData.Community.Display.V1.FormattedValue'] != undefined && garantia[0]['statuscode@OData.Community.Display.V1.FormattedValue'] == "Vigente")) {
            setVisibleDesembolso(true)
        } else {
            setVisibleDesembolso(false)
        }
    }, [garantia, isSGRLider])

    useEffect(() => {
        if (planCuotas?.length > 0) {
            setTablaPlanDeCuotas(planCuotas)
            setPlanDeCuotasCargado(true)
        } else if (planCuotas?.length === 0 && retrievePlanCuotas == true) {
            setPlanDeCuotasCargado(true)
        }
    }, [planCuotas, retrievePlanCuotas])

    useEffect(() => {
        if (documentosGarantiasPorId?.length > 0 ) {
            let docsGaran = []
            documentosGarantiasPorId.forEach(item => {
                if (item["filename"] != undefined) {
                    let garan = {
                        garantia: item["ae.new_garantia@OData.Community.Display.V1.FormattedValue"],
                        filename: item.filename,
                        type: item["ae.new_tipo@OData.Community.Display.V1.FormattedValue"],
                        tipo: item["ae.new_tipo"],
                        annotationid: item.annotationid,
                        visiblePortal:  item["ae.new_visibleenportal"],
                        nota: {
                            annotationid: item.annotationid,
                            upload: (item["ae.new_tipo"] === 100000006 && !disabled) ? true : false,
                            garantiaId: id,
                            new_adjuntosid: item["ae.new_adjuntosid"],
                            visiblePortal:  item["ae.new_visibleenportal"],
                        }
                    }
                    if(garan.tipo !== 100000006){
                        docsGaran.push(garan)
                    }else if(garan.tipo === 100000006 && !disabled){
                        setContieneTemplateGarantia(true)
                        docsGaran.push(garan)
                    }
                    else if(garan.tipo === 100000006 && disabled && garan.visiblePortal === true){
                        setContieneTemplateGarantia(true)
                        docsGaran.push(garan)
                    }
                }
            })
            setDocumentos(docsGaran)
            setLog(false)
        }
    }, [documentosGarantiasPorId])

    useEffect(() => {
        if (desembolsoAnteriorSelector?.length > 0) {
            if (desembolsoAnteriorSelector[0]['desembolso1.new_ndeordendelagarantiaotorgada'] !== undefined) {
                var items = []
                desembolsoAnteriorSelector.forEach((item) => {
                    let desembolso1 = {
                        orden: item['desembolso1.new_ndeordendelagarantiaotorgada'],
                        socio: item['desembolso1.new_socioparticipe@OData.Community.Display.V1.FormattedValue'],
                        acreedor: item['desembolso1.new_acreedor@OData.Community.Display.V1.FormattedValue'],
                        fecha: item['desembolso1.new_fechadenegociacion@OData.Community.Display.V1.FormattedValue'],
                        monto: item['desembolso1.new_monto@OData.Community.Display.V1.FormattedValue'],
                        garantiaid: item['desembolso1.new_garantiaid'],
                    }
                    let desembolso2 = {
                        orden: item['desembolso2.new_ndeordendelagarantiaotorgada'],
                        socio: item['desembolso2.new_socioparticipe@OData.Community.Display.V1.FormattedValue'],
                        acreedor: item['desembolso2.new_acreedor@OData.Community.Display.V1.FormattedValue'],
                        fecha: item['desembolso2.new_fechadenegociacion@OData.Community.Display.V1.FormattedValue'],
                        monto: item['desembolso2.new_monto@OData.Community.Display.V1.FormattedValue'],
                        garantiaid: item['desembolso2.new_garantiaid'],
                    }
                    items.push(desembolso1)
                    items.push(desembolso2)
                })
                setGarantiaAnterior(items)
                setDesembolsoCargado(true)
            }
        } else if (desembolsoAnteriorSelector?.length === 0 && retrieveDesembolso == true) {
            setDesembolsoCargado(true)
        }
    }, [resultadoDesembolsoAnterior, retrieveDesembolso])

    useEffect(() => {
        if (desembolsoGarantiaResultado === "EXITO") {
            setTimeout(() => {
                handleClose()
            }, 5000);
        }
    }, [desembolsoGarantiaResultado])


    const validarEstadoYSetear = (value) => {
        if (value.value === "1")//estado === En Gestion
        {
            if (defaultValues.estadoDelSocio !== 100000000)//Socio Activo
            {
                toast.error('El socio debe estar "Activo" para cambiar el estado de la garantía a "En Gestión"', {
                    theme: "dark",
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setEstadoGarantia({ value: garantia[0].statuscode })
                return
            } else {
                setEstadoGarantia(value)
            }
        } else {
            setEstadoGarantia(value)
        }
    }

    const ActualizarGarantias = (datos) => {
        const { montoBruto, fechaDeCarga, fechaEmision, numeroPrestamo, puntosPorcentuales, fechaMonetizacion, fechaVencimiento, montocomprometido } = datos

        if (fechaDeCarga === '' || fechaDeCarga === undefined) {
            toast.error('La fecha de carga es requerida!', {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }
        if (fechaDeCarga >= fechaVencimiento) {
            toast.error('La Fecha de Carga es mayor o igual que la Fecha de Vencimiento del Préstamo', {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }
        if (montoBruto === '') {
            toast.error('El monto bruto es requerido!', {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }
        if (fechaEmision != '' && fechaEmision != undefined) {
            if (fechaVencimiento != undefined && fechaEmision > fechaVencimiento) {
                toast.error('La fecha de emisión no puede ser mayor a la fecha de vencimiento', {
                    theme: "dark",
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return
            }
        }
        if (fechaMonetizacion != '' && fechaMonetizacion != undefined) {
            if (fechaVencimiento == '' || fechaVencimiento == undefined) {
                toast.error('La fecha de vencimiento debe estar completa para monetizar la garantía', {
                    theme: "dark",
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return
            } else if (fechaVencimiento < fechaMonetizacion || fechaVencimiento == undefined) {
                toast.error('La fecha de vencimiento no puede ser menor a la fecha de monetización', {
                    theme: "dark",
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return
            }
        }
        if (dictamenAval.value == 100000002 || dictamenAval.value == 100000003) {
            if (determinadaAsamblea.value == '' || determinadaAsamblea.value == undefined) {
                toast.error('El campo determinada en asamblea es requerido', {
                    theme: "dark",
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                return
            }
        }
        dispatch(editarGarantias(token, montoBruto, fechaDeCarga ? Moment(fechaDeCarga).format("YYYY-MM-DD") : "", garantia[0]._new_acreedor_value, Number(estadoGarantia.value),
            fechaEmision ? Moment(fechaEmision).format("YYYY-MM-DD") : "", numeroPrestamo, fechaMonetizacion ? Moment(fechaMonetizacion).format("YYYY-MM-DD") : "",
            garantia[0].new_sistemadeamortizacion, garantia[0].new_tasa, puntosPorcentuales, garantia[0].new_periodicidadpagos, dictamenAval.value, garantia[0].new_creditoaprobado?.toString(), garantia[0].new_codigo,
            garantia[0].new_tipodegarantias, fechaVencimiento ? Moment(fechaVencimiento).format("YYYY-MM-DD") : "", id, montocomprometido, determinadaAsamblea.value ? String(determinadaAsamblea.value) : "", garantia[0]._new_socioparticipe_value,
            garantia[0]._new_nmerodeserie_value, garantia[0].new_tipodeoperacion
        ))
    }
    const DesembolsoParcial = (datos) => {
        const { montoBruto, fechaDeCarga, fechaEmision, numeroPrestamo, puntosPorcentuales, fechaMonetizacion, fechaVencimiento, montocomprometido } = datos

        dispatch(desembolsoGarantia(token, montoBruto, garantia[0]._new_acreedor_value, 0, fechaEmision ? Moment(fechaEmision).format("YYYY-MM-DD") : "",
            numeroPrestamo, fechaMonetizacion ? Moment(fechaMonetizacion).format("YYYY-MM-DD") : "", garantia[0].new_sistemadeamortizacion, garantia[0].new_tasa, puntosPorcentuales,
            garantia[0].new_periodicidadpagos, garantia[0].new_creditoaprobado?.toString(), garantia[0].new_codigo, garantia[0].new_tipodegarantias,
            fechaVencimiento ? Moment(fechaVencimiento).format("YYYY-MM-DD") : "", id, montocomprometido, determinadaAsamblea.value ? String(determinadaAsamblea.value) : "", garantia[0]._new_socioparticipe_value,
            garantia[0]._new_nmerodeserie_value, garantia[0].new_tipodeoperacion, garantia[0].new_garantiaid

        ))
        setIsAgregarDesembolso(false)
    }

    const convertirFecha = (fechaAConvertir) => {
        let objectDate = new Date(fechaAConvertir);
        let day = objectDate.getDate();
        let month = objectDate.getMonth();
        let year = objectDate.getFullYear();
        let fechaConvertida = year + '/' + month + '/' + day
        return fechaConvertida
    }

    const obtenerPlanesDeCuotas = () => {
        dispatch(obtenerPlanCuotas(id, token))
    }

    const obtenerDocumentos = () => {
        setDocumentos([])
        setLogDocumentos(false)
        setLog(true)
        dispatch(obtenerDocumentosGarantiasPorId(id, token))
        setTimeout(() => {
            setLogDocumentos(true)
        }, 2500);
    }

    const obtenerDocsAnteriorPorId = () => {
        setGarantiaAnterior([])
        setDocumentos([])
        setLogGarantiasAnteriores(false)
        setLog(true)
        dispatch(obtenerDesembolsoAnteriorId(id, token))
        setDesembolsoCargado(false)
        setTimeout(() => {
            setLogGarantiasAnteriores(true)
        }, 2500);
    }

    const methods = useForm({
        shouldUnregister: false,
        defaultValues,
        mode: "onChange"
    })
    const { handleSubmit } = methods

    const handleClose = () => {
        dispatch(setModalGarantias(false))
        setDocumentos([])
        setGarantiaAnterior([])
    }
    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    const OpenModalAdjuntos = () => {
        setOpenModalAdjunto(true)
    }

    const OpenModalTemplate = () => {
        setOpenModalTemplate(true)
    }

    const setBotonAgregarDesembolso = () => {
        setOpenModalAgregarDesembolso(true)
    }

    const buttonSx = {
        ...(resultadoPlanCuotas === 'LOADING' && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        }),
    };

    const CompletarOpcionesDeDictamenPorEstadoGarantia = (estadoGa) => {
        switch (estadoGa) {
            case 100000004:
                return [{ value: '100000000', label: 'En Cartera' }]
            case 1:
                return [
                    { value: '100000001', label: 'En Gestión' },
                    { value: '100000002', label: 'Aprobado' },
                    { value: '100000003', label: 'Rechazado' },
                    { value: '100000004', label: 'Consideracion Asamblea' },
                    { value: '100000005', label: 'Completando Legajo' },
                    { value: '100000006', label: 'Aval Emitido' },
                ]
            case 100000001:
                return [
                    { value: '100000007', label: 'Desembolso Total' },
                    { value: '100000008', label: 'Desembolso Parcial' }
                ]
            default:
                return []
        }
    }

    const opcionesEstadoGar = [
        { value: 1, label: 'En Gestión' },
        { value: 100000004, label: 'En Cartera' }
    ]

    const opcionesDeterminadaAsamblea = [
        { value: undefined, label: '---' },
        { value: 'true', label: 'SI' },
        { value: 'false', label: 'NO' },
    ]

    return (
        <>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <AppBar sx={{ position: 'relative', backgroundColor: theme.palette.background.common }}>
                    <Toolbar sx={{ display: "flex", flexDirection: "row", justifyContent: { xs: "center", md: "space-between" }, alignItems: { xs: "flex-start", md: "center" } }}>
                        <Box
                            sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, justifyContent: { xs: "center", md: "space-between" }, alignItems: { xs: "flex-start", md: "center" }, width: "95%", gap: { xs: 2 } }}
                        >
                            <Typography sx={{ fontSize: { xs: ".9rem", md: "1.1rem" }, mt: { xs: 2, md: 0 } }}>
                                {`${name}`}
                            </Typography>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Brightness1Icon sx={{ mr: 2, fontSize: ".9rem", color: '#43a047' }} />
                                <Typography sx={{ fontSize: { xs: ".9rem", md: "1.1rem" } }}>
                                    {`${nameSGRLider} Lider`}
                                </Typography>
                            </Box>
                            {!disabled && <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Button
                                    type="button"
                                    onClick={OpenModalTemplate}
                                    variant="text"
                                    color="inherit"
                                >
                                    <InsertDriveFileIcon sx={{ mr: 1 }} />
                                    <Typography
                                        color="inherit"
                                        sx={{ fontSize: { xs: ".9rem", md: "1.1rem" } }}
                                    >
                                        Generar Plantilla
                                    </Typography>
                                </Button>
                            </Box>}
                            {!disabled && <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                <Button
                                    type="button"
                                    onClick={OpenModalAdjuntos}
                                    variant="text"
                                    color="inherit"
                                >
                                    <CloudUploadIcon sx={{ mr: 1 }} />
                                    <Typography
                                        color="inherit"
                                        sx={{ fontSize: { xs: ".9rem", md: "1.1rem" } }}
                                    >
                                        Subir Adjunto
                                    </Typography>
                                </Button>
                            </Box>}
                        </Box>
                        <Tooltip title={<Typography sx={{ color: '#fff' }}>Cerrar</Typography>} >
                            <IconButton
                                edge="end"
                                color="warning"
                                onClick={handleClose}
                                aria-label="close"
                                sx={{ mr: 1 }}
                            >
                                <CloseIcon sx={{ fontSize: { xs: "1.2rem", md: "1.5rem" } }} />
                            </IconButton>
                        </Tooltip>
                    </Toolbar>
                </AppBar>
                <DialogContent sx={{ backgroundColor: theme.palette.background.default }}>
                    <DialogContentText id="alert-dialog-description">
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, mb: 1, borderColor: 'divider', px: 4 }}>
                                <TabList
                                    onChange={handleChange}
                                    aria-label="basic tabs example"
                                    flexContainer="true"
                                    scroller="true"
                                    variant="scrollable"
                                    indicator
                                >
                                    <Tab label="General" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="0" />
                                    <Tab label="Datos del Préstamo" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="1" />
                                    <Tab label="Monetización y Ponderación" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="2" />
                                    <Tab label="Documentos" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="3" />
                                    <Tab label="Desembolsos anteriores" sx={{ color: theme.typography.primary.main, fontSize: { xs: ".7rem", md: ".9rem", fontWeight: '500' } }} value="4" />
                                </TabList>
                            </Box>
                            <FormProvider {...methods}>
                                <TabPanel value="0" sx={{ p: "0px" }}>
                                    <Paper elevation={3} sx={{ p: 3 }}>
                                        <Grid container spacing={1} sx={{ px: { xs: 1, md: 4 } }}>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            readOnly={true}
                                                            size="small"
                                                            type="text"
                                                            name="tipoDeOperación"
                                                            label="Tipo de Operación"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            readOnly={true}
                                                            size="small"
                                                            type="text"
                                                            name="codigo"
                                                            label="Código"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            readOnly={true}
                                                            size="small"
                                                            type="text"
                                                            name="pyme"
                                                            label="Pyme"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            readOnly={true}
                                                            size="small"
                                                            type="text"
                                                            name="cuit"
                                                            label="CUIT"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, mt: 1, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <Controller
                                                        fullwidth="true"
                                                        name="fechaDeCarga"
                                                        sx={{ m: 1 }}
                                                        render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
                                                            <FormControl fullWidth sx={{ m: 1, mt: 3, width: { xs: "40vh", md: "38vw" } }}>
                                                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
                                                                    <DesktopDatePicker
                                                                        readOnly={true}
                                                                        {...field}
                                                                        views={['day', 'month', 'year']}
                                                                        inputRef={ref}
                                                                        label="Fecha de Carga:"
                                                                        renderInput={(inputProps) => (
                                                                            <TextField
                                                                                {...inputProps}
                                                                                onBlur={onBlur}
                                                                                size="small"
                                                                                name={name}
                                                                                error={!!fieldState.error}
                                                                                helperText={fieldState.error ? fieldState.error.message : null}
                                                                                rules={{ required: "Required!" }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            </FormControl>
                                                        )}
                                                    />
                                                    {/* <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            readOnly={disabled}
                                                            size="small"
                                                            type="date"
                                                            name="fechaDeCarga"
                                                            label="Fecha de Carga"
                                                        />
                                                    </FormControl> */}
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            readOnly={true}
                                                            size="small"
                                                            type="text"
                                                            name="acreedor"
                                                            label="Acreedor"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    {disabled ? <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} /> : <LockIcon sx={{ color: "transparent" }} />}
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CurrencyTextField
                                                            readOnly={disabled}
                                                            size="small"
                                                            type="text"
                                                            name="montocomprometido"
                                                            label="Monto comprometido"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            readOnly={true}
                                                            size="small"
                                                            type="text"
                                                            name="divisa"
                                                            label="Divisa"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    {disabled ? <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} /> : <LockIcon sx={{ color: "transparent" }} />}
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CurrencyTextField
                                                            readOnly={disabled}
                                                            type="text"
                                                            size="small"
                                                            name="montoBruto"
                                                            label="Monto Bruto"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    {disabledEstado ? <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} /> : <LockIcon sx={{ color: "transparent" }} />}
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <InputLabel variant="standard" htmlFor="estadoDeLaGarantia" sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}>
                                                            Estado de la Garantia
                                                        </InputLabel>
                                                        <NativeSelect
                                                            defaultValue={estadoGarantia.value}
                                                            disabled={disabled}
                                                            onChange={(e) => validarEstadoYSetear({ value: e.target.value })}
                                                            value={estadoGarantia.value}
                                                            inputProps={{
                                                                name: "estadoDeLaGarantia",
                                                                id: "estadoDeLaGarantia",
                                                                style: { fontSize: ".8rem" },
                                                            }}
                                                        >
                                                            {opcionesEstadoGar.map((item) => {
                                                                return <option key={item.value} value={item.value}>{item.label}</option>;
                                                            })}
                                                        </NativeSelect>
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    {disabled ? <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} /> : <LockIcon sx={{ color: "transparent" }} />}
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <InputLabel variant="standard" htmlFor="determinadaEnAsamblea" sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}>
                                                            Determinada en Asamblea
                                                        </InputLabel>
                                                        <NativeSelect
                                                            defaultValue={determinadaAsamblea.value}
                                                            disabled={disabled}
                                                            onChange={(e) => { setDeterminadaAsamblea({ value: e.target.value }) }}
                                                            inputProps={{
                                                                name: "determinadaEnAsamblea",
                                                                id: "determinadaEnAsamblea",
                                                                style: { fontSize: ".8rem" },
                                                            }}
                                                        >
                                                            {opcionesDeterminadaAsamblea.map((item) => {
                                                                return <option key={item.value} value={item.value}>{item.label}</option>;
                                                            })}
                                                        </NativeSelect>
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    {disabled ? <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} /> : <LockIcon sx={{ color: "transparent" }} />}
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <InputLabel variant="standard" htmlFor="dictamenAval" sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: 2 }}>
                                                            Dictamen de Aval
                                                        </InputLabel>
                                                        <NativeSelect
                                                            defaultValue={dictamenAval.value}
                                                            disabled={disabled}
                                                            onChange={(e) => { setDictamenAval({ value: e.target.value }) }}
                                                            inputProps={{
                                                                name: "dictamenAval",
                                                                id: "dictamenAval",
                                                                style: { fontSize: ".8rem" },
                                                            }}
                                                        >
                                                            {opcionesDictamenAval.map((item) => {
                                                                return <option key={item.value} value={item.value}>{item.label}</option>;
                                                            })}
                                                        </NativeSelect>
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Divider sx={{ my: 3 }} />
                                        <Typography sx={{ fontSize: "1rem", ml: 4 }} color="inherit">Serie Asociada</Typography>
                                        <Grid container spacing={1} sx={{ px: { xs: 1, md: 4 } }}>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            readOnly={true}
                                                            size="small"
                                                            type="text"
                                                            name="serie"
                                                            label="Serie"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Divider sx={{ my: 3 }} />
                                        <Typography sx={{ fontSize: "1rem", ml: 4 }} color="inherit">Datos Adicionales</Typography>
                                        <Grid container spacing={1} sx={{ px: { xs: 1, md: 4 } }}>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, mt: 1, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <Controller
                                                        fullwidth="true"
                                                        name="fechaCancelada"
                                                        sx={{ m: 1 }}
                                                        render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
                                                            <FormControl fullWidth sx={{ m: 1, mt: 3, width: { xs: "40vh", md: "38vw" } }}>
                                                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
                                                                    <DesktopDatePicker
                                                                        {...field}
                                                                        views={['day', 'month', 'year']}
                                                                        inputRef={ref}
                                                                        label="Fecha de Cancelada:"
                                                                        readOnly={true}
                                                                        renderInput={(inputProps) => (
                                                                            <TextField
                                                                                readOnly={disabled}
                                                                                {...inputProps}
                                                                                onBlur={onBlur}
                                                                                size="small"
                                                                                name={name}
                                                                                error={!!fieldState.error}
                                                                                helperText={fieldState.error ? fieldState.error.message : null}
                                                                                rules={{ required: "Required!" }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            </FormControl>
                                                        )}
                                                    />
                                                    {/* <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            readOnly={true}
                                                            size="small"
                                                            type="date"
                                                            name="fechaCancelada"
                                                            label="Fecha de Cancelada"
                                                        />
                                                    </FormControl> */}
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            readOnly={true}
                                                            size="small"
                                                            type="text"
                                                            name="tipoGarantía"
                                                            label="Tipo de Garantía"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Divider sx={{ my: 3 }} />
                                        <Typography sx={{ fontSize: "1rem", ml: 4 }} color="inherit">Fechas de Pago</Typography>
                                        <Grid container spacing={1} sx={{ px: { xs: 1, md: 4 } }}>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, mt: 1, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <Controller
                                                        fullwidth="true"
                                                        name="fechaEstimadaPago"
                                                        sx={{ m: 1 }}
                                                        render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
                                                            <FormControl fullWidth sx={{ m: 1, mt: 3, width: { xs: "40vh", md: "38vw" } }}>
                                                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
                                                                    <DesktopDatePicker
                                                                        {...field}
                                                                        views={['day', 'month', 'year']}
                                                                        inputRef={ref}
                                                                        label="Fecha Estimada de Pago:"
                                                                        readOnly={true}
                                                                        renderInput={(inputProps) => (
                                                                            <TextField
                                                                                readOnly={disabled}
                                                                                {...inputProps}
                                                                                onBlur={onBlur}
                                                                                size="small"
                                                                                name={name}
                                                                                error={!!fieldState.error}
                                                                                helperText={fieldState.error ? fieldState.error.message : null}
                                                                                rules={{ required: "Required!" }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            </FormControl>
                                                        )}
                                                    />
                                                    {/* <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            readOnly={true}
                                                            size="small"
                                                            type="date"
                                                            name="fechaEstimadaPago"
                                                            label="Fecha Estimada de Pago"
                                                        />
                                                    </FormControl> */}
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            readOnly={true}
                                                            size="small"
                                                            type="text"
                                                            name="fechaRealPago"
                                                            label="Fecha Real de Pago"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Divider sx={{ my: 3 }} />
                                        <Typography sx={{ fontSize: "1rem", ml: 4 }} color="inherit">Saldos</Typography>
                                        <Grid container spacing={1} sx={{ px: { xs: 1, md: 4 } }}>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CurrencyTextField
                                                            readOnly={true}
                                                            size="small"
                                                            type="number"
                                                            name="saldoOperativo"
                                                            label="Saldo Operativo"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CurrencyTextField
                                                            readOnly={true}
                                                            size="small"
                                                            type="text"
                                                            name="saldoVigente"
                                                            label="Saldo Vigente"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CurrencyTextField
                                                            readOnly={true}
                                                            size="small"
                                                            type="number"
                                                            name="montoDesembolsa"
                                                            label="Monto a Desembolsar"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CurrencyTextField
                                                            readOnly={true}
                                                            size="small"
                                                            type="text"
                                                            name="saldoVigentePonderado"
                                                            label="Saldo Vigente Ponderado"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </TabPanel>
                                <TabPanel value="1" sx={{ p: "0px" }}>
                                    <Paper elevation={3} sx={{ p: 3 }}>
                                        <Grid container spacing={1} sx={{ px: { xs: 1, md: 4 } }}>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    {disabled ? <LockIcon sx={{ color: theme.palette.icons, mt: 1, fontSize: { xs: "1.3rem", md: "1.5rem" } }} /> : <LockIcon sx={{ color: "transparent" }} />}
                                                    <Controller
                                                        fullwidth="true"
                                                        name="fechaEmision"
                                                        sx={{ m: 1 }}
                                                        render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
                                                            <FormControl fullWidth sx={{ mt: 2, width: { xs: "40vh", md: "38vw" } }}>
                                                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
                                                                    <DesktopDatePicker
                                                                        {...field}
                                                                        views={['day', 'month', 'year']}
                                                                        inputRef={ref}
                                                                        label="Fecha de Emisión:"
                                                                        readOnly={disabled}
                                                                        renderInput={(inputProps) => (
                                                                            <TextField
                                                                                {...inputProps}
                                                                                onBlur={onBlur}
                                                                                size="small"
                                                                                name={name}
                                                                                error={!!fieldState.error}
                                                                                helperText={fieldState.error ? fieldState.error.message : null}
                                                                                rules={{ required: "Required!" }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            </FormControl>
                                                        )}
                                                    />
                                                    {/* <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            readOnly={disabled}
                                                            size="small"
                                                            type="date"
                                                            name="fechaEmision"
                                                            label="Fecha de Emisión"


                                                        />
                                                    </FormControl> */}
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    {disabled ? <LockIcon sx={{ color: theme.palette.icons, mt: 1, fontSize: { xs: "1.3rem", md: "1.5rem" } }} /> : <LockIcon sx={{ color: "transparent" }} />}
                                                    <Controller
                                                        fullwidth="true"
                                                        name="fechaVencimiento"
                                                        sx={{ m: 1 }}
                                                        render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
                                                            <FormControl fullWidth sx={{ mt: 2, width: { xs: "40vh", md: "38vw" } }}>
                                                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
                                                                    <DesktopDatePicker
                                                                        {...field}
                                                                        views={['day', 'month', 'year']}
                                                                        inputRef={ref}
                                                                        label="Fecha de Vencimiento:"
                                                                        readOnly={disabled}
                                                                        renderInput={(inputProps) => (
                                                                            <TextField
                                                                                {...inputProps}
                                                                                readOnly={disabled}
                                                                                onBlur={onBlur}
                                                                                size="small"
                                                                                name={name}
                                                                                error={!!fieldState.error}
                                                                                helperText={fieldState.error ? fieldState.error.message : null}
                                                                                rules={{ required: "Required!" }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            </FormControl>
                                                        )}
                                                    />
                                                    {/* <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            readOnly={disabled}
                                                            size="small"
                                                            type="date"
                                                            name="fechaVencimiento"
                                                            label="Fecha de Vencimiento"
                                                        />
                                                    </FormControl> */}
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    {disabled ? <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} /> : <LockIcon sx={{ color: "transparent" }} />}
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            readOnly={disabled}
                                                            size="small"
                                                            type="number"
                                                            name="numeroPrestamo"
                                                            label="Número del Préstamo"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            readOnly={true}
                                                            size="small"
                                                            type="number"
                                                            name="plazoDias"
                                                            label="Plazo en días"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            readOnly={true}
                                                            size="small"
                                                            type="text"
                                                            name="oficialCuentas"
                                                            label="Oficial de Cuentas"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Divider sx={{ my: 3 }} />
                                        <Typography sx={{ fontSize: "1rem", ml: 4 }} color="inherit">Plan de Cuotas</Typography>
                                        <Grid container spacing={1} sx={{ px: { xs: 1, md: 4 } }}>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CurrencyTextField
                                                            readOnly={true}
                                                            size="small"
                                                            type="number"
                                                            name="saldoCuotasOperativo"
                                                            label="Saldo Cuotas Operativo"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CurrencyTextField
                                                            readOnly={true}
                                                            size="small"
                                                            type="number"
                                                            name="saldoCuotasVigente"
                                                            label="Saldo Cuotas Vigente"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1} sx={{ px: { xs: 1, md: 4 } }}>
                                            <Grid xs={12} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <FormControl sx={{ m: 1, width: { xs: "80vh", md: "76vw" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            readOnly={true}
                                                            size="small"
                                                            type="text"
                                                            name="new_observaciones"
                                                            label="Observaciones"
                                                            multiline
                                                            rows={5}
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Box sx={{ m: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                            <Button
                                                type="button"
                                                onClick={(e) => obtenerPlanesDeCuotas(e)}
                                                variant="contained"
                                                sx={buttonSx}
                                            >
                                                <Typography variant="subtitle2" color="inherit" sx={{ fontSize: { xs: ".7rem", md: ".8rem" } }}>Ver Planes de Cuotas</Typography>
                                                {resultadoPlanCuotas === 'LOADING' && (
                                                    <CircularProgress
                                                        size={27}
                                                        sx={{
                                                            color: '#fff',
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            marginTop: '-12px',
                                                            marginLeft: '-12px',
                                                        }}
                                                    />
                                                )}
                                            </Button>
                                        </Box>
                                        {planDeCuotasCargado &&
                                            <Box component="div" sx={{ ml: { xs: 0, md: 4 } }}>
                                                {tablaPlanDeCuotas?.length > 0 && resultadoPlanCuotas === 'EXITO' ?
                                                    <Tabla columnas={COLUMNSPLANCUOTAS(theme)} datos={tablaPlanDeCuotas} titulo="Plan de cuotas" />
                                                    :
                                                    <Typography variant="h6" color="inherit" sx={{ fontFamily: theme.typography.fontFamily, color: theme.typography.primary.main, mt: 3, mb: 1, mx: 5 }}>No hay planes de cuotas</Typography>
                                                }
                                            </Box>
                                        }
                                    </Paper>
                                </TabPanel>
                                <TabPanel value="2" sx={{ p: "0px" }}>
                                    <Paper elevation={3} sx={{ p: 3 }}>
                                        <Typography sx={{ fontSize: "1rem", ml: 4 }} color="inherit">Monetización</Typography>
                                        {/* <Box component="div"> */}
                                        <Grid container spacing={1} sx={{ px: { xs: 1, md: 4 } }}>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    {disabled ? <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} /> : <LockIcon sx={{ color: "transparent" }} />}
                                                    <Controller
                                                        fullwidth="true"
                                                        name="fechaMonetizacion"
                                                        sx={{ m: 1 }}
                                                        render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
                                                            <FormControl fullWidth sx={{ mt: 2, width: { xs: "40vh", md: "38vw" } }}>
                                                                <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="es">
                                                                    <DesktopDatePicker
                                                                        {...field}
                                                                        views={['day', 'month', 'year']}
                                                                        inputRef={ref}
                                                                        label="Fecha de Monetización:"
                                                                        readOnly={disabled}
                                                                        renderInput={(inputProps) => (
                                                                            <TextField
                                                                                readOnly={disabled}
                                                                                {...inputProps}
                                                                                onBlur={onBlur}
                                                                                size="small"
                                                                                name={name}
                                                                                error={!!fieldState.error}
                                                                                helperText={fieldState.error ? fieldState.error.message : null}
                                                                                rules={{ required: "Required!" }}
                                                                            />
                                                                        )}
                                                                    />
                                                                </LocalizationProvider>
                                                            </FormControl>
                                                        )}
                                                    />
                                                    {/* <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            readOnly={disabled}
                                                            size="small"
                                                            type="date"
                                                            name="fechaMonetizacion"
                                                            label="Fecha de Monetización"
                                                        />
                                                    </FormControl> */}
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Divider sx={{ my: 3 }} />
                                        <Typography sx={{ fontSize: "1rem", ml: 4 }} color="inherit">Ponderación</Typography>
                                        <Grid container spacing={1} sx={{ px: { xs: 1, md: 4 } }}>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            readOnly={true}
                                                            size="small"
                                                            type="number"
                                                            name="porcentaje"
                                                            label="Porcentaje"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CurrencyTextField
                                                            readOnly={true}
                                                            size="small"
                                                            type="number"
                                                            name="importePonderacion"
                                                            label="Importe Ponderación"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            readOnly={true}
                                                            size="small"
                                                            type="text"
                                                            name="categoria"
                                                            label="Categoría"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            readOnly={true}
                                                            size="small"
                                                            type="text"
                                                            name="tipodeSocio"
                                                            label="Tipo de socio"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            readOnly={true}
                                                            size="small"
                                                            type="text"
                                                            name="ponderadorAplicable"
                                                            label="Ponderador Aplicable"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                        <Divider sx={{ my: 3 }} />
                                        <Typography sx={{ fontSize: "1rem", ml: 4 }} color="inherit">Tasas y Gracia</Typography>
                                        <Grid container spacing={1} sx={{ px: { xs: 1, md: 4 } }}>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            readOnly={true}
                                                            size="small"
                                                            type="text"
                                                            name="sistemaAmortización"
                                                            label="Sistema de Amortización"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            readOnly={true}
                                                            size="small"
                                                            type="text"
                                                            name="tasa"
                                                            label="Tasa"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            readOnly={true}
                                                            size="small"
                                                            type="number"
                                                            name="puntosPorcentuales"
                                                            label="Puntos Porcentuales"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            readOnly={true}
                                                            size="small"
                                                            type="number"
                                                            name="Período de Gracia"
                                                            label="periodoGracia"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                            <Grid xs={12} sm={6} item sx={{ pt: 0, pb: 0 }}>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                    <LockIcon sx={{ color: theme.palette.icons, fontSize: { xs: "1.3rem", md: "1.5rem" } }} />
                                                    <FormControl sx={{ m: 1, width: { xs: "40vh", md: "38vw" } }}>
                                                        <CustomTextField
                                                            Component={TextField}
                                                            readOnly={true}
                                                            size="small"
                                                            type="text"
                                                            name="periodicidadDePagos"
                                                            label="Periodicidad de Pagos"
                                                        />
                                                    </FormControl>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </TabPanel>
                                <TabPanel value="3" sx={{ p: "0px" }}>
                                    <Paper elevation={3} sx={{ p: 3 }}>
                                        <Box sx={{ m: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                            <Button
                                                type="button"
                                                onClick={(e) => obtenerDocumentos(e)}
                                                variant="contained"
                                            >
                                                <Typography variant="subtitle2" color="inherit" sx={{ fontSize: { xs: ".7rem", md: ".8rem" } }}>Ver Documentos</Typography>
                                                {resultadoDocumentoPorID === 'LOADING' && (
                                                    <CircularProgress
                                                        size={27}
                                                        sx={{
                                                            color: '#fff',
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            marginTop: '-12px',
                                                            marginLeft: '-12px',
                                                        }}
                                                    />
                                                )}
                                            </Button>

                                        </Box>
                                        {resultadoDocumentoPorID === 'EXITO' && logDocumentos &&
                                            <Box component="div" sx={{ ml: { xs: 0, md: 4 } }}>
                                                {documentos?.length > 0 ?
                                                    <Tabla columnas={COLUMNSDOCUMENTOS} datos={documentos} titulo="Documentos" />
                                                    :
                                                    <Typography variant="h6" color="inherit" sx={{ fontFamily: theme.typography.fontFamily, color: theme.typography.primary.main, mt: 3, mb: 1, mx: 5 }}>No hay documentos cargados</Typography>
                                                }
                                            </Box>
                                        }
                                    </Paper>
                                </TabPanel>
                                <TabPanel value="4" sx={{ p: "0px" }}>
                                    <Paper elevation={3} sx={{ p: 3 }}>
                                        <Box sx={{ m: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                            <Button
                                                type="button"
                                                onClick={(e) => obtenerDocsAnteriorPorId(e)}
                                                variant="contained"
                                            >
                                                <Typography variant="subtitle2" color="inherit" sx={{ fontSize: { xs: ".7rem", md: ".8rem" } }}>Ver desembolsos anteriores</Typography>
                                                {resultadoDesembolsoAnterior === 'LOADING' && (
                                                    <CircularProgress
                                                        size={27}
                                                        sx={{
                                                            color: '#fff',
                                                            position: 'absolute',
                                                            top: '50%',
                                                            left: '50%',
                                                            marginTop: '-12px',
                                                            marginLeft: '-12px',
                                                        }}
                                                    />
                                                )}
                                            </Button>
                                        </Box>
                                        {/* {desembolsoCargado &&
                                            <Box component="div" sx={{ ml: { xs: 0, md: 4 } }}>
                                                {garantiaAnterior?.length > 0 && resultadoDesembolsoAnterior === 'EXITO' ?
                                                    <Tabla columnas={COLUMNSDESEMBOLSOANTERIOR} datos={garantiaAnterior[1].acreedor ? garantiaAnterior.slice(0, 2) : garantiaAnterior.slice(0, 1)} titulo="Garantías con desembolsos anteriores" />
                                                    :
                                                    <Typography variant="h6" color="inherit" sx={{ fontFamily: theme.typography.fontFamily, color: theme.typography.primary.main, mt: 3, mb: 1, mx: 5 }}>No hay garantías con desembolsos anteriores</Typography>
                                                }
                                            </Box>
                                        } */}
                                        {resultadoDesembolsoAnterior === 'EXITO' && logGarantiasAnteriores &&
                                            <Box component="div" sx={{ ml: { xs: 0, md: 4 } }}>
                                                {garantiaAnterior?.length > 0 ?
                                                    <Tabla columnas={COLUMNSDESEMBOLSOANTERIOR} datos={garantiaAnterior[1].acreedor ? garantiaAnterior.slice(0, 2) : garantiaAnterior.slice(0, 1)} titulo="Garantías con desembolsos anteriores" />
                                                    :
                                                    <Typography variant="h6" color="inherit" sx={{ fontFamily: theme.typography.fontFamily, color: theme.typography.primary.main, mt: 3, mb: 1, mx: 5 }}>No hay garantías con desembolsos anteriores</Typography>
                                                }
                                            </Box>
                                        }
                                    </Paper>
                                </TabPanel>
                            </FormProvider>
                        </TabContext>
                        <Box sx={{ display: "flex", gap: 2 }}>
                            <Box sx={{ mt: 3, mb: 2, position: 'relative' }}>
                                <Button
                                    type="button"
                                    onClick={handleSubmit(ActualizarGarantias)}
                                    variant="contained"
                                    disabled={disabled || garantiaModificadaResultado === 'LOADING'}
                                >
                                    <Typography variant="subtitle2" color="inherit" sx={{ fontSize: { xs: ".7rem", md: ".8rem" } }}>Actualizar</Typography>
                                </Button>
                                {garantiaModificadaResultado === 'LOADING' && (
                                    <CircularProgress
                                        size={27}
                                        sx={{
                                            color: theme.palette.icons,
                                            position: 'absolute',
                                            top: '50%',
                                            left: '50%',
                                            marginTop: '-12px',
                                            marginLeft: '-12px',
                                        }}
                                    />
                                )}
                            </Box>
                            {visibleDesembolso &&
                                <Box sx={{ mt: 3, mb: 2, position: 'relative' }}>
                                    <Button
                                        type="button"
                                        onClick={isAgregarDesembolso ? handleSubmit(DesembolsoParcial)() : setBotonAgregarDesembolso}
                                        variant="contained"
                                        disabled={disabled || (desembolsoGarantiaResultado === 'LOADING' || desembolsoGarantiaResultado === 'EXITO')}
                                    >
                                        <Typography variant="subtitle2" color="inherit" sx={{ fontSize: { xs: ".7rem", md: ".8rem" } }}>Agregar Desembolso</Typography>
                                    </Button>
                                    {desembolsoGarantiaResultado === 'LOADING' && (
                                        <CircularProgress
                                            size={27}
                                            sx={{
                                                color: theme.palette.icons,
                                                position: 'absolute',
                                                top: '50%',
                                                left: '50%',
                                                marginTop: '-12px',
                                                marginLeft: '-12px',
                                            }}
                                        />
                                    )}
                                </Box>}
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            <ModalAgregarDesembolso open={openModalAgregarDesembolso} setOpen={setOpenModalAgregarDesembolso} IsAgregarDesembolso={setIsAgregarDesembolso} />
            <ModalSubirAdjuntoGarantia open={openModalAdjunto} setOpen={setOpenModalAdjunto} idGarantia={id} />
            <ModalTemplateGarantia open={openModalTemplate} setOpen={setOpenModalTemplate} idGarantia={id} idSerie={defaultValues.serieid} />
        </>
    )
}

export default ModalGarantias