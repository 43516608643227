import React, { useState, useEffect } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { leerUsuarioActivo, activarUsuarioFirebase } from "../Redux/Usuarios";
import { auth } from "../firebase";
import SolicitudAlta from "./SolicitudAlta";
import MisSGR from "../Paginas/MisSGR";
import Serie from "../Paginas/Serie";
import { Garantias } from "../Paginas/Garantias";
import { Inicio } from "../Paginas/Inicio";
import { Registro } from "../Paginas/Registro";
import { OperacionesSindicadas } from "../Paginas/OperacionesSindicadas";
import { RegistroSGR } from "../Paginas/RegistroSGR";
import { InicioSesion } from "../Paginas/InicioSesion";
import { Series } from "../Paginas/Series";
import { Pymes } from "../Paginas/Pymes";
import RecuperarContraseña from "../Paginas/RecuperarContraseña";
import ResumenPosicion from "../Paginas/ResumenPosicion";
import CarpetaDigital from "../Paginas/CarpetaDigital";
import Operaciones from "../Paginas/Operaciones";
import Cuenta from "../Paginas/Cuenta";
import GarantiasPyme from "../Paginas/GarantiasPyme";
import DatosSgr from "../Paginas/DatosSgr";
import { loginToken, RenovarToken } from "../Redux/Token";
import { obtenerParticipacionEnSeries } from "../Redux/Series";
import { obtenerCuenta } from "../Redux/Cuenta";
import PrivateRouter from "./PrivateRouter";
import { MyProSidebarProvider } from "./sidebar/sidebarContext";
import Appbar from "./Appbar";
import SpinnerF from "./SpinnerF";
import { Box } from "@mui/material";
import LoaderAnimado from "./loaderAnimado/LoaderAnimado";

const AppContent = ({ setDark, dark }) => {
  const dispatch = useDispatch();

  const location = useLocation();
  const showSidebarAndAppbar = location.pathname !== "/mis-sgr";

  const token = useSelector((store) => store.token.token);
  const name = useSelector((store) => store.sgrs.nameSGR);
  const id = useSelector((store) => store.sgrs.idSGR);
  const participacionesSelector = useSelector(
    (store) => store.series.participacionesEnSerie
  );
  const cuentaSelector = useSelector((store) => store.cuenta.cuenta);
  const accountid = useSelector((store) => store.usuarios.accountid);
  const IdSGR = useSelector((store) => store.sgrs.idSGR);
  const activo = useSelector((store) => store.usuarios.activo);
  const photoURL = useSelector((store) => store.usuarios.photoURL);
  const tipo = useSelector((store) => store.usuarios.tipo);
  const [logSelector, setLogSelector] = useState(true);
  const [logRutas, setLogRutas] = useState(false);
  const [loggedUser, setLoggedUser] = useState(false);
  const [nameSGR, setNameSGR] = useState(
    JSON.parse(localStorage.getItem("Nombre SGR"))
  );
  const [idSGR, setIdSGR] = useState(
    JSON.parse(localStorage.getItem("Id SGR"))
      ? JSON.parse(localStorage.getItem("Id SGR"))
      : null
  );
  const [usuario, setUsuario] = React.useState(
    JSON.parse(localStorage.getItem("usuario"))
      ? JSON.parse(localStorage.getItem("usuario"))
      : null
  );
  const [llamadaDatosCuenta, setLlamadaDatosCuenta] = React.useState(false);
  const [sgrLider, setSgrLider] = useState("");
  const [cuenta, setCuenta] = React.useState(null);
  const [fotoURL, setFotoURL] = React.useState();

  React.useEffect(() => {
    if (activo) {
      setUsuario(JSON.parse(localStorage.getItem("usuario")));
      setLoggedUser(true);
    } else if (!activo) {
      setLoggedUser(false);
    }
  }, [activo]);

  React.useEffect(() => {
    const fetchUser = () => {
      auth.onAuthStateChanged((user) => {
        if (user) {
          if (localStorage.getItem("usuario")) {
            let usuarioStorage = JSON.parse(localStorage.getItem('usuario'))
            dispatch(leerUsuarioActivo());
            setLoggedUser(true);
            setTimeout(() => { }, 2000);
          } else {
            setTimeout(() => { }, 2000);
          }
          if (token == undefined || token == "") {
            dispatch(loginToken());
          }
        } else {
          clearCacheData();
          setTimeout(() => { }, 2000);
        }
      });
    };
    fetchUser();
    setTimeout(() => {
      setLogRutas(true);
    }, 1500);
  }, []);

  useEffect(() => {
    if (activo) {
      setLoggedUser(activo);
    }
    if (accountid) {
      if (cuentaSelector != null && llamadaDatosCuenta) {
        setCuenta(cuentaSelector)
        validarUsuarioActivo(cuentaSelector)
      }
      if (token.token && !llamadaDatosCuenta) {
        dispatch(obtenerCuenta(accountid, token.token));
        setLlamadaDatosCuenta(true);
      }
    }
    if (token && idSGR) {
      dispatch(obtenerParticipacionEnSeries(token.token, idSGR));
    }
    if (photoURL) {
      setFotoURL(photoURL);
    }
  }, [token, idSGR, accountid, cuentaSelector, llamadaDatosCuenta, activo, photoURL, tipo]);

  const validarUsuarioActivo = (usuario) => {
    let usuarioStorage = JSON.parse(localStorage.getItem('usuario'))
    if (usuarioStorage != null && usuarioStorage.estado !== usuario.new_estadodelsocio && usuario.new_estadodelsocio === 100000000) {
      dispatch(activarUsuarioFirebase(usuarioStorage.uid, usuario.new_estadodelsocio))
    }
  }

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(loginToken(token));
    }, 1800000);
    return () => clearInterval(intervalId);
  }, [])

  useEffect(() => {
    if (participacionesSelector?.length > 0 && logSelector) {
      setSgrLider(
        participacionesSelector.map((item) => item["serie.new_sgrlider"])
      );
    }
  }, [participacionesSelector]);

  useEffect(() => {
    if (id) {
      setIdSGR(JSON.parse(localStorage.getItem("Id SGR")));
    }
    if (name) {
      setNameSGR(JSON.parse(localStorage.getItem("Nombre SGR")));
    }
    if (sgrLider) {
      localStorage.setItem("Id SGRLider", JSON.stringify(sgrLider[0]));
      setLogSelector(false);
    }
  }, [id, name, sgrLider, nameSGR, IdSGR]);

  const clearCacheData = () => {
    caches.keys().then((names) => {
      names.forEach((name) => {
        caches.delete(name);
      });
    });
  };

  const setearColor = () => {
    setDark(!dark);
    localStorage.setItem("Dark Mode", JSON.stringify(!dark));
  };

  return (
    <>
      {logRutas ? (
        <>
          {showSidebarAndAppbar && (
            <MyProSidebarProvider loggedUser={loggedUser} loadingAPP={false}>
              <div style={{ height: "100%", width: "100%", borderRight: 1, borderColor: 'grey.500' }}>
                <main>
                  {loggedUser ? (
                    <Appbar
                      user={usuario || {}}
                      foto={fotoURL || ""}
                      setearColor={setearColor}
                      loggedUser={loggedUser}
                    />
                  ) : null}
                  <Routes>
                    <Route>
                      <Route
                        path="/inicio"
                        exact
                        element={
                          <PrivateRouter loggedUser={loggedUser}>
                            <Inicio nameSGR={nameSGR} dark={dark} />
                          </PrivateRouter>
                        }
                      />
                      <Route
                        path="/operaciones"
                        exact
                        element={
                          <PrivateRouter loggedUser={loggedUser}>
                            <OperacionesSindicadas nameSGR={nameSGR} />
                          </PrivateRouter>
                        }
                      />
                      <Route
                        path="/garantias"
                        exact
                        element={
                          <PrivateRouter loggedUser={loggedUser}>
                            <Garantias nameSGR={nameSGR} />
                          </PrivateRouter>
                        }
                      />
                      <Route
                        path="/series"
                        exact
                        element={
                          <PrivateRouter loggedUser={loggedUser}>
                            <Series nameSGR={nameSGR} idSGR={idSGR} />
                          </PrivateRouter>
                        }
                      />
                      <Route
                        path="/serie"
                        exact
                        element={
                          <PrivateRouter loggedUser={loggedUser}>
                            <Serie />
                          </PrivateRouter>
                        }
                      />
                      <Route
                        path="/pymes"
                        exact
                        element={
                          <PrivateRouter loggedUser={loggedUser}>
                            <Pymes nameSGR={nameSGR} />
                          </PrivateRouter>
                        }
                      />
                      <Route
                        path="/resumendeposicion"
                        exact
                        element={
                          <PrivateRouter loggedUser={loggedUser}>
                            <ResumenPosicion cuenta={cuenta} />
                          </PrivateRouter>
                        }
                      />
                      <Route
                        path="/midocumentaciondigital"
                        exact
                        element={
                          <PrivateRouter loggedUser={loggedUser}>
                            <CarpetaDigital />
                          </PrivateRouter>
                        }
                      />
                      <Route
                        path="/misgarantias"
                        exact
                        element={
                          <PrivateRouter loggedUser={loggedUser}>
                            <GarantiasPyme />
                          </PrivateRouter>
                        }
                      />
                      <Route
                        path="/misoperaciones"
                        exact
                        element={
                          <PrivateRouter loggedUser={loggedUser}>
                            <Operaciones />
                          </PrivateRouter>
                        }
                      />
                      {/* <Route
                        path="/misoperaciones"
                        exact
                        element={
                          <PrivateRouter loggedUser={loggedUser}>
                            <Operaciones />
                          </PrivateRouter>
                        }
                      /> */}
                      <Route
                        path="/micuenta"
                        exact
                        element={
                          <PrivateRouter loggedUser={loggedUser}>
                            <Cuenta />
                          </PrivateRouter>
                        }
                      />
                      <Route
                        path="/datos-sgr"
                        exact
                        element={
                          <PrivateRouter loggedUser={loggedUser}>
                            <DatosSgr />
                          </PrivateRouter>
                        }
                      />
                    </Route>
                    <Route
                      path="/solicitud-alta"
                      element={<SolicitudAlta setearColor={setearColor} />}
                    ></Route>
                    <Route path="/registro-sgr" element={<RegistroSGR />} />
                    <Route path="/registro" element={<Registro />} />
                    <Route path="/login" element={<InicioSesion />} />
                    <Route path="/recupero" element={<RecuperarContraseña />} />
                    <Route
                      path="*"
                      element={<Navigate to="/login" replace />}
                    />
                  </Routes>
                </main>
              </div>
            </MyProSidebarProvider>
          )}
          {
            !showSidebarAndAppbar && (
              <Routes>
                <Route
                  path="/mis-sgr"
                  exact
                  element={
                    <PrivateRouter loggedUser={loggedUser}>
                      <MisSGR />
                    </PrivateRouter>
                  }
                />
              </Routes>
            )
          }
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", minHeight: "100vh" }}>
          <LoaderAnimado />
        </Box>
      )}
    </>
  );
};

export default AppContent;
