import { Chip } from '@mui/material';
import Moment from 'moment'
let dollarUS = Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
});

export const COLUMNAOPERACIONES = [
    {
        accessorKey: 'id',
    },
    {
        header: 'Nro. de Orden',
        accessorKey: 'new_nrooperacion',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Tipo de Operación',
        accessorKey: 'new_tipooperacin',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
        // Cell: ({ cell }) => <strong>{dollarUS.format(cell.getValue())}</strong>
    },
    {
        header: 'Estado',
        accessorKey: 'statuscode',
        Cell: ({ cell }) => {
            switch (cell.getValue()) {
                case "Enviada":
                    return <Chip label="Enviada" sx={{ bgcolor: '#0288d1' }} />
                case "Monetizada":
                    return <Chip label="Monetizada" sx={{ bgcolor: '#388e3c' }} />
                case "Pendiente":
                    return <Chip label="Pendiente" sx={{ bgcolor: '#f57c00' }} />
                default:
                    return ""
            }
        }
    },
    {
        header: 'Destino de Fondo',
        accessorKey: 'new_destinodefondo',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Fecha de Creación',
        accessorKey: 'fechaCreacion_str',
        Cell: ({ cell }) => {cell.getValue()}
    },
   
    // {
    //     accessorKey: 'new_operacionid',
    //     Cell: ({ value }) => {
    //         return (
    //             <SeleccionarFila value={value} />
    //         )
    //     }
    // }
]