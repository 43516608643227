import axios from 'axios'
import { UrlApi } from '../Keys'

const dataInicial = {
    loading: false,
    series: [],
    seriesInicio: [],
    idSerie: "",
    participacionesEnSerie: [],
    resultadoSerie: false,
    resultado: "",
    resultadoParticipaciones: false,
}

const LOADING = 'LOADING'
const SERIES_EXITO = 'SERIES_EXITO'
const LOADING_SERIES = 'LOADING_SERIES'
const SERIES_ERROR = 'SERIES_ERROR'
const SERIES_EXITO_INICIO = 'SERIES_EXITO_INICIO'
const PARTICIPACIONES_EXITO = 'PARTICIPACIONES_EXITO'
const GET_ID_SERIE = 'GET_ID_SERIE'

export default function seriesReducer(state = dataInicial, action) {
    switch (action.type) {
        case SERIES_EXITO:
            return { ...state, series: action.payload, resultadoSerie: true, resultado: action.resultado }
        case LOADING_SERIES:
            return { ...state, resultadoSerie: false, resultado: action.resultado }
        case SERIES_ERROR:
            return { ...state, resultadoSerie: false, resultado: action.resultado }
        case SERIES_EXITO_INICIO:
            return { ...state, seriesInicio: action.payload, resultadoSerie: true, resultado: action.resultado }
        case PARTICIPACIONES_EXITO:
            return { ...state, participacionesEnSerie: action.payload, resultadoParticipaciones: true }
        case GET_ID_SERIE:
            return { ...state, idSerie: action.id, }
        case LOADING:
            return { ...state, series: [], resultadoSerie: false }
        default:
            return { ...state }
    }
}

export const obtenerSeriesYGarantias = (token, sgrid) => async (dispatch) => {
    dispatch({
        type: LOADING_SERIES,
        resultado: "LOADING"
    })
    if (token && sgrid) {
        var entidad = "new_garantias"
        var fetch = "<fetch mapping='logical'>" +
            "<entity name='new_garantia'>" +
            "<attribute name='new_garantiaid'/>" +
            "<attribute name='new_name'/>" +
            "<attribute name='createdon'/> " +
            "<attribute name='new_sgr'/>" +
            "<attribute name='new_serie'/>" +
            "<attribute name='new_nmerodeserie'/> " +
            "<attribute name='new_ndeordendelagarantiaotorgada'/> " +
            "<attribute name='new_socioparticipe'/>" +
            "<attribute name='new_acreedor'/>" +
            "<attribute name='statuscode'/>" +
            "<attribute name='new_monto'/>" +
            "<attribute name='new_tipodeoperacion'/>" +
            "<attribute name='new_codigo'/>" +
            "<attribute name='new_ndeordendelagarantiaotorgada'/>" +
            "<attribute name='new_nroordensepyme'/>" +
            "<attribute name='new_montoneto'/>" +
            "<attribute name='transactioncurrencyid'/>" +
            "<attribute name='new_reafianzada'/>" +
            "<attribute name='new_fechadeorigen'/>" +
            "<attribute name='new_dictamendelaval'/>" +
            "<attribute name='new_sociosprotector'/>" +
            "<attribute name='new_fechadecancelada'/>" +
            "<attribute name='new_tipodegarantias'/>" +
            "<attribute name='new_fechadepago'/>" +
            "<attribute name='new_fecharealdepago'/>" +
            "<attribute name='new_saldooperativo'/>" +
            "<attribute name='new_saldovigente'/>" +
            "<attribute name='new_saldovigenteponderado'/>" +
            "<attribute name='new_fechaemisindelcheque'/>" +
            "<attribute name='new_fechadevencimiento'/>" +
            "<attribute name='new_numerodeprestamo'/>" +
            "<attribute name='new_saldocreditoprincipal'/>" +
            "<attribute name='new_plazodiasprueba'/>" +
            "<attribute name='new_plazodias'/>" +
            "<attribute name='new_nroexpedientetad'/>" +
            "<attribute name='new_creditoaprobado'/>" +
            "<attribute name='new_linea'/>" +
            "<attribute name='new_oficialdecuentas'/>" +
            "<attribute name='new_saldocuotasoperativo'/>" +
            "<attribute name='new_montoadesembolsar'/>" +
            "<attribute name='new_montocomprometidodelaval'/>" +
            "<attribute name='new_saldocuotasvigentes'/>" +
            "<attribute name='new_ponderacion'/>" +
            "<attribute name='new_importeponderacion'/>" +
            "<attribute name='new_categoriatipodesocio'/>" +
            "<attribute name='new_ponderacionportipodesocio'/>" +
            "<attribute name='new_podenderaciondegarantias'/>" +
            "<attribute name='new_cargadasincertificadopyme'/>" +
            "<attribute name='new_sistemadeamortizacion'/>" +
            "<attribute name='new_puntosporcentuales'/>" +
            "<attribute name='statecode'/>" +
            "<attribute name='new_tasa'/>" +
            "<attribute name='new_periodogracia'/>" +
            "<attribute name='new_periodicidadpagos'/>" +
            "<attribute name='new_fechadenegociacion'/>" +
            "<attribute name='new_referido'/>" +
            "<attribute name='new_cantidadcuotasafrontadas'/>" +
            "<attribute name='new_determinadaenasamblea'/>" +
            "<attribute name='new_observaciones'/>" +
            "<order attribute ='createdon' descending='true' />" +
            "<filter type='and'>" +
            "<condition attribute='statecode' operator='eq' value='0' />" +
            "</filter>" +
            "<link-entity name='new_seriedeoperacinsindicada' from='new_seriedeoperacinsindicadaid' to='new_nmerodeserie' link-type='inner' alias='serie' >" +
            "<attribute name='new_name'/>" +
            "<attribute name='new_sgrlider'/>" +
            "<attribute name='statecode'/>" +
            "<attribute name='new_seriedeoperacinsindicadaid'/>" +
            "<attribute name='new_importetotalaavalar'/>" +
            "<attribute name='statuscode'/>" +
            "<link-entity name='new_participacinsgrenseriedeosindicadas' from='new_serie' to='new_seriedeoperacinsindicadaid' link-type='inner' alias='series' >" +
            "<attribute name='new_name'/>" +
            "<attribute name='new_sgr'/>" +
            "<filter>" +
            "<condition attribute='new_sgr' operator='eq' value='" + sgrid + "' />" +
            "</filter>" +
            "</link-entity>" +
            "</link-entity>" +
            "<link-entity name='account' from='accountid' to='new_socioparticipe' link-type='inner' alias='cuenta' >" +
            "<attribute name='name'/>" +
            "<attribute name='accountid'/>" +
            "<attribute name='new_estadodelsocio'/>" +
            "<attribute name='new_nmerodedocumento'/>" +
            "<attribute name='createdon'/>" +
            "<attribute name='new_urlfotodeperfil'/> " +
            "<attribute name='new_tipodedocumentoid'/>" +
            "<attribute name='new_condicionimpositiva'/>" +
            "<attribute name='emailaddress1'/>" +
            "<attribute name='new_actividadafip'/>" +
            "<attribute name='new_fechadealta'/>" +
            "<attribute name='new_calificacion'/>" +
            "</link-entity>" +
            "</entity>" +
            "</fetch>";
        try {
            await axios.post(`${UrlApi}api/consultafetch`,
                {
                    "entidad": entidad,
                    "fetch": fetch
                },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then((response) => {
                    dispatch({
                        type: SERIES_EXITO,
                        payload: response.data,
                        resultado: "EXITO"
                    })
                })
                .catch(err => {
                    console.log(err)
                    dispatch({
                        type: SERIES_ERROR,
                        resultado: "ERROR"
                    })
                })

        }

        catch (error) {
            // dispatch({
            //     type: ERROR
            // })
        }
    }
}

export const obtenerSeriesYGarantiasInicio = (token) => async (dispatch) => {
    dispatch({
        type: SERIES_EXITO_INICIO,
        resultado: "LOADING"
    })
    if (token) {
        var entidad = "new_garantias"
        var fetch = "<fetch mapping='logical'>" +
            "<entity name='new_garantia'>" +
            "<attribute name='new_monto'/> " +
            "<attribute name='new_fechadenegociacion'/>" +
            "<attribute name='statuscode'/> " +
            "<attribute name='new_garantiaid'/> " +
            "<attribute name='createdon'/> " +
            "<attribute name='new_montocomprometidodelaval'/> " +
            "<attribute name='new_dictamendelaval'/> " +
            "<attribute name='new_acreedor'/> " +
            "<attribute name='new_saldovigente'/> " +
            "<order attribute ='createdon' descending='false' />" +
            "<filter type='and'>" +
            "<condition attribute='statecode' operator='eq' value='0' />" +
            "</filter>" +
            "<link-entity name='new_seriedeoperacinsindicada' from='new_seriedeoperacinsindicadaid' to='new_nmerodeserie' link-type='inner' alias='serie'>" +
            "<attribute name='new_name'/>" +
            "<attribute name='new_seriedeoperacinsindicadaid'/>" +
            "<attribute name='new_importetotalaavalar'/>" +
            "<filter type='and'>" +
            "<condition attribute='statecode' operator='eq' value='0' />" +
            "</filter>" +
            "</link-entity>" +
            "</entity>" +
            "</fetch>";
        try {
            await axios.post(`${UrlApi}api/consultafetch`,
                {
                    "entidad": entidad,
                    "fetch": fetch
                },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then((response) => {
                    dispatch({
                        type: SERIES_EXITO_INICIO,
                        payload: response.data,
                        resultado: "EXITO"
                    })
                })
                .catch(err => {
                    console.log(err)
                    dispatch({
                        type: SERIES_EXITO_INICIO,
                        resultado: "ERROR"
                    })
                })

        }

        catch (error) {
            // dispatch({
            //     type: ERROR
            // })
        }
    }
}

export const obtenerParticipacionEnSeries = (token, sgrid) => async (dispatch) => {
    dispatch({
        type: LOADING
    })
    if (token && sgrid) {
        var entidad = "new_participacinsgrenseriedeosindicadases"
        var fetch = "<fetch mapping='logical'>" +
            "<entity name='new_participacinsgrenseriedeosindicadas'>" +
            "<attribute name='new_participacinsgrenseriedeosindicadasid'/> " +
            "<attribute name='new_name'/>" +
            "<attribute name='new_sgr'/>" +
            "<order attribute ='createdon' descending='false' />" +
            "<filter type='and'>" +
            "<condition attribute='new_sgr' operator='eq' value='" + sgrid + "' />" +
            "</filter>" +
            "<link-entity name='new_seriedeoperacinsindicada' from='new_seriedeoperacinsindicadaid' to='new_serie' link-type='outer' alias='serie'>" +
            "<attribute name='new_sgrlider'/>" +
            "<attribute name='new_seriedeoperacinsindicadaid'/>" +
            "<attribute name='statuscode'/>" +
            "<attribute name='new_name'/>" +
            "<attribute name='new_numerodeserie'/>" +
            "<attribute name='new_importetotalaavalar'/>" +
            "<attribute name='new_fechadeiniciodelaserie'/>" +
            "<attribute name='new_cantidadsgrparticipantes'/>" +
            "<attribute name='new_importependiente'/>" +
            "<attribute name='new_importetotalavaladoliquidado'/>" +
            "<link-entity name='new_garantia' from='new_nmerodeserie' to='new_seriedeoperacinsindicadaid' link-type='outer' alias='garantia'>" +
            "<attribute name='new_garantiaid'/>" +
            "<attribute name='new_saldovigente'/> " +
            "<attribute name='new_nmerodeserie'/> " +
            "<link-entity name='new_operacionsindicada' from='new_garantia' to='new_garantiaid' link-type='outer' alias='operacion'>" +
            "<attribute name='new_porcentaje'/>" +
            "<filter type='and'>" +
            "<condition attribute='new_sgr' operator='eq' value='" + sgrid + "' />" +
            "</filter>" +
            "</link-entity>" +
            "</link-entity>" +
            "</link-entity>" +
            "</entity>" +
            "</fetch>";
        try {
            await axios.post(`${UrlApi}api/consultafetch`,
                {
                    "entidad": entidad,
                    "fetch": fetch
                },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`
                    }
                })
                .then((response) => {
                    dispatch({
                        type: PARTICIPACIONES_EXITO,
                        payload: response.data
                    })
                })
                .catch(err => {
                    console.log(err)
                })

            // const response = await axios.get(`${UrlApiDynamics}ConsultaFetch?Entidad=${entidad}&fetch=${fetch}&cuit=${Entidad}`)

        }

        catch (error) {
            // dispatch({
            //     type: ERROR
            // })
        }
    }
}

export const getSerieId = (id) => async (dispatch) => {
    if (id.length > 0) {
        dispatch({
            type: GET_ID_SERIE,
            id: id
        })
    }
}