import React from 'react'
import { Box, Typography, Divider, Paper } from '@mui/material';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Container from '@mui/material/Container';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Tooltip from '@mui/material/Tooltip';
import Tabla from '../Tabla';
import { COLUMNASDOCUMENTOSGARANTIA } from "../../Columnas/ColumnasDocumentosGarantias"
import ModalNuevoAdjuntoG from './ModalNuevoAdjuntoG';

const ModalGarantiasPyme = ({ open, handleClose, adjuntos, garantia, garantiaid }) => {
    const [value, setValue] = React.useState("0")
    const [openNew, setOpenNew] = React.useState(false)

    const handleChangeTabs = (event, newValue) => {
        setValue(newValue)
    }

    let dollarUS = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
    });

    const handleOpenNew = () => {
        setOpenNew(true)
    }

    const handleCloseNew = () => setOpenNew(false);

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            maxWidth="lg"
        >
            <DialogContent sx={{ m: 0, p: 0 }}>
                {/* <Box sx={style}> */}
                <Paper elevation={2} sx={{ m: 0, p: 0 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", p: 1 }}>
                        <Typography id="alert-dialog-title" sx={{ fontSize: "1.2rem", pt: 1 }}>
                            Garantias
                        </Typography>
                        <Tooltip title={<Typography sx={{ color: '#fff' }}>Cerrar</Typography>}>
                            <IconButton
                                edge="end"
                                color="warning"
                                onClick={handleClose}
                                aria-label="close"
                                sx={{ mr: 2 }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    {/* <Typography id="modal-modal-title" variant="h6" component="h2" sx={{ p: 2 }}>
                        Garantias
                    </Typography> */}
                    <Divider />
                    <Container component="main">
                        <TabContext value={value}>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                // bgcolor: 'background.paper'
                            }}>
                                <TabList
                                    aria-label="scrollable force tabs example"
                                    onChange={handleChangeTabs} variant="scrollable"
                                    scrollButtons="auto"
                                    allowScrollButtonsMobile
                                >
                                    <Tab label="General" value="0" sx={{ fontWeight: 'bold', fontSize: '16px' }}></Tab>
                                    <Tab label="Adjuntos" value="1" sx={{ fontWeight: 'bold', fontSize: '16px' }}></Tab>
                                    {/* <Tab label="Plan de Cuotas" value="2"></Tab>
                                    <Tab label="Monetización y Ponderación" value="3"></Tab> */}
                                </TabList>
                            </Box>
                            <TabPanel value="0" sx={{ p: 0, mt: 1, height: '400px', overflow: 'auto' }}>
                                {
                                    garantia.map(gar =>
                                        <Grid container>
                                            <Grid xs={6} sx={{ p: 1 }}>
                                                <TextField fullWidth label="N° de Orden" variant="standard" value={gar.new_ndeordendelagarantiaotorgada ? gar.new_ndeordendelagarantiaotorgada : '-'} />
                                            </Grid>
                                            <Grid xs={6} sx={{ p: 1 }}>
                                                <TextField fullWidth label="Tipo de Operación" variant="standard" value={gar.new_tipodeoperacion ? gar.new_tipodeoperacion : '-'} />
                                            </Grid>
                                            <Grid xs={6} sx={{ p: 1 }}>
                                                <TextField fullWidth label="Formato del Cheque" variant="standard" value={gar.new_formatodelcheque ? gar.new_formatodelcheque : '-'} />
                                            </Grid>
                                            <Grid xs={6} sx={{ p: 1 }}>
                                                <TextField fullWidth label="Nro de Cheque" variant="standard" value={gar.new_numerodecheque ? gar.new_numerodecheque : '-'} />
                                            </Grid>
                                            <Grid xs={6} sx={{ p: 1 }}>
                                                <TextField fullWidth label="Tipo CHPD" variant="standard" value={gar.new_tipochpd ? gar.new_tipochpd : '-'} />
                                            </Grid>
                                            <Grid xs={6} sx={{ p: 1 }}>
                                                <TextField fullWidth label="Librador" variant="standard" value={gar.new_librador ? gar.new_librador : '-'} />
                                            </Grid>
                                            <Grid xs={6} sx={{ p: 1 }}>
                                                <TextField fullWidth label="Tipo de Garantía" variant="standard" value={gar.new_tipodegarantias ? gar.new_tipodegarantias : '-'} />
                                            </Grid>
                                            <Grid xs={6} sx={{ p: 1 }}>
                                                <TextField fullWidth label="Fecha de Carga" variant="standard" value={gar.createdon ? gar.createdon : '-'} />
                                            </Grid>
                                            <Grid xs={6} sx={{ p: 1 }}>
                                                <TextField fullWidth label="Monto Bruto" variant="standard" value={gar.new_monto ? dollarUS.format(gar.new_monto) : '-'} />
                                            </Grid>
                                            <Grid xs={6} sx={{ p: 1 }}>
                                                <TextField fullWidth label="Divisa" variant="standard" value={gar.transactioncurrencyid ? gar.transactioncurrencyid : '-'} />
                                            </Grid>
                                            <Grid xs={6} sx={{ p: 1 }}>
                                                <TextField fullWidth label="Estado de la Garantía" variant="standard" value={gar.statuscode_value ? gar.statuscode_value : '-'} />
                                            </Grid>
                                            <Grid xs={6} sx={{ p: 1 }}>
                                                <TextField fullWidth label="Acreedor" variant="standard" value={gar.new_acreedor ? gar.new_acreedor : '-'} />
                                            </Grid>
                                        </Grid>
                                    )
                                }
                            </ TabPanel>
                            <TabPanel value="1" sx={{ p: 0, mt: 1, height: '400px', overflow: 'auto' }}>
                                <Grid container>
                                    <Tabla
                                        datos={adjuntos}
                                        columnas={COLUMNASDOCUMENTOSGARANTIA}
                                        // titulo={"Adjuntos de la Garantia"}
                                        handleOpenNew={handleOpenNew}
                                        accionAgregar={true}
                                    />
                                </Grid>
                            </ TabPanel>
                            <ModalNuevoAdjuntoG open={openNew} handleClose={handleCloseNew} garantiaid={garantiaid} />
                        </TabContext>
                    </Container>
                </Paper>
            </DialogContent>
            {/* </Box> */}
        </Dialog >
    )
}

export default ModalGarantiasPyme