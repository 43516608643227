import React from "react";
import { Paper, Typography } from "@mui/material";
import Moment from 'moment'

export const COLUMNSCUENTASGR = [
    {
        header: "SGR",
        accessorKey: "_new_sgr_value",
        Cell: ({ cell }) => (
          <Typography color="inherit" sx={{ fontWeight: 600, fontSize: {xs:".7rem", md:".8rem"}  }}>
            {cell.getValue()}
          </Typography>
        ),
    },
    {
        header: "Fecha de Creación",
        accessorKey: "createdon",
        Cell: ({ cell }) => (
          <Typography color="inherit" sx={{ fontWeight: 600, fontSize: {xs:".7rem", md:".8rem"}  }}>
            {cell.getValue()}
          </Typography>
        ),
    },
    {
        header: "Rol",
        accessorKey: "new_rol",
        Cell: ({ cell }) => (
          <Typography color="inherit" sx={{ fontWeight: 600, fontSize: {xs:".7rem", md:".8rem"}  }}>
            {cell.getValue()}
          </Typography>
        ),
    },
    {
        header: "Saldo Bruto Gtias Vig",
        accessorKey: "new_saldobrutogaratiasvigentes",
        Cell: ({ cell }) => (
          <Typography color="primary" sx={{ fontWeight: 600, fontSize: {xs:".7rem", md:".8rem"} }}>
            {cell.getValue()}
          </Typography>
        ),
    },
    {
        header: "Saldo Deuda por Gtias Abo",
        accessorKey: "new_saldodeudaporgtiasabonada",
        Cell: ({ cell }) => (
          <Typography color="inherit" sx={{ fontWeight: 600, fontSize: {xs:".7rem", md:".8rem"}  }}>
            {cell.getValue()}
          </Typography>
        ),
    },
    {
        header: "Cant Gtias en Mora",
        accessorKey: "new_cantidadgtiasenmora",
        Cell: ({ cell }) => (
          <Typography color="inherit" sx={{ fontWeight: 600, fontSize: {xs:".7rem", md:".8rem"}  }}>
            {cell.getValue()}
          </Typography>
        ),
    },
    {
        header: "Situación de la Deuda",
        accessorKey: "new_situaciondeladueda",
        Cell: ({ cell }) => (
          <Typography color="inherit" sx={{ fontWeight: 600, fontSize: {xs:".7rem", md:".8rem"}  }}>
            {cell.getValue()}
          </Typography>
        ),
    },
    {
        header: "Días de Atraso",
        accessorKey: "new_diasdeatraso",
        Cell: ({ cell }) => (
          <Typography color="inherit" sx={{ fontWeight: 600, fontSize: {xs:".7rem", md:".8rem"}  }}>
            {cell.getValue()}
          </Typography>
        ),
    },
]