import React from 'react'
import { Box } from '@mui/material';
import Fade from 'react-reveal/Fade';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ComprobanteDeVenta from '../Components/ComprobanteDeVenta';
import Perfil from '../Components/Perfil';
import Relaciones from '../Components/Relaciones';
import SociedadesDeBolsa from '../Components/SociedadesDeBolsa'
import CertificadosPymes from '../Components/CertificadosPymes'

const Cuenta = () => {
    const [value, setValue] = React.useState("1")

    const handleChange = (event, newValue) => {
        setValue(newValue)
    }

    return (
        <Box component="main" sx={{ flexGrow: 0, pt: 4, px: 1 }}>
            <Fade>
                <Grid sx={{ mx: 2 }}>
                    <TabContext value={value}>
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            // bgcolor: 'background.paper'
                        }}>
                            <TabList
                                aria-label="scrollable force tabs example"
                                onChange={handleChange} variant="scrollable"
                                scrollButtons="auto"
                                allowScrollButtonsMobile
                            >
                                <Tab label="General" value="1" sx={{ fontWeight: 'bold', fontSize: '16px' }}></Tab>
                                <Tab label="Vinculaciones" value="2" sx={{ fontWeight: 'bold', fontSize: '16px' }}></Tab>
                                <Tab label="Certificados Pymes" value="3" sx={{ fontWeight: 'bold', fontSize: '16px' }}></Tab>
                                <Tab label="Sociedad de bolsa" value="4" sx={{ fontWeight: 'bold', fontSize: '16px' }}></Tab>
                                <Tab label="Comprobante de Venta" value="5" sx={{ fontWeight: 'bold', fontSize: '16px' }}></Tab>
                            </TabList>
                        </Box>
                        <TabPanel value="1" sx={{ p: 0 }}>
                            <Perfil />
                        </TabPanel>
                        <TabPanel value="2" sx={{ p: 0 }}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <Relaciones />
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="3" sx={{ p: 0 }}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <CertificadosPymes />
                                </Grid>
                            </Grid>
                        </TabPanel>
                        <TabPanel value="4" sx={{ p: 0 }}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <SociedadesDeBolsa />
                                </Grid>

                            </Grid>
                        </TabPanel>
                        <TabPanel value="5" sx={{ p: 0 }}>
                            <Grid container spacing={4}>
                                <Grid item xs={12} sm={12} md={12}>
                                    <ComprobanteDeVenta />
                                </Grid>
                            </Grid>
                        </TabPanel>
                    </TabContext>
                </Grid>
            </Fade>
        </Box >
    )
}

export default Cuenta