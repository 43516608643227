import Chip from '@mui/material/Chip';

export const COLUMNSLIB = [
    {
        accessorKey: 'id',
        enableEditing: false,
    },
    {
        header: 'Fecha',
        accessorKey: 'new_fecha',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Librador',
        accessorKey: 'new_librador',
        Cell: ({ cell }) => <strong><Chip label={cell.getValue()} sx={{ bgcolor: '#ad387f' }} /></strong>
    },
    {
        header: '% Librador',
        accessorKey: 'new_porcentajelibrador',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>,
        muiTableBodyCellEditTextFieldProps: {
            type: 'number',
        },
    },
]
//#