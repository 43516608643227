import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import usuariosReducer from '../Redux/Usuarios.js';
import tokenReducer from '../Redux/Token.js';
import seriesReducer from '../Redux/Series.js'
import garantiasReducer from '../Redux/Garantias.js';
import contactosReducers from '../Redux/Contacto'
import operacionesReducer from '../Redux/OperacionesSindicadas.js'
import sgrsReducers from '../Redux/SGR'
import documentosPorCuentaReducers from '../Redux/CarpetaDigital'
import cuentaReducers from '../Redux/Cuenta'
import relacionesReducers from '../Redux/RelacionDeVinculacion.js'
import tipoDocumentosReducers from '../Redux/TipoDeDocumento'
import planCuotasReducer from '../Redux/PlanDeCuotas'
import abrirModal from '../Redux/AbrirModal'
import datosReducers from '../Redux/DatosSolicitudAlta.js'
import limitesReducers from '../Redux/LimitePorLinea.js'
import actividadesAFIPReducers from '../Redux/ActividadAFIP'
import indicadoresReducer from '../Redux/Indicadores.js'
import operacionesReducers from '../Redux/Operaciones.js'
import documentosPorSerieReducers from '../Redux/DocumentosPorSerie.js'

const appReducer = combineReducers({
    usuarios: usuariosReducer,
    token: tokenReducer,
    series: seriesReducer,
    garantias: garantiasReducer,
    operacione: operacionesReducer,
    contactos: contactosReducers,
    sgrs: sgrsReducers,
    documentosPorCuenta: documentosPorCuentaReducers,
    cuenta: cuentaReducers,
    relaciones: relacionesReducers,
    tiposDocumentos: tipoDocumentosReducers,
    planCuotas: planCuotasReducer,
    setModal: abrirModal,
    datos: datosReducers,
    limites: limitesReducers,
    actividadesAFIP: actividadesAFIPReducers,
    indicadores: indicadoresReducer,
    operaciones: operacionesReducers,
    documentosPorSerie: documentosPorSerieReducers,
})

const rootReducer = (state, action) => {
    // when a logout action is dispatched it will reset redux state
    if (action.type === 'CERRAR_SESION') {
      state = undefined;
    }
  
    return appReducer(state, action);
  };

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore() {
    const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)))
    return store
}