import React, { useState, useContext } from "react"
import {
  Dialog, Box, useTheme, DialogContent, DialogContentText, DialogTitle, CircularProgress, Button,
  IconButton, TextField, DialogActions, FormControl, Typography, Modal
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useSelector, useDispatch } from "react-redux"
import { modificarDocumentoSeleccionado } from "../../Redux/CarpetaDigital"
import "react-toastify/dist/ReactToastify.css"
import { FormProvider, useForm } from 'react-hook-form'
import CustomTextField from "../CustomTextField"
import CustomSearchSelect from "../CustomSearchSelect"
import TokenContext from '../../context/TokenContext'
import Tooltip from '@mui/material/Tooltip';
import Fade from 'react-reveal/Fade';
import { green } from '@mui/material/colors';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { es } from 'date-fns/locale'
import { Controller } from 'react-hook-form';

const ModalDatosDocumentacion = ({ open, setOpen, id, documentos }) => {

  const dispatch = useDispatch()
  const theme = useTheme()
  const token = useContext(TokenContext)

  const modificarDocumento = useSelector(store => store.documentosPorCuenta.modificarDocumento)

  const [defaultValues, setDefaultValues] = useState({
    estado: { value: documentos[0]?.statuscode, label: documentos[0]['statuscode@OData.Community.Display.V1.FormattedValue'] },
    nombre: documentos[0]?.new_name,
    fechadeCreacion: documentos[0]['createdon@OData.Community.Display.V1.FormattedValue'],
    visiblePortalSocio: { label: documentos[0]?.new_visibleenportal ? 'Si' : 'No', value: documentos[0]?.new_visibleenportal },
    fechaVencimiento: documentos[0]?.new_fechadevencimiento != null ? documentos[0]?.new_fechadevencimiento.split("T")[0] : ""
  })

  const methods = useForm({
    shouldUnregister: false,
    defaultValues,
    mode: "onChange"
  })
  const { handleSubmit } = methods

  const modificarDocumentacion = (datos) => {
    const { nombre, fechadeCreacion, estado, visiblePortalSocio, fechaVencimiento } = datos
    debugger
    dispatch(modificarDocumentoSeleccionado(token, id, Number(estado.value), fechaVencimiento, visiblePortalSocio.value + ""))
  }

  const estadoOpciones = [
    { value: '1', label: 'Pendiente' },
    { value: '100000000', label: 'Recibido' },
    { value: '100000001', label: 'Aprobado' }
  ]
  const visibleOpciones = [
    { value: true, label: 'Si' },
    { value: false, label: 'No' },
  ]

  const handleClose = () => {
    setOpen(false)
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: 300,
    maxWidth: 550,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    py: 1,
    px: 2
  };

  const buttonSx = {
    ...(modificarDocumento === 'LOADING' && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      closeAfterTransition
    >
      <Fade in={open}>
        <Box sx={style}>
          <Box sx={{ display: "flex", justifyContent: "space-between", backgroundColor: theme.palette.background.common }}>
            <Typography id="alert-dialog-title" sx={{ fontSize: "1.2rem", mt: 1 }}>
              Modificar Documentación
            </Typography>
            <Tooltip title={<Typography sx={{ color: '#fff' }}>Cerrar</Typography>} >
              <IconButton
                edge="end"
                color="warning"
                onClick={handleClose}
                aria-label="close"
                sx={{ mr: 1 }}
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
          </Box>
          <FormProvider {...methods} sx={{ backgroundColor: theme.palette.background.default }}>
            <Box component="div" sx={{ display: "flex", flexDirection: { md: "row", xs: "column" }, justifyContent: "space-between", alignItems: "center" }}>
              <FormControl sx={{ m: 1, width: { md: '100%', xs: "90%" } }}>
                <CustomTextField
                  Component={TextField}
                  readOnly={true}
                  type="text"
                  name="nombre"
                  label="Nombre"
                />
              </FormControl>
            </Box>
            <Box component="div" sx={{ display: "flex", flexDirection: { md: "row", xs: "column" }, justifyContent: "space-between", alignItems: "center" }}>
              <FormControl sx={{ m: 1, width: { md: '70%', xs: "90%" } }}>
                <CustomSearchSelect
                  options={estadoOpciones}
                  type="text"
                  name="estado"
                  lab="Estado"

                />
              </FormControl>
              <FormControl sx={{ m: 1, width: { md: '70%', xs: "90%" } }}>
                <CustomSearchSelect
                  options={visibleOpciones}
                  type="text"
                  name="visiblePortalSocio"
                  lab="Visible en Portal del Socio"
                />
              </FormControl>
            </Box>
            <Box component="div" sx={{ display: "flex", flexDirection: { md: "row", xs: "column" }, justifyContent: "space-between", alignItems: "center" }}>
              <Controller
                fullwidth="true"
                name="fechaVencimiento"
                // sx={{ m: 1 }}
                render={({ field: { ref, onBlur, name, ...field }, fieldState }) => (
                  <FormControl fullWidth sx={{ m: 1, mt: 2, width: { md: '70%', xs: "90%" } }} >
                    <LocalizationProvider locale={es} dateAdapter={AdapterDateFns}>
                      <DesktopDatePicker
                        {...field}
                        views={['day', 'month', 'year']}
                        inputRef={ref}
                        label="Fecha de Vencimiento:"
                        renderInput={(inputProps) => (
                          <TextField
                            {...inputProps}
                            onBlur={onBlur}
                            // size="small"
                            name={name}
                            error={!!fieldState.error}
                            helperText={fieldState.error ? fieldState.error.message : null}
                            rules={{ required: "Required!" }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </FormControl>
                )}
              />
              <FormControl sx={{ m: 1, width: { md: '70%', xs: "90%" } }}>
                <CustomTextField
                  Component={TextField}
                  readOnly={true}
                  type="text"
                  name="fechadeCreacion"
                  label="Fecha de Creación"
                />
              </FormControl>
              {/* <FormControl sx={{ m: 1, width: { md: '70%', xs: "90%" } }}>
                <CustomTextField
                  Component={TextField}
                  fullWidth
                  type="date"
                  name="fechaVencimiento"
                  label="Fecha de Vencimiento"
                />
              </FormControl> */}
            </Box>
          </FormProvider>
          <Box sx={{ m: 1, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
            <Button
              type="button"
              onClick={handleSubmit(modificarDocumentacion)}
              variant="contained"
              sx={buttonSx}
              disabled={modificarDocumento === 'LOADING'}
            >
              {modificarDocumento === 'LOADING' && (
                <CircularProgress
                  size={24}
                  color="inherit"
                  style={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: -12,
                    marginLeft: -12
                  }}
                />
              )}
              Actualizar
            </Button>
          </Box>
          {/* <Box sx={{ mt: 3, mb: 2, position: 'relative' }}>
            <Button
              type="button"
              onClick={handleSubmit(modificarDocumentacion)}
              disabled={modificarDocumento === 'LOADING'}
              variant="contained"
              sx={{ fontSize: { xs: ".7rem", md: ".9rem" } }}
            >
              Actualizar
            </Button>
            {modificarDocumento === 'LOADING' && (
              <CircularProgress
                size={27}
                sx={{
                  color: theme.palette.icons,
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box> */}
        </Box>
      </Fade>
    </Modal>
    // <div>
    //   <Dialog
    //     open={open}
    //     onClose={handleClose}
    //     aria-labelledby="alert-dialog-title"
    //     aria-describedby="alert-dialog-description"
    //     maxWidth="md"
    //     fullWidth
    //   >
    //     <Box sx={{ display: "flex", justifyContent:"space-between", px:2 }}>
    //       <DialogTitle id="alert-dialog-title" sx={{ fontSize: {xs:"1rem", md:"1.2rem"} }}>
    //         Modificar Documentación
    //       </DialogTitle>
    //       <IconButton
    //         edge="end"
    //         color="warning"
    //         onClick={handleClose}
    //         aria-label="close"
    //       >
    //         <CloseIcon sx={{mr:1}}/>
    //       </IconButton>
    //     </Box>
    //     <Divider />
    //     <DialogContent>
    //       <DialogContentText id="alert-dialog-description">
    //         <FormProvider {...methods}>
    //             <Box component="div" sx={{display:"flex", flexDirection: {md:"row", xs:"column"}, justifyContent:"space-between", alignItems:"center"}}>
    //                 <FormControl sx={{ m: 1, width: {md:'70%', xs:"90%"} }}>
    //                     <CustomTextField
    //                         Component={TextField}
    //                         readOnly ={true}
    //                         type="text"
    //                         name="nombre"
    //                         label="Nombre"
    //                     />
    //                 </FormControl>
    //                 <FormControl sx={{ m: 1, width: {md:'70%', xs:"90%"} }}>
    //                     <CustomTextField
    //                         Component={TextField}
    //                         readOnly ={true}
    //                         type="text"
    //                         name="fechadeCreacion"
    //                         label="Fecha de Creación"
    //                     />
    //                 </FormControl>
    //             </Box>
    //             <Box component="div" sx={{display:"flex", flexDirection: {md:"row", xs:"column"}, justifyContent:"space-between", alignItems:"center"}}>
    //                 <FormControl sx={{ m: 1, width: {md:'70%', xs:"90%"} }}>
    //                     <CustomSearchSelect
    //                         options={estadoOpciones}
    //                         type="text"
    //                         name="estado"
    //                         lab="Estado"

    //                     />
    //                 </FormControl>
    //                 <FormControl sx={{ m: 1, width: {md:'70%', xs:"90%"} }}>
    //                     <CustomSearchSelect
    //                         options={visibleOpciones}
    //                         type="text"
    //                         name="visiblePortalSocio"
    //                         lab="Visible en Portal del Socio"
    //                     />
    //                 </FormControl>
    //                 <FormControl sx={{ m: 1, width: {md:'70%', xs:"90%"} }}>
    //                     <CustomTextField
    //                         Component={TextField}
    //                         type="date"
    //                         name="fechaVencimiento"
    //                         label="Fecha de Vencimiento"
    //                     />
    //                 </FormControl>
    //             </Box>
    //         </FormProvider>
    //       </DialogContentText>
    //     </DialogContent>
    //     <DialogActions>
    //         <Box sx={{ mt: 3, mb:2, position: 'relative' }}>
    //             <Button
    //               type="button"
    //               onClick={handleSubmit(modificarDocumentacion)}
    //               disabled={modificarDocumento === 'LOADING'}
    //               variant="contained" 
    //               sx={{ fontSize:{ xs:".7rem", md:".9rem"}}}
    //               >
    //                   Actualizar
    //             </Button>
    //             {modificarDocumento === 'LOADING' && (
    //             <CircularProgress
    //                 size={27}
    //                 sx={{
    //                     color: theme.palette.icons,
    //                     position: 'absolute',
    //                     top: '50%',
    //                     left: '50%',
    //                     marginTop: '-12px',
    //                     marginLeft: '-12px',
    //                 }}
    //             />
    //             )}
    //         </Box>
    //     </DialogActions>
    //   </Dialog>
    // </div>
  );
};

export default ModalDatosDocumentacion;
