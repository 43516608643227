import React, { useState, useContext, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import {
    Dialog, Box, DialogContent, DialogContentText, DialogTitle, Button, IconButton, TextField,
    DialogActions, FormControl, Divider, CircularProgress, Tooltip, Typography, useTheme
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { useSelector, useDispatch } from "react-redux"
import CustomTextField from '../CustomTextField'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import CustomSearchSelect from '../CustomSearchSelect'
import { editarEstructuraSGR } from '../../Redux/Indicadores'
import TokenContext from '../../context/TokenContext'
import { green } from '@mui/material/colors';

const opcionesRol = [
    { value: 100000004, label: 'Accionistas' },
    { value: 100000000, label: 'Integrantes del Consejo de Administración' },
    { value: 100000001, label: 'Integrantes de la Comisión Fiscalizadora' },
    { value: 100000002, label: 'Gerente General' },
    { value: 100000003, label: 'Auditor' },
]

const ModalActualizarEstructuraSGR = ({ open, setOpen, datos, idSgr }) => {
    const dispatch = useDispatch()
    const token = useContext(TokenContext)
    const [rolSeleccionado, setRolSeleccionado] = React.useState([])
    const resultadoModif = useSelector(store => store.indicadores.resultadoModif)
    const theme = useTheme()
    const [roles, setRoles] = useState(...opcionesRol.filter(rol => rol.value === datos[0].rol))

    const [defaultValues, setDefaultValues] = useState({
        nombre: datos[0].nombre,
        rol: { value: roles.value, label: roles.label },
        cargo: datos[0].cargo,
        particAcc: datos[0],
        id: datos[0].id,
        new_numerodedocumento: datos[0].new_numerodedocumento,
        new_porcentaje: datos[0].new_porcentaje,
        new_correoelectronico: datos[0].new_correoelectronico
    })

    useEffect(() => {
        if (resultadoModif === "EXITO") {
            handleClose()
        }
    }, [resultadoModif])

    const crearEstructura = (datos) => {
        const { id, nombre, cargo, rol, new_numerodedocumento, new_porcentaje, new_correoelectronico } = datos
        dispatch(editarEstructuraSGR(token, idSgr, id, rol.value, cargo, nombre, new_numerodedocumento,
            new_porcentaje, new_correoelectronico))
        dispatch((dispatch) => {
            dispatch(editarEstructuraSGR(token, idSgr, id, rol.value, cargo, nombre, new_numerodedocumento,
                new_porcentaje, new_correoelectronico)).then(() => {
                    debugger
                    let sgrStore = localStorage.getItem('SGR Datos')

                });
        });
    }

    const validationSchema = yup.object().shape({
        new_numerodedocumento: yup.string().required('El dni es requerido'),
        rol: yup.object().required('El rol es requerido'),
        cargo: yup.string().required('El cargo es requerido'),
        nombre: yup.string().required('El nombre es requerido'),
        new_porcentaje: yup.string().when('rol', (rol) => {
            return rol.value == '100000004' ? yup.number().required().positive().integer() :
                yup.string().notRequired()
        }),
    })

    const methods = useForm({
        shouldUnregister: false,
        defaultValues,
        resolver: yupResolver(validationSchema),
        mode: "onChange",
    })

    const rol = methods.watch('rol')

    useEffect(() => {
        setRolSeleccionado(rol?.value)
    }, [rol])

    const { handleSubmit } = methods


    const handleClose = () => {
        setOpen(false)
    }

    const buttonSx = {
        ml: 0,
        ...(resultadoModif === 'LOADING' && {
            bgcolor: green[500],
            '&:hover': {
                bgcolor: green[700],
            },
        })
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                maxWidth="md"
                fullWidth
            >
                <Box sx={{ backgroundColor: theme.palette.background.default, display: "flex", justifyContent: "space-between", px: 2 }}>
                    <DialogTitle id="alert-dialog-title" sx={{ fontSize: { xs: "1rem", md: "1.1rem" } }} >
                        Modificar estructura SGR
                    </DialogTitle>
                    <Tooltip title={<Typography sx={{ color: '#fff' }}>Cerrar</Typography>} >
                        <IconButton
                            edge="end"
                            color="warning"
                            onClick={handleClose}
                            aria-label="close"
                            sx={{ width: 40, height: 40, mr: 1, mt: 1 }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </Tooltip>
                </Box>
                <Divider />
                <DialogContent sx={{ backgroundColor: theme.palette.background.default, py: 1 }}>
                    <DialogContentText id="alert-dialog-description">
                        <FormProvider {...methods}>
                            <Box component="div" sx={{ display: "flex", flexDirection: { md: "row", xs: "column" }, justifyContent: "space-between", alignItems: "center" }}>
                                <FormControl sx={{ m: 1, width: { md: '70%', xs: "90%" } }}>
                                    <CustomTextField
                                        Component={TextField}
                                        type="text"
                                        name="new_numerodedocumento"
                                        label="CUIT"
                                        helperText="El cuit es requerido"
                                        req="true"
                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1, width: { md: '70%', xs: "90%" } }}>
                                    <CustomTextField
                                        Component={TextField}
                                        type="text"
                                        name="nombre"
                                        label="Nombre y Apellido"
                                        req="true"
                                    />
                                </FormControl>

                            </Box>
                            <Box component="div" sx={{ display: "flex", flexDirection: { md: "row", xs: "column" }, justifyContent: "space-between", alignItems: "center" }}>
                                <FormControl sx={{ m: 1, width: { md: '70%', xs: "90%" } }}>
                                    <CustomTextField
                                        Component={TextField}
                                        type="text"
                                        name="cargo"
                                        label="Cargo"
                                        req="true"
                                    />
                                </FormControl>
                                <FormControl sx={{ m: 1, width: { md: "70%", sx: "90%" } }}>
                                    <CustomSearchSelect
                                        options={opcionesRol}
                                        type="text"
                                        name="rol"
                                        lab="Rol"
                                        helperText="Por favor seleccione el rol"
                                        rules={{ required: "Required!" }}
                                        req="true"
                                    />
                                </FormControl>
                            </Box>
                            <Box component="div" sx={{ display: "flex", flexDirection: { md: "row", xs: "column" }, justifyContent: "space-between", alignItems: "center" }}>
                                <FormControl sx={{ m: 1, width: { md: '49%', xs: "90%" } }}>
                                    <CustomTextField
                                        Component={TextField}
                                        type="text"
                                        name="new_correoelectronico"
                                        label="Correo electrónico"
                                    />
                                </FormControl>
                                {rolSeleccionado === 100000004 ?
                                    <FormControl sx={{ m: 1, width: { md: '49%', xs: "90%" } }}>
                                        <CustomTextField
                                            Component={TextField}
                                            type="number"
                                            name="new_porcentaje"
                                            label="Porcentaje"
                                            helperText="El porcentaje es requerido"
                                        />
                                    </FormControl>
                                    : null}
                            </Box>
                        </FormProvider>
                    </DialogContentText>
                    <Box sx={{ mt: 2, mx: 1, p: 0, display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <Button
                            type="button"
                            onClick={handleSubmit(crearEstructura)}
                            variant="contained"
                            sx={buttonSx}
                            disabled={resultadoModif === 'LOADING'}
                        >
                            {resultadoModif === 'LOADING' && (
                                <CircularProgress
                                    size={24}
                                    color="inherit"
                                    style={{
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: -12,
                                        marginLeft: -12
                                    }}
                                />
                            )}
                            Editar
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default ModalActualizarEstructuraSGR
