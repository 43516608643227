import React, { useContext } from 'react'
import { Box, Typography, Button, Paper, Grid } from '@mui/material';
import Modal from '@mui/material/Modal';
import { useSelector, useDispatch } from 'react-redux';
import { cargarDocumentacionPorOperacion, obtenerDocumentosDeOperacion } from '../../Redux/Operaciones';
import { toast } from 'react-toastify';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { MuiFileInput } from 'mui-file-input'
import TextField from '@mui/material/TextField';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Tooltip from '@mui/material/Tooltip';
import TokenContext from '../../context/TokenContext';
;

const ModalNuevoDocumentoOP = ({ open, handleClose, documentoOPID, documento }) => {
    const dispatch = useDispatch()
    const token = useContext(TokenContext)

    const [selectedFiles, setSelectedFiles] = React.useState([])
    const [desabilitado, setDesabilitado] = React.useState(false)
    const [opcionesDocumentos, setOpcionesDocumentos] = React.useState([])

    const accountid = useSelector(store => store.usuarios.accountid)
    const cargaDocumentoSelector = useSelector(store => store.operaciones.cargaDocumento)
    const documentoIDSelector = useSelector(store => store.operaciones.documentoid)

    React.useEffect(() => {
        if (cargaDocumentoSelector == "EXITO") {
            dispatch(obtenerDocumentosDeOperacion(accountid, token))
            if (desabilitado == true) {
                setDesabilitado(!desabilitado)
                setTimeout(() => {
                    handleClose()
                    // reset()
                    setSelectedFiles([])
                }, 1500)
            }
        }
    }, [cargaDocumentoSelector, token])
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        // p: 4,
    };

    const changeHandler = (event) => {
        setSelectedFiles(event)
    };

    const submitDatos = () => {
        if (documentoOPID === '') {
            toast.error('El documento es requerido!', {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }

        if (selectedFiles.length === 0) {
            toast.error('El archivo adjunto es requerido!', {
                theme: "dark",
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }
        const formData = new FormData();
        for (let index = 0; index < selectedFiles.length; index++) {
            if (selectedFiles[index].size >= 15000000) {
                toast.error('El archivo no puede superar los 15 megas', {
                    theme: "dark",
                    position: "top-center",
                    autoClose: 1500,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                setSelectedFiles([])
                return
            }
            let element = selectedFiles[index];
            formData.append(`body${index}`, element);
        }
        dispatch(cargarDocumentacionPorOperacion(formData, documentoOPID, token))
        setDesabilitado(!desabilitado)
    };

    const cerrarModal = () => {
        // reset()
        setSelectedFiles([])
        handleClose()
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Paper elevation={4} sx={{ py: 1 }}>
                    <Box sx={{ display: "flex", justifyContent: "space-between", px: 1 }}>
                        <Typography id="alert-dialog-title" sx={{ fontSize: "1.2rem", pt: 1, ml: 1 }}>
                            Subir Adjunto
                        </Typography>
                        <Tooltip title={<Typography sx={{ color: '#fff' }}>Cerrar</Typography>}>
                            <IconButton
                                edge="end"
                                color="warning"
                                onClick={cerrarModal}
                                aria-label="close"
                                sx={{ mr: 2 }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                    {/* <FormProvider {...methods}>
                        <form id="Garantia"> */}
                    <Grid container>
                        <Grid xs={12} sx={{ p: 2, mt: 2 }}>
                            <TextField
                                label="Documento"
                                value={documento}
                                fullWidth
                                variant="standard"
                            />
                        </Grid>
                        <Grid xs={12} sx={{ p: 2, mt: 1 }}>
                            <MuiFileInput
                                value={selectedFiles}
                                multiple
                                label="Adjuntar documento"
                                onChange={changeHandler}
                            />
                        </Grid>
                        <Grid sx={{ p: 2 }}>
                            <Button
                                sx={{ mt: 1, mb: 1 }}
                                fullWidth
                                variant="contained"
                                onClick={submitDatos}
                                disabled={desabilitado}
                            >
                                Subir archivo
                            </Button>
                        </Grid>
                        <Grid sx={{ px: 2 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Box sx={{ height: '100%' }} >
                                    <WarningAmberIcon color="warning" sx={{ mr: 1 }} />
                                </Box>
                                <Box sx={{ height: '100%' }} >
                                    <Typography>
                                        El tamaño máximo permitido por archivo es de 15 MB
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    {/* </form>
                    </FormProvider> */}
                </Paper>
            </Box >
        </Modal >
    )
}

export default ModalNuevoDocumentoOP