import React, { useContext } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Grid from '@mui/material/Unstable_Grid2'
import { COLUMNASGLOBAL } from '../Columnas/ColumnasRelaciones'
import { eliminarRelacionVinculacion, obtenerRelaciones, obtenerRelacionesFETCH } from '../Redux/RelacionDeVinculacion'
import TablaRelaciones from './TablaRelaciones'
import TokenContext from '../context/TokenContext'
import { Box, Paper } from '@mui/material'
import ModalRelacionesPortalSocio from "../Components/modales/ModalRelacionesPortalSocio"
import LoaderAnimado from './loaderAnimado/LoaderAnimado'

const Relaciones = () => {
    const dispatch = useDispatch()
    const token = useContext(TokenContext)
    //################ VINCULACIONES ####################
    const [relaciones, setRelaciones] = React.useState([])
    const [llamadaRelaciones, setLlamadaRelaciones] = React.useState(false)
    const relacionesSelector = useSelector(store => store.relaciones.relaciones)
    const [columnasGlobal, setColumnasGlobal] = React.useState(COLUMNASGLOBAL)

    const accountid = useSelector(store => store.usuarios.accountid)
    const [totalPorcentajeAccionario, setTotalPorcentajeAccionario] = React.useState(0)
    const [abrirModal, setAbrirModal] = React.useState(false)
    const [modo, setModo] = React.useState('')
    const [valoresAeditar, setValoresAeditar] = React.useState()
    const [relacionId, setRelacionId] = React.useState('')
    const [loadingData, setLoadingData] = React.useState(false)
    const loadingRelaciones = useSelector(store => store.relaciones.loadingRelaciones)
    const inactivacionSelector = useSelector(store => store.relaciones.inactivar)

    React.useEffect(() => {
        setLoadingData(loadingRelaciones)
    }, [loadingRelaciones])

    React.useEffect(() => {
        
        if (relacionesSelector.length > 0 && llamadaRelaciones === true) {
            var relas = []
            let porcentaje = 0
            relacionesSelector.filter(relacion => relacion.statecode === 0).forEach(item => {
                var obj = {
                    personeria: item["aa.new_personeria@OData.Community.Display.V1.FormattedValue"],
                    contactid: item["ab.contactid"],
                    firstname: item["ab.firstname"],
                    lastname: item["ab.lastname"],
                    name: item["aa.name"],
                    emailaddress1: item["aa.emailaddress1"],
                    birthdate: item["ab.birthdate"],
                    new_nmerodedocumento: item["aa.new_nmerodedocumento"],
                    new_nrodedocumento: item["ab.new_nrodedocumento"],
                    new_cuitcuil: item["ab.new_cuitcuil"],
                    new_lugardenacimiento: item["ab.new_lugardenacimiento"],
                    telephone1: item["ab.telephone1"],
                    emailaddress1: item["ab.emailaddress1"],
                    emailadress1: item["aa.emailaddress1"],
                    new_correoelectrnicopararecibirestadodecuenta: item["ab.new_correoelectrnicopararecibirestadodecuenta"],
                    new_profesionoficioactividad: item["ab.new_profesionoficioactividad"],
                    new_name: item.new_name,
                    id: item.new_participacionaccionariaid,
                    new_porcentajedeparticipacion: item.new_porcentajedeparticipacion,
                    new_relacion: item["new_relacion@OData.Community.Display.V1.FormattedValue"],
                    new_tipoderelacion: item.new_tipoderelacion,
                    new_tipoderelacion_nombre: item["new_tipoderelacion@OData.Community.Display.V1.FormattedValue"],
                    _new_cuentacontactovinculado_value: item["_new_cuentacontactovinculado_value@OData.Community.Display.V1.FormattedValue"],
                    _new_cuentacontactovinculado_id: item._new_cuentacontactovinculado_value,
                    new_porcentajedeparticipacion: item.new_porcentajedeparticipacion,
                    new_porcentajebeneficiario: item.new_porcentajebeneficiario,
                    familystatuscode: item["ab.familystatuscode"],
                    new_cargo: item.new_cargo
                }
                relas.push(obj)
                if(obj.new_tipoderelacion === 100000001 && obj.new_porcentajedeparticipacion != undefined 
                    && obj.new_porcentajedeparticipacion > 0){
                    porcentaje += obj.new_porcentajedeparticipacion
                }
            })
            setTotalPorcentajeAccionario(porcentaje)
            setRelaciones(relas)
            setLoadingData(false)
        } else if (token && llamadaRelaciones === false) {
            obtenerRelacionesPorCuenta(token)
            setLlamadaRelaciones(true)
        } else if (relacionesSelector.length == 0 && llamadaRelaciones === true && inactivacionSelector === "EXITO") {
            setRelaciones([])
        }
    }, [relacionesSelector, token])


    const obtenerRelacionesPorCuenta = (Token) => {
        dispatch(obtenerRelaciones(accountid, Token))
    }

    const relacionesFETCH = (Token) => {
        dispatch(obtenerRelacionesFETCH(accountid, Token))
    }

    const cerrarModal = () => {
        setAbrirModal(false)
        setModo('')
    }

    const handleModal = (id, obj, editar) => {
        if (editar === true) {
            const { new_tipoderelacion, firstname, lastname, new_cuitcuil, new_nrodedocumento,
                emailaddress1, new_correoelectrnicopararecibirestadodecuenta, telephone1,
                birthdate, new_lugardenacimiento, new_profesionoficioactividad, familystatuscode,
                name, new_nmerodedocumento, emailadress1, personeria, new_porcentajedeparticipacion, new_porcentajebeneficiario, contactid, accountid, new_cargo
            } = obj

            if (new_nmerodedocumento !== undefined) {
                const defaultValues = {
                    accountid: accountid,
                    tipo: 'Juridica',
                    tipoRelacion: new_tipoderelacion,
                    razonSocial: name,
                    Nombre: firstname,
                    Apellido: lastname,
                    CUITCUIL: new_nmerodedocumento,
                    DNI: new_nrodedocumento,
                    fecha: birthdate,
                    lugarNacimiento: new_lugardenacimiento,
                    correo: emailadress1,
                    correo2: new_correoelectrnicopararecibirestadodecuenta,
                    ProfesionOficioActividad: new_profesionoficioactividad,
                    telefonoLaboral: telephone1,
                    estadoCivil: familystatuscode,
                    porcentajeParticipacion: new_porcentajedeparticipacion,
                    porcentaje: new_porcentajebeneficiario,
                    cargo: new_cargo,
                };
                setModo('editar')
                setRelacionId(id)
                setValoresAeditar(defaultValues)
            } else {
                const defaultValues = {
                    contactid: contactid,
                    tipo: 'Humana',
                    tipoRelacion: new_tipoderelacion,
                    razonSocial: name,
                    Nombre: firstname,
                    Apellido: lastname,
                    CUITCUIL: new_cuitcuil,
                    DNI: new_nrodedocumento,
                    fecha: birthdate,
                    lugarNacimiento: new_lugardenacimiento,
                    correo: emailaddress1,
                    correo2: new_correoelectrnicopararecibirestadodecuenta,
                    ProfesionOficioActividad: new_profesionoficioactividad,
                    telefonoLaboral: telephone1,
                    estadoCivil: familystatuscode,
                    porcentajeParticipacion: new_porcentajedeparticipacion,
                    porcentaje: new_porcentajebeneficiario,
                    cargo: new_cargo,
                };
                setModo('editar')
                setRelacionId(id)
                setValoresAeditar(defaultValues)
            }
        } else {
            setModo('agregar')
        }

        setAbrirModal(true)
    }

    const funcionEliminarRelacion = (objeto) => {
        const { id } = objeto
        if (token) {
            dispatch((dispatch) => {
                dispatch(eliminarRelacionVinculacion(objeto, accountid, id, token))
                    .then(() => {
                        // Actualiza la tabla cuando se cumple la promesa
                        dispatchearAccion()
                    });
            });
        }
    }

    const dispatchearAccion = () => {
        dispatch(obtenerRelaciones(accountid, token))
        relacionesFETCH(token)
    }

    return (
        <Grid container spacing={4} sx={{ pt: 2 }}>
            <Grid item xs={12}>
                <Paper elevation={4} >
                    {
                        loadingData ? (
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '100%',
                                    height: '100%',
                                    py: 2
                                }}>
                                <LoaderAnimado />
                            </Box>
                        )
                            :
                            <TablaRelaciones
                                lineas={relaciones}
                                columnas={columnasGlobal}
                                handleOpen={handleModal}
                                titulo={"Relaciones"}
                                funcionEliminar={funcionEliminarRelacion}
                            />
                    }

                </Paper>
            </Grid>
            <ModalRelacionesPortalSocio
                open={abrirModal}
                close={cerrarModal}
                modo={modo}
                id={relacionId}
                objeto={valoresAeditar}
                porcentaje={totalPorcentajeAccionario}
            />
        </Grid>
    )
}

export default Relaciones