import React from "react";
import { Typography, Chip } from "@mui/material";
import Moment from 'moment'

let dollarUS = Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const COLUMNSPLANCUOTAS = (tema) => [
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>N° DE CUOTA</Typography>,
    accessorKey: "new_numero",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {cell.getValue()}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>FECHA DE VENCIMIENTO</Typography>,
    accessorKey: "new_fechadevencimiento",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {Moment(cell.getValue()).format("DD-MM-YYYY")}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>ESTADO</Typography>,
    accessorKey: "statuscode",
    Cell: ({ cell }) => {
      switch (cell.getValue()) {
        case 100000000:
          return (
            <Chip label="Vencida" sx={{
              bgcolor: tema.palette.mode === "dark" ? 'rgba(255, 163, 25, 0.2)' : 'rgb(255, 163, 25, 0.8)',
              color: tema.palette.mode === "dark" ? 'rgb(255, 163, 25)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
          );
        case 100000001:
          return (
            <Chip label="Afrontada" sx={{
              bgcolor: tema.palette.mode === "dark" ? 'rgba(87, 202, 34, 0.2)' : 'rgb(87, 202, 34, 0.8)',
              color: tema.palette.mode === "dark" ? 'rgb(87, 202, 34)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
          );
        case 1:
          return (
            <Chip label="Vigente" sx={{
              bgcolor: tema.palette.mode === "dark" ? 'rgba87, 202, 34, 0.2)' : 'rgb(87, 202, 34, 0.8)',
              color: tema.palette.mode === "dark" ? 'rgb(87, 202, 34)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
          )
        case 100000002:
          return (
            <Chip label="Honrada" sx={{
              bgcolor: tema.palette.mode === "dark" ? 'rgba(87, 202, 34, 0.2)' : 'rgb(87, 202, 34, 0.8)',
              color: tema.palette.mode === "dark" ? 'rgb(87, 202, 34)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
          );
        case 100000003:
          return (
            <Chip label="Cancelada Anticipadamente" sx={{
              bgcolor: tema.palette.mode === "dark" ? 'rgba(255, 163, 25, 0.2)' : 'rgb(255, 163, 25, 0.8)',
              color: tema.palette.mode === "dark" ? 'rgb(255, 163, 25)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
          );
        case 100000005:
          return (
            <Chip label="Incobrable" sx={{
              bgcolor: tema.palette.mode === "dark" ? 'rgba(255, 163, 25, 0.2)' : 'rgb(255, 163, 25, 0.8)',
              color: tema.palette.mode === "dark" ? 'rgb(255, 163, 25)' : '#223354', fontSize: '.8rem', fontWeight: 'bold'
            }} />
          );
        default:
          return "---";
      }
    },
  },
  // {
  //     header: "Divisa",
  //     accessorKey: "transactioncurrencyid",
  //     Cell: ({ cell }) => (
  //       <Typography color="inherit" sx={{ fontWeight: 600, fontSize: {xs:".7rem", md:".8rem"}  }}>
  //         {cell.getValue()}
  //       </Typography>
  //     ),
  // },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>AMORTIZACIÓN</Typography>,
    accessorKey: "new_amortizacion",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {dollarUS.format(cell.getValue())}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>INTERÉS</Typography>,
    accessorKey: "new_interesperiodo",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {dollarUS.format(cell.getValue())}
      </Typography>
    ),
  },
  {
    header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>MONTO CUOTA</Typography>,
    accessorKey: "new_montocuota",
    Cell: ({ cell }) => (
      <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
        {dollarUS.format(cell.getValue())}
      </Typography>
    ),
  },
]