import React, { useState, useEffect, useContext } from "react";
import { Dialog, Box, DialogContent, DialogContentText, Toolbar, Typography, IconButton, AppBar, useTheme } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { COLUMNSCARPD } from "../../Columnas/ColumnasCarpetaDigital"
import { obtenerTodosDocumentosPorCuentaActivosPorSocio } from "../../Redux/CarpetaDigital"
import { useDispatch, useSelector } from "react-redux"
import Tabla from "../Tabla"
import { setModalCarpetaDigital } from "../../Redux/AbrirModal"
import LoaderAnimado from "../loaderAnimado/LoaderAnimado"
import TokenContext from '../../context/TokenContext'
import Tooltip from '@mui/material/Tooltip';

const ModalCarpetaDigital = ({ open, setOpen, titulo }) => {
  const dispatch = useDispatch()
  const token = useContext(TokenContext)
  const theme = useTheme()
  const [carpetas, setCarpetas] = useState([])
  const [disabled, setDisabled] = useState(true)
  const [idSGR, setIdSGR] = useState(JSON.parse(localStorage.getItem('Id SGR')))
  const [razonSocial, setRazonSocial] = useState('')
  const [log, setLog] = useState(true)

  const socioIdSelector = useSelector(store => store.cuenta.cuentaIdSeleccionada)
  const cuentaConGarantiaSelector = useSelector(store => store.cuenta.cuentaConGarantia)
  const modificarDocumento = useSelector(store => store.documentosPorCuenta.modificarDocumento)
  const cargaDocumento = useSelector(store => store.documentosPorCuenta.cargaDocumento)

  useEffect(() => {
    setLog(true)
    setCarpetas([])
    setRazonSocial("")
  }, [])

  React.useEffect(() => {
    if (token && socioIdSelector) {
      buscarDocumentos()
    }
  }, [token])

  useEffect(() => {
    if (modificarDocumento == "EXITO" || cargaDocumento == "EXITO") {
      buscarDocumentos()
    }
  }, [modificarDocumento, cargaDocumento])

  const buscarDocumentos = () => {
    let desabilitado = true
    if (cuentaConGarantiaSelector.length > 0) {
      var todasPymes = [...new Map(cuentaConGarantiaSelector.map(item =>
        [item["accountid"], item])).values()]
      if (todasPymes?.filter(item => item.accountid == socioIdSelector)?.length > 0) {
        var pyme = todasPymes.filter(item => item.accountid == socioIdSelector)[0]
        if (pyme["af.new_sgrlider"] === idSGR) {
          setDisabled(false)
          desabilitado = false
        } else {
          setDisabled(true)
          desabilitado = true
        }
        setRazonSocial(pyme.name)
      }
    }
    dispatch(obtenerTodosDocumentosPorCuentaActivosPorSocio(token, socioIdSelector))
      .then(data => {
        if (data?.length > 0) {
          var carpetasD = []
          data?.forEach(item => {
            var carpeta = {
              new_documentacionporcuentaid: item.new_documentacionporcuentaid,
              new_vinculocompartido: item.new_vinculocompartido,
              new_name: item.new_name,
              statuscode: item.statuscode,
              estado: item['statuscode@OData.Community.Display.V1.FormattedValue'],
              fechaCreacion: item['createdon@OData.Community.Display.V1.FormattedValue'],
              visiblePortal: item.new_visibleenportal,
              visiblePortalName: item['new_visibleenportal@OData.Community.Display.V1.FormattedValue'],
              fechaDeVencimiento: item.new_fechadevencimiento,
              objeto: {
                new_vinculocompartido: item.new_vinculocompartido,
                new_documentacionporcuentaid: item.new_documentacionporcuentaid,
                desabilitado: desabilitado
              }
            }
            carpetasD.push(carpeta)
          })
          setCarpetas(carpetasD)
          setLog(false)
        } else {
          setCarpetas([])
          setLog(false)
        }
      })
      .catch(() => {
        setCarpetas([])
        setLog(false)
      })
  }

  const handleClose = () => {
    setOpen(false)
    dispatch(setModalCarpetaDigital(false))
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
          <Typography sx={{ ml: 2, flex: 1, fontSize: { xs: ".8rem", md: "1rem" } }} >
            {razonSocial}
          </Typography>
          <Tooltip title={<Typography sx={{ color: '#fff' }}>Cerrar</Typography>} >
            <IconButton
              edge="end"
              color={theme.palette.mode === 'dark' ? "warning" : "#e3f2fd"}
              onClick={handleClose}
              aria-label="close"
              sx={{ mr: 2 }}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Toolbar>
      </AppBar>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {log ?
            <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", p: 4 }}>
              <LoaderAnimado />
            </Box>
            :
            <Tabla columnas={COLUMNSCARPD(theme)} datos={carpetas} titulo={"Carpeta Digital"} />
          }
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

export default ModalCarpetaDigital;
