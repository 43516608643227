import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Avatar from "@mui/material/Avatar";
import {
  Button,
  CircularProgress,
  TextField,
  Grid,
  Box,
  Typography,
} from "@mui/material/";
import logo from "../Imagenes/casfog-logo.png";
import Container from "@mui/material/Container";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import { login, logincasfog } from "../Redux/Usuarios";

const schema = yup.object().shape({
  correo: yup
    .string()
    .email("El correo electrónico no es válido")
    .required("El correo electrónico es requerido"),
  password: yup
    .string()
    .min(4, "La contraseña debe tener al menos 4 caracteres")
    .max(16, "La contraseña no puede serperar los 16 caracteres")
    .min(8, "La contraseña no puede tener menos de 8 caracteres")
    .required("La contraseña es requerida"),
});

const Login = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();

  const activo = useSelector((store) => store.usuarios.activo);
  const resultadoLogin = useSelector((store) => store.usuarios.resultadoLogin);
  const tipo = useSelector((store) => store.usuarios.tipo);

  React.useEffect(() => {
    if (activo && tipo) {
      if (activo == true) {
        reset();
        tipo === "SGR" ? navigate("/mis-sgr") : navigate("/resumendeposicion");
      }
    }
  }, [activo, tipo]);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmitHandler = (data) => {
    dispatch(logincasfog(data.correo, data.password));
  };

  return (
    <Container component="main" maxWidth="xs">
      <Box
        component="div"
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 0, width: 90, height: 90 }} src={logo}></Avatar>
        <Typography component="h1" variant="h5" sx={{ mb: 2, color: "#fff" }}>
          Iniciar Sesion
        </Typography>
        <form onSubmit={handleSubmit(onSubmitHandler)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                id="correo"
                label={
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 600, color: "#fff" }}
                  >
                    Usuario
                  </Typography>
                }
                {...register("correo")}
                helperText={errors.correo?.message}
                error={errors.correo}
                autoFocus
                sx={{
                  input: { color: "#fff" },
                  fieldset: { borderColor: "#fff" },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label={
                  <Typography
                    variant="subtitle2"
                    sx={{ fontWeight: 600, color: "#fff" }}
                  >
                    Contraseña
                  </Typography>
                }
                type="password"
                id="password"
                {...register("password")}
                helperText={errors.password?.message}
                error={errors.password}
                autoFocus
                sx={{
                  input: { color: "#fff" },
                  fieldset: { borderColor: "#fff" },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Box sx={{ mt: 1, mb: 2, position: "relative" }}>
            <Button
              type="submit"
              disabled={resultadoLogin === "LOADING"}
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 1 }}
            >
              Ingresar
            </Button>
            {resultadoLogin === "LOADING" && (
              <CircularProgress
                size={27}
                sx={{
                  color: "#fff",
                  position: "absolute",
                  top: "60%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </form>
        <Box
          sx={{
            mt: 1,
            display: "flex",
            flexDirection: { md: "row", xs: "column" },
            justifyContent: { xs: "flex-start", md: "space-between" },
            alignItems: { xs: "flex-start", md: "center" },
            width: "100%",
            gap:1
          }}
        >
          <Link
            to="/recupero"
            style={{ textDecoration: "none", color: "#fefefe" }}
          >
            Olvidaste la contraseña?
          </Link>
          <Link
            to="/solicitud-alta"
            style={{ textDecoration: "none", color: "#fff" }}
          >
            Todavía no sos socio?
            <br />
            Ingresá acá
          </Link>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
