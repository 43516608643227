import { Paper, Typography } from "@mui/material"

export const COLUMNASBALANCES = (tema) => [
    {
        accessorKey: 'id',
    },
    {
        header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>SGR</Typography>,
        accessorKey: 'sgr',
        Cell: ({ cell }) => (
            <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
                {cell.getValue()}
            </Typography>
        ),
    },
    {
        header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>DOCUMENTACIÓN</Typography>,
        accessorKey: 'documentacion',
        Cell: ({ cell }) => (
            <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
                {cell.getValue()}
            </Typography>
        ),
    },
    {
        header: <Typography sx={{ fontSize: '.8rem', fontWeight: 'bold', color: tema.typography.primary.main }}>FECHA DE VENCIMIENTO</Typography>,
        accessorKey: 'fecha_vencimiento',
        Cell: ({ cell }) => (
            <Typography color="inherit" sx={{ fontSize: '.8rem', fontWeight: 'medium', color: tema.typography.primary.main, fontWeight: 'bold' }}>
                {cell.getValue()}
            </Typography>
        ),
    },
]
