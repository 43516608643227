import Chip from '@mui/material/Chip';

export const COLUMNSPYT = [
    {
        accessorKey: 'id',
        enableEditing: false,
    },
    {
        header: 'Fecha',
        accessorKey: 'new_fecha',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Socio participe/tercero',
        accessorKey: 'new_socioparticipetercero',
        Cell: ({ cell }) => <strong><Chip label={cell.getValue()} sx={{ bgcolor: '#ad387f' }} /></strong>
    },
    {
        header: '% Socio participe/tercero',
        accessorKey: 'new_porcentajesocioparticipetercero',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>,
        muiTableBodyCellEditTextFieldProps: {
            type: 'number',
        },
    },
]

export const COLUMNSPRO = [
    {
        accessorKey: 'id',
        enableEditing: false,
    },
    {
        header: 'Fecha',
        accessorKey: 'new_fecha',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>
    },
    {
        header: 'Socio Protector',
        accessorKey: 'new_socioprotector',
        Cell: ({ cell }) => <strong><Chip label={cell.getValue()} sx={{ bgcolor: '#ad387f' }} /></strong>
    },
    {
        header: '% Socio protector',
        accessorKey: 'new_porcentajesocioprotector',
        Cell: ({ cell }) => <strong>{cell.getValue()}</strong>,
        muiTableBodyCellEditTextFieldProps: {
            type: 'number',
        },
    },
]