import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { teal, green } from "@mui/material/colors";
import { useSelector } from "react-redux";
import TokenContext from "./context/TokenContext";
import AppContent from "./Components/AppContent ";

function App() {
  const token = useSelector((store) => store.token.token);
  const [tokenContext, setTokenContext] = useState("");
  const [dark, setDark] = useState(true);

  useEffect(() => {
    if (token.token) {
      setTokenContext(token.token);
    }
  }, [token]);

  useEffect(() => {
    var modo = JSON.parse(localStorage.getItem("Dark Mode"))
    if (modo != null) {
      if (modo == true) {
        setDark(true)
      } else {
        setDark(false)
      }
    }
  }, [])

  //este es el theme que cambia los modos de color
  const temaOscuroBlanco = createTheme({
    components: {
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            "&:hover": {
              opacity: 0.9,
              color: "#fff",
            },
          }),
        },
      },
    },
    typography: {
      // In Chinese and Japanese the characters are usually larger,
      // so a smaller fontsize may be appropriate.
      fontFamily: [
        'Roboto',
        'Nunito',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif'
      ].join(','),
      fontSize: 12,
      primary: {
        ...(dark === true && {
          color: "#223354",
          main: '#ffffff'
        }),
        ...(dark === false && {
          color: teal[800],
          main: '#223354'
        }),
      },
      secondary: {
        ...(dark === true && {
          color: "#ffd166",
        }),
        ...(dark === false && {
          color: teal[800],
        }),
      },
    },
    listItemText: {
      fontSize: "0.5rem",
    },
    palette: {
      mode: dark ? "dark" : "light",
      background: {
        ...(dark === true && {
          default: "rgb(26, 32, 53)",
          paper: "rgb(26, 32, 53)",
          main: "rgb(26, 32, 53)",
          common: "rgb(26, 32, 53)",
          // default: "#24292e",
          // paper: "#24292e",
          // main: "#24292e",
          graficos: "#393BA7",
        }),
        ...(dark === false && {
          default: "#f2f5f9",
          paper: "rgba(255, 255, 255)",
          main: "#f2f5f9",
          common: "rgb(26, 32, 53)",
          graficos: "rgba(255, 255, 255)",
        }),
      },
      primary: {
        ...teal,
        fontSize: 15,
        ...(dark === true && {
          main: "#43a047",
          menu: "#rgba(255, 255, 255, 0.5)"
        }),
        ...(dark === false && {
          main: "#43a047",
          menu: "#223354"
        }),
      },
      secondary: {
        ...(dark === true && {
          // main: "#997d3d",
          main: "#43a047"
        }),
        ...(dark === false && {
          // main: green[400],
          main: "#43a047"
        }),
      },
      icons: {
        ...(dark === true && {
          // color: "#35F041",
          default: "rgba(255, 255, 255, 0.8)",
          main: "#43a047"
        }),
        ...(dark === false && {
          // color: teal[800],
          default: "#223354",
          main: "#5569ff"
        }),
      },
    },
  });
  
  return (
    <ThemeProvider theme={temaOscuroBlanco}>
      <TokenContext.Provider value={tokenContext}>
        <CssBaseline />
        <BrowserRouter basename="">
          <AppContent setDark={setDark} dark={dark} />
        </BrowserRouter>
      </TokenContext.Provider>
    </ThemeProvider>
  );
}

export default App;
